import React, { useRef, useState } from "react";
import { Form } from "@unform/web";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Yup from "yup";
import apiOld from "../../../services/Api";
import CustomSelectWithSearch from "../../input/customSelectorWithSearch/CustomSelectWithSearch";
import {
  ButtonsContainer,
  Container,
  ContainerInput,
  ExitButton,
  Modal,
  SubmitButton,
} from "./ChangeDemandTypeModal.styles";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export default function ChangeDemandTypeModal(props) {
  const { open, handleClose, modalData } = props;
  const demandTypes = useSelector((state) => state.demandTypes.all);
  const formRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  async function handleSubmit(data) {
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        demandTypeId: Yup.string()
          .required()
          .nullable(true)
          .label("Tipo de demanda"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setSubmitLoading(true);

      const request = {
        demandTypeId: data.demandTypeId,
      };

      await apiOld.patch(`/demands/${modalData.id}/demandType`, request);

      toast.success("Tipo de demanda alterado com sucesso!");

      handleClose(true);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
    setSubmitLoading(false);
  }

  return (
    <>
      {open && (
        <Container>
          <Modal>
            <h1>Alterar tipo de demanda</h1>

            <Form ref={formRef} onSubmit={handleSubmit}>
              <ContainerInput>
                <CustomSelectWithSearch
                  name="demandTypeId"
                  label="Tipo de demanda"
                  defaultValue={modalData.demandTypeId}
                  options={demandTypes?.map((demandType) => ({
                    name: demandType.name,
                    value: demandType.id,
                  }))}
                />
              </ContainerInput>

              <ButtonsContainer>
                <SubmitButton type="submit">
                  {!submitLoading && "Alterar"}
                  {submitLoading && <CircularProgress color="inherit" />}
                </SubmitButton>

                <ExitButton onClick={() => handleClose(false)}>
                  Fechar
                </ExitButton>
              </ButtonsContainer>
            </Form>
          </Modal>
        </Container>
      )}
    </>
  );
}
