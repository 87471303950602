import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import MiniDrawer from '../../../components/MiniDrawer';
import Table from '../../../components/Table';
import history from '../../../history';
import { formatDate } from '../../../utils';

const useStyles = makeStyles(theme => ({
  content: {
    height: '100vh',
    background: '#F5F6FA',
  },
  title: {
    fontSize: '35px',
  },
  table: {
    borderCollapse: 'collapse',
    margin: '25px 0',
    fontSize: '17px',
    width: '100%',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
  },
  tableHead: {
    backgroundColor: '#009879',
    color: '#ffffff',
    textAlign: 'left',
  },
  tableHeadTitleFirst: {
    borderRadius: '10px 0px 0px 0px',
    padding: '12px 15px',
  },
  tableHeadTitleLast: {
    borderRadius: '0px 10px 0px 0px',
    padding: '12px 15px',
  },
  tableHeadTitle: {
    padding: '12px 15px',
  },
  tableItem: {
    borderBottom: '1px solid #dddddd',
    '&:hover': {
      background: '#e0e0e0',
    },
  },
  tableItemColumn: {
    padding: '12px 15px',
    fontSize: '15px',
  },
  tableItemColumnStatus: {
    padding: '12px 15px',
    fontSize: '15px',
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'none',
  },
  action: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: '#000',
  },
  exportButton: {
    padding: '10px 15px',
    fontSize: '17px',
    background: '#1ab394',
    border: 'none',
    color: '#fff',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  selectSiz4Items: {},
}));

const headCells = [
  { id: 'customerName', numeric: false, label: 'Cliente' },
  { id: 'templateName', numeric: false, label: 'Template' },
  { id: 'createdAt', numeric: false, label: 'Data de Criação' },
];

const searchOptions = [
  // {
  //   value: 'name',
  //   name: 'Nome',
  // },
];

export default function DocumentTemplateMetrics() {
  const classes = useStyles();

  function handleClickItem(documentTemplate) {
    history.push(`/documents/${documentTemplate.documentId}/`);
  }

  async function handleRows(data) {
    return {
      rows: formatRows(data.templates),
      total: data.total,
    };
  }

  const formatRows = rows => {
    return rows.map(row => {
      row.templateName = row.template.name;
      row.customerName = row.document.customer.name;
      row.createdAt = formatDate(row.document.createdAt);

      return row;
    });
  };

  return (
    <React.Fragment>
      <MiniDrawer
        currentExpaded='templates'
        main={
          <Grid container className={classes.main}>
            <Grid item className={classes.content} xs={12}>
              <Grid item xs={12}>
                <Table
                  searchOptions={searchOptions}
                  title='Documentos gerados por template'
                  headCells={headCells}
                  handleRows={handleRows}
                  handleClickItem={handleClickItem}
                  route='metrics/documentTemplate'
                  rolePrefix='TEMPLATE'
                />
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </React.Fragment>
  );
}
