import CircularProgress from "@material-ui/core/CircularProgress";
import { Form } from "@unform/web";
import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import apiOld from "../../../services/Api";
import {
  Modal,
  Container,
  Title,
  ButtonsContainer,
  SubmitButton,
  ExitButton,
} from "./DeleteMessageConfirmationModal.styles";

export default function DeleteMessageConfirmationModal({
  open,
  handleClose,
  demandId,
  messageId,
}) {
  const formRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  async function handleSubmit() {
    try {
      setSubmitLoading(true);

      await apiOld.delete(`/demands/${demandId}/items/${messageId}`);

      toast.success("Mensagem deletada com sucesso!");

      handleClose(true);
    } catch (error) {
    } finally {
      setSubmitLoading(false);
    }
  }

  return (
    <>
      {open && (
        <Container>
          <Modal>
            <Title>Você deseja realmente deletar está mensagem?</Title>

            <Form ref={formRef} onSubmit={handleSubmit}>
              <ButtonsContainer>
                <SubmitButton type="submit">
                  {!submitLoading && "Excluir"}
                  {submitLoading && <CircularProgress color="inherit" />}
                </SubmitButton>

                <ExitButton onClick={() => handleClose(false)}>
                  Cancelar
                </ExitButton>
              </ButtonsContainer>
            </Form>
          </Modal>
        </Container>
      )}
    </>
  );
}
