import apiOld from "../Api";

const tagsApi = {
  
  addTag: async function (customerId, tagId, data) {
    return apiOld.post(`/customers/${customerId}/tags/${tagId}`, data);
  },

  create: async function (data) {
    return apiOld.post("/tags", data);
  },

  getAll: async function (additionalParams = {}) {
    return apiOld.get("/tags", {
      params: {
        ...additionalParams,
      },
    });
  },

  getById: async function (id, additionalParams = {}) {
    return apiOld.get(`/tags/${id}`, {
      params: {
        ...additionalParams,
      },
    });
  },

  delete: async function (customerId, tagId) {
    return apiOld.delete(`/customers/${customerId}/tags/${tagId}`);
  },
};

export default tagsApi;
