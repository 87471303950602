import React, { useEffect, useState } from 'react';
import MiniDrawer from '../../../components/MiniDrawer';
import apiOld from "../../../services/Api";
import DocumentService from '../../../services/documentService';
import { PageContainer } from '../../../components/Pages.styles';
import { saveFileToUserDisk, formatDate } from '../../../utils';
import Table from "../../../components/Table";

const documentService = new DocumentService();

export default function CreateMapping() {
  const [state, setState] = useState({
    document: {
      id: '',
      name: '',
      customer: {},
      user: {},
      template: {},
    },
    loading: true,
    editing: false,
  });



  const headCells = [
    { id: "name", numeric: false, label: "Nome" },
    { id: "customerName", numeric: false, label: "Cliente" },
    { id: "templateName", numeric: false, label: "Template" },
    { id: "createdAt", numeric: false, label: "Data de Criação" },
    { id: "id", numeric: false, label: "ID" },
  ];

  const searchOptions = [
    {
      value: "name",
      name: "Nome",
    },
  ];

  const loadCustomersInput = async () => {
    try {
      const response = await apiOld.get("/customers");
      const customers = response.data.customers;

      searchOptions.push({
        value: "customerId",
        name: "Cliente",
        customInput: customers.map((customer) => ({
          name: customer.name,
          value: customer.id,
        })),
      });
    } catch (error) {
      console.error(error);
    }
  };

  const loadTemplatesInput = async () => {
    try {
      const response = await apiOld.get("/templates");
      const templates = response.data.templates;

      searchOptions.push({
        value: "templateId",
        name: "Template",
        customInput: templates.map((template) => ({
          name: template.name,
          value: template.id,
        })),
      });
    } catch (error) {
      console.error(error);
    }
  };

  async function handleRows(data) {
    return {
      rows: await formatRows(data.documents),
      total: data.total,
    };
  }

  const formatRows = async (rows) => {
    const response = await apiOld.get("/customers");
    const customers = response.data.customers;

    return rows.map((row) => {
      row.createdAt = formatDate(row.createdAt);
      row.name = row.name || row.customFields.fileName || "--";
      row.templateName = row.templateName || "Anexo*";
      row.customerName =
        row.customerName ||
        customers.find((customer) => customer.id === row.customerId)?.name ||
        "--";
      return row;
    });
  };

  const handleClickItem = async (id) => {
    try {
      setState(prevState => ({
        ...prevState,
        loading: true,
      }));

      const documentData = (
        await apiOld.get(`/documents/${id}`, {
          params: {
            detailed: true,
          },
        })
      ).data;

      documentData.signedDownloadUrl = (
        await documentService.getDocumentSignedDownloadObj(id)
      ).signedUrl;

      setState(prevState => ({
        ...prevState,
        document: documentData,
        loading: false,
      }));
      const response = (await apiOld.get('auth/users/'))?.data;

      const backOfficeUserData = {
        name: response.name,
        email: response.email,
        phone: response.phone
      }
      const userData = {
        socialReason: documentData.customer.socialReason,
        cnpj: documentData.customer.cnpj,
        addressCity: documentData.customer.addressCity,
        email: documentData.customer.email
      }

      const query = {
        user: userData,
        backOffice: backOfficeUserData,
        documentId: id
      }
      
      await apiOld.post('/ropa', query, {
        responseType: 'arraybuffer',
      }).then((res) => {
        console.log(res)
        saveFileToUserDisk(res.data, res.headers, `Ropa.xlsx`)
      })
        .catch((err) => {
          console.log(err)
          console.error("Erro ao baixar o ROPA")
        })
    } catch (error) {
      setState(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  }

  useEffect(() => {
    loadCustomersInput();
    loadTemplatesInput();
  }, []);

 
  return (
    <React.Fragment>
      <MiniDrawer customStyle={{ padding: '0px' }} currentExpaded={'cs'}>
        <PageContainer>
          <div id="Content">
            <h1>Para gerar o ROPA, basta selecionar o documento desejado!</h1>
          </div>
          <Table
            searchOptions={searchOptions}
            title="Gerar ROPA"
            headCells={headCells}
            handleRows={handleRows}
            handleClickItem={handleClickItem}
            route="documents"
            rolePrefix="DOCUMENT"
          />
        </PageContainer>
      </MiniDrawer>
    </React.Fragment>
  );
}
