import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import MiniDrawer from '../../components/MiniDrawer';
import Table from '../../components/Table';
import apiOld from '../../services/Api';
import { openDemandModal } from '../../routes';
import EditMarcasModal from '../../components/editMarcasModal';

const useStyles = makeStyles(theme => ({
    content: {
        height: '100vh',
        background: '#F5F6FA',
    },
    title: {
        fontSize: '35px',
    },
    table: {
        borderCollapse: 'collapse',
        margin: '25px 0',
        fontSize: '17px',
        width: '100%',
        boxShadow: '0 0 20px rgba(0, 0, 0, 0.15)',
        borderRadius: '10px',
    },
    tableHead: {
        backgroundColor: '#009879',
        color: '#ffffff',
        textAlign: 'left',
    },
    tableHeadTitleFirst: {
        borderRadius: '10px 0px 0px 0px',
        padding: '12px 15px',
    },
    tableHeadTitleLast: {
        borderRadius: '0px 10px 0px 0px',
        padding: '12px 15px',
    },
    tableHeadTitle: {
        padding: '12px 15px',
    },
    tableItem: {
        borderBottom: '1px solid #dddddd',
        '&:hover': {
            background: '#e0e0e0',
        },
    },
    tableItemColumn: {
        padding: '12px 15px',
        fontSize: '15px',
    },
    tableItemColumnStatus: {
        padding: '12px 15px',
        fontSize: '15px',
    },
    link: {
        cursor: 'pointer',
        textDecoration: 'none',
    },
    action: {
        cursor: 'pointer',
        textDecoration: 'none',
        color: '#000',
    },
    exportButton: {
        padding: '10px 15px',
        fontSize: '17px',
        background: '#1ab394',
        border: 'none',
        color: '#fff',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    selectSiz4Items: {},
}));

const headCells = [
    { id: 'id', numeric: false, label: 'ID' },
    { id: 'demandId', numeric: false, label: 'Demand ID' },
    { id: 'responsibleUser', numeric: false, label: 'Usuário Responsável' },
    { id: 'name', numeric: false, label: 'Nome da Marca' },
    { id: 'apolUser', numeric: false, label: 'Login INPI' },
    { id: 'createdAt', numeric: false, label: 'Data de Criação' }
];

export default function Marcas() {
    const classes = useStyles();
    const [openMarcasModal, setOpenMarcasModal] = useState(false);
    const [idMarcaToEdit, setIdMarcaToEdit] = useState(null);
    const [searchOptions, setSearchOptions] = useState([
        {
            value: 'name',
            name: 'Nome',
        },
    ]);
    
    
    function handleClickItem(demandId) {
        openDemandModal(demandId);
    }

    const handleRows = useCallback(data => {
        const formatedDemands = formatRows(data.marcas);
        return {
            rows: formatedDemands,
            total: data.total,
        };
    }, []);

    const formatRows = rows => {
        return rows.map(row => ({
            ...row
        }));
    };

    function handleEdit(marcaId) {
        setIdMarcaToEdit(marcaId);
        setOpenMarcasModal(true);
    }


    return (
        <React.Fragment>
            <MiniDrawer
                currentExpaded='Marcas'
                main={
                    <>
                    <Grid container className={classes.main}>
                        <Grid container item className={classes.content}>
                            <Grid item xs={12}>
                                <EditMarcasModal id={idMarcaToEdit} handleClose={setOpenMarcasModal} isOpen={openMarcasModal}/>
                                {searchOptions && (
                                    <Table
                                        marcas={true}
                                        searchOptions={searchOptions}
                                        title='Marcas'
                                        headCells={headCells}
                                        handleRows={handleRows}
                                        handleClickItem={handleClickItem}
                                        route='marcas'
                                        rolePrefix='DEMAND'
                                        handleEdit={handleEdit}
                                        customParams={{ hasCustomer: true }}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    </>
                }
            />
        </React.Fragment>
    )
}
