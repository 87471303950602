export const CONFIGS_KEY = "@ndm-configs";
export const defaultConfigs = {
  columns: {},
};
export const defaultColumnConfigs = {
  internSlaOrder: "ASC",
  externSlaOrder: "",
  updateOrder: ""
};

export const setConfigs = (configs) => {
  localStorage.setItem(CONFIGS_KEY, JSON.stringify(configs));
};

export const getConfigs = () => JSON.parse(localStorage.getItem(CONFIGS_KEY));
