import React from 'react';
import {
  Container,
  Title,
  Description,
  ButtonContainer,
  Button,
} from './ButtonsModal.styles';
import Modal from '../modal/Modal';

export default function ButtonsModal({
  open,
  buttons,
  title = '',
  description = '',
  handleCloseButtonsModal,
}) {
  return open ? (
    <Modal visible={open}>
      <Container>
        <Title>{title}</Title>
        <Description>{description}</Description>

        <ButtonContainer>
          {buttons.map(({ bgColor, onClick, element }, idx) => (
            <Button key={idx} bgColor={bgColor} onClick={onClick}>
              {element}
            </Button>
          ))}
          <Button bgColor='#ea5f59' onClick={handleCloseButtonsModal}>
            Cancelar
          </Button>
        </ButtonContainer>
      </Container>
    </Modal>
  ) : null;
}
