import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Form } from "@unform/web";
import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import CustomInput from "../components/input/CustomInput";
import history from "../history";
import apiOld from "../services/Api";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(4),
  },
  submit: {
    fontSize: "20px",
    color: "#FFF",
    backgroundColor: "#1ab394",
    borderRadius: "31px",
    border: "none",
    padding: "10px 26px",
    marginLeft: "10px",
    boxShadow: "0 0 6px rgba(0, 0, 0, 0.09)",
  },
  createAccount: {
    backgroundColor: "#D4D4D4",
    borderRadius: "31px",
    border: "none",
    padding: "10px 26px",
    color: "#858585",
    fontSize: "20px",
  },
  title: {
    fontSize: "36px",
  },
  main: {
    background: "#F5F6FA",
    height: "95vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
  },
  buttonsContainer: {
    justifyContent: "flex-end",
    margin: theme.spacing(4, 0, 2),
  },
  forgotPassword: {
    textDecoration: "none",
    color: "#000",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  forgotPasswordContainer: {
    justifyContent: "center",
    marginTop: "25px",
    fontSize: "16px",
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    height: "5vh",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      marginRight: "0px",
    },
    marginRight: "20px",
    background: "#F5F6FA",
  },
  footerText: {
    textDecoration: "none",
    color: "#000",
    "&:hover": {
      textDecoration: "underline",
    },
    padding: "20px",
  },
  description: {
    fontSize: "20px",
    color: "#000000",
    marginBottom: "0.9rem",
  },
  resetPassowordContent: {
    justifyContent: "center",
  },
  back: {
    fontSize: "20px",
    color: "#000000",
    marginLeft: "10px",
  },
  containerBack: {
    marginBottom: "30px",
    flexGrow: 1,
  },
  blackLink: {
    textDecoration: "none",
    color: "#000",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  teste: {
    flexGrow: 1,
  },
  mainContainer: {
    flexGrow: 2,
  },
}));

export default function ResetPassword() {
  const classes = useStyles();
  const formRef = useRef(null);
  const { email, token } = useParams();
  const [submitLoading, setSubmitLoading] = useState(false);

  async function handleSubmit(data) {
    try {
      setSubmitLoading(true);

      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        senha: Yup.string()
          .required()
          .matches(
            /((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}))/,
            "Deve conter no mínimo 8 caracteres, sendo no mínimo uma letra maiúscula, uma letra minúscula e um número"
          ),
        confirmar: Yup.string()
          .required()
          .oneOf([Yup.ref("senha"), null], "As senhas devem combinar"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const request = {
        email: email,
        token: token,
        newPassword: data.senha,
      };

      await apiOld.post("/reset-password/", request);

      toast.success("Senha redefinida com sucesso!");

      history.push("/signIn");
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }

    setSubmitLoading(false);
  }

  return (
    <React.Fragment>
      <Grid container className={classes.main}>
        <Grid container className={classes.resetPassowordContent}>
          <Grid container item className={classes.mainContainer} sm={8} lg={4}>
            <div className={classes.paper}>
              <h1 className={classes.title}>Redefinir Senha</h1>
              <Form
                ref={formRef}
                className={classes.form}
                onSubmit={handleSubmit}
              >
                <Grid container className={classes.formContainer}>
                  <Grid item xs={12}>
                    <p className={classes.description}>
                      Sua senha deve conter no mínimo 8 caracteres, sendo no
                      mínimo uma letra maiúscula, uma letra minúscula e um
                      número
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <CustomInput name="senha" label="Senha" type="password" />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomInput
                      name="confirmar"
                      label="Confirmar Senha"
                      type="password"
                    />
                  </Grid>
                  <Grid container className={classes.buttonsContainer}>
                    <Grid item>
                      <button type="submit" className={classes.submit}>
                        {!submitLoading && "Enviar"}
                        {submitLoading && <CircularProgress color="inherit" />}
                      </button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.footer}>
        <div>
          {/* <a className={classes.footerText}>{"Termos & Condições"}</a><a className={classes.footerText} >{"Políticas de Privacidade"}</a> */}
        </div>
      </div>
    </React.Fragment>
  );
}
