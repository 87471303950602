import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Label,
  CalendarInput,
  Preview,
  CloseIcon,
  InputContainer,
  ArrowDown,
  Error,
} from './Calendar.styles';
import { useField } from '@unform/core';
import { formatDate, isHoliday } from '../../../utils/index';
import moment from 'moment';

function Calendar({
  name,
  selectRange,
  label,
  placeholder,
  defaultValue = null,
  containerProps = {},
  ...rest
}) {
  const calendarInputRef = useRef(null);
  const calendarRef = useRef(null);
  const { fieldName, registerField, error } = useField(name);
  const [value, setValue] = useState(defaultValue);
  const [visible, setVisible] = useState(false);

  const onChange = e => {
    setValue(e);
    setVisible(false);

    if (typeof rest.onChange === 'function') {
      rest.onChange(e);
    }
  };

  const _handleClick = e => {
    if (!calendarInputRef.current.contains(e.target)) {
      setVisible(false);
    }
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: calendarInputRef.current,
      getValue: () => {
        return calendarRef.current.props.value;
      },
      setValue: (_, val) => {
        setValue(val);
      },
      clearValue: () => {
        setValue(null);
      },
    });

    document.addEventListener('mousedown', _handleClick);

    return () => {
      document.removeEventListener('mousedown', _handleClick);
    };
  }, [fieldName, registerField]);

  const handleCalendarClick = (value, e) => {
    // abre o calendário após ele ser fechado pelo listener
    setTimeout(() => {
      setVisible(true);
    }, 1);
  };

  const handleCalendarHolidaysTile = props => {
    const { date, view } = props;
    if (view === 'month') {
      if (isHoliday(moment(date))) return 'isHoliday';
    }
    if (rest?.tileClassName) return rest.tileClassName(props);
  };

  return (
    <Container {...containerProps}>
      <Label>{label || 'Data'}</Label>

      <InputContainer hasValue={!!value}>
        <Preview onClick={() => setVisible(!visible)}>
          {!!value && selectRange
            ? `${formatDate(value[0])} até ${formatDate(value[1])}`
            : value
            ? `${formatDate(value)}`
            : placeholder || 'Filtrar por período'}
        </Preview>

        {value ? (
          <CloseIcon
            className='fa fa-lg fa-times'
            onClick={() => setValue(defaultValue)}
          />
        ) : (
          <ArrowDown />
        )}
      </InputContainer>

      {error && <Error>{error}</Error>}

      <CalendarInput
        calendarType='US'
        showNeighboringMonth={true}
        selectRange={selectRange}
        onClickDecade={handleCalendarClick}
        onClickMonth={handleCalendarClick}
        onClickYear={handleCalendarClick}
        value={value}
        visible={visible}
        displayType={'contents'}
        inputRef={calendarInputRef}
        {...rest}
        ref={calendarRef}
        onChange={onChange}
        tileClassName={handleCalendarHolidaysTile}
      />
    </Container>
  );
}

Calendar.propTypes = {
  name: PropTypes.string,
  selectRange: PropTypes.bool,
};

export default Calendar;
