import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import MiniDrawer from '../../components/MiniDrawer';
import Table from '../../components/Table';
import apiOld from '../../services/Api';
import { openDemandModal } from '../../routes';
import { Constants } from '../../utils/Constants';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  content: {
    height: '100vh',
    background: '#F5F6FA',
  },
  title: {
    fontSize: '35px',
  },
  table: {
    borderCollapse: 'collapse',
    margin: '25px 0',
    fontSize: '17px',
    width: '100%',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
  },
  tableHead: {
    backgroundColor: '#009879',
    color: '#ffffff',
    textAlign: 'left',
  },
  tableHeadTitleFirst: {
    borderRadius: '10px 0px 0px 0px',
    padding: '12px 15px',
  },
  tableHeadTitleLast: {
    borderRadius: '0px 10px 0px 0px',
    padding: '12px 15px',
  },
  tableHeadTitle: {
    padding: '12px 15px',
  },
  tableItem: {
    borderBottom: '1px solid #dddddd',
    '&:hover': {
      background: '#e0e0e0',
    },
  },
  tableItemColumn: {
    padding: '12px 15px',
    fontSize: '15px',
  },
  tableItemColumnStatus: {
    padding: '12px 15px',
    fontSize: '15px',
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'none',
  },
  action: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: '#000',
  },
  exportButton: {
    padding: '10px 15px',
    fontSize: '17px',
    background: '#1ab394',
    border: 'none',
    color: '#fff',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  selectSiz4Items: {},
}));

const headCells = [
  { id: 'name', numeric: false, label: 'Nome' },
  { id: 'customerName', numeric: false, label: 'Nome do Cliente' },
  { id: 'status', numeric: false, label: 'Status' },
  { id: 'createdAt', numeric: false, label: 'Data de Criação' },
  { id: 'activeContactUserName', numeric: false, label: 'Responsável CS' },
  { id: 'id', numeric: false, label: 'ID' },
];

export default function ListDemands() {
  const classes = useStyles();
  const [searchOptions, setSearchOptions] = useState([
    {
      value: 'name',
      name: 'Nome',
    },
  ]);

  const loadCustomersInput = async () => {
    try {
      const response = await apiOld.get('/customers');
      const customers = response.data.customers;

      setSearchOptions(searchOptions => [
        ...searchOptions,
        {
          value: 'customerId',
          name: 'Cliente',
          customInput: customers.map(customer => ({
            name: customer.name,
            value: customer.id,
          })),
        },
      ]);
    } catch (error) {
      console.error(error);
    }
  };

  const loadLawyer = async () => {
    try {

      const response = await apiOld.get('/demands');
      const lawyer = response.data.demands

      const _customInput = lawyer.map(data => ({
        name: data.backofficeUserName,
        value: data.backofficeUserId
      }))

      const parsed_array = _customInput.map(val => { return JSON.stringify(val) })
      const filtered_array = parsed_array.filter((value, ind) => parsed_array.indexOf(value) === ind).map((val) => { return JSON.parse(val) })

      setSearchOptions(searchOptions => [
        ...searchOptions,
        {
          value: 'backofficeUserId',
          name: 'Advogado',
          customInput: filtered_array
        },

      ]);


    } catch (error) {
      console.error(error);
    }
  }



  const loadDemandsTypes = async () => {
    try {
      const response = await apiOld.get('/demands/types');
      const types = response.data.demandTypes;


      setSearchOptions(searchOptions => [
        ...searchOptions,
        {
          value: 'demandTypeId',
          name: 'Tipo de Demanda',
          customInput: types.map(type => ({
            name: type.name,
            value: type.id,
          })),
        },
      ]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadCustomersInput();
    loadDemandsTypes();
    loadLawyer();
  }, []);

  function handleClickItem(demandId) {
    openDemandModal(demandId);
  }

  const handleRows = useCallback(async (data) => {
    const demands = await Promise.all(data.demands.map(async (demand) => {
      const { data: customerData } = await apiOld.get(`/customers/${demand.customerId}`);
      const { ActiveContact } = customerData;
      let activeContactUserName = 'Indefinido';
      
      if (ActiveContact && ActiveContact.backofficeUserId) {
        const { data: userData } = await apiOld.get(`/users/${ActiveContact.backofficeUserId}`);
        activeContactUserName = userData?.name;
      }
      
      return {
        ...demand,
        activeContactUserName,
      };
    }));
    
    const formattedDemands = formatRows(demands);
    
    return {
      rows: formattedDemands,
      total: data.total,
    };
  }, []);
  
  const formatRows = rows => {
    return rows.map(row => ({
      ...row,
      status: Constants.DEMAND_STATUSES[row.statusKey].name,
      customerName: row.customer?.name || '--',
    }));
  };

  function handleEdit(demandId) {
    openDemandModal(demandId);
  }

  return (
    <React.Fragment>
      <MiniDrawer
        currentExpaded='demands'
        main={
          <Grid container className={classes.main}>
            <Grid container item className={classes.content}>
              <Grid item xs={12}>
                {searchOptions && (
                  <Table
                    searchOptions={searchOptions}
                    title='Demandas'
                    headCells={headCells}
                    handleRows={handleRows}
                    handleClickItem={handleClickItem}
                    route='demands'
                    rolePrefix='DEMAND'
                    handleEdit={handleEdit}
                    customParams={{ hasCustomer: true }}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </React.Fragment>
  );
}
