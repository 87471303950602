import { formatDate } from '.';

const holidays = new Array(12).fill(false).map(x => new Array(31).fill(false));

const holidaysDates = [
  '01/01',
  '07/04', 
  '21/04', 
  '01/05', 
  '08/06', 
  '07/09', 
  '12/10', 
  '02/11', 
  '15/11',
  '25/12',
];

holidaysDates.forEach(date => {
  // populate matrix
  const [day, month] = date.split('/');

  holidays[+month - 1][+day - 1] = true;
});

const Constants = {
  SOCIETY_TYPES: [
    { name: 'Sociedade Limitada', value: 'Sociedade Limitada' },
    { name: 'Sociedade Anônima', value: 'Sociedade Anônima' },
    { name: 'Sociedade Individual', value: 'Sociedade Individual' },
    {
      name: 'Sociedade Individual de Responsabilidade Limitada',
      value: 'Sociedade Individual de Responsabilidade Limitada',
    },
    {
      name: 'Sociedade de Propósito Específico',
      value: 'Sociedade de Propósito Específico',
    },
    {
      name: 'Microempreendedor Individual',
      value: 'Microempreendedor Individual',
    },
    { name: 'Empresário Individual', value: 'Empresário Individual' },
    {
      name: 'Empresa Individual de Responsabilidade Limitada',
      value: 'Empresa Individual de Responsabilidade Limitada',
    },
    { name: 'Sociedade em Nome Coletivo', value: 'Sociedade em Nome Coletivo' },
    {
      name: 'Sociedade em Comandita Simples',
      value: 'Sociedade em Comandita Simples',
    },
    {
      name: 'Sociedade em Comandita por Ações',
      value: 'Sociedade em Comandita por Ações',
    },
  ],
  CIVIL_STATUS: [
    { name: '', value: '' },
    { name: 'Solteiro', value: 'Solteiro' },
    {
      name: 'Casado sob o regime de comunhão parcial de bens',
      value: 'Casado sob o regime de comunhão parcial de bens',
    },
    {
      name: 'Casado sob o regime de comunhão universal de bens',
      value: 'Casado sob o regime de comunhão universal de bens',
    },
    {
      name: 'Casado sob o regime de separação total de bens',
      value: 'Casado sob o regime de separação total de bens',
    },
    {
      name: 'Casado sob o regime de participação final nos aquestos',
      value: 'Casado sob o regime de participação final nos aquestos',
    },
    { name: 'Viúvo', value: 'Viúvo' },
    { name: 'Divorciado', value: 'Divorciado' },
  ],
  PERSON_TYPES: [
    { id: 'PF', value: 'PF', label: 'Pessoa Fisíca' },
    { id: 'PJ', value: 'PJ', label: 'Pessoa Juridica' },
  ],
  SEX_TYPES: [
    { name: 'Masculino', value: 1 },
    { name: 'Feminino', value: 2 },
  ],
  FEEDBACK_TYPES: {
    DEMAND: 'DEMAND',
  },
  DEMAND_STATUSES: {
    OPEN: {
      key: 'OPEN',
      value: 'OPEN',
      name: 'Aberta',
      color: '#1994FF',
      backgroundColor1: 'rgba(25,148,255, 0.2)',
      backgroundColor2: 'rgba(25,148,255, 0.8)',
    },
    UNCHECKED: {
      key: 'UNCHECKED',
      value: 'UNCHECKED',
      name: 'Não triada',
      color: '#2e2e2e',
      backgroundColor1: '#d3d3d3',
      backgroundColor2: 'rgba(25,148,255, 0.8)',
    },
    IN_PROGRESS: {
      key: 'IN_PROGRESS',
      value: 'IN_PROGRESS',
      name: 'Em Andamento',
      color: '#6344C8',
      backgroundColor1: 'rgba(99,68,200, 0.2)',
      backgroundColor2: 'rgba(99,68,200, 0.8)',
    },
    PENDING: {
      key: 'PENDING',
      value: 'PENDING',
      name: 'Pendente',
      color: '#F2B90F',
      backgroundColor1: 'rgba(242,185,15, 0.2)',
      backgroundColor2: 'rgba(242,185,15, 0.8)',
    },
    FINISHED: {
      key: 'FINISHED',
      value: 'FINISHED',
      name: 'Finalizada',
      color: '#1A9E1A',
      backgroundColor1: 'rgba(26,158,26, 0.2)',
      backgroundColor2: 'rgba(26,158,26, 0.8)',
    },
    BLOCKED: {
      key: 'BLOCKED',
      value: 'BLOCKED',
      name: 'Bloqueada',
      color: '#A9A9A9',
      backgroundColor1: '	rgb(169,169,169, 0.2)',
      backgroundColor2: 'rgba(169,169,169, 0.8)',
    }
  },
  DEMAND_TYPES: {
    ONBOARDING_MEETING: {
      key: 'Reunião de Onboarding',
    },
    ACTIVE_CONTACT_MEETING: {
      key: 'Reunião de Contato Ativo',
      color: '#F20FB9',
      backgroundColor1: 'rgba(242, 15, 207, 0.2)',
    },
    ENGAGEMENT_MEETING: {
      key: 'Reunião de Acompanhamento',
      color: '#F20FB9',
      backgroundColor1: 'rgba(242, 15, 207, 0.2)',
    },
    CHURN_MEETING: {
      key: 'Reunião de Alinhamento',
      color: '#F20FB9',
      backgroundColor1: 'rgba(242, 15, 207, 0.2)',
    },
    ROPA_MEETING: {
      key: 'Geração do ROPA',
      color: '#1994FF',
      backgroundColor1: 'rgba(25,148,255, 0.2)',
    }
  },
  DEMAND_STATUSES_LIST: [
    {
      name: 'Aberta',
      value: 'OPEN',
    },
    {
      name: 'Em Andamento',
      value: 'IN_PROGRESS',
    },
    {
      name: 'Pendente',
      value: 'PENDING',
    },
    {
      name: 'Finalizada',
      value: 'FINISHED',
    },
    {
      name: 'Bloqueada',
      value: 'BLOCKED',
    },
  ],
  FORM_TYPES: [
    { id: 'TEXT', value: 'TEXT', label: 'Texto' },
    { id: 'FILE', value: 'FILE', label: 'Arquivo' },
    {
      id: 'MULTIPLE_FILES',
      value: 'MULTIPLE_FILES',
      label: 'Multiplos arquivos',
    },
  ],
  HOLIDAYS: holidays,
  FEEDBACK_MAX_RATING: 5,
  TIME_TO_CONTACT_DAYS: 15,
  QUERY_PARAMS_STATES: {
    DEMAND: {
      DEMAND_MODAL: 'demandId',
    },
    FEEDBACK: {
      DEMAND_FEEDBACK_MODAL: 'demandFeedbackId',
    },
  },
  MESSAGE_TYPES: {
    STATUS: 'STATUS',
    MESSAGE: 'MESSAGE',
    DOCUMENT: 'DOCUMENT',
    FORM: 'FORM',
    FORM_ANSWER: 'FORM_ANSWER',
    PRIVATE_MESSAGE: 'PRIVATE_MESSAGE',
    ATTACHMENT: 'ATTACHMENT',
  },
  FILE_TYPES: {
    ATTACHMENT: 'ATTACHMENT',
    DOCUMENT: 'DOCUMENT',
    TEMPLATE: 'TEMPLATE',
    LOGO: 'LOGO',
    SALES_FORM: 'SALES_FORM',
    CUSTOMER_SERVICE_CONTRACT_FILE: 'CUSTOMER_SERVICE_CONTRACT_FILE',
  },
  ANNOTATION_TYPES: {
    general: {
      key: 'general',
      name: 'Geral',
      color: '#54a7bb',
    },
    sales: {
      key: 'sales',
      name: 'Vendas',
      color: '#219ebc',
    },
    onboarding: {
      key: 'onboarding',
      name: 'Onboarding',
      color: '#03486a',
    },
    ongoing: {
      key: 'ongoing',
      name: 'Ongoing',
      color: '#022e44',
    },
    emails: {
      key: 'emails',
      name: 'Emails',
      color: '#001b26',
    },
    cs: {
      key: 'cs',
      name: 'CS',
      color: '#001017',
    },
    finance: {
      key: 'finance',
      name: 'Financeiro',
      color: '#219eff',
    }
  },
  PUBLIC_LINKS: {
    PIPEDRIVE:
      'https://ndmadvogados.pipedrive.com/share/28cdd667856d5d4e18b117ae34087696ec52f01254c303c9ff75e6897c030c99',
  },
  USER_TYPES: {
    BACKOFFICE: 'BACKOFFICE',
    CUSTOMER: 'CUSTOMER',
  },
  CUSTOMER_TYPES: {
    AGRO: 'agro',
    FINTECH: 'fintech',
    MARKETPLACE: 'marketplace',
    SAUDE: 'saude',
    TECH: 'tech',
    LIST: ['agro', 'marketplace', 'saude', 'tech', 'fintech'],
    OPTIONS: [
      { name: 'Agro', value: 'agro' },
      { name: 'Fintech', value: 'fintech' },
      { name: 'Marketplace', value: 'marketplace' },
      { name: 'Saúde', value: 'saude' },
      { name: 'Tech', value: 'tech' },
      {
        name: 'Todos',
        value: null,
      },
    ],
  },
  CUSTOMER_COLUMNS: [
    {
      label: 'Data de expiração do contrato',
      value: row => formatDate(row.contractExpiration),
    },
    { label: 'Data de renovação', value: row => formatDate(row.renewalDate) },
    {
      label: 'Data do próximo contato ativo',
      value: row =>
        row.ActiveContact?.nextActiveContactDate
          ? formatDate(row.ActiveContact.nextActiveContactDate)
          : 'Indefinido',
    },
    {
      label: 'Responsável pelo contato ativo',
      value: row => row.ActiveContact?.User?.name || 'Indefinido',
    },
    { label: 'Status', value: 'status' },
    { label: 'Nome', value: 'name' },
    { label: 'Health Score', value: row => row.healthScore || 'Indefinido' },
    { label: 'Tipo de pessoa', value: 'personType' },
    { label: 'Tipo de cliente', value: 'customerType' },
    {
      label: 'CPF',
      value: 'cpf',
    },
    {
      label: 'CNPJ',
      value: 'cnpj',
    },
    { label: 'Razão Social', value: 'socialReason' },
    { label: 'typeCompany', value: 'typeCompany' },
    {
      label: 'Nome do Representante Legal',
      value: 'legalRepresentativeName',
    },
    {
      label: 'CPF do Representante Legal',
      value: 'legalRepresentativeCpf',
    },
    { label: 'CEP', value: 'cep' },
    { label: 'Endereço', value: 'address' },
    { label: 'Complemento do Endereço', value: 'addressComplement' },
    { label: 'Número', value: 'addressNumber' },
    { label: 'Bairro', value: 'addressDistrict' },
    { label: 'Cidade', value: 'addressCity' },
    { label: 'Estado', value: 'addressState' },
    {
      label: 'Tags',
      value: row => row.tags?.map(tag => tag?.name).join(', ') || '',
    },
    { label: 'Email', value: 'email' },
    { label: 'Criado em', value: row => formatDate(row.createdAt) },
    { label: 'Atualizado em', value: row => formatDate(row.updatedAt) },
  ],
  DEFAULT_TAGS: {
    CUSTOMER_SUCCESS_IGNORE: [
      'Teste',
      'Inadimplente',
      'Churn Cliente',
      'Churn NDM',
      'Demanda Específica',
    ],
    OVERDUE: 'Inadimplente',
    ONBOARDING: 'Onboarding',
    SUSPENDED_CONTRACT: 'Contrato Suspenso',
    INACTIVE_CUSTOMERS_TAGS: ['Churn Cliente', 'Churn NDM'],
    CHURN_REASONS: [
      { label: 'Rescisão Unilateral NDM', isNDM: true },
      { label: 'Rescisão por Inadimplência', isNDM: true },
      { label: 'Descontinuidade do Negócio', isNDM: false },
      { label: 'Corte de Custos', isNDM: false },
      { label: 'Baixa Demanda', isNDM: false },
      { label: 'Insatisfação do Cliente', isNDM: false },
      { label: 'Apoio de Outro Jurídico', isNDM: false },
      { label: 'Não retornou motivação', isNDM: false },
    ],
    ASAAS_CUSTOMER_IGNORE: ['Teste', 'Demanda Específica'],
    CUSTOMER_PREMIUM: 'Premium',
  },
  ONBOARDING_STATUSES: {
    WAITING_FOR_ONBOARDING: 'WAITING_FOR_ONBOARDING',
    ON_ONBOARDING: 'ON_ONBOARDING',
    ONBOARDING_FINISHED: 'ONBOARDING_FINISHED',
  },
  TEMPLATE_FIELDS_TYPES: [
    {
      value: 'text',
      name: 'Texto',
    },
    {
      value: 'boolean',
      name: 'Verdadeiro/Falso',
    },
    {
      value: 'options',
      name: 'Dropdown',
    },
    {
      value: 'date',
      name: 'Data',
    },
  ],
  CUSTOMER_CONTRACT_TYPES: {
    LEGAL_ADVICE: 'LEGAL_ADVICE',
    SPECIFIC_DEMAND: 'SPECIFIC_DEMAND',
    options: [
      {
        name: 'Assessoria Jurídica',
        value: 'LEGAL_ADVICE',
      },
      {
        name: 'Demanda Específica',
        value: 'SPECIFIC_DEMAND',
      },
    ],
    list: ['LEGAL_ADVICE', 'SPECIFIC_DEMAND'],
  },
  CS_ACTIONS: [
    {
      title: 'Criar Demanda de Reunião',
      daysToAdd: 7,
    },
    {
      title: 'Adicionar mais usuários na demanda e reenviar mensagem',
      daysToAdd: 3,
    },
    {
      title: 'Enviar E-mail',
      daysToAdd: 3,
    },
    {
      title: 'Enviar Mensagem Digisac',
      daysToAdd: 2,
    },
    {
      title: 'Fazer ligação via Skype',
      daysToAdd: 0,
    },
  ],
};

export { Constants };
