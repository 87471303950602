import apiOld from "../Api";

const searchApi = {
  search: async function (additionalParams = {}) {
    return apiOld.get("/search", {
      params: {
        ...additionalParams,
      },
    });
  },
};

export default searchApi;
