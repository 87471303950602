import filesApi from './apis/filesApi';
import { Constants } from '../utils/Constants';
import documentsApi from './apis/documentsApi';
import FileService from './fileService';
import * as Yup from 'yup';

export default class DocumentService {
  constructor() {
    this.fileService = new FileService();
  }

  async createDocument({ file, name, demandId, customerId }) {
    const formatedFile =
      await this.fileService.uploadFileAndFormatToDocumentStructure({
        file,
        documentType: Constants.FILE_TYPES.DOCUMENT,
      });

    return (
      await documentsApi.create({
        name,
        demandId,
        customerId,
        ...formatedFile,
      })
    ).data;
  }

  async updateDocument({ documentId, file, name }) {
    let formatedFile;

    if (file)
      formatedFile =
        await this.fileService.uploadFileAndFormatToDocumentStructure({
          file,
          documentType: Constants.FILE_TYPES.DOCUMENT,
        });

    const schema = Yup.object().shape({
      name: Yup.string().label('Nome').required(),
    });

    await schema.validate(
      {
        name,
      },
      {
        abortEarly: false,
      }
    );

    return (
      await documentsApi.update(documentId, {
        name,
        ...formatedFile,
      })
    ).data;
  }

  async downloadDocumentById(documentId) {
    const documentObj = await this.getDocumentSignedDownloadObj(documentId);

    filesApi.downloadFileByUrl(documentObj.signedUrl, documentObj.fileName);
  }

  async getDocumentByIdAndCustomer(data) {
    const documentObj = await this.getDocumentSignedDownloadObjByTwo(data);

    filesApi.getDocumentByIdAndCustomerId(documentObj.signedUrl)
  }

  async getDocumentSignedDownloadObjByTwo(data){
    const documentObj = (await documentsApi.getDocumentUrlByTwoFactors(data)).data;

    return {
      signedUrl: documentObj.signedUrl,
      fileName: documentObj.fileName,
    };
  }

  async getDocumentSignedDownloadObj(documentId) {
    const documentObj = (await documentsApi.getDocumentUrl(documentId)).data;

    return {
      signedUrl: documentObj.signedUrl,
      fileName: documentObj.fileName,
    };
  }
}
