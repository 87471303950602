module.exports = {
  DEMAND_DESCRIPTIONS: {
    FIRST_CONTACT:
      'Olá, [nome do cliente]. Como vai?\nMeu nome é [seu nome], sou uma das advogadas da NDM e entro em contato para saber se podemos marcar uma reunião de contato ativo e conversar sobre a [nome da empresa]/o seu negócio/projeto.\nNosso objetivo com essa call é fazer o nosso ponto de contato trimestral, ver quais são as necessidades jurídicas da empresa no momento e também o que poderíamos acrescentar para vocês.\nVocê possui disponibilidade na [quarta-feira (18/08) às 10h00]? Se não, peço que me indique uma data e um horário que sejam melhores para você, para que eu possa me adaptar!\nAtenciosamente,',
    SUCCESIVE_CONTACTS:
      'Olá, [nome do cliente]. Como vai?\nDando continuidade aos nossos contatos ativos, gostaria de saber a sua disponibilidade para agendarmos uma nova reunião.\nPodemos nos falar na terça-feira (XX/XX) às 13:00?\nCaso contrário, peço que me sugira outra opção de dia e horário para que eu possa me adaptar.\nAtenciosamente,',
    LOW_ENGAGEMENT:
      'Olá, (NOME DO CLIENTE).\nComo você está? Espero que bem.\nNotamos que não tivemos retorno de vocês nos últimos dias. O que acha de marcarmos uma reunião para alinharmos os próximos passos jurídicos da (NOME DA EMPRESA)? Essa reunião será importante para entendermos o momento de vocês e propor novas demandas que trarão mais segurança.\nTenho disponibilidade na (DATA E HORÁRIO). Caso não seja possível nesse dia e horário, gentileza indicar uma data e um horário que sejam melhores para você para que eu possa me adaptar.',
  },
};
