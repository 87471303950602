import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import DefaultTags from '../../constants/DefaultTags';
import { openDemandModal } from '../../routes';
import { formatDate, objectToURLSearchParamsString } from '../../utils';
import { Constants } from '../../utils/Constants';
import { BackofficeResponsibleModal } from '../csModals/BackofficeResponsibleModal';
import { ButtonOptionsModal } from '../csModals/ButtonOptions';
import { PayOverdueIcon, SimpleTable } from './Tables.styles';

export const GenericCustomerTable = ({ customers = [], fetchData }) => {
  const [modals, setModals] = useState({
    contactAtiveResponsibleModal: {
      open: false,
    },
    buttonsModal: {
      open: false,
      title: '',
      description: '',
      data: {},
      fetchData: () => {},
      handleCloseButtonsModal: () => {},
    },
  });

  const handleCloseButtonsModal = () =>
    setModals(prev => ({
      ...prev,
      buttonsModal: {
        ...prev.buttonsModal,
        open: false,
        title: '',
        description: '',
        data: {},
        fetchData: () => {},
        handleCloseButtonsModal: () => {},
      },
    }));

  const handleOpenButtonsModal = useCallback(
    customer => () =>
      setModals(prev => ({
        ...prev,
        buttonsModal: {
          ...prev.buttonsModal,
          open: true,
          title: 'Qual ação deseja realizar?',
          description:
            'Você pode sugerir uma reunião (caso o cliente não tenha) ou registrar uma atividade.',
          data: customer,
          fetchData,
          handleCloseButtonsModal,
        },
      })),
    [fetchData]
  );

  const _handleOpenActiveContactResponsibleModal = customer => {
    setModals(prev => ({
      ...prev,
      contactAtiveResponsibleModal: {
        open: true,
        customer,
        handleClose: () => {
          fetchData();
          setModals(prev => ({
            ...prev,
            contactAtiveResponsibleModal: {
              open: false,
            },
          }));
        },
      },
    }));
  };
  const annotationsParams = useCallback(
    () =>
      objectToURLSearchParamsString({
        ANNOTATION_TYPE: Constants.ANNOTATION_TYPES.cs.key,
      }),
    []
  );
  return customers.length > 0 ? (
    <SimpleTable.Container>
      <SimpleTable.Table>
        <SimpleTable.TableHead>
          <SimpleTable.TableRow>
            <SimpleTable.TableHeadCell
              style={{
                width: '20%',
              }}
            >
              Clientes
            </SimpleTable.TableHeadCell>

            <SimpleTable.TableHeadCell
              style={{
                width: '20%',
              }}
            >
              Responsável
            </SimpleTable.TableHeadCell>

            <SimpleTable.TableHeadCell
              style={{
                width: '20%',
              }}
            >
              Última demanda do cliente em
            </SimpleTable.TableHeadCell>

            <SimpleTable.TableHeadCell
              style={{
                width: '20%',
              }}
            >
              Ultima atividade CS
            </SimpleTable.TableHeadCell>

            <SimpleTable.TableHeadCell
              style={{
                width: '20%',
              }}
            >
              Data da última atividade CS
            </SimpleTable.TableHeadCell>
          </SimpleTable.TableRow>
        </SimpleTable.TableHead>

        <SimpleTable.TableBody>
          {customers.map(customer => (
            <SimpleTable.TableRow key={customer.id}>
              <SimpleTable.TableData>
                <Link to={`/customers/${customer.id}`}>
                  {customer.name}
                  {customer.tags?.find(
                    tag => tag.name === DefaultTags.PAYMENT_OVERDUE
                  ) && (
                    <PayOverdueIcon className='fas fa-exclamation-circle'></PayOverdueIcon>
                  )}
                </Link>
              </SimpleTable.TableData>

              <SimpleTable.TableData>
                <span
                  onClick={() =>
                    _handleOpenActiveContactResponsibleModal(customer)
                  }
                >
                  {customer.ActiveContact?.User?.name || 'Indefinido'}
                </span>
              </SimpleTable.TableData>

              <SimpleTable.TableData>
                {customer.lastActivity ? (
                  <span
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => openDemandModal(customer.lastActivity.id)}
                  >
                    {formatDate(customer.lastActivity.demandCreatedAt)}
                  </span>
                ) : (
                  <Link to={`/customers/${customer.id}/demands`}>Nenhuma</Link>
                )}
              </SimpleTable.TableData>

              <SimpleTable.TableData>
                <SimpleTable.TableDataButton
                  style={{
                    backgroundColor: '#1994FF',
                    fontWeight: 'bold',
                    color: 'white',
                    marginLeft: 0,
                  }}
                  onClick={handleOpenButtonsModal(customer)}
                >
                  Registrar nova atividade
                </SimpleTable.TableDataButton>
              </SimpleTable.TableData>
              <SimpleTable.TableData>
                <Link
                  title={
                    customer.annotations?.reduce(
                      (prev, curr) =>
                        curr?.type === Constants.ANNOTATION_TYPES.cs.key
                          ? curr.content
                          : prev,
                      ''
                    ) || ''
                  }
                  to={{
                    pathname: `/customers/${customer.id}`,
                    search: annotationsParams(),
                  }}
                >
                  {customer.engagement?.lastActivity
                    ? formatDate(customer.engagement.lastActivity)
                    : 'Nunca'}
                </Link>
              </SimpleTable.TableData>
            </SimpleTable.TableRow>
          ))}
        </SimpleTable.TableBody>
      </SimpleTable.Table>
      <ButtonOptionsModal {...modals.buttonsModal} />
      <BackofficeResponsibleModal {...modals.contactAtiveResponsibleModal} />
    </SimpleTable.Container>
  ) : (
    <SimpleTable.TableRow>
      <SimpleTable.TableData>
        <span>Nenhum cliente 😃</span>
      </SimpleTable.TableData>
    </SimpleTable.TableRow>
  );
};
