import React, { useState, useEffect, useRef, useCallback } from 'react';
import { AttachmentIcon } from './RichTextArea.styles';
import { useField } from '@unform/core';

export default function AttachmentsButton({ name = '', defaultValue }) {
  const attachmentsInputRef = useRef(null);
  const { fieldName, registerField, error } = useField(name);
  const [preview, setPreview] = useState(defaultValue);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: attachmentsInputRef,
      getValue: ref => {
        return ref?.current?.files || null;
      },
      clearValue(ref) {
        ref.current.value = null;
        setPreview(null);
      },
      setValue(_, value) {
        setPreview(value);
      },
    });
  }, [fieldName, registerField]);

  const handlePreview = useCallback(e => {
    const files = e.target.files;

    if (!files || files.length === 0) {
      setPreview('');
    }
    if (files.length === 1) {
      setPreview(files[0].name);
    } else {
      setPreview(files.length + ' arquivos');
    }
  }, []);

  return (
    <AttachmentIcon.Container>
      <AttachmentIcon.Label>
        <i className='fas fa-paperclip' />

        <AttachmentIcon.Input
          ref={attachmentsInputRef}
          onChange={handlePreview}
          type='file'
          multiple
        />

        <AttachmentIcon.Preview>{preview}</AttachmentIcon.Preview>

        {error && <AttachmentIcon.Preview>{error}</AttachmentIcon.Preview>}
      </AttachmentIcon.Label>
    </AttachmentIcon.Container>
  );
}
