import { combineReducers } from "redux";
import { RECIEVE_FORMS } from "../actionsTypes/ActionTypes";
import produce from "immer";

const ALL_INITIAL_STATE = [];
const BY_ID_INITIAL_STATE = {};

const all = produce((draft, action) => {
  switch (action.type) {
    case RECIEVE_FORMS: {
      draft = action.forms;

      return draft;
    }
    default:
      return draft;
  }
}, ALL_INITIAL_STATE);

const byId = produce((draft, action) => {
  switch (action.type) {
    case RECIEVE_FORMS:
      action.forms.forEach((form) => {
        draft[form.id] = form;
      });
      break;
    default:
      return draft;
  }
}, BY_ID_INITIAL_STATE);

export default combineReducers({
  byId,
  all,
});

export const getForm = (state, id) => state.byId[id];
