import React, { useEffect, useState, useRef, useCallback } from 'react';
import apiOld from '../../services/Api';
import MiniDrawer from '../../components/MiniDrawer';
import CustomInput from '../../components/input/CustomInput';
import CustomerAnnotationsModal from '../../components/customerAnnotationsModal/CustomerAnnotationsModal';
import Grid from '@material-ui/core/Grid';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import { validateBr } from 'js-brasil';
import { Form } from '@unform/web';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useQuery } from '../../routes';
import DataTable from "react-data-table-component"


import {
  AddUserModal,
  AddUserModalContainer,
  AddUserModalTitle,
  AddUserModalOptionsContainer,
  AddUserModalCancelButton,
  AddUserModalSubmitButton,
  OnboardingFormModal,
  HealthScore,
} from './CustomerProfile.styles';
import { toast } from 'react-toastify';
import TagsModal from '../../components/tagsModal/TagsModal';
import customerApi from '../../services/apis/customerApi';
import { TagComponent } from '../../components/input/TagInput';
import CustomerEmailContractModal from '../../components/customerEmailContractModal/CustomerEmailContractModal';
import {
  formatDate,
  formatDateHour,
  formatDateWithMonthName,
} from '../../utils';
import moment from 'moment';
import CheckboxInput from '../../components/input/CheckboxInput';
import usersApi from '../../services/apis/usersApi';
import ConfirmationModal from '../../components/confirmationModal/ConfirmationModal';
import Modal from '../../components/modal/Modal';
import FileInput from '../../components/input/FileInput';
import customerOnboardingApi from '../../services/apis/customerOnboardingApi';
import filesApi from '../../services/apis/filesApi';
import { Constants } from '../../utils/Constants';
import CustomerService from '../../services/customerService';
import { useSelector } from 'react-redux';
import RegisterNewCustomerSaleModal from './customerProfile/components/modals/registerNewCustomerSale/RegisterNewCustomerSaleModal';
import businessApi from '../../services/apis/businessApi';

const customerService = new CustomerService();

const useStyles = makeStyles(() => ({
  main: {
    background: '#FFF',
  },
  mainTitle: {
    background: '#FFF',
    borderTop: 'solid 2px #e7eaec',
    borderBottom: 'solid 1px #e7eaec',
    padding: '15px',
    justifyContent: 'space-between',
  },
  line: {
    backgorund: 'reds',
  },
  title: {
    fontSize: '15px',
  },
  mainForm: {
    padding: '10px',
  },
  form: {
    width: '100%',
  },
  input: {
    padding: '10px',
    a: {
      color: 'rgba(0, 0, 0, 0.87)',
    }
  },
  buttonContainer: {
    justifyContent: 'flex-end',
    padding: '10px',
  },
  editButton: {
    padding: '10px 10px',
    fontSize: '15px',
    background: '#1ab394',
    border: 'none',
    color: '#fff',
    borderRadius: '4px',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  demandsButton: {
    padding: '10px 10px',
    fontSize: '15px',
    background: '#ed9405',
    border: 'none',
    color: '#fff',
    borderRadius: '4px',
    textDecoration: 'none',
    marginRight: '10px',
  },
  annotationsButton: {
    padding: '10px 10px',
    fontSize: '15px',
    background: '#0585ed',
    border: 'none',
    color: '#fff',
    borderRadius: '4px',
    textDecoration: 'none',
    marginRight: '10px',
    cursor: 'pointer',
  },
  subTitle: {
    background: '#FFF',
    borderBottom: 'solid 1px #e7eaec',
  },
  addUser: {
    backgroundColor: '#1ab394',
    padding: '0px 10px',
    fontSize: '30px',
    border: 'none',
    color: '#fff',
    borderRadius: '5px',
    cursor: 'pointer',
  },
}));

const CustomerOnboardingFormModal = ({ open, handleClose, customerId }) => {
  const onboardingFormRef = useRef(null);

  const _handleOnboardingFormSubmit = async data => {
    try {
      onboardingFormRef.current.setErrors({});

      const salesFormYupSchema = Yup.object().shape({
        salesFormFile: Yup.mixed().required(
          'Formulário de onboarding é obrigatório'
        ),
      });

      await salesFormYupSchema.validate(data, {
        abortEarly: false,
      });

      const salesFormUploadedObject = await filesApi.uploadFile(
        data.salesFormFile,
        {
          type: Constants.FILE_TYPES.SALES_FORM,
        }
      );

      const request = {
        salesFormFileKey: salesFormUploadedObject.key,
      };

      await customerOnboardingApi.registerSalesForm(customerId, request);

      toast.success('formulário registrado com sucesso!');

      handleClose();
    } catch (error) {
      console.error(error);
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};

        error.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        onboardingFormRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <Modal visible={open}>
      <OnboardingFormModal.Container>
        <Form ref={onboardingFormRef} onSubmit={_handleOnboardingFormSubmit}>
          <OnboardingFormModal.Title>
            Registrar Formulário de Onboarding
          </OnboardingFormModal.Title>

          <FileInput label='Formulário de onboarding' name='salesFormFile' />

          <OnboardingFormModal.OptionsContainer>
            <OnboardingFormModal.CancelButton
              type='button'
              onClick={handleClose}
            >
              Cancelar
            </OnboardingFormModal.CancelButton>

            <OnboardingFormModal.SubmitButton type='submit'>
              Registrar
            </OnboardingFormModal.SubmitButton>
          </OnboardingFormModal.OptionsContainer>
        </Form>
      </OnboardingFormModal.Container>
    </Modal>
  );
};

const CustomerServiceContractUploadFormModal = ({
  open,
  handleClose,
  customerId,
}) => {
  /* 
  Modal para enviar o arquivo de contrato de serviço do cliente
  attr: serviceContractAwsKey
  */
  const customerServiceContractUploadFormRef = useRef(null);

  const _handleServiceContractFormSubmit = async data => {
    try {
      customerServiceContractUploadFormRef.current.setErrors({});

      await customerService.handleUploadCustomerServiceContractFile(
        customerId,
        data.serviceContractFile
      );

      handleClose(true);
    } catch (error) {
      console.error(error);
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};

        error.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        customerServiceContractUploadFormRef.current.setErrors(
          validationErrors
        );
      }
    }
  };

  return (
    <Modal visible={open}>
      <OnboardingFormModal.Container>
        <Form
          ref={customerServiceContractUploadFormRef}
          onSubmit={_handleServiceContractFormSubmit}
        >
          <OnboardingFormModal.Title>
            Upload do contrato de serviço
          </OnboardingFormModal.Title>

          <FileInput label='Contrato' name='serviceContractFile' />

          <OnboardingFormModal.OptionsContainer>
            <OnboardingFormModal.CancelButton
              type='button'
              onClick={handleClose}
            >
              Cancelar
            </OnboardingFormModal.CancelButton>

            <OnboardingFormModal.SubmitButton type='submit'>
              Salvar
            </OnboardingFormModal.SubmitButton>
          </OnboardingFormModal.OptionsContainer>
        </Form>
      </OnboardingFormModal.Container>
    </Modal>
  );
};

export default function CustomerProfile() {
  const addUserFormRef = useRef(null);
  const classes = useStyles();
  const { id } = useParams();
  useEffect(() => {
    window.location.href = `${process.env.REACT_APP_NEW_BACKOFFICE_URL}#/customers/${id}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const backofficeUsers = useSelector(state => state.backofficeUsers.all);
  const [state, setState] = useState({
    confirmationModal: {},
    afterSellFormModalIsOpen: false,
    updateCustomerServiceContractModalOpen: false,
  });
  const query = useQuery();
  const [customerData, setCustomerData] = useState(false);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [isAddBusinessModalOpen, setIsAddBusinessModalOpen] = useState(false);
  const [businessForm, setBusinessForm] = useState({});
  const [isAnnotationsModalOpen, setIsAnnotationsModalOpen] = useState(
    query.has('ANNOTATION_TYPE')
  );
  const [isTagModalOpen, setIsTagModalOpen] = useState(false);
  const [isRegisterNewSaleModalOpen, setIsRegisterNewSaleModalOpen] =
    useState(false);
  const [isEmailContractModalOpen, setIsEmailContractModalOpen] =
    useState(false);

  const [business, setBusiness] = useState([]);
  const fetchData = useCallback(async () => {
    try {
      const customerData = (await apiOld.get(`/customers/${id}`))?.data;

      const resBusiness = (await businessApi.getByCustomerId(id))?.data
      setCustomerData(customerData);
      setBusiness(resBusiness);
    } catch (error) { }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleModalClose = () => {
    setIsAnnotationsModalOpen(false);
  };


  const handleAddAdditionalUser = async data => {
    try {
      addUserFormRef.current.setErrors({});

      const addUserYupSchema = Yup.object().shape({
        name: Yup.string().label('Nome').required(),
        email: Yup.string().label('Email').email().required(),
        phone: Yup.string()
          .label('Telefone')
          .test(
            'celular',
            'Número celular inválido, você inseriu o 9 antes?',
            value => {
              if (value === '') return true;
              return validateBr.celular(value);
            }
          ),
        role: Yup.string().label('Nome'),
        customerAdmin: Yup.boolean().label('Administrador'),
        customerSuperAdmin: Yup.boolean().label('Super Administrador'),
      });

      await addUserYupSchema.validate(data, {
        abortEarly: false,
      });

      const request = {
        name: data.name,
        email: data.email,
        phone: data.phone,
        role: data.role,
        customerAdmin: data.customerAdmin,
        customerSuperAdmin: data.customerSuperAdmin,
      };
      await apiOld.post('/customers/' + id + '/users', request);

      toast.success('Usuário adicionado com sucesso!');

      setIsAddUserModalOpen(false);
      fetchData();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};

        error.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        addUserFormRef.current.setErrors(validationErrors);
      }
    }
  };

  const _handleCloseUpdateCustomerServiceContractModal = updated => {
    if (updated) {
      fetchData();
    }

    setState({
      ...state,
      updateCustomerServiceContractModalOpen: false,
    });
  };

  const columns = [
    {
      name: 'Nome',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Criado em',
      selector: 'createdAt',
      sortable: true,
    },
    {
      name: 'Ações',
      selector: 'actions',
      sortable: false,
    }
  ]

  const history = useHistory();

  const treatedBusiness = business?.map((item) => {
    const styleButtons = {
      whiteSpace: 'nowrap',
      maxHeight: '40px',
      marginRight: '15px',
    };

    return {
      ...item,
      createdAt: formatDate(item.createdAt),
      actions: (
        <div style={{ display: 'inline-flex' }}>
          <button
            className={classes.editButton}
            style={{
              ...styleButtons,
              backgroundColor: '#ed9405',
            }}
            onClick={() => {
              history.push(`/business/demands?customerId=${id}&businessId=${item.id}`);
            }}
          >
            Demandas
          </button>
          <button
            className={classes.editButton}
            style={{
              ...styleButtons,
              backgroundColor: 'rosybrown',
            }}
            onClick={() => {
              history.push(`/business/journeys?customerId=${id}&businessId=${item.id}`);
            }}
          >
            Jornadas
          </button>
          <button
            className={classes.editButton}
            style={{
              ...styleButtons,
              backgroundColor: 'blueviolet',
            }}
            onClick={() => {
              setBusinessForm(item);
              setIsAddBusinessModalOpen(true);
            }}
          >
            editar
          </button>
          <button
            className={classes.editButton}
            style={{
              ...styleButtons,
              backgroundColor: 'gray',
            }}
            onClick={() => {
              history.push(`/business/${item.id}`);
            }}
          >
            opções
          </button>
          <button
            className={classes.editButton}
            style={{
              ...styleButtons,
              backgroundColor: 'red',
            }}
            onClick={() => {
              if (window.confirm('Tem certeza que deseja deletar?')) {
                businessApi.handleDeleteBusiness(item.id).then(() => {
                  fetchData();
                })
              }
            }}
          >
            deletar
          </button>
        </div>
      )
    }
  })

  return (
    <React.Fragment>
      <MiniDrawer currentExpaded='customers'>
        <Grid container className={classes.main}>
          {customerData && backofficeUsers && (
            <>
              <Grid container className={classes.mainTitle}>
                <h2 className={classes.title}>Cliente</h2>

                <div>
                  <button
                    className={classes.annotationsButton}
                    style={{
                      backgroundColor: 'ButtonShadow',
                    }}
                    onClick={() => setIsRegisterNewSaleModalOpen(true)}
                  >
                    Registrar nova venda
                  </button>
                  <button
                    className={classes.annotationsButton}
                    style={{
                      backgroundColor: 'coral',
                    }}
                    onClick={() =>
                      setState({ ...state, afterSellFormModalIsOpen: true })
                    }
                  >
                    Formulário pós-venda
                  </button>
                  <button
                    className={classes.annotationsButton}
                    style={{
                      backgroundColor: 'brown',
                    }}
                    onClick={() => setIsEmailContractModalOpen(true)}
                  >
                    Email Contrato
                  </button>
                  <button
                    className={classes.annotationsButton}
                    style={{
                      backgroundColor: 'purple',
                    }}
                    onClick={() => setIsTagModalOpen(true)}
                  >
                    Tags
                  </button>
                  <button
                    className={classes.annotationsButton}
                    onClick={() => setIsAnnotationsModalOpen(true)}
                  >
                    Anotações
                  </button>
                  <Link
                    className={classes.demandsButton}
                    style={{
                      backgroundColor: 'rosybrown',
                    }}
                    to={`/business/journeys?customerId=${id}`}
                  >
                    Jornadas
                  </Link>
                  <Link
                    className={classes.demandsButton}
                    to={`/business/demands?customerId=${id}`}
                  >
                    Demandas
                  </Link>
                  <Link
                    className={classes.editButton}
                    to={`/customers/${id}/edit`}
                  >
                    Editar
                  </Link>
                </div>
              </Grid>

              <Grid container className={classes.mainForm}>
                <Grid container>
                  <Grid item className={classes.input} xs={12}>
                    <h2 className={classes.subTitle}>Informações Básicas</h2>
                  </Grid>

                  <Grid item className={classes.input} xs={6}>
                    <label>Nome:</label>
                    <p>{customerData.name}</p>
                  </Grid>

                  <Grid item className={classes.input} xs={6}>
                    <label>Health Score:</label>
                    <HealthScore health={customerData.healthScore}>
                      {customerData.healthScore || 'Indefinido'}
                    </HealthScore>
                  </Grid>

                  {customerData.personType === 'PF' && (
                    <>
                      <Grid item className={classes.input} xs={6}>
                        <label>RG:</label>
                        <p>{customerData.rg}</p>
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <label>CNH:</label>
                        <p>{customerData.cnh}</p>
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <label>CPF:</label>
                        <p>{customerData.cpf}</p>
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <label>Sexo:</label>
                        <p>{customerData.sex}</p>
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <label>Nacionalidade:</label>
                        <p>{customerData.nationality}</p>
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <label>Estado Civil:</label>
                        <p>{customerData.civilState}</p>
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <label>Profissão:</label>
                        <p>{customerData.sex}</p>
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <label>Data de Nascimento:</label>
                        <p>{customerData.birthDate}</p>
                      </Grid>
                    </>
                  )}
                  {customerData.personType === 'PJ' && (
                    <>
                      <Grid item className={classes.input} xs={6}>
                        <label>Razão Social:</label>
                        <p>{customerData.socialReason}</p>
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <label>CNPJ:</label>
                        <p>{customerData.cnpj}</p>
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <label>Tipo de sociedade:</label>
                        <p>{customerData.typeCompany}</p>
                      </Grid>
                    </>
                  )}

                  <Grid item className={classes.input} xs={6}>
                    <label>Tags</label>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      {customerData?.tags?.map(tag => (
                        <TagComponent
                          disabled
                          key={tag.id}
                          name={tag.name}
                          description={tag.description}
                        />
                      ))}
                    </div>
                  </Grid>

                  {customerData?.onboarding?.salesFormFileKey ? (
                    <Grid item className={classes.input} xs={6}>
                      <label>Formulário de onboarding</label>

                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <button
                          className={classes.editButton}
                          onClick={() =>
                            filesApi.downloadFileByKey(
                              customerData.onboarding.salesFormFileKey
                            )
                          }
                        >
                          Baixar
                        </button>
                      </div>
                    </Grid>
                  ) : null}

                  <Grid item className={classes.input} xs={6}>
                    <label>Acesso do cliente:</label>
                    <p>{customerData.customerAccess}</p>
                  </Grid>

                  <Grid item className={classes.input} xs={6}>
                    <label>Tipo de Pessoa</label>
                    <p>{customerData.personType}</p>
                  </Grid>

                  <Grid item className={classes.input} xs={6}>
                    <label>Contrato de assesoria</label>

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      {customerData.serviceContractAwsKey && (
                        <button
                          className={classes.editButton}
                          onClick={() =>
                            filesApi.downloadFileByKey(
                              customerData.serviceContractAwsKey
                            )
                          }
                          style={{ marginRight: '10px' }}
                        >
                          Baixar
                        </button>
                      )}

                      <button
                        className={classes.editButton}
                        onClick={() =>
                          setState({
                            ...state,
                            updateCustomerServiceContractModalOpen: true,
                          })
                        }
                        style={{
                          backgroundColor: 'blueviolet',
                        }}
                      >
                        {customerData.serviceContractAwsKey
                          ? 'Atualizar'
                          : 'Adicionar arquivo do contrato'}
                      </button>
                    </div>
                  </Grid>

                  <Grid item className={classes.input} xs={6}>
                    <label>Tipo de Cliente</label>
                    <p>
                      {customerData.customerType
                        ? customerData.customerType
                        : 'indefinido'}
                    </p>
                  </Grid>

                  <Grid item className={classes.input} xs={6}>
                    <label>Vencimento última parcela do contrato:</label>
                    <p>
                      {customerData.contractExpiration
                        ? formatDate(customerData.contractExpiration)
                        : '--'}
                    </p>
                  </Grid>
                  <Grid item className={classes.input} xs={6}>
                    <label>Data da venda:</label>
                    <p>
                      {customerData.saleDate
                        ? formatDate(customerData.saleDate)
                        : '--'}
                    </p>
                  </Grid>
                  <Grid item className={classes.input} xs={6}>
                    <label>Inadimplente? </label>
                    <p>
                      {customerData?.tags?.some(
                        tag => tag.name.toLowerCase() === 'inadimplente'
                      )
                        ? 'Sim'
                        : 'Não'}
                    </p>
                  </Grid>
                  <Grid item className={classes.input} xs={6}>
                    <label>Acesso a plataforma? </label>

                    <p>
                      {customerData.accessAllowedUntil
                        ? moment().isAfter(customerData.accessAllowedUntil)
                          ? 'Não'
                          : `Até ${formatDateWithMonthName(
                            customerData.accessAllowedUntil
                          )} às ${formatDateHour(
                            customerData.accessAllowedUntil
                          )} `
                        : 'Sim'}
                    </p>
                  </Grid>
                  <Grid item className={classes.input} xs={6}>
                    <label>Email de renovação de contrato enviado? </label>
                    <p>
                      {customerData?.contractRenewalEmailSent ? 'Sim' : 'Não'}
                    </p>
                  </Grid>
                  <Grid item className={classes.input} xs={6}>
                    <label>Data do próximo contato: </label>

                    <p
                      style={{
                        color:
                          moment(
                            customerData.ActiveContact?.nextActiveContactDate
                          ).isValid() &&
                            moment(
                              customerData.ActiveContact?.nextActiveContactDate
                            ).isBefore(moment().add(7, 'days'))
                            ? 'red'
                            : undefined,
                      }}
                    >
                      {customerData.ActiveContact?.nextActiveContactDate
                        ? `${formatDateWithMonthName(
                          customerData.ActiveContact?.nextActiveContactDate
                        )} `
                        : 'indefinida'}
                    </p>
                  </Grid>
                  <Grid item className={classes.input} xs={6}>
                    <label>Responsável pelo contato:</label>
                    <p>
                      {backofficeUsers.find(
                        user =>
                          user.id ===
                          customerData.ActiveContact?.backofficeUserId
                      )?.name || 'Indefinido'}
                    </p>
                  </Grid>

                  <Grid item className={classes.input} xs={12}>
                    <h2 className={classes.subTitle}>Endereço</h2>
                  </Grid>
                  <Grid item className={classes.input} xs={6}>
                    <label>Cliente Internacional?</label>
                    <p>{customerData.isInternacional ? 'Sim' : 'Não'}</p>
                  </Grid>
                  <Grid item className={classes.input} xs={6}>
                    <label>
                      {customerData.isInternacional ? 'Zip code' : 'CEP'}
                    </label>
                    <p>{customerData.cep}</p>
                  </Grid>
                  <Grid item className={classes.input} xs={6}>
                    <label>Logradouro:</label>
                    <p>{customerData.address}</p>
                  </Grid>
                  <Grid item className={classes.input} xs={6}>
                    <label>Complemento:</label>
                    <p>{customerData.addressComplement}</p>
                  </Grid>
                  <Grid item className={classes.input} xs={6}>
                    <label>Número:</label>
                    <p>{customerData.addressNumber}</p>
                  </Grid>
                  <Grid item className={classes.input} xs={6}>
                    <label>Bairro:</label>
                    <p>{customerData.addressDistrict}</p>
                  </Grid>
                  <Grid item className={classes.input} xs={6}>
                    <label>Estado:</label>
                    <p>{customerData.addressState}</p>
                  </Grid>
                  <Grid item className={classes.input} xs={6}>
                    <label>Cidade:</label>
                    <p>{customerData.addressCity}</p>
                  </Grid>
                  {customerData.personType === 'PJ' && (
                    <>
                      <Grid item className={classes.input} xs={12}>
                        <h2 className={classes.subTitle}>Representante Legal</h2>
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <label>Nome do Representante Legal:</label>
                        <p>{customerData.legalRepresentativeName}</p>
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <label>CPF do Representante Legal:</label>
                        <p>{customerData.legalRepresentativeCpf}</p>
                      </Grid>
                    </>
                  )}
                  <Grid item className={classes.input} xs={12}>
                    <h2 className={classes.subTitle}>Informações de Contato</h2>
                  </Grid>
                  <Grid item className={classes.input} xs={6}>
                    <label>E-mail</label>
                    <p>{customerData.email}</p>
                  </Grid>
                  <Grid item className={classes.input} xs={6}>
                    <label>Telefone</label>
                    <p>{customerData.phone}</p>
                  </Grid>

                  <Grid item className={classes.input} xs={6}>
                    <a target="_blank" href={customerData.site} rel="noreferrer">{customerData.site}</a>
                  </Grid>

                  <Grid
                    container
                    className={classes.subTitle}
                    style={{ margin: '10px' }}
                    spacing={1}
                    direction='row'
                  >
                    <Grid item>
                      <h2>Usuários do cliente</h2>
                    </Grid>

                    <Grid item>
                      <button
                        className={classes.addUser}
                        type='button'
                        onClick={() => setIsAddUserModalOpen(true)}
                      >
                        +
                      </button>
                    </Grid>
                  </Grid>



                  {customerData.users?.map((user, idx) => (
                    <React.Fragment key={user.id}>
                      <Grid item className={classes.input} xs={12}>
                        <h2 className={classes.title}>Usuário {idx + 1}</h2>
                      </Grid>

                      <Grid item className={classes.input} xs={3}>
                        <label>Nome</label>
                        <p>{user.name}</p>
                      </Grid>

                      <Grid item className={classes.input} xs={3}>
                        <label>Email</label>
                        <p>{user.email}</p>
                      </Grid>

                      <Grid item className={classes.input} xs={3}>
                        <label>Telefone</label>
                        <p>{user.phone}</p>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <button
                          className={classes.editButton}
                          style={{
                            whiteSpace: 'nowrap',
                            maxHeight: '40px',
                            backgroundColor: 'blueviolet',
                          }}
                          disabled={
                            state['userGenerateNewPasswordLoading' + user.id]
                          }
                          onClick={() => {
                            setState({
                              ...state,
                              confirmationModal: {
                                open: true,
                                resolveCallback: async () => {
                                  setState({
                                    ...state,
                                    confirmationModal: {},
                                  });

                                  usersApi
                                    .generateNewPassword(user.id)
                                    .then(() => {
                                      toast.success(
                                        `Uma nova senha foi gerada com sucesso para o usuário ${user.name}`
                                      );
                                    });
                                },
                                rejectCallback: () => {
                                  setState({
                                    ...state,
                                    confirmationModal: {},
                                  });
                                },
                                description: `Esta ação irá gerar uma nova senha para o usuário "${user.name}" e enviará um email com a nova senha para "${user.email}"`,
                                title: 'Gerar uma nova senha',
                              },
                            });
                          }}
                        >
                          Enviar nova senha
                        </button>
                        <Link
                          className={classes.editButton}
                          to={`/users/${user.id}/edit`}
                        >
                          Editar
                        </Link>
                      </Grid>

                    </React.Fragment>
                  ))}

                  <Grid
                    container
                    className={classes.subTitle}
                    style={{ margin: '10px' }}
                    spacing={1}
                    direction='row'
                  >
                    <Grid item>
                      <h2>Negocios do cliente</h2>
                    </Grid>

                    <Grid item>
                      <button
                        className={classes.addUser}
                        type='button'
                        onClick={() => setIsAddBusinessModalOpen(true)}
                      >
                        +
                      </button>
                    </Grid>
                  </Grid>
                  <DataTable
                    columns={columns}
                    data={treatedBusiness}
                  />

                </Grid>
              </Grid>
            </>
          )}

          {customerData && (
            <CustomerAnnotationsModal
              open={isAnnotationsModalOpen}
              handleClose={handleModalClose}
              modalData={customerData}
            />
          )}

          <AddUserModal open={isAddUserModalOpen}>
            <AddUserModalContainer>
              <Form ref={addUserFormRef} onSubmit={handleAddAdditionalUser}>
                <AddUserModalTitle>Adicionar usuário</AddUserModalTitle>

                <CustomInput name='name' label='Nome*' />
                <CustomInput name='email' label='Email*' />
                <InputMask mask='(99)99999-9999' maskChar=' ' disabled={false}>
                  {() => <CustomInput name='phone' label='Telefone' />}
                </InputMask>
                <CustomInput name='role' label='Cargo/Função' />
                <CheckboxInput name='customerAdmin' label='Administrador' />
                <CheckboxInput name='customerSuperAdmin' label='Super Administrador' />
                <AddUserModalOptionsContainer>
                  <AddUserModalCancelButton
                    type='button'
                    onClick={() => setIsAddUserModalOpen(false)}
                  >
                    Cancelar
                  </AddUserModalCancelButton>

                  <AddUserModalSubmitButton type='submit'>
                    Adicionar
                  </AddUserModalSubmitButton>
                </AddUserModalOptionsContainer>
              </Form>
            </AddUserModalContainer>
          </AddUserModal>

          <AddUserModal open={isAddBusinessModalOpen}>
            <AddUserModalContainer>
              <Form onSubmit={() => {
                let saveFunction = businessApi.handleAddBusiness
                let successMessage = 'Negocio adicionado com sucesso'

                if (businessForm?.id) {
                  saveFunction = businessApi.handleUpdateBusiness
                  successMessage = 'Negocio atualizado com sucesso'
                }

                saveFunction(businessForm, id).then(() => {
                  toast.success(successMessage)
                  setIsAddBusinessModalOpen(false)
                  fetchData()
                }).catch((error) => {
                  toast.error('Erro ao sincronizar dados')
                })

              }}>
                <AddUserModalTitle>Adicionar negocio</AddUserModalTitle>

                <CustomInput value={businessForm?.name} onChange={(e) => setBusinessForm({ ...businessForm, name: e.target.value })} name='name' label='Nome*' />

                <AddUserModalOptionsContainer>
                  <AddUserModalCancelButton
                    type='button'
                    onClick={() => {
                      setBusinessForm({ name: '' })
                      setIsAddBusinessModalOpen(false)
                    }}
                  >
                    Cancelar
                  </AddUserModalCancelButton>

                  <AddUserModalSubmitButton type='submit'>
                    Adicionar
                  </AddUserModalSubmitButton>
                </AddUserModalOptionsContainer>
              </Form>
            </AddUserModalContainer>
          </AddUserModal>

          <TagsModal
            visible={isTagModalOpen}
            actualTags={customerData.tags}
            handleFetchTags={fetchData}
            handleRemoveTag={async tagId => {
              try {
                await customerApi.removeTag(customerData.id, tagId);
              } catch (error) { }
            }}
            handleAddTag={async (tagId, data) => {
              try {
                console.log(tagId, data)
                await customerApi.addTag(customerData.id, tagId, data);
              } catch (error) { }
            }}
            handleCloseTagsModal={() => {
              setIsTagModalOpen(false);
            }}
          />

          <CustomerEmailContractModal
            open={isEmailContractModalOpen}
            customerId={id}
            customerEmail={customerData?.email}
            handleClose={() => {
              setIsEmailContractModalOpen(false);
              fetchData();
            }}
          />

          <ConfirmationModal {...state.confirmationModal} />

          <CustomerOnboardingFormModal
            open={state.afterSellFormModalIsOpen}
            customerId={customerData?.id}
            handleClose={() =>
              setState({ ...state, afterSellFormModalIsOpen: false })
            } g
          />

          <CustomerServiceContractUploadFormModal
            open={state.updateCustomerServiceContractModalOpen}
            customerId={customerData?.id}
            handleClose={_handleCloseUpdateCustomerServiceContractModal}
          />

          <RegisterNewCustomerSaleModal
            open={isRegisterNewSaleModalOpen}
            customer={customerData}
            handleClose={() => setIsRegisterNewSaleModalOpen(false)}
          />
        </Grid>
      </MiniDrawer>
    </React.Fragment>
  );
}
