import React, { useState, useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import Editor, { createEditorStateWithText } from '@draft-js-plugins/editor';
import { convertToRaw, EditorState, Modifier } from 'draft-js';
import createToolbarPlugin, {
  Separator,
} from '@draft-js-plugins/static-toolbar';
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  UnorderedListButton,
  OrderedListButton,
} from '@draft-js-plugins/buttons';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/static-toolbar/lib/plugin.css';
import '@draft-js-plugins/linkify/lib/plugin.css';
import { Container } from './RichTextArea.styles';
import AttachmentsButton from './AttachmentsButton';

export default function RichTextArea({
  name = '',
  readOnly = false,
  defaultValue = '',
  maxLength = 70000,
  ...rest
}) {
  const { setDraft } = rest;
  const inputRef = useRef(null);
  const { fieldName, registerField, error } = useField(name);
  const [editorState, setEditorState] = useState(() =>
    createEditorStateWithText(defaultValue || '')
  );
  const [{ plugins, Toolbar }] = useState(() => {
    const toolbarPlugin = createToolbarPlugin();
    const linkifyPlugin = createLinkifyPlugin();
    const { Toolbar } = toolbarPlugin;
    const plugins = [toolbarPlugin, linkifyPlugin];

    return {
      plugins,
      Toolbar,
    };
  });

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        return ref.current.editor.props.editorState
          .getCurrentContent()
          .hasText()
          ? JSON.stringify(
            convertToRaw(
              ref.current.editor.props.editorState.getCurrentContent()
            )
          )
          : '';
      },
      setValue: (ref, value = '') => {
        setEditorState(createEditorStateWithText(value));
      },
      clearValue: ref => {
        setEditorState(EditorState.createEmpty());
      },
    });
  }, [fieldName, registerField]);

  const focus = e => {
    inputRef.current.focus();
  };

  const handleBeforeInput = input => {
    if (maxLength) {
      if (
        JSON.stringify(convertToRaw(editorState.getCurrentContent())).length >=
        maxLength
      ) {
        return 'handled';
      }
    }
  };

  const getCurrentTextSelection = editorState => {
    const selectionState = editorState.getSelection();
    const anchorKey = selectionState.getAnchorKey();
    const currentContent = editorState.getCurrentContent();
    const currentContentBlock = currentContent.getBlockForKey(anchorKey);
    const start = selectionState.getStartOffset();
    const end = selectionState.getEndOffset();
    const selectedText = currentContentBlock.getText().slice(start, end);

    return selectedText;
  };

  const handlePastedText = (input = '') => {
    /* impede de digitar mais caracteres que o limite do banco de dados
      já calculando o tamanho em stringfy
    */
    if (maxLength) {
      const contentState = editorState.getCurrentContent();
      const currentTextLength = JSON.stringify(
        convertToRaw(contentState)
      ).length;

      /* 
      TODO: verificar o tamanho json total apos inserir o texto, pra caso seja text formatado(maior tamanho)
      inserir o texto e calcular o tamanho e ver se é possivel
      JSON.stringify(convertToRaw(nextEditorState.getCurrentContent()))
            .length
       */

      if (currentTextLength + input.length >= maxLength) {
        const selection = editorState.getSelection();
        let nextEditorState = EditorState.createEmpty();

        if (selection.isCollapsed()) {
          const nextContentState = Modifier.insertText(
            contentState,
            selection,
            input.slice(0, maxLength - currentTextLength)
          );
          nextEditorState = EditorState.push(
            editorState,
            nextContentState,
            'insert-characters'
          );
        } else {
          const nextContentState = Modifier.replaceText(
            contentState,
            selection,
            input.slice(
              0,
              maxLength -
              currentTextLength +
              getCurrentTextSelection(editorState).length
            )
          );
          nextEditorState = EditorState.push(
            editorState,
            nextContentState,
            'insert-characters'
          );
        }

        setEditorState(nextEditorState);
        return 'handled';
      }
    }
  };

  const handleOnChange = (state) => {
    setEditorState(state);
    const draft = state.getCurrentContent().getPlainText();
    if (setDraft) {
      setDraft(draft);
    }
  }

  return editorState ? (
    <Container onMouseDown={focus}>
      {error && (
        <span
          style={{
            color: 'red',
            fontSize: '.8rem',
          }}
        >
          {error}
        </span>
      )}
      <Editor
        editorState={editorState}
        onChange={handleOnChange}
        plugins={plugins}
        ref={inputRef}
        handleBeforeInput={handleBeforeInput}
        handleReturn={handleBeforeInput}
        handlePastedText={handlePastedText}
        readOnly={readOnly}
        {...rest}
      />

      <Toolbar className='toolbar'>
        {rest => (
          <>
            <BoldButton {...rest} />
            <ItalicButton {...rest} />
            <UnderlineButton {...rest} />
            <Separator {...rest} />
            <UnorderedListButton {...rest} />
            <OrderedListButton {...rest} />
            <Separator {...rest} />
            <AttachmentsButton name='attachments' {...rest} />
          </>
        )}
      </Toolbar>
    </Container>
  ) : (
    <div>Carregando editor...</div>
  );
}
