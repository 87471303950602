import apiOld from '../Api';

const customerJourneyApi = {
  createCustomerJourney: async function (data = {}) {
    return apiOld.post('/customerJourneys', data);
  },

  updateCustomerJourney: async function (customerJourneyId, data = {}) {
    return apiOld.put(`/customerJourneys/${customerJourneyId}`, data);
  },

  getCustomerJourneyById: async function (customerJourneyId) {
    return apiOld.get(`/customerJourneys/${customerJourneyId}`);
  },

  listAllCustomerJourneys: async function (params = {}) {
    return apiOld.get('/customerJourneys', {
      params,
    });
  },

  addDemandToCustomerJourney: async function (customerJourneyId, bodyData) {
    return apiOld.post(`/customerJourneys/${customerJourneyId}/demands`, bodyData);
  },
};

export default customerJourneyApi;
