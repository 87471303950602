import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import MiniDrawer from '../../components/MiniDrawer';
import apiOld from '../../services/Api';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useParams } from 'react-router-dom';
import { openDemandModal } from '../../routes';
import DocumentService from '../../services/documentService';
import { Form } from '@unform/web';
import CustomInput from '../../components/input/CustomInput';
import FileInput from '../../components/input/FileInput';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import { formatDateFullDetails } from '../../utils';

const documentService = new DocumentService();

const useStyles = makeStyles(() => ({
  main: {
    background: '#FFF',
    padding: '10px',
    borderRadius: '10px',
    marginRight: '20px',
    'box-shadow': '0px 2px 10px 1px rgb(200 200 200)',
    '& a, button': {
      color: 'royalblue',
      border: 'none',
      backgroundColor: 'transparent',
      fontWeight: '600',
      textDecoration: 'none',
      cursor: 'pointer',
    },
    '& h3, h2': {
      fontWeight: 'normal',
    },
    '& .editButton': {
      color: 'blueviolet',
    },
    height: 'fit-content',
  },
  mainTitle: {
    background: '#FFF',
    borderBottom: 'solid 1px #e7eaec',
    paddingBottom: '5px',
    justifyContent: 'space-between',
    height: 'fit-content',
  },
  title: {
    // fontSize: '15px',
  },
  mainForm: {
    justifyContent: 'flex-start',
  },
  item: {
    padding: '0px',
    marginTop: '10px',
  },
  editButton: {
    border: 'none',
    color: '#fff',
    textDecoration: 'none',
  },
  fields: {
    listStyleType: 'none',
    marginTop: '10px',
  },
  field: {
    padding: '10px 15px',
    border: '1px solid #e7eaec',
  },
  download: {
    cursor: 'pointer',
    color: '#fff',
    borderRadius: '5px',
  },
}));

export default function DocumentProfile() {
  const { id: documentId } = useParams();
  const editDocumentFormRef = React.useRef(null);
  const classes = useStyles();
  const [state, setState] = useState({
    document: {
      id: '',
      name: '',
      customer: {},
      user: {},
      template: {},
    },
    loading: true,
    editing: false,
  });

  const fetchData = async documentId => {
    try {
      setState(prevState => ({
        ...prevState,
        loading: true,
      }));

      const documentData = (
        await apiOld.get(`/documents/${documentId}`, {
          params: {
            detailed: true,
          },
        })
      ).data;

      documentData.signedDownloadUrl = (
        await documentService.getDocumentSignedDownloadObj(documentId)
      ).signedUrl;

      setState(prevState => ({
        ...prevState,
        document: documentData,
        loading: false,
      }));
    } catch (error) {
      setState(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  useEffect(() => {
    if (documentId) fetchData(documentId);
  }, [documentId]);

  const _handleEditDocumentFormSubmit = async data => {
    try {
      setState({
        ...state,
        loading: true,
      });

      await documentService.updateDocument({ documentId, ...data });

      await fetchData(documentId);

      toast.success('Documento atualizado com sucesso!');

      setState(prevState => ({
        ...prevState,
        loading: false,
        editing: false,
      }));
    } catch (error) {
      console.error(error);
      setState({
        ...state,
        loading: false,
      });
    }
  };

  return (
    <React.Fragment>
      <MiniDrawer currentExpaded='documents'>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100vw',
            height: '100%',
            padding: '0px 20px',
          }}
        >
          <Grid
            container
            className={classes.main}
            xs={3}
            alignContent='flex-start'
          >
            <Loading open={state.loading} />

            <>
              <Grid container className={classes.mainTitle}>
                <h2 className={classes.title}>Documento</h2>
                <button
                  className={'editButton'}
                  onClick={() => {
                    setState({ ...state, editing: !state.editing });
                  }}
                  style={{
                    color: state.editing ? 'red' : 'blueviolet',
                  }}
                >
                  {state.editing ? 'Cancelar' : 'Editar'}
                </button>
              </Grid>

              <Grid
                container
                className={classes.mainForm}
                alignContent='flex-start'
              >
                <Form
                  style={{
                    width: '100%',
                  }}
                  ref={editDocumentFormRef}
                  onSubmit={_handleEditDocumentFormSubmit}
                >
                  <Grid item className={classes.item} xs={12}>
                    <h3>Nome:</h3>
                    {state.editing ? (
                      <CustomInput
                        name='name'
                        defaultValue={state.document.name}
                      />
                    ) : (
                      <p>{state.document.name}</p>
                    )}
                  </Grid>

                  {!state.editing && (
                    <>
                      <Grid item className={classes.item} xs={12}>
                        <h3>Versão:</h3>

                        <p>{state.document.version}</p>
                      </Grid>
                      <Grid item className={classes.item} xs={12}>
                        <h3>Tamanho:</h3>

                        <p>
                          {state.document.fileSize > 1000000
                            ? `${Number(
                                state.document.fileSize / 1048576
                              ).toFixed(2)} MB`
                            : `${Number(state.document.fileSize / 1024).toFixed(
                                2
                              )} KB`}
                        </p>
                      </Grid>
                      <Grid item className={classes.item} xs={12}>
                        <h3>Cliente:</h3>
                        <span>
                          <Link
                            to={`/customers/${state.document.customer.id}`}
                            style={{
                              textDecoration: 'none',
                            }}
                          >
                            {state.document.customer.name}
                          </Link>
                        </span>
                      </Grid>

                      {state.document.template ? (
                        <Grid item className={classes.item} xs={12}>
                          <h3>Template:</h3>
                          <span>{state.document.template.name}</span>
                        </Grid>
                      ) : null}
                      {state.document.demand ? (
                        <Grid item className={classes.item} xs={12}>
                          <h3>Demanda:</h3>
                          <button
                            onClick={() =>
                              openDemandModal(state.document.demand.id)
                            }
                            style={{
                              textDecoration: 'none',
                              border: 'none',
                              background: 'transparent',
                              color: 'royalblue',
                            }}
                          >
                            {state.document.demand.name}
                          </button>
                        </Grid>
                      ) : null}
                      <Grid item className={classes.item} xs={12}>
                        <h3>Criado pelo usuário:</h3>
                        {state.document.user ? (
                          <Link
                            to={`/users/${state.document.user.id}`}
                            style={{
                              textDecoration: 'none',
                            }}
                          >
                            {state.document.user.name}
                          </Link>
                        ) : (
                          <p>Usuário não existe mais</p>
                        )}
                      </Grid>

                      <Grid item className={classes.item} xs={12}>
                        <h3>Criado em:</h3>

                        <p>{formatDateFullDetails(state.document.createdAt)}</p>
                      </Grid>

                      <Grid item className={classes.item} xs={12}>
                        <h3>Atualizado pela útlima vez em:</h3>

                        <p>{formatDateFullDetails(state.document.updatedAt)}</p>
                      </Grid>
                    </>
                  )}

                  <Grid item className={classes.item} xs={12}>
                    <h3>{state.editing ? 'Novo arquivo:' : 'Arquivo'}</h3>
                    {state.editing ? (
                      <FileInput name='file' />
                    ) : (
                      <button
                        className={classes.download}
                        onClick={async () => {
                          await documentService.downloadDocumentById(
                            documentId
                          );
                        }}
                      >
                        Baixar Arquivo
                      </button>
                    )}
                  </Grid>
                  {state.editing ? (
                    <Grid
                      container
                      className={classes.item}
                      xs={12}
                      justify='flex-end'
                    >
                      <button
                        type='submit'
                        style={{
                          fontSize: '1.1rem',
                          color: '#1ab394',
                        }}
                      >
                        Salvar
                      </button>
                    </Grid>
                  ) : null}
                </Form>
              </Grid>
            </>
          </Grid>

          {state.document.signedDownloadUrl && (
            <iframe
              title={state.document.id}
              src={
                'https://docs.google.com/gview?url=' +
                encodeURIComponent(state.document.signedDownloadUrl) +
                '&embedded=true'
              }
              style={{
                width: '55%',
                height: '100%',
                border: 'none',
                boxShadow: '0px 2px 10px 1px rgb(200 200 200)',
              }}
            ></iframe>
          )}
        </div>
      </MiniDrawer>
    </React.Fragment>
  );
}
