import * as actionTypes from "../actionsTypes/configs";

export const loadConfigs = () => {
  return {
    type: actionTypes.LOAD_CONFIGS,
  };
};

export const patchDemandsBoardConfigs = (config) => {
  return {
    type: actionTypes.PATCH_DEMANDS_BOARD_CONFIG,
    config,
  };
};

export const patchDemandsBoardColumnConfigs = (columns) => {
  return {
    type: actionTypes.PATCH_DEMANDS_BOARD_COLUMN_CONFIG,
    columns,
  };
};
