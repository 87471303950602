import styled from "styled-components";

export const StatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 20px;
  overflow-y: auto;
`;

export const StatsInfo = {
  Container: styled.div`
    display: flex;
    flex-direction: row;
  `,
  Label: styled.label``,
  Content: styled.span`
    margin-left: 10px;
  `,
};
