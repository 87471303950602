import { Button, Grid, Input } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MiniDrawer from "../../components/MiniDrawer";
import CustomInput from "../../components/input/CustomInput";
import { Label } from "@material-ui/icons";
import { Form } from "@unform/web";
import apiOld from "../../services/Api";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";
import { useQuery } from "../../routes";
import CustomSelectWithSearch from "../../components/input/customSelectorWithSearch/CustomSelectWithSearch";

export default function NewMarca() {
    const [marcaProcessNumber, setMarcaProcessNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [customerId, setCustomerId] = useState('');

    const search = useQuery();


    const importMarca = async () => {
        setLoading(true);
        await apiOld.post(`/marcas/import/`, { marcaProcessNumber, customerId }).finally(() => {
            setLoading(false);
        });        
        toast.success('Marca importada com sucesso!');
    }


    useEffect(() => {
        setLoading(true);

        apiOld.get('/customers').then(response => {
            setCustomers(response.data.customers);
            setLoading(false);
        });

    }, []);

    return (
        <React.Fragment>
            <MiniDrawer
                currentExpaded='Marcas'
                main={
                    <>
                        <Loading open={loading} />
                        <Grid container >
                            <h2 >Importar marca</h2>
                        </Grid>
                        {/* form with content align to center */}
                        <Form

                        >
                            <Grid container style={{ justifyContent: 'center' }} >
                                <Grid item={true} xs={6}>
                                    <CustomInput
                                        name='processNumber'
                                        label='Numero do processo'
                                        placeholder='123456789,987654321'
                                        value={marcaProcessNumber}
                                        onChange={(e) => setMarcaProcessNumber(e.target.value)}
                                    />
                                    <CustomSelectWithSearch
                                        placeholder='Cliente'
                                        name='customerId'
                                        label='Cliente'
                                        defaultValue={search.get('customerId') || null}
                                        options={customers?.map(customer => ({
                                            name: customer.name,
                                            value: customer.id,
                                        }))}
                                        onChange={customerId => {
                                            setCustomerId(customerId);
                                        }}
                                    />
                                    <br />
                                    <Button style={{
                                        padding: '10px 15px',
                                        background: '#1ab394',
                                        border: 'none',
                                        color: '#fff',
                                        borderRadius: '5px',
                                    }}
                                        onClick={() => { importMarca() }}
                                    >Importar</Button>
                                </Grid>
                            </Grid>
                        </Form>
                    </>
                }
            />
        </React.Fragment>
    )
}