import React, { useEffect, useRef, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import CustomInput from '../../components/input/CustomInput';
import CustomSelectWithSearch from '../../components/input/customSelectorWithSearch/CustomSelectWithSearch';
import FileInputMult from '../../components/input/FileInputMult';
import Loading from '../../components/Loading';
import MiniDrawer from '../../components/MiniDrawer';
import apiOld from '../../services/Api';
import { openDemandModal, useQuery } from '../../routes';
import CustomTextArea from '../../components/input/CustomTextArea';
import { toast } from 'react-toastify';
import CheckboxInput from '../../components/input/CheckboxInput';
import customerApi from '../../services/apis/customerApi';
import { DEMAND_DESCRIPTIONS } from '../../constants/DefaultTexts';
import CustomerJourneyService from '../../services/journeys/customerJourneyService';
import DemandService from '../../services/demandService';
import history from '../../history'
import FilterBulkDemand from '../../components/demandModal/filterSendUsersDemand/filterBulkDemand';

const customerJourneyService = new CustomerJourneyService();
const demandService = new DemandService();

export const useStyles = makeStyles(() => ({
  main: {
    background: '#FFF',
  },
  mainTitle: {
    display: 'flex',
    flexDirection: 'column',
    background: '#FFF',
    borderTop: 'solid 2px #e7eaec',
    borderBottom: 'solid 1px #e7eaec',
    padding: '15px',
  },
  line: {
    backgorund: 'reds',
  },
  title: {
    fontSize: '15px',
  },
  mainForm: {
    padding: '10px',
  },
  form: {
    width: '100%',
  },
  input: {
    padding: '10px',
  },
  buttonContainer: {
    justifyContent: 'flex-end',
    padding: '10px',
  },
  submit: {
    padding: '10px 15px',
    fontSize: '17px',
    background: '#1ab394',
    border: 'none',
    color: '#fff',
    borderRadius: '5px',
  },
  subTitle: {
    background: '#FFF',
    borderBottom: 'solid 1px #e7eaec',
  },
}));

export default function CreateDemandNotification() {
  const search = useQuery();

  const classes = useStyles();
  const formRef = useRef(null);
  const [state, setState] = useState({
    demandCustomerId: search.get('customerId'),
    demandCustomerUsers: [],
    demandCustomerUserId: '',
    customerJourneys: [],
    demandPrivate: false,
    sendMail: false,
  });

  const [submitLoading, setSubmitLoading] = useState(false);
  const [content, setContent] = useState(false);
  const [contentFiltered, setContentFiltered] = useState({});
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    typeCompany: '',
    customerType: '',
    personType: '',
    addressState: '',
    addressCity: '',
    tagId: '',
  });

  useEffect(() => {
    setLoading(true);

    apiOld.get('/customers').then(response => {
      setContent(response.data);
    });
    setLoading(false);
  }, []);

  useEffect(() => {
    const queryString = new URLSearchParams(params).toString();

    apiOld.get(`/customers?${queryString}`).then(response => {
      const tagsToIgnore = ['Churn NDM', 'Churn Cliente', 'Demanda Específica'];
      const listCustomerNotTagIgnore = response.data?.customers?.filter((customer) => !customer.tags?.some(tag => tagsToIgnore.includes(tag.name)));
      setContentFiltered({customers: listCustomerNotTagIgnore, total: listCustomerNotTagIgnore.length});
    });
    console.log(params);
  }, [params]);

  const _fetchCustomerUsers = async customerId => {
    try {
      const customerUsers = (await customerApi.getCustomerUsers(customerId))
        ?.data;

      setState(prevState => ({
        ...prevState,
        demandCustomerUsers: customerUsers,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const _fetchCustomerJourneys = async customerId => {
    try {
      formRef.current?.clearField('journeyId');

      const customerJourneys = (
        await customerJourneyService.listAllCustomerJourneys({
          customerId,
          withJourneyType: true,
        })
      )?.data.customerJourneys;

      setState(prevState => ({
        ...prevState,
        customerJourneys: customerJourneys,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (state.demandCustomerId) {
      _fetchCustomerUsers(state.demandCustomerId);
      _fetchCustomerJourneys(state.demandCustomerId);
    }
  }, [state.demandCustomerId]);

  async function handleSubmit(data, { reset }) {
    try {
      const dataComplet = { ...data, ...params };
      setSubmitLoading(true);
      formRef.current.setErrors({});

      await demandService.sendDemand(dataComplet);

      toast.success('Demanda enviada com sucesso!');

      reset();
      history.push('/')
      // openDemandModal(sendnewDemand.id);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};

        error.inner.forEach(error => {
          validationErrors[error.path] = error.message;

          toast.error(error.message, {
            toastId: 'validationError',
          });
        });

        formRef.current.setErrors(validationErrors);
      }
    } finally {
      setSubmitLoading(false);
    }
  }

  return (
    <React.Fragment>
      <MiniDrawer
        currentExpaded='demands'
        main={
          <Grid container className={classes.main}>
            <Loading open={loading} />
            {content && (
              <>
                <Grid container className={classes.mainTitle}>
                  <h2 className={classes.title}>Enviar Demanda em Massa</h2>
                  <span>Aqui você pode enviar uma demanda para todos os usuários</span>
                </Grid>
                <Grid container className={classes.mainForm}>
                  <Form
                    ref={formRef}
                    onSubmit={handleSubmit}
                    className={classes.form}
                    initialData={{
                      name: search.get('name'),
                      description: search.has('descriptionKey')
                        ? DEMAND_DESCRIPTIONS[search.get('descriptionKey')]
                        : '',
                    }}
                  >
                    <Grid container>
                      <Grid item={true} className={classes.input} xs={12}>
                        <CustomInput name='name' label='Nome*' />
                      </Grid>

                      <Grid item={true} className={classes.input} xs={12}>
                        <CustomTextArea
                          name='description'
                          label='Descrição'
                          placeholder='Descrição detalhada'
                          maxLength='2048'
                          resize={search.has('descriptionKey')}
                        />
                      </Grid>
                      {/* 
                      <Grid item={true} className={classes.input} xs={6}>
                        <CustomSelectWithSearch
                          placeholder='Cliente'
                          name='customerId'
                          label='Cliente'
                          defaultValue={search.get('customerId') || null}
                          options={content.customers?.map(customer => ({
                            name: customer.name,
                            value: customer.id,
                          }))}
                          onChange={customerId => {
                            formRef.current.clearField('demandUserId');
                            setState({
                              ...state,
                              demandCustomerId: customerId,
                            });
                          }}
                        />
                      </Grid> */}

                      <Grid item={true} className={classes.input} xs={6}>
                        <FileInputMult name='attachments' label='Anexos' />
                      </Grid>

                      <Grid container={true} className={classes.input}>
                        {state.demandCustomerId && (
                          <>
                            <Grid item xs={6} className='inputContainer'>
                              <CustomSelectWithSearch
                                placeholder='Jornada'
                                name='journeyId'
                                label='Adicionar à jornada'
                                options={state.customerJourneys?.map(
                                  customerJourney => ({
                                    name: customerJourney.journeyType.title,
                                    value: customerJourney.id,
                                  })
                                )}
                              />
                            </Grid>

                            <Grid item={true} className={classes.input} xs={6}>
                              <CustomSelectWithSearch
                                placeholder='Usuário'
                                name='demandUserId'
                                label='Usuário do cliente'
                                options={state.demandCustomerUsers?.map(
                                  user => ({
                                    name: user.name,
                                    value: user.id,
                                  })
                                )}
                                onChange={demandUserId => {
                                  formRef.current.clearField(
                                    'demandUserFollowingIds'
                                  );
                                  setState({
                                    ...state,
                                    demandCustomerUserId: demandUserId,
                                  });
                                }}
                              />
                            </Grid>
                            {state.demandCustomerUserId &&
                              !state.demandPrivate && (
                                <Grid
                                  item={true}
                                  className={classes.input}
                                  xs={6}
                                >
                                  <CustomSelectWithSearch
                                    multiple={true}
                                    placeholder='Incluir usuários notificáveis'
                                    name='demandUserFollowingIds'
                                    printOptions='on-focus'
                                    label='Usuários notificáveis (além do usuário da demanda)'
                                    options={state.demandCustomerUsers
                                      ?.filter(
                                        user =>
                                          user.id !== state.demandCustomerUserId
                                      )
                                      .map(user => ({
                                        name: user.name,
                                        value: user.id,
                                      }))}
                                  />
                                </Grid>
                              )}
                          </>
                        )}
                      </Grid>

                      <Grid item={true} className={classes.input} xs={6}>
                        <CheckboxInput
                          name='sendMail'
                          label='Enviar e-mail'
                          onChange={e => {
                            setState({
                              ...state,
                              sendMail: e.target.checked,
                            });
                          }}
                        />
                      </Grid>
                      <Grid container className={classes.buttonContainer}>
                        <button
                          className={classes.submit}
                          type='button'
                          style={{
                            background: 'rgba(220,220,220, 1)',
                            color: '#333',
                            fontSize: '0.8rem',
                            marginRight: '1rem',
                          }}
                          onClick={() => {
                            window.location.reload();
                          }}
                        >Limpar Filtros</button>
                        <button
                          className={classes.submit}
                          disabled
                          style={{
                            background: 'rgba(220,220,220, 1)',
                            color: '#333',
                            fontSize: '0.8rem',
                          }}>Total de envios: {contentFiltered?.total}</button>
                      </Grid>
                      <FilterBulkDemand
                        content={content}
                        contentFiltered={contentFiltered}
                        params={params}
                        setParams={setParams} />
                      <Grid container className={classes.buttonContainer}>
                        <button className={classes.submit} type='submit'>
                          {!submitLoading && 'Enviar'}
                          {submitLoading && (
                            <CircularProgress color='inherit' />
                          )}
                        </button>
                      </Grid>
                    </Grid>
                  </Form>
                </Grid>
              </>
            )}
          </Grid>
        }
      />
    </React.Fragment>
  );
}
