import { combineReducers } from "redux";
import {
  RECIEVE_DEMANDS,
  PATCH_DEMAND,
  CREATE_DEMAND,
  DELETE_DEMAND,
} from "../actionsTypes/demandActionTypes";
import produce from "immer";

const ALL_INITIAL_STATE = [];
const BY_ID_INITIAL_STATE = {};

const all = produce((draft = ALL_INITIAL_STATE, action) => {
  switch (action.type) {
    case RECIEVE_DEMANDS: {
      draft = action.demands;
      return draft;
    }
    case PATCH_DEMAND: {
      return draft.map((demand) => {
        if (+demand.id === +action.demand.id) {
          return { ...demand, ...action.demand };
        }
        return demand;
      });
    }
    case CREATE_DEMAND: {
      draft.push(action.demand);
      break;
    }
    case DELETE_DEMAND: {
      return draft.filter((demand) => +demand.id !== +action.demandId);
    }
    default:
      return draft;
  }
}, ALL_INITIAL_STATE);

const byId = produce((draft, action) => {
  switch (action.type) {
    case RECIEVE_DEMANDS:
      action.demands.forEach((demand) => {
        draft[demand.id] = demand;
      });
      break;
    case PATCH_DEMAND:
      draft[action.demand.id] = {
        ...draft[action.demand.id],
        ...action.demand,
      };
      break;
    case CREATE_DEMAND: {
      draft[action.demand.id] = action.demand;
      break;
    }
    case DELETE_DEMAND: {
      delete draft[action.demandId];
      break;
    }
    default:
      return draft;
  }
}, BY_ID_INITIAL_STATE);

export default combineReducers({
  byId,
  all,
});

export const getDemand = (state, id) => state.byId[id];
