import CustomerSale from '../../../domains/entities/customerSale/CustomerSale';
import customerSaleApi from '../../apis/customerSaleApi';
import * as Yup from 'yup';

export default class CustomerSaleService {
  static async registerCustomerSale(saleData) {
    const customerSale = new CustomerSale(saleData);

    return (
      await customerSaleApi.registerSale(
        customerSale.customerId,
        customerSale.getRegisterSaleData()
      )
    ).data;
  }

  static validateSaleData(saleData) {
    const schema = Yup.object().shape({
      contractType: Yup.string()
        .nullable()
        .required()
        .label('Tipo de contrato'),
      saleDescription: Yup.string().nullable().required().label('Descrição'),
      saleDate: Yup.date().nullable().required().label('Data da venda'),
      customerId: Yup.string().nullable().required().label('Cliente'),
    });

    return schema.validate(saleData, {
      abortEarly: false,
    });
  }

  static async getBackofficeCustomerSalesTable(query) {
    return (await customerSaleApi.getBackofficeCustomerSalesTable(query)).data;
  }
}
