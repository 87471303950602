import styled from 'styled-components';

export const PageContainer = styled.main`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-top: 'solid 2px #e7eaec';
  height: 100%;
  padding: 20px 10px;
  overflow-x: auto;
  box-shadow: 0px 1px 3px 0px rgb(220 220 220);

  h1 {
    font-size: 18px;
  }
  h2 {
    font-size: 16px;
  }
  h3 {
    font-size: 14px;
  }

  .titleContainer {
    border-bottom: solid 1px #e7eaec;
    padding: 10px;
  }

  .inputContainer,
  .MuiGrid-container:not(:first-of-type) {
    padding: 5px 10px;
  }

  .formContainer {
    width: 100%;
  }

  .button,
  .submit {
    padding: 8px 10px;
    font-size: 13px;
    background: #1ab394;
    border: none;
    color: #fff;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
  }

  .secondaryButton {
    background-color: #2f4050;
  }

  .buttonContainer {
    margin: 5px 10px;
  }

  .buttonLink {
    text-decoration: none;
    color: blueviolet;
  }
  .Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    button{
      margin: 10px;
    }
  }
  .upload{
    margin: 20px;
    padding: 8px 10px;
    font-size: 13px;
    border: none;
    color: #fff;
    background: #2f4050;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
  }
`;
