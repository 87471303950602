import moment from 'moment';
import { Constants } from '../utils/Constants';

const handleFields = fields => {
  var newFields = [];

  for (const field of fields) {
    newFields.push({ key: field, label: removeKeyCaracters(field) });
  }

  return newFields;
};

export const removeKeyCaracters = field => {
  return field.substring(1, field.length - 1).replace(/_/g, ' ');
};
export const handleAndRemoveTemplateFields = (fields, formAnswersFields) => {
  if (formAnswersFields)
    return handleFields(fields).filter(
      field =>
        !Constants.REMOVE_TEMPLATE_FIELDS.includes(field.key) &&
        !formAnswersFields.some(
          formAnswersField => formAnswersField.key === field.key
        )
    );
  return handleFields(fields).filter(
    field => !Constants.REMOVE_TEMPLATE_FIELDS.includes(field.key)
  );
};

export const isHoliday = date => {
  return Constants.HOLIDAYS[date.month()][date.date() - 1];
};

export const addWorkingDays = (
  date,
  daysQuantity,
  { checkWorkShift = false } = {}
) => {
  let result = moment(date);

  while (daysQuantity > 0) {
    result = result.add(1, 'days');
    if (
      result.isoWeekday() !== 6 &&
      result.isoWeekday() !== 7 &&
      !isHoliday(result)
    ) {
      daysQuantity -= 1;
    }
  }

  if (checkWorkShift) {
    // add one more day if actual time isnt in the work shift or its an weekend
    const now = moment();
    const isWeekend = now.isoWeekday() === 6 || now.isoWeekday() === 7;
    const isAfter18hrs = now.hours() >= 18;

    if (isWeekend || isAfter18hrs) result = addWorkingDays(result, 1);
  }

  return result;
};

export const subtractWorkingDays = (date, days) => {
  date = moment(date);

  while (days > 0) {
    date = date.subtract(1, 'days');
    if (
      date.isoWeekday() !== 6 &&
      date.isoWeekday() !== 7 &&
      !isHoliday(date)
    ) {
      days -= 1;
    }
  }

  return date;
};
