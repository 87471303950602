import React, { useState, useRef } from "react";
import Editor from "@draft-js-plugins/editor";
import createLinkifyPlugin from "@draft-js-plugins/linkify";
import { CompositeDecorator, convertFromRaw, EditorState } from "draft-js";

const linkifyPlugin = createLinkifyPlugin({
  target: "_blank",
});
const plugins = [linkifyPlugin];
const [decorators] = plugins.map((plugin) => plugin.decorators);
const decorator = new CompositeDecorator(decorators);

export default function ReadOnlyTextArea({ defaultValue, ...rest }) {
  const inputRef = useRef(null);
  const [editorState, setEditorState] = useState(() => {
    return EditorState.createWithContent(
      convertFromRaw(defaultValue),
      decorator
    );
  });

  return editorState ? (
    <Editor
      editorState={editorState}
      onChange={(state) => setEditorState(state)}
      plugins={plugins}
      ref={inputRef}
      readOnly={true}
      {...rest}
    />
  ) : (
    <div>Carregando editor...</div>
  );
}
