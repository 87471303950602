import React, { useEffect, useRef } from "react"
import DataTable from "react-data-table-component"


import { useParams } from "react-router-dom"
import businessApi from "../../services/apis/businessApi"
import customerApi from "../../services/apis/customerApi"
import MiniDrawer from "../../components/MiniDrawer"
import CustomInput from "../../components/input/CustomInput"
import { Form } from "@unform/web"
import { toast } from "react-toastify"

const UsersToBusiness = () => {
    const [users, setUsers] = React.useState([]);
    const [businessInUse, setBusinessInUse] = React.useState(null);
    const formRef = useRef(null);


    const businesId = useParams().id;

    const loadBusinessInUse = () => {
        return businessApi.getByBusinessId(businesId).then(result => {
            setBusinessInUse(result.data);
            return result.data;
        });
    }

    useEffect(() => {
        loadBusinessInUse().then((data) => {
            customerApi.getCustomerUsers(data.customerId).then(({data}) => {
                setUsers(data);
            });
        }
        );
    }, [businesId]);
    


    const columns = [
        {
            name: 'Nome',
            selector: 'name',
            sortable: false,
        },
        {
            name: 'Ações',
            selector: 'actions',
            sortable: false,
        }
    ]


    const treatedBusiness = users.map((user) => {
        const userAlreadyAssociated = businessInUse.Users?.find((item) => item.id === user.id);
        const ToggleAssociateButton = () => {

            if (userAlreadyAssociated) {
                return (
                    <button onClick={() => {
                        businessApi.dessasociateBusiness({
                            businessId: businessInUse.id,
                            userId: user.id
                        }).then(() => {
                            loadBusinessInUse();
                        })
                        
                    
                    }} type="button">
                        Excluir do negocio
                    </button>
                )
            }else{
                return (
                    <button onClick={() => {
                        businessApi.associateBusiness({
                            businessId: businessInUse.id,
                            userId: user.id
                        }).then(() => {
                            loadBusinessInUse();
                        })
                    }} type="button">
                        Adicionar ao negocio
                    </button>
                )
            }
        }
        return {
            ...user,
            actions: (
                <div style={{ display: 'inline-flex' }}>
                    <ToggleAssociateButton />
                </div>
            )
        }
    })

    const updateBusiness = (data) => {
        businessApi.handleUpdateBusiness(businessInUse).then(() => {
            toast.success('Negocio atualizado com sucesso')
            loadBusinessInUse();
        }).catch((err) => {
            toast.error('Erro ao atualizar o negocio')
        });
    }


    return (
        <React.Fragment>
        <MiniDrawer
          currentExpaded='customers'
          main={
            <>
                <h1>Ajuste a quantidade de SLAdo negocio {businessInUse?.name}</h1>
                <br></br>
                <h5>Digite zero para SLA compartilhado</h5>
                <br />
                <Form
                    ref={formRef}
                    onSubmit={updateBusiness}
                >
                    <CustomInput
                        name='numberOfSlas'
                        label='Quantidade de SLAs'
                        type='number'
                        value={businessInUse?.numberOfSlas}
                        onChange={(e) => {
                            setBusinessInUse({
                                ...businessInUse,
                                numberOfSlas: Number(e.target.value)
                            })
                        }}

                    />

                    <button type='submit'>
                      Salvar
                    </button>
                </Form>

                <h1>Ajuste as permissões do usuário</h1>
                <br></br>
                <br />
                <DataTable
                    columns={columns}
                    data={treatedBusiness}
                />
            </>

            }
        />
        </React.Fragment>
    )
}



export default UsersToBusiness