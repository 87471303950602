import journeyTypeDefaultDemandApi from '../apis/journeyTypeDefaultDemandApi';

export default class JourneyTypeDefaultDemandService {
  async createJourneyTypeDefaultDemand(data) {
    return await journeyTypeDefaultDemandApi.createJourneyTypeDefaultDemand(
      data
    );
  }

  async updateJourneyTypeDefaultDemand(defaultDemandId, data) {
    return await journeyTypeDefaultDemandApi.updateJourneyTypeDefaultDemand(
      defaultDemandId,
      data
    );
  }

  async getJourneyTypeDefaultDemandById(defaultDemandId) {
    return (
      await journeyTypeDefaultDemandApi.getJourneyTypeDefaultDemandById(
        defaultDemandId
      )
    ).data;
  }

  async deleteJourneyTypeDefaultDemandById(defaultDemandId) {
    return (
      await journeyTypeDefaultDemandApi.deleteJourneyTypeDefaultDemandById(
        defaultDemandId
      )
    ).data;
  }

  async listAllJourneyTypeDefaultDemandsByJourneyTypeId(journeyTypeId) {
    return await journeyTypeDefaultDemandApi.listAllJourneyTypeDefaultDemands({
      journeyTypeId,
    });
  }

  async listAllJourneyTypeDefaultDemands(queryParams) {
    return await journeyTypeDefaultDemandApi.listAllJourneyTypeDefaultDemands(
      queryParams
    );
  }
}
