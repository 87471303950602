import React, { useEffect, useState, useCallback, useRef } from 'react';
import moment from 'moment';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import Loading from '../../../components/Loading';
import MiniDrawer from '../../../components/MiniDrawer';
import csApi from '../../../services/apis/csApi';
import DefaultTags from '../../../constants/DefaultTags';
import {
  Container,
  Title,
  Section,
  SectionTitle,
  SectionContent,
  CustomerListsContainer,
  CustomerListContent,
  SimpleTable,
  ContainerInput,
} from '../Dashboard.styles';
import CustomSelectWithSearch from '../../../components/input/customSelectorWithSearch/CustomSelectWithSearch';
import { ActiveContactCustomerTable } from '../../../components/Tables/ActiveContactCustomerTable';
import { useSelector, connect } from 'react-redux';
import { Constants } from '../../../utils/Constants';
import demandsApi from '../../../services/apis/demandsApi';
import customerApi from '../../../services/apis/customerApi';
import { PriorityDemands } from '../../../components/Tables/PriorityDemands';
import { getActiveContacts, confirmFollowUp } from '../../../redux/actions/ActiveContact'


function ActiveContactDashboard(props) {
  useEffect(() => {
    props.getActiveContacts()
  }, []);
  const backofficeUserIdRef = useRef(null);
  const backofficeUsers = useSelector(state => state.backofficeUsers.all);
  const [state, setState] = useState({
    customers: null,
    metrics: null,
    userMetrics: false
  });

  const [loading, setLoading] = useState(false);

  const isCustomerSpecificDemand = tags =>
    tags?.some(tag => tag.name === DefaultTags.SPECIFC_DEMAND);

  const getCustomerContactDiff = nextActiveContactDate => {
    if (!nextActiveContactDate) return false;
    return moment(nextActiveContactDate).diff(moment(), 'days');
  };

  const filterChurn = (dataDemand, searchString, isObj = false) => {
    if (!isObj) {
      return dataDemand?.some(item => item.name.toLowerCase().includes(searchString));
    }
    else {
      return dataDemand.filter(demands => {
        return !demands?.customer?.tags?.some(item =>
          item.name.toLowerCase().includes(searchString))

      }).length
    }
  }

  const _formatCustomers = useCallback(customers => {
    return {
      timeToContact: customers.filter(
        customer =>
          customer.ActiveContact?.nextActiveContactDate &&
          getCustomerContactDiff(
            customer.ActiveContact?.nextActiveContactDate
          ) <= Constants.TIME_TO_CONTACT_DAYS &&
          !isCustomerSpecificDemand(customer.tags)
      ),
      notInTimeToContact: customers.filter(
        customer =>
          customer.ActiveContact?.nextActiveContactDate &&
          getCustomerContactDiff(
            customer.ActiveContact?.nextActiveContactDate
          ) > Constants.TIME_TO_CONTACT_DAYS &&
          !isCustomerSpecificDemand(customer.tags)
      ),
    };
  }, []);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);

      const data = (await csApi.getActiveContactsDashboard())?.data;

      setState(state => ({
        ...state,
        activeContactCustomers: _formatCustomers(data.activeContactCustomers),
        metrics: data.contactTimeMetrics
      }));

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [_formatCustomers]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSubmit = useCallback(
    async data => {
      try {
        backofficeUserIdRef.current.setErrors({});

        const schema = Yup.object().shape({
          backofficeUserId: Yup.string()
            .label('Advogado')
            .required()
            .nullable(true),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);

        const request = {
          backofficeUserId: data.backofficeUserId,
        };
        const userMetrics =
          request.backofficeUserId !== 'G' &&
          (await csApi.getMetricByBackofficeUserId(request.backofficeUserId))
            .data;
        setState(prevState => ({
          ...prevState,
          userMetrics,
        }));
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const validationErrors = {};

          error.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });

          return backofficeUserIdRef.current.setErrors(validationErrors);
        }

        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [backofficeUserIdRef]
  );

  return (
    <React.Fragment>
      <MiniDrawer
        currentExpaded='cs'
        main={
          <Container>
            <Loading open={loading} />

            <Title>Contato Ativo</Title>

            <Section>
              <SectionTitle>
                Métricas de Contato Ativo (hora de contactar)
              </SectionTitle>
              <SectionContent>
                {backofficeUsers && (
                  <Form ref={backofficeUserIdRef} onSubmit={handleSubmit}>
                    <ContainerInput>
                      <CustomSelectWithSearch
                        placeholder='Advogado'
                        name='backofficeUserId'
                        label='Advogado'
                        options={[{ name: 'Geral', value: 'G' }].concat(
                          backofficeUsers?.map(users => ({
                            name: users.name,
                            value: users.id,
                          }))
                        )}
                        defaultValue={'G'}
                        onChange={() => {
                          backofficeUserIdRef.current.submitForm();
                        }}
                      />
                    </ContainerInput>
                  </Form>
                )}
                <CustomerListsContainer>
                  <CustomerListContent>
                    {state.metrics && (
                      <SimpleTable.Container>
                        <SimpleTable.Table>
                          <SimpleTable.TableHead>
                            <SimpleTable.TableRow>
                              <SimpleTable.TableHeadCell
                                style={{
                                  width: '25%',
                                }}
                              >
                                Contato Ativo nos
                              </SimpleTable.TableHeadCell>

                              <SimpleTable.TableHeadCell
                                style={{
                                  width: '25%',
                                  textAlign: 'center',
                                }}
                              >
                                Aguardando contato
                              </SimpleTable.TableHeadCell>

                              <SimpleTable.TableHeadCell
                                style={{
                                  width: '25%',
                                  textAlign: 'center',
                                }}
                              >
                                Reunião realizada
                              </SimpleTable.TableHeadCell>

                              <SimpleTable.TableHeadCell
                                style={{
                                  width: '25%',
                                  textAlign: 'center',
                                }}
                              >
                                Percentual de reuniões realizadas
                              </SimpleTable.TableHeadCell>
                            </SimpleTable.TableRow>
                          </SimpleTable.TableHead>

                          <SimpleTable.TableBody>
                            {[
                              {
                                period: 'Últimos 7 dias',
                                in: state.userMetrics
                                  ? state.userMetrics.contactTimeLastWeek?.in
                                  : state.metrics.contactTimeLastWeek?.in,
                                out: state.userMetrics
                                  ? state.userMetrics.contactTimeLastWeek?.out
                                  : state.metrics.contactTimeLastWeek?.out,
                              },
                              {
                                period: 'Últimos 30 dias',
                                in: state.userMetrics
                                  ? state.userMetrics.contactTimeLastMonth?.in
                                  : state.metrics.contactTimeLastMonth?.in,
                                out: state.userMetrics
                                  ? state.userMetrics.contactTimeLastMonth?.out
                                  : state.metrics.contactTimeLastMonth?.out,
                              },
                              {
                                period: 'Últimos 90 dias',
                                in: state.userMetrics
                                  ? state.userMetrics.contactTimeLast3Months?.in
                                  : state.metrics.contactTimeLast3Months?.in,
                                out: state.userMetrics
                                  ? state.userMetrics.contactTimeLast3Months
                                    ?.out
                                  : state.metrics.contactTimeLast3Months?.out,
                              },
                            ].map(statistics => (
                              <SimpleTable.TableRow key={statistics.period}>
                                <SimpleTable.TableData>
                                  {statistics.period}
                                </SimpleTable.TableData>

                                <SimpleTable.TableData
                                  style={{
                                    textAlign: 'center',
                                    color: '#ea5f59',
                                  }}
                                >
                                  {statistics.in}
                                </SimpleTable.TableData>

                                <SimpleTable.TableData
                                  style={{
                                    textAlign: 'center',
                                    color: '#1ab394',
                                  }}
                                >
                                  {statistics.out}
                                </SimpleTable.TableData>

                                <SimpleTable.TableData
                                  style={{
                                    textAlign: 'center',
                                    color: '#1994FF',
                                  }}
                                >
                                  {statistics?.in > 0
                                    ? `${Number(
                                      (statistics?.out * 100) / statistics?.in
                                    ).toFixed(2)} %`
                                    : 'Indefinido'}
                                </SimpleTable.TableData>
                              </SimpleTable.TableRow>
                            ))}
                          </SimpleTable.TableBody>
                        </SimpleTable.Table>
                      </SimpleTable.Container>
                    )}
                  </CustomerListContent>
                </CustomerListsContainer>
              </SectionContent>
            </Section>

            <Section>
              <SectionTitle>Status do contato</SectionTitle>

              <SectionContent>
                <CustomerListsContainer>
                  <CustomerListContent>
                    <h3>Hora de contactar 🕗</h3>

                    <h2>
                      Total:{' '}
                      {state.activeContactCustomers?.timeToContact.length}
                    </h2>

                    <ActiveContactCustomerTable
                      customers={state.activeContactCustomers?.timeToContact}
                      fetchData={fetchData}
                    />
                  </CustomerListContent>
                </CustomerListsContainer>
                <CustomerListsContainer>
                  <CustomerListContent>
                    <h3>Fora da hora contactar ⌛</h3>

                    <h2>
                      Total:{' '}
                      {state.activeContactCustomers?.notInTimeToContact.length}
                    </h2>

                    <ActiveContactCustomerTable
                      customers={
                        state.activeContactCustomers?.notInTimeToContact
                      }
                      fetchData={fetchData}
                    />
                  </CustomerListContent>
                </CustomerListsContainer>
                <CustomerListsContainer>
                  <CustomerListContent>
                    <h3>Demandas de valor</h3>

                    <h2>
                      Total:{' '}
                      {
                        filterChurn(props.valueDemands, 'churn', true)
                      }
                    </h2>
                    <PriorityDemands
                      fetchedData={props.valueDemands}
                      confirmAction={props.confirmFollowUp}
                      filterChurn={filterChurn}
                    />
                  </CustomerListContent>
                </CustomerListsContainer>
              </SectionContent>
            </Section>
          </Container>
        }
      />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return { valueDemands: state.ActiveContact.valueDemands }
}

const mapDispatchToProps = {
  getActiveContacts, confirmFollowUp
}

export default connect(mapStateToProps, mapDispatchToProps)(ActiveContactDashboard)