import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999;
  opacity: 1;
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  height: 85%;
  position: relative;
  padding: 30px;
  background: #f5f6fa;
  border-radius: 15px;

  h1 {
    height: 40px;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 40px);
    align-items: center;
  }
`;

export const ContainerInput = styled.div`
  width: 100%;
  margin-top: 15px;
`;

export const MessageContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 15px;
  height: calc(100% - 120px);
  min-height: calc(100% - 120px);
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: center;
  height: 40px;
`;

export const SubmitButton = styled.button`
  font-size: 20px;
  color: #fff;
  border-radius: 5px;
  border: none;
  padding: 10px 40px;
  background-color: #1ab394;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.09);
`;
export const ExitButton = styled(SubmitButton)`
  background-color: #a0a0a0;
  margin-left: 15px;
`;
