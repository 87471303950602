import React, { useRef, useState } from 'react';
import {
  Container,
  ButtonConfirm,
  ButtonClose,
  ResponseContainer,
  ResponseSectionContainer,
} from './SearchModal.styles';
import Modal from '../modal/Modal';
import { Form } from '@unform/web';
import CustomInput from '../input/CustomInput';
import searchApi from '../../services/apis/searchApi';
import { openDemandModal } from '../../routes';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';

export default function SearchModal(props) {
  const { open, handleCloseModal } = props;
  const searchFormRef = useRef(false);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({});

  const handleSearchFormSubmit = async data => {
    try {
      setLoading(true);

      searchFormRef.current.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().label('Nome').required().nullable(true),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const responseData = (await searchApi.search(data))?.data;

      setResult(responseData);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};

        error.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });

        searchFormRef.current.setErrors(validationErrors);
      }
    } finally {
      setLoading(false);
    }
  };

  return open ? (
    <Modal visible={open}>
      <Container>
        <Form ref={searchFormRef} onSubmit={handleSearchFormSubmit}>
          <CustomInput
            autoFocus='true'
            type='text'
            name='name'
            label='Cliente, demanda'
          ></CustomInput>

          <ButtonConfirm type='submit' disabled={loading}>
            Procurar
          </ButtonConfirm>

          <ButtonClose
            className='fa fa-lg fa-times'
            onClick={handleCloseModal}
          ></ButtonClose>
        </Form>

        <ResponseContainer>
          {result && result.customers && (
            <ResponseSectionContainer>
              <h2>Clientes</h2>

              <ul>
                {result.customers.map(customer => (
                  <li key={customer.id}>
                    <Link to={`/customers/${customer.id}`}>
                      {customer.name}
                    </Link>
                  </li>
                ))}
              </ul>

              {result.customers.length === 0 && <span>Nada encontrado!</span>}
            </ResponseSectionContainer>
          )}

          {result && result.demands && (
            <ResponseSectionContainer>
              <h2>Demandas</h2>

              <ul>
                {result.demands.map(demand => (
                  <li key={demand.id}>
                    <button
                      onClick={() => {
                        handleCloseModal();
                        openDemandModal(demand.id);
                      }}
                    >
                      {demand.name}
                    </button>
                  </li>
                ))}
                {result.demands.length === 0 && <span>Nada encontrado!</span>}
              </ul>
            </ResponseSectionContainer>
          )}

          {/* <ResponseSectionContainer>
            <h2>Documentos</h2>
          </ResponseSectionContainer> */}
        </ResponseContainer>
      </Container>
    </Modal>
  ) : null;
}
