import React, { useRef, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Form } from "@unform/web";
import { toast } from "react-toastify";
import * as Yup from "yup";
import CustomInput from "../../components/input/CustomInput";
import MiniDrawer from "../../components/MiniDrawer";
import tagsApi from "../../services/apis/tagsApi";

const useStyles = makeStyles(() => ({
  main: {
    background: "#FFF",
  },
  mainTitle: {
    background: "#FFF",
    borderTop: "solid 2px #e7eaec",
    borderBottom: "solid 1px #e7eaec",
    padding: "15px",
  },
  title: {
    fontSize: "15px",
  },
  mainForm: {
    padding: "10px",
  },
  form: {
    width: "100%",
  },
  input: {
    padding: "10px",
  },
  buttonContainer: {
    justifyContent: "flex-end",
    padding: "10px",
  },
  submit: {
    padding: "10px 15px",
    fontSize: "17px",
    background: "#1ab394",
    border: "none",
    color: "#fff",
    borderRadius: "5px",
  },
  subTitle: {
    background: "#FFF",
    borderBottom: "solid 1px #e7eaec",
  },
}));

export default function CreateDemandType() {
  const classes = useStyles();
  const createTagFormRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleCreateTagFormSubmit = async (data, { reset }) => {
    try {
      setSubmitLoading(true);
      createTagFormRef.current.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().label("Nome").required(),
        description: Yup.string().label("Descrição"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await tagsApi.create(data);

      toast.success("Tag criada com sucesso!");

      reset();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};

        error.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        createTagFormRef.current.setErrors(validationErrors);

        return;
      }

      console.error(error);
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <React.Fragment>
      <MiniDrawer
        currentExpaded="tags"
        main={
          <Grid container className={classes.main}>
            <Grid container className={classes.mainTitle}>
              <h2 className={classes.title}>Criar Tag</h2>
            </Grid>
            <Grid container className={classes.mainForm}>
              <Form
                ref={createTagFormRef}
                onSubmit={handleCreateTagFormSubmit}
                className={classes.form}
              >
                <Grid container>
                  <Grid item className={classes.input} xs={6}>
                    <CustomInput name="name" label="Nome*" />
                  </Grid>
                  <Grid item className={classes.input} xs={6}>
                    <CustomInput name="description" label="Descrição" />
                  </Grid>

                  <Grid container className={classes.buttonContainer}>
                    <button className={classes.submit} type="submit">
                      {!submitLoading && "Criar"}
                      {submitLoading && <CircularProgress color="inherit" />}
                    </button>
                  </Grid>
                </Grid>
              </Form>
            </Grid>
          </Grid>
        }
      />
    </React.Fragment>
  );
}
