import { Constants } from '../utils/Constants';
import { preventWindowCloseOnUpload } from '../utils';
import FileService from './fileService';
import * as Yup from 'yup';
import demandsApi from './apis/demandsApi';

export default class DemandService {
  constructor() {
    this.fileService = new FileService();
  }

  async createDemand(data) {
    const schema = Yup.object().shape({
      name: Yup.string().label('Nome').required(),
      description: Yup.string().label('Descrição').required(),
      businessId: Yup.string().label('Negócio').required(),
      customerId: Yup.string().label('Cliente').required().nullable(true),
      privateDemand: Yup.boolean().label('Sigilosa'),
      demandUserId: Yup.string()
        .label('Usuário do cliente responsável')
        .nullable(true)
        .required(),
      demandUserFollowingIds: Yup.array(Yup.string())
        .label('Usuários notificáveis além do usuário da demanda')
        .nullable(true),
    });

    await schema.validate(data, {
      abortEarly: false,
    });

    window.addEventListener('beforeunload', preventWindowCloseOnUpload);

    const formatedAttachments =
      await this.fileService.uploadFilesAndFormatToDocumentStructure({
        files: data.attachments,
        documentType: Constants.FILE_TYPES.DOCUMENT,
      });

    const requestBody = {
      ...data,
      private: data.privateDemand,
      following: [
        data.demandUserId,
        ...((!data.privateDemand ? data.demandUserFollowingIds : []) || []),
      ],
      attachments: formatedAttachments,
    };

    const newDemand = (await demandsApi.createDemand(requestBody)).data;

    window.removeEventListener('beforeunload', preventWindowCloseOnUpload);

    return newDemand;
  }

  async sendDemand(data) {
    const schema = Yup.object().shape({
      name: Yup.string().label('Nome').required(),
      description: Yup.string().label('Descrição').required(),
      sendMail: Yup.string().label('Enviar e-mail').required().nullable(true),
    });

    await schema.validate(data, {
      abortEarly: false,
    });

    window.addEventListener('beforeunload', preventWindowCloseOnUpload);

    const formatedAttachments =
      await this.fileService.uploadFilesAndFormatToDocumentStructure({
        files: data.attachments,
        documentType: Constants.FILE_TYPES.DOCUMENT,
      });

    const requestBody = {
      ...data,
      sendMail: data.sendMail,
      attachments: formatedAttachments,
    };

    const sendDemand = (await demandsApi.sendDemand(requestBody)).data;

    window.removeEventListener('beforeunload', preventWindowCloseOnUpload);

    return sendDemand;
  }

  async updatedemandStatus({ demandId, statusKey, message, attachments }) {
    const schema = Yup.object().shape({
      statusKey: Yup.string().required(),
      message: Yup.string().nullable(true),
    });

    await schema.validate(
      {
        statusKey,
        message,
      },
      {
        abortEarly: false,
      }
    );

    window.addEventListener('beforeunload', preventWindowCloseOnUpload);

    const formatedAttachments =
      await this.fileService.uploadFilesAndFormatToDocumentStructure({
        files: attachments,
        documentType: Constants.FILE_TYPES.DOCUMENT,
      });

    const requestBody = {
      statusKey,
      message,
      attachments: formatedAttachments,
    };

    const updatedDemand = await demandsApi.updateDemandStatus(
      demandId,
      requestBody
    );

    window.removeEventListener('beforeunload', preventWindowCloseOnUpload);

    return updatedDemand.data;
  }

  async getCsDemandsPanel() {
    return (await demandsApi.getCsDemandsPanel())?.data;
  }

  static async getCustomerDemandsSlas(customerData) {
    return (await demandsApi.getCustomerSlas(customerData))?.data;
  }

  static async toggleDemandPrivate({ demandId, userId }) {
    const updatedDemand = await demandsApi.demandTogglePrivate(demandId, {
      userId,
    });
    return updatedDemand.data;
  }
}
