import apiOld from '../Api';

const templatesApi = {
  getTemplateById: function (templateId) {
    return apiOld.get(`/templates/${templateId}`);
  },
  createTemplate: function (data) {
    return apiOld.post('/templates', data);
  },
  updateTemplate: function (templateId, data) {
    return apiOld.put(`/templates/${templateId}`, data);
  },
  getFieldsFromBase64TemplateFile: function (
    data = {
      file: {},
    }
  ) {
    return apiOld.post('/templatesFiles/fields', data);
  },
};

export default templatesApi;
