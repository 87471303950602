import styled from "styled-components";

export const FeedbackModalStyles = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: center; */
    background-color: #fff;
    padding: 30px 30px;
    border-radius: 15px;
    width: 400px;
  `,
  ButtonClose: styled.i`
    position: absolute;
    align-self: flex-end;
    cursor: pointer;
  `,
  Title: styled.h1`
    font-size: 28px;
    align-self: center;
    margin-bottom: 10px;
  `,
  InfoContainer: styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  `,
  Label: styled.span`
    font-size: 13px;
    font-weight: bold;
  `,
  InfoText: styled.p`
    font-size: 11px;
    font-weight: normal;

    a,
    button {
      text-decoration: none;
      cursor: pointer;
      color: blue;
      background-color: transparent;
      border: none;
      font-weight: normal;
      font-size: 11px;
    }
  `,
};
