import * as Yup from 'yup';

export default class TemplateField {
  id;
  templateId;
  name;
  description;
  index;
  label;
  placeholder;
  required;
  type;
  options;
  boolean;

  static createFieldYupSchema = Yup.object({
    name: Yup.string().label('Nome').required(),
    description: Yup.string().label('Descrição').required(),
    label: Yup.string().label('Label').required(),
    placeholder: Yup.string().label('Placeholder').required(),
    type: Yup.string().label('Tipo').required(),
    options: Yup.mixed().when('type', {
      is: 'options',
      then: Yup.mixed()
        .nullable()
        .required()
        .transform(value => {
          if (value) {
            return value.split(',').map(s => s.trim());
          }
        })
        .test(
          'options',
          'Opções inválidas. Separe por vírgula, ex: "Um, Dois" (sem vírgulas no final e começo)',
          value => {
            return value?.every(option => !!option);
          }
        ),
    }),
  });

  constructor(fieldData = {}) {
    this.id = fieldData.id;
    this.templateId = fieldData.templateId;
    this.name = fieldData.name;
    this.description = fieldData.description;
    this.index = fieldData.index;
    this.label = fieldData.label;
    this.placeholder = fieldData.placeholder;
    this.required = fieldData.required;
    this.type = fieldData.type;
    this.options = fieldData.options;
    this.boolean = fieldData.boolean;
  }
}
