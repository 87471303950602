import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as DownloadIconFile } from '../../static/download.svg';
import { ReactComponent as FileAltIconFile } from '../../static/file-alt.svg';
import { ReactComponent as FileIcon } from '../../static/file.svg';

export const Container = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 90000;
  opacity: 1;
  box-sizing: border-box;
  white-space: initial;
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 150px);
  position: relative;
  background: #fff;
  border-radius: 10px;
  height: calc(100vh - 50px);

  header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgb(220, 226, 230);
    padding: 8px 15px;
  }

  main {
    display: flex;
    height: calc(100% - 61.4px);
  }
`;

export const Status = styled.div`
  border-radius: 5px;
  color: ${props => props.color};
  padding: 5px 10px;
  max-height: 42px;
  background-color: ${props => props.backgroundColor};
  font-weight: bold;
  font-size: 13px;
  white-space: pre;
  margin-right: 5px;
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  flex-grow: 2;
  width: calc(100% - 230px);

  h2 {
    font-size: 21px;
    padding: 0;
    color: #3d474d;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  align-content: center;
  justify-content: flex-start;
  width: 100%;
`;

export const HeaderInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;

  h3 {
    color: rgb(138, 148, 153);
    font-size: 13px;
  }

  h4 {
    color: #3d474d;
    font-size: 17px;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    max-width: 400px;
  }

  p {
    color: #3d474d;
    font-size: 17px;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
  }

  button {
    border: none;
    background-color: #1ab394;
    color: #fff;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
  }
`;

export const ButtonLink = styled(Link)`
  border: none;
  line-height: 1.13;
  background-color: #1ab394;
  color: #fff;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  text-decoration: none;
`;

export const InternSLAContainer = styled.div`
  display: flex;
  flex-direction: row;

  button {
    margin-left: 5px;
    cursor: pointer;
    color: rgb(23, 43, 77);
    background-color: rgb(247, 249, 250);
    width: 35px;
    height: 25px;
    border: none;
    border-radius: 3px;
  }

  .internSLAinput {
    width: 80px;
    margin-top: auto;
    font-size: 13px;
    padding: 3px 5px;
    border-radius: 5px;
    outline: solid 1px transparent;
    border: rgb(23, 43, 7, 0.2) solid 1px;

    :disabled {
      background-color: transparent;
      border-color: transparent;
      cursor: text;
    }

    :disabled:hover {
      background-color: rgb(247, 249, 250);
    }
  }
  .save {
  }
  .cancel {
  }
`;

export const HistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  padding-top: 8px;
  padding-right: 10px;
  margin-bottom: 10px;
  overflow-y: auto;

  /* ::-webkit-scrollbar {
    display: none;
  } */
`;

export const MessageDate = styled.p`
  font-size: 13px;
  font-weight: 600;
  margin: 8px 0;
  color: #262626;
  width: 100%;
  text-align: center;
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0;
  width: 100%;
  align-self: flex-start;

  ${props =>
    props.right &&
    `
      align-self: flex-end; 
      align-items: flex-end;
  `}
`;

export const Message = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f1f1f1;
  padding: 11px 20px;
  border-radius: 0 16px 16px;
  max-width: 75%;
  min-width: 200px;

  .DraftEditor-root {
    margin-right: auto;
    width: 100%;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    margin-top: 2px;
    font-size: 14px;
    color: ${props => (props.deleted ? '#fff' : '#707070')};
  }

  ${props =>
    props.right &&
    `
      background-color: #FFEEDB;
      align-items: flex-end;
  `}

  ${props =>
    props.private &&
    `
      background-color: #dafbff;
    `}

  ${props =>
    props.deleted &&
    `
      background-color: #ea5f59;
    `}
`;

export const MessageDeleteIcon = styled.i`
  font-weight: 600;
  font-size: 15px;
  color: red;
  position: absolute;
  left: 10px;
  cursor: pointer;
`;

export const MessageDeletedInfo = styled.span`
  font-weight: 300;
  font-size: 11px;
  align-self: flex-end;
  margin-right: 10px;
`;

export const MessageName = styled.p`
  font-weight: 600;
  font-size: 15px;
  color: ${props => (props.deleted ? '#fff' : '#070707')};
  width: auto;
`;

export const MessageText = styled.p`
  margin-top: 8px;
  font-size: 14px;
  color: ${props => (props.deleted ? '#fff' : '#707070')};
  width: 100%;
  overflow-wrap: break-word;
  ${props =>
    props.expanded || !props.deleted
      ? `
  white-space: pre-wrap;
  `
      : `
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    `}
`;

export const MessageTime = styled.p`
  margin-top: 10px;
  margin-left: 20px;
  font-size: 13px;
  color: #707070;
  font-weight: 600;

  ${props =>
    props.right &&
    `
      margin-right: 20px;
  `}
`;

export const MessageStatus = styled.p`
  position: relative;
  margin-top: 8px;
  background-color: #e2e2e2;
  padding: 19px 20px 19px 35px;
  color: ${props => props.color};
  font-weight: 600;
  font-size: 15px;
  border-radius: 16px;
  width: 100%;

  ::before {
    position: absolute;
    content: ' ';
    top: 14px;
    left: 19px;
    border-radius: 3px;
    width: 5px;
    height: 30px;
    background-color: ${props => props.color};
  }

  ${props =>
    props.right &&
    `
      background-color: #FFDAB1;
  `}
`;

export const MessageAttachment = styled.button`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin: 3px 0px;
  width: 100%;
  background-color: #ffdab1;
  padding: 10px 10px;
  color: #000000;
  font-weight: 600;
  font-size: 13px;
  border-radius: 16px;
  background-color: #e2e2e2;
  border: none;
  cursor: pointer;

  span {
    // filename
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: start;
    width: 100%;
  }

  button {
    border: none;
    background-color: transparent;
    white-space: nowrap;
    margin-left: 10px;
    align-items: center;
    display: flex;
    font-weight: 600;
    cursor: pointer;
    font-size: 1.2rem;
  }

  ${props =>
    props.right &&
    `
    background-color: #FFDAB1;
  `}
`;

export const SendMessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100px;
  max-height: 60%;

  form {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
`;

export const SendMessageEditorContainer = styled.div`
  display: flex;
  width: calc(100% - 40px);
`;

export const SendMessageButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: rgb(0, 170, 255);
  border: none;
  padding: 10px;
  border-radius: 2px;
  margin-left: 5px;
  box-shadow: 0px 1px 3px 0px rgb(220 220 220);
  box-sizing: border-box;
  width: 36px;

  :hover,
  :focus {
    background-color: rgb(0, 170, 255, 0.8);
  }

  :disabled {
    background-color: rgb(138, 148, 153);
  }
`;

export const CloseButtonLine = styled.div`
  border-radius: 5px;
  background-color: #262626;
  height: 3px;
  width: 100%;
  margin: 5px 0px;
  transition-duration: 0.3s;
`;

export const ActionIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
`;

export const DeleteButtonIcon = styled.i`
  box-sizing: content-box;
  width: 20px;
  margin-right: 15px;
  cursor: pointer;
  color: red;
`;

export const CloseButtonLine1 = styled(CloseButtonLine)``;
export const CloseButtonLine2 = styled(CloseButtonLine)``;
export const CloseButton = styled.button`
  box-sizing: content-box;
  width: 20px;
  border: none;
  background-color: transparent;
  cursor: pointer;

  ${CloseButtonLine1} {
    transform: rotate(45deg) translate(0, 5px);
  }
  ${CloseButtonLine2} {
    transform: rotate(-45deg) translate(0, -6px);
  }
`;

export const DownloadIcon = styled(DownloadIconFile)`
  width: 21px;
  min-width: 21px;
  height: 21px;
  min-height: 21px;
  margin-left: 10px;
`;

export const DocumentIcon = styled(FileIcon)`
  width: 21.49px;
  height: 25.78px;
  viewbox: 0 0 21.49px 25.78px;
  margin-right: 5px;
`;

export const AttachmentIcon = styled(FileAltIconFile)`
  width: 21px;
  min-width: 21px;
  height: 21px;
  min-height: 21px;
  margin-right: 10px;
`;

export const LinkStyled = styled(Link)`
  fill: #fff;
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 13px 25px;
  border-radius: 29px;
  font-size: 23px;
  border: none;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  background-color: #262626;
  color: #fff;

  :hover {
    background-color: #c2986a;
  }
`;

// -------- PROFILE ---------

export const DemandProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;

  h1 {
    font-size: 20px;
    font-weight: bold;
    margin-top: 0px;
    text-align: center;
  }
`;

export const DemandProfileDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 10px 20px;

  h2 {
    font-size: 16px;
    font-weight: bold;
    margin: 5px 0px;
    width: 100%;
    border-bottom: 1px solid #707070;
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 2px;

    h3 {
      font-weight: normal;
      font-size: 15px;
      color: #707070;
      margin: 0px 5px;
      align-self: flex-start;
    }

    a {
      text-decoration: none;
      font-weight: normal;
      font-size: 14px;
      color: #3366bb;
    }

    p {
      font-weight: normal;
      font-size: 14px;
      max-height: 300px;
      overflow-y: auto;
    }
  }
`;

//  ----------- TOLLBAR

export const Toolbar = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 1px solid rgb(220, 226, 230);
  background-color: rgb(247, 249, 250);
  border-bottom-right-radius: 10px;
  flex: 0 0 230px;
  overflow-y: auto;
`;

export const ToolbarItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid rgb(220, 226, 230);

  div {
    flex-direction: column;
    margin-left: 10px;
  }

  h4 {
    color: rgb(138, 148, 153);
    font-size: 13px;
  }

  p {
    color: rgb(138, 148, 153);
    font-size: 17px;
    padding: 0px;
  }

  .button {
    color: rgb(138, 148, 153);
    font-size: 17px;
    padding: 0px;
    font-weight: 500;
  }
`;

export const ToolbarItemContainerButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 20px;
  border: none;
  background-color: rgb(247, 249, 250);
  cursor: pointer;

  div {
    flex-direction: column;
    margin-left: 10px;

    .tags {
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
    }
  }

  h4 {
    color: rgb(138, 148, 153);
    font-size: 13px;
  }

  p {
    color: rgb(138, 148, 153);
    font-size: 17px;
    padding: 0px;
  }

  :not(:last-child) {
    border-bottom: 1px solid rgb(220, 226, 230);
  }
  :hover {
    background-color: rgb(226, 228, 229);
  }
`;
