import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import { useField } from '@unform/core';
import SelectSearch from 'react-select-search';
import './style.css';

const useStyles = makeStyles(theme => ({
  error: {
    color: 'red',
    opacity: '70%',
    paddingTop: '0px',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    color: '#A8A8A8',
    fontSize: '12px',
  },
}));

export default function CustomSelectWithSearch({
  name,
  label,
  options,
  defaultValue,
  multiple,
  ...rest
}) {
  /* 
    Default value only works when the component is mounted.
  */
  const classes = useStyles();
  const inputRef = useRef(null);
  const { fieldName, registerField, error } = useField(name);
  const [inputValue, setInputValue] = useState(defaultValue);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: ref => {
        if (!ref.firstChild.firstChild.value) {
          return null;
        }

        return multiple
          ? options
              .filter(({ name }) =>
                ref.firstChild.firstChild.value.split(', ').includes(name)
              )
              .map(({ value }) => value)
          : options.find(
              option => option.name === ref.firstChild.firstChild.value
            )?.value;
      },
      setValue: (ref, value) => {
        setInputValue(value);
      },
      clearValue: () => {
        setInputValue(null);
      },
    });
  }, [fieldName, registerField, options, multiple]);

  return (
    <div className={classes.main}>
      <label className={classes.label} htmlFor={inputRef}>
        {label}
        <SelectSearch
          ref={inputRef}
          search
          value={inputValue}
          onChange={setInputValue}
          options={options}
          defaultValue={defaultValue}
          multiple={multiple}
          {...rest}
        />
      </label>
      {error && <span className={classes.error}>{error}</span>}
    </div>
  );
}
