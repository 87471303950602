import {api } from '../Api';

const businessApi = {
    getByCustomerId: function (customerId) {
        return api.get(`/business/`, {
            params: {
                customerId
            },
        });
    },
    getByBusinessId: function (businessId) {
        return api.get(`/business/${businessId}`);
    },
    handleAddBusiness: function (business, customerId) {
        return api.post(`/business/`, {...business, customerId});
    },
    handleUpdateBusiness: function (business) {
        return api.patch(`/business/${business.id}`, business);
    },
    handleDeleteBusiness: function (businessId) {
        return api.delete(`/business/${businessId}`);
    },
    associateBusiness: function(body) {
        return  api.post(`/business/associate-user`, body);
    },    
    dessasociateBusiness: function(body) {
        return  api.post(`/business/desassociate-user`, body);
    }
}

export default businessApi; 