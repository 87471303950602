import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useField } from "@unform/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  Image: {
    backgroundColor: "#3434",
    width: "100px",
    height: "100px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100px 100px",
    cursor: "pointer",
    border: "1px solid #e5e6e7",
    borderRadius: "5px",
    padding: "2px",
    display: "flex",
    alignItems: "flex-end",
  },
  Span: {
    color: "#FFF",
    marginRight: "50px",
  },
  Label: {
    color: "#A8A8A8",
    fontSize: "14px",
    fontWeight: "bold",
    marginBottom: "2px",
  },
  Error: {
    color: "red",
    opacity: "70%",
  },
  Input: {
    visibility: "hidden",
  },
}));

function ImageInputWithPreview({
  name,
  defaultValue = null,
  inputProps = {},
  label = "Imagem",
  ...rest
}) {
  const inputRef = useRef(null);
  const { fieldName, registerField, error } = useField(name);
  const [value, setValue] = useState(null);
  const [preview, setPreview] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: () => value,
      setValue: (_, value) => {
        setValue(value);
        setPreview(value);
      },
      clearValue: () => {
        setValue(defaultValue);
        setPreview(null);
      },
    });
    if (!preview && defaultValue) setPreview(defaultValue);
  }, [fieldName, registerField, value, preview, defaultValue]);

  const handleChange = (e) => {
    const imageFile = e.target.files[0];

    if (imageFile) {
      setValue(imageFile);
      setPreview(URL.createObjectURL(imageFile));
    }
  };

  return (
    <>
      <label className={classes.Label}>{label}</label>

      <div
        onClick={() => (rest.disabled ? null : inputRef.current.click())}
        name={name}
        defaultValue={defaultValue}
        style={{ backgroundImage: `url(${preview})` }}
        className={classes.Image}
        {...rest}
      >
        <input
          ref={inputRef}
          name="upload"
          type="file"
          accept="image/png, image/jpeg, image/jpg"
          onChange={handleChange}
          className={classes.Input}
          {...inputProps}
        />

        <span className={classes.Span}>
          {value || preview ? "Editar" : "Selecionar"}
        </span>
      </div>

      {error && <span className={classes.Error}>{error}</span>}
    </>
  );
}

ImageInputWithPreview.propTypes = {
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  inputProps: PropTypes.object,
};

export default ImageInputWithPreview;
