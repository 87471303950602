import React, { useCallback, useState } from 'react';
import moment from 'moment';
import Calendar from '../input/calendar/Calendar';
import { useEffect } from 'react';
import { addWorkingDays } from '../../services/Utils';
import DemandService from '../../services/demandService';
import { useSelector } from 'react-redux';
const hours = 11;
const minutes = 0;
export default function CustomerSlasCalendar({
  name = 'deleveryForecast',
  label = 'Data do SLA',
  defaultValue = addWorkingDays(moment().set("hours", hours).set("minute", minutes), 2).toDate(),
  modalData,
  ...rest
}) {
  const [customerSlas, setCustomerSlas] = useState([]);
  const demandType = useSelector(
    state => state.demandTypes.byId[modalData.demandTypeId]
  );

  const fetchCustomerSlas = useCallback(
    async modalData => {
      try {
        const slas = await DemandService.getCustomerDemandsSlas({
          businessId: modalData.Business.id,
          demandTypeName: demandType?.name,
        });
        setCustomerSlas(slas);
      } catch (error) {
        console.error(error);
      }
    },
    [demandType?.name]
  );

  useEffect(() => {
    if (modalData) fetchCustomerSlas(modalData);
  }, [fetchCustomerSlas, modalData]);

  const _getDemandsSlasInThisDate = date => {
    return customerSlas.filter(sla => moment(date).isSame(moment(sla), 'days'))
      .length;
  };

  const handleCalendarCustomTileClassname = ({ date, view }) => {
    if (view === 'month') {
      const demandsInThisDate = _getDemandsSlasInThisDate(date);

      if (demandsInThisDate > 1) {
        return 'hasMoreThanOneDemand';
      } else if (demandsInThisDate === 1) {
        return 'hasOneDemand';
      }

      return null;
    }
  };

  const handleCalendarCustomContent = ({ date, view }) => {
    if (view === 'month') {
      const demandsInThisDate = _getDemandsSlasInThisDate(date);
      let text = '';

      if (demandsInThisDate > 0) text = `${demandsInThisDate} demanda`;
      if (demandsInThisDate > 1) text = `${demandsInThisDate} demandas`;

      if (text)
        return (
          <>
            <span className='demandsCount'>{text}</span>
          </>
        );
    }
  };

  return (
    <Calendar
      name={name}
      label={label}
      placeholder='Selecionar data'
      defaultValue={defaultValue}
      selectRange={false}
      tileClassName={handleCalendarCustomTileClassname}
      tileContent={handleCalendarCustomContent}
      {...rest}
    />
  );
}
