import apiOld from '../Api';

const customerSaleApi = {
  registerSale: async function (customerId, saleData = {}) {
    return apiOld.post(`/customers/${customerId}/sale`, saleData);
  },

  getBackofficeCustomerSalesTable: async function (query = {}) {
    return apiOld.get(`/customerSales/table`, {
      params: query,
    });
  },
};

export default customerSaleApi;
