import React, { useEffect, useState } from "react";
import SectionAIR from "../../../components/riskAnalysis/SectionAIR";
import ModalAIR from "../../../components/riskAnalysis/modalAIR";
import CustomInput from "../../../components/input/CustomInput";
import { Grid } from "@material-ui/core";
import { AnalysisRiskApi } from "../../../services/apis/analysisRisk";
import { dataTitleSector, dataModalSector } from "../renderContent";

export default function SectorAIR() {
  const [addSector, setAddSector] = useState(false);
  const [editSector, setEditSector] = useState(false);
  const [sectors, setSectors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [idSelected, setIdSelected] = useState(0);

  const setValues = {
    add: setAddSector,
    edit: setEditSector,
  };

  const { sector } = AnalysisRiskApi;

  const createSector = async (data) => {
    await sector.create(data);
    fetchData();
  };

  const updateSector = async (id, data) => {
    await sector.update(id, data);
    fetchData();
  };

  const deleteSector = async (id) => {
    await sector.delete(id);
    fetchData();
  };

  const fetchData = async () => {
    setLoading(true);
    const sectorList = (await sector.getAll()).data;
    setSectors(sectorList);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <SectionAIR
        dataTitle={dataTitleSector}
        data={sectors}
        setValues={setValues}
        loading={loading}
        remove={deleteSector}
        setIdSelected={setIdSelected}
      />
      <ModalAIR
        open={editSector}
        close={() => setEditSector(false)}
        dataModal={dataModalSector.edit}
        id={idSelected}
        update={updateSector}
      >
        <div>
          <Grid item xs={12}>
            <CustomInput
              name="name"
              label="Setor:"
              placeholder="Digite o setor"
              maxLength={700000}
              defaultValue={(sectors.find((sector) => sector.id === idSelected))?.name}
            />
          </Grid>
        </div>
      </ModalAIR>
      <ModalAIR
        open={addSector}
        close={() => setAddSector(false)}
        dataModal={dataModalSector.add}
        create={createSector}
      >
        <div>
          <Grid item xs={12}>
            <CustomInput
              name="name"
              label="Setor:"
              placeholder="Digite o setor"
              maxLength={700000}
            />
          </Grid>
        </div>
      </ModalAIR>
    </>
  );
};