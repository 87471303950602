import React from 'react';
import { Container, ColumnTitle } from '../column/Column.style';
import { StatsContainer, StatsInfo } from './ColumnStats.style';

export default function ColumnStats({
  totalDemands = 0,
  uniqueDemands = 0,
  children,
}) {
  return (
    <Container>
      <header>
        <ColumnTitle>Estatísticas</ColumnTitle>
      </header>

      <StatsContainer>
        {children}

        <StatsInfo.Container title='Quantidade de vezes que todas demandas foram finalizadas'>
          <StatsInfo.Label>Total de demandas: </StatsInfo.Label>
          <StatsInfo.Content>{totalDemands}</StatsInfo.Content>
        </StatsInfo.Container>

        <StatsInfo.Container title='Total de cards criados'>
          <StatsInfo.Label>Demandas únicas: </StatsInfo.Label>
          <StatsInfo.Content>{uniqueDemands}</StatsInfo.Content>
        </StatsInfo.Container>
      </StatsContainer>
    </Container>
  );
}
