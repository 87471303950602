import { Form } from '@unform/web';
import React, { useRef, useState } from 'react';
import { Constants } from '../../../utils/Constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Yup from 'yup';
import CustomSelectWithSearch from '../../input/customSelectorWithSearch/CustomSelectWithSearch';
import {
  ButtonsContainer,
  Container,
  ContainerInput,
  ExitButton,
  MessageContainer,
  Modal,
  SubmitButton,
} from './ChangeDemandStatusModal.styles';
import RichTextArea from '../../input/richTextArea/RichTextArea';
import { toast } from 'react-toastify';
import DemandService from '../../../services/demandService';
import demandsApi from '../../../services/apis/demandsApi';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAllDraftByDemandId, setDraftFinish, setDraftStatus } from '../../../redux/actions/DraftDemandMessages';
const demandService = new DemandService();


export default function ChangeStatusModal({ open, handleClose, modalData, ...rest }) {
  const { isChangeStatusModalOpen, submit } = rest;
  const changeDemandStatusFormRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  async function handleSubmit(data) {
    try {
      if (data.statusKey === modalData.statusKey) {
        toast.warning(
          `Demanda já está ${Constants.DEMAND_STATUSES[data.statusKey].name}`
        );

        return;
      }

      setSubmitLoading(true);
      changeDemandStatusFormRef.current.setErrors({});

      if (data.statusKey === Constants.DEMAND_STATUSES.PENDING) {
        await demandsApi.updateDemand(modalData.id, { checked: true })
      }
      if (data.statusKey === Constants.DEMAND_STATUSES.IN_PROGRESS) {
        await demandsApi.updateDemand(modalData.id, { checked: false })
      }

      const updatedDemand = await demandService.updatedemandStatus({
        demandId: modalData.id,
        statusKey: data.statusKey,
        message: data.message,
        attachments: data.attachments,
      });

      toast.success('Status alterado com sucesso!');

      handleClose(updatedDemand);

      if (submit) {
        dispatch(deleteAllDraftByDemandId(demandId));
        changeDemandStatusFormRef.current.clear();
      };
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors = {};

        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });

        changeDemandStatusFormRef.current.setErrors(validationErrors);
      }
    } finally {
      setSubmitLoading(false);
    }
  }

  const dispatch = useDispatch();
  const demandId = modalData?.id;
  const { demandStatus, demandFinish } = useSelector(state => state?.drafts?.drafts);
  const draftDemand = isChangeStatusModalOpen === 'FINISHED' ? demandFinish?.[demandId] : demandStatus?.[demandId];

  const setDraft = (draft) => dispatch(
    isChangeStatusModalOpen === 'FINISHED'
      ? setDraftFinish(demandId, draft)
      : setDraftStatus(demandId, draft)
  );

  return (
    <>
      {open && (
        <Container>
          <Modal>
            <h1>Alterar status</h1>

            <Form ref={changeDemandStatusFormRef} onSubmit={handleSubmit}>
              <ContainerInput>
                <CustomSelectWithSearch
                  name='statusKey'
                  label='Status'
                  defaultValue={
                    typeof open === 'boolean' ? modalData.statusKey : open
                  }
                  options={
                    modalData?.statusKey === Constants.DEMAND_STATUSES.OPEN.key
                      ? [Constants.DEMAND_STATUSES.BLOCKED]
                      : Constants.DEMAND_STATUSES_LIST
                  }
                />
              </ContainerInput>

              <MessageContainer>
                <RichTextArea
                  name='message'
                  placeholder='Escrever mensagem'
                  maxLength={70000}
                  defaultValue={draftDemand || ''}
                  setDraft={setDraft}
                />
              </MessageContainer>

              <ButtonsContainer>
                <SubmitButton type='submit'>
                  {!submitLoading && 'Alterar'}
                  {submitLoading && <CircularProgress color='inherit' />}
                </SubmitButton>

                <ExitButton onClick={() => handleClose(false)}>
                  Fechar
                </ExitButton>
              </ButtonsContainer>
            </Form>
          </Modal>
        </Container>
      )}
    </>
  );
}
