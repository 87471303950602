import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  error: {
    color: 'red',
    opacity: '70%',
    marginLeft: '16px',
    textAlign: 'justify',
    display: 'block',
  },
  label: {
    paddingLeft: '3px',
  },

  main: {},
  container: {
    display: 'flex',
  },
  item: {
    padding: '10px 20px 10px 20px',
  },
}));

export default function CustomRadio({
  name,
  options,
  direction,
  defaultValue,
  ...rest
}) {
  const inputRefs = useRef([]);
  const { fieldName, registerField, error } = useField(name);
  const classes = useStyles();

  useEffect(() => {
    registerField({
      name: fieldName,
      path: 'value',
      ref: inputRefs.current,
      getValue(refs) {
        const checked = refs.find(ref => ref.checked);

        return checked ? checked.value : null;
      },
      setValue(refs, value) {
        if (value) {
          const item = refs.find(ref => ref.value === value);

          if (item) {
            item.checked = true;
          }
        } else {
          refs.map(ref => (ref.checked = false));
        }
      },
    });
  }, [fieldName, registerField]);

  return (
    <div
      className={classes.container}
      style={{ flexDirection: direction ? direction : 'row' }}
    >
      {error && <span style={{ color: 'red' }}>error</span>}
      {options.map((option, index) => (
        <div key={option.id} className={classes.item}>
          <label key={option.id}>
            <input
              ref={elRef => (inputRefs.current[index] = elRef)}
              type='radio'
              name={fieldName}
              value={option.id}
              defaultChecked={defaultValue === option.id}
              {...rest}
            />
            <span className={classes.label}>{option.label}</span>
          </label>
        </div>
      ))}
    </div>
  );
}
