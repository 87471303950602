import React, { useEffect, useState } from "react";
import MiniDrawer from "../../../components/MiniDrawer";
import Loading from "../../../components/Loading";
import { ClassStyle } from "../index";
import { BallPoint, GamePagButton, TextTag, propsGamePadButton } from "../../../components/riskAnalysis/index.styles";
import usersApi from "../../../services/apis/usersApi";
import { AnalysisRiskApi } from "../../../services/apis/analysisRisk";
import ScoreAIRByCustomer from "./scoreAIRByCustomer";
import Tooltip from "@material-ui/core/Tooltip";

export default function AnswerByCustomer() {
  const classes = ClassStyle();

  const [loading, setLoading] = useState(false);
  const [questionsList, setQuestionsList] = useState([]);
  const [questionsVisible, setQuestionsVisible] = useState(false);
  const [user, setUser] = useState(null);

  const { questionsAnsweredByUser } = AnalysisRiskApi;

  async function fetchData() {
    setLoading(true);

    const userIdByPath = window.location.pathname.split("/")[3];
    const businessIdByPath = window.location.pathname.split("/")[5];

    const user = (await usersApi.getUserById(userIdByPath))?.data;
    const questions = (await questionsAnsweredByUser(user.customerId, businessIdByPath))?.data;

    const groupedQuestions = questions.reduce((acc, item) => {
      const sectorAIR = item.question.SectorAIR.name;
      if (!acc[sectorAIR]) {
        acc[sectorAIR] = [];
      }
      acc[sectorAIR].push(item);
      return acc;
    }, {});

    setQuestionsList(groupedQuestions);
    setUser(user);
    setLoading(false);
  }

  const handleToggleQuestions = () => {
    setLoading(true);
    setTimeout(() => {
      setQuestionsVisible(!questionsVisible);
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <MiniDrawer
        currentExpaded="Menu"
        main={
          <>
            <Loading open={loading} />
            <section className={classes.content}>
              <h3 className={classes.title} style={{ padding: " 1rem 0 0 1rem" }}>
                Menu Analise Interna de Risco
              </h3>
              <ScoreAIRByCustomer user={user} setLoading={setLoading} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  margin: "0 3rem",
                  borderBottom: "solid 1px RGBA(188, 188, 188)",
                }}
              >
                <button
                  className={classes.button}
                  style={{
                    width: "15rem",
                    height: "3rem",
                    margin: "1rem 0 3rem 0",
                    border: "1.5px solid #1AB394",
                  }}
                  onClick={handleToggleQuestions}
                >
                  {!questionsVisible ? "Ver Respostas" : "Ocultar Respostas"}
                </button>
              </div>
              {questionsVisible &&
                Object.entries(questionsList).map(([sectorAIR, questions], index) => (
                  <div key={sectorAIR}>
                    {questions.map((item, questionIndex) => (
                      <div key={item.question.id}>
                        <div className={classes.cardCenter}>
                          <section
                            className={classes.cardBorder}
                            style={{ width: "95%", border: "none" }}
                          >
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <h3 style={{ display: 'flex', gap: '1rem' }} className={classes.title}>
                                {index + 1}- {item.question.name}
                                <Tooltip title={sectorAIR} placement="top" arrow>
                                  <i className='fas fa-question-circle fa-xs' />
                                </Tooltip>
                              </h3>
                            </div>
                            <div style={{ margin: "1rem 3rem" }}>
                              <GamePagButton
                                {...propsGamePadButton}
                                width="100%"
                                background="#f3f3f4"
                              >
                                <div className={classes.buttonContent} style={{ width: "85%" }}>
                                  <TextTag>Alternativa</TextTag>
                                  <p className={classes.buttonText}>{item.answer.name}</p>
                                </div>
                                <TextTag style={{ display: "flex" }}>
                                  <BallPoint>{item.answer.score}</BallPoint>
                                </TextTag>
                              </GamePagButton>
                            </div>
                          </section>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
            </section>
          </>
        }
      />
    </React.Fragment>
  );
}