import React, { useCallback } from 'react';
import {
  UsersContainer,
  User,
  Container,
  Modal,
} from './ChangeUserModal.styles';
import { toast } from 'react-toastify';
import demandsApi from '../../../services/apis/demandsApi';
import { CircularProgress } from '@material-ui/core';

export default function ChangeUserModal({
  open,
  handleClose,
  modalData,
  fetchData,
  isLoading,
}) {
  const actual = modalData.users?.filter(user =>
    modalData.following?.includes(user.id)
  );
  const toAdd = modalData.users?.filter(
    user => !modalData.following?.includes(user.id)
  );

  const handleAddFollowingUser = useCallback(async (userId, demandId) => {
    try {
      await demandsApi.demandToggleFollowingUser(demandId, { userId });
      toast.success('Usuário adicionado com sucesso!');
    } catch (error) {
      console.error(error);
      toast.error('Oops, algo inesperado aconteceu!');
    }
  }, []);
  const handleRemoveFollowingUser = useCallback(async (userId, demandId) => {
    try {
      if (actual.length === 1) toast.error('É necessário ter ao menos um usuário');
      else {
        await demandsApi.demandToggleFollowingUser(demandId, { userId });
        toast.success('Usuário removido com sucesso!');
      }
    } catch (error) {
      console.error(error);
      toast.error('Oops, algo inesperado aconteceu!');
    }
  }, [actual]);

  return (
    <>
      {open && (
        <Container>
          <Modal>
            <i className='fa fa-2x fa-times closeModal' onClick={handleClose} />
            <h1>Usuários do cliente notificados na Demanda</h1>
            <h2>Atuais</h2>
            <UsersContainer>
              {isLoading ? (
                <CircularProgress color='inherit' />
              ) : (
                actual.map(user => (
                  <User
                    key={user.id}
                    onClick={async () => {
                      await handleRemoveFollowingUser(
                        user.id,
                        modalData.demandId
                      );
                      fetchData();
                    }}
                  >
                    {user.name}
                  </User>
                ))
              )}
              {actual?.length === 0 && <span>Nenhum usuário por aqui</span>}
            </UsersContainer>

            <h2>Adicionar</h2>
            <UsersContainer>
              {isLoading ? (
                <CircularProgress color='inherit' />
              ) : (
                toAdd.map(user => (
                  <User
                    key={user.id}
                    onClick={async () => {
                      await handleAddFollowingUser(user.id, modalData.demandId);
                      fetchData();
                    }}
                  >
                    {user.name}
                  </User>
                ))
              )}
              {toAdd?.length === 0 && <span>Nenhum usuário por aqui</span>}
            </UsersContainer>
          </Modal>
        </Container>
      )}
    </>
  );
}
