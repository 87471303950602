import React, { useState } from 'react';
import moment from 'moment';
import apiOld from '../../../services/Api';
import ModalTriagem from '../screeningModal/ScreeningModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  ColumnTitle,
  QuantityBadger,
  CardsContainer,
  EmptyColumn,
  FilterContainer,
  Filter,
  FilterIcon,
  FilterButton,
  FilterButtonLabel,
} from './Column.style';
import { PATCH_DEMAND } from '../../../redux/actionsTypes/demandActionTypes';
import { patchDemandsBoardColumnConfigs } from '../../../redux/actions/configs';
import { defaultColumnConfigs } from '../../../configs/demandsBoardConfigs';
import { openDemandModal } from '../../../routes';
import Card from '../card/Card';
import { Constants } from '../../../utils/Constants';

export default function Column({
  columnTitle,
  index,
  backofficeUserId,
  columnStatus,
  demands,
  totalDemands,
  authUser = false,
  demandsInProgressQuantity = null,
  ignoreCheckedOrdenation = false
}) {
  const dispatch = useDispatch();
  const demandsBoardConfig = useSelector(state => state.configs.demandsBoard);
  const columnConfig = demandsBoardConfig.columns[
    backofficeUserId || columnStatus
  ] || {
    ...defaultColumnConfigs,
  };
  const [modalTriagemIsOpen, setModalTriagemIsOpen] = useState(false);
  const [modalData, setModalData] = useState(null);



  async function handleChangeBackofficeUserColumn(demandId) {
    try {
      const request = {
        backofficeUserId: backofficeUserId,
      };

      const demandUpdated = (
        await apiOld.patch(`/demands/${demandId}/backofficeUser`, request)
      )?.data;

      dispatch({
        type: PATCH_DEMAND,
        demand: demandUpdated,
      });
    } catch (error) {
      console.error(error);
    }
  }

  async function handleChangeStatus(demandId, newStatusKey) {
    try {
      const request = {
        statusKey: newStatusKey,
      };

      const demandUpdated = (
        await apiOld.patch(`/demands/${demandId}/status`, request)
      )?.data;

      dispatch({
        type: PATCH_DEMAND,
        demand: demandUpdated,
      });
    } catch (err) { }
  }

  function handleTriagemModalClose(wasSuccess) {
    if (wasSuccess) {
      // Open demand modal after triagem
      openDemandModal(wasSuccess);
    }
    setModalTriagemIsOpen(false);
  }

  const sortUncheckedDemands = (a, b) => {
    if (a.checked == true) {
      if (b.checked != true) {
        return 1
      } else {
        return 0
      }
    } else if (b.checked == true) {
      return -1
    } else {
      return 0
    }
  }

  const sortDemands = (a, b) => {
    // -1 = a vem antes, 1 = b vem antes
    let demandKey = ''
    if (columnConfig.externSlaOrder !== "")
      demandKey = 'deleveryForecast'
    if (columnConfig.internSlaOrder !== "")
      demandKey = 'internDeliveryForecast'
    if (columnConfig.updateOrder !== "")
      demandKey = 'lastBackofficeAction'

    const order =
      (columnConfig.internSlaOrder || columnConfig.externSlaOrder || columnConfig.updateOrder) === 'ASC'
        ? 'isBefore'
        : 'isAfter';

    // demands sem SLA sempre primeiro
    if (a[demandKey] === null) {
      return -1;
    }
    if (b[demandKey] === null) {
      return 1;
    }

    return moment(a[demandKey])[order](b[demandKey]) ? -1 : 1;
  };

  const formatDemands = demands => {
    let demandsSorted = demands.sort(sortDemands) 

    if(!ignoreCheckedOrdenation){
      demandsSorted = demandsSorted.sort(sortUncheckedDemands)
    }
    return demandsSorted;
  };

  const handleFilterChange = (key, value) => {
    const newConfig = { ...defaultColumnConfigs };

    if (key === 'internSlaOrder') {
      newConfig.externSlaOrder = '';
      newConfig.updateOrder = '';
    } if (key === 'externSlaOrder') {
      newConfig.internSlaOrder = '';
      newConfig.updateOrder = '';
    } if (key === 'updateOrder') {
      newConfig.internSlaOrder = '';
      newConfig.externSlaOrder = '';
    }

    newConfig[key] = value === 'ASC' ? 'DESC' : 'ASC';

    dispatch(
      patchDemandsBoardColumnConfigs({
        [backofficeUserId || columnStatus]: newConfig,
      })
    );
  };

  const formatedDemands = formatDemands(demands);

  return (
    <Container>
      <ModalTriagem
        open={modalTriagemIsOpen}
        modalData={modalData}
        handleClose={handleTriagemModalClose}
      />

      <header>
        <ColumnTitle authUser={authUser}>{columnTitle}</ColumnTitle>

        {columnStatus ? (
          <div>
            <QuantityBadger status={columnStatus}>
              {totalDemands}
            </QuantityBadger>
          </div>
        ) : (
          <div>
            {
              <QuantityBadger
                status={Constants.DEMAND_STATUSES.IN_PROGRESS.key}
              >
                {demandsInProgressQuantity}
              </QuantityBadger>
            }
          </div>
        )}
      </header>

      <FilterContainer>
        <Filter>
          <FilterButtonLabel>
            <FilterIcon
              order={columnConfig.externSlaOrder}
              className={`fas  fa-${columnConfig.externSlaOrder !== ''
                ? columnConfig.externSlaOrder === 'ASC'
                  ? 'sort-up'
                  : 'sort-down'
                : 'sort'
                }`}
            />
            SLA Externo{' '}
            <FilterButton
              type='button'
              value={columnConfig.externSlaOrder}
              onClick={e =>
                handleFilterChange('externSlaOrder', e.target.value)
              }
            />
          </FilterButtonLabel>
        </Filter>

        <Filter>
          <FilterButtonLabel>
            <FilterIcon
              order={columnConfig.internSlaOrder}
              className={`fas  fa-${columnConfig.internSlaOrder !== ''
                ? columnConfig.internSlaOrder === 'ASC'
                  ? 'sort-up'
                  : 'sort-down'
                : 'sort'
                }`}
            />
            SLA Interno{' '}
            <FilterButton
              type='button'
              value={columnConfig.internSlaOrder}
              onClick={e =>
                handleFilterChange('internSlaOrder', e.target.value)
              }
            />
          </FilterButtonLabel>
        </Filter>
        <Filter>
          <FilterButtonLabel>
            <FilterIcon
              order={columnConfig.updateOrder}
              className={`fas  fa-${columnConfig.updateOrder !== ''
                ? columnConfig.updateOrder === 'ASC'
                  ? 'sort-up'
                  : 'sort-down'
                : 'sort'
                }`}
            />
            Última atualização{' '}
            <FilterButton
              type='button'
              value={columnConfig.updateOrder}
              onClick={e =>
                handleFilterChange('updateOrder', e.target.value)
              }
            />
          </FilterButtonLabel>
        </Filter>
      </FilterContainer>

      <CardsContainer>
        {formatedDemands.map((demand, demandIdx) => (
          <Card
            key={demand.id}
            data={demand}
            demandId={demand.id}
            index={demandIdx}
            listId={backofficeUserId || columnStatus}
            listIndex={index}
            columnTitle={columnTitle}
          />
        ))}

        {formatedDemands &&
          formatedDemands instanceof Array &&
          formatedDemands.length === 0 && (
            <EmptyColumn>Nenhuma demanda por aqui</EmptyColumn>
          )}
      </CardsContainer>
    </Container>
  );
}
