import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Container,
  ColumnContainer,
} from '../../../components/demandsBoard/DemandsBoard.styles';
import Column from '../../../components/demandsBoard/column/Column';
import MiniDrawer from '../../../components/MiniDrawer';
import { Constants } from '../../../utils/Constants';
import usersApi from '../../../services/apis/usersApi';
import DemandService from '../../../services/demandService';

const demandService = new DemandService();

export default function CsDemandsBoard() {
  const authUser = useSelector(state => state.auth.user);
  const [state, setState] = React.useState({
    columns: [],
    demands: [],
    loading: true,
  });

  const _fetchDemandsData = async authUser => {
    const demands = (await demandService.getCsDemandsPanel()).demands;
    const backofficeUsers = (await usersApi.getAllBackofficeUsers()).data.users;
    const columns = [
      {
        title: 'Triagem',
        columnStatus: Constants.DEMAND_STATUSES.OPEN.key,
        cards: demands.filter(
          demand => demand.statusKey === Constants.DEMAND_STATUSES.OPEN.key
        ),
      },
      {
        title: 'Pendentes',
        columnStatus: Constants.DEMAND_STATUSES.PENDING.key,
        cards: demands.filter(
          demand => demand.statusKey === Constants.DEMAND_STATUSES.PENDING.key
        ),
      },
      {
        title: authUser.name,
        backofficeUserId: authUser.id,
        cards: demands.filter(
          demand =>
            demand.backofficeUserId === authUser.id &&
            (demand.statusKey === Constants.DEMAND_STATUSES.IN_PROGRESS.key ||
              demand.statusKey === Constants.DEMAND_STATUSES.OPEN.key)
        ),
        authUser: true,
      },
      ...backofficeUsers
        .filter(user => user.id !== authUser.id)
        .map(user => ({
          title: user.name,
          backofficeUserId: user.id,
          cards: demands.filter(
            demand =>
              demand.backofficeUserId === user.id &&
              demand.statusKey !== Constants.DEMAND_STATUSES.PENDING.key
          ),
        }))
        .sort((a, b) => a.title.localeCompare(b.title)),
    ];
    const demandsWithoutBackofficeUser = demands?.filter(
      demand =>
        !demand.backofficeUserId &&
        (demand.statusKey === Constants.DEMAND_STATUSES.PENDING.key ||
          demand.statusKey === Constants.DEMAND_STATUSES.IN_PROGRESS.key)
    );
    if (demandsWithoutBackofficeUser?.length) {
      columns.splice(2, 0, {
        title: 'Sem responsável',
        cards: demandsWithoutBackofficeUser,
      });
    }

    setState({
      columns,
      demands,
      loading: false,
    });
  };

  useEffect(() => {
    _fetchDemandsData(authUser);
  }, []);

  return (
    <React.Fragment>
      <MiniDrawer
        customStyle={{ padding: '0px' }}
        currentExpaded={'cs'}
        main={
          <Container>
            <ColumnContainer>
              {state.columns.map((column, idx) => (
                <Column
                  key={
                    column.backofficeUserId ||
                    column.columnStatus ||
                    column.title
                  }
                  index={idx}
                  columnTitle={column.title}
                  backofficeUserName={column.backofficeUserName}
                  backofficeUserId={column.backofficeUserId}
                  columnStatus={column.columnStatus}
                  demands={column.cards}
                  authUser={column.authUser}
                  totalDemands={column.cards.length}
                  demandsInProgressQuantity={
                    column.cards.filter(
                      demand =>
                        demand.statusKey ===
                        Constants.DEMAND_STATUSES.IN_PROGRESS.key
                    ).length
                  }
                  demandsOpenQuantity={column.cards.length}
                />
              ))}
            </ColumnContainer>
          </Container>
        }
      />
    </React.Fragment>
  );
}
