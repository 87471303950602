import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  ColumnContainer,
} from '../../../components/demandsBoard/DemandsBoard.styles';
import Column from '../../../components/demandsBoard/column/Column';
import ColumnStats from '../../../components/demandsBoard/columnStats/ColumnStats';
import MiniDrawer from '../../../components/MiniDrawer';
import { useQuery } from '../../../routes';
import { Constants } from '../../../utils/Constants';
import demandsApi from '../../../services/apis/demandsApi';
import { Form } from '@unform/web';
import Calendar from '../../../components/input/calendar/Calendar';

const formatColumns = demands => {
  return [
    {
      title: 'Aberta',
      columnStatus: Constants.DEMAND_STATUSES.OPEN.key,
      cards: demands.filter(
        demand => demand.statusKey === Constants.DEMAND_STATUSES.OPEN.key
      ),
    },
    {
      title: 'Pendentes',
      columnStatus: Constants.DEMAND_STATUSES.PENDING.key,
      cards: demands.filter(
        demand => demand.statusKey === Constants.DEMAND_STATUSES.PENDING.key
      ),
    },
    {
      title: 'Em Andamento',
      columnStatus: Constants.DEMAND_STATUSES.IN_PROGRESS.key,
      cards: demands.filter(
        demand => demand.statusKey === Constants.DEMAND_STATUSES.IN_PROGRESS.key
      ),
    },
    {
      title: 'Finalizada',
      columnStatus: Constants.DEMAND_STATUSES.FINISHED.key,
      cards: demands.filter(
        demand => demand.statusKey === Constants.DEMAND_STATUSES.FINISHED.key
      ),
    },
    {
      title: 'Bloqueadas',
      columnStatus: Constants.DEMAND_STATUSES.BLOCKED.key,
      cards: demands.filter(
        demand => demand.statusKey === Constants.DEMAND_STATUSES.BLOCKED.key
      ),
    },
  ];
};

export default function CustomerDemandsBoard() {
  const businessId = useQuery().get('businessId');
  const customerId = useQuery().get('customerId');

  const [demands, setDemands] = useState(null);
  const [columns, setColumns] = useState(null);
  const [query, setQuery] = useState(null);

  const fetchDemandsData = useCallback(
    async query => {
      try {
        const demandsData =
          (
            await demandsApi.getAllDemandsWithCustomer({
              customerId: customerId,
              businessId: businessId,
              ...query,
            })
          )?.data || [];

        setDemands(demandsData.demands);
        setColumns(formatColumns(demandsData.demands));
      } catch (error) { }
    },
    [customerId, businessId]
  );

  useEffect(() => {
    if (customerId) fetchDemandsData(query);
  }, [customerId, fetchDemandsData, query]);

  return (
    <React.Fragment>
      <MiniDrawer
        main={
          <Container>
            <ColumnContainer>
              {columns &&
                columns.map((column, idx) => (
                  <Column
                    key={column.backofficeUserId || column.columnStatus}
                    index={idx}
                    columnTitle={column.title}
                    backofficeUserId={column.backofficeUserId}
                    columnStatus={column.columnStatus}
                    demands={column.cards}
                    totalDemands={column.cards.length}
                    isCustomerDemandsBoard={true}
                    ignoreCheckedOrdenation={true}
                  />
                ))}

              {demands && (
                <ColumnStats
                  totalDemands={demands
                    .filter(demand => !demand.isMassDemand)
                    .reduce((prev, current) => prev + current.finishedCount, 0)
                  }
                  uniqueDemands={demands?.filter(demand => !demand.isMassDemand)?.length}
                >
                  <Form
                    style={{
                      width: '250px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      marginBottom: '10px',
                    }}
                  >
                    <div
                      style={{
                        position: 'relative',
                        width: '100%',
                        marginRight: '10px',
                      }}
                    >
                      <Calendar
                        name='startDate'
                        label='De'
                        onChange={e => {
                          setQuery(prevState => ({
                            ...prevState,
                            startDate: e,
                          }));
                        }}
                      />
                    </div>

                    <div
                      style={{
                        position: 'relative',
                        width: '100%',
                      }}
                    >
                      <Calendar
                        name='endDate'
                        label='Até'
                        onChange={e => {
                          setQuery(prevState => ({
                            ...prevState,
                            endDate: e,
                          }));
                        }}
                      />
                    </div>
                  </Form>
                </ColumnStats>
              )}
            </ColumnContainer>
          </Container>
        }
      />
    </React.Fragment >
  );
}
