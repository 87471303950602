import React, { useEffect, useRef, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import CustomInput from '../../components/input/CustomInput';
import CustomSelectWithSearch from '../../components/input/customSelectorWithSearch/CustomSelectWithSearch';
import FileInputMult from '../../components/input/FileInputMult';
import Loading from '../../components/Loading';
import MiniDrawer from '../../components/MiniDrawer';
import apiOld from '../../services/Api';
import { openDemandModal, useQuery } from '../../routes';
import CustomTextArea from '../../components/input/CustomTextArea';
import { toast } from 'react-toastify';
import CheckboxInput from '../../components/input/CheckboxInput';
import customerApi from '../../services/apis/customerApi';
import { DEMAND_DESCRIPTIONS } from '../../constants/DefaultTexts';
import CustomerJourneyService from '../../services/journeys/customerJourneyService';
import DemandService from '../../services/demandService';
import businessApi from '../../services/apis/businessApi';

const customerJourneyService = new CustomerJourneyService();
const demandService = new DemandService();

const useStyles = makeStyles(() => ({
  main: {
    background: '#FFF',
  },
  mainTitle: {
    background: '#FFF',
    borderTop: 'solid 2px #e7eaec',
    borderBottom: 'solid 1px #e7eaec',
    padding: '15px',
  },
  line: {
    backgorund: 'reds',
  },
  title: {
    fontSize: '15px',
  },
  mainForm: {
    padding: '10px',
  },
  form: {
    width: '100%',
  },
  input: {
    padding: '10px',
  },
  buttonContainer: {
    justifyContent: 'flex-end',
    padding: '10px',
  },
  submit: {
    padding: '10px 15px',
    fontSize: '17px',
    background: '#1ab394',
    border: 'none',
    color: '#fff',
    borderRadius: '5px',
  },
  subTitle: {
    background: '#FFF',
    borderBottom: 'solid 1px #e7eaec',
  },
}));

export default function CreateDemand() {
  const search = useQuery();

  const classes = useStyles();
  const formRef = useRef(null);
  const [state, setState] = useState({
    demandCustomerId: search.get('customerId'),
    demandCustomerUsers: [],
    demandCustomerUserId: '',
    customerJourneys: [],
    demandBusinesses: [],
    demandPrivate: false,
  });

  const [submitLoading, setSubmitLoading] = useState(false);
  const [content, setContent] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    apiOld.get('/customers').then(response => {
      setContent(response.data);
    });

    setLoading(false);
  }, []);

  const _fetchCustomerUsers = async customerId => {
    try {
      const customerUsers = (await customerApi.getCustomerUsers(customerId))
        ?.data;

      setState(prevState => ({
        ...prevState,
        demandCustomerUsers: customerUsers,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const _fetchBusinesses = async customerId => {
    try {
      const businesses = (await businessApi.getByCustomerId(customerId))?.data;

      setState(prevState => ({
        ...prevState,
        demandBusinesses: businesses,
      }));
    } catch (error) {
      console.error(error);
    }
  };



  const _fetchCustomerJourneys = async customerId => {
    try {
      formRef.current?.clearField('journeyId');

      const customerJourneys = (
        await customerJourneyService.listAllCustomerJourneys({
          customerId,
          withJourneyType: true,
        })
      )?.data.customerJourneys;

      setState(prevState => ({
        ...prevState,
        customerJourneys: customerJourneys,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (state.demandCustomerId) {
      _fetchCustomerUsers(state.demandCustomerId);
      _fetchCustomerJourneys(state.demandCustomerId);
      _fetchBusinesses(state.demandCustomerId);
    }
  }, [state.demandCustomerId]);

  async function handleSubmit(data, { reset }) {
    try {
      setSubmitLoading(true);
      formRef.current.setErrors({});

      console.log(data)

      const newDemand = await demandService.createDemand(data);

      toast.success('Demanda criada com sucesso!');

      if (data.privateDemand === true) {
        document.getElementById('privateDemand').click()

        setState({
          ...state,
          demandPrivate: false,
        });
      }
      reset();
      openDemandModal(newDemand.id);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};

        error.inner.forEach(error => {
          validationErrors[error.path] = error.message;

          toast.error(error.message, {
            toastId: 'validationError',
          });
        });

        formRef.current.setErrors(validationErrors);
      }
    } finally {
      setSubmitLoading(false);
    }
  }

  return (
    <React.Fragment>
      <MiniDrawer
        currentExpaded='demands'
        main={
          <Grid container className={classes.main}>
            <Loading open={loading} />
            {content && (
              <>
                <Grid container className={classes.mainTitle}>
                  <h2 className={classes.title}>Criar Demanda</h2>
                </Grid>
                <Grid container className={classes.mainForm}>
                  <Form
                    ref={formRef}
                    onSubmit={handleSubmit}
                    className={classes.form}
                    initialData={{
                      name: search.get('name'),
                      description: search.has('descriptionKey')
                        ? DEMAND_DESCRIPTIONS[search.get('descriptionKey')]
                        : '',
                    }}
                  >
                    <Grid container>
                      <Grid item={true} className={classes.input} xs={12}>
                        <CustomInput name='name' label='Nome*' />
                      </Grid>

                      <Grid item={true} className={classes.input} xs={12}>
                        <CustomTextArea
                          name='description'
                          label='Descrição'
                          placeholder='Descrição detalhada'
                          maxLength='2048'
                          resize={search.has('descriptionKey')}
                        />
                      </Grid>

                      <Grid item={true} className={classes.input} xs={6}>
                        <CustomSelectWithSearch
                          placeholder='Cliente'
                          name='customerId'
                          label='Cliente'
                          defaultValue={search.get('customerId') || null}
                          options={content.customers?.map(customer => ({
                            name: customer.name,
                            value: customer.id,
                          }))}
                          onChange={customerId => {
                            formRef.current.clearField('demandUserId');
                            setState({
                              ...state,
                              demandCustomerId: customerId,
                            });
                          }}
                        />
                      </Grid>

                      <Grid item={true} className={classes.input} xs={6}>
                        <FileInputMult name='attachments' label='Anexos' />
                      </Grid>

                      <Grid container={true} className={classes.input}>
                        {state.demandCustomerId && (
                          <>
                            <Grid item xs={6} className='inputContainer'>
                              <CustomSelectWithSearch
                                placeholder='Jornada'
                                name='journeyId'
                                label='Adicionar à jornada'
                                options={state.customerJourneys?.map(
                                  customerJourney => ({
                                    name: customerJourney.journeyType.title,
                                    value: customerJourney.id,
                                  })
                                )}
                              />
                            </Grid>

                            <Grid item xs={6} className='inputContainer'>
                              <CustomSelectWithSearch
                                placeholder='Negócio'
                                name='businessId'
                                label='Selecione um negócio'
                                options={state.demandBusinesses?.map(
                                  business => ({
                                    name: business.name,
                                    value: business.id,
                                  })
                                )}
                              />
                            </Grid>

                            <Grid item={true} className={classes.input} xs={6}>
                              <CustomSelectWithSearch
                                placeholder='Usuário'
                                name='demandUserId'
                                label='Usuário do cliente'
                                options={state.demandCustomerUsers?.map(
                                  user => ({
                                    name: user.name,
                                    value: user.id,
                                  })
                                )}
                                onChange={demandUserId => {
                                  formRef.current.clearField(
                                    'demandUserFollowingIds'
                                  );
                                  setState({
                                    ...state,
                                    demandCustomerUserId: demandUserId,
                                  });
                                }}
                              />
                            </Grid>
                            {state.demandCustomerUserId &&
                              <Grid
                                item={true}
                                className={classes.input}
                                xs={6}
                              >
                                <CustomSelectWithSearch
                                  multiple={true}
                                  placeholder='Incluir usuários notificáveis'
                                  name='demandUserFollowingIds'
                                  printOptions='on-focus'
                                  label='Usuários notificáveis (além do usuário da demanda)'
                                  options={state.demandCustomerUsers
                                    ?.filter(
                                      user =>
                                        user.id !== state.demandCustomerUserId
                                    )
                                    .map(user => ({
                                      name: user.name,
                                      value: user.id,
                                    }))}
                                />
                              </Grid>
                            }
                          </>
                        )}
                      </Grid>

                      <Grid item={true} className={classes.input} xs={6}>
                        <CheckboxInput
                          name='privateDemand'
                          label='Sigilosa'
                          id="privateDemand"
                          onChange={e => {
                            setState({
                              ...state,
                              demandPrivate: e.target.checked,
                            });
                          }}
                        />
                      </Grid>

                      <Grid container className={classes.buttonContainer}>
                        <button className={classes.submit} type='submit'>
                          {!submitLoading && 'Cadastrar'}
                          {submitLoading && (
                            <CircularProgress color='inherit' />
                          )}
                        </button>
                      </Grid>
                    </Grid>
                  </Form>
                </Grid>
              </>
            )}
          </Grid>
        }
      />
    </React.Fragment>
  );
}
