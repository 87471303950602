import React from 'react';
import PropTypes from 'prop-types';

import history from '../../history';

import Modal from '../modal/Modal';
import Table from '../../components/Table';

import { Container } from './CustomersModal.styles';

function CustomersModal({
  visible,
  title,
  subtitle = 'Clientes',
  route,
  headCells,
  handleRows,
  customParams,
  handleCloseCustomersModal,
  ...rest
}) {
  function handleClickItem(id) {
    history.push(`/customers/${id}/`);
  }
  return (
    <Modal visible={visible} {...rest}>
      <Container>
        <i
          className='fa fa-2x fa-times closeModal'
          onClick={() => {
            history.replace({
              search: '',
            });
            handleCloseCustomersModal();
          }}
        />
        <h1>{title}</h1>
        <Table
          title={subtitle}
          headCells={headCells}
          handleRows={handleRows}
          handleClickItem={handleClickItem}
          route={route}
          hasActions={false}
          customParams={customParams}
        />
      </Container>
    </Modal>
  );
}

CustomersModal.propTypes = {
  visible: PropTypes.bool,
  actualTags: PropTypes.array,
  handleFetchTags: PropTypes.func,
  handleAddTag: PropTypes.func,
  handleRemoveTag: PropTypes.func,
  handleCloseCustomersModal: PropTypes.func,
};

export default CustomersModal;
