import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../../components/Loading";
import MiniDrawer from "../../../components/MiniDrawer";
import apiOld from "../../../services/Api";

const useStyles = makeStyles(() => ({
  main: {
    background: "#FFF",
  },
  mainTitle: {
    background: "#FFF",
    borderTop: "solid 2px #e7eaec",
    borderBottom: "solid 1px #e7eaec",
    padding: "15px",
  },
  line: {
    backgorund: "reds",
  },
  title: {
    fontSize: "15px",
  },
  mainForm: {
    padding: "10px",
  },
  form: {
    width: "100%",
  },
  item: {
    padding: "10px",
  },
  buttonContainer: {
    justifyContent: "flex-end",
    padding: "10px",
  },
  submit: {
    padding: "10px 15px",
    fontSize: "17px",
    background: "#1ab394",
    border: "none",
    color: "#fff",
    borderRadius: "5px",
  },
  fields: {
    listStyleType: "none",
    marginTop: "10px",
  },
  field: {
    padding: "10px 15px",
    border: "1px solid #e7eaec",
  },
  download: {
    cursor: "pointer",
    "&:hover": {
      color: "#1ab394",
    },
  },
}));

export default function DemandTypeProfile() {
  const classes = useStyles();
  const [content, setContent] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    setLoading(true);
    apiOld.get(`/demands/types/${id}`).then((response) => {
      setContent(response.data);
    });
    setLoading(false);
  }, [id]);

  return (
    <React.Fragment>
      <MiniDrawer
        currentExpaded="demandTypes"
        main={
          <Grid container className={classes.main}>
            <Loading open={loading} />
            {content && (
              <>
                <Grid container className={classes.mainTitle}>
                  <h2 className={classes.title}>Tipo de Demanda</h2>
                </Grid>
                <Grid container className={classes.mainForm}>
                  <Grid container>
                    <Grid item className={classes.item} xs={6}>
                      <h3>Nome:</h3>
                      <h4>{content.name}</h4>
                    </Grid>
                    <Grid item className={classes.item} xs={6}>
                      <h3>Descrição:</h3>
                      <h4>{content.description}</h4>
                    </Grid>
                    <Grid item className={classes.item} xs={6}>
                      <h3>SLA (Em dias úteis):</h3>
                      <h4>{content.deliveryTime}</h4>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        }
      />
    </React.Fragment>
  );
}
