import styled from "styled-components";
import { ToastContainer } from "react-toastify";

export const StyledToastContainer = styled(ToastContainer)`
  z-index: 200000;

  .Toastify__toast-container {
  }
  .Toastify__toast {
    .Toastify__toast-body {
      color: #262626;
    }
  }
  .Toastify__toast--error {
    .Toastify__close-button > svg {
      fill: #fff;
    }

    .Toastify__toast-body {
      color: #fff;
    }
  }
  .Toastify__toast--warning {
    background-color: #f2b90f;

    .Toastify__close-button > svg {
      fill: #fff;
    }

    .Toastify__progress-bar {
      // background-color: #fff;
    }

    .Toastify__toast-body {
      color: #fff;
    }
  }
  .Toastify__toast--success {
    background-color: #52e068;

    .Toastify__close-button > svg {
      fill: #fff;
    }

    .Toastify__toast-body {
      color: #fff;
    }
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
`;
