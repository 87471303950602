import styled from 'styled-components';

export const EditFieldPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);

  h1 {
    font-size: 18px;
    margin-bottom: 15px;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;

    .inputContainer {
      margin-bottom: 15px;
    }

    button {
      padding: 5px 10px;
      font-size: 13px;
      border: none;
      color: #fff;
      border-radius: 5px;
      height: fit-content;
      background-color: #222222;

      &[type='submit'] {
        background-color: #1ab394;
      }
    }
  }
`;
