import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import styled from 'styled-components';
import { Constants } from '../../utils/Constants';

const Label = styled.label`
  border-radius: 10px;
  text-align: center;
  padding: 5px 10px;
  background-color: ${props => {
    switch (props.name) {
      case Constants.DEFAULT_TAGS.OVERDUE:
        return '#ea5f59';
      case Constants.DEFAULT_TAGS.CUSTOMER_PREMIUM:
        return '#52e068';
      default:
        return '#222222';
    }
  }};
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  font-weight: normal;
  font-size: 13px;
  transition: transform 0.2s;
  margin: 3px;

  i {
    margin-left: auto;
    padding-left: 5px;
    font-size: 0.3rem;
  }

  ${props =>
    !props.disabled
      ? `
      cursor: pointer;

      :hover {
        transform: scale(1.08);
      }
  `
      : ''}
`;

const Input = styled.input`
  display: none;
`;

export const TagComponent = ({ name, description, ...rest }) => (
  <Label title={description} name={name} {...rest}>
    {name}

    <i className='fas fa-circle fa-lg'></i>
  </Label>
);

export default function TagInput({ name, description = '', ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    try {
      registerField({
        name: fieldName,
        ref: inputRef.current,
        path: 'value',
      });
    } catch (error) {}
  }, [fieldName, registerField]);

  return (
    <Label title={description} name={name} htmlFor={inputRef} {...rest}>
      {name}

      <Input readOnly={true} inputRef={inputRef} value={name} {...rest} />

      <i className='fas fa-circle fa-lg'></i>
    </Label>
  );
}
