import * as Yup from 'yup';
import TemplateField from '../TemplateField';

export default class EditTemplateFileData {
  templateFile;
  newFields = [];
  removedFieldsIds = [];

  constructor(templateData = {}) {
    this.templateFile = templateData.templateFile;
    this.newFields = templateData.newFields || [];
    this.removedFieldsIds = templateData.removedFieldsIds || [];
  }

  validateData() {
    return Yup.object()
      .shape({
        templateFile: Yup.mixed().required().label('Novo arquivo'),
        newFields: Yup.array()
          .of(TemplateField.createFieldYupSchema)
          .label('Novos campos'),
        removedFieldsIds: Yup.array()
          .of(Yup.string().required().label('Id do campo removido'))
          .label('Novos campos'),
      })
      .validate(this, {
        abortEarly: false,
      });
  }

  getDataWith(awsFileKey) {
    return {
      awsFileKey,
      newFields: this.newFields,
      removedFieldsIds: this.removedFieldsIds,
    };
  }
}
