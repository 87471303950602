import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useState } from 'react';
import CustomersModal from '../customersModal/CustomersModal';
import {
  CustomerListsContainer,
  CustomerListContent,
  SimpleTable,
} from './churn.styles';

export default function CAMetrics({ metrics }) {
  const [customerModal, setCustomerModal] = useState({
    open: false,
    route: '',
    title: '',
    headCells: [],
    handleRows: () => ({}),
    customParams: {},
  });

  return (
    <CustomerListsContainer>
      {(metrics?.loading || !metrics?.content) && <CircularProgress />}

      <CustomerListContent>
        {metrics?.content && (
          <SimpleTable.Container>
            <SimpleTable.Table>
              <SimpleTable.TableHead>
                <SimpleTable.TableRow>
                  <SimpleTable.TableHeadCell
                    style={{
                      width: '25%',
                    }}
                  >
                    Contato Ativo nos
                  </SimpleTable.TableHeadCell>
                  <SimpleTable.TableHeadCell
                    style={{
                      width: '25%',
                      textAlign: 'center',
                    }}
                  >
                    Aguardando contato
                  </SimpleTable.TableHeadCell>
                  <SimpleTable.TableHeadCell
                    style={{
                      width: '25%',
                      textAlign: 'center',
                    }}
                  >
                    Reunião realizada
                  </SimpleTable.TableHeadCell>
                  <SimpleTable.TableHeadCell
                    style={{
                      width: '25%',
                      textAlign: 'center',
                    }}
                  >
                    Percentual de reuniões realizadas
                  </SimpleTable.TableHeadCell>
                </SimpleTable.TableRow>
              </SimpleTable.TableHead>

              <SimpleTable.TableBody>
                {[
                  {
                    period: 'Últimos 7 dias',
                    in: metrics?.content
                      ?.contactTimeLastWeek.in,
                    out: metrics?.content
                      ?.contactTimeLastWeek.out,
                  },
                  {
                    period: 'Últimos 30 dias',
                    in: metrics?.content
                      ?.contactTimeLastMonth.in,
                    out: metrics?.content
                      ?.contactTimeLastMonth.out,
                  },
                  {
                    period: 'Últimos 90 dias',
                    in: metrics?.content
                      ?.contactTimeLast3Months.in,
                    out: metrics?.content
                      ?.contactTimeLast3Months.out,
                  },
                ].map(statistics => (
                  <SimpleTable.TableRow
                    key={statistics.period}
                    onClick={() =>
                      setCustomerModal({
                        open: true,
                        title: 'Detalhes contato ativo',
                        subtitle: 'teste',
                        route: 'metrics/contact-time',
                        headCells: [
                          {
                            id: 'name',
                            numeric: false,
                            label: 'Nome',
                          },
                          {
                            id: 'backofficeUserName',
                            numeric: false,
                            label: 'Responsável pelo contato',
                          },
                          {
                            id: 'done',
                            numeric: false,
                            label: 'Finalizado ?',
                          },

                        ],
                        handleRows: data => {
                          let contacts;
                          switch (statistics.period) {
                            case 'Últimos 7 dias':
                              contacts = data.contactTimeLastWeek.contacts
                              break;
                            case 'Últimos 30 dias':
                              contacts = data.contactTimeLastMonth.contacts

                              break;
                            case 'Últimos 90 dias':
                              contacts = data.contactTimeLast3Months.contacts

                              break;
                            default:
                              return null
                          }
                          return ({

                            rows: contacts.map(contact => {
                              return (
                                {
                                  id: contact.customerId,
                                  name: contact.customerName,
                                  backofficeUserName: contact.backofficeUserName,
                                  done: contact.done === true ? 'Sim' : 'Não'
                                }                           

                              )
                            }),
                            total: data.total,
                          })
                        },
                        customParams: {
                          name: statistics.period,
                          withValue: true,
                        },
                      })
                    }
                  >
                    <SimpleTable.TableData>
                      {statistics.period}
                    </SimpleTable.TableData>

                    <SimpleTable.TableData
                      style={{
                        textAlign: 'center',
                        color: '#ea5f59',
                        cursor: 'pointer',
                      }}

                    >
                      {statistics.in}
                    </SimpleTable.TableData>
                    <SimpleTable.TableData
                      style={{
                        textAlign: 'center',
                        color: '#1ab394',
                        cursor: 'pointer',
                      }}
                    >
                      {statistics.out}
                    </SimpleTable.TableData>
                    <SimpleTable.TableData
                      style={{
                        textAlign: 'center',
                        color: '#1994FF',
                        cursor: 'pointer',
                      }}

                    >
                      {statistics?.in > 0
                        ? `${Number(
                          (statistics?.out * 100) /
                          statistics?.in
                        ).toFixed(2)} %`
                        : 'Indefinido'}
                    </SimpleTable.TableData>
                  </SimpleTable.TableRow>
                ))}
              </SimpleTable.TableBody>
            </SimpleTable.Table>
          </SimpleTable.Container>
        )}
      </CustomerListContent>
      {
        customerModal.open && (
          <CustomersModal
            visible={customerModal.open}
            route={customerModal.route}
            title={customerModal.title}
            subtitle={customerModal.subtitle}
            headCells={customerModal.headCells}
            handleRows={customerModal.handleRows}
            customParams={customerModal.customParams}
            handleCloseCustomersModal={() =>
              setCustomerModal({
                open: false,
                route: '',
                title: '',
                headCells: [],
                handleRows: () => ({}),
                customParams: {},
              })
            }
          />
        )
      }
    </CustomerListsContainer >
  );
}
