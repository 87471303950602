import React, { useEffect, useState} from "react";
import MiniDrawer from "../../components/MiniDrawer";
import { CircularProgress, Grid, makeStyles } from "@material-ui/core";
import Loading from "../../components/Loading";
import { Form } from "@unform/web";
import CustomInput from "../../components/input/CustomInput";
import CustomTextArea from "../../components/input/CustomTextArea";
import { popupApi } from "../../services/apis/popupApi";

import moment from "moment";
import { toast } from "react-toastify";
import Modal from "../../components/modal/Modal";

export default function PopUp() {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [popupList, setPopupList] = useState([]);
  const [popup, setPopup] = useState(null);

  const useStyles = makeStyles(() => ({
    main: {
      background: '#fff',
      padding: '10px',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '80%',
      width: '100%',
      background: '#fff',
    },
    mainTitle: {
      background: '#FFF',
      borderBottom: 'solid 1px #e7eaec',
      padding: '15px',
    },
    modal: {
      background: '#FFF',
      padding: '2rem',
    },
    mainSubtitle : {
        background: '#FFF',
        borderTop: 'solid 1px #e7eaec',
        padding: '15px',
      },
    line: {
      backgorund: 'reds',
    },
    title: {
      fontSize: '1.2rem',
    },
    form: {
      width: '100%',
    },
    input: {
      padding: '10px',
    },
    buttonContainer: {
      justifyContent: 'flex-end',
      padding: '10px',
    },
    submit: {
      padding: '12px 2rem',
      fontSize: '17px',
      background: '#1ab394',
      border: 'none',
      color: '#fff',
      borderRadius: '5px',
    },
    subTitle: {
      background: '#FFF',
      borderBottom: 'solid 1px #e7eaec',
    },
    button: {
      padding: '10px 20px',
      fontSize: '16px',
      background: '#1ab394',
      border: 'none',
      color: '#fff',
      borderRadius: '4px',
      textDecoration: 'none',
      cursor: 'pointer',
      marginLeft: '1rem',
    },
  }));

  const classes = useStyles();
  const fetch = async () => {
    setLoading(true);
    const popups = (await popupApi.getAll()).data;   
    setPopupList(popups);
    setLoading(false);
  };

  const handleSubmit = async (data) => {
    try {
      setSubmitLoading(true);
      const request = (await popupApi.create(data)).data;
      toast.success('PopUp criado com sucesso!');
      setPopupList([...popupList, request]);
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitLoading(false);
    }
  };

  const deletePopup = async (id) => {
    await popupApi.delete(id);
    toast.success('PopUp deletado com sucesso!');
    const newList = popupList.filter((popup) => popup.id !== id);
    setPopupList(newList);
  };

  const getPopupById = async (id) => {
    const popup = (await popupApi.getById(id)).data;
    setPopup(popup);
  };

  const formatDate = (date) => {
    return moment(date).format('DD/MM/YYYY');
  };

  const handleEdit = async (data) => {
    console.log(data);
    try {
      const request = (await popupApi.update(popup.id, data)).data;
      toast.success('PopUp editado com sucesso!');
      const newList = popupList.map((popup) => {
        if (popup.id === request.id) {
          return request;
        }
        return popup;
      });
      setPopupList(newList);
    } catch (error) {
      console.log(error);
    } finally {
      setOpen(false);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  const HandleForm = ({dataEditable, onSubmit}) => {
    return (
      <Form
        onSubmit={onSubmit}
        className={classes.form}
      >
        <Grid container>
          <Grid item className={classes.input} xs={12}>
            <CustomInput 
              name='title' 
              label='Título do evento*'
              defaultValue={dataEditable ? dataEditable.title : ''}
            />
          </Grid>

          <Grid item className={classes.input} xs={12}>
            <CustomTextArea
              name='description'
              label='Descrição'
              placeholder='Descrição do evento'
              maxLength='2048'
              defaultValue={dataEditable ? dataEditable.description : ''}
            />
          </Grid>

          <Grid item className={classes.input} xs={12}>
            <CustomInput 
              name='link' 
              label='link do presente' 
              defaultValue={dataEditable ? dataEditable.link : ''}
            />
          </Grid>

          <Grid item className={classes.input} xs={12}>
            <CustomInput 
              name='img' 
              label='link da imagem' 
              defaultValue={dataEditable ? dataEditable.img : ''}
            />
          </Grid>

          <Grid container xs={12}>
            <Grid item className={classes.input} xs={6}>
              <CustomInput 
                name='dateInit' 
                label='Data de início*' 
                type='date'
              />
            </Grid>
            <Grid item className={classes.input} xs={6}>
              <CustomInput 
                name='dateEnd' 
                label='Data de termino*' 
                type='date' 
              />
            </Grid>
          </Grid>
          
          <Grid container className={classes.buttonContainer} style={{margin: '1rem 0'}}>
            <button className={classes.submit} type='submit'>
              {submitLoading ? (
                <CircularProgress size={24} color='inherit'/>
              ) : (
                `${dataEditable ? 'Editar' : 'Criar'}`
              )}
            </button>
          </Grid>
        </Grid>
      </Form>
    )
  }

  return (
    <React.Fragment>
      <MiniDrawer
        currentExpaded='popup'
        main={
            <Grid container className={classes.main}>
              <Loading open={loading} />
              <Modal visible={open}>
                <Grid container className={classes.modal} xs={6}>
                  <h1 onClick={() => setOpen(false)} style={{cursor: 'pointer'}}>x</h1>
                  <HandleForm dataEditable={popup} onSubmit={handleEdit} />
                </Grid>
              </Modal>
              <Grid container className={classes.mainTitle}>
                <h2 className={classes.title}>Criar PopUp</h2>
              </Grid>
              <Grid container className={classes.container}>
                <HandleForm onSubmit={handleSubmit} />

                <Grid container className={classes.mainTitle} style={{marginTop: '1rem'}}>
                  <h2 className={classes.title}>Lista de PopUp's</h2>
                </Grid>

                <Grid container>
                  <Grid item className={classes.input} xs={12}>
                    <h2 className={classes.title}>Evento</h2>
                  </Grid>

                  {
                    popupList.map((popup, index) => (
                      <Grid container key={popup.id}>
                        <Grid item className={classes.input} xs={5}>
                    <label>Nome</label>
                    <p>{popup.title}</p>
                  </Grid>

                  <Grid item className={classes.input} xs={2}>
                    <label>Data inicio</label>
                    <p>{formatDate(popup.dateInit)}</p>
                  </Grid>

                  <Grid item className={classes.input} xs={2}>
                    <label>Data fim</label>
                    <p>{formatDate(popup.dateEnd)}</p>
                  </Grid>

                  <Grid item className={classes.input} xs={3}>
                    <button 
                      className={classes.button}
                      onClick={() => {
                        setOpen(true);
                        getPopupById(popup.id);
                      }}
                    >Editar</button>
                    <button 
                      className={classes.button}
                      style={{ background: '#d31f12'}}
                      onClick={() => deletePopup(popup.id)}
                    >Deletar</button>
                  </Grid>
                      </Grid>
                    ))
                  }
                </Grid>
              </Grid>
            </Grid>              
          }
        />
      </React.Fragment>      
  )
}