import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999;
  opacity: 1;
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  position: relative;
  padding: 30px;
  background: #f5f6fa;
  border-radius: 15px;
  i {
    align-self: flex-end;
    position: absolute;
    cursor: pointer;
  }
`;

export const UsersContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 0px;
`;

export const User = styled.div`
  border-radius: 10px;
  text-align: center;
  padding: 5px 10px;
  background-color: #222222;
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  font-weight: normal;
  font-size: 13px;
  transition: transform 0.2s;
  margin: 3px;

  ${props =>
    !props.disabled
      ? `
      cursor: pointer;

      :hover {
        transform: scale(1.08);
      }
  `
      : ''}
`;
