import demandsApi from '../../../services/apis/demandsApi';
import { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import DemandGraph from './graph';
import { calculatePercentage, filterFinishedDemands, sortDemandsByMonthYear } from './defaultFunctions';

export default function AllDemandFinishedGraph() {
  const [demandByMounth, setDemandByMounth] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    function groupDemandsByMonthYear(demands) {
      const result = [];
      for (const demand of demands) {
        const monthYear = moment(demand.updatedAt).format('MMM YYYY');
        let group = result.find((g) => g.monthYear === monthYear);

        if (!group) {
          group = { monthYear, finishedCount: 0 };
          result.push(group);
        }
        group.finishedCount += demand.finishedCount;

      }
      return result;
    }

    const cumulativeFinishedCount = (demands) => {
      let sum = 0;
    
      return demands.map((obj) => {
        sum += obj.finishedCount;
        return { monthYear: obj.monthYear, finishedCount: sum };
      });
    };
    

    async function fetchDemands() {
      setLoading(true);
      const demands = (await demandsApi.getAllDemands()).data.demands?.filter(demand => !demand.isMassDemand);
      const demandsFinished = filterFinishedDemands(demands);
      const demandsByMonthYear = groupDemandsByMonthYear(demandsFinished);
      const sortedDemands = sortDemandsByMonthYear(demandsByMonthYear);
      const cumulativeValues = cumulativeFinishedCount(sortedDemands);
      setDemandByMounth(cumulativeValues);
      setLoading(false);
    }
    fetchDemands();
  }, []);

  const getProgressPercentage = useCallback(
    tooltipItems => {
      return `Em ${moment(tooltipItems[0].label, 'MMM').format('MMMM')}`;
    },
    []
  );

  return (
    <DemandGraph
      title="Demandas Finalizadas (TODAS)"
      loading={loading}
      demand={demandByMounth}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            callbacks: {
              footer: getProgressPercentage,
            },
          },
        },
      }}
      data={{
        labels: demandByMounth?.map(demand => demand.monthYear),
        datasets: [{
          label: 'Demandas Finalizadas',
          backgroundColor: '#084d6e',
          data: demandByMounth?.map(demand => demand.finishedCount),
        }],
        percentages: calculatePercentage(demandByMounth),
      }}
    />
  );
}