import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from '@material-ui/core/styles';
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Loading from '../../../components/Loading';
import Grid from '@material-ui/core/Grid';
import CustomInput from '../../../components/input/CustomInput';
import MiniDrawer from '../../../components/MiniDrawer';
import history from '../../../history';
import apiOld from '../../../services/Api';
import CustomSelectWithSearch from '../../../components/input/customSelectorWithSearch/CustomSelectWithSearch';
import { useCallback } from 'react';
import { toast } from 'react-toastify';

const useStyles = makeStyles(() => ({
  main: {
    background: '#FFF',
  },
  mainTitle: {
    background: '#FFF',
    borderTop: 'solid 2px #e7eaec',
    borderBottom: 'solid 1px #e7eaec',
    padding: '15px',
  },
  line: {
    backgorund: 'reds',
  },
  title: {
    fontSize: '15px',
  },
  mainForm: {
    padding: '10px',
  },
  form: {
    width: '100%',
  },
  input: {
    padding: '10px',
  },
  buttonContainer: {
    justifyContent: 'flex-end',
    padding: '10px',
  },
  submit: {
    padding: '10px 15px',
    fontSize: '17px',
    background: '#1ab394',
    border: 'none',
    color: '#fff',
    borderRadius: '5px',
  },
  download: {
    cursor: 'pointer',
    '&:hover': {
      color: '#1ab394',
    },
  },
}));

export default function EditDemandType() {
  const { id } = useParams();
  const classes = useStyles();
  const formRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [content, setContent] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      const response = await apiOld.get(`/demands/types/${id}`);
      const responseForms = await apiOld.get('/forms');

      setContent({ ...response.data, forms: responseForms.data.forms });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  async function handleSubmit(data) {
    setSubmitLoading(true);

    try {
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        descrição: Yup.string().required(),
        nome: Yup.string().required(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const request = {
        name: data.nome,
        description: data.descrição,
      };

      await apiOld.put(`/demands/types/${id}`, request);

      toast.success('Tipo de demanda atualizado com sucesso!');

      history.push(`/demands/types/${id}`);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
    setSubmitLoading(false);
  }

  return (
    <React.Fragment>
      <MiniDrawer
        currentExpaded='demandTypes'
        main={
          <Grid container className={classes.main}>
            <Loading open={loading} />
            {content && (
              <>
                <Grid container className={classes.mainTitle}>
                  <h2 className={classes.title}>Editar Tipo de Demanda</h2>
                </Grid>
                <Grid container className={classes.mainForm}>
                  <Form
                    ref={formRef}
                    onSubmit={handleSubmit}
                    className={classes.form}
                  >
                    <Grid container>
                      <Grid item className={classes.input} xs={12}>
                        <CustomInput
                          name='nome'
                          label='Nome*'
                          defaultValue={content.name}
                        />
                      </Grid>
                      <Grid item className={classes.input} xs={12}>
                        <CustomInput
                          name='descrição'
                          label='Descrição*'
                          defaultValue={content.description}
                        />
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <CustomSelectWithSearch
                          placeholder='Nenhum formulário'
                          name='formulário'
                          label='Formulário'
                          defaultValue={content.formId}
                          options={content.forms?.map(form => ({
                            name: form.name,
                            value: form.id,
                          }))}
                        />
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <CustomInput
                          type='number'
                          name='SLA'
                          label='SLA (Em dias úteis)*'
                          defaultValue={content.deliveryTime}
                        />
                      </Grid>
                      <Grid
                        container
                        className={classes.buttonContainer}
                        xs={12}
                      >
                        <button className={classes.submit} type='submit'>
                          {!submitLoading && 'Atualizar'}
                          {submitLoading && (
                            <CircularProgress color='inherit' />
                          )}
                        </button>
                      </Grid>
                    </Grid>
                  </Form>
                </Grid>
              </>
            )}
          </Grid>
        }
      />
    </React.Fragment>
  );
}
