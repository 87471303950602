import apiOld from '../Api';

const customerJourneyDemandApi = {
  updateCustomerJourneyDemand: async function (demandId, data = {}) {
    return apiOld.put(`/customerJourneyDemands/${demandId}`, data);
  },

  removeDemandFromCustomerJourney: async function (demandId) {
    return apiOld.delete(`/customerJourneyDemands/${demandId}`);
  },

  getCustomerJourneyDemandById: async function (demandId) {
    return apiOld.get(`/customerJourneyDemands/${demandId}`);
  },

  listAllCustomerJourneyDemands: async function (params = {}) {
    return apiOld.get('/customerJourneyDemands', {
      params,
    });
  },
};

export default customerJourneyDemandApi;
