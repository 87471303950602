import React, { useCallback, useEffect, useRef, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import CustomInput from '../../components/input/CustomInput';
import MiniDrawer from '../../components/MiniDrawer';
import { PageContainer } from '../../components/Pages.styles';
import CustomSelectWithSearch from '../../components/input/customSelectorWithSearch/CustomSelectWithSearch';
import customerApi from '../../services/apis/customerApi';
import { useSelector } from 'react-redux';
import JourneyTypeService from '../../services/journeys/journeyTypeService';
import CustomerJourneyService from '../../services/journeys/customerJourneyService';
import history from '../../history';
import businessApi from '../../services/apis/businessApi';

const journeyTypeService = new JourneyTypeService();
const customerJourneyService = new CustomerJourneyService();

export default function CreateCustomerJourney() {
  const demandTypes = useSelector(state => state.demandTypes.all);
  const createCustomerJourneyUnformRef = useRef(null);
  const [state, setState] = useState({
    customers: [],
    journeyTypes: [],
    journeyCustomer: undefined,
    journeyCustomerUsers: undefined,
    journeyTypeId: undefined,
    journeyTypeDefaultDemands: [],
    businesses: [],
    selectedBusiness: undefined,
  });



  const _handleCustomerChange = async (customerId) => {
    createCustomerJourneyUnformRef.current.clearField('journeyCustomerUserId');

    const businesses = (await businessApi.getByCustomerId(customerId))?.data;


    setState({
      ...state,
      journeyCustomer: state.customers.find(c => c.id === customerId),
      journeyTypeId: undefined,
      businesses
    });
  };

  const _handleJourneyTypeChange = journeyTypeId => {
    setState({
      ...state,
      journeyTypeId: journeyTypeId,
      journeyTypeDefaultDemands: state.journeyTypes.find(
        jt => jt.id === journeyTypeId
      ).defaultDemands,
    });
  };

  const _handleAddDefaultDemand = defaultDemandId => {
    const newJourneyTypeDefaultDemands = {
      id: new Date().getTime(),
    };

    setState({
      ...state,
      journeyTypeDefaultDemands: [
        ...state.journeyTypeDefaultDemands,
        newJourneyTypeDefaultDemands,
      ],
    });
  };

  const _handleRemoveDefaultDemand = defaultDemandId => {
    const newJourneyTypeDefaultDemands = state.journeyTypeDefaultDemands.filter(
      d => d.id !== defaultDemandId
    );

    setState({
      ...state,
      journeyTypeDefaultDemands: newJourneyTypeDefaultDemands,
    });
  };

  const _handleBusinessChange = businessId => {
    setState({
      ...state,
      selectedBusiness: state.businesses.find(b => b.id === businessId),
    });
  }

  const _handleCreateCustomerJourney = async data => {
    try {
      createCustomerJourneyUnformRef.current.setErrors({});

      const schema = Yup.lazy(obj =>
        Yup.object(
          Object.keys(obj).reduce(
            (prev, key) => {
              let rule;

              if (
                key.includes('-') &&
                Object.keys(obj).filter(k => k.includes(key.split('-')[1]))
                  .length === 1
              ) {
                return prev;
              }

              if (key.includes('title')) {
                rule = Yup.string().required('Título da demanda é obrigatório');
              }
              if (key.includes('description')) {
                rule = Yup.string().required(
                  'Descrição da demanda é obrigatório'
                );
              }
              if (key.includes('demandTypeId')) {
                rule = Yup.string()
                  .nullable()
                  .required('Tipo da demanda é obrigatório');
              }

              if (rule) return { ...prev, [key]: rule };
              else return prev;
            },
            {
              journeyCustomerUserId: Yup.string()
                .nullable()
                .required('Selecione um usuário'),
              journeyTypeId: Yup.number().required(
                'Selecione um tipo de viagem'
              ),
            }
          )
        )
      );

      await schema.validate(data, {
        abortEarly: false,
      });

      const request = {
        customerId: state.journeyCustomer.id,
        journeyTypeId: state.journeyTypeId,
        journeyCustomerUserId: data.journeyCustomerUserId,
        demands: state.journeyTypeDefaultDemands.map(defaultDemand => {
          return {
            name: data[`title-${defaultDemand.id}`],
            description: data[`description-${defaultDemand.id}`],
            demandTypeId: data[`demandTypeId-${defaultDemand.id}`],
            demandUserId: data.journeyCustomerUserId,
            customerId: state.journeyCustomer.id,
            businessId: state.selectedBusiness.id,
          };
        }),
      };

      const newJourney = (
        await customerJourneyService.createCustomerJourney(request)
      ).data;

      toast.success('Jornada criada com sucesso!');

      history.push(
        `/business/journeys?customerId=${newJourney.customerId}&customerJourneyId=${newJourney.id}&businessId=${state.selectedBusiness.id}`
      );
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};

        error.inner.forEach(err => {
          validationErrors[err.path] = err.message;
        });

        createCustomerJourneyUnformRef.current.setErrors(validationErrors);
        return;
      }

      console.error(error);
    }
  };

  const fetchCustomerUsers = useCallback(async () => {
    try {
      const customerUsers = (
        await customerApi.getCustomerUsers(state.journeyCustomer.id)
      )?.data;

      setState(prevState => ({
        ...prevState,
        journeyCustomerUsers: customerUsers,
      }));
    } catch (error) {
      console.error(error);
    }
  }, [state.journeyCustomer]);

  const _fetchJourneyTypes = async () => {
    try {
      const journeyTypes = await journeyTypeService.listAllJourneyTypes({
        withDefaultDemands: true,
      });

      setState(prevState => ({
        ...prevState,
        journeyTypes: journeyTypes,
        journeyTypeDefaultDemands: journeyTypes.defaultDemands,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const _fetchCustomers = async () => {
    try {
      const customers = (
        await customerApi.getAll({
          withJourneys: true,
        })
      )?.data.customers;

      setState(prevState => ({
        ...prevState,
        customers: customers,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (state.journeyCustomer) fetchCustomerUsers();
  }, [fetchCustomerUsers, state.journeyCustomer]);

  useEffect(() => {
    _fetchCustomers();
    _fetchJourneyTypes();
  }, []);

  return (
    <React.Fragment>
      <MiniDrawer currentExpaded='journeys'>
        <PageContainer>
          <Grid container>
            <Grid container className='titleContainer'>
              <h1>Criar Jornada para um Cliente</h1>
            </Grid>

            <Form
              ref={createCustomerJourneyUnformRef}
              className='formContainer'
              onSubmit={_handleCreateCustomerJourney}
            >
              <Grid container>
                <Grid item xs={6} className='inputContainer'>
                  <CustomSelectWithSearch
                    placeholder='Cliente'
                    name='customerId'
                    label='Cliente'
                    options={state.customers?.map(customer => ({
                      name: customer.name,
                      value: customer.id,
                    }))}
                    onChange={_handleCustomerChange}
                  />
                </Grid>

                {state.journeyCustomerUsers && (
                  <Grid item xs={6} className='inputContainer'>
                    <CustomSelectWithSearch
                      placeholder='Negócio'
                      name='businessId'
                      label='Selecione um negócio'
                      onChange={_handleBusinessChange}
                      options={state.businesses?.map(
                        business => ({
                          name: business.name,
                          value: business.id,
                        })
                      )}
                    />
                  </Grid>
                )}

                {state.selectedBusiness && (
                  <Grid item xs={6} className='inputContainer'>
                    <CustomSelectWithSearch
                      placeholder='Jornada'
                      name='journeyTypeId'
                      label='Nova jornada'
                      options={state.journeyTypes
                        // ?.filter(
                        //   journeyType =>{
                        //     return !state.journeyCustomer.customerJourneys.find(
                        //       j => {
                        //         return j.journeyTypeId === journeyType.id && j.demands[0].businessId !== state.selectedBusiness.id
                        //       }
                        //     ) &&
                        //     (journeyType.customerType ===
                        //       state.journeyCustomer.customerType ||
                        //       journeyType.customerType === null)
                        //     }
                        // )
                        ?.map(journeyType => ({
                          name: journeyType.title,
                          value: journeyType.id,
                        }))}
                      onChange={_handleJourneyTypeChange}
                    />
                  </Grid>
                )}

                {state.selectedBusiness && (
                  <Grid item xs={6} className='inputContainer'>
                    <CustomSelectWithSearch
                      placeholder='Usuário'
                      name='journeyCustomerUserId'
                      label='Usuário do cliente'
                      options={state.journeyCustomerUsers?.map(user => ({
                        name: user.name,
                        value: user.id,
                      }))}
                    />
                  </Grid>
                )}

                {state.journeyTypeDefaultDemands?.length ? (
                  <>
                    <Grid container spacing={1} direction='row'>
                      <Grid item>
                        <h2>Demandas base</h2>
                      </Grid>

                      <p>
                        Essas demandas abaixo serão as criadas inicialmente.
                        Somente a primeira será criada no status ABERTA, as
                        restantes serão todas criadas com o status BLOQUEADA.
                        Você poderá editar a ordem das demandas após a criação
                        da jornada.
                      </p>
                    </Grid>

                    {state.journeyTypeDefaultDemands.map(
                      (defaultDemand, idx) => {
                        return (
                          <Grid
                            container
                            id={defaultDemand.id}
                            key={defaultDemand.id}
                          >
                            <h3>
                              <i
                                className='fas fa-minus-square fa-lg'
                                style={{
                                  color: '#ff3333',
                                  marginRight: '5px',
                                  cursor: 'pointer',
                                }}
                                onClick={() =>
                                  _handleRemoveDefaultDemand(defaultDemand.id)
                                }
                              />
                              Demanda {idx + 1}
                            </h3>

                            <Grid
                              container
                              style={{ borderBottom: 'solid 1px #e7eaec' }}
                            >
                              <Grid item xs={6} className='inputContainer'>
                                <CustomInput
                                  name={`title-${defaultDemand.id}`}
                                  label='Título*'
                                  defaultValue={defaultDemand.title}
                                />
                              </Grid>

                              <Grid item xs={6} className='inputContainer'>
                                <CustomInput
                                  name={`description-${defaultDemand.id}`}
                                  label='Descrição*'
                                  defaultValue={defaultDemand.description}
                                />
                              </Grid>

                              <Grid item xs={6} className='inputContainer'>
                                <CustomSelectWithSearch
                                  placeholder='Tipo de demanda'
                                  name={`demandTypeId-${defaultDemand.id}`}
                                  label='Tipo de demanda*'
                                  defaultValue={defaultDemand.demandTypeId}
                                  options={demandTypes?.map(demandType => ({
                                    name: demandType.name,
                                    value: demandType.id,
                                  }))}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      }
                    )}

                    <Grid container justify='center'>
                      <button
                        type='button'
                        className='submit'
                        style={{
                          backgroundColor: 'blueviolet',
                        }}
                        onClick={_handleAddDefaultDemand}
                      >
                        Adicionar
                      </button>
                    </Grid>

                    <Grid container justify='flex-end'>
                     
                    </Grid>
                  </>
                ) : null}
              </Grid>
              <button type='submit' className='submit'>
                        Criar Jornada
                      </button>
            </Form>
          </Grid>
        </PageContainer>
      </MiniDrawer>
    </React.Fragment>
  );
}
