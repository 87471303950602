import React, { useEffect } from "react";
import MiniDrawer from "../../components/MiniDrawer";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import history from "../../history";
import apiOld from "../../services/Api";
import Table from "../../components/Table";
import { formatDate } from "../../utils";

const useStyles = makeStyles((theme) => ({
  content: {
    height: "100vh",
    background: "#F5F6FA",
  },
  title: {
    fontSize: "35px",
  },
  table: {
    borderCollapse: "collapse",
    margin: "25px 0",
    fontSize: "17px",
    width: "100%",
    boxShadow: "0 0 20px rgba(0, 0, 0, 0.15)",
    borderRadius: "10px",
  },
  tableHead: {
    backgroundColor: "#009879",
    color: "#ffffff",
    textAlign: "left",
  },
  tableHeadTitleFirst: {
    borderRadius: "10px 0px 0px 0px",
    padding: "12px 15px",
  },
  tableHeadTitleLast: {
    borderRadius: "0px 10px 0px 0px",
    padding: "12px 15px",
  },
  tableHeadTitle: {
    padding: "12px 15px",
  },
  tableItem: {
    borderBottom: "1px solid #dddddd",
    "&:hover": {
      background: "#e0e0e0",
    },
  },
  tableItemColumn: {
    padding: "12px 15px",
    fontSize: "15px",
  },
  tableItemColumnStatus: {
    padding: "12px 15px",
    fontSize: "15px",
  },
  link: {
    cursor: "pointer",
    textDecoration: "none",
  },
  action: {
    cursor: "pointer",
    textDecoration: "none",
    color: "#000",
  },
  exportButton: {
    padding: "10px 15px",
    fontSize: "17px",
    background: "#1ab394",
    border: "none",
    color: "#fff",
    borderRadius: "5px",
    cursor: "pointer",
  },
  selectSiz4Items: {},
}));

const headCells = [
  { id: "name", numeric: false, label: "Nome" },
  { id: "customerName", numeric: false, label: "Cliente" },
  { id: "templateName", numeric: false, label: "Template" },
  { id: "createdAt", numeric: false, label: "Data de Criação" },
  { id: "id", numeric: false, label: "ID" },
];

const searchOptions = [
  {
    value: "name",
    name: "Nome",
  },
];

export default function ListDocuments() {
  const classes = useStyles();

  function handleClickItem(id) {
    history.push(`/documents/${id}/`);
  }

  async function handleRows(data) {
    return {
      rows: await formatRows(data.documents),
      total: data.total,
    };
  }

  const formatRows = async (rows) => {
    const response = await apiOld.get("/customers");
    const customers = response.data.customers;

    return rows.map((row) => {
      row.createdAt = formatDate(row.createdAt);
      row.name = row.name || row.customFields.fileName || "--";
      row.templateName = row.templateName || "Anexo*";
      row.customerName =
        row.customerName ||
        customers.find((customer) => customer.id === row.customerId)?.name ||
        "--";
      return row;
    });
  };

  const loadCustomersInput = async () => {
    try {
      const response = await apiOld.get("/customers");
      const customers = response.data.customers;

      searchOptions.push({
        value: "customerId",
        name: "Cliente",
        customInput: customers.map((customer) => ({
          name: customer.name,
          value: customer.id,
        })),
      });
    } catch (error) {
      console.error(error);
    }
  };

  const loadTemplatesInput = async () => {
    try {
      const response = await apiOld.get("/templates");
      const templates = response.data.templates;

      searchOptions.push({
        value: "templateId",
        name: "Template",
        customInput: templates.map((template) => ({
          name: template.name,
          value: template.id,
        })),
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadCustomersInput();
    loadTemplatesInput();
  }, []);


  return (
    <React.Fragment>
      <MiniDrawer
        currentExpaded="documents"
        main={
          <Grid container className={classes.main}>
            <Grid item className={classes.content} xs={12}>
              <Grid item xs={12}>
                <Table
                  searchOptions={searchOptions}
                  title="Documentos"
                  headCells={headCells}
                  handleRows={handleRows}
                  handleClickItem={handleClickItem}
                  route="documents"
                  rolePrefix="DOCUMENT"
                />
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </React.Fragment>
  );
}
