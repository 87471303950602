import React from "react";
import MiniDrawer from "../MiniDrawer";
import { ClassStyle } from "../../pages/riskAnalysis/index";
import { BallPoint, GamePagButton, TextTag } from "./index.styles";
import { addQueryParamToUrl } from "../../routes";
import Loading from "../Loading";
import { propsGamePadButton } from "./index.styles";
import moment from "moment";

export default function QestionContent({ dataTitle, data, params, ...rest }) {
  const classes = ClassStyle();
  const { setValues, sectorId, loading, remove, removeAnswer, setIdSelected } = rest;

  const renderQuestionCards = () => {
    const filteredData = data.filter(
      (e) => e.subcategory === params.subcategory && sectorId === e.Sector_id
    );
    const count = filteredData.length;

    return (
      <>
        <h5 className={classes.subTitle} style={{ padding: 0 }}>
          {dataTitle.title} ({count})
        </h5>
        {filteredData?.sort((a,b) => {
            const acc = moment(a.createdAt);
            const curr = moment(b.createdAt);
            return acc.isBefore(curr) ? -1 : 1;
          })?.map((e, index) => (
          <div key={e.id}>
            <div className={classes.cardCenter}>
              <section className={classes.cardBorder}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <h3 className={classes.title}>
                    {index + 1}- {e.name}
                  </h3>
                  <h3 className={classes.title} style={{ marginRight: "1rem", fontSize: "1rem" }}>
                    <p
                      style={{ color: "#B1B11B", cursor: "pointer" }}
                      onClick={async () => {
                        setValues?.editAnwser(true);
                        setIdSelected(e?.id);
                      }}
                    >
                      Editar
                    </p>
                    <p
                      style={{ color: "#A93030", cursor: "pointer" }}
                      onClick={async () => {
                        const confirmed = window.confirm('Tem certeza que deseja remover?');
                        if (confirmed) {
                          await remove.question(e?.id);
                        }
                      }}
                    >
                      Deleta
                    </p>
                  </h3>
                </div>
                <button
                  className={classes.button}
                  onClick={() => {
                    setValues.addAnwser(true);
                    setIdSelected(e?.id);
                  }}
                >
                  + Adicionar
                </button>
                {e.AnswerAIRs?.sort((a,b) => {
                  const acc = moment(a.createdAt);
                  const curr = moment(b.createdAt);
                  return acc.isBefore(curr) ? -1 : 1;
                })?.map((item) => (
                  <div style={{ margin: "1rem 3rem" }} key={item.id}>
                    <GamePagButton
                      {...propsGamePadButton}
                      onClick={() => {
                        addQueryParamToUrl(dataTitle.params, item.name);
                      }}
                    >
                      <div className={classes.buttonContent} style={{ width: "85%" }}>
                        <TextTag>Alternativa</TextTag>
                        <p className={classes.buttonText}>{item.name}</p>
                      </div>
                      <TextTag style={{ display: "flex" }}>
                        <BallPoint>{item.score}</BallPoint>
                        <i
                          onClick={(e) => {
                            e.stopPropagation();
                            setValues?.edit(true);
                            setIdSelected(item?.id);
                          }}
                          style={{ color: "#B1B11B" }}
                          className="fa fa-pencil fa-lg"
                        />
                        <i
                          onClick={async (e) => {
                            e.stopPropagation();
                            const confirmed = window.confirm('Tem certeza que deseja remover?');
                            if (confirmed) {
                              removeAnswer(item.id);
                            }
                          }}
                          style={{ color: "#A93030", marginLeft: "10px" }}
                          className="fa fa-trash fa-lg"
                        />
                      </TextTag>
                    </GamePagButton>
                  </div>
                ))}
              </section>
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <React.Fragment>
      <MiniDrawer
        currentExpaded={dataTitle.params}
        main={
          <>
            <Loading open={loading} />
            <span>
              {`${params ? 'Você está em' : ''} ${params?.sector ? '>' + params?.sector : ''} ${params?.subcategory ? '>' + params?.subcategory : ''}`}</span>
            <section className={classes.content}>
              <div>
                <h3 className={classes.title}>{dataTitle.subTitle}</h3>
                <div className={classes.cardCenter}>
                  <GamePagButton onClick={() => setValues?.add(true)}>
                    <span className={classes.iconSum}>+</span>
                    <p className={classes.buttonText}>{dataTitle.buttonText}</p>
                  </GamePagButton>
                </div>
              </div>
              <main className={classes.sectionMain}>
                {renderQuestionCards()}
              </main>
            </section>
          </>
        } />
    </React.Fragment >
  );
};