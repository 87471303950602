import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Section,
  SectionContent,
  CustomerListsContainer,
  CustomerListContent,
  SectionTitle,
} from '../../../pages/dashboard/Dashboard.styles';
import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';

export default function DemandGraph({ title, loading, demand, options, data }) {
  return (
    <Section>
      <SectionTitle>{title}</SectionTitle>
      <div style={{ marginBottom: '1vh', width: '100%', height: '100%' }}>
        <SectionContent>
          <CustomerListsContainer>
            {loading ? <CircularProgress /> : (
              <CustomerListContent style={{ height: '50vh' }}>
                {demand && (
                  <Bar
                    datasetIdKey='Revenue Sheet'
                    options={options}
                    data={data}
                  />
                )}
              </CustomerListContent>
            )}
          </CustomerListsContainer>
        </SectionContent>
      </div>
    </Section>
  )
}