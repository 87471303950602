import React from 'react';

export const styleDataTable = {
  table: {
    style: {
      backgroundColor: 'transparent',
      borderCollapse: 'collapse',
    },
  },
  headRow: {
    style: {
      backgroundColor: 'transparent',
    },
  },
  headCells: {
    style: {
      color: '#000',
      fontWeight: '400',
      fontSize: '1rem',
    },
  },
  rows: {
    style: {
      backgroundColor: 'transparent',
      height: '1rem',
      fontSize: '0.9rem',
      margin: '0.25rem 0',
      color: '#666',
      borderBottom: '1px solid RGBA(188, 188, 188, 0.5)',
    },
  },
};

export const styleDataTableScore = {
  ...styleDataTable,
  rows: {
    style: {
      backgroundColor: 'transparent',
      height: '5rem',
      fontSize: '0.9rem',
      color: '#666',
    },
  },
  cells: {
    style: {
      border: '1px solid RGBA(188, 188, 188, 0.5)',
    },
  },
};

export const columns = [
  {
    name: 'NOME',
    selector: 'name',
    sortable: true,
    width: '25%',
  },
  {
    name: 'CLIENTE',
    selector: 'customer',
    sortable: true,
  },
  {
    name: 'NEGÓCIO',
    selector: 'business',
    sortable: true,
  },
  {
    name: 'STATUS',
    selector: 'status',
    sortable: true,
  },
  {
    name: 'ULTIMA ATUALIZAÇÃO',
    selector: 'updatedAt',
    sortable: true,
  },
  {
    name: 'AÇÃO',
    selector: 'action',
    sortable: true,
  },
];

export const columnsScore = [
  {
    name: 'SEÇÃO DE RISCOS',
    selector: 'section',
    sortable: true,
    width: '30%',
    style: {
      maxWidth: '200px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    cell: (row) => <div style={{cursor: 'help'}} title={row.section}>{row.section}</div>,
  },
  {
    name: 'STATUS',
    selector: 'status',
    sortable: true,
  },
  {
    name: 'PROGRESSO',
    selector: 'progress',
    sortable: true,
  },
  {
    name: 'RISCO',
    selector: 'risk',
    sortable: true,
  },
];