import * as Yup from 'yup';
import { Constants } from '../utils/Constants';
import usersApi from './apis/usersApi';

export default class UserService {
  static _validateDeleteUserData(data) {
    const userYupSchema = Yup.object().shape({
      userType: Yup.string().required('O tipo do usuário é obrigatório'),
      userId: Yup.string().required('O usuário é obrigatório'),
      newResponsibleUserId: Yup.string()
        .nullable()
        .when('userType', {
          is: Constants.USER_TYPES.CUSTOMER,
          then: Yup.string()
            .nullable()
            .required('O novo responsável é obrigatório'),
        }),
    });

    return userYupSchema.validateSync(data, {
      abortEarly: false,
    });
  }

  static async deleteUser(userId, newResponsibleUserId) {
    return (
      await usersApi.deleteUser(userId, {
        data: {
          newResponsibleUserId,
        },
      })
    ).data;
  }

  static handleDeleteUser({ userId, newResponsibleUserId, userType }) {
    if (
      this._validateDeleteUserData({
        userId,
        newResponsibleUserId,
        userType,
      })
    ) {
      return this.deleteUser(userId, newResponsibleUserId);
    }
  }
}
