import CircularProgress from "@material-ui/core/CircularProgress";
import { Form } from "@unform/web";
import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import apiOld from "../../../services/Api";
import CustomInput from "../../input/CustomInput";
import {
  ButtonsContainer,
  Container,
  ContainerInput,
  ExitButton,
  Modal,
  SubmitButton,
} from "./ChangeDemandTitleModal.styles";

export default function ChangeDemandTitleModal(props) {
  const { open, handleClose, modalData } = props;
  const formRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  async function handleSubmit(data) {
    try {
      setSubmitLoading(true);

      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        title: Yup.string().required("Título não pode estar em branco!"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const request = {
        title: data.title,
      };

      await apiOld.patch(`/demands/${modalData.id}/title`, request);

      toast.success("Título alterado com sucesso!");

      handleClose(true);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};

        error.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      } else {
      }
    } finally {
      setSubmitLoading(false);
    }
  }

  return (
    <>
      {open && (
        <Container>
          <Modal>
            <h1>Alterar Título</h1>

            <span>Título atual: {modalData.name}</span>

            <Form ref={formRef} onSubmit={handleSubmit}>
              <ContainerInput>
                <CustomInput name="title" label="Novo título" />
              </ContainerInput>

              <ButtonsContainer>
                <SubmitButton type="submit">
                  {!submitLoading && "Alterar"}
                  {submitLoading && <CircularProgress color="inherit" />}
                </SubmitButton>

                <ExitButton onClick={() => handleClose(false)}>
                  Cancelar
                </ExitButton>
              </ButtonsContainer>
            </Form>
          </Modal>
        </Container>
      )}
    </>
  );
}
