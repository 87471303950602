import React, { useState, useEffect } from 'react';
import MiniDrawer from '../../components/MiniDrawer';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import apiOld from '../../services/Api';
import { Constants } from '../../utils/Constants';
import { useParams } from 'react-router';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  main: {
    background: '#FFF',
  },
  mainTitle: {
    background: '#FFF',
    borderTop: 'solid 2px #e7eaec',
    borderBottom: 'solid 1px #e7eaec',
    padding: '15px',
    justifyContent: 'space-between',
  },
  line: {
    backgorund: 'reds',
  },
  title: {
    fontSize: '15px',
  },
  mainForm: {
    padding: '10px',
  },
  form: {
    width: '100%',
  },
  input: {
    padding: '10px',
  },
  buttonContainer: {
    justifyContent: 'flex-end',
    padding: '10px',
  },
  editButton: {
    padding: '10px 10px',
    fontSize: '15px',
    background: '#1ab394',
    border: 'none',
    color: '#fff',
    borderRadius: '4px',
    textDecoration: 'none',
  },
  submit: {
    padding: '10px 15px',
    fontSize: '17px',
    background: '#1ab394',
    border: 'none',
    color: '#fff',
    borderRadius: '5px',
  },
  subTitle: {
    background: '#FFF',
    borderBottom: 'solid 1px #e7eaec',
  },
}));

export default function ViewForm() {
  const classes = useStyles();
  const [form, setForm] = useState(null);
  const { id } = useParams();

  const loadForm = useCallback(async () => {
    try {
      const response = await apiOld.get('/forms/' + id);
      const formData = response.data;

      setForm(formData);
    } catch (error) {
      console.error(console.error());
    }
  }, [id]);

  useEffect(() => {
    loadForm();
  }, [loadForm]);

  return (
    <React.Fragment>
      <MiniDrawer
        main={
          form && (
            <Grid container className={classes.main}>
              <Grid container className={classes.mainTitle}>
                <h2 className={classes.title}>Formulário</h2>

                <Link className={classes.editButton} to={`/forms/${id}/edit`}>
                  Editar
                </Link>
              </Grid>
              <Grid container className={classes.mainForm}>
                <Grid container>
                  <Grid item className={classes.input} xs={12}>
                    <h2 className={classes.subTitle}>Informações Básicas</h2>
                  </Grid>

                  <Grid item className={classes.input} xs={12}>
                    <label>Nome do formulário</label>
                    <p>{form.name}</p>
                  </Grid>

                  <Grid item className={classes.input} xs={12}>
                    <label>Descrição</label>
                    <p>{form.description}</p>
                  </Grid>

                  <Grid item className={classes.input} xs={12}>
                    <h2 className={classes.subTitle}>Campos do formulário</h2>
                  </Grid>

                  {form &&
                    form.fields.map((field, idx) => {
                      return (
                        <Grid
                          container
                          xs={12}
                          style={{ borderBottom: 'solid 1px #e7eaec' }}
                        >
                          <Grid item xs={12}>
                            <h3 style={{ padding: '5px 0px 0px 10px' }}>
                              Entrada {idx + 1}
                            </h3>
                          </Grid>

                          <Grid item className={classes.input} xs={4}>
                            <label>Chave</label>
                            <p>{field.key}</p>
                          </Grid>

                          <Grid item className={classes.input} xs={4}>
                            <h4>Tipo</h4>

                            <p>
                              {
                                Constants.FORM_TYPES.find(
                                  type => type.id === field.type
                                ).label
                              }
                            </p>
                          </Grid>

                          <Grid item className={classes.input} xs={4}>
                            <label>Necessário</label>
                            <p>{field.required ? 'Sim' : 'Não'}</p>
                          </Grid>

                          <Grid item className={classes.input} xs={4}>
                            <label>Titulo</label>
                            <p>{field.label}</p>
                          </Grid>

                          <Grid item className={classes.input} xs={8}>
                            <label>Descrição</label>
                            <p>{field.description}</p>
                          </Grid>
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
            </Grid>
          )
        }
      />
    </React.Fragment>
  );
}
