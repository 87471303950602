import templatesApi from '../apis/templatesApi';
import * as Yup from 'yup';
import { Constants } from '../../utils/Constants';
import FileService from '../fileService';

const fileService = new FileService();

export default class TemplateService {
  defaultTemplateInputNames = [
    'name',
    'description',
    'backofficeOnly',
    'templateFile',
    'html',
  ];

  async getTemplateById(templateId) {
    return (await templatesApi.getTemplateById(templateId)).data;
  }

  validateTemplateFormData(templateFormData) {
    const schema = Yup.lazy(obj =>
      Yup.object(
        Object.keys(obj).reduce(
          (prev, key) => {
            if (!this.defaultTemplateInputNames.includes(key)) {
              // Its a field
              const rule = Yup.object({
                name: Yup.string().label('Nome').required(),
                description: Yup.string().label('Descrição').required(),
                label: Yup.string().label('Label').required(),
                placeholder: Yup.string().label('Placeholder').required(),
                type: Yup.string().label('Tipo').required(),
                options: Yup.mixed().when('type', {
                  is: 'options',
                  then: Yup.mixed()
                    .nullable()
                    .required()
                    .transform(value => {
                      if (value) {
                        return value.split(',').map(s => s.trim());
                      }
                    })
                    .test(
                      'options',
                      'Opções inválidas. Separe por vírgula, ex: "Um, Dois" (sem vírgulas no final e começo)',
                      value => {
                        return value?.every(option => !!option);
                      }
                    ),
                }),
              });

              return { ...prev, [key]: rule };
            }

            return prev;
          },
          {
            name: Yup.string().label('Nome').required(),
            templateFile: Yup.string().label('Arquivo').required(),
            description: Yup.string().label('Descrição').required(),
            html: Yup.string().label('Html').required(),
            backofficeOnly: Yup.boolean().label('Apenas para backoffice'),
          }
        )
      )
    );

    return schema.validate(templateFormData, {
      abortEarly: false,
    });
  }

  formatCreateTemplateData(templateData, templateAwsFileKey) {
    /* 
      Format the template data from form to be sent to the API
      Put template fields on an array
      {
        name: '',
        description: '',
        backofficeOnly: '',
        awsFileKey: '',
        html: '',
        fields: [
          {
            name: '',
            description: '',
            label: '',
            placeholder: '',
            type: '',
            index: number,
            options: []
          }
        ]
      }
    */
    return Object.keys(templateData).reduce(
      (prev, key) => {
        if (key === 'templateFile') {
          return prev;
        }

        if (this.defaultTemplateInputNames.includes(key))
          return {
            ...prev,
            [key]: templateData[key],
          };

        const { name, description, label, placeholder, type, options, index } =
          templateData[key];
        const fieldData = {
          name,
          description,
          label,
          placeholder,
          type,
          index: +index,
        };

        if (type === 'options') {
          fieldData.options = options;
        }

        const fields = prev.fields ? [...prev.fields, fieldData] : [fieldData];

        return {
          ...prev,
          fields,
        };
      },
      {
        awsFileKey: templateAwsFileKey,
      }
    );
  }

  async createTemplate(templateData) {
    return (await templatesApi.createTemplate(templateData)).data;
  }

  static async updateTemplate(templateId, templateData) {
    return (await templatesApi.updateTemplate(templateId, templateData)).data;
  }

  static uploadTemplateFileAndReturnAwsKey(templateFile) {
    return fileService.uploadFileAndReturnAwsKey(templateFile, {
      type: Constants.FILE_TYPES.TEMPLATE,
    });
  }

  async validateAndCreateTemplate(templateData) {
    await this.validateTemplateFormData(templateData);
    const templateAwsFileKey =
      await TemplateService.uploadTemplateFileAndReturnAwsKey(
        templateData.templateFile
      );
    const formatedCreateTemplateData = this.formatCreateTemplateData(
      templateData,
      templateAwsFileKey
    );

    return this.createTemplate(formatedCreateTemplateData);
  }

  /**
   * Retrieve fields in an docx file
   *
   * @param {String} base64TemplateFile base 64 file
   * @returns {Object} Object with fields as keys and inner fields as values
   */
  async getFieldsFromBase64TemplateFile(base64TemplateFile) {
    return (
      await templatesApi.getFieldsFromBase64TemplateFile({
        file: base64TemplateFile,
      })
    ).data.fields;
  }
}
