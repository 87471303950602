export const RECIEVE_BACKOFFICE_USERS = "RECIEVE_BACKOFFICE_USERS";
export const RECIEVE_DEMAND_TYPES = "RECIEVE_DEMAND_TYPES";
export const RECIEVE_FORMS = "RECIEVE_FORMS";

export const actions = {
  RECIEVE_BACKOFFICE_USERS,
  RECIEVE_DEMAND_TYPES,
  RECIEVE_FORMS,
};

export default actions;
