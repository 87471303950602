import axios from 'axios';
import { saveFileToUserDisk } from '../../utils';
import apiOld from '../Api';

const rawApi = axios.create();

const filesApi = {
  getFileBlobByUrl: async function (url) {
    return await rawApi.get(url, {
      responseType: 'blob',
    });
  },

  getSignedPutFileUrl: async function ({ fileName, fileType, type, publicRead = false }) {
    return await apiOld.get('/signedPutFileUrl', {
      params: {
        fileName,
        fileType,
        type,
        publicRead,
      },
    });
  },

  uploadFileToSignedPutFileUrl: async function ({ signedUrl, file }) {
    return rawApi.put(signedUrl, file, {
      headers: {
        'Content-Type': file.type,
      },
    });
  },

  uploadFile: async function (file, { type, publicRead } = {}) {
    const signedRequest = (
      await filesApi.getSignedPutFileUrl({
        fileName: file.name,
        fileType: file.type,
        type,
        publicRead
      })
    ).data;

    await filesApi.uploadFileToSignedPutFileUrl({
      file,
      signedUrl: signedRequest.signedUrl,
    });

    return signedRequest;
  },

  getSignedDownloadFileUrl: async function (params = {}) {
    return apiOld.get('/signedDownloadFileUrl', {
      params,
    });
  },

  downloadFileByKey: async function (key) {
    const signedObj = (
      await filesApi.getSignedDownloadFileUrl({
        key: key,
      })
    )?.data;

    const fileResponse = await rawApi.get(signedObj.signedUrl, {
      responseType: 'blob',
    });

    saveFileToUserDisk(
      fileResponse.data,
      fileResponse.headers['content-type'],
      signedObj.fileName
    );
  },

  downloadFileByUrl: async function (fileUrl, fileName) {
    const fileResponse = await rawApi.get(fileUrl, {
      responseType: 'blob',
    });

    saveFileToUserDisk(
      fileResponse.data,
      fileResponse.headers['content-type'],
      fileName
    );
  },

  getDocumentByIdAndCustomerId: async function (fileUrl) {
    const fileResponse = await rawApi.get(fileUrl, {
      responseType: 'blob',
    })


    saveFileToUserDisk(
      fileResponse.data,
      fileResponse.headers['content-type'],
    );

    // não iremos baixar o arquivo
    // console.log(fileResponse.data)
  }
};

export default filesApi;
