import styled from "styled-components";

export const GamePagButton = styled.button`
  background: ${props => props.background || 'rgba(26, 179, 148, 0.3)'};  
  width: ${props => props.width || '95%'};
  height: ${props => props.height || '4rem'};
  color: ${props => props.color || '#1AB394'};
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: ${props => props.fontWeight || 'bold'};
  text-align: left;
  display: flex;
  justify-content: ${props => props.edit ? 'space-between' : 'none'};
  align-items: center;
  gap: 1rem;
  &:hover {
    background: ${props => props.backgroundHover || 'rgba(26, 179, 148, 0.35)'};  
  }
`;

export const TextTag = styled.p`
  font-size: 0.85rem;
  font-weight: bold;
  color: #000;
  text-transform: uppercase;
  font-family: Helvetica;
  display: flex;
`;

export const BallPoint = styled.div`
  width: 1rem;
  height: 1rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  padding: 10px;
  border-radius: 50%;
  background: #1AB394;
`;

export const propsGamePadButton = {
  background: "rgba(204, 204, 204, 0.32)",
  backgroundHover: "rgba(204, 204, 204, 0.42)",
  color: "#333",
  fontWeight: "100",
  height: "5.5rem",
  width: "100%",
  edit: true,
};