import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Form } from "@unform/web";
import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import CustomInput from "../components/input/CustomInput";
import history from "../history";
import apiOld from "../services/Api";
import { ReactComponent as BackIcon } from "../static/back.svg";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(4),
  },
  submit: {
    fontSize: "20px",
    color: "#FFF",
    background: "#1ab394",
    borderRadius: "31px",
    border: "none",
    padding: "10px 26px",
    marginLeft: "10px",
    boxShadow: "0 0 6px rgba(0, 0, 0, 0.09)",
  },
  createAccount: {
    backgroundColor: "#D4D4D4",
    borderRadius: "31px",
    border: "none",
    padding: "10px 26px",
    color: "#858585",
    fontSize: "20px",
  },
  title: {
    fontSize: "36px",
  },
  main: {
    background: "#F5F6FA",
    height: "95vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
  },
  buttonsContainer: {
    justifyContent: "flex-end",
    margin: theme.spacing(4, 0, 2),
  },
  forgotPassword: {
    textDecoration: "none",
    color: "#000",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  forgotPasswordContainer: {
    justifyContent: "center",
    marginTop: "25px",
    fontSize: "16px",
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    height: "5vh",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      marginRight: "0px",
    },
    marginRight: "20px",
    background: "#F5F6FA",
  },
  footerText: {
    textDecoration: "none",
    color: "#000",
    "&:hover": {
      textDecoration: "underline",
    },
    padding: "20px",
  },
  description: {
    fontSize: "20px",
    color: "#000000",
    marginBottom: "0.9rem",
  },
  resetPassowordContent: {
    justifyContent: "center",
  },
  back: {
    fontSize: "20px",
    color: "#000000",
    marginLeft: "10px",
  },
  containerBack: {
    marginBottom: "30px",
    flexGrow: 1,
  },
  blackLink: {
    textDecoration: "none",
    color: "#000",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  teste: {
    flexGrow: 1,
  },
  mainContainer: {
    flexGrow: 2,
  },
}));

export default function ResetPassword() {
  const classes = useStyles();
  const formRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  async function handleSubmit(data) {
    try {
      setSubmitLoading(true);

      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        email: Yup.string().email().required(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const request = {
        email: data.email,
      };

      await apiOld.post("/reset-password/token", request);

      toast.success("Email enviado com sucesso!");

      setSuccess(true);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors = {};

        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }

    setSubmitLoading(false);
  }

  const handleBack = () => {
    if (success) setSuccess(false);
    else history.push("/signIn");
  };

  return (
    <React.Fragment>
      <Grid container className={classes.main}>
        <Grid container className={classes.resetPassowordContent}>
          <Grid container item className={classes.containerBack} sm={1}>
            <button
              className={classes.blackLink}
              onClick={handleBack}
              textDecoration="none"
              style={{ display: "flex" }}
            >
              <BackIcon display="flex" />
              <h5 className={classes.back}>Voltar</h5>
            </button>
          </Grid>
          <Grid container item className={classes.mainContainer} sm={8} lg={4}>
            {!success && (
              <div className={classes.paper}>
                <h1 className={classes.title}>Esqueci minha senha</h1>
                <Form
                  ref={formRef}
                  className={classes.form}
                  onSubmit={handleSubmit}
                >
                  <Grid container className={classes.formContainer}>
                    <Grid item xs={12}>
                      <p className={classes.description}>
                        Coloque oa e-mail de sua conta, que iremos enviar um
                        e-mail para você recuperar sua senha.
                      </p>
                    </Grid>
                    <Grid item xs={12}>
                      <CustomInput
                        name="email"
                        label="E-mail"
                        autoComplete="email"
                        autoFocus
                      />
                    </Grid>
                    <Grid container className={classes.buttonsContainer}>
                      <Grid item>
                        <button type="submit" className={classes.submit}>
                          {!submitLoading && "Enviar"}
                          {submitLoading && (
                            <CircularProgress color="inherit" />
                          )}
                        </button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              </div>
            )}
            {success && (
              <div className={classes.paper}>
                <h1 className={classes.title}>E-mail enviado</h1>
                <Form
                  ref={formRef}
                  className={classes.form}
                  onSubmit={handleSubmit}
                >
                  <Grid container className={classes.formContainer}>
                    <Grid item xs={12}>
                      <p className={classes.description}>
                        Enviamos um e-mail para o endereço informado. Clique no
                        link e cadastre uma nova senha (Se você não receber o
                        e-mail, basta voltar e reenviar)
                      </p>
                    </Grid>
                  </Grid>
                </Form>
              </div>
            )}
          </Grid>
          <Grid container item className={classes.teste} sm={1}></Grid>
        </Grid>
      </Grid>
      <div className={classes.footer}>
        <div>
          {/* <a className={classes.footerText}>{"Termos & Condições"}</a><a className={classes.footerText} >{"Políticas de Privacidade"}</a> */}
        </div>
      </div>
    </React.Fragment>
  );
}
