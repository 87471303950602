import React, { useRef, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import CustomCheckbox from '../../components/input/CustomCheckbox';
import CustomInput from '../../components/input/CustomInput';
import MiniDrawer from '../../components/MiniDrawer';
import history from '../../history';
import apiOld from '../../services/Api';
import { toast } from 'react-toastify';

const useStyles = makeStyles(() => ({
  main: {
    background: '#FFF',
  },
  mainTitle: {
    background: '#FFF',
    borderTop: 'solid 2px #e7eaec',
    borderBottom: 'solid 1px #e7eaec',
    padding: '15px',
  },
  line: {
    backgorund: 'reds',
  },
  title: {
    fontSize: '15px',
  },
  mainForm: {
    padding: '10px',
  },
  form: {
    width: '100%',
  },
  input: {
    padding: '10px',
  },
  buttonContainer: {
    justifyContent: 'flex-end',
    padding: '10px',
  },
  submit: {
    padding: '10px 15px',
    fontSize: '17px',
    background: '#1ab394',
    border: 'none',
    color: '#fff',
    borderRadius: '5px',
  },
}));

export default function CreateUser() {
  const classes = useStyles();
  const [submitLoading, setSubmitLoading] = useState(false);
  const formRef = useRef(null);

  async function handleSubmit(data) {
    setSubmitLoading(true);
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        email: Yup.string().required().email(),
        função: Yup.string().required(),
        nome: Yup.string().required(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const request = {
        email: data.email,
        name: data.nome,
        roles: data.rolesTemplate
          .concat(data.rolesCustomer)
          .concat(data.rolesUser)
          .concat(data.rolesDocument)
          .concat(data.rolesConfig)
          .concat(data.rolesForm)
          .concat(data.rolesDemand)
          .concat(data.rolesDemandType),
        occupation: data.função,
        password: data.senha,
      };

      await apiOld.post('/users', request);

      toast.success('Usuário criado com sucesso!');

      history.push('/users');
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
    setSubmitLoading(false);
  }

  const rolesUser = [
    { id: 'CREATE_USER', value: 'CREATE_USER', label: 'Criar' },
    { id: 'UPDATE_USER', value: 'UPDATE_USER', label: 'Editar' },
    { id: 'DELETE_USER', value: 'DELETE_USER', label: 'Deletar' },
    { id: 'GET_USERS', value: 'GET_USERS', label: 'Buscar' },
  ];

  const rolesCustomer = [
    { id: 'CREATE_CUSTOMER', value: 'CREATE_CUSTOMER', label: 'Criar' },
    { id: 'UPDATE_CUSTOMER', value: 'UPDATE_CUSTOMER', label: 'Editar' },
    {
      id: 'DELETE_CUSTOMER',
      value: 'DELETE_CUSTOMER',
      label: 'Deletar',
    },
    { id: 'GET_CUSTOMERS', value: 'GET_CUSTOMERS', label: 'Buscar' },
  ];

  const rolesTemplate = [
    { id: 'CREATE_TEMPLATE', value: 'CREATE_TEMPLATE', label: 'Criar' },
    { id: 'UPDATE_TEMPLATE', value: 'UPDATE_TEMPLATE', label: 'Editar' },
    {
      id: 'DELETE_TEMPLATE',
      value: 'DELETE_TEMPLATE',
      label: 'Deletar',
    },
    { id: 'GET_TEMPLATES', value: 'GET_TEMPLATES', label: 'Buscar' },
  ];

  const rolesDocument = [
    { id: 'CREATE_DOCUMENT', value: 'CREATE_DOCUMENT', label: 'Criar' },
    { id: 'UPDATE_DOCUMENT', value: 'UPDATE_DOCUMENT', label: 'Editar' },
    {
      id: 'DELETE_DOCUMENT',
      value: 'DELETE_DOCUMENT',
      label: 'Deletar',
    },
    { id: 'GET_DOCUMENTS', value: 'GET_DOCUMENTS', label: 'Buscar' },
  ];

  const rolesForm = [
    { id: 'CREATE_FORM', value: 'CREATE_FORM', label: 'Criar' },
    { id: 'UPDATE_FORM', value: 'UPDATE_FORM', label: 'Editar' },
    { id: 'DELETE_FORM', value: 'DELETE_FORM', label: 'Deletar' },
    { id: 'GET_FORMS', value: 'GET_FORMS', label: 'Buscar' },
  ];

  const rolesDemandType = [
    { id: 'CREATE_DEMAND_TYPE', value: 'CREATE_DEMAND_TYPE', label: 'Criar' },
    { id: 'UPDATE_DEMAND_TYPE', value: 'UPDATE_DEMAND_TYPE', label: 'Editar' },
    { id: 'DELETE_DEMAND_TYPE', value: 'DELETE_DEMAND_TYPE', label: 'Deletar' },
    { id: 'GET_DEMAND_TYPES', value: 'GET_DEMAND_TYPES', label: 'Buscar' },
  ];

  const rolesDemand = [
    { id: 'CREATE_DEMAND', value: 'CREATE_DEMAND', label: 'Criar' },
    { id: 'PATCH_DEMAND', value: 'PATCH_DEMAND', label: 'Editar' },
    { id: 'DELETE_DEMAND', value: 'DELETE_DEMAND', label: 'Deletar' },
    { id: 'GET_DEMANDS', value: 'GET_DEMANDS', label: 'Buscar' },
  ];

  const rolesConfig = [
    { id: 'CREATE_CONFIG', value: 'CREATE_CONFIG', label: 'Criar' },
    { id: 'UPDATE_CONFIG', value: 'UPDATE_CONFIG', label: 'Editar' },
    { id: 'DELETE_CONFIG', value: 'DELETE_CONFIG', label: 'Deletar' },
    { id: 'GET_CONFIGS', value: 'GET_CONFIGS', label: 'Buscar' },
  ];

  const handleRolesChange = (field, path) => {
    const fields = formRef.current.getFieldValue(field);

    if (fields.length > 0 && !fields.includes('GET_' + path)) {
      formRef.current.setFieldValue(field, 'GET_' + path);
    }
  };

  return (
    <React.Fragment>
      <MiniDrawer
        currentExpaded='users'
        main={
          <Grid container className={classes.main}>
            <Grid container className={classes.mainTitle}>
              <h2 className={classes.title}>Criar Usuário (backoffice)</h2>
            </Grid>
            <Grid container className={classes.mainForm}>
              <Form
                ref={formRef}
                onSubmit={handleSubmit}
                className={classes.form}
              >
                <Grid container>
                  <Grid item className={classes.input} xs={12}>
                    <CustomInput name='nome' label='Nome*' />
                  </Grid>
                  <Grid item className={classes.input} xs={6}>
                    <CustomInput name='email' label='E-mail*' />
                  </Grid>
                  <Grid item className={classes.input} xs={6}>
                    <CustomInput
                      name='função'
                      label='Título (Função, cargo, etc...)*'
                    />
                  </Grid>
                  <Grid item className={classes.input} xs={12}>
                    <h3>Permissões:</h3>
                  </Grid>
                  <Grid item className={classes.input} xs={12}>
                    <h4>Usuários</h4>
                    <CustomCheckbox
                      name='rolesUser'
                      options={rolesUser}
                      customOnChange={() =>
                        handleRolesChange('rolesUser', 'USERS')
                      }
                      checkAll
                    />
                  </Grid>
                  <Grid item className={classes.input} xs={12}>
                    <h4>Clientes</h4>
                    <CustomCheckbox
                      name='rolesCustomer'
                      options={rolesCustomer}
                      customOnChange={() =>
                        handleRolesChange('rolesCustomer', 'CUSTOMERS')
                      }
                      checkAll
                    />
                  </Grid>
                  <Grid item className={classes.input} xs={12}>
                    <h4>Templates</h4>
                    <CustomCheckbox
                      name='rolesTemplate'
                      options={rolesTemplate}
                      customOnChange={() =>
                        handleRolesChange('rolesTemplate', 'TEMPLATES')
                      }
                      checkAll
                    />
                  </Grid>
                  <Grid item className={classes.input} xs={12}>
                    <h4>Documentos</h4>
                    <CustomCheckbox
                      name='rolesDocument'
                      options={rolesDocument}
                      customOnChange={() =>
                        handleRolesChange('rolesDocument', 'DOCUMENTS')
                      }
                      checkAll
                    />
                  </Grid>
                  <Grid item className={classes.input} xs={12}>
                    <h4>Formulários</h4>
                    <CustomCheckbox
                      name='rolesForm'
                      options={rolesForm}
                      customOnChange={() =>
                        handleRolesChange('rolesForm', 'FORMS')
                      }
                      checkAll
                    />
                  </Grid>
                  <Grid item className={classes.input} xs={12}>
                    <h4>Demandas</h4>
                    <CustomCheckbox
                      name='rolesDemand'
                      options={rolesDemand}
                      customOnChange={() =>
                        handleRolesChange('rolesDemand', 'DEMANDS')
                      }
                      checkAll
                    />
                  </Grid>
                  <Grid item className={classes.input} xs={12}>
                    <h4>Tipos de Demanda</h4>
                    <CustomCheckbox
                      name='rolesDemandType'
                      options={rolesDemandType}
                      customOnChange={() =>
                        handleRolesChange('rolesDemandType', 'DEMAND_TYPES')
                      }
                      checkAll
                    />
                  </Grid>
                  <Grid item className={classes.input} xs={12}>
                    <h4>Configurações</h4>
                    <CustomCheckbox
                      name='rolesConfig'
                      options={rolesConfig}
                      customOnChange={() =>
                        handleRolesChange('rolesConfig', 'CONFIGS')
                      }
                      checkAll
                    />
                  </Grid>
                  <Grid container className={classes.buttonContainer}>
                    <button className={classes.submit} type='submit'>
                      {!submitLoading && 'Cadastrar'}
                      {submitLoading && <CircularProgress color='inherit' />}
                    </button>
                  </Grid>
                </Grid>
              </Form>
            </Grid>
          </Grid>
        }
      />
    </React.Fragment>
  );
}
