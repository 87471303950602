import apiOld from "../../services/Api"
import * as Types from '../actionsTypes/ActiveContact'

export const getActiveContacts = async () => {
    const res = await apiOld.get('/cs/valueDemands')
    return {
        type: Types.FETCH_VALUE_DEMANDS,
        value: res.data
    }
}

export const confirmFollowUp  = async (followUp) => {
    let res = await apiOld.post(`/cs/valueDemands/${followUp.id}`, {finishedDate: new Date() })
    res = await apiOld.get(`/cs/valueDemands/${followUp.demandId}`)
    return {
        type: Types.CONFIRMED_FUP,
        value: res.data[0]
    }
}