import styled from 'styled-components';

export const AddUserModal = styled.div`
  position: fixed;
  display: ${props => (props.open ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1399;
  opacity: 1;
  box-sizing: border-box;
  white-space: initial;
`;

export const AddUserModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 30px 40px;
  border-radius: 30px;
  width: 500px;

  form {
    display: flex;
    flex-direction: column;
  }

  input {
    width: 100%;
  }
`;

export const AddUserModalTitle = styled.h1`
  flex: 1;
  align-self: center;
  font-size: 30px;
  text-align: center;
  margin-bottom: 30px;
`;

export const AddUserModalOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;

export const AddUserModalCancelButton = styled.button`
  border: none;
  padding: 7px 10px;
  background-color: transparent;
  color: #121212;
  font-size: 17px;
  border-radius: 5px;
  margin: 10px 0px;
  align-self: flex-end;
`;

export const AddUserModalSubmitButton = styled.button`
  border: none;
  padding: 7px 10px;
  background-color: #1ab394;
  color: #fff;
  font-size: 17px;
  border-radius: 5px;
  margin: 10px 0px;
  align-self: flex-end;
`;

export const OnboardingFormModal = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 30px 40px;
    border-radius: 30px;
    width: 500px;

    form {
      display: flex;
      flex-direction: column;
    }

    input {
      width: 100%;
    }
  `,
  Title: styled.h1`
    flex: 1;
    align-self: center;
    font-size: 20px;
    text-align: center;
    margin-bottom: 15px;
  `,
  OptionsContainer: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  `,
  CancelButton: styled.button`
    border: none;
    padding: 7px 10px;
    background-color: transparent;
    color: #121212;
    font-size: 17px;
    border-radius: 5px;
    margin: 10px 0px;
    align-self: flex-end;
  `,

  SubmitButton: styled.button`
    border: none;
    padding: 7px 10px;
    background-color: #1ab394;
    color: #fff;
    font-size: 17px;
    border-radius: 5px;
    margin: 10px 0px;
    align-self: flex-end;
  `,
};

export const HealthScore = styled.p`
  color: ${({ health }) => {
    if (+health >= 71) {
      return '#00a859';
    } else if (+health >= 51) {
      return '#f5a623';
    } else {
      return '#ea5f59';
    }
  }} !important;
`;
