import apiOld from '../Api';

const csApi = {
  getActiveContactsDashboard: async function (additionalParams = {}) {
    return apiOld.get(`/cs/activeContacts`, {
      params: {
        ...additionalParams,
      },
    });
  },

  getEngagementDashboard: async function (additionalParams = {}) {
    return apiOld.get('/cs/engagement', {
      params: {
        ...additionalParams,
      },
    });
  },

  getContractDashboard: async function (additionalParams = {}) {
    return apiOld.get('/cs/contracts', {
      params: {
        ...additionalParams,
      },
    });
  },

  getOnboardingDashboard: async function (additionalParams = {}) {
    return apiOld.get('/cs/onboarding', {
      params: {
        ...additionalParams,
      },
    });
  },

  getFeedbackDashboard: async function (additionalParams = {}) {
    return apiOld.get('/feedbacks/dashboard', {
      params: {
        ...additionalParams,
      },
    });
  },

  getFeedbackById: async function (feedbackId, additionalParams = {}) {
    return apiOld.get(`/feedbacks/${feedbackId}`, {
      params: {
        ...additionalParams,
      },
    });
  },

  getMetricByBackofficeUserId: async function (
    backofficeUserId,
    additionalParams = {}
  ) {
    return apiOld.get(`/cs/activeContacts/${backofficeUserId}/metrics`, {
      params: {
        ...additionalParams,
      },
    });
  },
};

export default csApi;
