import React, { useState } from "react";
import { useQuery } from "../../../routes";
import SectionAIR from "../../../components/riskAnalysis/SectionAIR";
import { dataSubcategory, dataTitleSubcategory } from "../renderContent";

export default function SubcategoryAIR() {
  const sector = useQuery().get('sector');
  const [params, setParams] = useState({ sector });

  return (
    <SectionAIR
      dataTitle={dataTitleSubcategory}
      data={dataSubcategory}
      params={params}
      isList={true}
    />
  );
}