import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import DefaultTags from '../../constants/DefaultTags';
import { openDemandModal } from '../../routes';
import { formatDate, objectToURLSearchParamsString } from '../../utils';
import { Constants } from '../../utils/Constants';
import { ButtonOptionsModal } from '../csModals/ButtonOptions';
import { PayOverdueIcon, SimpleTable } from './Tables.styles';

export const EngagementCustomerTable = ({ customers = [], fetchData }) => {
  const [buttonsModal, setButtonsModal] = useState({
    open: false,
    title: '',
    description: '',
    data: {},
    fetchData: () => {},
    handleCloseButtonsModal: () => {},
  });

  const handleCloseButtonsModal = () =>
    setButtonsModal(prev => ({
      ...prev,
      open: false,
      title: '',
      description: '',
      data: {},
      fetchData: () => {},
      handleCloseButtonsModal: () => {},
    }));

  const handleOpenButtonsModal = useCallback(
    customer => () =>
      setButtonsModal(prev => ({
        ...prev,
        open: true,
        title: 'Qual ação deseja realizar?',
        description:
          'Você pode sugerir uma reunião (caso o cliente não tenha) ou registrar uma atividade.',
        data: customer,
        fetchData,
        handleCloseButtonsModal,
      })),
    [fetchData]
  );

  const annotationsParams = useCallback(
    () =>
      objectToURLSearchParamsString({
        ANNOTATION_TYPE: Constants.ANNOTATION_TYPES.cs.key,
      }),
    []
  );
  return customers.length > 0 ? (
    <SimpleTable.Container>
      <SimpleTable.Table>
        <SimpleTable.TableHead>
          <SimpleTable.TableRow>
            <SimpleTable.TableHeadCell
              style={{
                width: '25%',
              }}
            >
              Clientes
            </SimpleTable.TableHeadCell>

            <SimpleTable.TableHeadCell
              style={{
                width: '25%',
              }}
            >
              Demanda de engajamento
            </SimpleTable.TableHeadCell>

            <SimpleTable.TableHeadCell
              style={{
                width: '25%',
              }}
            >
              Ultima atividade CS
            </SimpleTable.TableHeadCell>

            <SimpleTable.TableHeadCell
              style={{
                width: '25%',
              }}
            >
              Data da última atividade CS
            </SimpleTable.TableHeadCell>
          </SimpleTable.TableRow>
        </SimpleTable.TableHead>

        <SimpleTable.TableBody>
          {customers.map(customer => (
            <SimpleTable.TableRow key={customer.id}>
              <SimpleTable.TableData>
                <Link to={`/customers/${customer.id}`}>
                  {customer.name}
                  {customer.tags?.find(
                    tag => tag.name === DefaultTags.PAYMENT_OVERDUE
                  ) && (
                    <PayOverdueIcon className='fas fa-exclamation-circle'></PayOverdueIcon>
                  )}
                </Link>
              </SimpleTable.TableData>

              <SimpleTable.TableData>
                {customer.engagement.demandId ? (
                  <span
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      openDemandModal(customer.engagement.demandId)
                    }
                  >
                    {customer.engagement.demand.name}
                  </span>
                ) : (
                  <Link to={`/customers/${customer.id}/demands`}>Nenhuma</Link>
                )}
              </SimpleTable.TableData>

              <SimpleTable.TableData>
                <SimpleTable.TableDataButton
                  style={{
                    backgroundColor: '#1994FF',
                    fontWeight: 'bold',
                    color: 'white',
                    marginLeft: 0,
                  }}
                  onClick={handleOpenButtonsModal(customer)}
                >
                  Registrar nova atividade
                </SimpleTable.TableDataButton>
              </SimpleTable.TableData>
              <SimpleTable.TableData>
                <Link
                  title={
                    customer.annotations?.reduce(
                      (prev, curr) =>
                        curr?.type === Constants.ANNOTATION_TYPES.cs.key
                          ? curr.content
                          : prev,
                      ''
                    ) || ''
                  }
                  to={{
                    pathname: `/customers/${customer.id}`,
                    search: annotationsParams(),
                  }}
                >
                  {customer.engagement?.lastActivity
                    ? formatDate(customer.engagement.lastActivity)
                    : 'Nunca'}
                </Link>
              </SimpleTable.TableData>
            </SimpleTable.TableRow>
          ))}
        </SimpleTable.TableBody>
      </SimpleTable.Table>
      <ButtonOptionsModal {...buttonsModal} />
    </SimpleTable.Container>
  ) : (
    <SimpleTable.TableRow>
      <SimpleTable.TableData>
        <span>Nenhum cliente 😃</span>
      </SimpleTable.TableData>
    </SimpleTable.TableRow>
  );
};
