import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
const useStyles = makeStyles(theme => ({
  main: {
    position: 'fixed',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    background: 'rgba(0,0,0,0.8)',
    zIndex: '99999',
    opacity: '1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default function Loading({ open }) {
  const classes = useStyles();

  return (
    <>
      {open && (
        <div className={classes.main}>
          <CircularProgress />
        </div>
      )}
    </>
  );
}
