import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;

  .DraftEditor-root {
    box-sizing: border-box;
    border: 1px solid #ddd;
    cursor: text;
    padding: 5px;
    border-radius: 2px;
    box-shadow: inset 0px 1px 6px -3px #ababab;
    background: #fefefe;
    overflow-y: auto;
    /* height: 100px; */
    height: calc(100% - 36px);
    overflow-x: hidden;
  }
`;

export const AttachmentIcon = {
  Container: styled.div`
    display: inline-block;
    background: #fbfbfb;
    color: #888;
    font-size: 18px;
    border: 0;
    vertical-align: bottom;
    min-height: 34px;
    min-width: 36px;
    max-height: 34px;
    max-width: 200px;
    cursor: pointer;

    &:hover,
    &:focus {
      background: #f3f3f3;
    }
  `,
  Label: styled.label`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    min-height: 100%;
    min-height: 34px;
    max-width: 100%;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    align-self: center;

    i {
      color: #888;
    }
  `,
  Input: styled.input`
    display: none;
  `,
  Preview: styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 3px;
    font-size: 11px;
  `,
};
