import moment from 'moment';
import { Constants } from './Constants';

const fileToBase64 = file => {
  return new Promise(resolve => {
    const reader = new FileReader();

    reader.onload = function (event) {
      resolve(event.target.result);
    };
    reader.readAsDataURL(file);
  });
};

export const formatFileToBase64 = async file => {
  return {
    base64: await fileToBase64(file),
    type: file.type,
    name: file.name,
    size: file.size,
  };
};

export const formatId = id => {
  return '0'.repeat(5 - String(id).length) + id;
};

/**
 * Parse date to yup validate
 */
export const parseDateFromYupWithMask = (
  value,
  originalValue,
  mask = 'DD/MM/YYYY'
) => {
  return originalValue ? moment(originalValue, mask, true).toDate() : null;
};

export const formatDateHour = date => {
  return moment(date).format('HH:mm');
};

export const formatDateWithMonthName = date => {
  return moment(date).format('DD [de] MMMM [de] YYYY');
};

export const formatDate = date => {
  const parsedDate = moment(date).format('DD/MM/YYYY') || '';

  if (parsedDate.includes('/')) return parsedDate;

  return '';
};

export const formatDateFullDetails = date => {
  return moment(date).format('DD [de] MMMM [de] YYYY [às] HH:mm');
};

export const tryParseJson = target => {
  try {
    const result = JSON.parse(target);

    if (typeof result !== 'object') {
      return null;
    }

    return result;
  } catch (error) {
    return null;
  }
};

export const enumerateDaysBetweenDates = (startDate, endDate, format) => {
  var dates = [];
  var currDate = moment(startDate).startOf('day');
  var lastDate = moment(endDate).startOf('day');

  while (currDate.diff(lastDate) <= 0) {
    dates.push(currDate.clone().format(format));
    currDate.add(1, 'days');
  }

  return dates;
};

export const enumerateMonthsBetweenDates = (startDate, endDate, format) => {
  var dates = [];
  var currDate = moment(startDate).startOf('day');
  var lastDate = moment(endDate).startOf('day');

  while (currDate.diff(lastDate) <= 0) {
    dates.push(currDate.clone().format(format));
    currDate.add(1, 'month');
  }

  return dates;
};

export const preventWindowCloseOnUpload = e => {
  const confirmationMessage =
    'O envio dos arquivos ainda não terminou, você deseja realmente sair?';

  (e || window.event).returnValue = confirmationMessage;

  return confirmationMessage;
};

export const isHoliday = date => {
  return Constants.HOLIDAYS[date.month()][date.date() - 1];
};

export const validateEmail = email => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
};

export const paramsToObject = entries => {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
};

export const objectToURLSearchParamsString = object =>
  new URLSearchParams(object).toString();

export const getJourneyCompletePercentage = (demands = []) => {
  const total = demands.length;
  const completed = demands.filter(
    demand => demand.statusKey === Constants.DEMAND_STATUSES.FINISHED.key
  ).length;

  return Math.round((completed / total) * 100) || 0;
};

export const saveFileToUserDisk = (fileBinary, fileType, fileName) => {
  const fileUrl = window.URL.createObjectURL(
    new Blob([fileBinary], {
      type: fileType,
    })
  );
  const link = document.createElement('a');

  link.href = fileUrl;
  link.target = '_blank';
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const addBusinessDays = (originalDate, numDaysToAdd) => {
  const Sunday = 0;
  const Saturday = 6;
  let daysRemaining = numDaysToAdd;

  const newDate = originalDate.clone();

  while (daysRemaining > 0) {
    newDate.add(1, 'days');
    if (newDate.day() !== Sunday && newDate.day() !== Saturday) {
      daysRemaining--;
    }
  }

  return newDate;
};

export const getDifferenceInBusinessDays = (firstDate, secondDate) => {
  const Sunday = 0;
  const Saturday = 6;
  let diff = 0;

  const secondDateIsAfterFirstDate = secondDate.isAfter(firstDate, 'days');

  const upperDate = secondDateIsAfterFirstDate ? secondDate : firstDate;

  const lowerDate = secondDateIsAfterFirstDate ? firstDate : secondDate;

  while (!upperDate.isSame(lowerDate, 'days')) {
    upperDate.subtract(1, 'days');
    if (upperDate.day() !== Sunday && upperDate.day() !== Saturday) {
      diff += secondDateIsAfterFirstDate ? 1 : -1;
    }
  }

  return diff;
};

export const parseBRcurrencyToFloat = string =>
  parseFloat(string.replace(/[^0-9,-]+/g, '').replace(',', '.')) || null;

export const findNewIndexInIndexesList = (currentIdx, nextIdx, indexesList) => {
  // TODO: if currentIdx is not in indexesList, use nextIdx - 1 as base index?
  /* 
      Calcula o novo índice do elemento que foi movido numa lista de índices (float)
      Calc next index of element in list of indexes (float)
    */
  if (+nextIdx !== currentIdx) {
    let result;
    const indexesListLength = indexesList.length;
    const idxOfNewValue = indexesList.findIndex(idx => idx === +nextIdx);

    if (idxOfNewValue === 0) {
      // quer mudar para primeira posição
      result = indexesList[0] / 2;
    } else if (idxOfNewValue === indexesListLength - 1) {
      // quer mudar para ultima posição
      result = indexesList[indexesListLength - 1] * 2;
    } else {
      // quer mudar para o meio de alguma posicao
      if (nextIdx < currentIdx)
        // ex: [1,3,4, x,5] -> [1,x,3,4,5] = (1+3)/2
        // se a proxima posição for menor que a atual,
        // compara com a próxima posição e a posição anterior
        result = Number(
          (indexesList[idxOfNewValue - 1] + indexesList[idxOfNewValue]) / 2
        );
      else {
        // ex: [1,x,3,4,5] -> [1,3,4, x,5] = (4+5)/2
        // se a proxima posição for maior que a atual,
        // compara com a próxima posição e a posição posterior
        result = Number(
          (indexesList[idxOfNewValue + 1] + indexesList[idxOfNewValue]) / 2
        );
      }
    }

    return result;
  } else {
    return currentIdx;
  }
};
