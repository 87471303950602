import React from 'react';
import { InfoModalStyles } from './InfoModal.styles';
import Modal from '../modal/Modal';

export default function InfoModal({ open, title, info, handleClose, ...rest }) {
  return (
    <Modal visible={!!open} {...rest}>
      {!!open && (
        <InfoModalStyles.Container>
          <InfoModalStyles.ButtonClose
            className='fa fa-lg fa-times'
            onClick={handleClose}
          />

          <InfoModalStyles.Title>{title}</InfoModalStyles.Title>

          <InfoModalStyles.InfoContainer>
            <InfoModalStyles.InfoText>{info}</InfoModalStyles.InfoText>
          </InfoModalStyles.InfoContainer>
        </InfoModalStyles.Container>
      )}
    </Modal>
  );
}
