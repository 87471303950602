import styled from 'styled-components';
import { ReactComponent as CloseIconSVG } from '../../static/X.svg';

export const Frame = styled.iframe`
  width: 80%;
  height: 95%;
`;
export const Close = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  color: #fff;
  border-radius: 5px;
  font-size: 16px;
  background-color: #4c494c;
  padding: 20px 20px;
  height: 30px;
  width: 105px;
  margin: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

export const CloseIcon = styled(CloseIconSVG)`
  min-width: 15px;
  min-height: 15px;
  viewbox: 0 0 10 10;
  margin-left: 5px;

  rect {
    fill: #fff;
  }
`;
