import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useParams } from 'react-router-dom';
import MiniDrawer from '../../components/MiniDrawer';
import DeleteUserModal from '../../components/modals/users/deleteUserModal/DeleteUserModal';
import apiOld from '../../services/Api';
import { Constants } from '../../utils/Constants';
import { formatDate } from '../../utils';

const useStyles = makeStyles(() => ({
  main: {
    background: '#FFF',
  },
  mainTitle: {
    background: '#FFF',
    borderTop: 'solid 2px #e7eaec',
    borderBottom: 'solid 1px #e7eaec',
    padding: '15px',
  },
  line: {
    backgorund: 'reds',
  },
  title: {
    fontSize: '15px',
  },
  mainForm: {
    padding: '10px',
  },
  form: {
    width: '100%',
  },
  input: {
    padding: '10px',
  },
  buttonContainer: {
    justifyContent: 'flex-end',
    padding: '10px',
  },
  submit: {
    padding: '10px 15px',
    fontSize: '17px',
    background: '#1ab394',
    border: 'none',
    color: '#fff',
    borderRadius: '5px',
    cursor: 'pointer',
  },
}));

export default function UserProfile() {
  const { id } = useParams();
  const classes = useStyles();
  const [user, setUser] = useState(false);
  const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);

  useEffect(() => {
    apiOld
      .get(`/users/${id}`, {
        params: {
          withCustomer: true,
        },
      })
      .then(response => {
        setUser(response.data);
      });
  }, [id]);

  return (
    <React.Fragment>
      <MiniDrawer currentExpaded='users'>
        <Grid container className={classes.main}>
          {user && (
            <>
              <Grid container className={classes.mainTitle}>
                <Grid container justify='space-between'>
                  <h2 className={classes.title}>Usuário</h2>

                  <button
                    className={classes.submit}
                    type='button'
                    style={{
                      backgroundColor: '#f44336',
                    }}
                    onClick={() => setDeleteUserModalOpen(true)}
                  >
                    Excluir usuário
                  </button>
                </Grid>
              </Grid>

              <Grid container className={classes.mainForm}>
                <Grid container>
                  <Grid item className={classes.input} xs={6}>
                    <label>Nome</label>
                    <p>{user.name}</p>
                  </Grid>
                  <Grid item className={classes.input} xs={6}>
                    <label>E-mail</label>
                    <p>{user.email}</p>
                  </Grid>
                  <Grid item className={classes.input} xs={6}>
                    <label>Função</label>
                    <p>
                      {user.customFields
                        ? user.customFields.occupation
                          ? user.customFields.occupation
                          : ''
                        : ''}
                    </p>
                  </Grid>
                  <Grid item className={classes.input} xs={6}>
                    <label>Tipo de usuário</label>
                    <p>{user.type}</p>
                  </Grid>
                  <Grid item className={classes.input} xs={6}>
                    <label>Cliente Administrador</label>
                    <p>{user.customerAdmin ? 'Sim' : 'Não'}</p>
                  </Grid>
                  {user.type === Constants.USER_TYPES.CUSTOMER ? (
                    <Grid item className={classes.input} xs={6}>
                      <label>Cliente</label>
                      <p>
                        <Link
                          to={`/customers/${user.customerId}`}
                          style={{ textDecoration: 'none' }}
                        >
                          {user.Customer?.name}
                        </Link>
                      </p>
                    </Grid>
                  ) : null}
                </Grid>
                {user.type === Constants.USER_TYPES.BACKOFFICE ? (
                  <Grid container className={classes.mainTitle}>
                    <Grid container justify='space-between'>
                      <h2 className={classes.title}>Férias</h2>
                    </Grid>
                    <Grid item className={classes.input} xs={6}>
                      {user?.vacations?.map((vacation, index) => {
                        return (
                          <p>{index + 1}º Férias teve inicio: <label>{formatDate(vacation.startDate)}</label> e Fim: <label>{formatDate(vacation.endDate)}</label></p>
                        )
                      })}

                    </Grid>
                  </Grid>

                ) : ''}


              </Grid>

              <DeleteUserModal
                open={deleteUserModalOpen}
                userToDelete={user}
                handleClose={() => setDeleteUserModalOpen(false)}
              />
            </>
          )}
        </Grid>
      </MiniDrawer>
    </React.Fragment>
  );
}
