import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  padding: 30px 40px;
  border-radius: 15px;
  max-width: 700px;

  i {
    align-self: flex-end;
    margin-left: 20px;
    cursor: pointer;
  }

  form {
    width: 100%;
  }
`;

export const Title = styled.h1`
  font-size: 22px;
  font-weight: bold;
  color: #262626;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
`;

export const Description = styled.p`
  font-weight: normal;
  color: #262626;
  margin-bottom: 20px;
  width: 100%;
`;

export const ButtonSend = styled.button`
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 15px;
  color: #fff;
  background-color: #1ab394;
  margin-top: 20px;
  /* align-self: flex-end; */
  margin-left: 85%;
`;
