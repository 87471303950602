import CircularProgress from "@material-ui/core/CircularProgress";
import { Form } from "@unform/web";
import moment from "moment";
import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import demandsApi from "../../../services/apis/demandsApi";
import Calendar from "../../input/calendar/Calendar";
import {
  ButtonsContainer,
  Container,
  ContainerInput,
  ExitButton,
  Modal,
  SubmitButton,
} from "./ChangeInternSLAModal.styles";

export default function ChangeInternSLAModal(props) {
  const { open, handleClose, modalData } = props;
  const formRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  async function handleSubmit(data) {
    try {
      setSubmitLoading(true);
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        SLA: Yup.date().required().nullable(true),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const request = {
        deliveryTime: data.SLA,
      };

      await demandsApi.updateDemandInternSLA(modalData.id, request);

      toast.success("SLA Interno alterado com sucesso!");

      handleClose(true);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};

        error.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    } finally {
      setSubmitLoading(false);
    }
  }

  return (
    <>
      {open && (
        <Container>
          <Modal>
            <h1>Alterar SLA Interno</h1>

            <Form ref={formRef} onSubmit={handleSubmit}>
              <ContainerInput>
                <Calendar
                  name="SLA"
                  label="SLA Interno"
                  placeholder="Selecionar data"
                  defaultValue={moment().toDate()}
                  selectRange={false}
                />
              </ContainerInput>
              <ButtonsContainer>
                <SubmitButton type="submit">
                  {!submitLoading && "Alterar"}
                  {submitLoading && <CircularProgress color="inherit" />}
                </SubmitButton>
                <ExitButton onClick={() => handleClose(false)}>
                  Fechar
                </ExitButton>
              </ButtonsContainer>
            </Form>
          </Modal>
        </Container>
      )}
    </>
  );
}
