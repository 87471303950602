import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import { Checkbox } from '@material-ui/core';

export default function CheckboxInput({ name, label, defaultValue, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, registerField } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'checked',
    });
  }, [fieldName, registerField]);

  return (
    <label
      htmlFor={inputRef}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: '#A8A8A8',
        fontSize: '14px',
      }}
    >
      <Checkbox
        inputRef={inputRef}
        defaultChecked={defaultValue}
        defaultValue={defaultValue}
        {...rest}
      />
      {label}
    </label>
  );
}
