import React, { useEffect, useCallback } from 'react';
import { Switch, Route, Redirect, Router, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// -------- PUBLIC
import history from './history';
import SignIn from './pages/SignIn';
import ResetPassword from './pages/ResetPassword';
import ResetPasswordValidate from './pages/ResetPasswordValidate';
import Privacy from './pages/policies/Privacy';
import Terms from './pages/policies/Terms';
// -------- HOME
import Dashboard from './pages/dashboard/Dashboard';
import Notifications from './pages/Notifications';
// -------- USERS
import CreateUser from './pages/user/CreateUser';
import CreateTemplate from './pages/template/CreateTemplate';
import ListUser from './pages/user/ListUser';
import EditUser from './pages/user/EditUser';
import UserProfile from './pages/user/UserProfile';
import EditMyProfile from './pages/user/EditMyProfile';
// -------- CUSTOMERS
import CreateCustomer from './pages/customer/CreateCustomer';
import EditCustomer from './pages/customer/EditCustomer';
import CustomerProfile from './pages/customer/CustomerProfile';
import ListCustomer from './pages/customer/ListCustomer';
import CustomerJourneysBoard from './pages/customer/CustomerJourneysBoard';
// -------- CS
import EngagementDashboard from './pages/customersuccess/engagements/Engagement';
import ActiveContactDashboard from './pages/customersuccess/activeContact/ActiveContactDashboard';
import OnboardingDashboard from './pages/customersuccess/onboarding/OnboardingDashboard';
import ContractsDashboard from './pages/customersuccess/contractsDashboard/ContractDashboard';
// -- feedbacks
import FeedbackDashboard from './pages/customersuccess/feedbacks/feedbacksDashboard/FeedbackDashboard';
import FeedbackOnboardingDashboard from './pages/customersuccess/feedbacks/feedbacksOnboading/FeedbackOnboarding';
import FeedbackActiveContactDashboard from './pages/customersuccess/feedbacks/feedbacksActiveContact/FeedbackActiveContact';
import FeedbackPlatform from './pages/customersuccess/feedbacks/feedbacksPlatform/FeedbackPlatform';
// ----------
import CsDemandsBoard from './pages/customersuccess/csDemandsBoard/CsDemandsBoard';
import CreateRopa from './pages/customersuccess/ropa/CreateMapping';
// -------- TEMPLATES
import TemplateProfile from './pages/template/TemplateProfile';
import EditTemplate from './pages/template/editTemplate/EditTemplate';
import ListTemplates from './pages/template/ListTemplates';
// -------- DOCUMENTS
import CreateDocument from './pages/document/CreateDocument';
import DocumentProfile from './pages/document/DocumentProfile';
import ListDocuments from './pages/document/ListDocuments';
// -------- FORMS
import CreateForm from './pages/forms/CreateForm';
import ListForms from './pages/forms/ListForms';
import EditForm from './pages/forms/EditForm';
import ViewForm from './pages/forms/ViewForm';
// -------- JOURNEY TYPES
import CreateJourneyType from './pages/journey/CreateJourneyType';
import ViewJourneyType from './pages/journey/ViewJourneyType';
import EditJourneyType from './pages/journey/EditJourneyType';
import ListJourneyType from './pages/journey/ListJourneyType';
import EditJourneyTypeOrder from './pages/journey/EditJourneyTypeOrder';
// -------- CUSTOMER JOURNEY
import CreateCustomerJourney from './pages/journey/CreateCustomerJourney';
import ListCustomerJourney from './pages/journey/ListCustomerJourney';
import EditListCustomerJourney from './pages/journey/EditListCustomerJourney';
import EditCustomerJourneyDemandsOrder from './pages/journey/EditCustomerJourneyDemandsOrder';
// -------- DEMANDS
import CustomerDemandsBoard from './pages/demand/customerDemandsBoard/CustomerDemandsBoard';
import DemandsBoard from './pages/demand/demandsBoard/DemandsBoard';
import CreateDemandType from './pages/demand/demandType/CreateDemandType';
import EditDemandType from './pages/demand/demandType/EditDemandType';
import DemandTypeProfile from './pages/demand/demandType/DemandTypeProfile';
import ListDemandTypes from './pages/demand/demandType/ListDemandTypes';
import CreateDemand from './pages/demand/CreateDemand';
import CreateDemandNotification from './pages/demand/CreateDemandNotification';
import ListDemands from './pages/demand/ListDemands';
import Marcas from './pages/marcas/Marcas'
import DemandModal from './components/demandModal/DemandModal';
// -------- LOGS
import ListLogs from './pages/logs/ListLogs';
import ViewLog from './pages/logs/ViewLog';
// -------- TAGS
import ListTags from './pages/tags/ListTags';
import CreateTag from './pages/tags/CreateTag';
// -------- RISK ANALYSIS
import SectorAIR from './pages/riskAnalysis/sector/sector';
import SubcategoryAIR from './pages/riskAnalysis/subcategory/Subcategory';
import QuestionAIR from './pages/riskAnalysis/questions/Questions';
import MenuAIR from './pages/riskAnalysis/menu/menu';
import ScoreAIRByCustomer from './pages/riskAnalysis/byCustomer/scoreAIRByCustomer';
import AnswerByCustomer from './pages/riskAnalysis/byCustomer/answerByCustomer';
// -------- SALES
import SalesDashboard from './pages/sales/SalesDashboard';
// -------- OTHERS
import Test from './pages/Test';

import authApi from './services/apis/authApi';
import usersApi from './services/apis/usersApi';
import demandTypesApi from './services/apis/demandTypesApi';
import formsApi from './services/apis/formsApi';
import { loadConfigs } from './redux/actions/configs';
import EditTemplateFile from './pages/template/editTemplateFile/EditTemplateFile';
import DocumentTemplateMetrics from './pages/template/documentTemplateMetrics/DocumentTemplateMetrics';
import Vacations from './pages/user/Vacations';
import NewMarca from './pages/marcas/NewMarca';
import fileToUrl from './pages/tools/fileToUrl';
import PopUp from './pages/tools/popup';
import CreateDemandMessage from './pages/demand/DemandMessage';
import UsersToBusiness from './pages/business/UsersToBusiness';

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const addQueryParamToUrl = (key, value) => {
  const queryParams = new URLSearchParams(window.location.search);

  queryParams.set(key, value);

  history.push({
    pathname: window.location.pathname,
    search: queryParams.toString(),
  });
};

export const removeQueryParamFromUrl = key => {
  const queryParams = new URLSearchParams(window.location.search);

  queryParams.delete(key);

  history.push({
    pathname: window.location.pathname,
    search: queryParams.toString(),
  });
};

export const openDemandModal = (demandId, options) => {
  addQueryParamToUrl('demandId', demandId);
  for (let key in options) {
    addQueryParamToUrl(key, options[key]);
  }
};

export const closeDemandModal = () => {
  removeQueryParamFromUrl('demandId');
  removeQueryParamFromUrl('isScreeningModalOpen');
};

function Routes() {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const dispatch = useDispatch();

  const fetchUserData = useCallback(async () => {
    try {
      await authApi.getAuthUser();
    } catch (error) {
      console.error(error);
    }
  }, []);

  const fetchDemandsBoardData = useCallback(async () => {
    try {
      await usersApi.getAllBackofficeUsers({
        orderBy: 'name',
      });
      await demandTypesApi.getAllDemandTypes();
      await formsApi.getAllForms();
    } catch (error) {
      console.error('erro', error);
    }
  }, []);

  useEffect(() => {
    // global state
    if (isAuthenticated) {
      fetchUserData();
      fetchDemandsBoardData();
      dispatch(loadConfigs());
    }
  }, [fetchUserData, fetchDemandsBoardData, isAuthenticated, dispatch]);

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/signIn', state: { from: props.location } }}
          />
        )
      }
    />
  );

  const PublicRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );

  return (
    <Router history={history}>
      <DemandModal />

      <Switch>
        <Route exact path='/privacy' component={Privacy} />
        <Route exact path='/terms' component={Terms} />

        <PublicRoute exact path='/signIn' component={SignIn} />
        <PublicRoute exact path='/resetPassword' component={ResetPassword} />
        <PublicRoute
          exact
          path='/reset-password/email/:email/token/:token'
          component={ResetPasswordValidate}
        />

        <PrivateRoute exact path='/' component={Dashboard} />
        <PrivateRoute exact path='/notifications' component={Notifications} />
        <PrivateRoute exact path='/profile/edit' component={EditMyProfile} />
        <PrivateRoute exact path='/new/marcas' component={NewMarca} />
        <PrivateRoute exact path='/marcas' component={Marcas} />

        {/* // -------------- CS -------------*/}
        <PrivateRoute
          exact
          path='/cs/engagement'
          component={EngagementDashboard}
        />
        <PrivateRoute
          exact
          path='/cs/contact'
          component={ActiveContactDashboard}
        />
        <PrivateRoute
          exact
          path='/cs/onboarding'
          component={OnboardingDashboard}
        />
        <PrivateRoute
          exact
          path='/cs/contracts'
          component={ContractsDashboard}
        />
        <PrivateRoute
          exact
          path='/cs/feedbacks'
          component={FeedbackDashboard}
        />

        <PrivateRoute
          exact
          path='/cs/feedbacks/onboarding'
          component={FeedbackOnboardingDashboard}
        />

        <PrivateRoute
          exact
          path='/cs/feedbacks/active-contact'
          component={FeedbackActiveContactDashboard}
        />

        <PrivateRoute
          exact
          path='/cs/feedbacks/platform'
          component={FeedbackPlatform}
        />

        <PrivateRoute
          exact
          path='/cs/csDemandsBoard'
          component={CsDemandsBoard}
        />

        <PrivateRoute exact path='/tools/ropa' component={CreateRopa} />
        <PrivateRoute exact path='/tools/fileToUrl' component={fileToUrl} />
        <PrivateRoute exact path='/tools/popup' component={PopUp} />

        <PrivateRoute exact path='/new/users/' component={CreateUser} />
        <PrivateRoute exact path='/users' component={ListUser} />
        <PrivateRoute exact path='/vacations' component={Vacations} />
        <PrivateRoute exact path='/users/:id/edit' component={EditUser} />
        <PrivateRoute exact path='/users/:id' component={UserProfile} />

        <PrivateRoute exact path='/customers' component={ListCustomer} />
        <PrivateRoute exact path='/new/customers/' component={CreateCustomer} />
        <PrivateRoute
          exact
          path='/customers/:id/edit'
          component={EditCustomer}
        />
        <PrivateRoute exact path='/customers/:id' component={CustomerProfile} />
        <PrivateRoute
          exact
          path='/customers/:id/demands'
          component={CustomerDemandsBoard}
        />
        <PrivateRoute
          exact
          path='/business/demands'
          component={CustomerDemandsBoard}
        />
        <PrivateRoute
          exact
          path='/business/journeys'
          component={CustomerJourneysBoard}
        />
        <PrivateRoute
          exact
          path='/customers/:id/journeys/:customerJourneyId/editDemandsOrder'
          component={EditCustomerJourneyDemandsOrder}
        />

        {/* // -------------- TEMPLATES -------------*/}

        <PrivateRoute exact path='/templates' component={ListTemplates} />
        <PrivateRoute exact path='/new/templates' component={CreateTemplate} />
        <PrivateRoute exact path='/templates/:id' component={TemplateProfile} />
        <PrivateRoute
          exact
          path='/templates/:id/edit'
          component={EditTemplate}
        />
        <PrivateRoute
          exact
          path='/templates/:id/edit/templateFile'
          component={EditTemplateFile}
        />
        <PrivateRoute
          exact
          path='/metrics/templates'
          component={DocumentTemplateMetrics}
        />

        <PrivateRoute exact path='/documents' component={ListDocuments} />
        <PrivateRoute exact path='/new/documents' component={CreateDocument} />
        <PrivateRoute exact path='/documents/:id' component={DocumentProfile} />

        <PrivateRoute exact path='/new/forms/' component={CreateForm} />
        <PrivateRoute exact path='/forms' component={ListForms} />
        <PrivateRoute exact path='/forms/:id/edit' component={EditForm} />
        <PrivateRoute exact path='/forms/:id' component={ViewForm} />

        <PrivateRoute
          exact
          path='/new/demands/types'
          component={CreateDemandType}
        />
        <PrivateRoute
          exact
          path='/demands/types/:id/edit'
          component={EditDemandType}
        />
        <PrivateRoute
          exact
          path='/demands/types/:id/'
          component={DemandTypeProfile}
        />
        <PrivateRoute exact path='/demands/types' component={ListDemandTypes} />
        {/* ------------------------- JOURNEY TYPES --------------------------- */}
        <PrivateRoute
          exact
          path='/new/journeyTypes'
          component={CreateJourneyType}
        />

        <PrivateRoute exact path='/journeyTypes' component={ListJourneyType} />

        <PrivateRoute
          exact
          path='/journeyTypes/editOrder'
          component={EditJourneyTypeOrder}
        />

        <PrivateRoute
          exact
          path='/journeyTypes/:journeyTypeId'
          component={ViewJourneyType}
        />

        <PrivateRoute
          exact
          path='/journeyTypes/:journeyTypeId/edit'
          component={EditJourneyType}
        />

        <PrivateRoute
          exact
          path='/new/customers/journeys'
          component={CreateCustomerJourney}
        />
        <PrivateRoute
          exact
          path='/customersJourneys'
          component={ListCustomerJourney}
        />

        <PrivateRoute
          exact
          path='/editcustomersJourneys'
          component={EditListCustomerJourney}
        />
        {/* ------------------------- DEMANDS --------------------------- */}

        <PrivateRoute exact path='/new/demands' component={CreateDemand} />
        <PrivateRoute exact path='/new/demands/notification' component={CreateDemandNotification} />
        <PrivateRoute exact path='/new/demands/message' component={CreateDemandMessage} />
        <PrivateRoute exact path='/demands/board' component={DemandsBoard} />
        <PrivateRoute exact path='/demands/' component={ListDemands} />

        {/* ------------------------- TAGS --------------------------- */}

        <PrivateRoute exact path='/new/tags' component={CreateTag} />
        <PrivateRoute exact path='/tags' component={ListTags} />

        {/* ------------------------- LOGS --------------------------- */}

        <PrivateRoute exact path='/logs' component={ListLogs} />
        <PrivateRoute exact path='/logs/:id' component={ViewLog} />

        {/* ------------------------- SALES --------------------------- */}

        <PrivateRoute
          exact
          path='/sales/dashboard'
          component={SalesDashboard}
        />

        {/* ------------------------- ANALYSIS RISK --------------------------- */}
        <PrivateRoute exact path='/riskAnalysis/sector' component={SectorAIR} />
        <PrivateRoute exact path='/riskAnalysis/subcategory' component={SubcategoryAIR} />
        <PrivateRoute exact path='/riskAnalysis/question' component={QuestionAIR} />
        <PrivateRoute exact path='/riskAnalysis/menu/:id/score' component={ScoreAIRByCustomer} />
        <PrivateRoute exact path='/riskAnalysis/menu/:id/business/:businessId' component={AnswerByCustomer} />
        <PrivateRoute exact path='/riskAnalysis/menu' component={MenuAIR} />
        
        {/* ---------------------------------------------------- */}

        {/* ------------------------- BUSINESS --------------------------- */}
        
        <PrivateRoute exact path='/business/:id' component={UsersToBusiness} />

        {/* ---------------------------------------------------- */}

        <PrivateRoute exact path='/test' component={Test} />
      </Switch>
    </Router>
  );
}

export default Routes;
