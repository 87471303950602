import React, { useRef, useState } from "react";
import { Form } from "@unform/web";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Yup from "yup";
import apiOld from "../../../services/Api";
import CustomSelectWithSearch from "../../input/customSelectorWithSearch/CustomSelectWithSearch";
import {
  ButtonsContainer,
  Container,
  ContainerInput,
  ExitButton,
  Modal,
  SubmitButton,
} from "./ChangeDemandResponsibleModal.styles";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export default function ChangeDemandResponsibleModal(props) {
  const { open, handleClose, modalData } = props;
  const backofficeUsers = useSelector((state) => state.backofficeUsers.all);
  const formRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  async function handleSubmit(data) {
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        backofficeUserId: Yup.string()
          .required()
          .nullable(true)
          .label("Advogado"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setSubmitLoading(true);

      const res = await apiOld.patch(`/demands/${modalData.id}/backofficeUser`, data).catch(err => err.response);

      if(res.status !== 200){
        return alert(res.data)
      }

      toast.success("Responsável alterado com sucesso!");

      handleClose(true);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};

        error.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    } finally {
      setSubmitLoading(false);
    }
  }

  return (
    <>
      {open && (
        <Container>
          <Modal>
            <h1>Alterar responsável pela demanda</h1>

            <Form ref={formRef} onSubmit={handleSubmit}>
              <ContainerInput>
                <CustomSelectWithSearch
                  name="backofficeUserId"
                  label="Advogado"
                  defaultValue={modalData.demandTypeId}
                  options={backofficeUsers?.map((backofficeUser) => ({
                    name: backofficeUser.name,
                    value: backofficeUser.id,
                  }))}
                />
              </ContainerInput>

              <ButtonsContainer>
                <SubmitButton type="submit">
                  {!submitLoading && "Alterar"}
                  {submitLoading && <CircularProgress color="inherit" />}
                </SubmitButton>

                <ExitButton onClick={() => handleClose(false)}>
                  Fechar
                </ExitButton>
              </ButtonsContainer>
            </Form>
          </Modal>
        </Container>
      )}
    </>
  );
}
