import apiOld from '../../Api';

const templateFieldsApi = {
  createTemplateField: function (data) {
    return apiOld.post('/templateFields', data);
  },
  updateTemplateField: function (fieldId, data) {
    return apiOld.put(`/templateFields/${fieldId}`, data);
  },
};

export default templateFieldsApi;
