import apiOld, {  newApi } from '../Api';
import redux from '../../redux/store';
import * as demandActions from '../../redux/actions/demandActions';
import { toast } from 'react-toastify';

const { store } = redux;

const demandsApi = {
  getAllDemandsWithCustomer: async function (additionalParams = {}) {
    return new Promise((resolve, reject) => {
      apiOld
        .get('/demands', {
          params: {
            hasCustomer: true,
            ...additionalParams,
          },
        })
        .then(response => {
          store.dispatch(demandActions.recieveDemands(response.data.demands));
          resolve(response);
        })
        .catch(reject);
    });
  },

  getAllDemands: async function (additionalParams = {}) {
    return apiOld.get('/demands', {
      params: additionalParams,
    });
  },

  getDemandsPanel: async function () {
    return apiOld.get('/demandsPanel');
  },

  getCsDemandsPanel: async function () {
    return apiOld.get('/csDemandsPanel');
  },

  getDemandById: async function (id, options) {
    return apiOld.get(`/demands/${id}`, options);
  },

  getCustomerSlas: async function ({ businessId, demandTypeName }) {
    return apiOld.get(`/demands/${businessId}/slas`, {
      params: { demandTypeName },
    });
  },

  updateDemand: async function (id, data) {
    return new Promise((resolve, reject) => {
      apiOld
        .put(`/demands/${id}`, data)
        .then(response => {
          store.dispatch(demandActions.patchDemand(response.data));
          resolve(response);
        })
        .catch(error => reject(error));
    });
  },

  updateDemandStatus: async function (demandId, data) {
    return new Promise((resolve, reject) => {
      apiOld
        .patch(`/demands/${demandId}/status`, data)
        .then(response => {
          store.dispatch(demandActions.patchDemand(response.data));
          resolve(response);
        })
        .catch(error => reject(error));
    });
  },

  updateDemandInternSLA: async function (demandId, data) {
    return new Promise((resolve, reject) => {
      apiOld
        .patch(`/demands/${demandId}/internDeliveryForecast`, data)
        .then(response => {
          store.dispatch(demandActions.patchDemand(response.data));
          resolve(response);
        })
        .catch(error => reject(error));
    });
  },

  createDemand: async function (data) {
    return new Promise((resolve, reject) => {
      apiOld
        .post(`/demands`, data)
        .then(response => {
          store.dispatch(demandActions.createDemand(response.data));
          resolve(response);
        })
        .catch(error => reject(error));
    });
  },

  sendDemand: async function (data) {
    return new Promise((resolve, reject) => {
      apiOld
        .post(`/sendDemands`, data)
        .then(response => {
          store.dispatch(demandActions.createDemand(response.data));
          resolve(response);
        })
        .catch(error => reject(error))
    })
  },

  deleteDemand: async function (demandId) {
    return new Promise((resolve, reject) => {
      apiOld
        .delete(`/demands/${demandId}`)
        .then(response => {
          store.dispatch(demandActions.deleteDemand(demandId));
          resolve(response);
        })
        .catch(error => reject(error));
    });
  },

  prioritizeDemand: async function (demandId) {
    return newApi.patch(`/demands/${demandId}/prioritize`);
  },

  demandToggleFollowingUser: async function (demandId, additionalParams = {}) {
    return new Promise((resolve, reject) => {
      apiOld
        .patch(`/demands/${demandId}/following`, additionalParams)
        .then(response => {
          resolve(response);
        })
        .catch(error => reject(error));
    });
  },

  alterBusiness: async function (demandId, businessId) {
    try {
      await apiOld.post(`/demands/${demandId}/alterBusiness`, { businessId });
      toast.success('Negócio alterado com sucesso!');
    } catch (error) {
      toast.error('Erro ao alterar negócio!');
    }    
  },

  demandTogglePrivate: async function (demandId, additionalParams = {}) {
    return new Promise((resolve, reject) => {
      apiOld
        .patch(`/demands/${demandId}/private`, additionalParams)
        .then(response => {
          resolve(response);
        })
        .catch(error => reject(error));
    });
  },
};

export default demandsApi;
