import styled from "styled-components";

export const Container = styled.div`
  display: flex;

  label {
    font-size: 18px;
    margin-bottom: 20px;
  }

  a {
    color: #3ca4ff;
  }

  p {
    margin-left: 10px;
    color: #fff;
  }

  ul {
    margin-left: 10px;
    color: #fff;

    .key {
      font-weight: bold;
      color: #fff;
    }

    .value {
      word-wrap: break-word;
      color: #a5d6ff;
    }

    span {
      width: 100%;
    }

    ul {
      border: none;
      padding: 0;
      border-left: 1px solid #707070;
      border-radius: 0px;
      padding-left: 10px;
    }
  }

  p,
  ul {
    border: 1px solid #707070;
    border-radius: 5px;
    padding: 20px;
    background-color: #1c1b1b;
  }
`;
