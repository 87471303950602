import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from '@unform/web';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import * as Yup from 'yup';
import CustomInput from '../../components/input/CustomInput';
import MiniDrawer from '../../components/MiniDrawer';
import apiOld from '../../services/Api';
import { toast } from 'react-toastify';
import moment from 'moment';
import Calendar from '../../components/input/calendar/Calendar';

const useStyles = makeStyles(() => ({
  main: {
    background: '#FFF',
  },
  mainTitle: {
    background: '#FFF',
    borderTop: 'solid 2px #e7eaec',
    borderBottom: 'solid 1px #e7eaec',
    padding: '15px',
  },
  line: {
    backgorund: 'reds',
  },
  title: {
    fontSize: '15px',
  },
  mainForm: {
    padding: '10px',
  },
  form: {
    width: '100%',
  },
  input: {
    padding: '10px',
  },
  buttonContainer: {
    justifyContent: 'flex-end',
    padding: '10px',
  },
  submit: {
    padding: '10px 15px',
    fontSize: '17px',
    background: '#1ab394',
    border: 'none',
    color: '#fff',
    borderRadius: '5px',
  },
}));

export default function EditMyProfile() {
  const classes = useStyles();
  const formRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [content, setContent] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);

      const userData = (await apiOld.get('auth/users/'))?.data;

      setContent(userData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  async function handleSubmit(data) {
    try {
      setSubmitLoading(true);



      formRef.current.setErrors({});

      let schema;

      if (data.senha) {
        schema = Yup.object().shape({
          email: Yup.string().required().email(),
          senha: Yup.string()
            .required()
            .matches(
              /((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}))/,
              'Deve conter no mínimo 8 caracteres, sendo no mínimo uma letra maiúscula, uma letra minúscula e um número'
            ),
          confirmar: Yup.string()
            .required()
            .oneOf([Yup.ref('senha'), null], 'As senhas devem combinar'),
          função: Yup.string().required(),
          nome: Yup.string().required(),
        });
      } else {
        schema = Yup.object().shape({
          email: Yup.string().required().email(),
          função: Yup.string().required(),
          nome: Yup.string().required(),
        });
      }

      await schema.validate(data, {
        abortEarly: false,
      });

      const request = {
        email: data.email,
        name: data.nome,
        password: data.senha,
        occupation: data.função,
      };

      if (data.vacations) {
        request.vacations = { startDate: data.vacations['0'], endDate: data.vacations['1'] };
      }

      await apiOld.put('/auth/users/', request);

      toast.success('Dados alterados com sucesso!');
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};

        error.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
    setSubmitLoading(false);
  }

  return (
    <React.Fragment>
      <MiniDrawer
        main={
          <Grid container className={classes.main}>
            <Backdrop className={classes.backdrop} open={loading}>
              <CircularProgress color='inherit' />
            </Backdrop>
            {content && (
              <>
                <Grid container className={classes.mainTitle}>
                  <h2 className={classes.title}>Editar Usuário</h2>
                </Grid>
                <Grid container className={classes.mainForm}>
                  <Form
                    ref={formRef}
                    onSubmit={handleSubmit}
                    className={classes.form}
                  >
                    <Grid container>
                      <Grid item className={classes.input} xs={12}>
                        <CustomInput
                          name='nome'
                          label='Nome*'
                          defaultValue={content.name}
                        />
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <CustomInput
                          name='email'
                          label='E-mail*'
                          defaultValue={content.email}
                        />
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <CustomInput
                          name='função'
                          label='Título (Função, cargo, etc...)*'
                          defaultValue={
                            content.customFields
                              ? content.customFields.occupation
                                ? content.customFields.occupation
                                : ''
                              : ''
                          }
                        />
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <CustomInput
                          type='password'
                          name='senha'
                          label='Senha'
                        />
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <CustomInput
                          type='password'
                          name='confirmar'
                          label='Confirme sua senha'
                        />
                      </Grid>
                      <Grid item className={classes.input} xs={12}>
                        <Calendar
                          name="vacations"
                          selectRange={true}
                          label="Férias"
                          placeholder={"Selecione suas férias"}
                          defaultValue={content.vacations && !moment(content.vacations.endDate).isBefore(moment()) ? [new Date(content.vacations.startDate), new Date(content.vacations.endDate)] : null}
                        />
                      </Grid>
                      <Grid
                        container
                        className={classes.buttonContainer}
                        xs={12}
                      >
                        <button className={classes.submit} type='submit'>
                          {!submitLoading && 'Salvar'}
                          {submitLoading && (
                            <CircularProgress color='inherit' />
                          )}
                        </button>
                      </Grid>
                    </Grid>
                  </Form>
                </Grid>
              </>
            )}
          </Grid>
        }
      />
    </React.Fragment>
  );
}
