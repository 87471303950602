import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999;
  opacity: 1;
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  position: relative;
  padding: 30px;
  background: #f5f6fa;
  border-radius: 15px;
`;

export const ContainerInput = styled.div`
  width: 100%;
  margin-top: 15px;
  position: relative;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
`;

export const SubmitButton = styled.button`
  font-size: 20px;
  color: #fff;
  border-radius: 5px;
  border: none;
  padding: 10px 40px;
  background-color: #1ab394;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.09);
`;
export const ExitButton = styled(SubmitButton)`
  background-color: #a0a0a0;
  margin-left: 15px;
`;
