import apiOld from '../Api';

const demandProccessItemsApi = {
  createMessage: async function (demandId, data) {
    return new Promise((resolve, reject) => {
      apiOld
        .post(`/demands/${demandId}/items/message`, data)
        .then(resolve)
        .catch(reject);
    });
  },
  createPrivateMessage: async function (demandId, data) {
    return new Promise((resolve, reject) => {
      apiOld
        .post(`/demands/${demandId}/items/privateMessage`, data)
        .then(resolve)
        .catch(reject);
    });
  },
};

export default demandProccessItemsApi;
