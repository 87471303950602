import axios from 'axios';
import { toast } from 'react-toastify';
import { getToken } from './Auth';
import * as authActions from "../redux/actions/auth";
import redux from "../redux/store";
import history from '../history';

const { store } = redux;

const requestInterceptor = async config => {
  const token = getToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}

const responseInterceptor =  error => {
  console.error('Response object: ', error.response);
  if (error.response) {
    if (error.response.status === 500) {
      toast.error("Oops, algo inesperado aconteceu!", {
        toastId: error.response.status,
      });
    } else if (error.response.status === 413) {
      toast.error('Arquivo muito grande!', {
        toastId: error.response.status,
      });
    } else if (error.response.status === 401) {
      toast.error(error.response.data.message, {
        toastId: error.response.status,
      });
      store.dispatch(authActions.endSession())
      history.push('/signIn')
    }
    else if (error.response.data?.message) {
      console.error('Error CODE:', error.response.data?.code);
      toast.error(error.response.data.message, {
        toastId: error.response.status,
      });
    }
  } else {
    toast.error("Oops, algo inesperado aconteceu!");
  }

  return Promise.reject(error);
};

const apiOld = axios.create({
  baseURL: process.env.REACT_APP_API_OLD_URL,
});

apiOld.interceptors.request.use(requestInterceptor);

apiOld.interceptors.response.use(
  response => response,
  responseInterceptor
);

const newApi = axios.create({
  baseURL: process.env.REACT_APP_API_NEW_URL,
});


newApi.interceptors.request.use(requestInterceptor);

newApi.interceptors.response.use(
  response => response,
  responseInterceptor
  );
  
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });
  
  api.interceptors.request.use(requestInterceptor);
  
  api.interceptors.response.use(
    response => response,
    responseInterceptor
    );
  export { newApi, api };

export default apiOld;
