import styled from "styled-components";
import { Constants } from "../../../utils/Constants";

export const Container = styled.ul`
  flex: 0 0 320px;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 10px;
  white-space: nowrap;
  max-width: 320px;

  :not(:first-child) {
    border-left: 1px solid rgba(0, 0, 0, 0.05);
  }
  :last-child {
    padding-right: 250px;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const ColumnTitle = styled.h2`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 1.5em;
  font-weight: ${(props) => (props.authUser ? "bold" : "500")};
`;

export const EmptyColumn = styled.span`
  font-size: 15px;
  padding: 50px 0px;
  color: rgba(0, 0, 0, 0.4);
  align-self: center;
  display: inline-block;
  width: 100%;
  text-align: center;
`;

export const QuantityBadger = styled.span`
  margin-left: 5px;
  font-size: 1em;
  font-weight: bold;
  color: #fff;
  padding: 2px 5px;
  border-radius: 4px;
  background-color: ${(props) => {
    switch (props.status) {
      case Constants.DEMAND_STATUSES.OPEN.key:
        return Constants.DEMAND_STATUSES.OPEN.backgroundColor2;
      case Constants.DEMAND_STATUSES.IN_PROGRESS.key:
        return Constants.DEMAND_STATUSES.IN_PROGRESS.backgroundColor2;
      case Constants.DEMAND_STATUSES.PENDING.key:
        return Constants.DEMAND_STATUSES.PENDING.backgroundColor2;
      case Constants.DEMAND_STATUSES.FINISHED.key:
        return Constants.DEMAND_STATUSES.FINISHED.backgroundColor2;
      default:
        return "rgba(0, 0, 0, 0.3)";
    }
  }};
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  height: 100%;
  overflow-y: auto;
`;

export const FilterContainer = styled.fieldset`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border: none;
`;

export const Filter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
`;

export const FilterButton = styled.input`
  display: none;
`;

export const FilterIcon = styled.i`
  margin: 0px 3px;
  transform: ${(props) =>
    props.order !== ""
      ? props.order === "ASC"
        ? "translateY(4px)"
        : "translateY(-2px)"
      : ""};
`;

export const FilterButtonLabel = styled.label`
  cursor: pointer;
  color: #707070;
  font-weight: normal;
`;
