import apiOld from "../Api";
import redux from "../../redux/store";
import * as formsActions from "../../redux/actions/formsActions";

const { store } = redux;

const formsApi = {
  getAllForms: async function (additionalParams = {}) {
    return new Promise((resolve, reject) => {
      apiOld
        .get("/forms", {
          params: {
            ...additionalParams,
          },
        })
        .then((response) => {
          store.dispatch(formsActions.recieveForms(response.data.forms));
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
};

export default formsApi;
