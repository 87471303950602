import {
  setUser,
  setToken,
  removeToken,
  removeUser,
  getUser,
} from "../../services/Auth";
import { LOGIN, LOGOUT, UPDATE_SESSION } from "../actionsTypes/auth";
// import produce from "immer";

const AUTH_INITIAL_STATE = {
  isAuthenticated: !!getUser(),
  user: getUser() || null,
};

function auth(state = AUTH_INITIAL_STATE, action) {
  switch (action.type) {
    case LOGIN: {
      setUser(action.user);
      setToken(action.token);

      return { ...state, isAuthenticated: true, user: action.user };
    }

    case LOGOUT: {
      removeToken();
      removeUser();

      return { ...state, isAuthenticated: false, user: null };
    }

    case UPDATE_SESSION: {
      setUser(action.updatedUserData);

      return {
        ...state,
        isAuthenticated: true,
        user: action.updatedUserData,
      };
    }

    default:
      return state;
  }
}

export default auth;
