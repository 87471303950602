module.exports = {
  CUSTOMER_SUCCESS_IGNORE: [
    'Teste',
    'Churn Cliente',
    'Churn NDM',
    'Demanda Específica',
  ],
  SPECIFC_DEMAND: 'Demanda Específica',
  ONBOARDING: 'Onboarding',
  SUSPENDED_CONTRACT: 'Contrato Suspenso',
  PAYMENT_OVERDUE: 'Inadimplente',
  CHURN_CUSTOMERS_TAGS: ['Churn Cliente', 'Churn NDM'],
  ASAAS_CUSTOMER_IGNORE: ['Teste', 'Demanda Específica'],
  TEST_CUSTOMERS: 'Teste',
};