import React, { useState, useRef, useEffect } from 'react';
import CustomInput from '../../components/input/CustomInput';
import MiniDrawer from '../../components/MiniDrawer';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from '@unform/web';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomCheckbox from '../../components/input/CustomCheckbox';
import * as Yup from 'yup';
import apiOld from '../../services/Api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Constants } from '../../utils/Constants';
import CheckboxInput from '../../components/input/CheckboxInput';
import DeleteUserModal from '../../components/modals/users/deleteUserModal/DeleteUserModal';
import Calendar from '../../components/input/calendar/Calendar';

const useStyles = makeStyles(() => ({
  main: {
    background: '#FFF',
  },
  mainTitle: {
    background: '#FFF',
    borderTop: 'solid 2px #e7eaec',
    borderBottom: 'solid 1px #e7eaec',
    padding: '15px',
  },
  line: {
    backgorund: 'reds',
  },
  title: {
    fontSize: '15px',
  },
  mainForm: {
    padding: '10px',
  },
  form: {
    width: '100%',
  },
  input: {
    padding: '10px',
  },
  buttonContainer: {
    justifyContent: 'flex-end',
    padding: '10px',
  },
  submit: {
    padding: '10px 15px',
    fontSize: '17px',
    background: '#1ab394',
    border: 'none',
    color: '#fff',
    borderRadius: '5px',
    cursor: 'pointer',
  },
}));

export default function EditUser() {
  const { id: userId } = useParams();
  const classes = useStyles();
  const formRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [user, setUser] = useState(false);
  const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);

  function getUser(userId) {
    if (!user) {
      return apiOld.get(`/users/${userId}`).then(response => {
        setUser(response.data);
      });
    } else {
      return user
    }
  }

  useEffect(() => {
    getUser(userId)
  }, [userId]);

  function addVacation(e) {
    user.vacations.push({ startDate: new Date(), endDate: new Date() })
    setUser({ ...user })
  }

  async function deleteVacation(vacationIndex) {
    if (window.confirm('Tem certeza que deseja deletar um registro de férias ?')) {
      const vacation = user.vacations[vacationIndex]
      if (!vacation.id) {
        user.vacations.splice(vacationIndex, 1)
        setUser({ ...user })
        window.location.reload(false)
        return
      }
      return apiOld.delete(`/vacation/${vacation.id}`).then(() => {
        user.vacations.splice(vacationIndex, 1)
        setUser({ ...user })
        window.location.reload(false)
      }).catch(err => alert(err));
    }
  }

  async function handleSubmit(data) {
    setSubmitLoading(true);
    let vacationsTreated = [];
    user.vacations?.forEach((vacation,index) => {
      vacationsTreated.push({
        ...vacation,
        startDate: data.vacations[index][0],
        endDate: data.vacations[index][1],
      })
    })

    try {
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        email: Yup.string().required().email(),
        phone: Yup.string(),
        occupation: Yup.string(),
        name: Yup.string().required(),
        customerAdmin: Yup.boolean().label('Administrador'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const request = {
        email: data.email,
        phone: data.phone,
        name: data.name,
        roles: (data.rolesTemplate || [])
          .concat(data.rolesCustomer)
          .concat(data.rolesUser)
          .concat(data.rolesDocument)
          .concat(data.rolesConfig)
          .concat(data.rolesForm)
          .concat(data.rolesDemand)
          .concat(data.rolesDemandType),
        occupation: data.occupation,
        customerAdmin: data.customerAdmin,
        customerSuperAdmin: data.customerSuperAdmin,
        vacations: vacationsTreated
      };

      await apiOld.put(`/users/${userId}`, request);

      toast.success('Dados alterados com sucesso!');
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};

        error.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }finally{
      setSubmitLoading(false);
      setTimeout(() => window.location.reload(false),2000)
    }
    

  }

  const defaultChecked = (roles, item) => {
    if (roles == null) return false;
    else return roles.includes(item);
  };

  const formatRoles = key => {
    return [
      {
        id: `CREATE_${key}`,
        value: `CREATE_${key}`,
        label: 'Criar',
        defaultChecked: defaultChecked(user.roles, `CREATE_${key}`),
      },
      {
        id: `UPDATE_${key}`,
        value: `UPDATE_${key}`,
        label: 'Editar',
        defaultChecked: defaultChecked(user.roles, `UPDATE_${key}`),
      },
      {
        id: `DELETE_${key}`,
        value: `DELETE_${key}`,
        label: 'Deletar',
        defaultChecked: defaultChecked(user.roles, `DELETE_${key}`),
      },
      {
        id: `GET_${key}`,
        value: `GET_${key}S`,
        label: 'Buscar',
        defaultChecked: defaultChecked(user.roles, `GET_${key}S`),
      },
    ];
  };

  const rolesUser = formatRoles('USER');
  const rolesCustomer = formatRoles('CUSTOMER');
  const rolesTemplate = formatRoles('TEMPLATE');
  const rolesDocument = formatRoles('DOCUMENT');
  const rolesForm = formatRoles('FORM');
  const rolesDemand = formatRoles('DEMAND');
  const rolesDemandType = formatRoles('DEMAND_TYPE');
  const rolesConfig = formatRoles('CONFIG');

  return (
    <React.Fragment>
      <MiniDrawer currentExpaded='users'>
        <Grid container className={classes.main}>
          {user && (
            <>
              <Grid container className={classes.mainTitle}>
                <Grid container justify='space-between'>
                  <h2 className={classes.title}>Editar Usuário</h2>

                  <button
                    className={classes.submit}
                    type='button'
                    style={{
                      backgroundColor: '#f44336',
                    }}
                    onClick={() => setDeleteUserModalOpen(true)}
                  >
                    Excluir usuário
                  </button>
                </Grid>
              </Grid>
              <Grid container className={classes.mainForm}>
                <Form
                  ref={formRef}
                  onSubmit={handleSubmit}
                  className={classes.form}
                >
                  <Grid container>
                    <Grid item className={classes.input} xs={6}>
                      <CustomInput
                        name='name'
                        label='Nome*'
                        defaultValue={user.name}
                      />
                    </Grid>
                    <Grid item className={classes.input} xs={6}>
                      <CustomInput
                        name='phone'
                        label='Telefone'
                        defaultValue={user.phone}
                      />
                    </Grid>
                    <Grid item className={classes.input} xs={6}>
                      <CustomInput
                        name='email'
                        label='E-mail*'
                        defaultValue={user.email}
                      />
                    </Grid>
                    <Grid item className={classes.input} xs={6}>
                      <CustomInput
                        name='occupation'
                        label='Título (Função, cargo, etc...)'
                        defaultValue={
                          user.customFields
                            ? user.customFields.occupation
                              ? user.customFields.occupation
                              : ''
                            : ''
                        }
                      />
                    </Grid>

                    {user.type === Constants.USER_TYPES.BACKOFFICE ? (
                      <Grid container className={classes.mainTitle}>
                        <Grid container justify='space-between'>
                          <h2 className={classes.title}>Férias</h2>

                          <button
                            className={classes.submit}
                            type='button'
                            onClick={addVacation}
                          >
                            Adicionar férias
                          </button>
                        </Grid>
                        {user?.vacations?.map((vacation, index) => {
                          return (
                            <>
                              <Grid item className={classes.input} xs={5}>
                                <Calendar
                                  name={`vacations[${index}]`}
                                  selectRange={true}
                                  label={`${index + 1}º Férias`}
                                  placeholder={"Selecione suas férias"}
                                  defaultValue={[new Date(vacation.startDate), new Date(vacation.endDate)]}
                                />
                              </Grid>
                              <Grid item className={classes.input} style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }} xs={1}>
                                <button
                                  className={classes.submit}
                                  type='button'
                                  style={{
                                    backgroundColor: '#f44336',
                                  }}
                                  onClick={() => deleteVacation(index)}
                                >
                                  Excluir
                                </button>
                              </Grid>
                            </>
                          )
                        })}

                      </Grid>

                    ) : ''}

                    {user.type === Constants.USER_TYPES.BACKOFFICE ? (
                      <>
                        <Grid item className={classes.input} xs={12}>
                          <h3>Permissões:</h3>
                        </Grid>
                        <Grid item className={classes.input} xs={12}>
                          <h4>Usuários</h4>
                          <CustomCheckbox
                            name='rolesUser'
                            options={rolesUser}
                          />
                        </Grid>
                        <Grid item className={classes.input} xs={12}>
                          <h4>Clientes</h4>
                          <CustomCheckbox
                            name='rolesCustomer'
                            options={rolesCustomer}
                          />
                        </Grid>
                        <Grid item className={classes.input} xs={12}>
                          <h4>Templates</h4>
                          <CustomCheckbox
                            name='rolesTemplate'
                            options={rolesTemplate}
                          />
                        </Grid>
                        <Grid item className={classes.input} xs={12}>
                          <h4>Documentos</h4>
                          <CustomCheckbox
                            name='rolesDocument'
                            options={rolesDocument}
                          />
                        </Grid>
                        <Grid item className={classes.input} xs={12}>
                          <h4>Formulários</h4>
                          <CustomCheckbox
                            name='rolesForm'
                            options={rolesForm}
                          />
                        </Grid>
                        <Grid item className={classes.input} xs={12}>
                          <h4>Demandas</h4>
                          <CustomCheckbox
                            name='rolesDemand'
                            options={rolesDemand}
                          />
                        </Grid>
                        <Grid item className={classes.input} xs={12}>
                          <h4>Tipos de Demanda</h4>
                          <CustomCheckbox
                            name='rolesDemandType'
                            options={rolesDemandType}
                          />
                        </Grid>
                        <Grid item className={classes.input} xs={12}>
                          <h4>Configurações</h4>
                          <CustomCheckbox
                            name='rolesConfig'
                            options={rolesConfig}
                          />
                        </Grid>
                      </>
                    ) : (
                      <>
                        <CheckboxInput
                          name='customerAdmin'
                          label='Administrador'
                          defaultValue={user.customerAdmin}
                        />
                        <CheckboxInput
                          name='customerSuperAdmin'
                          label='Super Administrador'
                          defaultValue={user.customerSuperAdmin}
                        />
                      </>
                    )}

                    <Grid container className={classes.buttonContainer} xs={12}>
                      <button className={classes.submit} type='submit'>
                        {submitLoading ? (
                          <CircularProgress color='inherit' />
                        ) : (
                          'Salvar'
                        )}
                      </button>
                    </Grid>
                  </Grid>
                </Form>
              </Grid>
            </>
          )}
        </Grid>

        <DeleteUserModal
          open={deleteUserModalOpen}
          userToDelete={user}
          handleClose={() => setDeleteUserModalOpen(false)}
        />
      </MiniDrawer>
    </React.Fragment>
  );
}
