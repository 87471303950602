import * as actionTypes from "../actionsTypes/auth";

export const login = (user, token) => {
  return {
    type: actionTypes.LOGIN,
    user,
    token,
  };
};

export const updateSession = (updatedUserData) => {
  return {
    type: actionTypes.UPDATE_SESSION,
    updatedUserData,
  };
};

export const endSession = () => {
  return {
    type: actionTypes.LOGOUT
  }
}