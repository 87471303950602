import React, { useState, useEffect } from 'react';
import {
  Container,
  ColumnContainer,
} from '../../components/demandsBoard/DemandsBoard.styles';
import Column from '../../components/demandsBoard/column/Column';
import ColumnStats from '../../components/demandsBoard/columnStats/ColumnStats';
import MiniDrawer from '../../components/MiniDrawer';
import { useParams } from 'react-router';
import { Constants } from '../../utils/Constants';
import SelectSearch from 'react-select-search';
import CustomerJourneyService from '../../services/journeys/customerJourneyService';
import { getJourneyCompletePercentage } from '../../utils';
import { useQuery } from '../../routes';
import { Link } from 'react-router-dom';
import { PageContainer } from '../../components/Pages.styles';

const customerJourneyService = new CustomerJourneyService();

export default function CustomerJourneysBoard() {
  const query = useQuery();
  const initialJourneyId = query.get('customerJourneyId');
  const businessId = query.get('businessId');
  const customerId = query.get('customerId');

  const [state, setState] = useState({
    customerJourneys: [],
    demands: [],
    selectedCustomerJourneyId: initialJourneyId,
  });

  const _fetchCustomerJourneys = async customerId => {
    try {
      const customerJourneys = (
        await customerJourneyService.listAllCustomerJourneys({
          customerId,
          withJourneyType: true,
          withDemands: true,
        })
      )?.data.customerJourneys;


      const businessOfDemands = customerJourneys
        .map((customerJourney) => {
          const demands = customerJourney.demands.filter(demand => {
            return demand.businessId === businessId;
          });

          if (demands.length > 0) {
            return {
              ...customerJourney,
              demands: demands,
            }
          }
          return null;
        });
      
      const journeyBusiness = businessId ? businessOfDemands
        .filter(journey => journey) : customerJourneys;

      setState(prevState => ({
        ...prevState,
        customerJourneys: journeyBusiness,
        demands:
          journeyBusiness.find(
            customerJourney => customerJourney?.id === +initialJourneyId
          )?.demands || [],
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const _handleCustomerJourneyChange = customerJourneyId => {
    // select demands from selected customer journey
    setState(prevState => ({
      ...prevState,
      demands: prevState.customerJourneys.find(
        customerJourney => customerJourney.id === customerJourneyId
      ).demands,
      selectedCustomerJourneyId: customerJourneyId,
    }));
  };

  useEffect(() => {
    if (customerId) _fetchCustomerJourneys(customerId);
  }, [customerId, businessId]);

  const formatColumns = demands => {
    return [
      {
        title: 'Aberta',
        columnStatus: Constants.DEMAND_STATUSES.OPEN.key,
        cards: demands.filter(
          demand => demand.statusKey === Constants.DEMAND_STATUSES.OPEN.key
        ),
      },
      {
        title: 'Pendentes',
        columnStatus: Constants.DEMAND_STATUSES.PENDING.key,
        cards: demands.filter(
          demand => demand.statusKey === Constants.DEMAND_STATUSES.PENDING.key
        ),
      },
      {
        title: 'Em Andamento',
        columnStatus: Constants.DEMAND_STATUSES.IN_PROGRESS.key,
        cards: demands.filter(
          demand =>
            demand.statusKey === Constants.DEMAND_STATUSES.IN_PROGRESS.key
        ),
      },
      {
        title: 'Finalizadas',
        columnStatus: Constants.DEMAND_STATUSES.FINISHED.key,
        cards: demands.filter(
          demand => demand.statusKey === Constants.DEMAND_STATUSES.FINISHED.key
        ),
      },
      {
        title: 'Bloqueadas',
        columnStatus: Constants.DEMAND_STATUSES.BLOCKED.key,
        cards: demands.filter(
          demand => demand.statusKey === Constants.DEMAND_STATUSES.BLOCKED.key
        ),
      },
    ];
  };
  return (
    <React.Fragment>
      <MiniDrawer
        currentExpaded='customers'
        main={
          <PageContainer>
            <Container>
              <h2>Jornadas do cliente</h2>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '520px',
                  padding: '10px 0px',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <SelectSearch
                  style={{
                    backgroundColor: 'transparent',
                  }}
                  placeholder='Selecione uma jornada'
                  onChange={_handleCustomerJourneyChange}
                  value={+initialJourneyId}
                  options={state.customerJourneys.map(customerJourney => ({
                    value: customerJourney.id,
                    name: customerJourney.journeyType.title,
                  }))}
                />
                {state.selectedCustomerJourneyId && (
                  <>
                    <p>
                      {getJourneyCompletePercentage(state.demands)}% Completa
                    </p>

                    <a
                      className='buttonLink'
                      href={`${process.env.REACT_APP_NEW_BACKOFFICE_URL}#/customer/${customerId}/journey/${state.selectedCustomerJourneyId}/edit`}
                    >
                      Editar ordem das demandas
                    </a>
                  </>
                )}
              </div>

              <ColumnContainer
                style={
                  {
                    // backgroundColor: '#eee',
                  }
                }
              >
                {state.demands.length ? (
                  <>
                    {formatColumns(state.demands).map((column, idx) => (
                      <Column
                        key={column.backofficeUserId || column.columnStatus}
                        index={idx}
                        columnTitle={column.title}
                        backofficeUserId={column.backofficeUserId}
                        columnStatus={column.columnStatus}
                        demands={column.cards}
                        totalDemands={column.cards.length}
                        isCustomerDemandsBoard={true}
                      />
                    ))}
                    <ColumnStats
                      totalDemands={state.demands.reduce(
                        (prev, current, idx) => {
                          // soma o total de vezes que todas demadas foram finalizadas

                          return prev + +current.finishedCount;
                        },
                        0
                      )}
                      uniqueDemands={state.demands?.length || 0}
                    />
                  </>
                ) : null}
              </ColumnContainer>
            </Container>
          </PageContainer>
        }
      />
    </React.Fragment>
  );
}
