import { makeStyles } from '@material-ui/core';
import { useField } from '@unform/core';
import React, { useEffect, useRef } from 'react';

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
  },
  error: {
    color: 'red',
    opacity: '70%',
    textAlign: 'justify',
    display: 'block',
  },
  input: {
    borderRadius: '5px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #e5e6e7',
    fontFamily: 'open sans, Helvetica Neue, Helvetica, Arial, sans-serif',
    height: '36px',
    marginTop: '2px',
    marginBottom: '6px',
    padding: '0px 10px',
    '&:focus': {
      border: '1px solid #18a689;',
    },
    width: '100%',
  },
  label: {
    color: '#A8A8A8',
    fontSize: '12px',
  },
}));

export default function CustomInput({ name, label, ...rest }) {
  const classes = useStyles();
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <div className={classes.main}>
      <label className={classes.label} htmlFor='input'>
        {label}
      </label>

      <input
        maxLength='255'
        className={classes.input}
        ref={inputRef}
        defaultValue={defaultValue}
        {...rest}
      />

      {error && <span className={classes.error}>{error}</span>}
    </div>
  );
}
