import customerJourneyApi from '../apis/customerJourneyApi';
import * as Yup from 'yup';

export default class CustomerJourneyService {
  async createCustomerJourney(data) {
    return await customerJourneyApi.createCustomerJourney(data);
  }

  async updateCustomerJourney(customerJourneyId, data) {
    return await customerJourneyApi.updateCustomerJourney(
      customerJourneyId,
      data
    );
  }

  async getCustomerJourneyById(customerJourneyId) {
    return (await customerJourneyApi.getCustomerJourneyById(customerJourneyId))
      .data;
  }

  async listAllCustomerJourneys(queryParams) {
    return await customerJourneyApi.listAllCustomerJourneys(queryParams);
  }

  async addDemandToCustomerJourney(customerJourneyId, demandId) {
    const schema = Yup.object().shape({
      customerJourneyId: Yup.string()
        .required()
        .nullable(true)
        .label('Jornada'),
      demandId: Yup.string().required().nullable(true).label('Demanda'),
    });

    await schema.validate(
      {
        customerJourneyId,
        demandId,
      },
      {
        abortEarly: false,
      }
    );

    return await customerJourneyApi.addDemandToCustomerJourney(
      customerJourneyId,
      {
        demandId,
      }
    );
  }
}
