import apiOld from '../Api';

const metricsApi = {
  getInfos: async function (additionalParams = {}) {
    return apiOld.get(`/dashboard`, {
      params: {
        ...additionalParams,
      },
    });
  },

  getEngagementDashMetrics: async function (additionalParams = {}) {
    return apiOld.get(`/metrics/engagement`, {
      params: {
        ...additionalParams,
      },
    });
  },

  getContactTimeDashMetrics: async function (additionalParams = {}) {
    return apiOld.get('/metrics/contact-time', {
      params: {
        ...additionalParams,
      },
    });
  },

  getRenewalDashMetrics: async function (additionalParams = {}) {
    return apiOld.get('/metrics/renewal', {
      params: {
        ...additionalParams,
      },
    });
  },
  getSuspendedDashMetrics: async function (additionalParams = {}) {
    return apiOld.get('/metrics/suspended', {
      params: {
        ...additionalParams,
      },
    });
  },

  getChurnDashMetrics: async function (additionalParams = {}) {
    return apiOld.get('/metrics/churn', {
      params: {
        ...additionalParams,
      },
    });
  },

  getOverdueDashMetrics: async function (additionalParams = {}) {
    return apiOld.get('/metrics/overdue-customers', {
      params: {
        ...additionalParams,
      },
    });
  },

  getRevenueDashMetrics: async function (additionalParams = {}) {
    return apiOld.get('/metrics/revenues', {
      params: {
        ...additionalParams,
      },
    });
  },

  getRevenueSheetMetrics: async function (additionalParams = {}) {
    return apiOld.get('/metrics/revenues/sheets', {
      params: {
        ...additionalParams,
      },
    });
  },

  getGaMetrics: async function (additionalParams = {}) {
    return apiOld.get('/metrics/ga', {
      params: {
        ...additionalParams,
      },
    });
  },

  getYtMetrics: async function (additionalParams = {}) {
    return apiOld.get('/metrics/yt', {
      params: {
        ...additionalParams,
      },
    });
  },
};

export default metricsApi;
