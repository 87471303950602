import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  border-radius: 15px;
  padding: 30px 30px;
  max-width: 50vw;
  width: fit-content;
`;

export const Title = styled.h1`
  font-size: 20px;
  font-weight: bold;
  color: #232323;
  text-align: center;
`;

export const Description = styled.p`
  font-size: 14px;
  font-weight: normal;
  color: #707070;
  text-align: center;
  margin-top: 30px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  align-self: flex-end;
`;

export const Button = styled.button`
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 15px;
  margin: 0 0.5rem 1rem 0.5rem;
  color: #fff;
  background-color: ${({ bgColor }) => bgColor || '#3366BB'};
`;
