import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import xlsx from 'json-as-xlsx';
import ModalAlert from './ModalAlert';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomInput from './input/CustomInput';
import CustomSelectWithSearch from './input/customSelectorWithSearch/CustomSelectWithSearch';
import apiOld from '../services/Api';
import * as Yup from 'yup';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { Form } from '@unform/web';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useQuery } from '../routes';
import { paramsToObject } from '../utils';
import history from '../history';

function descendingComparator(obj1, obj2, orderBy, order) {
  let result;
  const a = obj1[orderBy];
  const b = obj2[orderBy];

  if (['createdAt', 'updatedAt', 'saleDate'].includes(orderBy)) {
    // Order by date
    const parsedA = moment(a, 'DD-MM-YYYY');
    const parsedB = moment(b, 'DD-MM-YYYY');

    if (parsedA.isBefore(parsedB)) {
      result = 1;
    } else if (parsedA.isAfter(parsedB)) {
      result = -1;
    } else {
      result = 0;
    }

    return order === 'desc' ? result : -result;
  } else if (b < a) {
    result = -1;
  } else if (b > a) {
    result = 1;
  } else {
    return 0;
  }

  return order === 'desc' ? result : -result;
}

function stableSort(array, orderBy, order,serverSort) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  if(!serverSort){
    stabilizedThis.sort((a, b) => {
      const orderResult = descendingComparator(a[0], b[0], orderBy, order);
  
      if (orderResult !== 0) return orderResult;
  
      return a[1] - b[1];
    });

  }
  return stabilizedThis.map(el => el[0]);
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, headCells, hasActions } =
    props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding='checkbox'>
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align='left'
            padding={headCell.disablePadding ? 'default' : 'none'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {hasActions && (
          <TableCell key='actions' align='right' padding='default'>
            Ações
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight: {
    color: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
  },
  title: {
    flex: '1 1 100%',
  },
  reset: {
    fontSize: '15px',
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
}));

const EnhancedTableToolbar = ({ numSelected, title, reset }) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color='inherit'
          variant='subtitle1'
          component='div'
        >
          {numSelected} selecionado
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant='h6'
          id='tableTitle'
          component='p'
        >
          {title}
        </Typography>
      )}

      {numSelected === 0 && (
        <Typography
          variant='h6'
          className={classes.reset}
          onClick={() => reset()}
        >
          Resetar
          <IconButton aria-label='filter list'>
            <RotateLeftIcon />
          </IconButton>
        </Typography>
      )}

      {
        numSelected > 0 && (
          <Tooltip title='Delete'>
            <IconButton aria-label='delete'>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) /* : (
        <Tooltip
          title='Filtrar lista'
          // onClick={() => setFilterVisibility(!filterVisibility)}
        >
          <IconButton aria-label='filter list'>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      ) */
      }
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableCell: {
    cursor: 'pointer',
    textDecoration: 'none',
  },
  action: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: '#000',
    border: 'none',
    background: 'transparent',
  },
  button: {
    padding: '10px 15px',
    fontSize: '17px',
    background: '#1ab394',
    border: 'none',
    color: '#fff',
    borderRadius: '5px',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 3px',
  },
  buttonItem: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: 'auto',
    '& a': {
      display: 'flex',
      alignItems: 'center',
    },
    '& a, button': {
      marginRight: '15px',
      marginLeft: '15px',
    },
  },
  actionContainer: {
    justifyContent: 'flex-end',
  },
  form: {
    width: '100%',
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '15px 15px 0px 15px',
    minHeight: '116px',
  },
}));

export default function CustomTable({
  title,
  headCells,
  handleRows,
  handleClickItem,
  route,
  downloadRoute,
  downloadColumns,
  rolePrefix,
  ignorePrefix = [],
  searchOptions,
  handleEdit,
  customParams = {},
  Icon,
  hasActions = true,
  canDelete = true,
  marcas = false,
  serverSort = false
}) {
  const classes = useStyles();
  const roles = useSelector(state => state.auth.user)?.roles;
  const ref = React.createRef();
  const searchFormRef = useRef(null);
  const urlQueryParams = paramsToObject(useQuery());
  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(false);
  const [tableInfos, setTableInfos] = useState(false);
  const [rows, setRows] = useState(null);
  const [filterVisibility, setFilterVisibility] = useState(false);
  const [openedDeleteModal, setOpenedDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [state, setState] = useState({
    queryParams: {
      orderBy: headCells[0]?.id || '',
      order: 'asc',
      page: 0,
      limit: 10,
      ...urlQueryParams,
    },
  });
  const [selectedSearchOption, setSelectedSearchOption] = useState(
    state.queryParams.where && searchOptions
      ? searchOptions.find(option => option.value === state.queryParams.where)
      : searchOptions
        ? searchOptions?.[0]
        : undefined
  );

  useEffect(() => {
    history.replace({
      search: new URLSearchParams(state.queryParams).toString(),
    });
  }, [state.queryParams]);

  useEffect(() => {
    if (state.queryParams.where)
      setSelectedSearchOption(
        searchOptions.find(option => option.value === state.queryParams.where)
      );
  }, [searchOptions, state.queryParams.where]);

  const handleLoadTable = useCallback(async () => {
    try {
      const params = {
        ...state.queryParams,
        ...customParams,
      };

      if (!marcas) {
        const response = await apiOld.get(`/${route}`, {
          params,
        });

        const parsedResponse = await handleRows(response.data);

        setRows(parsedResponse.rows);

        setTableInfos({
          total: parsedResponse.total,
        });
      } else {
        const response = await apiOld.get(`/${route}`);

        const parsedResponse = await handleRows(response.data);

        setRows(parsedResponse.rows);

        setTableInfos({
          total: parsedResponse.total,
        });
      }


    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};

        error.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });

        searchFormRef.current.setErrors(validationErrors);
      }

      console.error(error);
    }
  }, [state.queryParams, route, handleRows]);

  const handleDownload = useCallback(async () => {
    try {
      const response = await apiOld.get(`/${route}/${downloadRoute}`);

      const data = [
        {
          sheet: title,
          columns: downloadColumns,
          content: response.data,
        },
      ];
      const settings = {
        fileName: title,
      };
      xlsx(data, settings);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};

        error.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });

        return searchFormRef.current.setErrors(validationErrors);
      }

      console.error(error);
    }
  }, [route, downloadRoute, title, downloadColumns]);

  useEffect(() => {
    // handleLoadTable sendo chamada 2 vezes no inicio, n sei o pq
    handleLoadTable();
  }, [handleLoadTable]);

  const _resetQueryParams = () => {
    // searchFormRef.current?.reset();
    if (state.queryParams.where)
      setTimeout(() =>
        searchFormRef?.current?.clearField(state.queryParams.where)
      );

    setState(prevState => ({
      ...prevState,
      queryParams: {
        orderBy: prevState.queryParams.orderBy,
        order: prevState.queryParams.order,
        page: 0,
        limit: prevState.queryParams.limit,
      },
    }));
  };

  const handleSearchFormSubmit = async data => {
    try {
      const schemaObjs = {};

      searchFormRef.current.setErrors({});

      Object.keys(data).map(
        key =>
        (schemaObjs[key] = Yup.string()
          .nullable()
          .label('Este campo')
          .required())
      );

      const schema = Yup.object().shape(schemaObjs);

      const search = await schema.validate(data, {
        abortEarly: false,
      });

      setState(prevState => ({
        ...prevState,
        queryParams: {
          orderBy: prevState.queryParams.orderBy,
          order: prevState.queryParams.order,
          page: 0,
          limit: prevState.queryParams.limit,
          ...search,
        },
      }));

      handleLoadTable(data);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};

        error.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });

        searchFormRef.current.setErrors(validationErrors);
      }

      console.error(error);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc =
      state.queryParams.orderBy === property &&
      state.queryParams.order === 'asc';

    setState({
      ...state,
      queryParams: {
        ...state.queryParams,
        order: isAsc ? 'desc' : 'asc',
        orderBy: property,
      },
    });
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id);

      setSelected(newSelecteds);

      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, page) => {
    setState({
      ...state,
      queryParams: {
        ...state.queryParams,
        page,
      },
    });
  };

  const handleChangeRowsPerPage = event => {
    setState({
      ...state,
      queryParams: {
        ...state.queryParams,
        limit: event.target.value,
      },
    });
  };

  const handleChangeDense = event => {
    setDense(event.target.checked);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  async function deleteItem(id) {
    try {
      await apiOld.delete(`/${route}/${id}`);
      if(!marcas) setRows(rows.filter(row => row.id !== id));
      else setRows(rows.filter(row => row.demandId !== id));

      toast.success('Excluído com sucesso!');
    } catch (error) { }
  }

  async function handleDelete(id) {
    setDeleteId(id);
    setOpenedDeleteModal(true);
  }

  function handleDeleteModalClose() {
    setOpenedDeleteModal(false);
  }

  return (
    <div className={classes.root}>
      <ModalAlert
        open={openedDeleteModal}
        handleSuccess={deleteItem}
        successOptions={deleteId}
        handleClose={handleDeleteModalClose}
        order={state.queryParams.order}
      />

      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          title={title}
          setFilterVisibility={setFilterVisibility}
          filterVisibility={filterVisibility}
          reset={_resetQueryParams}
        />
        {searchOptions && (
          <Grid container>
            <Form
              ref={searchFormRef}
              className={classes.form}
              onSubmit={handleSearchFormSubmit}
            >
              <Grid className={classes.searchContainer} container>
                <Grid item className={classes.input} xs={2}>
                  {searchOptions && (
                    <CustomSelectWithSearch
                      placeholder='tipo'
                      name='where'
                      label='Tipo*'
                      options={searchOptions}
                      onChange={e => {
                        setSelectedSearchOption(
                          searchOptions.find(option => option.value === e)
                        );

                        // Reset o input para evitar pegar o valor antigo de outro
                        setTimeout(() => searchFormRef.current.clearField(e));
                      }}
                      search={false}
                      defaultValue={
                        state.queryParams.where || searchOptions?.[0]?.value
                      }
                    />
                  )}
                </Grid>

                {selectedSearchOption && (
                  <Grid
                    item
                    className={classes.input}
                    style={{ marginLeft: '20px' }}
                    xs={6}
                  >
                    {selectedSearchOption.customElement ? (
                      selectedSearchOption.customElement()
                    ) : selectedSearchOption.customInput ? (
                      <CustomSelectWithSearch
                        placeholder={selectedSearchOption.name}
                        name={selectedSearchOption.value}
                        label={selectedSearchOption.name + '*'}
                        options={selectedSearchOption.customInput}
                        search={true}
                        defaultValue={
                          state.queryParams.where
                            ? state.queryParams[state.queryParams.where]
                            : undefined
                        }
                      />
                    ) : (
                      <CustomInput
                        name={selectedSearchOption.value}
                        label={selectedSearchOption.name}
                        defaultValue={
                          state.queryParams.where
                            ? state.queryParams[state.queryParams.where]
                            : undefined
                        }
                      />
                    )}
                  </Grid>
                )}

                <Grid
                  item
                  xs={1}
                  style={{
                    marginLeft: '15px',
                    alignSelf: 'center',
                    marginBottom: '25px',
                  }}
                >
                  <button type='submit' className={classes.button}>
                    Filtrar
                  </button>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        )}
        <TableContainer>
          <Table
            id='table-to-xls'
            ref={ref}
            className={classes.table}
            aria-labelledby='tableTitle'
            size={dense ? 'small' : 'medium'}
            aria-label='enhanced table'
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={state.queryParams.order}
              orderBy={state.queryParams.orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={tableInfos.total ? tableInfos.total : 0}
              headCells={headCells}
              hasActions={hasActions}
            />
            {rows ? (
              <TableBody>
                {stableSort(
                  rows,
                  state.queryParams.orderBy,
                  state.queryParams.order,
                  serverSort
                ).map((row, index) => {
                  const isItemSelected = !marcas ? isSelected(row.id) : isSelected(row.demandId);
                  return (
                    <TableRow
                      role='checkbox'
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                    >
                      <TableCell>
                        {row.showIcon && Icon}

                      </TableCell>
                      {headCells.map(head => (
                        <TableCell
                          className={classes.tableCell}
                          onClick={() => {
                            if(!marcas) handleClickItem(row.id || row)
                            else handleClickItem(row.demandId || row)
                          }}
                          key={head.id}
                          padding='none'
                          align='left'
                          title={row[head.id]}
                        >
                          {row[head.id]}
                        </TableCell>
                      ))}
                      {hasActions && (
                        <TableCell align='right'>
                          <Grid
                            container
                            className={classes.actionContainer}
                            spacing={2}
                          >
                            {roles ? (
                              roles.includes(`UPDATE_${rolePrefix}`) ||
                                ignorePrefix.includes('EDIT') ? (
                                handleEdit ? (
                                  <button
                                    onClick={() => {
                                      if (!marcas) handleEdit(row.id)
                                      else handleEdit(row.demandId)
                                    }}
                                    className={classes.action}
                                  >
                                    <i className='far fa-edit' />
                                  </button>
                                ) : (
                                  <Link
                                    to={`/${route}/${!marcas ? row.id : row.demandId}/edit`}
                                    className={classes.action}
                                  >
                                    <i className='far fa-edit' />
                                  </Link>
                                )
                              ) : null
                            ) : handleEdit ? (
                              <button
                                onClick={() => {
                                  if (!marcas) handleEdit(row.id)
                                  else handleEdit(row.demandId)
                                }}
                                className={classes.action}
                              >
                                <i className='far fa-edit' />
                              </button>
                            ) : (
                              <Link
                                to={`/${route}/${!marcas ? row.id : row.demandId}/edit`}
                                className={classes.action}
                              >
                                <i className='far fa-edit' />
                              </Link>
                            )}

                            <Grid item>
                              {(ignorePrefix.includes('DELETE')
                                ? true
                                : roles.includes(`INACTIVATE_${rolePrefix}`) ||
                                roles.includes(`DELETE_${rolePrefix}`)) &&
                                canDelete && (
                                  <button
                                    className={classes.action}
                                    onClick={() => {
                                      if(!marcas) handleDelete(row.id)
                                      else handleDelete(row.demandId)
                                    }}
                                  >
                                    <i className='far fa-trash-alt'></i>
                                  </button>
                                )}
                            </Grid>
                          </Grid>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell>
                    <CircularProgress color='inherit' />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {tableInfos && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 1000]}
            component='div'
            count={tableInfos.total ? tableInfos.total : 0}
            rowsPerPage={parseInt(state.queryParams.limit)}
            labelRowsPerPage='Linhas por página'
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count} item(s)`
            }
            page={parseInt(state.queryParams.page)}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </Paper>

      <Grid className={classes.buttonContainer}>
        <Grid item xs={6}>
          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label='Tabela densa'
          />
        </Grid>

        <Grid item xs={6} className={classes.buttonItem}>
          {hasActions && (
            <Link to={`/new/${route}`} className={classes.button}>
              Cadastrar
            </Link>
          )}

          <ReactHTMLTableToExcel
            id='test-table-xls-button'
            className={classes.button}
            table='table-to-xls'
            filename='users'
            sheet='tablexls'
            buttonText='XLS (Página atual)'
          />
          {downloadRoute && (
            <button onClick={handleDownload} className={classes.button}>
              XLS
            </button>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
