import React from 'react';
import Grid from '@material-ui/core/Grid';
import MiniDrawer from '../../components/MiniDrawer';
import { PageContainer } from '../../components/Pages.styles';
import history from '../../history';
import CustomTable from '../../components/Table';
import { formatDate } from '../../utils';

const headCells = [
  { id: 'journeyTypeId', numeric: false, label: 'Jornada' },
  { id: 'customerId', numeric: false, label: 'Cliente' },
  { id: 'createdAt', numeric: false, label: 'Data de Criação' },
];

export default function ListCustomerJourney() {
  const handleRows = data => {
    return {
      rows: data.customerJourneys.map(customerJourneys => ({
        ...customerJourneys,
        id: customerJourneys.customer.id,
        journeyTypeId: customerJourneys.journeyType.title,
        customerId: customerJourneys.customer.name,
        createdAt: formatDate(customerJourneys.createdAt),
      })),
      total: data.total,
    };
  };

  const handleClickItem = customerId => {
    history.push(`/customers/${customerId}/journeys/edit`);
  };

  return (
    <React.Fragment>
      <MiniDrawer currentExpaded='journeys'>
        <PageContainer>
          <Grid container>
            <CustomTable
              title='Editar jornadas de clientes'
              headCells={headCells}
              handleRows={handleRows}
              handleClickItem={handleClickItem}
              route='customerJourneys'
              ignorePrefix={['EDIT']}
              hasActions={false}
              customParams={{
                withJourneyType: true,
                withCustomer: true,
              }}
            />
          </Grid>
        </PageContainer>
      </MiniDrawer>
    </React.Fragment>
  );
}
