import { Grid } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import MiniDrawer from "../../components/MiniDrawer";
import Table from '../../components/Table';
import history from "../../history";
import { formatDate } from "../../utils";


const headCells = [
    { id: 'id', numeric: false, label: 'ID usuário' },
    { id: 'username', numeric: false, label: 'Usuário' },
    { id: 'startDate', numeric: false, label: 'Inicio' },
    { id: 'endDate', numeric: false, label: 'Fim' },
];

export default function Vacations() {
    const [searchOptions, setSearchOptions] = useState([
        {
            value: 'name',
            name: 'Usuário',
        },
    ]);

    const handleRows = useCallback(data => {
        const treatedRows = data.rows.map(row => {
            return {
                username: row.user.name,
                id: row.user.id,
                startDate: formatDate(row.startDate),
                endDate: formatDate(row.endDate),
            }
        })
        return { rows: treatedRows, total: data.count }
    }, []);

    function handleClickItem({userId}) {
        history.push(`/users/${userId}`);
    }

    return (
        <React.Fragment>
            <MiniDrawer currentExpaded='users'>
                <Grid container >
                    <Grid container item >
                        <Grid item xs={12}>
                            {searchOptions && (
                                <Table
                                    searchOptions={searchOptions}
                                    title='Férias'
                                    headCells={headCells}
                                    handleRows={handleRows}
                                    handleClickItem={handleClickItem}
                                    route='vacation/latest'
                                    rolePrefix='DEMAND'
                                    hasActions={false}
                                    serverSort={true}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </MiniDrawer>
        </React.Fragment>
    )
}