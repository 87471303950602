import React, { useRef, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@unform/web';
import moment from 'moment';
import * as Yup from 'yup';
import apiOld from '../../../services/Api';
import {
  ButtonsContainer,
  ContainerInput,
  ExitButton,
  ContentContainer,
  SubmitButton,
} from './ChangeSLAModal.styles';
import CustomerSlasCalendar from '../../customerSlasCalendar/CustomerSlasCalendar';
import Modal from '../../../components/modal/Modal';

export default function ChangeSLAModal({ open, handleClose, modalData }) {
  const formRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  async function handleChangeDemandSla(data) {
    try {
      setSubmitLoading(true);
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        deliveryTime: Yup.date().required().nullable(true),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const request = {
        deliveryTime: data.deliveryTime,
      };

      await apiOld.patch(`/demands/${modalData.id}/deleveryForecast`, request);

      handleClose(true);
    } catch (error) {
      console.error(error);

      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};

        error.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    } finally {
      setSubmitLoading(false);
    }
  }

  return (
    <Modal visible={open}>
      <ContentContainer>
        <h1>Alterar SLA Externo</h1>

        <Form ref={formRef} onSubmit={handleChangeDemandSla}>
          <ContainerInput>
            <CustomerSlasCalendar
              name='deliveryTime'
              defaultValue={
                modalData.deleveryForecast
                  ? moment(modalData.deleveryForecast).toDate()
                  : undefined
              }
              modalData={modalData}
            />
          </ContainerInput>

          <ButtonsContainer>
            <SubmitButton type='submit'>
              {!submitLoading && 'Alterar'}
              {submitLoading && <CircularProgress color='inherit' />}
            </SubmitButton>

            <ExitButton onClick={() => handleClose(false)}>Fechar</ExitButton>
          </ButtonsContainer>
        </Form>
      </ContentContainer>
    </Modal>
  );
}
