import styled, { css } from 'styled-components';
import { Constants } from '../../../utils/Constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background: #fff;
  border-radius: 5px;
  margin-bottom: 10px;
  box-shadow: 0 1px 4px 0 rgba(192, 208, 230, 0.8);
  cursor: grab;
  white-space: nowrap;
  max-width: 320px;
  min-height: 170px;
  overflow: hidden;

  h2 {
    font-weight: 500;
    font-size: 18px;
    padding: 0px 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .client-name {
    padding: 0px 15px;
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${props =>
    props.isDragging &&
    css`
      border: 2px dashed rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: transparent;
      box-shadow: none;
      cursor: grabbing;

      h2,
      p,
      div,
      span {
        opacity: 0.3;
      }
    `}
`;

export const Status = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 5px 5px 0 0;
  padding: 5px 15px;
  color: ${props => {
    switch (props.status) {
      case Constants.DEMAND_STATUSES.OPEN.key:
        return Constants.DEMAND_STATUSES.OPEN.color;
      case Constants.DEMAND_STATUSES.IN_PROGRESS.key:
        return props.isActiveContact
          ? Constants.DEMAND_TYPES.ACTIVE_CONTACT_MEETING.color
          : Constants.DEMAND_STATUSES.IN_PROGRESS.color;
      case Constants.DEMAND_STATUSES.PENDING.key:
        return Constants.DEMAND_STATUSES.PENDING.color;
      case Constants.DEMAND_STATUSES.FINISHED.key:
        return Constants.DEMAND_STATUSES.FINISHED.color;
      case 'OVERDUE':
        return '#ff4a55';
      case 'DUETOMORROW':
        return '#fff';
      case Constants.DEMAND_STATUSES.UNCHECKED.key:
        return Constants.DEMAND_STATUSES.UNCHECKED.color
      default:
        return '#000';
    }
  }};
  background-color: ${props => {
    switch (props.status) {
      case Constants.DEMAND_STATUSES.OPEN.key:
        return Constants.DEMAND_STATUSES.OPEN.backgroundColor1;
      case Constants.DEMAND_STATUSES.IN_PROGRESS.key:
        return props.isActiveContact
          ? Constants.DEMAND_TYPES.ACTIVE_CONTACT_MEETING.backgroundColor1
          : Constants.DEMAND_STATUSES.IN_PROGRESS.backgroundColor1;
      case Constants.DEMAND_STATUSES.PENDING.key:
        return Constants.DEMAND_STATUSES.PENDING.backgroundColor1;
      case Constants.DEMAND_STATUSES.FINISHED.key:
        return Constants.DEMAND_STATUSES.FINISHED.backgroundColor1;
      case 'OVERDUE':
        return 'rgb(255, 74, 85, 0.2)';
      case Constants.DEMAND_STATUSES.UNCHECKED.key:
        return Constants.DEMAND_STATUSES.UNCHECKED.backgroundColor1
      default:
        return 'rgba(0, 0, 0, 0.2)';
    }
  }};
  ${props =>
    props.status === Constants.DEMAND_STATUSES.FINISHED.key &&
    css`
      text-decoration: line-through;
    `}
  font-weight: bold;
`;

export const DemandTypeName = styled.span`
  color: rgb(0, 0, 0, 0.7);
  font-weight: lighter;
  padding: 0px 14px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* text-align: end; */
  /* text-decoration: underline; */
  font-style: italic;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 4px 15px;
`;

export const Description = styled.span`
  color: rgb(0, 0, 0, 0.5);
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  :first-child {
    border-top: 1px solid rgb(0, 0, 0, 0.1);
    padding-top: 3px;
  }
`;
export const PayOverdueIcon = styled.i`
  font-size: 14px;
  margin-left: 6px;
  color: #ea5f59;
  float: right;
`;

export const ActiveContactIcon = styled.i`
  font-size: 14px;
  color: #000;
  float: right;
`;
