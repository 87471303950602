import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import MiniDrawer from '../../components/MiniDrawer';
import { PageContainer } from '../../components/Pages.styles';
import JourneyTypeService from '../../services/journeys/journeyTypeService';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

const journeyTypeService = new JourneyTypeService();

export default function ViewJourneyType() {
  const { journeyTypeId } = useParams();
  const [state, setState] = useState({
    journeyType: {
      id: '',
      title: '',
      description: '',
      defaultDemands: [],
    },
  });

  const _fetchJourneyTypeData = async journeyTypeId => {
    try {
      const journeyType = await journeyTypeService.getJourneyTypeById(
        journeyTypeId
      );

      setState(prevState => ({
        ...prevState,
        journeyType,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (journeyTypeId) _fetchJourneyTypeData(journeyTypeId);
  }, [journeyTypeId]);

  return (
    <React.Fragment>
      <MiniDrawer currentExpaded='journeys'>
        <PageContainer>
          <Grid container>
            <Grid container className='titleContainer'>
              <h1>Tipo de Jornada</h1>
            </Grid>

            <Grid container>
              <Grid item xs={6} className='inputContainer'>
                <p>
                  <strong>Título da jornada:</strong>
                </p>
                <span>{state.journeyType.title}</span>
              </Grid>

              <Grid item xs={6} className='inputContainer'>
                <p>
                  <strong>Tipo de cliente elegível a jornada:</strong>
                </p>
                <span>{state.journeyType.customerType || 'Todos'}</span>
              </Grid>

              <Grid item xs={12} className='inputContainer'>
                <p>
                  <strong>Descrição da jornada:</strong>
                </p>
                <span>{state.journeyType.description}</span>
              </Grid>

              <Grid container className='titleContainer'>
                <h1>Demandas padrão</h1>
              </Grid>

              <p>
                Essas demandas abaixo, serão as demandas sugeridas na hora de
                criar uma jornada para o cliente, você pode altear a ordem das
                demandas clicando em "Editar"
              </p>

              {state.journeyType.defaultDemands.length > 0 ? (
                state.journeyType.defaultDemands.map(demand => (
                  <React.Fragment key={demand.id}>
                    <Grid item xs={4} className='inputContainer'>
                      <p>
                        <strong>Título:</strong>
                      </p>
                      <span>{demand.title}</span>
                    </Grid>

                    <Grid item xs={8} className='inputContainer'>
                      <p>
                        <strong>Descrição:</strong>
                      </p>
                      <span>{demand.description}</span>
                    </Grid>
                  </React.Fragment>
                ))
              ) : (
                <Grid container justify='center'>
                  <Link
                    to={`/journeyTypes/${journeyTypeId}/edit`}
                    className={'submit secondaryButton'}
                  >
                    Adicionar
                  </Link>
                </Grid>
              )}

              <Grid container justify='flex-end'>
                <Link
                  to={`/journeyTypes/${journeyTypeId}/edit`}
                  className={'submit'}
                >
                  Editar essa Jornada
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </PageContainer>
      </MiniDrawer>
    </React.Fragment>
  );
}
