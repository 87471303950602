import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Yup from 'yup';
import CustomSelectWithSearch from '../../input/customSelectorWithSearch/CustomSelectWithSearch';
import {
  ButtonsContainer,
  Container,
  ContainerInput,
  ExitButton,
  Modal,
  SubmitButton,
} from './ChangeDemandPrivacyModal.styles';
import { toast } from 'react-toastify';
import CustomerService from '../../../services/customerService';
import DemandService from '../../../services/demandService';

export default function ChangeDemandPrivacyModal({
  open,
  handleClose,
  modalData,
}) {
  const formRef = useRef(null);
  const [customerUsers, setCustomerUsers] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);

  const fetchCustomerUsers = async customerId => {
    try {
      setSubmitLoading(true);
      const customerUsers = await CustomerService.getCustomerUsersById(
        customerId
      );

      setCustomerUsers(customerUsers);
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomerUsers(modalData.customerId);
  }, [modalData.customerId]);

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          userId: Yup.string()
            .required()
            .nullable(true)
            .label('Usuário do cliente responsável'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setSubmitLoading(true);

        const request = {
          userId: data.userId,
        };

        await DemandService.toggleDemandPrivate({
          demandId: modalData.id,
          userId: request.userId,
        });

        toast.success('Demanda atualizada com sucesso!');

        handleClose(true);
      } catch (err) {
        console.error(err);
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });
          formRef.current.setErrors(validationErrors);
        }
      } finally {
        setSubmitLoading(false);
      }
    },
    [modalData.id, handleClose]
  );

  return (
    <>
      {open && (
        <Container>
          <Modal>
            <h1>
              Qual será o responsável do cliente após a demanda se tornar{' '}
              {modalData.private ? 'não' : ''} sigilosa?
            </h1>

            <Form ref={formRef} onSubmit={handleSubmit}>
              <ContainerInput>
                <CustomSelectWithSearch
                  placeholder='Usuário'
                  name='userId'
                  required
                  label='Usuário do cliente'
                  options={customerUsers?.map(user => ({
                    name: user.name,
                    value: user.id,
                  }))}
                />
              </ContainerInput>

              <ButtonsContainer>
                <SubmitButton type='submit'>
                  {!submitLoading && 'Alterar'}
                  {submitLoading && <CircularProgress color='inherit' />}
                </SubmitButton>

                <ExitButton onClick={() => handleClose(false)}>
                  Fechar
                </ExitButton>
              </ButtonsContainer>
            </Form>
          </Modal>
        </Container>
      )}
    </>
  );
}
