import apiOld from '../Api';

const documentsApi = {
  create: async function (data) {
    return apiOld.post('/documents', data);
  },

  update: async function (documentId, data) {
    return apiOld.put(`/documents/${documentId}`, data);
  },

  getDocumentUrl: async function (documentId) {
    return apiOld.get(`/documents/${documentId}/download`);
  },

  getDocumentUrlByTwoFactors: async function (data){
    return apiOld.get(`/documents/${data.documentId}/${data.customerId}/download`);
  }
};

export default documentsApi;
