import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* max-width: 90vw; */
  height: 100%;
  overflow-y: auto;


  #filter{
    display: flex;
    align-items: center;
    justify-content: left;
    h1{
      padding: 10px;
      color: #676a6c;
    }
    #selected{
      width: 150px;
      margin: 10px;
    }
    #btn{
    border: none;
    padding: 7px 10px;
    background-color: #1ab394;
    color: #fff;
    font-size: 17px;
    border-radius: 5px;
    margin: 11px 0px;
    align-self: flex-end;
    }
  }
`;

export const ColumnContainer = styled.div`
  display: flex;
  width: 100%;
  max-height: 90%;
  min-height: 90%;
  overflow-y: auto;
  overflow-x: auto;
`;
