import React, { useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import CustomInput from '../../components/input/CustomInput';
import CustomTextArea from '../../components/input/CustomTextArea';
import MiniDrawer from '../../components/MiniDrawer';
import CustomSelect from '../../components/input/CustomSelect';
import { Constants } from '../../utils/Constants';
import { PageContainer } from '../../components/Pages.styles';
import JourneyTypeService from '../../services/journeys/journeyTypeService';
import history from '../../history';

export default function CreateJourneyType() {
  const createJourneyTypeUnformRef = useRef(null);
  const journeyTypeService = new JourneyTypeService();

  const _handleCreateJourneyTypeFormSubmit = async data => {
    try {
      const schema = Yup.object().shape({
        title: Yup.string().required('O título é obrigatório'),
        description: Yup.string().required('A descrição é obrigatória'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const requestData = {
        ...data,
        customerType: data.customerType === 'Todos' ? null : data.customerType,
      };

      const newJourneyType = (
        await journeyTypeService.createJourneyType(requestData)
      ).data;

      toast.success('Tipo de jornada criado com sucesso!');

      history.push(`/journeyTypes/${newJourneyType.id}`);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};

        error.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });

        createJourneyTypeUnformRef.current.setErrors(errorMessages);
      }
    }
  };

  return (
    <React.Fragment>
      <MiniDrawer currentExpaded='journeys'>
        <PageContainer>
          <Grid container>
            <Grid container className='titleContainer'>
              <h1>Criar Tipo de Jornada</h1>
            </Grid>

            <Form
              ref={createJourneyTypeUnformRef}
              className='formContainer'
              onSubmit={_handleCreateJourneyTypeFormSubmit}
            >
              <Grid container>
                <Grid item xs={6} className='inputContainer'>
                  <CustomInput name='title' label='Título*' />
                </Grid>

                <Grid item xs={6} className='inputContainer'>
                  <CustomSelect
                    name='customerType'
                    label='Tipo de Cliente*'
                    options={Constants.CUSTOMER_TYPES.OPTIONS}
                  />
                </Grid>

                <Grid item xs={12} className='inputContainer'>
                  <CustomTextArea
                    name='description'
                    label='Descrição*'
                    placeholder='Descrição da jornada'
                  />
                </Grid>

                <Grid container justify='flex-end'>
                  <button type='submit' className='submit'>
                    Criar Novo Tipo de Jornada
                  </button>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </PageContainer>
      </MiniDrawer>
    </React.Fragment>
  );
}
