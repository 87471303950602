import CircularProgress from "@material-ui/core/CircularProgress";
import { Form } from "@unform/web";
import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import apiOld from "../../../services/Api";
import CustomInput from "../../input/CustomInput";
import {
    ButtonsContainer,
    Container,
    ContainerInputMarcas,
    ExitButton,
    Modal,
    SubmitButton,
} from "../changeDemandTitleModal/ChangeDemandTitleModal.styles";

export default function ChangeDemandProcessNumberModal(props) {
    const { open, handleClose, modalData } = props;
    const formRef = useRef(null);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [inputFields, setInputFields] = useState([
        { processNumber: '' }
    ]);

    const handleChangeInput = (i, e) => {
        const values = [...inputFields]
        if (e.target.value.length <= 9) {
            values[i]['processNumber'] = e.target.value
            setInputFields(values)
        }
    }

    const handleAddFields = () => {
        setInputFields([...inputFields, { processNumber: '' }])
    }

    const handleRemoveFields = (i) => {
        const values = [...inputFields]
        values.splice(i, 1)
        setInputFields(values)
    }

    const hasDuplicates = (array) => {
        return (new Set(array)).size !== array.length;
    }

    async function handleTest(data){
        
    }


    async function handleSubmit(data) {
        try {
            setSubmitLoading(true);

            formRef.current.setErrors({});

            const schema = Yup.object().shape({
                processNumber: Yup.string().required("Número do processo não pode estar em branco!").min(9, 'O número de processo precisa ter 9 digitos').max(9, 'O número de processo precisa ter 9 digitos')
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            const request = {
                processNumber: inputFields,
            };

            let array = []
            inputFields.map(dt => array.push(dt.processNumber))
            if (!hasDuplicates(array)) {

                await apiOld.patch(`/marcas/${modalData.id}/processNumberMarcas`, request);

                toast.success("Número do processo alterado com sucesso!");

                handleClose(true);
            } else {
                toast.error("Existem números respetidos!")
            }

        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const validationErrors = {};

                error.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });

                formRef.current.setErrors(validationErrors);
            } else {
            }
        } finally {
            setSubmitLoading(false);
        }
    }

    return (
        <>
            {open && (
                <Container>
                    <Modal>
                        <h1>Adicionar Número do Processo</h1>
                        <Form ref={formRef} onSubmit={handleSubmit}>
                            {
                                inputFields && inputFields.map((inputField, i) => (
                                    <ContainerInputMarcas key={i}>
                                        <CustomInput
                                            type='number'
                                            name='processNumber'
                                            label="Número do Processo"
                                            value={inputField.processNumber}
                                            onChange={event => handleChangeInput(i, event)}
                                        />
                                        <button type='button' onClick={handleAddFields}>+</button>
                                        <button type='button' onClick={handleRemoveFields}>-</button>
                                    </ContainerInputMarcas>
                                ))
                            }
                            <ButtonsContainer>
                                <SubmitButton type="submit">
                                    {!submitLoading && "Salvar"}
                                    {submitLoading && <CircularProgress color="inherit" />}
                                </SubmitButton>

                                <ExitButton onClick={() => handleClose(false)}>
                                    Cancelar
                                </ExitButton>
                            </ButtonsContainer>
                        </Form>
                    </Modal>
                </Container>
            )}
        </>
    )
}
