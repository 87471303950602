import React from 'react';
import MiniDrawer from '../../components/MiniDrawer';
import { PageContainer } from '../../components/Pages.styles';
import history from '../../history';
import CustomTable from '../../components/Table';
import { formatDate } from '../../utils';
import { Link } from 'react-router-dom';

const headCells = [
  { id: 'index', numeric: true, label: 'Posição' },
  { id: 'title', numeric: false, label: 'Título' },
  { id: 'description', numeric: false, label: 'Descrição' },
  { id: 'customerType', numeric: false, label: 'Tipo de Cliente' },
  { id: 'createdAt', numeric: false, label: 'Data de Criação' },
];

export default function ListJourneyType() {
  const handleRows = data => {
    return {
      rows: data.journeyTypes.map(journeyType => ({
        ...journeyType,
        createdAt: formatDate(journeyType.createdAt),
        customerType: journeyType.customerType || 'todos',
      })),
      total: data.total,
    };
  };

  const handleClickItem = journeyTypeId => {
    history.push(`/journeyTypes/${journeyTypeId}`);
  };

  return (
    <React.Fragment>
      <MiniDrawer currentExpaded='journeys'>
        <PageContainer>
          <div className='buttonContainer'>
            <Link to='journeyTypes/editOrder' className='button'>
              Editar ordens
            </Link>
          </div>

          <CustomTable
            title='Tipos de Jornada'
            headCells={headCells}
            handleRows={handleRows}
            handleClickItem={handleClickItem}
            route='journeyTypes'
            ignorePrefix={['EDIT', 'DELETE']}
            // handleEdit={_handleEditJourneyType}
            // rolePrefix='JOURNEYTYPES'
          />
        </PageContainer>
      </MiniDrawer>
    </React.Fragment>
  );
}
