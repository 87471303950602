import React, { useState } from 'react';
import {
  Container,
  Title,
  Description,
  ButtonContainer,
  ButtonConfirm,
  ButtonCancel,
} from './ConfirmationModal.styles';
import Modal from '../modal/Modal';
import Loading from '../Loading';

export default function ConfirmationModal({
  open,
  resolveCallback,
  rejectCallback,
  title = '',
  description = '',
}) {
  const [loading, setIsLoading] = useState(false);
  return (
    <Modal visible={open}>
      <Container>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <ButtonContainer>
          <ButtonCancel
            onClick={async () => {
              setIsLoading(true);
              await rejectCallback();
              setIsLoading(false);
            }}
          >
            Cancelar
          </ButtonCancel>

          <ButtonConfirm
            onClick={async () => {
              setIsLoading(true);
              await resolveCallback();
              setIsLoading(false);
            }}
          >
            Confirmar
          </ButtonConfirm>
        </ButtonContainer>

        <Loading open={loading} />
      </Container>
    </Modal>
  );
}
