import * as Yup from 'yup';
import filesApi from './apis/filesApi';
import { Constants } from '../utils/Constants';
import { toast } from 'react-toastify';
import customerApi from './apis/customerApi';

export default class CustomerService {
  static async getCustomerById(customerId) {
    return (await customerApi.getById(customerId)).data;
  }

  static async getCustomerUsersById(customerId) {
    return (await customerApi.getById(customerId)).data.users;
  }

  async updateCustomer(customerId, updateData) {
    return await customerApi.updateCustomer(customerId, updateData);
  }

  async handleUploadCustomerServiceContractFile(customerId, contractFile) {
    const contractFileYupSchema = Yup.object().shape({
      contractFile: Yup.mixed().required('Arquivo do contrato é obrigatório'),
    });

    await contractFileYupSchema.validate(
      { contractFile },
      {
        abortEarly: false,
      }
    );

    const contractFileUploadedAwsObject = await filesApi.uploadFile(
      contractFile,
      {
        type: Constants.FILE_TYPES.CUSTOMER_SERVICE_CONTRACT_FILE,
      }
    );

    const updateData = {
      serviceContractAwsKey: contractFileUploadedAwsObject.key,
    };

    const response = await this.updateCustomer(customerId, updateData);

    toast.success('Contrato do cliente salvo com sucesso!');

    return response;
  }
}
