import { createStore, combineReducers, applyMiddleware } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import demands from './reducers/demands';
import backofficeUsers from './reducers/backofficeUsers';
import demandTypes from './reducers/demandTypes';
import forms from './reducers/forms';
import auth from './reducers/auth';
import configs from './reducers/configs';
import ActiveContact from './reducers/ActiveContact';
import DraftMessageReducer from './reducers/DraftDemandMessage';
import promise from 'redux-promise';
import thunk from 'redux-thunk';
import multi from 'redux-multi';
import { createStateSyncMiddleware, initMessageListener } from "redux-state-sync";

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['drafts'],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    auth,
    demands,
    backofficeUsers,
    demandTypes,
    forms,
    configs,
    ActiveContact,
    drafts: DraftMessageReducer,
  })
);

const store = createStore(persistedReducer, applyMiddleware(multi, thunk, promise, createStateSyncMiddleware()));
initMessageListener(store);
const persistor = persistStore(store);
persistor.persist();
const redux = { store, persistor };

export default redux;
