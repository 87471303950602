import { combineReducers } from "redux";
import { RECIEVE_DEMAND_TYPES } from "../actionsTypes/ActionTypes";
import produce from "immer";

const ALL_INITIAL_STATE = [];
const BY_ID_INITIAL_STATE = {};

const all = produce((draft, action) => {
  switch (action.type) {
    case RECIEVE_DEMAND_TYPES: {
      draft = action.demandTypes;

      return draft;
    }
    default:
      return draft;
  }
}, ALL_INITIAL_STATE);

const byId = produce((draft, action) => {
  switch (action.type) {
    case RECIEVE_DEMAND_TYPES:
      action.demandTypes.forEach((demandType) => {
        draft[demandType.id] = demandType;
      });
      break;
    default:
      return draft;
  }
}, BY_ID_INITIAL_STATE);

export default combineReducers({
  byId,
  all,
});

export const getDemandType = (state, id) => state.byId[id];
