import { TextareaAutosize } from '@material-ui/core';
import styled from 'styled-components';

export const ContainerFilterDemand = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 1px;
  width: 98%;
  max-width: 1080px;
  margin: 2rem 0.5rem;
`;

export const OptionContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
  flex-direction: row;
  width: 33rem;
  align-items: center;
  justify-content: center;
`;

export const SquareButton = styled.button`
  background-color: rgba(220, 220, 220, 0.5);
  color: #333;
  border: none;
  width: ${props => (props.width ? props.width : '8rem')};
  height: ${props => (props.height ? props.height : '2.2rem')};
  font-weight: bold;
  font-size: 11px;
  cursor: default;
`;

export const ButtonFilter = styled.button`
  min-width: 7rem;
  max-width: 30rem;
  min-height: 2rem;
  max-height: 6rem;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  background: transparent;
  font-size: 0.8rem;
  border: none;
  border-bottom: 1px solid rgba(220,220,220, 1);
  border-left: 1px solid rgba(220,220,220, 1);
  border-radius: 8px;
  color: black;
  box-shadow: 1px 1px 0px 0px rgba(220,220,220, 1), 0px 1px 0px 0px rgba(220,220,220, 1);
  &:hover {
    background: rgba(220,220,220, 1);
  };
`;

export const DivMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TextArea = styled.div`
  display: block;
  width: 100%;
  height: 20rem;
  font-size: 16px;
  font-family: sans-serif;
  line-height: 1.5;
  color: #333;
  border: none;
  border-radius: 8px;
  box-shadow: 0 0.05px 8px 0.05px rgba(0, 0, 0, 0.2);
  resize: none;
  overflow-y: auto;
  &:focus {
    outline: none;
  }
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #c2c2c2;
    border-radius: 5px;
  }
  & > button {
    margin-right: 8px;
    padding: 8px;
    border-radius: 8px;
    border: none;
    background-color: rgba(220,220,220, 1);
    color: #333;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    &:hover {
      background-color: #333;
      color: #fff;
      transform: scale(1.1);
    }
  }
  @media (max-width: 76px) {
    width: 80%;
  }
`;