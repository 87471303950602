import { Constants } from '../utils/Constants';
import demandProccessItemsApi from './apis/demandProccessItemsApi';
import { preventWindowCloseOnUpload } from '../utils';
import FileService from './fileService';

export default class MessageService {
  constructor() {
    this.fileService = new FileService();
  }

  async createTextMessageWithAttachments({ demandId, content, attachments }) {
    if (!content && !attachments) return;

    window.addEventListener('beforeunload', preventWindowCloseOnUpload);

    const formatedAttachments =
      await this.fileService.uploadFilesAndFormatToDocumentStructure({
        files: attachments,
        documentType: Constants.FILE_TYPES.DOCUMENT,
      });

    const requestBody = {
      content,
      attachments: formatedAttachments,
    };

    const newMessage = (
      await demandProccessItemsApi.createMessage(demandId, requestBody)
    ).data;

    window.removeEventListener('beforeunload', preventWindowCloseOnUpload);

    return newMessage;
  }

  async createPrivateTextMessageWithAttachments({
    demandId,
    content,
    attachments,
  }) {
    const formatedAttachments =
      await this.fileService.uploadFilesAndFormatToDocumentStructure({
        files: attachments,
        documentType: Constants.FILE_TYPES.DOCUMENT,
      });

    const data = {
      content,
      attachments: formatedAttachments,
    };

    return (await demandProccessItemsApi.createPrivateMessage(demandId, data))
      .data;
  }
}
