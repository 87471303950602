import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* align-self: flex-start; */

  Form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 15px;
    padding: 20px 0px;
    width: 650px;

    input {
      width: 400px;
    }
  }
`;

const Button = styled.button`
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 15px;
  color: #fff;
  height: 40px;
`;

export const ButtonConfirm = styled(Button)`
  background-color: #1ab394;
  margin-left: 20px;
`;

export const ButtonClose = styled.i`
  margin-left: 20px;
  position: absolute;
  margin-left: 600px;
  margin-bottom: 60px;
  cursor: pointer;
`;

export const ResponseContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;
`;

export const ResponseSectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 15px;
  margin-right: 30px;
  padding: 20px 30px;
  max-height: 70vh;

  h2 {
    border-bottom: 1px solid #707070;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  ul {
    overflow-y: auto;
    max-width: 500px;

    li {
      overflow: hidden;

      a {
        text-decoration: none;
        color: #1994ff;
        font-size: 22px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      button {
        border: none;
        background-color: transparent;
        font-size: 22px;
        color: #1994ff;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 5px;
      }
    }
  }
`;
