import React, { useEffect, useRef, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Form } from "@unform/web";
import { toast } from "react-toastify";
import * as Yup from "yup";
import CustomInput from "../../../components/input/CustomInput";
import CustomSelectWithSearch from "../../../components/input/customSelectorWithSearch/CustomSelectWithSearch";
import Loading from "../../../components/Loading";
import MiniDrawer from "../../../components/MiniDrawer";
import history from "../../../history";
import apiOld from "../../../services/Api";

const useStyles = makeStyles(() => ({
  main: {
    background: "#FFF",
  },
  mainTitle: {
    background: "#FFF",
    borderTop: "solid 2px #e7eaec",
    borderBottom: "solid 1px #e7eaec",
    padding: "15px",
  },
  title: {
    fontSize: "15px",
  },
  mainForm: {
    padding: "10px",
  },
  form: {
    width: "100%",
  },
  input: {
    padding: "10px",
  },
  buttonContainer: {
    justifyContent: "flex-end",
    padding: "10px",
  },
  submit: {
    padding: "10px 15px",
    fontSize: "17px",
    background: "#1ab394",
    border: "none",
    color: "#fff",
    borderRadius: "5px",
  },
  subTitle: {
    background: "#FFF",
    borderBottom: "solid 1px #e7eaec",
  },
}));

export default function CreateDemandType() {
  const classes = useStyles();
  const formRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [content, setContent] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    apiOld.get("/forms").then((response) => {
      setContent(response.data);
    });

    setLoading(false);
  }, []);

  async function handleSubmit(data) {
    setSubmitLoading(true);
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        nome: Yup.string().required(),
        descrição: Yup.string().required(),
        SLA: Yup.number().required(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const request = {
        name: data.nome,
        description: data.descrição,
        formId: data.formulário,
        deliveryTime: data.SLA,
      };

      const response = await apiOld.post("/demands/types", request);

      toast.success("Tipo de demanda criado com sucesso!");

      history.push(`/demands/types/${response.data.id}`);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
    setSubmitLoading(false);
  }

  return (
    <React.Fragment>
      <MiniDrawer
        currentExpaded="demandTypes"
        main={
          <Grid container className={classes.main}>
            <Loading open={loading} />
            {content && (
              <>
                <Grid container className={classes.mainTitle}>
                  <h2 className={classes.title}>Criar Tipo de Demanda</h2>
                </Grid>
                <Grid container className={classes.mainForm}>
                  <Form
                    ref={formRef}
                    onSubmit={handleSubmit}
                    className={classes.form}
                  >
                    <Grid container>
                      <Grid item className={classes.input} xs={12}>
                        <CustomInput name="nome" label="Nome*" />
                      </Grid>
                      <Grid item className={classes.input} xs={12}>
                        <CustomInput name="descrição" label="Descrição*" />
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <CustomSelectWithSearch
                          placeholder="Nenhum formulário"
                          name="formulário"
                          label="Formulário"
                          options={content.forms?.map((form) => ({
                            name: form.name,
                            value: form.id,
                          }))}
                        />
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <CustomInput
                          type="number"
                          name="SLA"
                          label="SLA (Em dias úteis)*"
                        />
                      </Grid>
                      <Grid container className={classes.buttonContainer}>
                        <button className={classes.submit} type="submit">
                          {!submitLoading && "Cadastrar"}
                          {submitLoading && (
                            <CircularProgress color="inherit" />
                          )}
                        </button>
                      </Grid>
                    </Grid>
                  </Form>
                </Grid>
              </>
            )}
          </Grid>
        }
      />
    </React.Fragment>
  );
}
