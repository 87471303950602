import React, { useState, useRef, useEffect } from 'react';
import CustomInput from '../../../components/input/CustomInput';
import MiniDrawer from '../../../components/MiniDrawer';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from '@unform/web';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Yup from 'yup';
import history from '../../../history';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import TemplateService from '../../../services/templates/templateService';
import CustomTextArea from '../../../components/input/CustomTextArea';
import FileService from '../../../services/fileService';
import CheckboxInput from '../../../components/input/CheckboxInput';
import { EditTemplateFieldModal } from './components/editTemplateFieldModal/editTemplateFieldModal';

const templateService = new TemplateService();
const fileService = new FileService();

const useStyles = makeStyles(() => ({
  main: {
    background: '#FFF',
  },
  mainTitle: {
    background: '#FFF',
    borderTop: 'solid 2px #e7eaec',
    borderBottom: 'solid 1px #e7eaec',
    padding: '15px',
  },
  line: {
    backgorund: 'reds',
  },
  title: {
    fontSize: '15px',
  },
  mainForm: {
    padding: '10px',
  },
  form: {
    width: '100%',
  },
  input: {
    padding: '10px',
  },
  fields: {
    listStyleType: 'none',
    marginTop: '10px',
  },
  field: {
    padding: '10px 15px',
    border: '1px solid #e7eaec',
  },
  buttonContainer: {
    justifyContent: 'flex-end',
    padding: '10px',
  },
  submit: {
    padding: '5px 10px',
    fontSize: '13px',
    background: '#1ab394',
    border: 'none',
    color: '#fff',
    borderRadius: '5px',
    height: '100%',
  },
  download: {
    cursor: 'pointer',
    backgroundColor: '#1ab394',
    padding: '5px 10px',
    marginTop: '5px',
    color: '#fff',
    borderRadius: '5px',
    border: 'none',
  },
}));

export default function EditTemplate() {
  const { id: templateId } = useParams();
  const classes = useStyles();
  const formRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [template, setTemplate] = useState({
    id: '',
    name: '',
    description: '',
    html: '',
    fields: [],
  });
  const [editFieldModal, setEditFieldModal] = useState({
    open: false,
    field: undefined,
  });

  const _fetchTemplateData = async templateId => {
    try {
      const template = await templateService.getTemplateById(templateId);

      setTemplate(template);
    } catch (error) {
      console.error(error);
    }
  };

  async function _handleUpdateTemplateFormSubmit(templateFormData) {
    setSubmitLoading(true);

    try {
      formRef.current.setErrors({});

      const updatedData = await templateService.validateTemplateFormData({
        ...templateFormData,
        templateFile: template.awsFileKey,
      });
      const updatedTemplate = await TemplateService.updateTemplate(
        templateId,
        updatedData
      );

      toast.success('Template atualizado com sucesso!');

      history.push(`/templates/${updatedTemplate.id}`);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};

        error.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }

      console.error(error);
    }

    setSubmitLoading(false);
  }

  const _handleDownloadTemplateDocxFile = awsFileKey => {
    fileService.downloadFileByAwsKey(awsFileKey);
  };

  const _openEditFieldModal = field => {
    setEditFieldModal({
      open: true,
      field,
    });
  };

  const _closeEditFieldModal = () => {
    _fetchTemplateData(templateId);

    setEditFieldModal({
      open: false,
      field: undefined,
    });
  };

  useEffect(() => {
    if (templateId) {
      _fetchTemplateData(templateId);
    }
  }, [templateId]);

  return (
    <React.Fragment>
      <MiniDrawer currentExpaded='templates'>
        <Grid container className={classes.main}>
          {template && (
            <>
              <Grid container className={classes.mainTitle}>
                <h2 className={classes.title}>Editar Template</h2>
              </Grid>

              <Grid container className={classes.mainForm}>
                <Form
                  ref={formRef}
                  onSubmit={_handleUpdateTemplateFormSubmit}
                  className={classes.form}
                >
                  <Grid container>
                    <Grid item className={classes.input} xs={12}>
                      <CustomInput
                        name='name'
                        label='Nome'
                        defaultValue={template.name}
                      />
                    </Grid>

                    <Grid item className={classes.input} xs={12}>
                      <CustomTextArea
                        name='description'
                        label='Descrição'
                        maxLength='2000'
                        defaultValue={template.description}
                      />
                    </Grid>

                    <Grid item className={classes.input} xs={12}>
                      <CustomTextArea
                        name='html'
                        label='Html'
                        maxLength='200000'
                        defaultValue={template.html}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <CheckboxInput
                        name='backofficeOnly'
                        label='Apenas para o backoffice utilizar'
                        defaultValue={template.backofficeOnly}
                      />
                    </Grid>

                    <Grid item className={classes.input} xs={12}>
                      <h3>Template Atual:</h3>

                      <div
                        style={{
                          marginTop: '10px',
                        }}
                      >
                        <button
                          className={classes.submit}
                          onClick={() =>
                            _handleDownloadTemplateDocxFile(template.awsFileKey)
                          }
                          type='button'
                        >
                          Baixar Template
                        </button>

                        <Link
                          to={`/templates/${template.id}/edit/templateFile`}
                          className={classes.submit}
                          style={{
                            textDecoration: 'none',
                            marginLeft: '20px',
                            backgroundColor: '#3366BB',
                          }}
                        >
                          Alterar arquivo do template
                        </Link>
                      </div>
                    </Grid>

                    <Grid container className={classes.buttonContainer}>
                      <button className={classes.submit} type='submit'>
                        {submitLoading ? (
                          <CircularProgress color='inherit' />
                        ) : (
                          'Salvar'
                        )}
                      </button>
                    </Grid>

                    <Grid item className={classes.input} xs={12}>
                      <h3>Campos do Template:</h3>

                      <ul className={classes.fields}>
                        {template.fields &&
                          template.fields.map((field, idx) => (
                            <li
                              className={classes.field}
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              }}
                              key={field.id}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                }}
                              >
                                <span>{idx + 1})</span>

                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginLeft: '10px',
                                  }}
                                >
                                  <span>Label: {field.label}</span>
                                  <span>Nome: {field.name}</span>
                                  <span>Descrição: {field.description}</span>
                                  <span>Tipo: {field.type}</span>
                                </div>
                              </div>

                              <button
                                type='button'
                                className={classes.submit}
                                style={{
                                  backgroundColor: 'blueviolet',
                                }}
                                onClick={() => _openEditFieldModal(field)}
                              >
                                Editar
                              </button>
                            </li>
                          ))}
                      </ul>
                    </Grid>
                  </Grid>
                </Form>
              </Grid>
            </>
          )}

          <EditTemplateFieldModal
            open={editFieldModal.open}
            templateField={editFieldModal.field}
            handleClose={_closeEditFieldModal}
            allTemplateFieldsIndexes={template.fields.map(field => field.index)}
          />
        </Grid>
      </MiniDrawer>
    </React.Fragment>
  );
}
