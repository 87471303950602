import apiOld from "../Api";
import redux from "../../redux/store";
import {
  removeToken,
  removeUser
} from '../../services/Auth'
import history from '../../history';
import * as authActions from "../../redux/actions/auth";

const { store } = redux;
const authApi = {
  login: async function (data = {}, additionalParams = {}) {
    return new Promise((resolve, reject) => {
      apiOld
        .post("/auth", data, {
          params: {
            ...additionalParams,
          },
        })
        .then((response) => {
          store.dispatch(
            authActions.login(response.data.user, response.data.token)
          );
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  getAuthUser: async function (additionalParams = {}) {
    return new Promise((resolve, reject) => {
      apiOld
        .get("/auth/users", {
          params: {
            ...additionalParams,
          },
        })
        .then((response) => {
          store.dispatch(authActions.updateSession(response.data));
          resolve(response);
        })
        .catch((error) => {
          removeToken()
          removeUser()
          history.push('/signIn');
          reject(error)
        });
    });
  },
};

export default authApi;
