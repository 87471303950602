import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import React, { useState } from 'react';
import { formatDate } from '../../../utils';
import { Constants } from '../../../utils/Constants';
import CustomersModal from '../../customersModal/CustomersModal';
import {
  CustomerListsContainer,
  CustomerListContent,
  SimpleTable,
} from './churn.styles';

export default function ChurnMetrics({ metrics }) {
  const [customerModal, setCustomerModal] = useState({
    open: false,
    route: '',
    title: '',
    headCells: [],
    handleRows: () => ({}),
    customParams: {},
  });

  const setChurnCustomerModal = ({ title, isNDM, startDate }) => {
    setCustomerModal({
      open: true,
      title: title,
      route: 'churns',
      headCells: [
        {
          id: 'name',
          numeric: false,
          label: 'Nome',
        },
        {
          id: 'createdAt',
          numeric: false,
          label: 'Data de Churn',
        },
        {
          id: 'reason',
          numeric: false,
          label: 'Motivo do Churn',
        },
        {
          id: 'accessAllowedUntil',
          numeric: false,
          label: 'Data de Acesso a Plataforma',
        },
        {
          id: 'ticket',
          numeric: true,
          label: 'Ticket',
        },
        {
          id: 'qntDemands',
          numeric: true,
          label: 'Número total de Demandas',
        },
      ],
      handleRows: data => ({
        rows: data.churns.map(churn => ({
          id: churn.customerId,
          name: churn.Customer?.name || 'Indefinido',
          createdAt: formatDate(churn.createdAt),
          reason: Constants.DEFAULT_TAGS.CHURN_REASONS[churn.reason].label,
          accessAllowedUntil: formatDate(churn.Customer?.accessAllowedUntil),
          ticket:
            parseFloat(churn.ticket).toLocaleString('pt-BR', {
              minimuFractionDigits: 2,
              style: 'currency',
              currency: 'BRL',
            }) || 'Indefinido',
          qntDemands: churn.qntDemands || 0,
        })),
        total: data.total,
      }),
      customParams: {
        qntDemands: true,
        withCustomer: true,
        startDate,
        isNDM,
      },
    });
  };

  return (
    <CustomerListsContainer>
      {(metrics?.loading || !metrics?.content) && <CircularProgress />}

      <CustomerListContent>
        {metrics?.content && (
          <SimpleTable.Container>
            <SimpleTable.Table>
              <SimpleTable.TableHead>
                <SimpleTable.TableRow>
                  <SimpleTable.TableHeadCell
                    style={{
                      width: '20%',
                    }}
                  >
                    Períodos
                  </SimpleTable.TableHeadCell>
                  {metrics?.content?.renewal && (
                    <SimpleTable.TableHeadCell
                      style={{
                        textAlign: 'center',
                        width: '20%',
                      }}
                    >
                      Renovado
                    </SimpleTable.TableHeadCell>
                  )}
                  {metrics?.content?.churn?.ndm && (
                    <SimpleTable.TableHeadCell
                      style={{
                        textAlign: 'center',
                        width: '20%',
                      }}
                    >
                      Churn NDM
                    </SimpleTable.TableHeadCell>
                  )}
                  {metrics?.content?.churn?.customer && (
                    <SimpleTable.TableHeadCell
                      style={{
                        textAlign: 'center',
                        width: '20%',
                      }}
                    >
                      Churn Cliente
                    </SimpleTable.TableHeadCell>
                  )}
                  {metrics?.content?.suspended && (
                    <SimpleTable.TableHeadCell
                      style={{
                        textAlign: 'center',
                        width: '20%',
                      }}
                    >
                      Suspensão
                    </SimpleTable.TableHeadCell>
                  )}
                </SimpleTable.TableRow>
              </SimpleTable.TableHead>

              <SimpleTable.TableBody>
                {[
                  {
                    period: 'Últimos 7 dias',
                    since: moment().subtract('1', 'week').toDate(),
                    renewalIn: metrics?.content?.renewal?.renewedLastWeek,
                    churnNdmIn: metrics?.content?.churn?.ndm?.churnedLastWeek,
                    churnCustomerIn:
                      metrics?.content?.churn?.customer?.churnedLastWeek,
                    suspended:
                      metrics?.content?.suspended?.LastWeek,
                  },
                  {
                    period: 'Últimos 30 dias',
                    since: moment().subtract('1', 'months').toDate(),
                    renewalIn: metrics?.content?.renewal?.renewedLastMonth,
                    churnNdmIn: metrics?.content?.churn?.ndm?.churnedLastMonth,
                    churnCustomerIn:
                      metrics?.content?.churn?.customer?.churnedLastMonth,
                    suspended:
                      metrics?.content?.suspended?.LastMonth,
                  },
                  {
                    period: 'Últimos 90 dias',
                    since: moment().subtract('3', 'months').toDate(),
                    renewalIn: metrics?.content?.renewal?.renewedLast3Months,
                    churnNdmIn:
                      metrics?.content?.churn?.ndm?.churnedLast3Months,
                    churnCustomerIn:
                      metrics?.content?.churn?.customer?.churnedLast3Months,
                    suspended:
                      metrics?.content?.suspended?.Last3Months,
                  },
                ].map(statistics => (
                  <SimpleTable.TableRow key={statistics.period}>
                    <SimpleTable.TableData>
                      {statistics.period}
                    </SimpleTable.TableData>

                    <SimpleTable.TableData
                      style={{
                        textAlign: 'center',
                        color: '#1ab394',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        setCustomerModal({
                          open: true,
                          title: 'Analítico Renovações',
                          route: 'customers',
                          headCells: [
                            {
                              id: 'name',
                              numeric: false,
                              label: 'Nome',
                            },
                            {
                              id: 'renewalDate',
                              numeric: false,
                              label: 'Data de renovação',
                            },
                            {
                              id: 'renewalValue',
                              numeric: true,
                              label: 'Valor',
                            },
                          ],
                          handleRows: data => ({
                            rows: data.customers.map(customer => ({
                              id: customer.id,
                              name: customer.name,
                              renewalDate: formatDate(customer.renewalDate),
                              renewalValue: customer.renewalValue
                                ? parseFloat(
                                    customer.renewalValue
                                  ).toLocaleString('pt-BR', {
                                    minimuFractionDigits: 2,
                                    style: 'currency',
                                    currency: 'BRL',
                                  })
                                : 'Indefinido',
                            })),
                            total: data.total,
                          }),
                          customParams: {
                            renewalDate: statistics.since,
                            withValue: true,
                          },
                        })
                      }
                    >
                      {statistics.renewalIn}
                    </SimpleTable.TableData>
                    <SimpleTable.TableData
                      style={{
                        textAlign: 'center',
                        color: '#1ab394',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        setChurnCustomerModal({
                          title: 'Analítico Churn NDM',
                          isNDM: true,
                          startDate: statistics.since,
                        })
                      }
                    >
                      {statistics.churnNdmIn}
                    </SimpleTable.TableData>
                    <SimpleTable.TableData
                      style={{
                        textAlign: 'center',
                        color: '#1ab394',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        setChurnCustomerModal({
                          title: 'Analítico Churn Cliente',
                          isNDM: false,
                          startDate: statistics.since,
                        })
                      }
                    >
                      {statistics.churnCustomerIn}
                    </SimpleTable.TableData>
                    <SimpleTable.TableData
                      style={{
                        textAlign: 'center',
                        color: '#1ab394',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        setCustomerModal({
                          open: true,
                          title: 'Analítico Suspensões',
                          route: 'suspendedContracts',
                          headCells: [
                            {
                              id: 'customerId',
                              numeric: false,
                              label: 'Id do Cliente',
                            },
                            {
                              id: 'customer',
                              numeric: false,
                              label: 'Cliente',
                            },
                            {
                              id: 'suspendedByUser',
                              numeric: false,
                              label: 'Suspenso por',
                            },
                            {
                              id: 'suspendedAt',
                              numeric: true,
                              label: 'Inicio',
                            },
                            {
                              id: 'suspendedUntil',
                              numeric: true,
                              label: 'Fim',
                            },
                          ],
                          handleRows: data => ({
                            rows: data.rows.map(suspended => ({
                              id: suspended.customerId,
                              customerId: suspended.customerId,
                              customer: suspended.Customer.name,
                              suspendedByUser: suspended.user.name,
                              suspendedAt: formatDate(suspended.suspendedAt),
                              suspendedUntil: formatDate(suspended.suspendedUntil),
                              
                            })),
                            total: data.total,
                          }),
                          customParams: {
                            startDate: statistics.since,
                          },
                        })
                      }
                    >
                      {statistics.suspended}
                    </SimpleTable.TableData>
                  </SimpleTable.TableRow>
                ))}
              </SimpleTable.TableBody>
            </SimpleTable.Table>
          </SimpleTable.Container>
        )}
      </CustomerListContent>
      {customerModal.open && (
        <CustomersModal
          visible={customerModal.open}
          route={customerModal.route}
          title={customerModal.title}
          headCells={customerModal.headCells}
          handleRows={customerModal.handleRows}
          customParams={customerModal.customParams}
          handleCloseCustomersModal={() =>
            setCustomerModal({
              open: false,
              route: '',
              title: '',
              headCells: [],
              handleRows: () => ({}),
              customParams: {},
            })
          }
        />
      )}
    </CustomerListsContainer>
  );
}
