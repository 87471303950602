export const dataTitleQuestion = {
  title: 'Pergunta',
  subTitle: 'Adicionar Questões',
  buttonText: 'Clique para criar uma questão',
  route: '/riskAnalysis/question',
  params: 'sector',
};
export const dataModalQuestion = {
  add: {
    title: 'Adicionar Questão',
    description: 'o campo abaixo para adicionar uma nova questão',
    label: 'Criar',
  },
  edit: {
    title: 'Editar Alternativa',
    description: 'o campo abaixo para editar a alternativa',
    label: 'Editar',
  },
  addAnwser: {
    title: 'Adicionar Alternativa',
    description: 'o campo abaixo para adicionar uma nova alternativa',
    label: 'Criar',
  },
  editAnwser: {
    title: 'Editar Questão',
    description: 'o campo abaixo para editar a questão',
    label: 'Editar',
  },
}

export const dataModalSector = {
  add: {
    title: 'Adicionar Evento de Risco',
    description: 'o campo abaixo para adicionar um novo Evento de Risco',
    label: 'Criar',
  },
  edit: {
    title: 'Editar Evento de Risco',
    description: 'o campo abaixo para editar o Evento de Risco',
    label: 'Editar',
  }
}
export const dataTitleSector = {
  title: 'Evento de Risco',
  subTitle: 'Adicionar Evento de Risco',
  buttonText: 'Clique para criar uma Evento de Risco',
  route: '/riskAnalysis/subcategory',
  params: 'sector',
};

export const dataSubcategory = [
  {name: 'Probabilidade'},
  {name: 'Impacto'},
];
export const dataTitleSubcategory = {
  title: 'Subcategoria',
  subTitle: 'Selecionar Subcategoria',
  route: '/riskAnalysis/question',
  params: 'subcategory',
};