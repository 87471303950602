import apiOld from '../Api';

const customerOnboardingApi = {
  getOnboardingDashboard: async function () {
    return apiOld.get(`/onboarding/dashboard`);
  },

  registerSalesForm: async function (customerId, data) {
    return apiOld.post(`/onboarding/${customerId}/registerSalesForm`, data);
  },

  setCustomerOnboardingMeetingSuggestedAt: async function (customerId) {
    return apiOld.post(`/onboarding/${customerId}/onboardingMeetingSuggestedAt`);
  },

  updateCustomerOnboardingStatus: async function (customerId, data) {
    return apiOld.patch(`/onboarding/${customerId}/status`, data);
  },
};

export default customerOnboardingApi;
