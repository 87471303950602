import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { formatDate } from '../../../../utils';
import Loading from '../../../../components/Loading';
import MiniDrawer from '../../../../components/MiniDrawer';
import csApi from '../../../../services/apis/csApi';
import Table from '../../../../components/Table';
import {
  Container,
  Title,
  Section,
  SectionTitle,
  SectionContent,
  MessageIcon,
} from '../../Dashboard.styles';
import { DemandFeedbackAverageContainer } from './FeedbackActiveContact.styles';
import { Constants } from '../../../../utils/Constants';
import FeedbackModal from '../../../../components/feedbacks/feedbackModal/FeedbackModal';
import {
  addQueryParamToUrl,
  removeQueryParamFromUrl,
} from '../../../../routes';
import { useSelector } from 'react-redux';

const feedbackTableHeadCells = [
  { id: 'rating', numeric: true, label: 'Nota' },
  { id: 'customer', numeric: false, label: 'Cliente' },
  { id: 'demand', numeric: false, label: 'Demanda' },
  { id: 'createdAt', numeric: false, label: 'Criado em' },
];

const handleFeedbackTableRows = data => {
  return {
    rows: data.feedbacks.map(feedback => {
      feedback.createdAt = formatDate(feedback.createdAt);
      feedback.customer = feedback.Customer?.name || feedback.customerId;
      feedback.demand = feedback.Demand?.name || feedback.demandId;
      feedback.showIcon = !!feedback.description;
      return feedback;
    }),
    total: data.total,
  };
};

export default function FeedbackDashboard() {
  const [feedbackDashboardData, setFeedbackDashboardData] = useState(false);
  const demandTypes = useSelector(state => state.demandTypes.all);

  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const dashboardData = (
        await csApi.getFeedbackDashboard({
          demandTypeId: demandTypes.filter(
            demand =>
              demand.name === Constants.DEMAND_TYPES.ACTIVE_CONTACT_MEETING.key
          )[0]?.id,
        })
      )?.data;
      setFeedbackDashboardData(dashboardData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [demandTypes]);

  const handleOpenDemandFeedbackModal = id => {
    addQueryParamToUrl(
      Constants.QUERY_PARAMS_STATES.FEEDBACK.DEMAND_FEEDBACK_MODAL,
      id
    );
  };

  const handleCloseDemandFeedbackModal = () => {
    removeQueryParamFromUrl(
      Constants.QUERY_PARAMS_STATES.FEEDBACK.DEMAND_FEEDBACK_MODAL
    );
  };

  useEffect(() => {
    if (demandTypes) fetchData();
  }, [fetchData, demandTypes]);

  return (
    <React.Fragment>
      <MiniDrawer
        currentExpaded='cs'
        main={
          <Container>
            <Loading open={loading} />

            <Title>Feedbacks dos clientes de Contato Ativo</Title>

            <Section>
              <SectionTitle>Feedbacks sobre demandas</SectionTitle>

              {feedbackDashboardData?.demandsFeedbacks && demandTypes && (
                <SectionContent>
                  <DemandFeedbackAverageContainer>
                    <h3>Média de feedbacks</h3>

                    <span>
                      {parseFloat(
                        feedbackDashboardData?.demandsFeedbacks.ratingAverage
                      ).toFixed(2)}
                    </span>
                  </DemandFeedbackAverageContainer>

                  <Table
                    // searchOptions={searchOptions}
                    title='Feedbacks'
                    headCells={feedbackTableHeadCells}
                    handleRows={handleFeedbackTableRows}
                    handleClickItem={feedbackId => {
                      handleOpenDemandFeedbackModal(feedbackId);
                    }}
                    route='feedbacks'
                    rolePrefix=''
                    // handleEdit={handleEdit}
                    customParams={{
                      type: Constants.FEEDBACK_TYPES.DEMAND,
                      demandTypeId: demandTypes
                        .filter(
                          demand =>
                            demand.name ===
                            Constants.DEMAND_TYPES.ACTIVE_CONTACT_MEETING.key
                        )
                        .map(type => type.id),
                      ignoreDemandTypeId: false,
                    }}
                    Icon={
                      <MessageIcon className='far fa-comment-alt'></MessageIcon>
                    }
                  />
                </SectionContent>
              )}
            </Section>

            <FeedbackModal handleClose={handleCloseDemandFeedbackModal} />
          </Container>
        }
      />
    </React.Fragment>
  );
}
