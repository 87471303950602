import { toast } from 'react-toastify';
import apiOld from '../Api';

const customerApi = {
  updateCustomer: async function (customerId, data = {}) {
    return apiOld.put(`/customers/${customerId}`, data);
  },

  getAll: async function (additionalParams = {}) {
    return apiOld.get(`/customers`, {
      params: {
        ...additionalParams,
      },
    });
  },

  getById: async function (customerId, additionalParams = {}) {
    return apiOld.get(`/customers/${customerId}`, {
      params: {
        ...additionalParams,
      },
    });
  },

  getCustomerUsers: async function (customerId, additionalParams = {}) {
    return apiOld.get(`/customers/${customerId}/users`, {
      params: {
        ...additionalParams,
      },
    });
  },

  getAllInFupDate: async function (additionalParams = {}) {
    return apiOld.get(`/customers/specificDemand/followup`, {
      params: {
        ...additionalParams,
      },
    });
  },

  addTag: async function (customerId, tagId, data) {
    return apiOld.post(`/customers/${customerId}/tags/${tagId}`, data);
  },

  removeTag: async function (customerId, tagId) {
    return apiOld.delete(`/customers/${customerId}/tags/${tagId}`);
  },

  sendContractEmail: async function (customerId, data = {}) {
    return apiOld.post(`/customers/${customerId}/contract`, data);
  },

  sendFollowupEmail: async function (customerId, key, data = {}) {
    return new Promise((resolve, reject) => {
      apiOld
        .post(`/customers/${customerId}/specificDemand/followup/${key}`, data)
        .then(response => {
          toast.success('Email enviado com sucesso!');

          resolve(response);
        })
        .catch(error => reject(error));
    });
  },

  fileCustomer: async function (customerId, data = {}) {
    return new Promise((resolve, reject) => {
      apiOld
        .post(`/customers/${customerId}/specificDemand/file`, data)
        .then(response => {
          toast.success('Cliente arquivado com sucesso!');

          resolve(response);
        })
        .catch(error => reject(error));
    });
  },

  registerCustomerEngagementActivity: async function (customerId, data = {}) {
    return new Promise((resolve, reject) => {
      apiOld
        .post(`/customers/${customerId}/engagement/register`, data)
        .then(response => {
          toast.success('Atividade registrada com sucesso!');

          resolve(response);
        })
        .catch(error => reject(error));
    });
  },

  registerCustomerActiveContactActivity: async function (
    customerId,
    data = {}
  ) {
    return new Promise((resolve, reject) => {
      apiOld
        .post(`/customers/${customerId}/activeContact/register`, data)
        .then(response => {
          toast.success('Atividade registrada com sucesso!');

          resolve(response);
        })
        .catch(error => reject(error));
    });
  },

  registerCustomerActiveContactNextAction: async function (
    customerId,
    data = {}
  ) {
    return new Promise((resolve, reject) => {
      apiOld
        .patch(`/customers/${customerId}/activeContact/action`, data)
        .then(response => {
          toast.success('Próxima ação registrada com sucesso!');

          resolve(response);
        })
        .catch(error => reject(error));
    });
  },

  updateActiveContactBackofficeUSer: async function (customerId, data = {}) {
    return new Promise((resolve, reject) => {
      apiOld
        .patch(`/customers/${customerId}/activeContact`, data)
        .then(response => {
          toast.success('Responsável alterado com sucesso!');

          resolve(response);
        })
        .catch(error => reject(error));
    });
  },

  deleteAnnotation: async function (customerId, annotationIdx, data = {}) {
    return new Promise((resolve, reject) => {
      apiOld
        .delete(`/customers/${customerId}/annotations/${annotationIdx}`, data)
        .then(response => {
          resolve(response);
        })
        .catch(reject);
    });
  },
};

export default customerApi;
