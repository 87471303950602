import customerOnboardingApi from './apis/customerOnboardingApi';

export default class OnboardingService {
  constructor() {
    this.customerOnboardingApi = customerOnboardingApi;
  }

  async getOnboardingDashboard() {
    return (await this.customerOnboardingApi.getOnboardingDashboard())?.data;
  }
}
