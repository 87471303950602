import React, { useRef } from 'react';
import {
  Container,
  Status,
  DemandTypeName,
  DescriptionContainer,
  Description,
  PayOverdueIcon,
} from './Card.style';
import { useDrag, useDrop } from 'react-dnd';
import { useDispatch } from 'react-redux';
import { formatDate, formatDateHour } from '../../../utils';
import { openDemandModal } from '../../../routes';
import moment from 'moment';
import { Constants } from '../../../utils/Constants';
import DefaultTags from '../../../constants/DefaultTags';

export default function Card({ data, demandId, index, listIndex, listId, columnTitle }) {
  const ref = useRef();
  const statusConfigs = Constants.DEMAND_STATUSES[data.statusKey];
  const overdue =
    data.deleveryForecast &&
    moment(data.deleveryForecast).isBefore(moment(), 'days');
  const dueToday =
    data.deleveryForecast &&
    moment(data.deleveryForecast).isSame(moment(), 'days');
  const dueTomorrow =
    data.deleveryForecast &&
    moment(data.deleveryForecast).isSame(moment().add(1, 'days'), 'days');
  const dispatch = useDispatch();

  const [{ isDragging }, dragRef] = useDrag({
    item: { index, listIndex, demandId, listId, data },
    type: 'CARD',
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, dropRef] = useDrop({
    accept: 'CARD',
    hover(item, monitor) {
      const draggedListIndex = item.listIndex;
      const targetListIndex = listIndex;
      const draggedIndex = item.index;
      const targetIndex = index;

      if (
        draggedIndex === targetIndex &&
        draggedListIndex === targetListIndex
      ) {
        return;
      }

      const targetSize = ref.current.getBoundingClientRect();
      const targetCenter = (targetSize.bottom - targetSize.top) / 2;
      const draggedOffset = monitor.getClientOffset();
      const draggedTop = draggedOffset.y - targetSize.top;

      if (draggedIndex < targetIndex && draggedTop < targetCenter) {
        return;
      }

      if (draggedIndex > targetIndex && draggedTop > targetCenter) {
        return;
      }

      dispatch({
        type: 'MOVE',
        fromList: draggedListIndex,
        toList: targetListIndex,
        from: draggedIndex,
        to: targetIndex,
        listId: listId,
      });

      item.index = targetIndex;
      item.listIndex = targetListIndex;
    },
  });

  const handleModalOpen = () => {
    openDemandModal(demandId);
  };

  dragRef(dropRef(ref));

  const getStatus = () => {
    if (data.statusKey === Constants.DEMAND_STATUSES.FINISHED.key)
      return data.statusKey;
    if (overdue) return 'OVERDUE';
    if (data.checked != true && (columnTitle != 'Pré-triagem')) return 'UNCHECKED'

    return data.statusKey;
  };

  const getTitle = () => {
    if (data.statusKey === Constants.DEMAND_STATUSES.FINISHED.key)
      return statusConfigs.name;
    if (overdue) return 'Atrasado!';
    if (dueToday) return 'Vence hoje!';
    if (dueTomorrow) return 'Vence amanhã!';

    return statusConfigs.name;
  };

  return (
    (data && (
      <>
        <Container
          ref={ref}
          isDragging={isDragging}
          onClick={() => handleModalOpen()}
        >
          <Status
            isActiveContact={[
              Constants.DEMAND_TYPES.ACTIVE_CONTACT_MEETING.key,
              Constants.DEMAND_TYPES.ENGAGEMENT_MEETING.key,
              Constants.DEMAND_TYPES.CHURN_MEETING.key,
            ].includes(data.demandTypeName)}
            status={getStatus()}
          >
            {getTitle()}

            <span>
              {data.internDeliveryForecast
                ? formatDate(new Date(data.internDeliveryForecast))
                : ''}
            </span>
          </Status>

          <h2>
            {data.externalId} - {data.name}
          </h2>

          <p className='client-name'>{data.customer?.name}</p>

          {data.demandTypeName && (
            <DemandTypeName>{data.demandTypeName}</DemandTypeName>
          )}

          <DescriptionContainer>
          <Description>
            {
            data.statusKey === Constants.DEMAND_STATUSES.FINISHED.key ? null : `Data de entrega: ${formatDate(data.deleveryForecast) || ''}`

            }

            </Description>
            <Description>
              Criada em: {formatDate(data.createdAt)}
              {' as '}
              {formatDateHour(data.createdAt)}
            </Description>
            <Description>
              Última atualização: {formatDate(data.lastBackofficeAction)}
              {' as '}
              {formatDateHour(data.lastBackofficeAction)}
            </Description>
            <Description>
              Negócio: {data.Business?.name}
            </Description>
            <Description>
              {data.customer?.tags?.find(
                tag => tag.name === DefaultTags.PAYMENT_OVERDUE
              ) && (
                <PayOverdueIcon className='fas fa-exclamation-circle'></PayOverdueIcon>
              )}
            </Description>
          </DescriptionContainer>
        </Container>
      </>
    )) ||
    null
  );
}
