import { Form } from '@unform/web';
import { useRef } from 'react';
import * as Yup from 'yup';
import customerApi from '../../services/apis/customerApi';
import CustomTextArea from '../input/CustomTextArea';
import Modal from '../modal/Modal';
import { ModalContainer } from './csModals.styles';

export const RegisterActivityModal = ({
  open,
  customer,
  isActiveContact = false,
  fetchData,
  handleClose,
}) => {
  const registerAcRef = useRef(null);

  const _handleFormSubmit = async data => {
    try {
      registerAcRef.current.setErrors({});

      const schema = Yup.object().shape({
        description: Yup.string().label('Descrição da atividade'),
      });

      const request = await schema.validate(data, {
        abortEarly: false,
      });

      await customerApi[
        isActiveContact
          ? 'registerCustomerActiveContactActivity'
          : 'registerCustomerEngagementActivity'
      ](customer.id, request);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};

        error.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });

        registerAcRef.current.setErrors(validationErrors);

        return;
      }

      console.error(error);
    } finally {
      await fetchData();
      handleClose();
    }
  };

  return (
    <Modal visible={open}>
      <ModalContainer>
        <i className='fa fa-2x fa-times closeModal' onClick={handleClose} />

        <h1>Registrar atividade</h1>

        <Form ref={registerAcRef} onSubmit={_handleFormSubmit}>
          <div className='inputContainer'>
            <CustomTextArea
              maxLength={70000}
              label='Descrição'
              name='description'
            />
          </div>

          <button type='submit' className='submitButton'>
            Registrar
          </button>
        </Form>
      </ModalContainer>
    </Modal>
  );
};
