import apiOld from "../Api";
import redux from "../../redux/store";
import * as demandTypesActions from "../../redux/actions/demandTypesActions";

const { store } = redux;

const demandTypesApi = {
  getAllDemandTypes: async function (additionalParams = {}) {
    return new Promise((resolve, reject) => {
      apiOld
        .get("/demands/types", {
          params: {
            ...additionalParams,
          },
        })
        .then((response) => {
          store.dispatch(
            demandTypesActions.recieveDemandTypes(response.data.demandTypes)
          );
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
};

export default demandTypesApi;
