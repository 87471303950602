import { SimpleTable } from '../metrics/churn/churn.styles';
import moment from 'moment';
import { CustomerLink } from './Tables.styles';
import { formatDate } from '../../utils';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const tableHeaders = [
  'Clientes',
  'Responsável',
  'Demanda',
  'Próxima ação',
  'Data da próxima ação',
  'ações',
];

const sortByDueDate = (a, b) => {
  const firstElementDueDate = new Date(
    a.FollowUps[a.FollowUps.length - 1].dueDate
  );
  const secondElementDueDate = new Date(
    b.FollowUps[b.FollowUps.length - 1].dueDate
  );
  return firstElementDueDate - secondElementDueDate;
};

const prepareDemands = (demands, backofficeUsers) => {
  let demandsPrepared = [...demands];
  demandsPrepared = demandsPrepared.map(demand => {
    demand.csBackofficeUserName =
      backofficeUsers.find(backofficeUser => {
        return (
          backofficeUser.id === demand.customer.ActiveContact?.backofficeUserId
        );
      })?.name || 'Indefinido';
    return demand;
  });

  const csUsers = [];
  demandsPrepared.forEach(demand => {
    if (!csUsers.includes(demand.csBackofficeUserName)) {
      csUsers.push(demand.csBackofficeUserName);
    }
  });

  const csUsersSorted = csUsers.sort();

  const demandsSortedByDate = demandsPrepared.sort(sortByDueDate);

  const newSortedDemandsArray = [];
  csUsersSorted.forEach(csUser => {
    while (true) {
      const demandIndex = demandsSortedByDate.findIndex(
        demand => demand.csBackofficeUserName === csUser
      );
      if (demandIndex === -1) {
        break;
      }
      newSortedDemandsArray.push(demandsSortedByDate[demandIndex]);
      demandsSortedByDate.splice(demandIndex, 1);
    }
  });

  return newSortedDemandsArray;
};

export const PriorityDemands = ({ fetchedData, confirmAction, filterChurn }) => {
  const backofficeUsers = useSelector(state => state.backofficeUsers.all);

  const demandsSorted = prepareDemands(
    fetchedData,
    backofficeUsers,
    filterChurn
  );
  return (
    <SimpleTable.Container>
      <SimpleTable.Table>
        <SimpleTable.TableHead>
          <SimpleTable.TableRow>
            {tableHeaders.map(header => {
              return (
                <SimpleTable.TableHeadCell>{header}</SimpleTable.TableHeadCell>
              );
            })}
          </SimpleTable.TableRow>
        </SimpleTable.TableHead>
        <SimpleTable.TableBody>
          {demandsSorted.map(data => {
            const { customer, FollowUps } = data;
            const nextAction = FollowUps[FollowUps.length - 1];
            const hasChurn = filterChurn(customer.tags, 'churn');

            return !hasChurn ? (
              <SimpleTable.TableRow>
                <SimpleTable.TableData>
                  <CustomerLink
                    title={`Health Score: ${customer.healthScore}`}
                    health={customer.healthScore}
                    to={`/customers/${customer.id}`}
                  >
                    {customer.name}
                  </CustomerLink>
                </SimpleTable.TableData>
                <SimpleTable.TableData>
                  {data.csBackofficeUserName}
                </SimpleTable.TableData>
                <SimpleTable.TableData>
                  <Link
                    to={{
                      pathname: `/demands/board`,
                      search: `?demandId=${data.id}`,
                    }}
                  >
                    {data.name}
                  </Link>
                </SimpleTable.TableData>
                <SimpleTable.TableData>{nextAction.todo}</SimpleTable.TableData>
                <SimpleTable.TableData
                  style={{
                    ...(moment().isAfter(nextAction.dueDate, 'days')
                      ? {
                          color: 'red',
                          fontWeight: 'bolder',
                        }
                      : {}),
                  }}
                >
                  {formatDate(nextAction.dueDate)}
                </SimpleTable.TableData>
                <SimpleTable.TableData>
                  <SimpleTable.TableDataButton
                    onClick={() => {
                      confirmAction(nextAction);
                    }}
                  >
                    Confirmar FUP
                  </SimpleTable.TableDataButton>
                </SimpleTable.TableData>
              </SimpleTable.TableRow>
            ) : null;
          })}
        </SimpleTable.TableBody>
      </SimpleTable.Table>
    </SimpleTable.Container>
  );
};
