import journeyTypeApi from '../apis/journeyTypeApi';

export default class JourneyTypeService {
  async createJourneyType(data) {
    return await journeyTypeApi.createJourneyType(data);
  }

  async updateJourneyType(journeyTypeId, data) {
    return await journeyTypeApi.updateJourneyType(journeyTypeId, data);
  }

  async getJourneyTypeById(journeyTypeId) {
    return (await journeyTypeApi.getJourneyTypeById(journeyTypeId)).data;
  }

  async listAllJourneyTypes(queryParams) {
    return (await journeyTypeApi.listAllJourneyTypes(queryParams)).data
      .journeyTypes;
  }
}
