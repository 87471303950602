import demandsApi from '../../../services/apis/demandsApi';
import { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import DemandGraph from './graph';
import { calculatePercentage, filterFinishedDemands, now, sortDemandsByMonthYear, twelveMonthsAgo } from './defaultFunctions';

export default function AveragePerCustomerOfDemandsFinished() {
  const [demandByMounth, setDemandByMounth] = useState([]);
  const [loading, setLoading] = useState(false);

  function groupDemandsByMonthAndCustomer(demands) {
    const result = [];
    for (const demand of demands) {
      const demandDate = moment(demand.updatedAt);
      const monthYear = demandDate.format('MMM YYYY');
      const customer = demand.customerId;

      if (demandDate.isBetween(twelveMonthsAgo, now)) {
        let group = result.find((g) => g.monthYear === monthYear && g.customerId === customer);

        if (!group) {
          group = { monthYear, customerId: customer, finishedCount: 0 };
          result.push(group);
        }
        group.finishedCount += demand.finishedCount;
      }
    }
    return result;
  }

  const groupDemandsByMonth = (demands) => {
    return demands.reduce((acc, demand) => {
      const monthYear = demand.monthYear;
      const group = acc.find((g) => g.monthYear === monthYear);
      if (!group) {
        acc.push({
          monthYear, 
          finishedCount: demand.finishedCount, 
          listDemand: [demand], 
          average: (Math.round(demand.finishedCount / 1)),
        });
      } else {
        group.finishedCount += demand.finishedCount;
        group.listDemand.push(demand);
        group.average = (Math.round((group.finishedCount) / (group.listDemand.length > 0 ? group.listDemand.length : 1) || 0));
      }
      return acc;
    }, []);
  }

  useEffect(() => {
    async function fetchDemands() {
      setLoading(true);
      const demands = (await demandsApi.getAllDemands()).data.demands?.filter(demand => !demand.isMassDemand);;
      const demandsFinished = filterFinishedDemands(demands);
      const demandsByCustomer = groupDemandsByMonthAndCustomer(demandsFinished);
      const groupDemands = groupDemandsByMonth(demandsByCustomer);
      const sortDemands = sortDemandsByMonthYear(groupDemands);
      setDemandByMounth(sortDemands);
      setLoading(false);
    }
    fetchDemands();
  }, []);

  const getProgressPercentage = useCallback(
    tooltipItems => {
      return `Em ${moment(tooltipItems[0].label, 'MMM').format('MMMM')}`;
    },
    []
  );

  return (
    <DemandGraph
      title="Demandas Finalizadas por cliente (ultimos 12 meses)"
      loading={loading}
      demand={demandByMounth}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            callbacks: {
              footer: getProgressPercentage,
            },
          },
        },
      }}
      data={{
        labels: [...new Set(demandByMounth?.map(demand => demand.monthYear))],
        datasets: [{
          label: 'Demandas Finalizadas por Cliente',
          backgroundColor: '#666666',
          data: demandByMounth?.map(demand => demand.average),
        }],
        percentages: calculatePercentage(demandByMounth),
      }}
    />
  );
}