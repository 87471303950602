import styled from 'styled-components';

export const Styles = {
  Container: styled.div`
    background-color: #fff;
    border-radius: 15px;
    padding: 30px 30px;
    width: 400px;

    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;

      & > div {
        margin-top: 10px;
      }
    }

    h1 {
      font-size: 20px;
      font-weight: bold;
      color: #232323;
      text-align: center;
    }

    p {
      font-size: 14px;
      font-weight: normal;
      color: #707070;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    .button-container {
      align-self: flex-end;
      margin-top: 20px;

      button {
        border: none;
        color: #fff;
        padding: 5px 10px;
        border-radius: 3px;
        background-color: #232323;
      }

      button[type='submit'] {
        background-color: #1ab394;
        margin-left: 10px;
      }
    }
  `,
};
