import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import MiniDrawer from '../../components/MiniDrawer';
import Table from '../../components/Table';
import history from '../../history';

const useStyles = makeStyles(theme => ({
  content: {
    height: '100vh',
    background: '#F5F6FA',
  },
  title: {
    fontSize: '35px',
  },
  table: {
    borderCollapse: 'collapse',
    margin: '25px 0',
    fontSize: '17px',
    width: '100%',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
  },
  tableHead: {
    backgroundColor: '#009879',
    color: '#ffffff',
    textAlign: 'left',
  },
  tableHeadTitleFirst: {
    borderRadius: '10px 0px 0px 0px',
    padding: '12px 15px',
  },
  tableHeadTitleLast: {
    borderRadius: '0px 10px 0px 0px',
    padding: '12px 15px',
  },
  tableHeadTitle: {
    padding: '12px 15px',
  },
  tableItem: {
    borderBottom: '1px solid #dddddd',
    '&:hover': {
      background: '#e0e0e0',
    },
  },
  tableItemColumn: {
    padding: '12px 15px',
    fontSize: '15px',
  },
  tableItemColumnStatus: {
    padding: '12px 15px',
    fontSize: '15px',
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'none',
  },
  action: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: '#000',
  },
  exportButton: {
    padding: '10px 15px',
    fontSize: '17px',
    background: '#1ab394',
    border: 'none',
    color: '#fff',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  selectSiz4Items: {},
}));

const headCells = [
  { id: 'name', numeric: false, label: 'Nome' },
  { id: 'email', numeric: false, label: 'E-mail' },
  { id: 'id', numeric: false, label: 'ID' },
];

const searchOptions = [
  {
    value: 'name',
    name: 'Nome',
  },
  {
    value: 'email',
    name: 'Email',
  },
];

export default function ListUser() {
  const classes = useStyles();

  function handleClickItem(id) {
    history.push(`/users/${id}/`);
  }

  async function handleRows(data) {
    return {
      rows: data.users,
      total: data.total,
    };
  }

  return (
    <React.Fragment>
      <MiniDrawer
        currentExpaded='users'
        main={
          <Grid container className={classes.main}>
            <Grid item className={classes.content} xs={12}>
              <Grid item xs={12}>
                <Table
                  title='Usuários'
                  headCells={headCells}
                  handleRows={handleRows}
                  handleClickItem={handleClickItem}
                  searchOptions={searchOptions}
                  route='users'
                  rolePrefix='USER'
                  canDelete={false}
                />
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </React.Fragment>
  );
}
