import React, { useRef, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from '../modal/Modal';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { Container, ContainerInput, TagsContainer } from './TagsModal.styles';
import { Constants } from '../../utils/Constants';
import TagInput, { TagComponent } from '../input/TagInput';
import tagsApi from '../../services/apis/tagsApi';
import Calendar from '../input/calendar/Calendar';
import CustomSelectWithSearch from '../input/customSelectorWithSearch/CustomSelectWithSearch';
import CustomTextArea from '../input/CustomTextArea';
import CustomInput from '../input/CustomInput';
import NumberFormat from 'react-number-format';
import { parseBRcurrencyToFloat } from '../../utils';

const AddOverdueCustomerTagModal = ({
  open,
  tag,
  handleClose,
  handleAddTag,
}) => {
  const addOverdueTagFormRef = useRef();
  const ticketRef = useRef();

  const _handleAddTag = async (data, { reset }) => {
    try {
      addOverdueTagFormRef.current.setErrors({});
      reset();
      const schema = Yup.object().shape({
        accessAllowedUntil: Yup.string().required(
          'Data até o último acesso a plataforma é necessário!'
        ),
        reason: Yup.number()
          .min(0)
          .max(7)
          .required('Motivo do churn é obrigatório!'),
        motivation: Yup.string().nullable(true),
        ticket: Yup.string().nullable(true),
      });

      const requestData = await schema.validate(data, {
        abortEarly: false,
      });
      requestData.ticket = parseBRcurrencyToFloat(requestData.ticket);
      await handleAddTag(tag.id, requestData);

      handleClose(true);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};

        error.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });

        return addOverdueTagFormRef.current.setErrors(validationErrors);
      }
      console.error(error);
    }
  };

  return (
    <Modal visible={open}>
      <Container
        style={{
          width: '450px',
        }}
      >
        <i className='fa fa-2x fa-times closeModal' onClick={handleClose} />

        <h1
          style={{
            marginBottom: '10px',
          }}
        >
          Cliente inadimplente
        </h1>

        <TagComponent
          name={tag?.name}
          description={tag?.description}
          disabled
        />

        <Form
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            marginTop: '30px',
          }}
          ref={addOverdueTagFormRef}
          onSubmit={_handleAddTag}
        >
          <ContainerInput>
            <Calendar
              label='Contrato do cliente é valido até'
              name='accessAllowedUntil'
              placeholder='Selecionar data'
              defaultValue={moment().add(1, 'month').toDate()}
            />
          </ContainerInput>

          <ContainerInput>
            <CustomSelectWithSearch
              name='reason'
              label='Qualificação do Motivo de Churn'
              options={Constants.DEFAULT_TAGS.CHURN_REASONS?.map(
                ({ label }, index) => ({
                  name: label,
                  value: index,
                })
              )}
            />
          </ContainerInput>

          <ContainerInput>
            <CustomTextArea
              name='motivation'
              label='Motivação'
              maxLength='2048'
              placeholder='Descrição da motivação do churn'
            />
          </ContainerInput>

          <ContainerInput>
            <NumberFormat
              thousandSeparator='.'
              decimalSeparator=','
              getInputRef={ticketRef}
              prefix={'R$'}
              decimalScale={2}
              fixedDecimalScale
              allowNegative={false}
              name='ticket'
              label='Ticket'
              customInput={CustomInput}
            />
          </ContainerInput>

          <button
            style={{
              marginTop: '30px',
              alignSelf: 'flex-end',
            }}
            type='submit'
          >
            Adicionar tag
          </button>
        </Form>
      </Container>
    </Modal>
  );
};

const AddSuspendedContractCustomerTagModal = ({
  open,
  tag,
  handleClose,
  handleAddTag,
}) => {
  const addFormRef = useRef();

  const _handleAddTag = async data => {
    try {
      addFormRef.current.setErrors({});

      const schema = Yup.object().shape({
        contractSuspedendUntil: Yup.date().required('Data necessária!'),
      });

      const schema2 = Yup.object().shape({
        contractSuspedend: Yup.date().required('Data necessária!'),
      })

      const requestData = await schema.validate(data, {
        abortEarly: false,
      });

      const requestDateStart = await schema2.validate(data, {
        abortEarly: false,
      })


      await handleAddTag(tag.id, requestData, requestDateStart);

      handleClose(true);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};

        error.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });

        return addFormRef.current.setErrors(validationErrors);
      }
      console.error(error);
    }
  };

  return (
    <Modal visible={open}>
      <Container
        style={{
          width: '450px',
        }}
      >
        <i className='fa fa-2x fa-times closeModal' onClick={handleClose} />

        <h1
          style={{
            marginBottom: '10px',
          }}
        >
          Contrato Suspenso
        </h1>

        <TagComponent
          name={tag?.name}
          description={tag?.description}
          disabled
        />

        <Form
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            marginTop: '30px',
          }}
          ref={addFormRef}
          onSubmit={_handleAddTag}
        >
          <div
            style={{
              width: '100%',
              position: 'relative',
            }}
          >
             <Calendar
              label='Contrato suspenso de'
              name='contractSuspedend'
              placeholder='Selecionar data'
              defaultValue={moment().add(1, 'month').toDate()}
            />

            <Calendar
              label='Contrato suspenso até'
              name='contractSuspedendUntil'
              placeholder='Selecionar data'
              defaultValue={moment().add(2, 'month').toDate()}
            />
          </div>

          <button
            style={{
              marginTop: '30px',
              alignSelf: 'flex-end',
            }}
            type='submit'
          >
            Adicionar tag
          </button>
        </Form>
      </Container>
    </Modal>
  );
};

function TagsModal({
  visible,
  actualTags = [],
  handleFetchTags,
  handleAddTag,
  handleRemoveTag,
  handleCloseTagsModal,
  ...rest
}) {
  const formRef = useRef(null);
  const [state, setState] = useState({
    tags: [],
    addOverdueCustomerTagModal: {
      open: false,
    },
    addSuspendedContractCustomerTagModal: {
      open: false,
    },
  });

  const fetchTags = useCallback(async () => {
    try {
      const tagsData = (await tagsApi.getAll())?.data;
      await handleFetchTags();

      setState(state => ({
        ...state,
        tags: tagsData.tags,
      }));
    } catch (error) {
      console.error(error);
    }
  }, [handleFetchTags]);

  useEffect(() => {
      fetchTags();
  }, [fetchTags]);

  const filterActualTags = tagsArray => {
    return tagsArray.filter(
      tag => !actualTags?.some(actualTag => actualTag.id === tag.id)
    );
  };

  return (
    <Modal visible={visible} {...rest}>
      <Container>
        <i
          className='fa fa-2x fa-times closeModal'
          onClick={handleCloseTagsModal}
        />

        <h1>Tags</h1>

        <h2>Tags atuais</h2>

        <TagsContainer>
          <Form ref={formRef}>
            {actualTags?.map(tag => (
              <TagInput
                key={tag.id}
                name={tag.name}
                description={tag.description}
                onClick={async () => {
                  await handleRemoveTag(tag.id);
                  fetchTags();
                }}
              />
            ))}
            {actualTags?.length === 0 && <span>Nenhuma tag por aqui</span>}
          </Form>
        </TagsContainer>

        <h2>Adicionar tags</h2>

        <TagsContainer>
          <Form ref={formRef}>
            {filterActualTags(state.tags).map(tag => (
              <TagInput
                key={tag.id}
                name={tag.name}
                description={tag.description}
                onClick={async () => {
                  if (
                    Constants.DEFAULT_TAGS.INACTIVE_CUSTOMERS_TAGS.includes(
                      tag.name
                    )
                  ) {
                    setState({
                      ...state,
                      addOverdueCustomerTagModal: {
                        open: true,
                        tag: tag,
                      },
                    });
                  } else if (
                    tag.name === Constants.DEFAULT_TAGS.SUSPENDED_CONTRACT
                  ) {
                    setState({
                      ...state,
                      addSuspendedContractCustomerTagModal: {
                        open: true,
                        tag: tag,
                      },
                    });
                  } else {
                    await handleAddTag(tag.id);
                  }
                  fetchTags();
                }}
              />
            ))}
            {filterActualTags(state.tags).length === 0 && (
              <span>Nenhuma tag disponível</span>
            )}
          </Form>
        </TagsContainer>
      </Container>

      <AddOverdueCustomerTagModal
        open={state.addOverdueCustomerTagModal.open}
        tag={state.addOverdueCustomerTagModal.tag}
        handleAddTag={handleAddTag}
        handleClose={success => {
          setState(state => ({
            ...state,
            addOverdueCustomerTagModal: {
              open: false,
            },
          }));
          if (success) fetchTags();
        }}
      />

      <AddSuspendedContractCustomerTagModal
        open={state.addSuspendedContractCustomerTagModal.open}
        tag={state.addSuspendedContractCustomerTagModal.tag}
        handleAddTag={handleAddTag}
        handleClose={success => {
          setState(state => ({
            ...state,
            addSuspendedContractCustomerTagModal: {
              open: false,
            },
          }));
          if (success) fetchTags();
        }}
      />
    </Modal>
  );
}

TagsModal.propTypes = {
  visible: PropTypes.bool,
  actualTags: PropTypes.array,
  handleFetchTags: PropTypes.func,
  handleAddTag: PropTypes.func,
  handleRemoveTag: PropTypes.func,
  handleCloseTagsModal: PropTypes.func,
};

export default TagsModal;
