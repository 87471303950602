import styled from 'styled-components';

export const Frame = styled.iframe`
  width: 100%;
  height: 80vh;
  border: none;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const Title = styled.h1`
  font-size: 30px;
  margin-bottom: 30px;
`;

export const CustomerListsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const CustomerListContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  padding: 0px;
  padding-right: 10px;
  margin-top: 20px;

  h3 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0px;
    height: 35px;

    span {
      font-weight: lighter;
      font-size: 11px;
    }
  }

  h2 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  :not(:last-child) {
    padding-bottom: 30px;
    border-bottom: 2px solid rgb(220, 226, 230);
  }
`;

export const SimpleTable = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: ${({ height }) => height || 'fit-content'};
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: 0 0 5px rgb(0, 0, 0, 0.5);
  `,
  Title: styled.h3`
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: bold;
  `,
  Table: styled.table`
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    white-space: nowrap;
  `,
  TableRow: styled.tr`
    background-color: #fff;

    :nth-child(even) {
      background-color: rgb(0, 0, 0, 0.1);
    }
  `,
  TableHead: styled.thead`
    background-color: #1994ff;
    position: sticky;
    top: 0;
    text-align: left;
    z-index: 1;

    tr {
      background-color: #2f4050;
      color: #121212;
      color: #fff;
    }
  `,
  TableBody: styled.tbody`
    tr {
      :hover {
        box-shadow: inset 0 0 3px rgb(0, 0, 0, 0.5);
      }
    }
  `,
  TableHeadCell: styled.th`
    :not(:first-child) {
      border-left: 1px solid #dddddd;
    }

    padding: 8px;
  `,
  TableData: styled.td`
    text-align: left;
    overflow: hidden;
    padding: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    *,
    a {
      text-decoration: none;
      color: inherit;
      cursor: pointer;
      display: block;
      padding: 8px;
      margin: -8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `,
  TableDataButton: styled.button`
    border: none;
    padding: 8px 14px;
    border-radius: 4px;
    font-size: 12px;
    margin-left: auto;
    margin-right: auto;
    transition: opacity 0.2s;

    :not(:disabled):hover {
      opacity: 0.9;
    }

    :disabled {
      cursor: not-allowed;
      background-color: grey;
      color: black;
    }
  `,
};

export const Section = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const SectionTitle = styled.h2`
  font-size: 20px;
  align-self: center;
  width: 100%;
  text-align: start;
  border-bottom: solid 1px rgb(0, 0, 0, 0.3);
  padding-bottom: 5px;
  margin-bottom: 10px;
`;

export const SectionContent = styled.div`
  display: flex;
  flex-flow: ${({ isRow }) => (isRow ? 'row wrap' : 'column wrap')};
  flex-wrap: 'wrap';
  width: 100%;
  justify-content: center;
  :not(:first-of-type) {
    margin-top: 50px;
  }
  margin-bottom: 60px;
`;

export const SectionItem = styled.div`
  width: 50%;
  height: 40vh;
  position: relative;
  resize: ${({ isResizable }) => (isResizable ? 'both' : 'none')};
  overflow: ${({ isResizable }) => (isResizable ? 'auto' : 'none')};
  padding: 1rem;
`;
