import React, { useState, useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import MiniDrawer from '../../components/MiniDrawer';
import { PageContainer } from '../../components/Pages.styles';
import JourneyTypeService from '../../services/journeys/journeyTypeService';
import JourneyTypeDefaultDemandService from '../../services/journeys/journeyTypeDefaultDemandService';
import { useParams } from 'react-router';
import { Form } from '@unform/web';
import CustomInput from '../../components/input/CustomInput';
import CustomTextArea from '../../components/input/CustomTextArea';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import history from '../../history';
import CustomSelectWithSearch from '../../components/input/customSelectorWithSearch/CustomSelectWithSearch';
import { useSelector } from 'react-redux';
import Modal from '../../components/modal/Modal';
import CustomSelect from '../../components/input/CustomSelect';
import { findNewIndexInIndexesList } from '../../utils';

const journeyTypeService = new JourneyTypeService();
const journeyTypeDefaultDemandService = new JourneyTypeDefaultDemandService();

const EditJourneyTypeDefaultDemandModal = ({
  defaultDemand,
  demandsIndexes,
  handleClose,
}) => {
  const updateDefaultDemandUnformRef = useRef(null);
  const demandTypes = useSelector(state => state.demandTypes.all);

  const _updateDefaultDemand = async data => {
    try {
      const schema = Yup.object().shape({
        title: Yup.string().required('O título é obrigatório'),
        description: Yup.string().required('A descrição é obrigatória'),
        demandTypeId: Yup.string()
          .nullable()
          .required('O tipo de demanda é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const updateData = {
        ...data,
        index: findNewIndexInIndexesList(
          defaultDemand.index,
          data.index,
          demandsIndexes
        ),
      };

      await journeyTypeDefaultDemandService.updateJourneyTypeDefaultDemand(
        defaultDemand.id,
        updateData
      );

      toast.success('Demanda padrão atualizada com sucesso!');

      handleClose(true);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};

        error.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });

        updateDefaultDemandUnformRef.current.setErrors(errorMessages);
      }
    }
  };

  return !!defaultDemand ? (
    <Modal visible={true}>
      <Grid
        container
        style={{
          backgroundColor: '#fff',
          padding: '10px',
          borderRadius: '5px',
          boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
          width: '70%',
        }}
      >
        <Grid container className='titleContainer'>
          <h1>Editar Demanda Padrão</h1>
        </Grid>

        <Grid container>
          <Form
            className='formContainer'
            ref={updateDefaultDemandUnformRef}
            onSubmit={_updateDefaultDemand}
          >
            <Grid container>
              <Grid item xs={12} className='inputContainer'>
                <CustomInput
                  name='title'
                  label='Título*'
                  defaultValue={defaultDemand.title}
                />
              </Grid>

              <Grid item xs={6} className='inputContainer'>
                {demandTypes && (
                  <CustomSelectWithSearch
                    placeholder='Tipo de demanda'
                    name='demandTypeId'
                    label='Tipo de demanda'
                    defaultValue={defaultDemand.demandTypeId}
                    options={demandTypes?.map(demandType => ({
                      name: demandType.name,
                      value: demandType.id,
                    }))}
                  />
                )}
              </Grid>

              <Grid item xs={6} className='inputContainer'>
                {demandsIndexes && (
                  <CustomSelect
                    placeholder='Posição'
                    name='index'
                    label='Posição da demanda'
                    defaultValue={defaultDemand.index}
                    options={demandsIndexes.map((index, fakeIndex) => ({
                      name: fakeIndex + 1,
                      value: index,
                    }))}
                  />
                )}
              </Grid>

              <Grid item xs={12} className='inputContainer'>
                <CustomTextArea
                  name='description'
                  label='Descrição*'
                  placeholder='Descrição da demanda'
                  defaultValue={defaultDemand.description}
                />
              </Grid>

              <Grid container justify='flex-end'>
                <button
                  type='button'
                  className='submit secondaryButton'
                  onClick={handleClose}
                  style={{ marginRight: '10px' }}
                >
                  Cancelar
                </button>

                <button type='submit' className='submit'>
                  Salvar Alterações
                </button>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </Modal>
  ) : null;
};

const CreateNewJourneyTypeDefaultDemandModal = ({
  open,
  journeyTypeId,
  handleClose,
}) => {
  const createDefaultDemandUnformRef = useRef(null);
  const demandTypes = useSelector(state => state.demandTypes.all);

  const _updateDefaultDemand = async data => {
    try {
      const schema = Yup.object().shape({
        title: Yup.string().required('O título é obrigatório'),
        description: Yup.string().required('A descrição é obrigatória'),
        demandTypeId: Yup.string()
          .nullable()
          .required('O tipo de demanda é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await journeyTypeDefaultDemandService.createJourneyTypeDefaultDemand({
        ...data,
        journeyTypeId,
      });

      toast.success('Demanda padrão criada com sucesso!');

      handleClose(true);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};

        error.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });

        createDefaultDemandUnformRef.current.setErrors(errorMessages);
      }
    }
  };

  return open ? (
    <Modal visible={true}>
      <Grid
        container
        style={{
          backgroundColor: '#fff',
          padding: '10px',
          borderRadius: '5px',
          boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
          width: '70%',
        }}
      >
        <Grid container className='titleContainer'>
          <h1>Criar Nova Demanda Padrão</h1>
        </Grid>

        <Grid container>
          <Form
            className='formContainer'
            ref={createDefaultDemandUnformRef}
            onSubmit={_updateDefaultDemand}
          >
            <Grid container>
              <Grid item xs={6} className='inputContainer'>
                <CustomInput name='title' label='Título*' />
              </Grid>

              <Grid item xs={6} className='inputContainer'>
                {demandTypes && (
                  <CustomSelectWithSearch
                    placeholder='Tipo de demanda'
                    name='demandTypeId'
                    label='Tipo de demanda'
                    options={demandTypes?.map(demandType => ({
                      name: demandType.name,
                      value: demandType.id,
                    }))}
                  />
                )}
              </Grid>

              <Grid item xs={12} className='inputContainer'>
                <CustomTextArea
                  name='description'
                  label='Descrição*'
                  placeholder='Descrição da demanda'
                />
              </Grid>

              <Grid container justify='flex-end'>
                <button
                  type='button'
                  className='submit secondaryButton'
                  onClick={handleClose}
                  style={{ marginRight: '10px' }}
                >
                  Cancelar
                </button>

                <button type='submit' className='submit'>
                  Criar Nova Demanda Padrão
                </button>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </Modal>
  ) : null;
};

export default function EditJourneyType() {
  const updateJourneyTypeUnformRef = useRef(null);
  const { journeyTypeId } = useParams();
  const [state, setState] = useState({
    journeyType: {
      id: '',
      title: '',
      description: '',
      defaultDemands: [],
    },
    isEditigDefaultDemand: false,
    isCreatingDefaultDemand: false,
  });

  const _fetchJourneyTypeData = async journeyTypeId => {
    try {
      const journeyType = await journeyTypeService.getJourneyTypeById(
        journeyTypeId
      );

      setState(prevState => ({
        ...prevState,
        journeyType,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const _updateJourneyTypeData = async data => {
    try {
      const schema = Yup.object().shape({
        title: Yup.string().required('O título é obrigatório'),
        description: Yup.string().required('A descrição é obrigatória'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await journeyTypeService.updateJourneyType(journeyTypeId, data);

      toast.success('Tipo de jornada atualizado com sucesso!');

      history.push(`/journeyTypes/${journeyTypeId}`);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};

        error.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });

        updateJourneyTypeUnformRef.current.setErrors(errorMessages);
      }
    }
  };

  const _handleEditDefaultDemand = defaultDemand => {
    setState(prevState => ({
      ...prevState,
      isEditigDefaultDemand: defaultDemand,
    }));
  };

  const _handleCloseDefaultDemandModal = isUpdated => {
    setState(prevState => ({
      ...prevState,
      isEditigDefaultDemand: false,
    }));

    if (isUpdated) {
      _fetchJourneyTypeData(journeyTypeId);
    }
  };

  const _handleDeleteDefaultDemand = async defaultDemandId => {
    try {
      await journeyTypeDefaultDemandService.deleteJourneyTypeDefaultDemandById(
        defaultDemandId
      );

      toast.success('Demanda padrão excluída com sucesso!');

      _fetchJourneyTypeData(journeyTypeId);
    } catch (error) {
      console.error(error);
    }
  };

  const _handleOpenCreateDefaultDemandModal = () => {
    setState(prevState => ({
      ...prevState,
      isCreatingDefaultDemand: true,
    }));
  };

  const _handleCloseCreateDefaultDemandModal = isCreated => {
    setState(prevState => ({
      ...prevState,
      isCreatingDefaultDemand: false,
    }));

    if (isCreated) {
      _fetchJourneyTypeData(journeyTypeId);
    }
  };

  useEffect(() => {
    if (journeyTypeId) _fetchJourneyTypeData(journeyTypeId);
  }, [journeyTypeId]);

  return (
    <React.Fragment>
      <MiniDrawer currentExpaded='journeys'>
        <PageContainer>
          <Grid container>
            <Grid container className='titleContainer'>
              <h1>Editar Tipo de Jornada</h1>
            </Grid>

            <Grid container>
              <Form
                className='formContainer'
                ref={updateJourneyTypeUnformRef}
                onSubmit={_updateJourneyTypeData}
              >
                <Grid container>
                  <Grid item xs={12} className='inputContainer'>
                    <CustomInput
                      name='title'
                      label='Título da jornada*'
                      defaultValue={state.journeyType.title}
                    />
                  </Grid>

                  <Grid item xs={12} className='inputContainer'>
                    <CustomTextArea
                      name='description'
                      label='Descrição da jornada*'
                      placeholder='Descrição da jornada'
                      defaultValue={state.journeyType.description}
                    />
                  </Grid>

                  <Grid container justify='flex-end'>
                    <button type='submit' className='submit'>
                      Salvar Alterações
                    </button>
                  </Grid>
                </Grid>
              </Form>

              <Grid container className='titleContainer'>
                <h1>Editar Demandas Padrão</h1>
              </Grid>

              <p>
                Essas demandas abaixo, serão as demandas sugeridas na hora de
                criar uma jornada para o cliente, você pode altear a ordem das
                demandas clicando em "Editar"
              </p>

              {state.journeyType.defaultDemands.map(defaultDemand => (
                <React.Fragment key={defaultDemand.id}>
                  <Grid item xs={3} className='inputContainer'>
                    <p>
                      <strong>Título:</strong>
                    </p>
                    <span>{defaultDemand.title}</span>
                  </Grid>

                  <Grid item xs={7} className='inputContainer'>
                    <p>
                      <strong>Descrição:</strong>
                    </p>
                    <span>{defaultDemand.description}</span>
                  </Grid>

                  <Grid item xs={2} className='inputContainer'>
                    <button
                      type='button'
                      className='submit'
                      style={{
                        backgroundColor: '#2f4050',
                      }}
                      onClick={() => _handleEditDefaultDemand(defaultDemand)}
                    >
                      Editar
                    </button>

                    <button
                      type='button'
                      className='submit'
                      style={{
                        marginLeft: '10px',
                        backgroundColor: '#f44336',
                      }}
                      onClick={() =>
                        _handleDeleteDefaultDemand(defaultDemand.id)
                      }
                    >
                      Remover
                    </button>
                  </Grid>
                </React.Fragment>
              ))}

              <Grid container justify='center'>
                <button
                  type='button'
                  className='submit secondaryButton'
                  onClick={_handleOpenCreateDefaultDemandModal}
                >
                  Adicionar Nova Demanda Padrão
                </button>
              </Grid>
            </Grid>
          </Grid>

          <EditJourneyTypeDefaultDemandModal
            defaultDemand={state.isEditigDefaultDemand}
            demandsIndexes={state.journeyType.defaultDemands?.map(
              ({ index }) => index
            )}
            handleClose={_handleCloseDefaultDemandModal}
          />

          <CreateNewJourneyTypeDefaultDemandModal
            journeyTypeId={journeyTypeId}
            open={state.isCreatingDefaultDemand}
            handleClose={_handleCloseCreateDefaultDemandModal}
          />
        </PageContainer>
      </MiniDrawer>
    </React.Fragment>
  );
}
