import React, { useEffect, useRef, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from '@unform/web';
import FileInputMult from '../../components/input/FileInputMult';
import MiniDrawer from '../../components/MiniDrawer';
import CustomerJourneyService from '../../services/journeys/customerJourneyService';
import DemandService from '../../services/demandService';
import { Constants } from '../../utils/Constants';
import FileService from '../../services/fileService';

const fileService = new FileService();

const useStyles = makeStyles(() => ({
  main: {
    background: '#FFF',
  },
  mainTitle: {
    background: '#FFF',
    borderTop: 'solid 2px #e7eaec',
    borderBottom: 'solid 1px #e7eaec',
    padding: '15px',
  },
  line: {
    backgorund: 'reds',
  },
  title: {
    fontSize: '15px',
  },
  mainForm: {
    padding: '10px',
  },
  form: {
    width: '100%',
  },
  input: {
    padding: '10px',
  },
  buttonContainer: {
    justifyContent: 'flex-end',
    padding: '10px',
  },
  submit: {
    padding: '10px 15px',
    fontSize: '17px',
    background: '#1ab394',
    border: 'none',
    color: '#fff',
    borderRadius: '5px',
  },
  subTitle: {
    background: '#FFF',
    borderBottom: 'solid 1px #e7eaec',
  },
}));

const copyTextToClipboard = (text) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.position = 'fixed';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
        document.execCommand('copy');
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
    }
    document.body.removeChild(textArea);
};




export default function CreateDemand() {

  const classes = useStyles();
  const formRef = useRef(null);


  const [submitLoading, setSubmitLoading] = useState(false);



  async function handleSubmit(data) {
    setSubmitLoading(true);
    try {
        const formatedAttachments =
          await fileService.uploadFilesAndFormatToDocumentStructure({
            files: data.attachments,
            documentType: Constants.FILE_TYPES.DOCUMENT,
            publicRead: true,
          });
    
        const {signedUrl} = await fileService.getSignedDownloadFileUrlByAwsKey(formatedAttachments[0].awsFileKey);
        copyTextToClipboard(signedUrl.split('?')[0].replace('ndm-prod', 'ndm-prod-public'));
        
    } catch (error) {
        alert('Erro ao criar link');
    } finally {
        setSubmitLoading(false);
        alert('Url copiada para a área de transferência');
    }
  }

  return (
    <React.Fragment>
      <MiniDrawer
        currentExpaded='demands'
        main={
          <Grid container className={classes.main}>
            
            
              <>
                <Grid container className={classes.mainTitle}>
                  <h2 className={classes.title}>Criar link de arquivo</h2>
                </Grid>
                <Grid container className={classes.mainForm}>
                  <Form
                    ref={formRef}
                    onSubmit={handleSubmit}
                    className={classes.form}
                  >
                    <Grid container>


                      <Grid item={true} className={classes.input} xs={12}>
                        <FileInputMult name='attachments' label='Anexos' />
                      </Grid>

                      <Grid container className={classes.buttonContainer}>
                        <button className={classes.submit} type='submit'>
                          {!submitLoading && 'Gerar link'}
                          {submitLoading && (
                            <CircularProgress color='inherit' />
                          )}
                        </button>
                      </Grid>
                    </Grid>
                  </Form>
                </Grid>
              </>
            
          </Grid>
        }
      />
    </React.Fragment>
  );
}
