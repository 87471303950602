import React, { useState, useEffect } from 'react';
import MiniDrawer from '../../components/MiniDrawer';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import apiOld from '../../services/Api';
import { useParams } from 'react-router';
import { useCallback } from 'react';
import { formatDateHour, formatDateWithMonthName } from '../../utils';
import { Container } from './viewLogs.styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  main: {
    background: '#FFF',
  },
  mainTitle: {
    background: '#FFF',
    borderTop: 'solid 2px #e7eaec',
    borderBottom: 'solid 1px #e7eaec',
    padding: '15px',
    justifyContent: 'space-between',
  },
  line: {
    backgorund: 'reds',
  },
  title: {
    fontSize: '15px',
  },
  mainForm: {
    padding: '10px',
  },
  form: {
    width: '100%',
  },
  input: {
    padding: '10px',
  },
  buttonContainer: {
    justifyContent: 'flex-end',
    padding: '10px',
  },
  editButton: {
    padding: '10px 10px',
    fontSize: '15px',
    background: '#1ab394',
    border: 'none',
    color: '#fff',
    borderRadius: '4px',
    textDecoration: 'none',
  },
  submit: {
    padding: '10px 15px',
    fontSize: '17px',
    background: '#1ab394',
    border: 'none',
    color: '#fff',
    borderRadius: '5px',
  },
  subTitle: {
    background: '#FFF',
    borderBottom: 'solid 1px #e7eaec',
  },
}));

export default function ViewLog() {
  const classes = useStyles();
  const [log, setLog] = useState(null);
  const { id } = useParams();

  const fetchDAta = useCallback(async () => {
    try {
      const response = await apiOld.get('/logs/' + id);
      const responseData = response.data;

      setLog({
        ...responseData,
        body: JSON.parse(responseData.body),
        headers: JSON.parse(responseData.headers),
      });
    } catch (error) {
      console.error(error);
    }
  }, [id]);

  useEffect(() => {
    fetchDAta();
  }, [fetchDAta]);

  const formatJsonToHtml = json => {
    if (typeof json !== 'object' || json === null) return '';

    return Object.entries(json).map(([key, value]) => {
      return (
        <li>
          <span className='key'>{key.toString()}: </span>

          {typeof value === 'object' && value !== null ? (
            <ul>{formatJsonToHtml(value)}</ul>
          ) : (
            <span className='value'>{String(value)}</span>
          )}
        </li>
      );
    });
  };

  return (
    <React.Fragment>
      <MiniDrawer
        main={
          log && (
            <Container>
              <Grid container className={classes.main}>
                <Grid container className={classes.mainTitle}>
                  <h2 className={classes.title}>Log perfil</h2>
                </Grid>
                <Grid container className={classes.mainForm}>
                  <Grid container>
                    <Grid item className={classes.input} xs={12}>
                      <h2 className={classes.subTitle}>Informações</h2>
                    </Grid>

                    <Grid item className={classes.input} xs={10}>
                      <label>Tipo</label>

                      <p>{log.type}</p>
                    </Grid>

                    <Grid item className={classes.input} xs={10}>
                      <label>Usuário</label>

                      <p>
                        <Link to={`/users/${log.userId}`}>{log.userId}</Link>
                      </p>
                    </Grid>

                    <Grid item className={classes.input} xs={10}>
                      <label>Tipo de usuário</label>

                      <p>{log.userType || '--'}</p>
                    </Grid>

                    <Grid item className={classes.input} xs={10}>
                      <label>Endereço de ip</label>

                      <p>{log.ip}</p>
                    </Grid>

                    <Grid item className={classes.input} xs={10}>
                      <label>Log criado em</label>

                      <p>
                        {formatDateWithMonthName(log.createdAt)} as{' '}
                        {formatDateHour(log.createdAt)}
                      </p>
                    </Grid>

                    <Grid item className={classes.input} xs={10}>
                      <label>Headers</label>

                      <ul>{formatJsonToHtml(log.headers)}</ul>
                    </Grid>

                    <Grid item className={classes.input} xs={10}>
                      <label>Body</label>

                      <ul>{formatJsonToHtml(log.body)}</ul>
                    </Grid>

                    <Grid item className={classes.input} xs={10}>
                      <label>Error</label>

                      <ul>{formatJsonToHtml(log.error) || 'False'}</ul>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          )
        }
      />
    </React.Fragment>
  );
}
