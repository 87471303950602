import React, { useState, useRef, useEffect } from "react";
import CustomInput from "../../components/input/CustomInput";
import MiniDrawer from "../../components/MiniDrawer";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Form } from "@unform/web";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomRadio from "../../components/input/CustomRadio";
import * as Yup from "yup";
import apiOld from "../../services/Api";
import CEPService from "../../services/CEPService";
import Backdrop from "@material-ui/core/Backdrop";
import { handleAndRemoveTemplateFields } from "../../services/Utils";
import history from "../../history";
import InputMask from "react-input-mask";
import CustomSelect from "../../components/input/CustomSelect";
import CustomSelectWithSearch from "../../components/input/customSelectorWithSearch/CustomSelectWithSearch";
import { Constants } from "../../utils/Constants";
import { useQuery } from "../../routes";
import { useCallback } from "react";
import { toast } from "react-toastify";

const useStyles = makeStyles(() => ({
  main: {
    background: "#FFF",
  },
  mainTitle: {
    background: "#FFF",
    borderTop: "solid 2px #e7eaec",
    borderBottom: "solid 1px #e7eaec",
    padding: "15px",
  },
  line: {
    backgorund: "reds",
  },
  title: {
    fontSize: "15px",
  },
  mainForm: {
    padding: "10px",
  },
  form: {
    width: "100%",
  },
  input: {
    padding: "10px",
  },
  buttonContainer: {
    justifyContent: "flex-end",
    padding: "10px",
  },
  submit: {
    padding: "10px 15px",
    fontSize: "17px",
    background: "#1ab394",
    border: "none",
    color: "#fff",
    borderRadius: "5px",
  },
  subTitle: {
    background: "#FFF",
    borderBottom: "solid 1px #e7eaec",
  },
}));

function Third(props) {
  const { formRef } = props;
  const classes = useStyles();
  const [personType, setPersonType] = useState("PF");

  const handleCEPChange = () => {
    const cep = formRef.current.getFieldValue("cep").replace(/\s/g, "");
    if (cep.length === 9) {
      findCEP(cep);
    }
  };

  const findCEP = async (cep) => {
    const response = await CEPService.get(`/${cep}/json`);
    formRef.current.setFieldValue("logradouro", response.data.logradouro);
    formRef.current.setFieldValue("bairro", response.data.bairro);
    formRef.current.setFieldValue("complemento", response.data.complemento);
    formRef.current.setFieldValue("cidade", response.data.localidade);
    formRef.current.setFieldValue("estado", response.data.uf);
  };

  const handlePersonTypeChange = () => {
    const personType = formRef.current.getFieldValue("personType");
    setPersonType(personType);
  };

  return (
    <Grid container>
      <Grid item className={classes.input} xs={12}>
        <h2 className={classes.subTitle}>Terceiro</h2>
      </Grid>
      <Grid item className={classes.input} xs={12}>
        <h4>Tipo</h4>
        <CustomRadio
          name="personType"
          defaultValue="PF"
          onChange={handlePersonTypeChange}
          options={Constants.PERSON_TYPES}
        />
      </Grid>
      <Grid item className={classes.input} xs={12}>
        <h2 className={classes.subTitle}>Informações Básicas</h2>
      </Grid>
      <Grid item className={classes.input} xs={12}>
        <CustomInput name="nome do terceiro" label="Nome" />
      </Grid>
      {personType === "PF" && (
        <>
          <Grid item className={classes.input} xs={6}>
            <CustomInput name="rg" label="RG" />
          </Grid>
          <Grid item className={classes.input} xs={6}>
            <CustomInput name="cnh" label="CNH" />
          </Grid>
          <Grid item className={classes.input} xs={6}>
            <InputMask mask="999.999.999-99" maskChar=" " disabled={false}>
              {() => <CustomInput name="cpf" label="CPF*" autoComplete="cpf" />}
            </InputMask>
          </Grid>
          <Grid item className={classes.input} xs={6}>
            <CustomSelect
              name="sexo"
              label="Sexo*"
              autoComplete="sex"
              options={Constants.SEX_TYPES}
            />
          </Grid>
          <Grid item className={classes.input} xs={6}>
            <CustomInput name="nacionalidade" label="Nacionalidade" />
          </Grid>
          <Grid item className={classes.input} xs={6}>
            <CustomSelect
              name="estado civil"
              label="Estado Civil*"
              options={Constants.CIVIL_STATUS}
            />
          </Grid>
          <Grid item className={classes.input} xs={6}>
            <CustomInput name="profissão" label="Profissão" />
          </Grid>
          <Grid item className={classes.input} xs={6}>
            <InputMask mask="99/99/9999" maskChar=" ">
              {() => (
                <CustomInput
                  name="data de nascimento"
                  label="Nascimento*"
                  autoComplete="bday"
                />
              )}
            </InputMask>
          </Grid>
        </>
      )}
      {personType === "PJ" && (
        <>
          <Grid item className={classes.input} xs={12}>
            <CustomInput name="razão social" label="Razão Social" />
          </Grid>
          <Grid item className={classes.input} xs={6}>
            <InputMask mask="99.999.999/9999-99" maskChar=" " disabled={false}>
              {() => <CustomInput name="cnpj" label="CNPJ*" />}
            </InputMask>
          </Grid>
          <Grid item className={classes.input} xs={6}>
            <CustomSelect
              name="tipo de sociedade"
              label="Tipo de Sociedade*"
              options={Constants.SOCIETY_TYPES}
            />
          </Grid>
        </>
      )}
      <Grid item className={classes.input} xs={12}>
        <h2 className={classes.subTitle}>Endereço</h2>
      </Grid>

      <Grid item className={classes.input} xs={3}>
        <InputMask
          mask="99999-999"
          maskChar=" "
          onChange={handleCEPChange}
          disabled={false}
        >
          {() => <CustomInput name="cep" label="CEP" />}
        </InputMask>
      </Grid>

      <Grid item className={classes.input} xs={9}>
        <CustomInput name="logradouro" label="Logradouro" />
      </Grid>
      <Grid item className={classes.input} xs={9}>
        <CustomInput name="complemento" label="Complemento" />
      </Grid>
      <Grid item className={classes.input} xs={3}>
        <CustomInput name="número" label="Número" />
      </Grid>
      <Grid item className={classes.input} xs={6}>
        <CustomInput name="bairro" label="Bairro" />
      </Grid>
      <Grid item className={classes.input} xs={6}>
        <CustomInput name="cidade" label="Cidade" />
      </Grid>
      <Grid item className={classes.input} xs={12}>
        <CustomInput name="estado" label="Estado" />
      </Grid>
      {personType === "PJ" && (
        <>
          <Grid item className={classes.input} xs={12}>
            <h2 className={classes.subTitle}>Representante Legal</h2>
          </Grid>
          <Grid item className={classes.input} xs={12}>
            <CustomInput
              name="nome do representante legal"
              label="Nome do Representante Legal"
            />
          </Grid>
          <Grid item className={classes.input} xs={6}>
            <InputMask mask="999.999.999-99" maskChar=" " disabled={false}>
              {() => (
                <CustomInput
                  name="cpf do representante legal"
                  label=" CPF do Representante Legal*"
                />
              )}
            </InputMask>
          </Grid>
        </>
      )}
      <Grid item className={classes.input} xs={12}>
        <h2 className={classes.subTitle}>Informações de Contato</h2>
      </Grid>
      <Grid item className={classes.input} xs={6}>
        <CustomInput name="email" label="E-mail" />
      </Grid>
      <Grid item className={classes.input} xs={6}>
        <InputMask mask="(99)99999-9999" maskChar=" " disabled={false}>
          {() => <CustomInput name="telefone" label="Telefone" />}
        </InputMask>
      </Grid>
    </Grid>
  );
}

export default function CreateDocument() {
  const classes = useStyles();
  const formRef = useRef(null);
  const query = useQuery();
  const demandId = query.get("documentDemandId");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [content, setContent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const [hiredType, setHiredType] = useState(null);
  const [contractorType, setContractorType] = useState(null);
  const [templateType, setTemplateType] = useState(null);

  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      const responseTemplate = await apiOld.get("/templates");

      if (demandId) {
        const responseFormAnswers = await apiOld.get("/forms-answers/", {
          params: {
            demandId: demandId,
          },
        });

        let formAnswersFields = [];

        responseFormAnswers.data.formAnswers.forEach((formAnswer) => {
          formAnswersFields = formAnswersFields.concat(formAnswer.fields);
        });

        setContent({
          templates: responseTemplate.data.templates,
          formAnswersFields: formAnswersFields,
        });
      } else {
        const responseCustomer = await apiOld.get("/customers");

        setContent({
          templates: responseTemplate.data.templates,
          customers: responseCustomer.data.customers,
        });
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [demandId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  async function handleSubmit(data) {
    setSubmitLoading(true);

    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        nome: Yup.string().required(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const requestFields = {};
      fields.forEach((field) => {
        requestFields[field.key] = data[field.key];
      });

      const template = content.templates.find(
        (template) => template.id === data.template
      );

      const request = {
        templateType: templateType,
        customerId: data.cliente,
        demandId: demandId,
        name: data.nome,
        templateKey: template.fileKey,
        templateName: template.name,
        templateId: data.template,
        contractorType: data.contractorType,
        hiredType: data.hiredType,
        fields: requestFields,
        third:
          data.contractorType === "third" || data.hiredType === "third"
            ? {
              email: data.email,
              phone: data.telefone,
              name: data["nome do terceiro"],
              rg: data.rg,
              cnh: data.cnh,
              cpf: data.cpf,
              sex: data.sexo,
              nationality: data.nacionalidade,
              civilState: data["estado civil"],
              profession: data["profissão"],
              birthDate: data["data de nascimento"],
              socialReason: data["razão social"],
              cnpj: data.cnpj,
              typeCompany: data["tipo de sociedade"],
              cep: data.cep,
              address: data.logradouro,
              addressComplement: data.complemento,
              addressNumber: data["número"],
              addressDistrict: data.baifieldsrro,
              addressCity: data.cidade,
              addressState: data.estado,
              legalRepresentativeName: data["nome do representante legal"],
              legalRepresentativeCpf: data["cpf do representante legal"],
            }
            : null,
      };

      const response = await apiOld.post("/documents", request);

      toast.success("Documento criado com sucesso!");

      history.push(`/documents/${response.data.id}`);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};

        error.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);

        return;
      }

      console.error(error);
    } finally {
      setSubmitLoading(false);
    }
  }

  const handleTemplateChange = (templateId) => {
    const template = content.templates.find(
      (template) => template.id === templateId
    );

    const fields = template ? (template.fields ? template.fields : null) : null;

    if (demandId)
      setFields(
        handleAndRemoveTemplateFields(fields, content.formAnswersFields)
      );
    else setFields(handleAndRemoveTemplateFields(fields));
    setTemplateType(template.type);
  };

  const handleHiredChange = () => {
    const hiredType = formRef.current.getFieldValue("hiredType");
    const contractorType = formRef.current.getFieldValue("contractorType");
    setHiredType(hiredType);

    if (hiredType === contractorType) {
      formRef.current.setFieldValue("contractorType", null);
      setContractorType(null);
    }
  };

  const handleContractorChange = () => {
    const hiredType = formRef.current.getFieldValue("hiredType");
    const contractorType = formRef.current.getFieldValue("contractorType");
    setContractorType(contractorType);

    if (hiredType === contractorType) {
      formRef.current.setFieldValue("hiredType", null);
      setHiredType(null);
    }
  };
  const contractOptions = [
    { id: "ndm", value: "NDM", label: "NDM" },
    { id: "customer", value: "Customer", label: "Cliente" },
    { id: "third", value: "Third", label: "Terceiro" },
  ];

  return (
    <React.Fragment>
      <MiniDrawer
        currentExpaded="documents"
        main={
          <Grid container className={classes.main}>
            <Backdrop className={classes.backdrop} open={loading}>
              <CircularProgress />
            </Backdrop>
            {content && (
              <>
                <Grid container className={classes.mainTitle}>
                  <h2 className={classes.title}>Criar Documento</h2>
                </Grid>
                <Grid container className={classes.mainForm}>
                  <Form
                    ref={formRef}
                    onSubmit={handleSubmit}
                    className={classes.form}
                  >
                    <Grid container>
                      <Grid item className={classes.input} xs={12}>
                        <CustomInput name="nome" label="Nome" />
                      </Grid>
                      {!demandId && (
                        <Grid item className={classes.input} xs={6}>
                          {content.customers && (
                            <CustomSelectWithSearch
                              placeholder="Escolha um cliente"
                              name="cliente"
                              label="Cliente*"
                              options={content?.customers?.map((customer) => ({
                                name: customer.name,
                                value: customer.id,
                              }))}
                            />
                          )}
                        </Grid>
                      )}
                      <Grid item className={classes.input} xs={6}>
                        <CustomSelectWithSearch
                          placeholder="Escolha um template"
                          name="template"
                          label="Template*"
                          onChange={handleTemplateChange}
                          options={content.templates.map((template) => ({
                            name: template.name,
                            value: template.id,
                          }))}
                        />
                      </Grid>
                      {templateType === "CONTRACTOR_HIRED" && (
                        <>
                          <Grid item className={classes.input} xs={12}>
                            <h2 className={classes.subTitle}>Contratante</h2>
                          </Grid>
                          <Grid item className={classes.input} xs={12}>
                            <CustomRadio
                              name="contractorType"
                              direction="column"
                              onChange={handleContractorChange}
                              options={contractOptions}
                            />
                          </Grid>
                          {contractorType === "third" && (
                            <Third formRef={formRef} />
                          )}
                          <Grid item className={classes.input} xs={12}>
                            <h2 className={classes.subTitle}>Contratada</h2>
                          </Grid>
                          <Grid item className={classes.input} xs={12}>
                            <CustomRadio
                              name="hiredType"
                              direction="column"
                              onChange={handleHiredChange}
                              options={contractOptions}
                            />
                          </Grid>
                          {hiredType === "third" && <Third formRef={formRef} />}
                        </>
                      )}
                      {fields && (
                        <>
                          <Grid item className={classes.input} xs={12}>
                            <h2 className={classes.subTitle}>
                              Campos do Template
                            </h2>
                          </Grid>
                          {fields.map((field) => (
                            <Grid item className={classes.input} xs={12}>
                              <CustomInput
                                name={field.key}
                                label={field.label}
                              />
                            </Grid>
                          ))}
                        </>
                      )}
                      <Grid container className={classes.buttonContainer}>
                        <button className={classes.submit} type="submit">
                          {!submitLoading && "Cadastrar"}
                          {submitLoading && (
                            <CircularProgress color="inherit" />
                          )}
                        </button>
                      </Grid>
                    </Grid>
                  </Form>
                </Grid>
              </>
            )}
          </Grid>
        }
      />
    </React.Fragment>
  );
}
