const setDraft = (type, demandId, draft) => {
  return {
    type: type,
    payload: {
      demandId: demandId,
      draft: draft
    }
  }
}

const deleteDraft = (type, demandId) => {
  return {
    type: type,
    payload: {
      demandId: demandId,
    }
  }
}

export const setDraftMessage = (demandId, draft) => {
  return setDraft('SET_DRAFT_MESSAGE', demandId, draft);
}

export const setDraftStatus = (demandId, draft) => {
  return setDraft('SET_DRAFT_STATUS', demandId, draft);
}

export const setDraftFinish = (demandId, draft) => {
  return setDraft('SET_DRAFT_FINISH', demandId, draft);
}

export const deleteAllDraftByDemandId = (demandId) => {
  return deleteDraft('DELETE_DRAFT', demandId);
}