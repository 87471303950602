import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Container,
  ColumnContainer,
} from '../../../components/demandsBoard/DemandsBoard.styles';
import Column from '../../../components/demandsBoard/column/Column';
import MiniDrawer from '../../../components/MiniDrawer';
import { Constants } from '../../../utils/Constants';
import demandsApi from '../../../services/apis/demandsApi';
import apiOld from '../../../services/Api';
import usersApi from '../../../services/apis/usersApi';
import Select from 'react-select'

export default function DemandsBoard() {
  const [options, setOptions] = useState([
    {
      value: null,
      label: null,
    },
  ]);

  const authUser = useSelector(state => state.auth.user);
  const [state, setState] = React.useState({
    columns: [],
    loading: true,
    demands: [],
  });

  const [columns, setColumns] = useState([])
  const [demands_, setDemands_] = useState([])

  const _fetchDemandsData = async authUser => {
    const demands = (await demandsApi.getDemandsPanel()).data.demands;
    const backofficeUsers = (await usersApi.getAllBackofficeUsers()).data.users;
    const columns = [
      {
        title: 'Pré-triagem',
        columnStatus: Constants.DEMAND_STATUSES.OPEN.key,
        cards: demands.filter(
          demand => demand.statusKey === Constants.DEMAND_STATUSES.OPEN.key
        ),
      },
      {
        title: authUser.name,
        backofficeUserId: authUser.id,
        cards: demands.filter(
          demand =>
            demand.backofficeUserId === authUser.id &&
            (demand.statusKey === Constants.DEMAND_STATUSES.IN_PROGRESS.key ||
              demand.statusKey === Constants.DEMAND_STATUSES.OPEN.key)
        ),
        authUser: true,
      },
      ...backofficeUsers
        .filter(user => user.id !== authUser.id)
        .map(user => ({
          title: user.name,
          backofficeUserId: user.id,
          cards: demands.filter(
            demand =>
              demand.backofficeUserId === user.id &&
              demand.statusKey !== Constants.DEMAND_STATUSES.PENDING.key &&
              ![
                Constants.DEMAND_TYPES.ACTIVE_CONTACT_MEETING.key,
                Constants.DEMAND_TYPES.ENGAGEMENT_MEETING.key,
                Constants.DEMAND_TYPES.ONBOARDING_MEETING.key,
                Constants.DEMAND_TYPES.CHURN_MEETING.key,
              ].includes(demand.demandTypeName)
          ),
        }))
        .sort((a, b) => a.title.localeCompare(b.title)),
    ];
    const demandsWithoutBackofficeUser = demands?.filter(
      demand =>
        !demand.backofficeUserId &&
        (demand.statusKey === Constants.DEMAND_STATUSES.PENDING.key ||
          demand.statusKey === Constants.DEMAND_STATUSES.IN_PROGRESS.key)
    );

    if (demandsWithoutBackofficeUser?.length) {
      columns.splice(2, 0, {
        title: 'Sem responsável',
        cards: demandsWithoutBackofficeUser,
      });
    }

    setState({
      columns,
      demands,
      loading: false,
    });

  };

  const loadLawyer = async () => {
    try {

      const response = await apiOld.get('/demands');
      const lawyer = response.data.demands

      const _customInput = lawyer.map(data => ({
        label: data.backofficeUserName,
        value: data.backofficeUserId
      }))

      const parsed_array = _customInput.map(val => { return JSON.stringify(val) })
      const filtered_array = parsed_array.filter((value, ind) => parsed_array.indexOf(value) === ind).map((val) => { return JSON.parse(val) })

      filtered_array.forEach(data => {
        setOptions(options => [
          ...options,
          {
            value: data.value,
            label: data.label
          },
        ]);
      })

    } catch (error) {
      console.error(error);
    }
  }

  const filterDemands = async (value) => {
    const response = (await apiOld.get('/demands', {
      params: {
        backofficeUserId: value.value,
        statusKey: Constants.DEMAND_STATUSES.PENDING.key
      }
    }))?.data?.demands;

    const demandsList = response
      .filter(demand => demand.demandTypeName !== Constants.DEMAND_TYPES.ACTIVE_CONTACT_MEETING.key
        && demand.demandTypeName !== Constants.DEMAND_TYPES.ENGAGEMENT_MEETING.key
        && demand.demandTypeName !== Constants.DEMAND_TYPES.ONBOARDING_MEETING.key
        && demand.demandTypeName !== Constants.DEMAND_TYPES.CHURN_MEETING.key)

    setColumns([{
      cards: demandsList,
      columnStatus: "PENDING",
      title: "Pendentes"
    }])

    state.columns.forEach(data => {
      if (data.backofficeUserId === value.value) setDemands_([data])
    })
  }

  useEffect(() => {
    loadLawyer();
    _fetchDemandsData(authUser);
  }, [authUser]);
  
  return (
    <React.Fragment>
      <MiniDrawer
        customStyle={{ padding: '0px' }}
        currentExpaded='demands'
        main={
          <Container>
            <div id="filter">
              <h1>Filtro de advogados</h1>
              <Select id="selected" options={options} onChange={filterDemands} />
              <button id="btn" onClick={() => {
                setColumns({})
                window.location.reload()
              }}>Remover filtro</button>
            </div>
            <ColumnContainer>

              {
                columns.length > 0 ?
                  <>
                    {
                      columns.map((column, idx) => (
                        <Column
                          key={
                            column.backofficeUserId ||
                            column.columnStatus ||
                            column.title
                          }
                          index={idx}
                          columnTitle={column.title}
                          backofficeUserName={column.backofficeUserName}
                          backofficeUserId={column.backofficeUserId}
                          columnStatus={column.columnStatus}
                          demands={column.cards}
                          authUser={column.authUser}
                          totalDemands={column.cards.length}
                          demandsInProgressQuantity={
                            column.cards.filter(
                              demand =>
                                demand.statusKey ===
                                Constants.DEMAND_STATUSES.IN_PROGRESS.key
                            ).length
                          }
                          demandsOpenQuantity={column.cards.length}
                        />
                      ))
                    }{
                      demands_.map((column, idx) => (
                        <Column
                          key={
                            column.backofficeUserId ||
                            column.columnStatus ||
                            column.title
                          }
                          index={idx}
                          columnTitle={column.title}
                          backofficeUserName={column.backofficeUserName}
                          backofficeUserId={column.backofficeUserId}
                          columnStatus={column.columnStatus}
                          demands={column.cards}
                          authUser={column.authUser}
                          totalDemands={column.cards.length}
                          demandsInProgressQuantity={
                            column.cards.filter(
                              demand =>
                                demand.statusKey ===
                                Constants.DEMAND_STATUSES.IN_PROGRESS.key
                            ).length
                          }
                          demandsOpenQuantity={column.cards.length}
                        />
                      ))
                    }
                  </>
                  : state.columns.map((column, idx) => (
                    <Column
                      key={
                        column.backofficeUserId ||
                        column.columnStatus ||
                        column.title
                      }
                      index={idx}
                      columnTitle={column.title}
                      backofficeUserName={column.backofficeUserName}
                      backofficeUserId={column.backofficeUserId}
                      columnStatus={column.columnStatus}
                      demands={column.cards}
                      authUser={column.authUser}
                      totalDemands={column.cards.length}
                      demandsInProgressQuantity={
                        column.cards.filter(
                          demand =>
                            demand.statusKey ===
                            Constants.DEMAND_STATUSES.IN_PROGRESS.key
                        ).length
                      }
                      demandsOpenQuantity={column.cards.length}
                    />
                  ))
              }

            </ColumnContainer>
          </Container>
        }
      />
    </React.Fragment>
  );
}
