const initialState = {
  drafts: {
    demandMessage: {},
    demandFinish: {},
    demandStatus: {},
  },
};

const updateDraft = (state, action, typeDraft) => {
  const { demandId, draft } = action.payload;
  const draftType = state.drafts[typeDraft];
  const updatedDraftType = {
    ...draftType,
    [demandId]: draft,
  };

  return {
    ...state,
    drafts: {
      ...state.drafts,
      [typeDraft]: updatedDraftType,
    },
  };
};

const deleteDraft = (state, action) => {
  const { demandId } = action.payload;
  const { demandMessage, demandFinish, demandStatus } = state.drafts;

  return {
    ...state,
    drafts: {
      demandMessage: {
        ...demandMessage,
        [demandId]: undefined,
      },
      demandFinish: {
        ...demandFinish,
        [demandId]: undefined,
      },
      demandStatus: {
        ...demandStatus,
        [demandId]: undefined,
      },
    },
  };
}

const DraftMessageReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DRAFT_MESSAGE':
      return updateDraft(state, action, 'demandMessage');
    case 'SET_DRAFT_STATUS':
      return updateDraft(state, action, 'demandStatus');
    case 'SET_DRAFT_FINISH':
      return updateDraft(state, action, 'demandFinish');
    case 'DELETE_DRAFT':
      return deleteDraft(state, action);
    default:
      return state;
  }
};

export default DraftMessageReducer;
