import React, { useRef, useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import {
  ButtonsContainer,
  Container,
  ContainerInput,
  ExitButton,
  Modal,
  SubmitButton,
} from './AddDemandToJourneyModal.styles';
import CustomerJourneyService from '../../../services/journeys/customerJourneyService';
import CustomSelectWithSearch from '../../input/customSelectorWithSearch/CustomSelectWithSearch';

const customerJourneyService = new CustomerJourneyService();

export default function AddDemandToJourneyModal({
  open,
  demandId,
  demandName,
  customerId,
  handleClose,
}) {
  const formRef = useRef(null);
  const [state, setState] = useState({
    loading: false,
    customerJourneys: [],
  });

  const fetchData = async customerId => {
    try {
      const customerJourneys = (
        await customerJourneyService.listAllCustomerJourneys({
          customerId,
          withJourneyType: true,
        })
      ).data.customerJourneys;

      setState(prevState => ({
        ...prevState,
        customerJourneys,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  async function handleSubmit(data) {
    try {
      await customerJourneyService.addDemandToCustomerJourney(
        data.customerJourneyId,
        demandId
      );

      handleClose(true);
    } catch (error) {
      console.error(error);

      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};

        error.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    } finally {
      setState({
        ...state,
        loading: false,
      });
    }
  }

  useEffect(() => {
    if (customerId) fetchData(customerId);
  }, [customerId]);

  return (
    <>
      {open && (
        <Container>
          <Modal>
            <h1>Adicionar demanda à jornada</h1>

            <p>
              Adicionar a demanda <strong>{demandName}</strong> a uma jornada
            </p>

            <Form ref={formRef} onSubmit={handleSubmit}>
              <ContainerInput>
                <CustomSelectWithSearch
                  name='customerJourneyId'
                  label='Jornada'
                  options={state.customerJourneys.map(j => ({
                    value: j.id,
                    name: j.journeyType.title,
                  }))}
                  optionValue='id'
                  optionLabel='name'
                  placeholder='Selecione uma jornada'
                  required
                />
              </ContainerInput>

              <ButtonsContainer>
                <SubmitButton type='submit'>
                  {state.loading ? (
                    <CircularProgress color='inherit' />
                  ) : (
                    'Adicionar'
                  )}
                </SubmitButton>

                <ExitButton onClick={() => handleClose(false)}>
                  Fechar
                </ExitButton>
              </ButtonsContainer>
            </Form>
          </Modal>
        </Container>
      )}
    </>
  );
}
