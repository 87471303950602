import React, { useRef, useState } from 'react';
import { Form } from '@unform/web';
import {
  Container,
  Title,
  ButtonSend,
  Description,
} from './CustomerEmailContractModal.styles';
import Modal from '../modal/Modal';
import FileInputMult from '../input/FileInputMult';
import customerApi from '../../services/apis/customerApi';
import { formatFileToBase64, validateEmail } from '../../utils';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Loading from '../../components/Loading';
import CustomInput from '../input/CustomInput';

export default function CustomerEmailContractModal(props) {
  const { open, handleClose, customerId, customerEmail } = props;
  const formRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  async function handleSubmit(data) {
    try {
      setSubmitLoading(true);
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        attachments: Yup.mixed().test(
          'obrigatory',
          'Relátorio é obrigatório',
          value => {
            if (!value.length) return false;

            return true;
          }
        ),
        emails: Yup.mixed().test(
          'emails',
          'Email(s) inválido(s)! Separe por vírgula, ex: "a@email.com, b@email.com"',
          value => {
            if (!value.length) return true;

            const emailList = value.split(',').map(email => {
              return email.trim();
            });

            return !emailList.some(email => !validateEmail(email));
          }
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const request = {
        attachments: await Promise.all(
          Array.from(data.attachments).map(
            async file => await formatFileToBase64(file)
          )
        ),
      };
      if (data.emails) {
        request.emails = data.emails.split(',').map(email => {
          return email.trim();
        });
      }

      await customerApi.sendContractEmail(customerId, request);

      toast.success('Email enviado com sucesso!');

      handleClose(true);
    } catch (error) {
      console.error(error);

      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};

        error.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    } finally {
      setSubmitLoading(false);
    }
  }

  return (
    <>
      {open && (
        <Modal visible={open}>
          <Loading open={submitLoading} />

          <Container>
            <Title>
              Enviar email de renovação de contrato
              <i className='fa fa-lg fa-times' onClick={handleClose} />
            </Title>

            <Description>
              O email será enviado para:{' '}
              <a href={`mailto:${customerEmail}}`}>{customerEmail}</a>
            </Description>

            <Form ref={formRef} onSubmit={handleSubmit}>
              <CustomInput
                name='emails'
                label='Email-s adicionais, separados por vírgula (,) ex: "a@email.com, b@email.com"'
              />

              <FileInputMult
                multiple={true}
                name='attachments'
                label='Anexos'
              />

              <ButtonSend disabled={submitLoading} type='submit'>
                Enviar
              </ButtonSend>
            </Form>
          </Container>
        </Modal>
      )}
    </>
  );
}
