import { makeStyles } from '@material-ui/core';
import { useField } from '@unform/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';

const useStyles = makeStyles(theme => ({
  error: {
    color: 'red',
    opacity: '70%',
    fontSize: '12px',
    fontWeight: 'normal',
    marginLeft: '6px',
  },
  label: {
    cursor: 'pointer',
  },
  input: {
    display: 'none',
  },
  mainLabel: {
    padding: '5px',
    display: 'flex',
    border: 'dashed',
    borderColor: '#A8A8A8',
    borderRadius: '5px',
    borderWidth: '2px',
    alignItems: 'center',
  },
  choseFiles: {
    border: 'solid',
    borderRadius: '5px',
    borderWidth: '2px',
    padding: '5px',
    borderColor: '#A8A8A8',
    fontSize: '14px',
    color: '#A8A8A8',
  },
  fileName: {
    marginLeft: '20px',
    fontSize: '14px',
    color: '#3052DE',
    textAlign: 'justify',
  },
  main: {},
  labelTitle: {
    color: '#A8A8A8',
    fontSize: '12px',
    textAlign: 'left',
  },
  labelTitleContainer: {},
}));

const FileInputMult = ({ name, label, ...rest }) => {
  const inputRef = useRef(null);
  const classes = useStyles();
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [preview, setPreview] = useState(defaultValue);

  const handlePreview = useCallback(e => {
    const files = e.target.files;

    if (!files || files.length === 0) {
      setPreview('');
    }
    if (files.length === 1) {
      setPreview(files[0].name);
    } else {
      setPreview(files.length + ' arquivos');
    }
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files',
      clearValue(ref) {
        ref.value = '';
        setPreview('');
      },
      setValue(_, value) {
        setPreview(value);
      },
    });
  }, [fieldName, registerField]);

  return (
    <div className={classes.main}>
      <div className={classes.labelTitleContainer}>
        <label className={classes.labelTitle} htmlFor='input-file'>
          {label}
          {error && <span className={classes.error}>{error}</span>}
        </label>
      </div>
      <label className={classes.label} htmlFor='input-file'>
        <div className={classes.mainLabel}>
          <h2 className={classes.choseFiles}>Escolher arquivos</h2>
          <span className={classes.fileName}>{preview}</span>
        </div>
      </label>
      <input
        ref={inputRef}
        id='input-file'
        name='input'
        onChange={handlePreview}
        className={classes.input}
        type='file'
        defaultValue={defaultValue}
        multiple
        {...rest}
      />
    </div>
  );
};

export default FileInputMult;
