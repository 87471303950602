import React from 'react';
import { Form } from '@unform/web';
import apiOld from '../services/Api';

export default function Test() {
  async function handleSubmit(data) {
    try {
      await apiOld.get('/signedPutFileUrl', {
        params: {
          type: 'attach',
          fileName: 'test',
          fileType: 'json',
        },
      });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div
      style={{
        width: '1000px',
        height: '1000px',
      }}
    >
      <h1>Testes</h1>

      <Form onSubmit={handleSubmit}>
        <div
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <button type='submit' value='eae'>
            Botao
          </button>
        </div>
      </Form>
    </div>
  );
}
