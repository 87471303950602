import React, { useEffect, useState } from "react";
import { useQuery } from "../../../routes";
import ModalAIR from "../../../components/riskAnalysis/modalAIR";
import { Grid } from "@material-ui/core";
import QestionContent from "../../../components/riskAnalysis/Questions";
import CustomInput from "../../../components/input/CustomInput";
import { AnalysisRiskApi } from "../../../services/apis/analysisRisk";
import { dataTitleQuestion, dataModalQuestion } from "../renderContent";

export default function QuestionAIR() {
  const sector = useQuery().get('sector');
  const subcategory = useQuery().get('subcategory');

  const [addQuestion, setAddQuestion] = useState(false);
  const [addAnwser, setAddAnwser] = useState(false);
  const [editAnwser, setEditAnwser] = useState(false);
  const [editQuestion, setEditQuestion] = useState(false);

  const [params, setParams] = useState({ sector, subcategory });

  const [sectorId, setSectorId] = useState(0);
  const [question, setQuestion] = useState([]);
  const [idSelected, setIdSelected] = useState(0);
  const [loading, setLoading] = useState(false);

  const setValues = {
    add: setAddQuestion,
    edit: setEditQuestion,
    addAnwser: setAddAnwser,
    editAnwser: setEditAnwser,
  }

  const updateQuestion = async (id, data) => {
    await AnalysisRiskApi.question.update(id, data);
    fetchData();
  };

  const createQuestion = async (data) => {
    await AnalysisRiskApi.question.create(data);
    fetchData();
  };

  const deleteQuestion = async (id) => {
    await AnalysisRiskApi.question.delete(id);
    fetchData();
  };

  const createAnswer = async (data) => {
    await AnalysisRiskApi.answer.create(data);
    fetchData();
  };

  const updateAnswer = async (id, data) => {
    await AnalysisRiskApi.answer.update(id, data);
    fetchData();
  };

  const deleteAnswer = async (id) => {
    await AnalysisRiskApi.answer.delete(id);
    fetchData();
  };

  const fetchData = async () => {
    setLoading(true);
    const questionList = (await AnalysisRiskApi.question.getAll()).data;
    setQuestion(questionList);

    const sectorList = (await AnalysisRiskApi.sector.getAll()).data;
    const sectorId = sectorList.find((item) => item.name === params.sector)?.id;
    setSectorId(sectorId);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <QestionContent
        dataTitle={dataTitleQuestion}
        data={question}
        params={params}
        setValues={setValues}
        sectorId={sectorId}
        loading={loading}
        remove={{ question: deleteQuestion, anwser: 'deleteAnwser' }}
        removeAnswer={deleteAnswer}
        setIdSelected={setIdSelected}
      />
      <ModalAIR
        open={editQuestion}
        close={() => setEditQuestion(false)}
        dataModal={dataModalQuestion.edit}
        update={updateAnswer}
        id={idSelected}
        
      >
        <div>
          <Grid item={true} xs={12}>
            <CustomInput
              label='Questão'
              name='name'
              placeholder='Digite a alternativa'
            />
            <CustomInput
              label='Pontuação'
              name='score'
              placeholder='Digite a pontuação'
              type='number'
            />
          </Grid>
        </div>
      </ModalAIR>
      <ModalAIR
        open={addQuestion}
        close={() => setAddQuestion(false)}
        dataModal={dataModalQuestion.add}
        create={createQuestion}
        relation={{ property: 'Sector_id', id: sectorId, subcategory }}
        id={idSelected}
      >
        <div>
          <Grid item={true} xs={12}>
            <CustomInput
              label='Questão'
              name='name'
              placeholder='Digite a questão'
            />
          </Grid>
        </div>
      </ModalAIR>
      <ModalAIR
        open={addAnwser}
        close={() => setAddAnwser(false)}
        dataModal={dataModalQuestion.addAnwser}
        relation={{ property: 'Question_id', questionId: idSelected }}
        create={createAnswer}
      >
        <div>
          <Grid item={true} xs={12}>
            <CustomInput
              label='Alternativa'
              name='name'
              placeholder='Digite a alternativa'
            />
            <CustomInput
              label='Pontuação'
              name='score'
              placeholder='Digite a pontuação'
              type='number'
            />
          </Grid>
        </div>
      </ModalAIR>
      <ModalAIR
        open={editAnwser}
        close={() => setEditAnwser(false)}
        dataModal={dataModalQuestion.editAnwser}
        update={updateQuestion}
        id={idSelected}
      >
        <div>
          <Grid item={true} xs={12}>
            <CustomInput
              label='Questão'
              name='name'
              placeholder='Digite a questão'
              defaultValue={question.find((item) => item.id === idSelected)?.name}
            />
          </Grid>
        </div>
      </ModalAIR>
    </>
  );
}