import React, { useCallback, useEffect, useRef, useState } from 'react';
import apiOld from '../../services/Api';
import {
  formatDateWithMonthName,
  formatDateHour,
  formatDate,
  tryParseJson,
} from '../../utils';
import Loading from '../Loading';
import {
  Container,
  Modal,
  Body,
  Toolbar,
  Status,
  HeaderInfoContainer,
  ToolbarItemContainer,
  HistoryContainer,
  MessageDate,
  MessageContainer,
  MessageDeleteIcon,
  MessageDeletedInfo,
  MessageName,
  MessageText,
  MessageTime,
  Message,
  MessageStatus,
  SendMessageButton,
  ToolbarItemContainerButton,
  SendMessageContainer,
  CloseButtonLine1,
  CloseButtonLine2,
  CloseButton,
  HeaderContainer,
  MessageAttachment,
  AttachmentIcon,
  DownloadIcon,
  LinkStyled,
  DocumentIcon,
  DeleteButtonIcon,
  ActionIconsContainer,
  DemandProfileContainer,
  DemandProfileDescriptionContainer,
  SendMessageEditorContainer,
  ButtonLink,
} from './DemandModal.styles';
import { Form } from '@unform/web';
import Linkify from '../Linkify';
import ChangeStatusModal from './changeDemandStatusModal/ChangeDemandStatusModal';
import ChangeDemandTypeModal from './changeDemandTypeModal/ChangeDemandTypeModal';
import ChangeDemandPrivacyModal from './changeDemandPrivacyModal/ChangeDemandPrivacyModal';
import ChangeDemandResponsibleModal from './changeDemandResponsibleModal/ChangeDemandResponsibleModal';
import ChangeSLAModal from './changeSLAModal/ChangeSLAModal';
import ChangeInternSLAModal from './changeInternSLAModal/ChangeInternSLAModal';
import ChangeDemandTitleModal from './changeDemandTitleModal/ChangeDemandTitleModal';
import ChangeDemandProcessNumberModal from './changeDemandProcessNumberModal/ChangeDemandProcessNumberModal'
import ChangeUserModal from './changeUserModal/ChangeUserModal';
import SendFormModal from './sendFormModal/SendFormModal';
import SendPrivateMessageModal from './sendPrivateMessageModal/SendPrivateMessageModal';
import DeleteMessageConfirmationModal from './deleteMessageConfirmationModal/DeleteMessageConfirmationModal';
import ModalTriagem from '../demandsBoard/screeningModal/ScreeningModal';
import AddDemandToJourneyModal from './addDemandToJourneyModal/AddDemandToJourneyModal';
import { TagComponent } from '../input/TagInput';
import { closeDemandModal, openDemandModal, useQuery } from '../../routes';
import { useSelector, useDispatch } from 'react-redux';
import { deleteAllDraftByDemandId, setDraftMessage } from '../../redux/actions/DraftDemandMessages';
import demandsApi from '../../services/apis/demandsApi';
import ConfirmationModal from '../confirmationModal/ConfirmationModal';
import { Constants } from '../../utils/Constants';
import TagsModal from '../tagsModal/TagsModal';
import customerApi from '../../services/apis/customerApi';
import { toast } from 'react-toastify';
import { addWorkingDays } from '../../services/Utils';
import RichTextArea from '../input/richTextArea/RichTextArea';
import ReadOnlyTextArea from '../readOnlyRichTextArea/ReadOnlyRichTextArea';
import { Link } from 'react-router-dom';
import DocumentService from '../../services/documentService';
import MessageService from '../../services/messageService';
import DocumentViewer from '../documentViewer/DocumentViewer';
import DemandService from '../../services/demandService';
import CustomerJourneyDemandService from '../../services/journeys/customerJourneyDemandService';
import templateString from 'template-string'
import CustomTextArea from '../input/CustomTextArea';
import AlterBusinessModal from '../demandsBoard/alterBusinessModal';

const demandService = new DemandService();
const documentService = new DocumentService();
const messageService = new MessageService();
const customerJourneyDemandService = new CustomerJourneyDemandService();

function NewlineText(props) {
  const text = props.text || '';
  return text.split('\n').map(str => (<p style={{marginLeft: '20px'}}>{str}</p>));
}

export default function DemandModal(props) {
  const formRef = useRef(null);
  const query = useQuery();

  const queryParamDemandId = query.get('demandId');
  const queryParamDemandTypeId = query.get('demandTypeId');
  const queryParamBackofficeUserId = query.get('backofficeUserId');
  const queryParamIsScreeningModalOpen =
    query.get('isScreeningModalOpen') === 'true';

  const authUser = useSelector(state => state.auth.user);
  const [state, setState] = useState({
    documentViewer: {
      visible: false,
      documentId: undefined,
      handleClose: () => {
        setState(prevState => ({
          ...prevState,
          documentViewer: {
            ...prevState.documentViewer,
            visible: false,
            documentId: undefined,
          },
        }));
      },
    },
  });
  const [sla, setSLA] = useState('')
  const [customerData, setCustomerData] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState(false);
  const [
    isDeleteMessageConfirmationModalOpen,
    setIsDeleteMessageConfirmationModalOpen,
  ] = useState(false);
  const [isModalSendFormOpen, setIsModalSendFormOpen] = useState(false);
  const [isChangeStatusModalOpen, setIsChangeStatusModalOpen] = useState(false);
  const [isChangeDemandTypeModalOpen, setIsChangeDemandTypeModalOpen] =
    useState(false);
  const [isChangeDemandPrivacyModalOpen, setIsChangeDemandPrivacyModalOpen] =
    useState(false);
  const [isChangeTitleModalOpen, setIsChangeTitleModalOpen] = useState(false);
  const [isChangeProcessModalOpen, setIsChangeProcessModalOpen] = useState(false);
  const [isChangeUserModalOpen, setIsChangeUserModalOpen] = useState(false);
  const [isChangeSLAModalOpen, setIsChangeSLAModalOpen] = useState(false);
  const [isChangeInternSLAModalOpen, setIsChangeInternSLAModalOpen] =
    useState(false);
  const [isChangeDemandResponsibleModal, setIsChangeDemandResponsibleModal] =
    useState(false);
  const [isDemandTriagemModalOpen, setIsDemandTriagemModalOpen] = useState(
    queryParamIsScreeningModalOpen
  );
  const [isSendPrivateMessageModalOpen, setIsSendPrivateMessageModalOpen] =
    useState(false);

  const [isAlterBusinessModalOpen, setIsAlterBusinessModalOpen] = useState(false);
  const [isTagModalOpen, setIsTagModalOpen] = useState(false);
  const [pageSelected, setPageSelected] = useState('MESSAGES');
  const [confirmationModal, setConfirmationModal] = useState({
    open: false,
    resolveCallback: null,
    rejectCallback: null,
    title: '',
    description: '',
  });
  const [isAddDemandToJourneyModalOpen, setIsAddDemandToJourneyModalOpen] =
    useState(false);

  const dispatch = useDispatch();
  const demandId = state?.demand?.id;
  const draftDemandByParam = useSelector(state => state?.drafts?.drafts?.demandMessage?.[queryParamDemandId]);

  useEffect(() => {
    if(demandId){
      window.location.href = `${process.env.REACT_APP_NEW_BACKOFFICE_URL}#/?demandId=${demandId}`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demandId]);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const demandData = (await demandsApi.getDemandById(queryParamDemandId))
        ?.data;
      const responseProcessItemsData = (
        await apiOld.get(`/demands/${queryParamDemandId}/items`)
      )?.data;
      if (demandData.customerId) {
        const responseCustomerData = (
          await customerApi.getById(demandData.customerId, {})
        )?.data;

        setCustomerData(responseCustomerData);
      }

      setState(prevState => ({
        ...prevState,
        demand: demandData,
      }));

      setMessages(formatMessages(responseProcessItemsData.demandProcessItems));

      scrollDown();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [queryParamDemandId]);

  useEffect(() => {
    if (queryParamDemandId) fetchData();
  }, [queryParamDemandId, fetchData]);

  useEffect(
    () => setIsDemandTriagemModalOpen(queryParamIsScreeningModalOpen),
    [queryParamIsScreeningModalOpen]
  );

  const handleClose = () => {
    closeDemandModal();
  };

  function scrollDown() {
    const scroll = document.getElementById('scroll');
    if (scroll) scroll.scrollTop = scroll.scrollHeight;
  }

  function formatMessages(messages) {
    const messagesFomated = {};

    messages.forEach(message => {
      const date = formatDateWithMonthName(message.createdAt);

      if (messagesFomated[date]) {
        messagesFomated[date].push(message);
      } else messagesFomated[date] = [message];
    });

    Object.keys(messagesFomated).forEach(day => {
      messagesFomated[day] = messagesFomated[day].sort(function (a, b) {
        return a.createdAt > b.createdAt ? 1 : -1;
      });
    });

    return messagesFomated;
  }

  async function handleSubmit(data, { reset }) {
    try {
      setSubmitLoading(true);
      formRef.current.setErrors({});

      if (data.message.length > 16000) {
        formRef.current.setErrors({
          message: 'Texto muito grande!',
        });

        return;
      }

      await messageService.createTextMessageWithAttachments({
        demandId: state.demand.id,
        content: data.message,
        attachments: data.attachments,
      });

      await dispatch(deleteAllDraftByDemandId(demandId))

      reset();
      fetchData();
      scrollDown();
      formRef.current.clear();
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitLoading(false);
    }
  }

  function handleSendFormModalClose(wasSuccess) {
    if (wasSuccess) {
      fetchData();
    }
    setIsModalSendFormOpen(false);
  }

  async function handleChangeStatusModalClose(updatedDemandOrNull) {
    if (updatedDemandOrNull) {
      const nextDemandInJourney =
        updatedDemandOrNull.nextBlockedDemandInJourney;

      await fetchData();

      if (nextDemandInJourney) {
        // tem uma próxima demanda na fila de jornada, perguntar se deseja
        // colocar ela em aberto já

        const resolveCallback = async () => {
          // Altera o status da demanda para aberto e abre o modal da demanda
          try {
            await demandService.updatedemandStatus({
              demandId: nextDemandInJourney.id,
              statusKey: Constants.DEMAND_STATUSES.OPEN.key,
            });

            toast.success('Demanda aberta com sucesso!');

            setConfirmationModal({});
            openDemandModal(nextDemandInJourney.id, {
              isScreeningModalOpen: true,
            });
          } catch (error) {
            console.error(error);
          }
        };

        const rejectCallback = () => {
          setConfirmationModal({});
        };

        setConfirmationModal({
          open: true,
          resolveCallback: resolveCallback,
          rejectCallback: rejectCallback,
          title: 'Deseja abrir a próxima demanda bloqueada da jornada?',
          description: `Está ação irá alterar o status da demanda "${nextDemandInJourney.name}" para ABERTA e abrirá o painel para triagem`,
        });
      }
    }
    setIsChangeStatusModalOpen(false);
  }

  function handleChangeDemandTypeModalClose(wasSuccess) {
    if (wasSuccess) {
      fetchData();
    }
    setIsChangeDemandTypeModalOpen(false);
  }

  function handleChangeDemandPrivacyModalClose(wasSuccess) {
    if (wasSuccess) {
      fetchData();
    }
    setIsChangeDemandPrivacyModalOpen(false);
  }

  function handleChangeSLAModalClose(wasSuccess) {
    if (wasSuccess) {
      fetchData();
    }
    setIsChangeSLAModalOpen(false);
  }

  function handleDemandProcessModalClose(wasSuccess) {
    if (wasSuccess) {
      fetchData();
    }
    setIsChangeProcessModalOpen(false)
  }

  function handleChangeTitleModalClose(wasSuccess) {
    if (wasSuccess) {
      fetchData();
    }
    setIsChangeTitleModalOpen(false);
  }

  function handleChangeUserModalClose() {
    setIsChangeUserModalOpen(false);
  }

  function handleChangeResponsibleModalClose(wasSuccess) {
    if (wasSuccess) {
      fetchData();
    }
    setIsDemandTriagemModalOpen(false);
  }

  function handleSendPrivateMessageModalClose(wasSuccess) {
    if (wasSuccess) {
      fetchData();
    }
    setIsSendPrivateMessageModalOpen(false);
  }

  const handleDeleteDemand = () => {
    const resolveCallback = async () => {
      try {
        await demandsApi.deleteDemand(state.demand.id);

        toast.success('Demanda excluida com sucesso!');

        setConfirmationModal({});
        closeDemandModal();
      } catch (error) {
        console.error(error);
      }
    };

    const rejectCallback = () => {
      setConfirmationModal({});
    };

    setConfirmationModal({
      open: true,
      resolveCallback: resolveCallback,
      rejectCallback: rejectCallback,
      title: 'Você realmente deseja excluir essa demanda?',
      description:
        'Todos os dados relacionados a essa demanda também serão excluidos irreversivelmente',
    });
  };

  const handlePriorizeDemand = () => {
    const resolveCallback = async () => {
      try {
        await demandsApi.prioritizeDemand(state.demand.id);

        toast.success('Demanda priorizada com sucesso!');

        setConfirmationModal({});
        fetchData();
      } catch (error) {
        console.error(error);
      }
    };

    const rejectCallback = () => {
      setConfirmationModal({});
    };

    setConfirmationModal({
      open: true,
      resolveCallback: resolveCallback,
      rejectCallback: rejectCallback,
      title: 'Você realmente deseja priorizar essa demanda?',
      description: `Está ação irá alterar o SLA da demanda para ${formatDate(
        addWorkingDays(new Date(), 2, { checkWorkShift: true })
      )} e irá adiar o SLA das demandas nesse intervalo!`,
    });
  };

  const handleDeleteMessageConfirmationModalClose = async wasSuccess => {
    if (wasSuccess) {
      fetchData();
    }

    setIsDeleteMessageConfirmationModalOpen(false);
  };

  const _openAddDemandToJourneyModal = async () => {
    setIsAddDemandToJourneyModalOpen(true);
  };

  const _handleRemoveDemandFromJourney = async demandId => {
    const resolveCallback = async () => {
      try {
        await customerJourneyDemandService.removeDemandFromCustomerJourney(
          demandId
        );

        toast.success('Demanda removida da jornada com sucesso!');

        setConfirmationModal({});
        fetchData();
      } catch (error) {
        console.error(error);
      }
    };

    const rejectCallback = () => {
      setConfirmationModal({});
    };

    setConfirmationModal({
      open: true,
      resolveCallback: resolveCallback,
      rejectCallback: rejectCallback,
      title: 'Você realmente deseja remover a demanda de sua jornada?',
      description: `Está ação irá remover a demanda da jornada atual.`,
    });
  };

  const _closeAddDemandToJourneyModal = async success => {
    if (success) {
      fetchData();
    }

    setIsAddDemandToJourneyModalOpen(false);
  };

  const _handleViewDocument = documentId => {
    setState({
      ...state,
      documentViewer: {
        ...state.documentViewer,
        visible: true,
        documentId,
      },
    });
  };

  const MessageComponent = ({ message }) => {
    const messageToBeTrated = { ...message};

    if(messageToBeTrated.type === 'TEMPLATED_MESSAGE' && messageToBeTrated.content !== ''){
      const contentInJson = JSON.parse(messageToBeTrated.content);
      if(contentInJson.parameters.oldDemand_deleveryForecast){
        const date = formatDate(contentInJson.parameters.oldDemand_deleveryForecast)
        contentInJson.parameters.oldDemand_deleveryForecast = date !== '' ? date : contentInJson.parameters.oldDemand_deleveryForecast
      }
      if(contentInJson.parameters.demand_deleveryForecast){
        contentInJson.parameters.demand_deleveryForecast = formatDate(contentInJson.parameters.demand_deleveryForecast)
      }
      messageToBeTrated.content = templateString(contentInJson.message, contentInJson.parameters)
      messageToBeTrated.type = 'MESSAGE'
    }

    const statusConfigs =
      messageToBeTrated.type === 'STATUS'
        ? Constants.DEMAND_STATUSES[messageToBeTrated.customFields?.statusKey]
        : null;
    const isRight = messageToBeTrated.userType === 'BACKOFFICE' ? 1 : 0;
    const isPrivate = messageToBeTrated.type === 'PRIVATE_MESSAGE';
    const isDeleted = messageToBeTrated.deleted;
    const canBeDeleted =
      !isDeleted &&
      isRight &&
      !isPrivate &&
      // message.type !== 'STATUS' &&
      messageToBeTrated.type !== 'PRIVATE_MESSAGE';

    const isJson = tryParseJson(messageToBeTrated.content);

    return (
      <MessageContainer right={isRight}>
        <Message right={isRight} private={isPrivate} deleted={isDeleted}>
          {canBeDeleted ? (
            <MessageDeleteIcon
              onClick={() =>
                setIsDeleteMessageConfirmationModalOpen(messageToBeTrated.id)
              }
              className='fa fa-trash'
            />
          ) : null}

          <MessageName deleted={isDeleted}>{messageToBeTrated.userName}</MessageName>

          {messageToBeTrated.type === 'STATUS' && (
            <MessageStatus right={isRight} color={statusConfigs.color}>
              {statusConfigs.name}
            </MessageStatus>
          )}

          {isJson ? (
            <>
              <ReadOnlyTextArea deleted={isDeleted} defaultValue={isJson} />
            </>
          ) : (
            <Linkify>
              <MessageText deleted={isDeleted}>{messageToBeTrated.content}</MessageText>
            </Linkify>
          )}

          {messageToBeTrated.attachments &&
            messageToBeTrated.attachments.map(doc => (
              <MessageAttachment
                title='Baixar documento'
                download
                right={isRight}
                key={doc.id}
              >
                <AttachmentIcon
                  onClick={() => {
                    documentService.downloadDocumentById(doc.id);
                  }}
                />

                <span
                  onClick={() => {
                    documentService.downloadDocumentById(doc.id);
                  }}
                >
                  {doc.fileName}
                </span>

                <DownloadIcon
                  onClick={() => {
                    documentService.downloadDocumentById(doc.id);
                  }}
                />
                <button
                  style={{
                    color: 'blueviolet',
                    backgroundColor: 'transparent',
                    border: 'none',
                    marginLeft: '10px',
                    fontSize: '0.7rem',
                    cursor: 'pointer',
                  }}
                  title='Visualizar documento'
                  onClick={() => _handleViewDocument(doc.id)}
                >
                  <i className='fas fa-eye fa-2x'></i>
                </button>
              </MessageAttachment>
            ))}

          {messageToBeTrated.type === 'FORM' && (
            <LinkStyled to={`/forms/${messageToBeTrated.externalId}`}>
              <DocumentIcon />
              Formulário
            </LinkStyled>
          )}
          {messageToBeTrated.type === 'DOCUMENT' && (
            <LinkStyled to={`/documents/${messageToBeTrated.externalId}`}>
              <DocumentIcon />
              Documento
            </LinkStyled>
          )}
        </Message>

        {isDeleted && (
          <MessageDeletedInfo>
            Mensagem deletada por: {messageToBeTrated.deletedByUserName}
          </MessageDeletedInfo>
        )}
        <MessageTime right={isRight}>
          {formatDateHour(messageToBeTrated.createdAt)}
        </MessageTime>
      </MessageContainer>
    );
  };

  function setChecked(checked) {
    demandsApi.updateDemand(state.demand.id, { checked }).then(() => fetchData())
  }
  function setPinned(pinned) {
    let confirmation = false;
    if (pinned === true) {
      confirmation = window.confirm('Tem certeza que deseja fixar o SLA dessa demanda ? ao fixar ela deixará de ter seu SLA otimizado automaticamente')
    } else {
      confirmation = window.confirm('Tem certeza que deseja desfixar o SLA dessa demanda ? ao desfixar ela poderá ter seu SLA otimizado automaticamente')
    }
    if (confirmation) {
      demandsApi.updateDemand(state.demand.id, { pinned }).then(() => fetchData())
    }
  }

  const loadSLA = async () => {
    const sla = await apiOld.get(`/demands/sla/${state.demand.businessId}`)
    setSLA(formatDate(sla.data))
    setIsDemandTriagemModalOpen(true)
  }

  return (
    <>
      {(queryParamDemandId && state.demand && (
        <Container>
          <Modal>
            {messages && (
              <>
                <header>
                  <HeaderContainer>
                    {state.demand.preJourneyTypeId && (
                      <Link
                        style={{
                          textDecoration: 'none',
                          color: '#fff',
                        }}
                        to={{
                          pathname: `/journeyTypes/${state.demand.preJourneyTypeId}`,
                        }}
                      >
                        <Status backgroundColor={'orange'}>Pré Jornada</Status>
                      </Link>
                    )}
                    {state.demand.journeyId && (
                      <Link
                        style={{
                          textDecoration: 'none',
                          color: '#fff',
                        }}
                        to={{
                          pathname: `/customers/${state.demand.customerId}/journeys`,
                          search: `?customerJourneyId=${state.demand.journeyId}`,
                        }}
                      >
                        <Status backgroundColor={'pink'}>Jornada</Status>
                      </Link>
                    )}
                    <Status
                      color={
                        Constants.DEMAND_STATUSES[state.demand.statusKey].color
                      }
                      backgroundColor={
                        Constants.DEMAND_STATUSES[state.demand.statusKey]
                          .backgroundColor1
                      }
                    >
                      {Constants.DEMAND_STATUSES[state.demand.statusKey].name}
                    </Status>

                    {state.demand.private && (
                      <Status
                        style={{ marginLeft: '5px' }}
                        color={'#fff'}
                        backgroundColor={'#ea5f59'}
                      >
                        Sigilosa
                      </Status>
                    )}

                    {state.demand.customer.observations &&  (
                      <Status
                        style={{ marginLeft: '5px' }}
                        color={'#000000'}
                        backgroundColor={'#fbff00'}
                      >
                        observação
                      </Status>
                    )}

                    <HeaderInfoContainer>
                      <h3>Título</h3>

                      <h4 onClick={() => setIsChangeTitleModalOpen(true)}>
                        {`${state.demand.externalId} - ${state.demand.name}`}
                      </h4>
                    </HeaderInfoContainer>

                    <HeaderInfoContainer>
                      <h3>Atribuído a</h3>
                      {state.demand.statusKey !==
                        Constants.DEMAND_STATUSES.OPEN.key ? (
                        <h4
                          onClick={() =>
                            setIsChangeDemandResponsibleModal(true)
                          }
                        >
                          {state.demand.backofficeUser?.name || 'indefinido'}
                        </h4>
                      ) : (
                        <button
                          onClick={() => loadSLA()}
                        >
                          Definir responsável
                        </button>
                      )}
                    </HeaderInfoContainer>

                    {state.demand.statusKey !==
                      Constants.DEMAND_STATUSES.FINISHED.key && (
                        <HeaderInfoContainer>
                          <h3>SLA Interno</h3>

                          {state.demand.internDeliveryForecast ? (
                            <span
                              style={{ cursor: 'pointer' }}
                              onClick={() => setIsChangeInternSLAModalOpen(true)}
                            >
                              {formatDate(state.demand.internDeliveryForecast)}
                            </span>
                          ) : (
                            <button
                              onClick={() => setIsChangeInternSLAModalOpen(true)}
                            >
                              Definir SLA Interno
                            </button>
                          )}
                        </HeaderInfoContainer>
                      )}

                    {state.demand.statusKey ===
                      Constants.DEMAND_STATUSES.IN_PROGRESS.key && (
                        <HeaderInfoContainer>
                          <button
                            onClick={() =>
                              setIsChangeStatusModalOpen(
                                Constants.DEMAND_STATUSES.FINISHED.key
                              )
                            }
                          >
                            Finalizar Demanda
                          </button>
                        </HeaderInfoContainer>
                      )}

                    {state.demand.checked !== true &&
                      (
                        <HeaderInfoContainer>
                          <button
                            onClick={() =>
                              setChecked(true)
                            }
                            style={{ backgroundColor: '#1994FF' }}

                          >
                            Marcar como triado
                          </button>
                        </HeaderInfoContainer>
                      )}

                    {state.demand.checked === true &&
                      (
                        <HeaderInfoContainer>
                          <button
                            onClick={() =>
                              setChecked(false)
                            }
                            style={{ backgroundColor: '#d3d3d3', color: '#2e2e2e' }}
                          >
                            Marcar como não triado
                          </button>
                        </HeaderInfoContainer>
                      )}



                    <HeaderInfoContainer>
                      <button
                        onClick={() => {
                          state.demand.backofficeUserId === authUser.id ? 
                            toast.error('Você é responsável pela demanda, não pode desativar as notificações.') 
                            : demandsApi
                              .demandToggleFollowingUser(state.demand.id)
                              .then(() => fetchData());
                        }}
                        style={{
                          backgroundColor: state.demand.following?.includes(
                            authUser.id
                          )
                            ? '#52e068'
                            : '#ff4a55',
                        }}
                      >
                        <i
                          className='fas fa-bell'
                          style={{ marginRight: '5px' }}
                        />
                        {state.demand.following?.includes(authUser.id)
                          ? 'Notificações ativadas'
                          : 'Notificações desativadas'}
                      </button>

                    </HeaderInfoContainer>
                    {state.demand.pinned !== true &&
                      <button
                        style={{
                          border: 'none',
                          color: '#fff',
                          borderRadius: '3px',
                          cursor: 'pointer',
                          backgroundColor: 'red',
                          padding: '5px 10px'
                        }}
                        onClick={() => setPinned(true)}
                      >
                        Fixar SLA
                      </button>
                    }
                    {state.demand.pinned === true &&
                      <button
                        style={{
                          border: 'none',
                          color: '#fff',
                          borderRadius: '3px',
                          cursor: 'pointer',
                          backgroundColor: 'red',
                          padding: '5px 10px'
                        }}
                        onClick={() => setPinned(false)}
                      >
                        Desfixar SLA
                      </button>
                    }

                    <HeaderInfoContainer>

                    </HeaderInfoContainer>

                    {state.demand.demandTypeName ===
                      Constants.DEMAND_TYPES.ROPA_MEETING.key && (
                        <HeaderInfoContainer>
                          <ButtonLink
                            to='/tools/ropa'
                            style={{
                              backgroundColor: '#1994FF',
                            }}
                          >
                            <i
                              className='fas fa-map'
                              style={{ marginRight: '5px' }}
                            />
                            ROPA
                          </ButtonLink>
                        </HeaderInfoContainer>
                      )}

                    <ActionIconsContainer>
                      <DeleteButtonIcon
                        className='fas fa-trash fa-lg'
                        onClick={handleDeleteDemand}
                      />

                      <CloseButton onClick={handleClose}>
                        <CloseButtonLine1 />
                        <CloseButtonLine2 />
                      </CloseButton>
                    </ActionIconsContainer>
                  </HeaderContainer>
                </header>

                <main>
                  <Body>
                    {pageSelected === 'PROFILE' && (
                      <DemandProfileContainer>
                        <h1>{state.demand.name}</h1>

                        <DemandProfileDescriptionContainer>
                          <h2>Detalhes</h2>

                          <div>
                            <h3>Cliente: </h3>

                            {state.demand?.customer?.name ? (
                              <Link
                                to={`/customers/${state.demand.customerId}`}
                              >
                                {state.demand.customer.name}
                              </Link>
                            ) : (
                              <strong>deletado</strong>
                            )}
                          </div>

                          <div>
                            <h3>Negócio: </h3>

                            {state.demand?.Business?.name ? (
                              <Link
                                to={`/business/${state.demand?.Business?.id}`}
                              >
                                {state.demand?.Business?.name}
                              </Link>
                            ) : (
                              <strong>Negócio não encontrado</strong>
                            )}
                          </div>

                          <div>
                            <h3>Usuário: </h3>

                            {state.demand?.user?.name ? (
                              <Link
                                to={`/users/${state.demand.userId}`}
                              >
                                {state.demand.user.name}
                              </Link>
                            ) : (
                              <strong>deletado</strong>
                            )}
                          </div>

                          <div>
                            <h3>Descrição: </h3>

                            <p>{state.demand.description}</p>
                          </div>

                          <div>
                            <h3>Tipo de demanda: </h3>

                            {state.demand.demandTypeId ? (
                              <Link
                                to={`/demands/types/${state.demand.demandTypeId}`}
                              >
                                {state.demand.demandTypeName}
                              </Link>
                            ) : (
                              <p>{state.demand.demandTypeName}</p>
                            )}
                          </div>

                          <div>
                            <h3>Responsável: </h3>
                            {state.demand.backofficeUser ? (
                              <Link
                                to={`/users/${state.demand.backofficeUser.id}`}
                              >
                                {state.demand.backofficeUser.name}
                              </Link>
                            ) : (
                              <p>
                                <strong>usuário indefinido</strong>
                              </p>
                            )}
                          </div>

                          <div>
                            <h3>SLA Interno: </h3>

                            <p>
                              {formatDateWithMonthName(
                                state.demand.internDeliveryForecast
                              )}
                            </p>
                          </div>

                          <div>
                            <h3>SLA Externo: </h3>

                            <p>
                              {formatDateWithMonthName(
                                state.demand.deleveryForecast
                              )}
                            </p>
                          </div>

                          <div>
                            <h3>Feedback: </h3>

                            {state.demand.feedback &&
                              state.demand.feedback?.skipped !== true ? (
                              <Link
                                to={`/cs/feedbacks?${Constants.QUERY_PARAMS_STATES.FEEDBACK.DEMAND_FEEDBACK_MODAL}=${state.demand.feedback.id}`}
                              >
                                {state.demand.feedback.rating}/
                                {Constants.FEEDBACK_MAX_RATING}
                              </Link>
                            ) : (
                              <p>nenhum</p>
                            )}
                          </div>

                          <div>
                            <h3>Criada em: </h3>

                            <p>
                              {formatDateWithMonthName(state.demand.createdAt)}
                            </p>

                            <h3>às</h3>

                            <p>{formatDateHour(state.demand.createdAt)}</p>

                            <h3>por</h3>

                            <Link to={`/users/${state.demand.userId}`}>
                              {state.demand.userId}
                            </Link>
                          </div>

                          <div>
                            <h3>Modificada pela última vez em: </h3>

                            <p>
                              {formatDateWithMonthName(state.demand.updatedAt)}
                            </p>

                            <h3> às</h3>

                            <p> {formatDateHour(state.demand.updatedAt)}</p>
                          </div>

                          <div>
                            <h3>Id externo: </h3>

                            <p>{state.demand.externalId}</p>
                          </div>

                          <div>
                            <h3>Id único</h3>

                            <p>{state.demand.id}</p>
                          </div>

                          <div>
                            <h3>Prioritária: </h3>

                            <p>'{state.demand.priority ? 'Sim' : 'Não'}'</p>

                            <h3>por</h3>

                            <p>
                              {state.demand.priority &&
                                state.demand.prioritizedUserId ? (
                                <Link
                                  to={`/users/${state.demand.prioritizedUserId}`}
                                >
                                  {state.demand.prioritizedUserId}
                                </Link>
                              ) : (
                                'Indefinido'
                              )}
                            </p>
                          </div>
                          <div style={{textAlign: 'center'}}>
                            <h3>Observações</h3>
                            
                          </div>
                          

                            <NewlineText text={state.demand.customer.observations}/>
                          

                          {state.demand.customFields && (
                            <div>
                              <h3>Detalhes técnicos: </h3>

                              <p>{JSON.stringy(state.demand.customFields)}</p>
                            </div>
                          )}
                        </DemandProfileDescriptionContainer>
                      </DemandProfileContainer>
                    )}

                    {pageSelected === 'MESSAGES' && (
                      <>
                        <HistoryContainer id='scroll'>
                          {Object.keys(messages).map((date, idx) => (
                            <React.Fragment key={date}>
                              <MessageDate>{date}</MessageDate>

                              {messages[date].map(message => (
                                <MessageComponent
                                  key={message.id}
                                  message={message}
                                />
                              ))}
                            </React.Fragment>
                          ))}
                        </HistoryContainer>

                        <SendMessageContainer>
                          <Form ref={formRef} onSubmit={handleSubmit}>
                            <SendMessageEditorContainer>
                              <RichTextArea
                                name='message'
                                placeholder='Escrever mensagem'
                                maxLength={70000}
                                defaultValue={draftDemandByParam || ''}
                                setDraft={(draft) => dispatch(setDraftMessage(queryParamDemandId, draft))}
                              />
                            </SendMessageEditorContainer>

                            <SendMessageButton
                              disabled={submitLoading}
                              type='submit'
                            >
                              {!submitLoading && (
                                <i className='fas fa-arrow-right fa-lg' />
                              )}
                              {submitLoading && (
                                <i className='fas fa-spinner  fa-lg fa-spin' />
                              )}
                            </SendMessageButton>
                          </Form>
                        </SendMessageContainer>
                      </>
                    )}
                  </Body>

                  <Toolbar>
                    <ToolbarItemContainerButton
                      onClick={() => setIsTagModalOpen(true)}
                    >
                      <i
                        className='fas fa-tags fa-2x'
                        style={{ fontSize: '1.4rem' }}
                      />

                      <div>
                        <h4>Tags do cliente</h4>

                        <div className='tags'>
                          {customerData?.tags?.map(tag => (
                            <TagComponent
                              disabled
                              key={tag.id}
                              name={tag.name}
                              description={tag.description}
                            />
                          ))}
                        </div>
                      </div>
                    </ToolbarItemContainerButton>

                    <ToolbarItemContainerButton
                      onClick={() =>
                        setPageSelected(
                          pageSelected === 'PROFILE' ? 'MESSAGES' : 'PROFILE'
                        )
                      }
                    >
                      {pageSelected === 'PROFILE' ? (
                        <i className='far fa-comment-dots fa-2x'></i>
                      ) : (
                        <i className='far fa-user-circle fa-2x'></i>
                      )}
                      <div>
                        <p className='button'>
                          {pageSelected === 'PROFILE'
                            ? 'Mensagens'
                            : 'Detalhes'}
                        </p>
                      </div>
                    </ToolbarItemContainerButton>
                    <ToolbarItemContainer>
                      <i className='far fa-calendar-alt fa-2x'></i>
                      <div>
                        <h4>Previsto para</h4>
                        <p>{formatDate(state.demand.deleveryForecast)}</p>
                      </div>
                    </ToolbarItemContainer>
                    <ToolbarItemContainer>
                      <i className='far fa-calendar-alt fa-2x'></i>
                      <div>
                        <h4>Criado em</h4>
                        <p>{formatDate(state.demand.createdAt)}</p>
                      </div>
                    </ToolbarItemContainer>

                    <ToolbarItemContainerButton
                      onClick={() => setIsChangeStatusModalOpen(true)}
                    >
                      <i className='fas fa-tasks fa-2x'></i>
                      <div>
                        <p className='button'>Alterar Status</p>
                      </div>
                    </ToolbarItemContainerButton>

                    <ToolbarItemContainerButton
                      onClick={() => setIsChangeDemandPrivacyModalOpen(true)}
                    >
                      <i
                        className={`fas fa-${state.demand.private ? '' : 'un'
                          }lock fa-2x`}
                      ></i>
                      <div>
                        <p className='button'>
                          Tornar {state.demand.private ? 'não' : ''} sigilosa
                        </p>
                      </div>
                    </ToolbarItemContainerButton>
                    <ToolbarItemContainerButton
                      onClick={() => setIsAlterBusinessModalOpen(true)}
                    >
                      <i className='far fa-lightbulb fa-2x'></i>

                      <div>
                        <p className='button'>Alterar negócio</p>
                      </div>
                    </ToolbarItemContainerButton>
                    {
                      state.demand.statusKey === Constants.DEMAND_STATUSES.OPEN.key && (
                        <>
                          <ToolbarItemContainerButton
                            onClick={() => setIsSendPrivateMessageModalOpen(true)}
                          >
                            <i className='far fa-comment-dots fa-2x'></i>

                            <div>
                              <p className='button'>Mensagem Privada</p>
                            </div>
                          </ToolbarItemContainerButton>
                        </>
                      )
                    }
                    {state.demand.statusKey !==
                      Constants.DEMAND_STATUSES.OPEN.key && (
                        <>
                          <ToolbarItemContainerButton
                            onClick={() => setIsChangeSLAModalOpen(true)}
                          >
                            <i className='far fa-clock fa-2x'></i>
                            <div>
                              <p className='button'>Alterar SLA</p>
                            </div>
                          </ToolbarItemContainerButton>

                          <ToolbarItemContainerButton
                            onClick={() => setIsChangeDemandTypeModalOpen(true)}
                          >
                            <i className='fas fa-info-circle fa-2x'></i>
                            <div>
                              <p className='button'>Alterar tipo</p>
                            </div>
                          </ToolbarItemContainerButton>

                          <ToolbarItemContainerButton
                            onClick={handlePriorizeDemand}
                          >
                            <i className='fas fa-arrow-circle-up fa-2x'></i>
                            <div>
                              <p className='button'>Priorizar</p>
                            </div>
                          </ToolbarItemContainerButton>

                          <ToolbarItemContainerButton
                            onClick={() => setIsSendPrivateMessageModalOpen(true)}
                          >
                            <i className='far fa-comment-dots fa-2x'></i>

                            <div>
                              <p className='button'>Mensagem Privada</p>
                            </div>
                          </ToolbarItemContainerButton>



                          <ToolbarItemContainerButton
                            onClick={() => setIsChangeTitleModalOpen(true)}
                          >
                            <i className='fas fa-paragraph fa-2x'></i>
                            <div>
                              <p className='button'>Alterar Título</p>
                            </div>
                          </ToolbarItemContainerButton>
                          {
                            state.demand.demandTypeName === 'Registro de Marca no INPI' && (
                              <ToolbarItemContainerButton
                                onClick={() => setIsChangeProcessModalOpen(true)}
                              >
                                <i class="fas fa-regular fa-registered fa-2x"></i>
                                <div>
                                  <p className='button'>Número de Processo</p>
                                </div>
                              </ToolbarItemContainerButton>
                            )

                          }
                          <ToolbarItemContainerButton
                            onClick={() => setIsChangeUserModalOpen(true)}
                          >
                            <i className='fas fa-user fa-2x'></i>
                            <div>
                              <p className='button'>Gerenciar Usuários</p>
                            </div>
                          </ToolbarItemContainerButton>
                        </>
                      )}

                    {!state.demand.journeyId && !state.demand.preJourneyTypeId && (
                      <ToolbarItemContainerButton
                        onClick={_openAddDemandToJourneyModal}
                      >
                        <i className='fas fa-road  fa-2x'></i>

                        <div>
                          <p className='button'>Adicionar a Jornada</p>
                        </div>
                      </ToolbarItemContainerButton>
                    )}
                    {state.demand.journeyId && (
                      <ToolbarItemContainerButton
                        onClick={() =>
                          _handleRemoveDemandFromJourney(state.demand.id)
                        }
                      >
                        <i
                          className='fas fa-road  fa-2x'
                          style={{
                            color: 'red',
                          }}
                        />

                        <div>
                          <p className='button'>Remover da Jornada</p>
                        </div>
                      </ToolbarItemContainerButton>
                    )}
                  </Toolbar>
                </main>

                <Loading open={loading} />
              </>
            )}
          </Modal>

          <ConfirmationModal {...confirmationModal} />

          <SendFormModal
            modalData={state.demand}
            open={isModalSendFormOpen}
            handleClose={handleSendFormModalClose}
          />
          <ChangeStatusModal
            modalData={state.demand}
            open={isChangeStatusModalOpen}
            handleClose={handleChangeStatusModalClose}
            isChangeStatusModalOpen={isChangeStatusModalOpen}
            submit
          />
          <ChangeDemandTypeModal
            modalData={state.demand}
            open={isChangeDemandTypeModalOpen}
            handleClose={handleChangeDemandTypeModalClose}
          />
          <ChangeDemandPrivacyModal
            modalData={state.demand}
            open={isChangeDemandPrivacyModalOpen}
            handleClose={handleChangeDemandPrivacyModalClose}
          />
          <ChangeSLAModal
            modalData={state.demand}
            open={isChangeSLAModalOpen}
            handleClose={handleChangeSLAModalClose}
          />
          <ChangeInternSLAModal
            modalData={state.demand}
            open={isChangeInternSLAModalOpen}
            handleClose={() => setIsChangeInternSLAModalOpen(false)}
          />
          <ChangeDemandTitleModal
            modalData={state.demand}
            open={isChangeTitleModalOpen}
            handleClose={handleChangeTitleModalClose}
          />
          <ChangeDemandProcessNumberModal
            modalData={state.demand}
            open={isChangeProcessModalOpen}
            handleClose={handleDemandProcessModalClose}
          />
          <ChangeUserModal
            modalData={{
              users: customerData?.users,
              following: state.demand?.following,
              demandId: state.demand?.id,
            }}
            open={isChangeUserModalOpen}
            isLoading={loading}
            fetchData={fetchData}
            handleClose={handleChangeUserModalClose}
          />
          <ChangeDemandResponsibleModal
            modalData={state.demand}
            open={isChangeDemandResponsibleModal}
            handleClose={wasSuccess => {
              if (wasSuccess) fetchData();
              setIsChangeDemandResponsibleModal(false);
            }}
          />
          <DeleteMessageConfirmationModal
            demandId={state.demand.id}
            messageId={isDeleteMessageConfirmationModalOpen}
            open={isDeleteMessageConfirmationModalOpen}
            handleClose={handleDeleteMessageConfirmationModalClose}
          />
          <SendPrivateMessageModal
            modalData={state.demand}
            open={isSendPrivateMessageModalOpen}
            handleClose={handleSendPrivateMessageModalClose}
          />

          <AlterBusinessModal
            open={isAlterBusinessModalOpen}
            setOpen={() => {
              setIsAlterBusinessModalOpen(false)
              fetchData()
            }}
            demand={state.demand}
          />

          <ModalTriagem
            open={isDemandTriagemModalOpen}
            modalData={{
              ...state.demand,
              demandId: state.demand.id,
              backofficeUserId: queryParamBackofficeUserId,
              demandTypeId: queryParamDemandTypeId,
            }}
            SLAExterno={sla}
            handleClose={handleChangeResponsibleModalClose}
          />

          <TagsModal
            visible={isTagModalOpen}
            actualTags={customerData?.tags || []}
            handleFetchTags={fetchData}
            handleRemoveTag={async tagId => {
              try {
                await customerApi.removeTag(state.demand.customerId, tagId);
              } catch (error) { }
            }}
            handleAddTag={async (tagId, data) => {
              try {
                await customerApi.addTag(state.demand.customerId, tagId, data);
              } catch (error) { }
            }}
            handleCloseTagsModal={() => {
              setIsTagModalOpen(false);
            }}
          />

          <AddDemandToJourneyModal
            open={isAddDemandToJourneyModalOpen}
            handleClose={_closeAddDemandToJourneyModal}
            demandId={state.demand.id}
            demandName={state.demand.name}
            customerId={state.demand.customerId}
          />

          <DocumentViewer {...state.documentViewer} />
        </Container>
      )) ||
        null
      }
    </>
  );
}
