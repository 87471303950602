import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { objectToURLSearchParamsString } from '../../utils';
import { Constants } from '../../utils/Constants';
import ButtonsModal from '../buttonsModal/ButtonsModal';
import { StyledLink } from './csModals.styles';
import { RegisterActivityModal } from './RegisterActivityModal';

export const ButtonOptionsModal = ({
  open,
  data,
  title,
  isActiveContact = false,
  description,
  fetchData,
  handleCloseButtonsModal,
}) => {
  const demandTypes = useSelector(state => state.demandTypes.all);
  const [registerActivityModal, setRegisterActivityModal] = useState({
    open: false,
    customer: {},
    fetchData,
    isActiveContact,
    handleClose: () => {},
  });
  const lowEngagementDemandParams = useCallback(
    ({ name, id }) =>
      objectToURLSearchParamsString({
        name: `${Constants.DEMAND_TYPES.ENGAGEMENT_MEETING.key} - ${name}`,
        descriptionKey: 'LOW_ENGAGEMENT',
        customerId: id,
        demandTypeId: demandTypes?.find(
          demand =>
            demand.name === Constants.DEMAND_TYPES.ENGAGEMENT_MEETING.key
        )?.id,
      }),
    [demandTypes]
  );

  const activeContactDemandParams = useCallback(
    ({ name, id, ActiveContact }) =>
      objectToURLSearchParamsString(
        !!ActiveContact
          ? {
              name: `Reunião Contato Ativo - ${name}`,
              descriptionKey: !!ActiveContact
                ? 'SUCCESIVE_CONTACTS'
                : 'FIRST_CONTACT',
              customerId: id,
              demandTypeId: demandTypes?.find(
                demand =>
                  demand.name ===
                  Constants.DEMAND_TYPES.ACTIVE_CONTACT_MEETING.key
              )?.id,
              backofficeUserId: ActiveContact.backofficeUserId,
            }
          : {
              name: `Reunião Contato Ativo - ${name}`,
              descriptionKey: !!ActiveContact
                ? 'SUCCESIVE_CONTACTS'
                : 'FIRST_CONTACT',
              customerId: id,
              demandTypeId: demandTypes?.find(
                demand =>
                  demand.name ===
                  Constants.DEMAND_TYPES.ACTIVE_CONTACT_MEETING.key
              )?.id,
            }
      ),

    [demandTypes]
  );

  const handleOpenRegisterActivityModal = customer => () => {
    setRegisterActivityModal(state => ({
      ...state,
      open: true,
      customer,
      fetchData,
      isActiveContact,
      handleClose: () => {
        setRegisterActivityModal(state => ({
          ...state,
          open: false,
          customer: {},
          fetchData,
          handleClose: () => {},
        }));
      },
    }));
  };

  // if customer has engagement demand, don't show suggest meeting
  const buttons = !data.engagement?.demandId
    ? [
        {
          element: (
            <StyledLink
              to={{
                pathname: `/new/demands`,
                search: isActiveContact
                  ? activeContactDemandParams(data)
                  : lowEngagementDemandParams(data),
              }}
            >
              Sugerir reunião
            </StyledLink>
          ),
        },
        {
          onClick: handleOpenRegisterActivityModal(data),
          element: 'Registrar nova atividade',
        },
      ]
    : [
        {
          onClick: handleOpenRegisterActivityModal(data),
          element: 'Registrar nova atividade',
        },
      ];
  return (
    <>
      <ButtonsModal
        open={open}
        title={title}
        description={description}
        buttons={buttons}
        handleCloseButtonsModal={handleCloseButtonsModal}
      />
      <RegisterActivityModal {...registerActivityModal} />
    </>
  );
};
