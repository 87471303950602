import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Loading from '../../../components/Loading';
import MiniDrawer from '../../../components/MiniDrawer';
import {
  Container,
  Title,
  Section,
  SectionTitle,
  SectionContent,
  CustomerListsContainer,
  CustomerListContent,
  SimpleTable,
} from '../Dashboard.styles';
import { Constants } from '../../../utils/Constants';
import ConfirmationModal from '../../../components/confirmationModal/ConfirmationModal';
import customerOnboardingApi from '../../../services/apis/customerOnboardingApi';
import { toast } from 'react-toastify';
import { formatDate, getDifferenceInBusinessDays } from '../../../utils';
import moment from 'moment';
import OnboardingService from '../../../services/onboardingService';

const onboardingService = new OnboardingService();

export default function OnboardingDashboard() {
  const [state, setState] = useState({
    customers: null,
    confirmationModal: {},
    metrics: {},
    onboarding: {
      customersWaitingForOnboarding: [],
      customersOnOnboarding: [],
    },
  });
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);

      const dashboardData = await onboardingService.getOnboardingDashboard();

      setState(state => ({
        ...state,
        metrics: dashboardData.metrics,
        onboarding: dashboardData.onboarding,
      }));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const _handleSetCustomerOnboardingMeetingSuggestedDate = customer => {
    setState({
      ...state,
      confirmationModal: {
        open: true,
        rejectCallback: () =>
          setState({
            ...state,
            confirmationModal: {},
          }),
        resolveCallback: () => {
          customerOnboardingApi
            .setCustomerOnboardingMeetingSuggestedAt(customer.id)
            .then(() => {
              toast.success(
                `Registro de reunião de onboarding sugerida para o ${customer.name} feito com sucesso!`
              );
              fetchData();
              setState({
                ...state,
                confirmationModal: {},
              });
            });
        },
        title: 'Registrar sugestão de reunião de onboarding',
        description: `Está ação irá registrar que a sugestão da reunião de onboarding do cliente "${customer.name}" foi feita hoje.`,
      },
    });
  };

  const _handleChangeCustomerOnboardingStatusToOnOnboarding = customer => {
    setState({
      ...state,
      confirmationModal: {
        open: true,
        rejectCallback: () =>
          setState({
            ...state,
            confirmationModal: {},
          }),
        resolveCallback: () => {
          customerOnboardingApi
            .updateCustomerOnboardingStatus(customer.id, {
              status: Constants.ONBOARDING_STATUSES.ON_ONBOARDING,
            })
            .then(() => {
              toast.success(
                `Status do onboarding do cliente ${customer.name} alterado com sucesso!`
              );
              fetchData();
              setState({
                ...state,
                confirmationModal: {},
              });
            });
        },
        title: 'Reunião de onboarding realizada',
        description: `Está ação irá alterar o status do onboarding do cliente "${customer.name}" para "em onboarding".`,
      },
    });
  };

  const _handleChangeCustomerOnboardingStatusToFinished = customer => {
    setState({
      ...state,
      confirmationModal: {
        open: true,
        rejectCallback: () =>
          setState({
            ...state,
            confirmationModal: {},
          }),
        resolveCallback: () => {
          customerOnboardingApi
            .updateCustomerOnboardingStatus(customer.id, {
              status: Constants.ONBOARDING_STATUSES.ONBOARDING_FINISHED,
            })
            .then(() => {
              toast.success(
                `Status do onboarding do cliente ${customer.name} alterado com sucesso!`
              );
              fetchData();
              setState({
                ...state,
                confirmationModal: {},
              });
            });
        },
        title: 'Cliente ativado',
        description: `Está ação irá alterar o status do onboarding do cliente "${customer.name}" para "onboarding finalizado".`,
      },
    });
  };

  const getActivationWaitingDays = date => {
    return getDifferenceInBusinessDays(moment(date), moment());
  };

  return (
    <React.Fragment>
      <MiniDrawer currentExpaded='cs'>
        <Container>
          <Loading open={loading} />

          <Title>Onboarding</Title>

          <Section>
            <SectionTitle>Clientes</SectionTitle>

            <SectionContent>
              <CustomerListsContainer>
                <CustomerListContent>
                  <h3>Clientes aguardando onboarding</h3>

                  <h2>
                    Total:{' '}
                    {state.onboarding.customersWaitingForOnboarding.length}
                  </h2>

                  <SimpleTable.Container>
                    <SimpleTable.Table>
                      <SimpleTable.TableHead>
                        <SimpleTable.TableRow>
                          <SimpleTable.TableHeadCell
                            style={{
                              width: '55%',
                            }}
                          >
                            Clientes
                          </SimpleTable.TableHeadCell>
                          <SimpleTable.TableHeadCell
                            style={{
                              width: '15%',
                            }}
                          >
                            Data da venda
                          </SimpleTable.TableHeadCell>
                          <SimpleTable.TableHeadCell
                            style={{
                              width: '15%',
                            }}
                          >
                            Reunião sugerida em
                          </SimpleTable.TableHeadCell>
                          <SimpleTable.TableHeadCell
                            style={{
                              width: '15%',
                            }}
                          >
                            Reunião realizada
                          </SimpleTable.TableHeadCell>
                        </SimpleTable.TableRow>
                      </SimpleTable.TableHead>

                      <SimpleTable.TableBody>
                        {state.onboarding.customersWaitingForOnboarding.length >
                        0 ? (
                          state.onboarding.customersWaitingForOnboarding.map(
                            onboardingData => (
                              <SimpleTable.TableRow
                                key={onboardingData.customerId}
                              >
                                <SimpleTable.TableData>
                                  <Link
                                    to={`/customers/${onboardingData.customerId}`}
                                  >
                                    {onboardingData.customer.isSpecificDemand
                                      ? 'Demanda Específica - ' +
                                        onboardingData.customer.name
                                      : '' + onboardingData.customer.name}
                                  </Link>
                                </SimpleTable.TableData>
                                <SimpleTable.TableData>
                                  <span>
                                    {onboardingData.customer.saleDate
                                      ? formatDate(
                                          moment(
                                            onboardingData.customer.saleDate
                                          )
                                        )
                                      : 'Indefinido'}
                                  </span>
                                </SimpleTable.TableData>
                                <SimpleTable.TableData>
                                  {onboardingData.onboardingMeetingSuggestedAt ? (
                                    <span>
                                      {formatDate(
                                        moment(
                                          onboardingData.onboardingMeetingSuggestedAt
                                        )
                                      )}
                                    </span>
                                  ) : (
                                    <SimpleTable.TableDataButton
                                      style={{
                                        backgroundColor: '#1994FF',
                                        fontWeight: 'bold',
                                        color: 'white',
                                      }}
                                      title='Sugerir reunião de onboarding'
                                      onClick={() =>
                                        _handleSetCustomerOnboardingMeetingSuggestedDate(
                                          onboardingData.customer
                                        )
                                      }
                                    >
                                      Sugerir reunião
                                    </SimpleTable.TableDataButton>
                                  )}
                                </SimpleTable.TableData>
                                <SimpleTable.TableData>
                                  <SimpleTable.TableDataButton
                                    style={{
                                      backgroundColor:
                                        onboardingData.onboardingMeetingSuggestedAt
                                          ? '#1ab394'
                                          : undefined,
                                      fontWeight: 'bold',
                                      color: 'white',
                                    }}
                                    title={
                                      onboardingData.onboardingMeetingSuggestedAt
                                        ? 'Registrar'
                                        : 'Você deve sugerir a reunião antes de registrar'
                                    }
                                    disabled={
                                      !onboardingData.onboardingMeetingSuggestedAt
                                    }
                                    onClick={() =>
                                      _handleChangeCustomerOnboardingStatusToOnOnboarding(
                                        onboardingData.customer
                                      )
                                    }
                                  >
                                    Registrar reunião
                                  </SimpleTable.TableDataButton>
                                </SimpleTable.TableData>
                              </SimpleTable.TableRow>
                            )
                          )
                        ) : (
                          <SimpleTable.TableRow>
                            <SimpleTable.TableData>
                              <span>Nenhum cliente </span>
                            </SimpleTable.TableData>
                          </SimpleTable.TableRow>
                        )}
                      </SimpleTable.TableBody>
                    </SimpleTable.Table>
                  </SimpleTable.Container>
                </CustomerListContent>

                <CustomerListContent>
                  <h3>Clientes em Onboarding</h3>
                  <h2>
                    Total: {state.onboarding.customersOnOnboarding.length}
                  </h2>

                  <SimpleTable.Container>
                    <SimpleTable.Table>
                      <SimpleTable.TableHead>
                        <SimpleTable.TableRow>
                          <SimpleTable.TableHeadCell
                            style={{
                              width: '50%',
                            }}
                          >
                            Clientes
                          </SimpleTable.TableHeadCell>
                          <SimpleTable.TableHeadCell
                            style={{
                              width: '25%',
                            }}
                          >
                            Ativar cliente
                          </SimpleTable.TableHeadCell>
                          <SimpleTable.TableHeadCell
                            style={{
                              width: '25%',
                            }}
                          >
                            Aguardando Ativação (dias úteis)
                          </SimpleTable.TableHeadCell>
                        </SimpleTable.TableRow>
                      </SimpleTable.TableHead>

                      <SimpleTable.TableBody>
                        {state.onboarding.customersOnOnboarding.length > 0 ? (
                          state.onboarding.customersOnOnboarding.map(
                            onboardingData => (
                              <SimpleTable.TableRow
                                key={onboardingData.customerId}
                              >
                                <SimpleTable.TableData
                                  style={{
                                    ...(!!onboardingData.onboardingMeetingFinishedAt &&
                                    getActivationWaitingDays(
                                      moment(
                                        onboardingData.onboardingMeetingFinishedAt
                                      )
                                    ) > 20
                                      ? {
                                          color: 'red',
                                          fontWeight: 'bolder',
                                        }
                                      : {}),
                                  }}
                                >
                                  <Link
                                    to={`/customers/${onboardingData.customerId}`}
                                  >
                                    {onboardingData.customer.isSpecificDemand
                                      ? 'Demanda Específica - ' +
                                        onboardingData.customer.name
                                      : '' + onboardingData.customer.name}
                                  </Link>
                                </SimpleTable.TableData>
                                <SimpleTable.TableData>
                                  <SimpleTable.TableDataButton
                                    style={{
                                      backgroundColor: 'blueviolet',
                                      fontWeight: 'bold',
                                      color: 'white',
                                    }}
                                    onClick={() =>
                                      _handleChangeCustomerOnboardingStatusToFinished(
                                        onboardingData.customer
                                      )
                                    }
                                  >
                                    Ativar cliente
                                  </SimpleTable.TableDataButton>
                                </SimpleTable.TableData>
                                <SimpleTable.TableData
                                  style={{
                                    ...(!!onboardingData.onboardingMeetingFinishedAt &&
                                    getActivationWaitingDays(
                                      moment(
                                        onboardingData.onboardingMeetingFinishedAt
                                      )
                                    ) > 20
                                      ? {
                                          color: 'red',
                                          fontWeight: 'bolder',
                                        }
                                      : {}),
                                  }}
                                >
                                  {onboardingData.onboardingMeetingFinishedAt
                                    ? getActivationWaitingDays(
                                        moment(
                                          onboardingData.onboardingMeetingFinishedAt
                                        )
                                      )
                                    : 'Indefinido'}
                                </SimpleTable.TableData>
                              </SimpleTable.TableRow>
                            )
                          )
                        ) : (
                          <SimpleTable.TableRow>
                            <SimpleTable.TableData>
                              <span>Nenhum cliente </span>
                            </SimpleTable.TableData>
                          </SimpleTable.TableRow>
                        )}
                      </SimpleTable.TableBody>
                    </SimpleTable.Table>
                  </SimpleTable.Container>
                </CustomerListContent>

                <h1>Métricas Onboarding</h1>

                <CustomerListContent>
                  <h3>Últimos 30 dias</h3>

                  <SimpleTable.Container>
                    <SimpleTable.Table>
                      <SimpleTable.TableHead>
                        <SimpleTable.TableRow>
                          <SimpleTable.TableHeadCell
                            style={{
                              width: '50%',
                            }}
                          >
                            Clientes
                          </SimpleTable.TableHeadCell>
                          <SimpleTable.TableHeadCell
                            style={{
                              width: '25%',
                            }}
                          >
                            Data da venda
                          </SimpleTable.TableHeadCell>
                          <SimpleTable.TableHeadCell
                            style={{
                              width: '25%',
                            }}
                          >
                            Reunião de sugerida em
                          </SimpleTable.TableHeadCell>
                        </SimpleTable.TableRow>
                      </SimpleTable.TableHead>

                      <SimpleTable.TableBody>
                        {state.metrics.last30days &&
                        state.metrics.last30days.length > 0 ? (
                          state.metrics.last30days.map(metric => (
                            <SimpleTable.TableRow key={metric.customerId}>
                              <SimpleTable.TableData>
                                <Link to={`/customers/${metric.customerId}`}>
                                  {metric.customer.isSpecificDemand
                                    ? 'Demanda Específica - ' +
                                      metric.customer.name
                                    : '' + metric.customer.name}
                                </Link>
                              </SimpleTable.TableData>
                              <SimpleTable.TableData>
                                <span>
                                  {formatDate(moment(metric.customer.saleDate))}
                                </span>
                              </SimpleTable.TableData>
                              <SimpleTable.TableData>
                                <span>
                                  {formatDate(
                                    moment(metric.onboardingMeetingSuggestedAt)
                                  )}
                                </span>
                              </SimpleTable.TableData>
                            </SimpleTable.TableRow>
                          ))
                        ) : (
                          <SimpleTable.TableRow>
                            <SimpleTable.TableData>
                              <span>Nenhum cliente </span>
                            </SimpleTable.TableData>
                          </SimpleTable.TableRow>
                        )}
                      </SimpleTable.TableBody>
                    </SimpleTable.Table>
                  </SimpleTable.Container>
                </CustomerListContent>

                <CustomerListContent>
                  <h3>Últimos 90 dias</h3>

                  <SimpleTable.Container>
                    <SimpleTable.Table>
                      <SimpleTable.TableHead>
                        <SimpleTable.TableRow>
                          <SimpleTable.TableHeadCell
                            style={{
                              width: '50%',
                            }}
                          >
                            Clientes
                          </SimpleTable.TableHeadCell>
                          <SimpleTable.TableHeadCell
                            style={{
                              width: '25%',
                            }}
                          >
                            Data da venda
                          </SimpleTable.TableHeadCell>
                          <SimpleTable.TableHeadCell
                            style={{
                              width: '25%',
                            }}
                          >
                            Reunião de sugerida em
                          </SimpleTable.TableHeadCell>
                        </SimpleTable.TableRow>
                      </SimpleTable.TableHead>

                      <SimpleTable.TableBody>
                        {state.metrics.last90days &&
                        state.metrics.last90days.length > 0 ? (
                          state.metrics.last90days.map(metric => (
                            <SimpleTable.TableRow key={metric.customerId}>
                              <SimpleTable.TableData>
                                <Link to={`/customers/${metric.customerId}`}>
                                  {metric.customer.isSpecificDemand
                                    ? 'Demanda Específica - ' +
                                      metric.customer.name
                                    : '' + metric.customer.name}
                                </Link>
                              </SimpleTable.TableData>
                              <SimpleTable.TableData>
                                <span>
                                  {formatDate(moment(metric.customer.saleDate))}
                                </span>
                              </SimpleTable.TableData>
                              <SimpleTable.TableData>
                                <span>
                                  {formatDate(
                                    moment(metric.onboardingMeetingSuggestedAt)
                                  )}
                                </span>
                              </SimpleTable.TableData>
                            </SimpleTable.TableRow>
                          ))
                        ) : (
                          <SimpleTable.TableRow>
                            <SimpleTable.TableData>
                              <span>Nenhum cliente </span>
                            </SimpleTable.TableData>
                          </SimpleTable.TableRow>
                        )}
                      </SimpleTable.TableBody>
                    </SimpleTable.Table>
                  </SimpleTable.Container>
                </CustomerListContent>
              </CustomerListsContainer>
            </SectionContent>
          </Section>

          <ConfirmationModal {...state.confirmationModal} />
        </Container>
      </MiniDrawer>
    </React.Fragment>
  );
}
