import moment from 'moment';

export const twelveMonthsAgo = moment().subtract(12, 'months').startOf('month');
export const now = moment();

export const filterFinishedDemands = (demands) => demands.filter(
  demand => demand.finishedCount > 0 && demand.statusKey === 'FINISHED' && !demand.isMassDemand
);

export const sortDemandsByMonthYear = (demands) => demands
  .sort((a, b) => moment(a.monthYear, 'MMM YYYY')
  .diff(moment(b.monthYear, 'MMM YYYY'))
);

export const calculatePercentage = (demandByMounth) => {
  const total = demandByMounth.map(demand => demand.finishedCount).reduce((acc, demand) => acc + demand, 0);
  const percentages = demandByMounth.map(demand => Math.round(demand.finishedCount / total * 100));
  return percentages;
}