import { makeStyles } from '@material-ui/core/styles';

export const ClassStyle = makeStyles(() => ({
  content: {
    background: '#fff',
    height: '100%',
    width: '100%',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.25rem',
      height: '0.25rem',
    },
    '&::-webkit-scrollbar-track': {
      background: '#DDE1E6',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#666',
      borderRadius: '10px',
      width: '0.25rem',
      height: '10px',
    },
  },
  cardCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  card: {
    margin: '3rem 0',
    width: '100%',
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    color: '#000',
    padding: '2rem 0 1rem 2rem',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  },
  subTitle: {
    fontSize: '1rem',
    fontWeight: '200',
    color: '#000',
    padding: '0 0 1rem 2rem',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  },
  tagTitle: {
    fontSize: '1rem',
    fontWeight: 'bolder',
    textTransform: 'uppercase',
    color: '#000',
  },
  iconSum: {
    fontSize: '2.5rem',
    fontWeight: '100',
  },
  main: {
    margin: '3rem 0',
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.25rem',
      height: '0.25rem',
    },
    '&::-webkit-scrollbar-track': {
      background: '#DDE1E6',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#666',
      borderRadius: '10px',
      width: '0.25rem',
      height: '10px',
    },
  },
  sectionMain: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    width: '90%',
    margin: '3rem auto',
    height: '100%',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0',
      height: '0',
    },
    '&::-webkit-scrollbar-track': {
      background: '#DDE1E6',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#666',
      borderRadius: '10px',
      width: '0',
      height: '0',
    },
  },
  buttonContent: {
    display: 'flex',
    gap: '3rem',
    alignItems: 'center',
    textAlign: 'justify',
  },
  cardBorder: {
    border: '1px solid #D1D1D1',
    borderRadius: '10px',
    width: '100%',
    height: '100%',
    margin: '0 auto',
  },
  button: {
    background: "rgba(26, 179, 148, 0.1)",
    backgroundHover: "rgba(26, 179, 148, 0.2)",
    color: "rgba(26, 179, 148, 0.5)",
    height: "2.25rem",
    width: "9rem",
    borderRadius: "0.25rem",
    border: "none",
    fontWeight: "bold",
    fontSize: "1rem",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    cursor: "pointer",
    margin: '1rem 0 1rem 2rem',
  },
}));