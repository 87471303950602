export default class CustomerSale {
  id = null;
  contractType = null;
  customerId = null;
  saleDescription = null;
  saleDate = null;

  constructor(saleData = {}) {
    this.id = saleData.id;
    this.contractType = saleData.contractType;
    this.customerId = saleData.customerId;
    this.saleDescription = saleData.saleDescription;
    this.saleDate = saleData.saleDate;
  }

  getRegisterSaleData() {
    return {
      contractType: this.contractType,
      customerId: this.customerId,
      saleDescription: this.saleDescription,
      saleDate: this.saleDate,
    };
  }
}
