import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import clsx from 'clsx';
import apiOld from '../services/Api';
import { useDispatch } from 'react-redux';
import { openDemandModal } from '../routes';
import DrawerAccordion from './DrawerAccordion';
import history from '../history';
import SearchModal from './searchModal/SearchModal';
import { Link } from 'react-router-dom';
import moment from 'moment';

const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    color: '#676a6c',
    background: '#f3f3f4',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: '#2f4050',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(9) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
    background: '#2f4050',
  },
  content: {
    flexGrow: 1,
    padding: '24px',
    background: '#f3f3f4',
    minHeight: 'calc(100% - 64px)',
    marginTop: '64px',
    width: '100%',
  },
  containerMenu: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logout: {
    textDecoration: 'none',
    cursor: 'pointer',
    verticalAlign: 'middle',
    border: 'none',
    backgroundColor: 'transparent',
  },
  notificationsIcon: {
    marginLeft: 'auto',
    marginRight: '20px',
  },
  notificationsContainer: {
    position: 'absolute',
    backgroundColor: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '20px',
    border: 'solid 1px #707070',
    width: '350px',
    height: '300px',
    right: '20px',
    top: '60px',
    padding: '20px',
    overflowY: 'auto',
    '-webkit-scrollbar': 'none;',
  },
  notificationsContent: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    borderBottom: 'solid 1px #707070',
    cursor: 'pointer',
    marginBottom: '15px',
    textDecoration: 'none',
    border: 'none',
    backgroundColor: 'transparent',
  },
  notificationsTitle: {
    fontSize: '18px',
    fontWeight: '600',
    textDecoration: 'none',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  notificationsDescription: {
    fontSize: '13px',
    fontWeight: '500',
    marginTop: '3px',
    marginBottom: '10px',
    textDecoration: 'none',
    textOverflow: 'ellipsis',
    textAlign: 'left',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  notificationsSeeMore: {
    textDecoration: 'none',
    color: '#676a6c',
    fontWeight: '500',
  },
  main: {
    height: '100%',
    width: '100%',
  },
  mainWithDrawerOpen: {
    width: `calc(100% - ${drawerWidth}px)`,
  },
}));

export default function MiniDrawer({ currentExpaded, main, children }) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(true);
  const [isSearchModalOpen, setIsSearchModalOpen] = React.useState(false);
  const [notifOpen, setNotifOpen] = React.useState(false);
  const [notifications, setNotifications] = React.useState([]);
  const [serverTime, setServerTime] = React.useState();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleNotificationIconClick = async () => {
    if (!notifOpen) {
      await apiOld.put('/users/notifications');
      getUserNotifications();
    }
    setNotifOpen(!notifOpen);
  };

  const handleNotificationClick = notf => {
    if (notf?.type === 'DEMAND') {
      openDemandModal(notf.link);
      setNotifOpen(false);
    }
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    dispatch({
      type: 'LOGOUT',
    });

    history.push('/signIn');
  };

  const getUserNotifications = async () => {
    try {
      const params = {
        limit: 10,
        page: 0,
      };

      const response = await apiOld.get('/users/notifications', { params });

      if (response.data) {
        setNotifications(response.data.userNotifications);
      }
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {

    getUserNotifications();

    const handleNotificationsClick = e => {
      const notificationsElement = document.querySelector('#notifications');
      const notificationsButtonElement = document.querySelector(
        '#notificationsButton'
      );

      if (
        notificationsElement &&
        notificationsButtonElement &&
        !notificationsButtonElement.contains(e.target) &&
        !notificationsElement.contains(e.target)
      ) {
        setNotifOpen(false);
      }
    };
    document.addEventListener('click', handleNotificationsClick);

    apiOld.get('/time').then(response => {
      setServerTime(response.data.time);
    });

    
    return () => {
      document.removeEventListener('click', handleNotificationsClick);
    };
  }, []);

  const localUnixDate = new Date().toLocaleTimeString();
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position='fixed'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Grid container className={classes.containerMenu}>
            <Typography variant='h6' noWrap>
              Bem vindo a NDM Advogados!!
            </Typography>

            <label
              htmlFor='search'
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              Pesquisar
              <input
                onClick={() => {
                  setIsSearchModalOpen(true);
                }}
                style={{
                  width: '150px',
                  marginLeft: '10px',
                  backgroundColor: 'transparent',
                  border: '1px solid #707070',
                  borderRadius: '4px',
                  height: '30px',
                  cursor: 'pointer',
                }}
                id='search'
                type='button'
              ></input>
            </label>

            <p>
              horario local: {localUnixDate}
            </p>
            <p style={{marginLeft: '5px'}}>
              Horario do servidor: {serverTime}
            </p>

            <IconButton
              color='inherit'
              id='notificationsButton'
              aria-label='Abrir notificações'
              onClick={handleNotificationIconClick}
              className={clsx(classes.notificationsIcon)}
            >
              {notifications.filter(item => item.isReaded === false).length >
              0 ? (
                <NotificationsActiveIcon id='notificationsButton' />
              ) : (
                <NotificationsIcon id='notificationsButton' />
              )}
            </IconButton>
            {notifOpen && (
              <div
                id='notifications'
                className={classes.notificationsContainer}
              >
                {notifications &&
                  notifications.map(item => (
                    <button
                      key={item.id}
                      className={classes.notificationsContent}
                      onClick={() => handleNotificationClick(item)}
                    >
                      <h4 className={classes.notificationsTitle}>
                        {item.title}
                      </h4>

                      <p className={classes.notificationsDescription}>
                        {item.description}
                      </p>
                    </button>
                  ))}
                {notifications.length === 0 && (
                  <p style={{ marginTop: '40%' }}>
                    Você não tem nenhuma notificação ainda!
                  </p>
                )}
                {notifications.length !== 0 && (
                  <Link
                    className={classes.notificationsSeeMore}
                    to='/notifications'
                  >
                    Ver tudo
                  </Link>
                )}
              </div>
            )}

            <button className={classes.logout} onClick={handleLogout}>
              <i className='fa fa-sign-out' />
              Sair
            </button>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <DrawerAccordion open={open} currentExpaded={currentExpaded} />
      </Drawer>

      <main className={classes.content}>
        <SearchModal
          open={isSearchModalOpen}
          handleCloseModal={() => setIsSearchModalOpen(false)}
        />
        <div className={clsx(classes.main, {})}>
          {main}
          {children}
        </div>
      </main>
    </div>
  );
}
