import React, { useEffect, useRef, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import CustomSelectWithSearch from '../../components/input/customSelectorWithSearch/CustomSelectWithSearch';
import Loading from '../../components/Loading';
import MiniDrawer from '../../components/MiniDrawer';
import apiOld from '../../services/Api';
import { toast } from 'react-toastify';
import history from '../../history'
import { useStyles } from './CreateDemandNotification';
import RichTextArea from '../../components/input/richTextArea/RichTextArea';
import MessageService from '../../services/messageService';

const messageService = new MessageService();

export default function CreateDemandMessage() {
  const classes = useStyles();
  const formRef = useRef(null);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [demandsInMass, setDemandsInMass] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const queryString = new URLSearchParams({ isMassDemand: true }).toString();

    apiOld.get(`/demands?${queryString}`).then(response => {
      setDemandsInMass({
        demands: response.data.demands,
        total: response.data.demands.length,
        demandsInMassNames: [...new Set(response.data.demands.map(demand => demand.name))]
      })
    });
    setLoading(false);
  }, []);

  async function handleSubmit(data, { reset }) {
    try {
      setSubmitLoading(true);
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        demandTitle: Yup.string().required('Título da demanda é obrigatório').nullable(true),
        message: Yup.string().required('Mensagem obrigatória'),
      });
      const request = await schema.validate(data, {
        abortEarly: false,
      });

      const requestData = {
        ...request,
        demandTitle: data.demandTitle,
        content: data.message,
      };
      const dataNewMessage = {
        content: requestData.content,
        attachments: data.attachments,
      }

      const listDemandsInMassFromMessage = demandsInMass.demands
        .filter(demand => demand.name === requestData.demandTitle);

      for (let demand of listDemandsInMassFromMessage) {
        await messageService.createTextMessageWithAttachments({
          demandId: demand.id,
          ...dataNewMessage,
        });
      }

      toast.success('mensagens enviadas com sucesso!');
      reset();
      history.push('/new/demands/notification')
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};

        error.inner.forEach(error => {
          validationErrors[error.path] = error.message;

          toast.error(error.message, {
            toastId: 'validationError',
          });
        });

        formRef.current.setErrors(validationErrors);
      }
    } finally {
      setSubmitLoading(false);
    }
  }

  return (
    <React.Fragment>
      <MiniDrawer
        currentExpaded='demands'
        main={
          <Grid container className={classes.main}>
            <Loading open={loading} />
            <Grid container className={classes.mainTitle}>
              <h2 className={classes.title}>Enviar Mensagem para Demanda em Massa</h2>
              <span>Aqui você pode enviar mensagem para uma lista de demanda em massa</span>
            </Grid>
            <Grid container className={classes.mainForm}>
              <Form
                ref={formRef}
                onSubmit={handleSubmit}
                className={classes.form}
              >
                <Grid container>
                  <Grid
                    item={true}
                    className={classes.input}
                    xs={12}
                  >
                    <CustomSelectWithSearch
                      placeholder='Escolha o título da demanda'
                      name='demandTitle'
                      printOptions='on-focus'
                      label='Título da demanda para qual deseja enviar a mensagem'
                      options={demandsInMass?.demandsInMassNames?.map(demand => ({
                        name: demand,
                        value: demand,
                      })) || []}
                    />
                  </Grid>
                  <Grid item={true} className={classes.input} xs={12}>
                    <p className={classes.subTitle} style={{ color: '#666' }}>Mensagem</p>
                    <RichTextArea
                      name='message'
                      label='Mensagem'
                      placeholder='Escrever mensagem'
                      maxLength={70000}
                    />
                  </Grid>
                  <Grid container className={classes.buttonContainer}>
                    <button className={classes.submit} type='submit'>
                      {!submitLoading && 'Enviar'}
                      {submitLoading && (
                        <CircularProgress color='inherit' />
                      )}
                    </button>
                  </Grid>
                </Grid>
              </Form>
            </Grid>
          </Grid>
        }
      />
    </React.Fragment>
  );
}
