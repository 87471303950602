import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import Modal from '../../../../../components/modal/Modal';
import { findNewIndexInIndexesList } from '../../../../../utils';
import Grid from '@material-ui/core/Grid';
import CustomSelect from '../../../../../components/input/CustomSelect';
import { Form } from '@unform/web';
import { EditFieldPageContainer } from './editTemplateFieldModal.styles';
import CustomInput from '../../../../../components/input/CustomInput';
import { Constants } from '../../../../../utils/Constants';
import TemplateFieldService from '../../../../../services/templates/templateFieldService';

export function EditTemplateFieldModal({
  templateField = {
    id: '',
    name: '',
    description: '',
    label: '',
    index: '',
  },
  allTemplateFieldsIndexes = [],
  handleClose,
  open,
}) {
  const formRef = useRef(null);
  const [fieldType, setFieldType] = useState(templateField.type);

  const _updateField = async data => {
    try {
      const newIndex = findNewIndexInIndexesList(
        templateField.index,
        data.index,
        allTemplateFieldsIndexes
      );
      const newFieldData = {
        ...data,
        index: newIndex,
      };

      const field = new TemplateFieldService(templateField.id, newFieldData);

      await field.update();

      toast.success('Campo atualizado com sucesso!');

      handleClose(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal visible={open}>
      <EditFieldPageContainer>
        <Grid
          container
          style={{
            backgroundColor: '#fff',
            padding: '15px',
            borderRadius: '5px',
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
            width: '500px',
          }}
        >
          <Grid container className='titleContainer'>
            <h1>Editar campo</h1>
          </Grid>

          <Grid container>
            <Form
              className='formContainer'
              ref={formRef}
              onSubmit={_updateField}
            >
              <Grid container>
                <Grid item xs={12} className='inputContainer'>
                  <Grid container>
                    <Grid item className='fieldInput' xs={12}>
                      <CustomInput
                        defaultValue={templateField.name}
                        name='name'
                        label='Nome'
                        disabled
                      />
                    </Grid>

                    <Grid item className='fieldInput' xs={12}>
                      <CustomInput
                        defaultValue={templateField.description}
                        name='description'
                        label='Descrição'
                      />
                    </Grid>

                    <Grid item className='fieldInput' xs={12}>
                      <CustomInput
                        defaultValue={templateField.label}
                        name='label'
                        label='Label'
                      />
                    </Grid>

                    <Grid item className='fieldInput' xs={12}>
                      <CustomInput
                        defaultValue={templateField.placeholder}
                        name='placeholder'
                        label='Placeholder'
                      />
                    </Grid>

                    <Grid item className='fieldInput' xs={12}>
                      <CustomSelect
                        name='type'
                        label='Tipo de campo'
                        defaultValue={templateField.type}
                        options={Constants.TEMPLATE_FIELDS_TYPES}
                        onChange={e => setFieldType(e.target.value)}
                      />
                    </Grid>

                    {fieldType === 'options' && (
                      <Grid item className='fieldInput' xs={12}>
                        <CustomInput
                          name='options'
                          label='Opções (separadas por vírgula)'
                        />
                      </Grid>
                    )}
                  </Grid>

                  {allTemplateFieldsIndexes && (
                    <CustomSelect
                      placeholder='Posição'
                      name='index'
                      label='Nova posição'
                      defaultValue={templateField.index}
                      options={allTemplateFieldsIndexes.map(
                        (fieldIndex, idx) => ({
                          name: idx + 1,
                          value: fieldIndex,
                        })
                      )}
                    />
                  )}
                </Grid>

                <Grid container justify='flex-end'>
                  <button
                    type='button'
                    className='submit secondaryButton'
                    onClick={handleClose}
                    style={{ marginRight: '10px' }}
                  >
                    Cancelar
                  </button>

                  <button type='submit' className='submit'>
                    Salvar Alterações
                  </button>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </Grid>
      </EditFieldPageContainer>
    </Modal>
  );
}
