import React, { useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import { columnsScore, styleDataTableScore } from "../menu/dataColumns";
import { ClassStyle } from "../index";
import { AnalysisRiskApi } from "../../../services/apis/analysisRisk";
import usersApi from "../../../services/apis/usersApi";

export default function ScoreAIRByCustomer({ user, setLoading }) {
  const classes = ClassStyle();

  const [answerSectorList, setAnswerSectorList] = useState([]);
  const [risk, setRisk] = useState(null);

  const { getAll: getAllSectors } = AnalysisRiskApi.sector;
  const { getQuestionBySector } = AnalysisRiskApi;

  const data = () => {
    return answerSectorList.map((item, index) => {
      const sectorRisk = risk[index];

      const countQuestions = item?.questions?.length;
      const countAnswersCustomer = item?.answerMarked?.length;
      const finished = countQuestions === countAnswersCustomer;

      return {
        id: item.sector.id,
        section: item.sector.name,
        status: `${finished ? 'Concluído' : 'Pendente'}`,
        progress: `Questões ${countAnswersCustomer}/${countQuestions}`,
        risk: `${sectorRisk?.level || 'Não calculado'}`,
      };
    });
  };

  const currentRisk = async (list) => {
    const risk = (await AnalysisRiskApi.calculateRisk({ answerList: list }))?.data;
    return risk;
  }

  async function fetchData() {
    setLoading(true);

    const userIdByPath = window.location.pathname.split('/')[3];
    const businessIdByPath = window.location.pathname.split('/')[5];
    
    const user = (await usersApi.getUserById(userIdByPath))?.data;
    const allSectors = (await getAllSectors())?.data;
    const answerCustomerByUser = await Promise.all(allSectors.map(async (sector) => {
      return (await getQuestionBySector({ 
        Sector_id: sector.id, 
        Customer_id: user.customerId, 
        Business_id: businessIdByPath,
      }))?.data;
    }));
    const calculateRiskPromises = answerCustomerByUser.map(async (item) => {
      return currentRisk(item?.answerMarked);
    });
    const calculateRisk = await Promise.all(calculateRiskPromises);

    setRisk(calculateRisk);
    setAnswerSectorList(answerCustomerByUser);
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className={classes.cardCenter} style={{ margin: '2.25rem 0' }}>
        <h3 className={classes.subTitle} style={{ fontSize: '2.25rem', paddingBottom: "0" }}>{user?.name}</h3>
        <h3 className={classes.subTitle} style={{ fontSize: '1.5rem' }}>A escala de riso está definida em:</h3>
      </div>
      <div style={{ margin: '4rem auto', width: '85%' }}>
        <DataTable columns={columnsScore} data={data()} customStyles={styleDataTableScore} />
      </div>
    </>
  );
}