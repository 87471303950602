import { combineReducers } from "redux";
import {
  setConfigs,
  getConfigs,
  defaultConfigs,
} from "../../configs/demandsBoardConfigs";
import {
  LOAD_CONFIGS,
  PATCH_DEMANDS_BOARD_CONFIG,
  PATCH_DEMANDS_BOARD_COLUMN_CONFIG,
} from "../actionsTypes/configs";
import produce from "immer";

const DEMANDS_BORAD_INITIAL_STATE = defaultConfigs;

const demandsBoard = produce((draft = DEMANDS_BORAD_INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_CONFIGS: {
      const configs = getConfigs();

      if (!configs) {
        setConfigs(defaultConfigs);
        draft = defaultConfigs;
      } else {
        draft = { ...defaultConfigs, ...configs };
      }

      return draft;
    }
    case PATCH_DEMANDS_BOARD_CONFIG: {
      const patchedConfig = { ...draft, ...action.config };

      setConfigs(patchedConfig);

      draft = patchedConfig;

      return draft;
    }
    case PATCH_DEMANDS_BOARD_COLUMN_CONFIG: {
      const patchedConfig = {
        ...draft,
        columns: { ...draft.columns, ...action.columns },
      };

      setConfigs(patchedConfig);

      draft = patchedConfig;

      return draft;
    }
    default:
      return draft;
  }
}, DEMANDS_BORAD_INITIAL_STATE);

export default combineReducers({
  demandsBoard,
});
