import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

export const SalesGrid = styled(Grid)`
  :last-child {
    padding-bottom: 60px;
  }
`;

export const SalesItemGrid = styled(Grid)``;
