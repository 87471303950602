import React from 'react';
import Linkify from 'react-linkify';

const CustomLinkify = ({ children, ...rest }) => {
  return (
    <Linkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <a
          target='blank'
          href={decoratedHref}
          key={key}
          style={{
            color: '#3366BB',
            fontWeight: '600',
            textDecoration: 'none',
          }}
        >
          {decoratedText}
        </a>
      )}
      {...rest}
    >
      {children}
    </Linkify>
  );
};

export default CustomLinkify;
