import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from '@unform/web';
import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import CheckboxInput from '../../components/input/CheckboxInput';
import CustomInput from '../../components/input/CustomInput';
import CustomSelect from '../../components/input/CustomSelect';
import CustomTextArea from '../../components/input/CustomTextArea';
import FileInput from '../../components/input/FileInput';
import MiniDrawer from '../../components/MiniDrawer';
import history from '../../history';
import TemplateService from '../../services/templates/templateService';
import { formatFileToBase64 } from '../../utils';
import { Constants } from '../../utils/Constants';

const templateService = new TemplateService();

const useStyles = makeStyles(() => ({
  main: {
    background: '#FFF',
  },
  mainTitle: {
    background: '#FFF',
    borderTop: 'solid 2px #e7eaec',
    borderBottom: 'solid 1px #e7eaec',
    padding: '15px',
  },
  line: {
    backgorund: 'reds',
  },
  title: {
    fontSize: '15px',
  },
  mainForm: {
    padding: '10px',
  },
  form: {
    width: '100%',
  },
  input: {
    padding: '10px',
  },
  buttonContainer: {
    justifyContent: 'flex-end',
    padding: '10px',
  },
  submit: {
    padding: '10px 15px',
    fontSize: '17px',
    background: '#1ab394',
    border: 'none',
    color: '#fff',
    borderRadius: '5px',
  },
}));

const FieldInputElement = ({ field, index }) => {
  const classes = useStyles();
  const [fieldType, setFieldType] = useState();

  const _handleFieldTypeInputChange = async e => {
    setFieldType(e.target.value);
  };

  return (
    <Grid
      container
      justify='space-between'
      style={{
        borderBottom: '1px solid gray',
        marginBottom: '10px',
      }}
    >
      <span
        style={{
          fontSize: '14px',
          fontWeight: 'bold',
        }}
      >
        {field}
      </span>

      <Grid container key={field}>
        <CustomInput
          name={`${field}.index`}
          label=''
          value={index + 1}
          disabled
          visible={false}
          hidden={true}
          type='number'
        />

        <Grid item className={classes.input} xs={4}>
          <CustomInput
            name={`${field}.name`}
            label='Nome'
            value={field}
            disabled
          />
        </Grid>

        <Grid item className={classes.input} xs={4}>
          <CustomInput
            defaultValue={field}
            name={`${field}.description`}
            label='Descrição'
          />
        </Grid>

        <Grid item className={classes.input} xs={4}>
          <CustomInput
            defaultValue={field}
            name={`${field}.label`}
            label='Label'
          />
        </Grid>

        <Grid item className={classes.input} xs={4}>
          <CustomInput
            defaultValue={field}
            name={`${field}.placeholder`}
            label='Placeholder'
          />
        </Grid>

        <Grid item className={classes.input} xs={4}>
          <CustomSelect
            name={`${field}.type`}
            label='Tipo de campo'
            options={Constants.TEMPLATE_FIELDS_TYPES}
            onChange={_handleFieldTypeInputChange}
          />
        </Grid>

        {fieldType === 'options' && (
          <Grid item className={classes.input} xs={4}>
            <CustomInput
              name={`${field}.options`}
              label='Opções (separadas por vírgula)'
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default function CreateTemplate() {
  const classes = useStyles();
  const createTemplateFormRef = useRef(null);
  const [state, setState] = useState({
    templateFields: [],
  });

  const _getFieldsFromBase64TemplateFile = async base64TemplateFile => {
    const fields = await templateService.getFieldsFromBase64TemplateFile(
      base64TemplateFile
    );

    setState(prevState => ({
      ...prevState,
      templateFields: fields,
    }));
  };

  const _handleTemplateFileInputChange = async e => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    const base64File = await formatFileToBase64(file);

    _getFieldsFromBase64TemplateFile(base64File);
  };

  async function handleCreateTemplateFormSubmit(templateData) {
    try {
      createTemplateFormRef.current.setErrors({});

      const newTemplate = await templateService.validateAndCreateTemplate(
        templateData
      );

      toast.success('Template criado com sucesso!');

      history.push(`/templates/${newTemplate.id}`);
    } catch (error) {
      console.error(error);

      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};

        error.inner.forEach(error => {
          validationErrors[error.path] = error.message;
          toast.error(error.message, {
            toastId: 'validation-error',
          });
        });

        createTemplateFormRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <React.Fragment>
      <MiniDrawer currentExpaded='templates'>
        <Grid container className={classes.main}>
          <Grid container className={classes.mainTitle}>
            <h2 className={classes.title}>Criar Template</h2>
          </Grid>

          <Grid container className={classes.mainForm}>
            <Form
              ref={createTemplateFormRef}
              onSubmit={handleCreateTemplateFormSubmit}
              className={classes.form}
            >
              <Grid item className={classes.input} xs={12}>
                <FileInput
                  name='templateFile'
                  label='Arquivo'
                  onChange={_handleTemplateFileInputChange}
                />
              </Grid>

              <Grid container className={classes.input}>
                <Grid item xs={12}>
                  <CustomInput name='name' label='Nome' />
                </Grid>

                <Grid item xs={12}>
                  <CustomTextArea
                    name='description'
                    label='Descrição'
                    maxLength='2000'
                  />
                </Grid>

                <Grid item xs={12}>
                  <CustomTextArea name='html' label='Html' maxLength='200000' />
                </Grid>

                <Grid item xs={12}>
                  <CheckboxInput
                    name='backofficeOnly'
                    label='Apenas para o backoffice utilizar'
                  />
                </Grid>

                <Grid item xs={12}>
                  <h3>Campos do template</h3>
                </Grid>

                <p
                  style={{
                    marginBottom: '20px',
                  }}
                >
                  Perguntas que o cliente terá que responder para criar o
                  documento.
                </p>

                {Object.entries(state.templateFields).map(([field], index) => (
                  <FieldInputElement key={field} field={field} index={index} />
                ))}
              </Grid>

              <Grid container className={classes.buttonContainer}>
                <button className={classes.submit} type='submit'>
                  Cadastrar
                </button>
              </Grid>
            </Form>
          </Grid>
        </Grid>
      </MiniDrawer>
    </React.Fragment>
  );
}
