import Modal from "../modal/Modal";
import {
    Container,
    Title,
    Description,
    ButtonContainer,
    Button,
  } from '../buttonsModal/ButtonsModal.styles';
import { useEffect, useRef, useState } from "react";
import { Form } from "@unform/web";
import CustomInput from "../input/CustomInput";
import FileInput from "../input/FileInput";
import { getMarcasById, updateMarcas } from "../../services/apis/marcasApi";
import DemandService from "../../services/demandService";
import { Constants } from "../../utils/Constants";
import MessageService from "../../services/messageService";

const demandService = new DemandService();
const messageService = new MessageService();

export default function EditMarcasModal({ isOpen, id, handleClose}) {
    // const [name, setName] = useState('');
    // const [image, setImage] = useState('');
    const [marca, setMarca] = useState(null);
    const formRef = useRef(null);
    useEffect(() => {
        if (id) {
            getMarcasById(id).then((response) => {
                setMarca(response);
            });
        }
    }, [id]);

    const handleSubmit = async (data) => {
        debugger;
        let message = `Alterado nome da marca para ${data?.name}`

        if (data?.image) {
          message += ` e alterado logo da marca`
        }

        await demandService.updatedemandStatus({
            demandId: marca.demandId,
            statusKey: Constants.DEMAND_STATUSES.IN_PROGRESS.key,
            });

        await messageService.createTextMessageWithAttachments({
            demandId: marca.demandId,
            content: message,
            attachments: [data?.image],
            });

        await updateMarcas(marca.id,{name: data.name, logo: data.image.name})
            


        handleClose(!isOpen);
    }

    if(marca === null) return null;

    return (
        <Modal visible={isOpen}>
            <Container>
                <Title>Editar marca</Title>
                <Form ref={formRef} onSubmit={handleSubmit} >
                    <Description>
                            <CustomInput name='name' label='Nome' />
                            <FileInput name='image' label='Logo' />
                    </Description>

                    <ButtonContainer>
                        <Button bgColor='#ea5f59' onClick={() => handleClose(!isOpen)}>
                            Cancelar
                        </Button>
                        <Button type="submit">Salvar</Button>
                    </ButtonContainer>
                </Form>
            </Container>
        </Modal>
    )
}