import { combineReducers } from "redux";
import { RECIEVE_BACKOFFICE_USERS } from "../actionsTypes/ActionTypes";
import produce from "immer";

const ALL_INITIAL_STATE = [];
const BY_ID_INITIAL_STATE = {};

const all = produce((draft, action) => {
  switch (action.type) {
    case RECIEVE_BACKOFFICE_USERS: {
      draft = action.backofficeUsers;
      draft.sort((a,b) => {
        if(a.name === b.name){
          return 0
        }
        if(a.name > b.name){
          return 1
        }
        return -1
      })
      return draft;
    }
    default:
      return draft;
  }
}, ALL_INITIAL_STATE);

const byId = produce((draft, action) => {
  switch (action.type) {
    case RECIEVE_BACKOFFICE_USERS:
      action.backofficeUsers.forEach((backofficeUser) => {
        draft[backofficeUser.id] = backofficeUser;
      });
      break;
    default:
      return draft;
  }
}, BY_ID_INITIAL_STATE);

export default combineReducers({
  byId,
  all,
});

export const getBackofficeUserId = (state, id) => state.byId[id];
