import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const SimpleTable = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: 0 0 5px rgb(0, 0, 0, 0.5);
  `,
  Title: styled.h3`
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: bold;
  `,
  Table: styled.table`
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    white-space: nowrap;
  `,
  TableRow: styled.tr`
    background-color: #fff;

    :nth-child(even) {
      background-color: rgb(0, 0, 0, 0.1);
    }
  `,
  TableHead: styled.thead`
    background-color: #1994ff;
    position: sticky;
    top: 0;
    text-align: left;
    z-index: 1;

    tr {
      background-color: #2f4050;
      color: #121212;
      color: #fff;
    }
  `,
  TableBody: styled.tbody`
    tr {
      :hover {
        box-shadow: inset 0 0 3px rgb(0, 0, 0, 0.5);
      }
    }
  `,
  TableHeadCell: styled.th`
    :not(:first-child) {
      border-left: 1px solid #dddddd;
    }
    white-space: normal;
    padding: 8px;
  `,
  TableData: styled.td`
    text-align: left;
    overflow: hidden;
    padding: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    *,
    a {
      text-decoration: none;
      color: inherit;
      cursor: pointer;
      position: relative;
      display: block;
      padding: 8px;
      margin: -8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `,
  TableDataButton: styled.button`
    border: none;
    padding: 8px 14px;
    border-radius: 4px;
    font-size: 12px;
    margin-left: auto;
    margin-right: auto;
    transition: opacity 0.2s;

    :not(:disabled):hover {
      opacity: 0.9;
    }

    :disabled {
      cursor: not-allowed;
      background-color: grey;
      color: black;
    }
  `,
};

export const PayOverdueIcon = styled.i`
  font-size: 14px;
  margin-left: 6px;
  color: #ea5f59 !important;
  float: right;
  position: absolute;
  right: 1%;
  top: 30%;
`;

export const CheckIcon = styled.i`
  margin-left: 0.2rem;
  display: inline;
  cursor: pointer;
`;

export const CheckedIcon = styled.i`
  margin-left: 0.2rem;
  display: inline;
  cursor: normal;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 10px;
  margin-top: 20px;

  h3 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0px;
    height: 35px;
  }

  h2 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }
`;

export const CustomerLink = styled(Link)`
  background-color: ${({ health }) => {
    if (+health >= 71) {
      return '#00a859';
    } else if (+health >= 51) {
      return '#f5a623';
    } else {
      return '#ea5f59';
    }
  }} !important;
`;
