import * as Types from '../actionsTypes/ActiveContact'

const InitialState = {
    valueDemands:[]
}

const ActiveContact  = (state = InitialState, action) => {
    switch (action.type) {
        case Types.FETCH_VALUE_DEMANDS: {
            return {...state, valueDemands: action.value}
        }
        case Types.CONFIRMED_FUP:{
            const valueDemands = [... state.valueDemands]
            const index = valueDemands.findIndex(valueDemand => valueDemand.id == action.value.id)
            valueDemands[index] = action.value
            return {...state,valueDemands}
        }
        default:
            return state

    }
}

export default ActiveContact