import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import CustomerService from '../../../../services/customerService';
import UserService from '../../../../services/userService';
import { Constants } from '../../../../utils/Constants';
import CustomSelectWithSearch from '../../../input/customSelectorWithSearch/CustomSelectWithSearch';
import Modal from '../../../modal/Modal';
import { Styles } from './DeleteUserModal.styles';
import * as Yup from 'yup';
import apiOld from '../../../../services/Api';

export default function DeleteUserModal({
  open,
  userToDelete = { id: '', name: '', type: '', customerId: '' },
  handleClose,
}) {
  const deleteUserFormRef = useRef(null);
  const [customerUsers, setCustomerUsers] = useState([]);
  const [bacckofficeUsers, setBacckofficeUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAllUsers = async () => {
    const { data } = await apiOld.get('/users');
    setBacckofficeUsers(data.users.filter((d) => d.type === Constants.USER_TYPES.BACKOFFICE && d.id !== userToDelete.id))
  }

  const fetchCustomerUsers = async customerId => {
    setLoading(true);

    try {
      const customerUsers = await CustomerService.getCustomerUsersById(
        customerId
      );

      setCustomerUsers(customerUsers);
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  const _handleDeleteUserFormSubmit = async formData => {
    setLoading(true);

    if (formData && formData.newBackofficeUserId) {
      try {
        await apiOld.put(`/cs/valueDemands/${userToDelete.id}/${formData.newBackofficeUserId}`)
        await UserService.handleDeleteUser({
          userId: userToDelete.id,
          newResponsibleUserId: formData.newBackofficeUserId,
          userType: userToDelete.type,
        });
        toast.success('Usuário excluído com sucesso!');
      } catch (error) {
        console.error(error);

        if (error instanceof Yup.ValidationError) {
          const validationErrors = {};

          error.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });

          deleteUserFormRef.current.setErrors(validationErrors);
        }
      } finally {
        handleClose(true);
        setLoading(false);
      }
    } else {
      try {
        await UserService.handleDeleteUser({
          userId: userToDelete.id,
          newResponsibleUserId: formData.newResponsibleUserId,
          userType: userToDelete.type,
        });

        toast.success('Usuário excluído com sucesso!');

        handleClose(true);
      } catch (error) {
        console.error(error);

        if (error instanceof Yup.ValidationError) {
          const validationErrors = {};

          error.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });

          deleteUserFormRef.current.setErrors(validationErrors);
        }
      } finally {
        setLoading(false);
      }

    }

    setLoading(false);
  };

  useEffect(() => {
    if (userToDelete.type === Constants.USER_TYPES.CUSTOMER) {
      fetchCustomerUsers(userToDelete.customerId);
    }
    getAllUsers()
  }, [userToDelete.type, userToDelete.customerId]);

  return (
    <Modal visible={open}>
      <Styles.Container>
        <Form onSubmit={_handleDeleteUserFormSubmit} ref={deleteUserFormRef}>
          <h1>Deletar usuário</h1>

          <p>
            Você realmente deseja excluir o usuário{' '}
            <strong>{userToDelete.name}</strong>?
          </p>

          {userToDelete.type === Constants.USER_TYPES.BACKOFFICE && (
            <>
              <CustomSelectWithSearch
                label='Escolha um novo advogado(a) para ser o(a) responsável pelas demandas do
                usuário excluido (inclusive demandas sigilosas) e também responsável pelo contato ativo.'
                name='newBackofficeUserId'
                options={bacckofficeUsers
                  .map(u => ({
                    value: u.id,
                    name: u.name,
                  }))
                }
              />
            </>
          )}

          {userToDelete.type === Constants.USER_TYPES.CUSTOMER && (
            <>
              <CustomSelectWithSearch
                label='Escolha uma nova pessoa para ser o responsável pelas demandas do
                usuário excluido (inclusive demandas sigilosas).'
                name='newResponsibleUserId'
                options={customerUsers
                  .filter(u => u.id !== userToDelete.id)
                  .map(u => ({
                    value: u.id,
                    name: u.name,
                  }))}
              />
            </>
          )}

          <div className='button-container'>
            <button onClick={() => handleClose(false)}>Cancelar</button>

            <button type='submit' disabled={loading}>
              Deletar usuário
            </button>
          </div>
        </Form>
      </Styles.Container>
    </Modal>
  );
}
