import React, { useEffect, useRef, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import MiniDrawer from '../../components/MiniDrawer';
import { PageContainer } from '../../components/Pages.styles';
import JourneyTypeService from '../../services/journeys/journeyTypeService';
import { findNewIndexInIndexesList } from '../../utils';
import { toast } from 'react-toastify';
import Modal from '../../components/modal/Modal';
import { Form } from '@unform/web';
import CustomSelect from '../../components/input/CustomSelect';

const journeyTypeService = new JourneyTypeService();

const EditJourneyTypeIndexModal = ({
  journeyType,
  journeyTypeIndexes,
  handleClose,
}) => {
  const updateDefaultDemandUnformRef = useRef(null);

  const _updateJourneyType = async data => {
    try {
      const updateData = {
        index: findNewIndexInIndexesList(
          journeyType.index,
          data.index,
          journeyTypeIndexes
        ),
      };

      await journeyTypeService.updateJourneyType(journeyType.id, updateData);

      toast.success('Tipo de jornada atualizado com sucesso!');

      handleClose(true);
    } catch (error) {
      console.error(error);
    }
  };

  return !!journeyType ? (
    <Modal visible={true}>
      <Grid
        container
        style={{
          backgroundColor: '#fff',
          padding: '10px',
          borderRadius: '5px',
          boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
          width: '400px',
        }}
      >
        <Grid container className='titleContainer'>
          <h1>Editar tipo de jornada</h1>
        </Grid>

        <Grid container>
          <Form
            className='formContainer'
            ref={updateDefaultDemandUnformRef}
            onSubmit={_updateJourneyType}
          >
            <Grid container>
              <Grid item xs={12} className='inputContainer'>
                {journeyTypeIndexes && (
                  <CustomSelect
                    placeholder='Posição'
                    name='index'
                    label='Nova posição'
                    defaultValue={journeyType.index}
                    options={journeyTypeIndexes.map((index, fakeIndex) => ({
                      name: fakeIndex + 1,
                      value: index,
                    }))}
                  />
                )}
              </Grid>

              <Grid container justify='flex-end'>
                <button
                  type='button'
                  className='submit secondaryButton'
                  onClick={handleClose}
                  style={{ marginRight: '10px' }}
                >
                  Cancelar
                </button>

                <button type='submit' className='submit'>
                  Salvar Alterações
                </button>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </Modal>
  ) : null;
};

export default function EditJourneyTypeOrder() {
  const [state, setState] = useState({
    journeyTypes: [],
    isEditigJourneyType: false,
  });

  const _fetchJourneyTypeData = async () => {
    try {
      const journeyTypes = await journeyTypeService.listAllJourneyTypes();

      setState(prevState => ({
        ...prevState,
        journeyTypes,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const _handleEditJourneyType = jt => {
    setState(prevState => ({
      ...prevState,
      isEditigJourneyType: jt,
    }));
  };

  const _handleCloseJourneyTypeEditModal = isUpdated => {
    setState(prevState => ({
      ...prevState,
      isEditigJourneyType: false,
    }));

    if (isUpdated) {
      _fetchJourneyTypeData();
    }
  };

  useEffect(() => {
    _fetchJourneyTypeData();
  }, []);

  return (
    <React.Fragment>
      <MiniDrawer currentExpaded='journeys'>
        <PageContainer>
          <Grid container>
            <Grid className='titleContainer' container>
              <h1>Editar ordem dos tipos de jornada</h1>
            </Grid>

            {state.journeyTypes.map((jt, idx) => (
              <React.Fragment key={jt.id}>
                <Grid item xs={1} className='inputContainer'>
                  <p>
                    <strong>Posição:</strong>
                  </p>
                  <span>{idx + 1}</span>
                </Grid>

                <Grid item xs={4} className='inputContainer'>
                  <p>
                    <strong>Título:</strong>
                  </p>

                  <span>{jt.title}</span>
                </Grid>

                <Grid item xs={4} className='inputContainer'>
                  <p>
                    <strong>Descrição:</strong>
                  </p>

                  <span>{jt.description}</span>
                </Grid>

                <Grid item xs={2} className='inputContainer'>
                  <p>
                    <strong>Tipo de cliente:</strong>
                  </p>

                  <span>{jt.customerType || 'todos'}</span>
                </Grid>

                <Grid item xs={1} className='inputContainer'>
                  <button
                    type='button'
                    className='submit'
                    style={{
                      backgroundColor: '#2f4050',
                    }}
                    onClick={() => _handleEditJourneyType(jt)}
                  >
                    Editar
                  </button>
                </Grid>
              </React.Fragment>
            ))}

            <EditJourneyTypeIndexModal
              journeyType={state.isEditigJourneyType}
              journeyTypeIndexes={state.journeyTypes.map(jt => jt.index)}
              handleClose={_handleCloseJourneyTypeEditModal}
            />
          </Grid>
        </PageContainer>
      </MiniDrawer>
    </React.Fragment>
  );
}
