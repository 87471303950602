import React, { useCallback, useRef, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@unform/web';
import { useEffect } from 'react';
import apiOld from '../../services/Api';
import customerApi from '../../services/apis/customerApi';
import { formatDateHour, formatDateWithMonthName } from '../../utils';
import Modal from '../modal/Modal';
import CustomTextArea from '../input/CustomTextArea';
import {
  Message,
  MessageContainer,
  MessageDeletedInfo,
  MessageName,
  MessageText,
  MessageTime,
  MessageDate,
} from '../demandModal/DemandModal.styles';
import { AnnotationsModal } from './CustomerAnnotationsModal.styles';
import CustomRadio from '../input/CustomRadio';
import * as Yup from 'yup';
import { useQuery } from '../../routes';
import { Constants } from '../../utils/Constants';

const AnnotationMessage = ({ annotation, customerId, fetchCustomerData }) => {
  const [state, setState] = useState({
    expanded: annotation.deleted ? false : true,
  });

  return (
    <MessageContainer right={1} key={`annotation-${annotation.idx}`}>
      <Message
        right={1}
        deleted={annotation.deleted}
        style={{
          maxWidth: '90%',
          backgroundColor:
            !annotation.deleted &&
            annotation.type &&
            Constants.ANNOTATION_TYPES[annotation.type]
              ? Constants.ANNOTATION_TYPES[annotation.type].color
              : null,
        }}
      >
        {annotation.deleted ? (
          <i
            style={{
              fontWeight: '600',
              fontSize: '15px',
              color: 'white',
              position: 'absolute',
              left: '10px',
              cursor: 'pointer',
            }}
            onClick={() => {
              setState(state => ({ ...state, expanded: !state.expanded }));
            }}
            className={`fa fa-chevron-${state.expanded ? 'up' : 'down'}`}
          />
        ) : (
          <i
            style={{
              fontWeight: '600',
              fontSize: '15px',
              color: 'red',
              position: 'absolute',
              left: '10px',
              cursor: 'pointer',
            }}
            onClick={async () => {
              await customerApi.deleteAnnotation(customerId, annotation.idx);
              fetchCustomerData();
            }}
            className='fa fa-trash'
          />
        )}

        <span
          style={{
            fontWeight: 'lighter',
            fontSize: '10px',
            // color: Constants.ANNOTATION_TYPES[annotation.type].color,
            color: annotation.type ? '#fff' : '#000',
            position: 'absolute',
            left: '30px',
          }}
        >
          {annotation.type
            ? Constants.ANNOTATION_TYPES[annotation.type].name
            : 'Geral'}
        </span>

        <MessageName
          style={{ color: annotation.type ? '#fff' : '#000' }}
          deleted={annotation.deleted}
        >
          {annotation.userName}
        </MessageName>

        <MessageText
          style={{ color: annotation.type ? '#fff' : '#000' }}
          deleted={annotation.deleted}
          expanded={state.expanded}
        >
          {annotation.content}
        </MessageText>
      </Message>

      {annotation.deleted && (
        <MessageDeletedInfo>
          Mensagem deletada por: {annotation.deletedByUserName}
        </MessageDeletedInfo>
      )}

      <MessageTime right={1}>
        {formatDateHour(annotation.createdAt)}
      </MessageTime>
    </MessageContainer>
  );
};

// TODO: mudar isso aqui pra query params?
export default function CustomerAnnotationsModal(props) {
  const { open, handleClose, modalData } = props;
  const formRef = useRef(null);
  const query = useQuery();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [state, setState] = useState({
    customerData: modalData,
    selectedType:
      Constants.ANNOTATION_TYPES[query.get('ANNOTATION_TYPE')] ||
      Constants.ANNOTATION_TYPES.general,
  });
  const [isInputDisabled, setIsInputDisabled] = useState(true);
  const [inputState, setInputState] = useState({
    disabled: false,
    focused: false,
  });

  const fetchAnnotations = useCallback(
    async scroll => {
      await customerApi.getById(modalData.id).then(({ data }) => {
        setState(state => ({
          ...state,
          customerData: data,
          customerAnnotations: _formatAnnotations(data.annotations),
        }));
      });

      if (scroll) scrollDown();
    },
    [modalData.id]
  );

  useEffect(() => {
    fetchAnnotations(true);
  }, [fetchAnnotations]);

  useEffect(() => {
    if (open) scrollDown();
  }, [open]);

  function scrollDown() {
    const scroll = document.getElementById('scroll');
    if (scroll) scroll.scrollTop = scroll.scrollHeight;
  }

  async function _handleAnnotationsFormSubmit(data, { reset }) {
    try {
      formRef.current.setErrors({});
      setSubmitLoading(true);

      const annotationSchema = Yup.object().shape({
        content: Yup.string().label('Anotação').required(),
        type: Yup.string().label('Tipo de anotação').required(),
      });

      const request = await annotationSchema.validate(data, {
        abortEarly: false,
      });

      await apiOld.post(
        `/customers/${state.customerData.id}/annotations`,
        request
      );

      reset();

      fetchAnnotations(true);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};

        error.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    } finally {
      setSubmitLoading(false);
    }
  }

  function _formatAnnotations(annotations) {
    const annotationsFomated = {};

    annotations?.forEach((annotation, idx) => {
      const date = formatDateWithMonthName(annotation.createdAt);

      annotation.idx = idx;

      if (annotationsFomated[date]) {
        annotationsFomated[date].push(annotation);
      } else annotationsFomated[date] = [annotation];
    });
    return annotationsFomated;
  }

  function _filterAnnotations(annotations = []) {
    if (state.selectedType === Constants.ANNOTATION_TYPES.general)
      return annotations;

    return annotations.filter(
      annotation => annotation.type === state.selectedType.key
    );
  }

  const _handleSelectorTypeChange = newType => {
    setState({
      ...state,
      selectedType: newType,
    });
    formRef?.current?.setFieldValue('type', newType.key);
    setTimeout(scrollDown, 1);
  };

  return open ? (
    <Modal visible={open}>
      <AnnotationsModal.Container>
        <AnnotationsModal.Header>
          <h1>Anotações</h1>

          <i
            onClick={handleClose}
            className='fas fa-times fa-2x'
            style={{
              cursor: 'pointer',
            }}
          />
        </AnnotationsModal.Header>
        {state.customerAnnotations && (
          <>
            <AnnotationsModal.TypeSelector.Container>
              {Object.values(Constants.ANNOTATION_TYPES).map(type => (
                <AnnotationsModal.TypeSelector.Selector
                  selected={state.selectedType.key === type.key}
                  onClick={() => _handleSelectorTypeChange(type)}
                >
                  {type.name}
                </AnnotationsModal.TypeSelector.Selector>
              ))}
            </AnnotationsModal.TypeSelector.Container>
            <AnnotationsModal.Body>
              <AnnotationsModal.ChatContainer id='scroll'>
                {Object.keys(state.customerAnnotations).map(date => {
                  const filteredAnnotations = _filterAnnotations(
                    state.customerAnnotations[date]
                  );

                  return filteredAnnotations.length ? (
                    <React.Fragment key={date}>
                      <MessageDate>{date}</MessageDate>

                      {filteredAnnotations.map(annotation => (
                        <AnnotationMessage
                          annotation={annotation}
                          customerId={state.customerData.id}
                          fetchCustomerData={fetchAnnotations}
                        />
                      ))}
                    </React.Fragment>
                  ) : null;
                })}
              </AnnotationsModal.ChatContainer>

              <Form ref={formRef} onSubmit={_handleAnnotationsFormSubmit}>
                <AnnotationsModal.FormTypeSelectorContainer>
                  <span>Tipo de anotação:</span>

                  <CustomRadio
                    name='type'
                    options={Object.values(Constants.ANNOTATION_TYPES).map(
                      type => ({
                        id: type.key,
                        label: type.name,
                      })
                    )}
                    defaultValue={state.selectedType.key}
                  />
                </AnnotationsModal.FormTypeSelectorContainer>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: inputState.focused ? '250px' : '100%',
                  }}
                >
                  <CustomTextArea
                    maxLength='50000'
                    name='content'
                    placeholder='Escrever mensagem'
                    onFocus={() =>
                      setInputState({
                        ...inputState,
                        focused: true,
                      })
                    }
                    onBlur={() =>
                      setInputState({
                        ...inputState,
                        focused: false,
                      })
                    }
                    onChange={() => {
                      setIsInputDisabled(
                        !formRef.current.getFieldValue('content')
                      );
                    }}
                    style={{
                      height: '100%',
                    }}
                  />

                  <AnnotationsModal.SendMessageButton
                    disabled={isInputDisabled}
                    type='submit'
                  >
                    {submitLoading ? (
                      <CircularProgress color='inherit' />
                    ) : (
                      <i
                        className='fas fa-arrow-right fa-lg'
                        style={{ color: 'white' }}
                      />
                    )}
                  </AnnotationsModal.SendMessageButton>
                </div>
              </Form>
            </AnnotationsModal.Body>
          </>
        )}
      </AnnotationsModal.Container>
    </Modal>
  ) : null;
}
