import React, { useState, useEffect } from 'react';
import { Frame, Close, CloseIcon } from './DocumentViewer.styles';
import Modal from '../modal/Modal';
import PropTypes from 'prop-types';
import DocumentService from '../../services/documentService';

const documentService = new DocumentService();

function DocumentViewer({ visible, handleClose, documentId }) {
  const [state, setState] = useState({
    signedUrl: null,
  });

  const _fetchDocumentData = async documentId => {
    const signedUrl = (
      await documentService.getDocumentSignedDownloadObj(documentId)
    ).signedUrl;

    setState({
      signedUrl,
    });
  };

  useEffect(() => {
    if (documentId) {
      _fetchDocumentData(documentId);
    }
  }, [documentId]);

  return (
    visible &&
    state.signedUrl && (
      <Modal visible={visible}>
        <>
          <Close type='button' onClick={handleClose}>
            Fechar
            <CloseIcon />
          </Close>

          <Frame
            src={
              'https://docs.google.com/gview?url=' +
              encodeURIComponent(state.signedUrl) +
              '&embedded=true'
            }
          ></Frame>
        </>
      </Modal>
    )
  );
}

DocumentViewer.propTypes = {
  visible: PropTypes.bool,
  setActive: PropTypes.func,
  url: PropTypes.string,
  setIsLoadingActive: PropTypes.func,
};

export default DocumentViewer;
