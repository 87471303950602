export const TOKEN_KEY = "@ndm-token";
export const USER_KEY = "@ndm-user";

export const setToken = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const removeToken = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const removeUser = () => {
  localStorage.removeItem(USER_KEY);
};

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const getUser = () => JSON.parse(localStorage.getItem(USER_KEY));

export const setUser = (user) =>
  localStorage.setItem(USER_KEY, JSON.stringify(user));
