import { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import DefaultTags from '../../constants/DefaultTags';
import { formatDate } from '../../utils';
import moment from 'moment';
import { Container, PayOverdueIcon, SimpleTable } from './Tables.styles';
import customerApi from '../../services/apis/customerApi';
import { FileCustomerModal } from '../../components/csModals/FileCustomerModal';
import ConfirmationModal from '../confirmationModal/ConfirmationModal';
import { CircularProgress } from '@material-ui/core';

const checkIconStyles = {
  width: '30px',
  marginLeft: 'calc(50% - 15px)',
  cursor: 'pointer',
};

export const SpecificDemandCustomers = () => {
  const [tableData, setTableData] = useState({
    customers: [],
    fileCustomerModal: {
      open: false,
      customer: {},
      handleClose: () => {},
    },
    confirmationModal: {
      open: false,
      resolveCallback: null,
      rejectCallback: null,
      title: '',
      description: '',
    },
  });
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await customerApi.getAllInFupDate();
      setTableData(prev => ({
        ...prev,
        customers: data.reverse(),
      }));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const _handleOpenFileCustomerModal = customer => {
    setTableData(prev => ({
      ...prev,
      fileCustomerModal: {
        open: true,
        customer,
        handleClose: () => {
          fetchData();
          setTableData(prev => ({
            ...prev,
            fileCustomerModal: {
              open: false,
              customer: {},
              handleClose: () => {},
            },
          }));
        },
      },
    }));
  };

  const handleSendFupEmail = useCallback(
    async (customerId, key) => {
      try {
        setLoading(true);
        await customerApi.sendFollowupEmail(customerId, key);
      } catch (error) {
        throw error;
      } finally {
        setLoading(false);
        fetchData();
      }
    },
    [fetchData]
  );

  const resolveCallback = useCallback(
    (customerId, key) => async () => {
      try {
        await handleSendFupEmail(customerId, key);
      } catch (error) {
        console.error(error);
      } finally {
        setTableData(prev => ({
          ...prev,
          confirmationModal: {
            open: false,
            resolveCallback: null,
            rejectCallback: null,
            title: '',
            description: '',
          },
        }));
      }
    },
    []
  );

  const openConfirmationModal = useCallback(
    (customerId, key) => () => {
      setTableData(prev => ({
        ...prev,
        confirmationModal: {
          open: true,
          resolveCallback: resolveCallback(customerId, key),
          rejectCallback: () => {
            setTableData(prev => ({
              ...prev,
              confirmationModal: {
                open: false,
                resolveCallback: null,
                rejectCallback: null,
                title: '',
                description: '',
              },
            }));
          },
          title: `Deseja mesmo enviar o ${
            key === 'first' ? 'primeiro' : 'segundo'
          } email de followup?`,
          description: 'Esta ação enviará um email para o cliente',
        },
      }));
    },
    [resolveCallback]
  );

  return !loading ? (
    <Container>
      <h2>Total: {tableData.customers?.length}</h2>

      {tableData.customers && tableData.customers.length > 0 ? (
        <SimpleTable.Container>
          <SimpleTable.Table>
            <SimpleTable.TableHead>
              <SimpleTable.TableRow>
                <SimpleTable.TableHeadCell
                  style={{
                    width: '25%',
                  }}
                >
                  Clientes
                </SimpleTable.TableHeadCell>

                <SimpleTable.TableHeadCell
                  style={{
                    width: '20%',
                  }}
                >
                  Data do Followup
                </SimpleTable.TableHeadCell>

                <SimpleTable.TableHeadCell
                  style={{
                    width: '20%',
                  }}
                >
                  Primeiro email
                </SimpleTable.TableHeadCell>

                <SimpleTable.TableHeadCell
                  style={{
                    width: '20%',
                  }}
                >
                  Segundo Email
                </SimpleTable.TableHeadCell>
                <SimpleTable.TableHeadCell
                  style={{
                    width: '15%',
                    textAlign: 'center',
                  }}
                >
                  Arquivar
                </SimpleTable.TableHeadCell>
              </SimpleTable.TableRow>
            </SimpleTable.TableHead>

            <SimpleTable.TableBody>
              {tableData.customers.map(customer => (
                <SimpleTable.TableRow key={customer.id}>
                  <SimpleTable.TableData>
                    <Link to={`/customers/${customer.id}`}>
                      {customer.name}
                      {customer.tags?.find(
                        tag => tag.name === DefaultTags.PAYMENT_OVERDUE
                      ) && (
                        <PayOverdueIcon className='fas fa-exclamation-circle'></PayOverdueIcon>
                      )}
                    </Link>
                  </SimpleTable.TableData>

                  <SimpleTable.TableData
                    style={{
                      ...(moment().isAfter(
                        customer.CustomerSpecificDemand.followupDate,
                        'days'
                      )
                        ? {
                            color: 'red',
                            fontWeight: 'bolder',
                          }
                        : {}),
                    }}
                  >
                    <Link to={`/customers/${customer.id}`}>
                      {formatDate(customer.CustomerSpecificDemand.followupDate)}
                    </Link>
                  </SimpleTable.TableData>

                  <SimpleTable.TableData>
                    {customer.CustomerSpecificDemand?.firstEmailDate ? (
                      <span>
                        {formatDate(
                          moment(
                            customer.CustomerSpecificDemand?.firstEmailDate
                          )
                        )}
                      </span>
                    ) : (
                      <SimpleTable.TableDataButton
                        style={{
                          backgroundColor: '#1994FF',
                          fontWeight: 'bold',
                          color: 'white',
                          marginLeft: 0,
                        }}
                        title='Enviar email'
                        onClick={openConfirmationModal(customer.id, 'first')}
                      >
                        Enviar email
                      </SimpleTable.TableDataButton>
                    )}
                  </SimpleTable.TableData>

                  <SimpleTable.TableData>
                    {customer.CustomerSpecificDemand?.secondEmailDate ? (
                      <span>
                        {formatDate(
                          moment(
                            customer.CustomerSpecificDemand?.secondEmailDate
                          )
                        )}
                      </span>
                    ) : (
                      <SimpleTable.TableDataButton
                        style={{
                          backgroundColor: '#1994FF',
                          fontWeight: 'bold',
                          color: 'white',
                          marginLeft: 0,
                        }}
                        title='Enviar email'
                        onClick={openConfirmationModal(customer.id, 'second')}
                      >
                        Enviar email
                      </SimpleTable.TableDataButton>
                    )}
                  </SimpleTable.TableData>

                  <SimpleTable.TableData>
                    <i
                      className='far fa-square'
                      style={checkIconStyles}
                      onClick={() => _handleOpenFileCustomerModal(customer)}
                    />
                  </SimpleTable.TableData>
                </SimpleTable.TableRow>
              ))}
            </SimpleTable.TableBody>
          </SimpleTable.Table>
        </SimpleTable.Container>
      ) : (
        <SimpleTable.TableRow>
          <SimpleTable.TableData>
            <span>Nenhum cliente 😃</span>
          </SimpleTable.TableData>
        </SimpleTable.TableRow>
      )}
      <FileCustomerModal {...tableData.fileCustomerModal} />
      <ConfirmationModal {...tableData.confirmationModal} />
    </Container>
  ) : (
    <CircularProgress />
  );
};
