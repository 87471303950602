import styled from 'styled-components';

export const AnnotationsModal = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    width: 75vw;
    position: relative;
    background: #fff;
    border-radius: 10px;
    height: calc(100vh - 100px);
  `,
  Header: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
    font-size: 10px;
  `,
  TypeSelector: {
    Container: styled.div`
      display: flex;
      flex-direction: row;
      padding: 0px 1px;
      border-bottom: 1px solid rgb(220, 226, 230);
    `,
    Selector: styled.button`
      background-color: transparent;
      padding: 0px 20px;
      height: 25px;
      overflow: hidden;
      color: #fff;
      cursor: pointer;
      font-size: 11px;

      border: none;
      border: solid 1px rgb(220, 226, 230);
      border-bottom: none;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      margin-right: -1px;

      transition: all 0.2s;

      ${props => {
        if (props.selected) {
          return `
            font-weight: bold;
            font-size: 13px;
            padding: 0px 25px;
          `;
        }
      }}

      background-color: gray;

      &:nth-child(1) {
        background-color: #54a7bb;
      }
      &:nth-child(2) {
        background-color: #219ebc;
      }
      &:nth-child(3) {
        background-color: #03486a;
      }
      &:nth-child(4) {
        background-color: #022e44;
      }
      &:nth-child(5) {
        background-color: #001b26;
      }
      &:nth-child(6) {
        background-color: #001017;
      }

      &:hover {
        opacity: 0.8;
        padding: 5px 25px;
      }
    `,
  },
  Body: styled.div`
    display: flex;
    height: calc(100% - 83px);
    flex-direction: column;
    padding: 10px;
    flex-grow: 2;
    width: 100%;

    h2 {
      font-size: 21px;
      padding: 0;
      color: #3d474d;
    }

    form {
      display: inline-flex;
      flex-direction: column;
    }
  `,
  ChatContainer: styled.div`
    padding-top: 8px;
    padding-right: 5px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    align-items: center;
    margin-bottom: 10px;
    /* max-width: 75%; */
  `,
  FormTypeSelectorContainer: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      font-weight: bold;
    }

    div {
      margin-left: 5px;
      padding: 0px;

      &:nth-child(1) {
        color: #54a7bb;
      }
      &:nth-child(2) {
        color: #219ebc;
      }
      &:nth-child(3) {
        color: #03486a;
      }
      &:nth-child(4) {
        color: #022e44;
      }
      &:nth-child(5) {
        color: #001b26;
      }
      &:nth-child(6) {
        color: #001017;
      }

      label {
        display: flex;
        align-items: center;
        flex-direction: row;
        font-size: 11px;
      }
    }
  `,
  SendMessageButton: styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    color: white;
    background-color: rgb(0, 170, 255);
    border: none;
    width: 36px;
    margin-left: 4px;

    :disabled {
      background-color: rgb(138, 148, 153);
      cursor: not-allowed;
    }
  `,
};
