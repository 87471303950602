import React from 'react';
import CustomSelectWithSearch from '../../input/customSelectorWithSearch/CustomSelectWithSearch';
import { ButtonFilter, ContainerFilterDemand, DivMain, OptionContainer, SquareButton, TextArea } from './styled';
import { Constants } from '../../../utils/Constants';
import { Grid } from '@material-ui/core';

function HandleGrid(name, defaultValue, options, params, setParams) {

  const handleChange = (selectedOption) => {
    setParams({ ...params, [name]: selectedOption });
  };

  return (
    <OptionContainer>
      <SquareButton disabled>{defaultValue}</SquareButton>
      <Grid
        item
        xs={6}>
        <CustomSelectWithSearch
          placeholder='Selecione uma opção'
          name={name}
          options={options}
          onChange={handleChange}
          search={false}
        />
      </Grid>
    </OptionContainer >
  );
}

export const FilterBulkDemand = ({ content, contentFiltered, params, setParams }) => {
  const customers = content?.customers || [];
  const unique = (key) => (list) => [...new Set(list.map(item => item[key]))]
    .map(item => ({ name: item, value: item }));
  const tags = [...new Map(customers.flatMap(item => item.tags || [])
    .filter(item => item.name)
    .map(item => [item.name, { name: item.name, value: item.id }]))
    .values()];

  const states = unique('addressState')(customers);
  const cities = unique('addressCity')(customers);

  Constants.CUSTOMER_TYPES.OPTIONS.pop()

  const dataHandleFilter = [
    {
      name: 'typeCompany',
      defaultValue: 'Tipo de sociedade',
      options: Constants.SOCIETY_TYPES,
    },
    {
      name: 'customerType',
      defaultValue: 'Tipo de cliente',
      options: Constants.CUSTOMER_TYPES.OPTIONS,
    },
    {
      name: 'personType',
      defaultValue: 'Tipo de pessoa',
      options: [
        { name: 'PF', value: 'PF' },
        { name: 'PJ', value: 'PJ' },
      ],
    },
    {
      name: 'addressState',
      defaultValue: 'Estado',
      options: states,
    },
    {
      name: 'addressCity',
      defaultValue: 'Cidade',
      options: cities,
    },
    {
      name: 'tagId',
      defaultValue: 'Tags',
      options: tags,
    },
  ];

  return (
    <DivMain>
      <ContainerFilterDemand>
        {
          dataHandleFilter.map((item) => {
            return HandleGrid(item.name, item.defaultValue, item.options, params, setParams)
          })
        }
      </ContainerFilterDemand>
      <ContainerFilterDemand>
        <TextArea>
          {
            contentFiltered?.customers?.length === 0 ? (
              <h3
                style={{ margin: '1rem 1.5rem' }}
              >Nenhum usuário encontrado</h3>
            ) : contentFiltered?.customers?.map((user) => (
              <ButtonFilter
                key={user?.id}
                disabled
              >{user?.name}</ButtonFilter>
            ))
          }
        </TextArea>
      </ContainerFilterDemand>
    </DivMain >
  );
};

export default FilterBulkDemand;
