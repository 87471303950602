import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { formatDate } from '../../../../utils';
import Loading from '../../../../components/Loading';
import MiniDrawer from '../../../../components/MiniDrawer';
import csApi from '../../../../services/apis/csApi';
import Table from '../../../../components/Table';
import {
  Container,
  Title,
  Section,
  SectionTitle,
  SectionContent,
  SimpleTable,
  MessageIcon,
} from '../../Dashboard.styles';
import {
  DemandFeedbackAverageContainer,
  DemandFeedbackLowAndHighContainer,
  DemandFeedbackLowAndHighItem,
} from './FeedbackDashboard.styles';
import { Constants } from '../../../../utils/Constants';
import FeedbackModal from '../../../../components/feedbacks/feedbackModal/FeedbackModal';
import {
  addQueryParamToUrl,
  removeQueryParamFromUrl,
} from '../../../../routes';
import { useSelector } from 'react-redux';

const feedbackTableHeadCells = [
  { id: 'rating', numeric: true, label: 'Nota' },
  { id: 'customer', numeric: false, label: 'Cliente' },
  { id: 'demand', numeric: false, label: 'Demanda' },
  { id: 'createdAt', numeric: false, label: 'Criado em' },
];

const handleFeedbackTableRows = data => {
  return {
    rows: data.feedbacks.map(feedback => {
      feedback.createdAt = formatDate(feedback.createdAt);
      feedback.customer = feedback.Customer?.name || feedback.customerId;
      feedback.demand = feedback.Demand?.name || feedback.demandId;
      feedback.showIcon = !!feedback.description;
      return feedback;
    }),
    total: data.total,
  };
};

export default function FeedbackDashboard() {
  const [feedbackDashboardData, setFeedbackDashboardData] = useState(false);
  const demandTypes = useSelector(state => state.demandTypes.all);

  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const dashboardData = (await csApi.getFeedbackDashboard())?.data;

      setFeedbackDashboardData(dashboardData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleOpenDemandFeedbackModal = id => {
    addQueryParamToUrl(
      Constants.QUERY_PARAMS_STATES.FEEDBACK.DEMAND_FEEDBACK_MODAL,
      id
    );
  };

  const handleCloseDemandFeedbackModal = () => {
    removeQueryParamFromUrl(
      Constants.QUERY_PARAMS_STATES.FEEDBACK.DEMAND_FEEDBACK_MODAL
    );
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const GenericFeedbackTable = ({ feedbacks }) => (
    <SimpleTable.Container>
      <SimpleTable.Table>
        <SimpleTable.TableHead>
          <SimpleTable.TableRow>
            <SimpleTable.TableHeadCell>Nota</SimpleTable.TableHeadCell>
            <SimpleTable.TableHeadCell>Cliente</SimpleTable.TableHeadCell>
            <SimpleTable.TableHeadCell>Demanda</SimpleTable.TableHeadCell>
            <SimpleTable.TableHeadCell>Criado em</SimpleTable.TableHeadCell>
          </SimpleTable.TableRow>
        </SimpleTable.TableHead>

        <SimpleTable.TableBody>
          {feedbacks?.map(feedback => (
            <SimpleTable.TableRow
              key={feedback.id}
              style={{
                cursor: 'pointer',
              }}
              onClick={() => handleOpenDemandFeedbackModal(feedback.id)}
            >
              <SimpleTable.TableData>
                {feedback.rating}/{Constants.FEEDBACK_MAX_RATING}
              </SimpleTable.TableData>
              <SimpleTable.TableData>
                {feedback.Customer?.name || feedback.customerId}{' '}
                {feedback.description && (
                  <MessageIcon className='far fa-comment-alt'></MessageIcon>
                )}
              </SimpleTable.TableData>
              <SimpleTable.TableData>
                {feedback.Demand?.name || feedback.demandId}
              </SimpleTable.TableData>
              <SimpleTable.TableData>
                {formatDate(feedback.createdAt)}
              </SimpleTable.TableData>
            </SimpleTable.TableRow>
          ))}
        </SimpleTable.TableBody>
      </SimpleTable.Table>
    </SimpleTable.Container>
  );

  return (
    <React.Fragment>
      <MiniDrawer
        currentExpaded='cs'
        main={
          <Container>
            <Loading open={loading} />

            <Title>Feedbacks dos clientes</Title>

            <Section>
              <SectionTitle>Feedbacks sobre demandas</SectionTitle>

              {feedbackDashboardData?.demandsFeedbacks && demandTypes && (
                <SectionContent>
                  <DemandFeedbackLowAndHighContainer>
                    <DemandFeedbackLowAndHighItem.Container>
                      <DemandFeedbackLowAndHighItem.Title>
                        5 Positivos mais recentes
                      </DemandFeedbackLowAndHighItem.Title>

                      <GenericFeedbackTable
                        feedbacks={
                          feedbackDashboardData.demandsFeedbacks.highests
                        }
                      />
                    </DemandFeedbackLowAndHighItem.Container>

                    <DemandFeedbackLowAndHighItem.Container>
                      <DemandFeedbackLowAndHighItem.Title>
                        5 Negativos mais recentes
                      </DemandFeedbackLowAndHighItem.Title>

                      <GenericFeedbackTable
                        feedbacks={
                          feedbackDashboardData.demandsFeedbacks.lowests
                        }
                      />
                    </DemandFeedbackLowAndHighItem.Container>
                  </DemandFeedbackLowAndHighContainer>

                  <DemandFeedbackAverageContainer>
                    <h3>Média de feedbacks</h3>

                    <span>
                      {parseFloat(
                        feedbackDashboardData?.demandsFeedbacks.ratingAverage
                      ).toFixed(2)}
                    </span>
                  </DemandFeedbackAverageContainer>

                  {demandTypes && (
                    <Table
                      // searchOptions={searchOptions}
                      title='Feedbacks'
                      headCells={feedbackTableHeadCells}
                      handleRows={handleFeedbackTableRows}
                      handleClickItem={feedbackId => {
                        handleOpenDemandFeedbackModal(feedbackId);
                      }}
                      route='feedbacks'
                      rolePrefix=''
                      // handleEdit={handleEdit}
                      customParams={{
                        type: Constants.FEEDBACK_TYPES.DEMAND,
                        demandTypeId: demandTypes
                          .filter(
                            demand =>
                              demand.name ===
                                Constants.DEMAND_TYPES.ONBOARDING_MEETING.key ||
                              demand.name ===
                                Constants.DEMAND_TYPES.ACTIVE_CONTACT_MEETING
                                  .key
                          )
                          .map(type => type.id),
                        ignoreDemandTypeId: true,
                      }}
                      Icon={
                        <MessageIcon className='far fa-comment-alt'></MessageIcon>
                      }
                    />
                  )}
                </SectionContent>
              )}
            </Section>

            <FeedbackModal handleClose={handleCloseDemandFeedbackModal} />
          </Container>
        }
      />
    </React.Fragment>
  );
}
