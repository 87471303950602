import React, { useRef, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@unform/web';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import apiOld from '../../../services/Api';
import CustomSelectWithSearch from '../../input/customSelectorWithSearch/CustomSelectWithSearch';
import {
  ButtonsContainer,
  Container,
  ContainerInput,
  ExitButton,
  Modal,
  SubmitButton,
} from './SendFormModal.styles';
import { toast } from 'react-toastify';

export default function ModalSendForm(props) {
  const { open, handleClose, modalData } = props;
  const formRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const forms = useSelector(state => state.forms.all);

  async function handleSubmit(data) {
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        formulário: Yup.string().required(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setSubmitLoading(true);

      const request = {
        formId: data.formulário,
      };

      await apiOld.post(`/demands/${modalData.id}/items/form`, request);

      toast.success('Formulário enviado com sucesso!');

      handleClose(true);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
    setSubmitLoading(false);
  }

  return (
    <>
      {open && (
        <Container>
          <Modal>
            <h1>Enviar formulário</h1>

            <Form ref={formRef} onSubmit={handleSubmit}>
              <ContainerInput>
                <CustomSelectWithSearch
                  placeholder='Nenhum tipo de formulário'
                  name='formulário'
                  label='Formulário'
                  options={forms?.map(form => ({
                    name: form.name,
                    value: form.id,
                  }))}
                />
              </ContainerInput>

              <ButtonsContainer>
                <SubmitButton type='submit'>
                  {!submitLoading && 'Enviar'}
                  {submitLoading && <CircularProgress color='inherit' />}
                </SubmitButton>

                <ExitButton onClick={() => handleClose(false)}>
                  Fechar
                </ExitButton>
              </ButtonsContainer>
            </Form>
          </Modal>
        </Container>
      )}
    </>
  );
}
