import fieldsApi from '../apis/templates/templateFieldsApi';
import * as Yup from 'yup';
import { Constants } from '../../utils/Constants';

export default class TemplateFieldService {
  id;
  name;
  label;
  type;
  options;
  required;

  static templateFieldYupSchema = Yup.object({
    name: Yup.string().label('Nome').required(),
    description: Yup.string().label('Descrição').required(),
    label: Yup.string().label('Label').required(),
    placeholder: Yup.string().label('Placeholder').required(),
    type: Yup.string()
      .label('Tipo')
      .required()
      .oneOf(Constants.TEMPLATE_FIELDS_TYPES.map(type => type.value)),
    options: Yup.mixed().when('type', {
      is: 'options',
      then: Yup.mixed()
        .nullable()
        .required()
        .transform(value => {
          if (value) {
            return value.split(',').map(s => s.trim());
          }
        })
        .test(
          'options',
          'Opções inválidas. Separe por vírgula, ex: "Um, Dois" (sem vírgulas no final e começo)',
          value => {
            return value?.every(option => !!option);
          }
        ),
    }),
    index: Yup.number().nullable().label('Índice'),
  });

  constructor(fieldId, fieldData) {
    this.id = fieldId;
    Object.assign(this, fieldData);
  }

  async create() {
    await this.validateTemplateFieldData();

    return this.createField();
  }

  async update() {
    await this.validateTemplateFieldData();

    const updatedData = await this.updateField();

    Object.assign(this, updatedData);
  }

  validateTemplateFieldData() {
    return TemplateFieldService.templateFieldYupSchema.validate(this, {
      abortEarly: false,
    });
  }

  async createField() {
    return (await fieldsApi.createTemplateField(this)).data;
  }

  async updateField() {
    return (await fieldsApi.updateTemplateField(this.id, this)).data;
  }
}
