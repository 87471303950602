import Modal from "../../components/modal/Modal";
import { Container } from "../../components/tagsModal/TagsModal.styles";
import { Button, Description } from "../../components/buttonsModal/ButtonsModal.styles";
import { ButtonContainer } from "../../components/confirmationModal/ConfirmationModal.styles";
import { Form } from "@unform/web";

export default function ModalAIR({ open, close, dataModal, children, ...rest }) {
  const { create, update, id, relation } = rest;

  const handleSubmit = async (data) => {
    if (create) {
      const dataComplete = { ...data };
      if (relation) {
        if (relation.property) {
          dataComplete[relation.property] = relation.id;
        }
        if (relation.subcategory) {
          dataComplete.subcategory = relation.subcategory;
        }
        if (relation.questionId) {
          dataComplete.Question_id = relation.questionId;
        }
      }
      await create(dataComplete);
    } else {
      await update(id, data);
    }
    close();
  };

  return (
    <Modal visible={open}>
      <Container style={{ width: '600px' }}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <h2 style={{ margin: '0' }}>{dataModal.title}</h2>
          <i className='fa fa-2x fa-times closeModal' onClick={close} />
          <Description style={{ margin: '0', textAlign: 'initial' }}>
            <span style={{ color: '#1AB394' }}>Preencha </span>
            {dataModal.description}
          </Description>
        </div>
        <Form
          style={{ width: '100%', margin: '1rem 0' }}
          onSubmit={handleSubmit}
        >
          {children}
          <ButtonContainer style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              bgColor='#D7D7D7'
              onClick={close}
              style={{ padding: '0.5rem 2rem' }}
            >
              Cancelar
            </Button>
            <Button
              bgColor='#1AB394'
              htmlType='submit'
              style={{ padding: '0.5rem 2rem' }}
            >
              {dataModal.label}
            </Button>
          </ButtonContainer>
        </Form>
      </Container>
    </Modal>
  );
};