import demandsApi from '../../../services/apis/demandsApi';
import { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import DemandGraph from './graph';
import { calculatePercentage, filterFinishedDemands, now, sortDemandsByMonthYear, twelveMonthsAgo } from './defaultFunctions';

export default function DemandFinishedGraph() {
  const [demandByMounth, setDemandByMounth] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    function groupDemandsByMonthYear(demands) {
      const result = [];
      for (const demand of demands) {
        const demandDate = moment(demand.updatedAt);
        const monthYear = demandDate.format('MMM YYYY');
        if (demandDate.isBetween(twelveMonthsAgo, now)) {
          let group = result.find((g) => g.monthYear === monthYear);

          if (!group) {
            group = { monthYear, finishedCount: 0 };
            result.push(group);
          }
          group.finishedCount += demand.finishedCount;
        }
      }
      return result;
    }

    async function fetchDemands() {
      setLoading(true);
      const demands = (await demandsApi.getAllDemands()).data.demands?.filter(demand => !demand.isMassDemand);
      const demandsFinished = filterFinishedDemands(demands);
      const demandsByMonthYear = groupDemandsByMonthYear(demandsFinished);
      const sortedDemands = sortDemandsByMonthYear(demandsByMonthYear);
      setDemandByMounth(sortedDemands);
      setLoading(false);
    }
    fetchDemands();
  }, []);

  const getProgressPercentage = useCallback(
    tooltipItems => {
      return `Em ${moment(tooltipItems[0].label, 'MMM').format('MMMM')}`;
    },
    []
  );

  return (
    <DemandGraph
      title="Demandas Finalizadas (ultimos 12 meses)"
      loading={loading}
      demand={demandByMounth}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            callbacks: {
              footer: getProgressPercentage,
            },
          },
        },
      }}
      data={{
        labels: demandByMounth?.map(demand => demand.monthYear),
        datasets: [{
          label: 'Demandas Finalizadas',
          backgroundColor: '#f4a460',
          data: demandByMounth?.map(demand => demand.finishedCount),
        }],
        percentages: calculatePercentage(demandByMounth),
      }}
    />
  );
}