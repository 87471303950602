import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useRef, useState } from "react";
import { Form } from "@unform/web";
import CircularProgress from "@material-ui/core/CircularProgress";
const useStyles = makeStyles((theme) => ({
  main: {
    position: "fixed",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    background: "rgba(0,0,0,0.8)",
    zIndex: "99999",
    opacity: "1",
  },
  modal: {
    width: "50%",
    position: "relative",
    margin: "10% auto",
    padding: "30px",
    background: "#F5F6FA",
    borderRadius: "15px",
  },
  submit: {
    fontSize: "20px",
    color: "#FFF",
    borderRadius: "5px",
    border: "none",
    padding: "10px 40px",
    backgroundColor: "#ff3333",
    boxShadow: "0 0 6px rgba(0, 0, 0, 0.09)",
  },
  buttonContainer: {
    marginTop: "20px",
    justifyContent: "center",
  },
  close: {
    fontSize: "20px",
    color: "#FFF",
    background: "#a0a0a0",
    borderRadius: "5px",
    border: "none",
    padding: "10px 40px",
    marginLeft: "10px",
    boxShadow: "0 0 6px rgba(0, 0, 0, 0.09)",
    textDecoration: "none",
  },
  text: {
    fontSize: "17px",
  },
}));

export default function ModalAlert(props) {
  const { open, handleSuccess, successOptions, handleClose } = props;
  const formRef = useRef(null);
  const classes = useStyles();
  const [submitLoading, setSubmitLoading] = useState(false);

  async function handleSubmit(data) {
    setSubmitLoading(true);

    await handleSuccess(successOptions);

    setSubmitLoading(false);

    handleClose();
  }

  return (
    <>
      {open && (
        <div className={classes.main}>
          <div className={classes.modal}>
            <h1>Excluir</h1>
            <Form
              ref={formRef}
              className={classes.form}
              onSubmit={handleSubmit}
            >
              <Grid item>
                <p className={classes.text}>
                  Realmente deseja excluir esse item? Esta ação não pode ser
                  revertida posteriormente!
                </p>
              </Grid>
              <Grid container item className={classes.buttonContainer}>
                <button type="submit" className={classes.submit}>
                  {!submitLoading && "Excluir"}
                  {submitLoading && <CircularProgress color="inherit" />}
                </button>
                <button onClick={() => handleClose()} className={classes.close}>
                  Fechar
                </button>
              </Grid>
            </Form>
          </div>
        </div>
      )}
    </>
  );
}
