import { Form } from '@unform/web';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import customerApi from '../../services/apis/customerApi';
import CustomSelectWithSearch from '../input/customSelectorWithSearch/CustomSelectWithSearch';
import Modal from '../modal/Modal';
import { ModalContainer } from './csModals.styles';

export const BackofficeResponsibleModal = ({ open, customer, handleClose }) => {
  const backofficeUsers = useSelector(state => state.backofficeUsers.all);
  const contactAtiveBackofficeResponsibleFormRef = useRef(null);

  const _handleFormSubmit = async data => {
    try {
      contactAtiveBackofficeResponsibleFormRef.current.setErrors({});

      const schema = Yup.object().shape({
        backofficeUserId: Yup.string()
          .required()
          .nullable(true)
          .label('Advogado responsável'),
      });

      const request = await schema.validate(data, {
        abortEarly: false,
      });

      await customerApi.updateActiveContactBackofficeUSer(customer.id, request);

      handleClose(true);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};

        error.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });

        contactAtiveBackofficeResponsibleFormRef.current.setErrors(
          validationErrors
        );

        return;
      }

      console.error(error);
    }
  };

  return (
    <Modal visible={open}>
      <ModalContainer>
        <i className='fa fa-2x fa-times closeModal' onClick={handleClose} />

        <h1>Atualizar responsável pelo contato ativo</h1>

        <p
          style={{
            margin: '10px 0px',
          }}
        >
          Responsável atual:{' '}
          {customer?.ActiveContact?.User ? (
            <Link
              style={{
                textDecoration: 'none',
              }}
              to={`/users/${customer.ActiveContact.backofficeUserId}`}
            >
              {customer.ActiveContact.User.name}
            </Link>
          ) : (
            'indefinido'
          )}
        </p>

        <Form
          ref={contactAtiveBackofficeResponsibleFormRef}
          onSubmit={_handleFormSubmit}
        >
          <div>
            {customer && (
              <CustomSelectWithSearch
                name='backofficeUserId'
                label='Advogado responsável'
                options={backofficeUsers?.map(user => ({
                  name: user.name,
                  value: user.id,
                }))}
                defaultValue={customer?.ActiveContact?.backofficeUserId}
              />
            )}
          </div>

          <button type='submit' className='submitButton'>
            Atualizar
          </button>
        </Form>
      </ModalContainer>
    </Modal>
  );
};
