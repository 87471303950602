import React, { useEffect, useRef, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import MiniDrawer from '../../components/MiniDrawer';
import { PageContainer } from '../../components/Pages.styles';
import { findNewIndexInIndexesList } from '../../utils';
import { toast } from 'react-toastify';
import Modal from '../../components/modal/Modal';
import { Form } from '@unform/web';
import CustomSelect from '../../components/input/CustomSelect';
import { useParams } from 'react-router-dom';
import CustomerJourneyDemandService from '../../services/journeys/customerJourneyDemandService';
import CustomerJourneyService from '../../services/journeys/customerJourneyService';
import Select from 'react-select'
import { v4 as uuidv4 } from 'uuid';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import DemandService from '../../services/demandService';
import { useHistory } from 'react-router'
import { useQuery } from '../../routes';
import history from '../../history';
import { Constants } from '../../utils/Constants';

const customerJourneyDemandService = new CustomerJourneyDemandService();
const customerJourneyService = new CustomerJourneyService();
const demandService = new DemandService();

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
  },
  error: {
    color: 'red',
    opacity: '70%',
    textAlign: 'justify',
    display: 'block',
  },
  input: {
    borderRadius: '5px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #e5e6e7',
    fontFamily: 'open sans, Helvetica Neue, Helvetica, Arial, sans-serif',
    height: '36px',
    marginTop: '2px',
    marginBottom: '6px',
    padding: '0px 10px',
    '&:focus': {
      border: '1px solid #18a689;',
    },
    width: '100%',
  },
  label: {
    color: '#A8A8A8',
    fontSize: '12px',
  },
  area: {
    background: '#FFFFFF',
    color: '#202020',
    fontSize: '16px',
    padding: '10px',
    resize: 'none',
    border: '1px solid rgb(220, 226, 230)',
    borderRadius: '5px',
    width: '100%',
  },
}));

const EditDemandIndexModal = ({ demand, demandIndexes, handleClose }) => {
  const updateDemandUnformRef = useRef(null);

  const _updateDemand = async data => {
    try {
      const updateData = {
        index: findNewIndexInIndexesList(
          demand.index,
          data.index,
          demandIndexes
        ),
      };

      await customerJourneyDemandService.updateCustomerJourneyDemand(
        demand.id,
        updateData
      );

      toast.success('Demanda atualizada com sucesso!');

      handleClose(true);
    } catch (error) {
      console.error(error);
    }
  };

  return !!demand ? (
    <Modal visible={true}>
      <Grid
        container
        style={{
          backgroundColor: '#fff',
          padding: '10px',
          borderRadius: '5px',
          boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
          width: '400px',
        }}
      >
        <Grid container className='titleContainer'>
          <h1>Editar demanda</h1>
        </Grid>

        <Grid container>
          <Form
            className='formContainer'
            ref={updateDemandUnformRef}
            onSubmit={_updateDemand}
          >
            <Grid container>
              <Grid item xs={12} className='inputContainer'>
                {demandIndexes && (
                  <CustomSelect
                    placeholder='Posição'
                    name='index'
                    label='Nova posição'
                    defaultValue={demand.index}
                    options={demandIndexes.map((index, fakeIndex) => ({
                      name: fakeIndex + 1,
                      value: index,
                    }))}
                  />
                )}
              </Grid>

              <Grid container justify='flex-end'>
                <button
                  type='button'
                  className='submit secondaryButton'
                  onClick={handleClose}
                  style={{ marginRight: '10px' }}
                >
                  Cancelar
                </button>

                <button type='submit' className='submit'>
                  Salvar Alterações
                </button>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </Modal>
  ) : null;
};

export default function EditCustomerJourneyDemandsOrder() {
  const { customerJourneyId } = useParams();
  const demandTypes = useSelector(state => state.demandTypes.all);
  const createDefaultDemandUnformRef = useRef(null);
  const customerId = useParams().id;
  const history = useHistory()
  const [state, setState] = useState({
    customerJourney: {},
    demands: [],
    isEditigDemand: false,
    customerJourneys: [],
    demands_: [],
    selectedCustomerJourneyId: +customerJourneyId,
    isCreatingDefaultDemand: false
  });
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      title: '',
      demandTypeId: '',
      description: ''
    }
  ])

  const classes = useStyles();

  const _fetchCustomerJourneys = async customerId => {
    try {
      const customerJourneys = (
        await customerJourneyService.listAllCustomerJourneys({
          customerId,
          withJourneyType: true,
          withDemands: true,
        })
      )?.data.customerJourneys;

      setState(prevState => ({
        ...prevState,
        customerJourneys: customerJourneys,
        demands_:
          customerJourneys.find(
            customerJourney => customerJourney.id === +customerJourneyId
          )?.demands || [],
      }));
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    if (customerId) _fetchCustomerJourneys(customerId)
  }, [customerId]);



  function timeout(delay) {
    return new Promise(res => setTimeout(res, delay));
  }

  const _updateDefaultDemand = async data => {

    try {
      for (let i = 0; i < inputFields.length; i++) {
        const createDemand = {
          name: inputFields[i].title,
          description: inputFields[i].description,
          customerId: customerId,
          demandUserId: state.demands_[0]?.userId,
          demandUserFollowingIds: state.demands_[0]?.following
        }

        const newDemand = await demandService.createDemand(createDemand);

        await demandService.updatedemandStatus({
          demandId: newDemand?.id,
          statusKey: Constants.DEMAND_STATUSES.BLOCKED.key
        })

        await customerJourneyService.addDemandToCustomerJourney(
          state.selectedCustomerJourneyId,
          newDemand?.id
        )
      }

      toast.success('Jornada do cliente editada com sucesso!');

      await timeout(1750);
      history.push(`/customers/${customerId}/journeys`)

    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};

        error.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });
        createDefaultDemandUnformRef.current.setErrors(errorMessages);
      }
    }
  };

  const addNewField = () => {
    setInputFields([...inputFields, {
      id: uuidv4(),
      title: '',
      demandTypeId: '',
      description: ''
    }]);
  }

  const handleChangeInput = (index, event) => {
    const values = [...inputFields]
    values[index][event.target.name] = event.target.value
    setInputFields(values)
  }
  const handleChangeInputSelector = (index, event) => {
    const values = [...inputFields]
    values[index]['demandTypeId'] = event.label
    setInputFields(values)
  }

  const _fetchCustomerJourneyData = async customerJourneyId => {
    try {
      const customerJourney =
        await customerJourneyService.getCustomerJourneyById(customerJourneyId);

      setState(prevState => ({
        ...prevState,
        customerJourney: customerJourney,
        demands: customerJourney.demands,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const _handleEditDemand = demand => {
    setState(prevState => ({
      ...prevState,
      isEditigDemand: demand,
    }));
  };

  const _handleCloseDemandEditModal = isUpdated => {
    setState(prevState => ({
      ...prevState,
      isEditigDemand: false,
    }));

    if (isUpdated) {
      _fetchCustomerJourneyData(customerJourneyId);
    }
  };

  useEffect(() => {
    _fetchCustomerJourneyData(customerJourneyId);
  }, [customerJourneyId]);

  return (
    <React.Fragment>
      <MiniDrawer currentExpaded='journeys'>
        <PageContainer>
          <Grid container>
            <Grid className='titleContainer' container>
              <h1>Editar ordem das demandas da jornada</h1>
            </Grid>

            {state.demands.map((demand, idx) => (
              <React.Fragment key={demand.id}>
                <Grid item xs={2} className='inputContainer'>
                  <p>
                    <strong>Posição:</strong>
                  </p>
                  <span>{idx + 1}</span>
                </Grid>

                <Grid item xs={9} className='inputContainer'>
                  <p>
                    <strong>Título:</strong>
                  </p>

                  <span>{demand.name}</span>
                </Grid>

                <Grid item xs={1} className='inputContainer'>
                  <button
                    type='button'
                    className='submit'
                    style={{
                      backgroundColor: '#2f4050',
                    }}
                    onClick={() => _handleEditDemand(demand)}
                  >
                    Editar
                  </button>
                </Grid>
              </React.Fragment>
            ))}

            <EditDemandIndexModal
              demand={state.isEditigDemand}
              demandIndexes={state.demands.map(demand => demand.index)}
              handleClose={_handleCloseDemandEditModal}
            />
          </Grid>
          <>
            {
              state.selectedCustomerJourneyId && (
                <Grid
                  container
                  style={{
                    backgroundColor: '#fff',
                    padding: '10px',
                    borderRadius: '5px',
                    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
                    width: '70%',
                  }}
                >
                  <Grid container className='titleContainer'>
                    <h1>Adicionar demanda na Jornada</h1>
                  </Grid>

                  <Form
                    className='formContainer'
                    ref={createDefaultDemandUnformRef}
                    onSubmit={_updateDefaultDemand}
                  >
                    <Grid container>
                      {
                        inputFields.map((inputField, index) => (
                          <Grid key={index} container>
                            <Grid item xs={6} className='inputContainer'>
                              <label className={classes.label} htmlFor='input'>
                                Título*
                              </label>
                              <input
                                maxLength='255'
                                className={classes.input}
                                name='title'
                                type='text'
                                value={inputField.title}
                                onChange={(e) => handleChangeInput(index, e)}
                                required
                              />
                            </Grid>
                            <Grid item xs={6} className='inputContainer'>
                              {demandTypes && (
                                <>
                                  <label className={classes.label} htmlFor='input'>
                                    Tipo de demanda
                                    <Select
                                      options={demandTypes?.map(demandType => (
                                        {
                                          value: demandType.id,
                                          label: demandType.name
                                        }
                                      ))}
                                      onChange={(e) => handleChangeInputSelector(index, e)}
                                      required
                                    />
                                  </label>
                                </>
                              )}
                            </Grid>
                            <Grid item xs={12} className='inputContainer'>
                              <label className={classes.label} htmlFor='input'>
                                Descrição*
                              </label>
                              <textarea
                                className={classes.area}
                                maxLength='255'
                                style={{ resize: false ? 'vertical' : 'none' }}
                                type='text'
                                name="description"
                                value={inputField.description}
                                onChange={(e) => handleChangeInput(index, e)}
                                required
                              />
                            </Grid>
                          </Grid>
                        ))
                      }
                      <Grid container justify='flex-end'>
                        <button
                          type='button'
                          className='submit secondaryButton'
                          onClick={addNewField}
                          style={{ marginRight: '10px' }}
                        >
                          +
                        </button>

                        <button type='submit' className='submit'>
                          Salvar
                        </button>
                      </Grid>
                    </Grid>
                  </Form>
                </Grid>
              )
            }
          </>
        </PageContainer>
      </MiniDrawer>
    </React.Fragment>
  );
}
