import moment from "moment";
import momentLocalePtBr from "moment/locale/pt-br.js";
import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Provider } from "react-redux";
import * as Yup from "yup";
import { pt } from "yup-locale-pt";
import "./global.css";
import { StyledToastContainer } from "./styles/global.styles";
import "react-toastify/dist/ReactToastify.css";
import redux from "./redux/store";
import Routes from "./routes";

const yupConfig = {
  ...pt,
  mixed: {
    ...pt.mixed,
    required: ({ label, path }) => {
      return `${label || path} é obrigatório`;
    },
  },
};

moment.updateLocale("pt-br", momentLocalePtBr);
Yup.setLocale(yupConfig);

const { store } = redux;

export default function App() {
  return (
    <DndProvider backend={HTML5Backend}>
      <Provider store={store}>
        <StyledToastContainer
          position="top-center"
          autoClose={2000}
          limit={2}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Routes />
      </Provider>
    </DndProvider>
  );
}
