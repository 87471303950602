import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  main: {
    width: '100%',
  },
  error: {
    color: 'red',
    opacity: '70%',
    textAlign: 'justify',
    display: 'block',
  },
  input: {
    background: '#FFFFFF',
    color: '#202020',
    fontSize: '16px',
    padding: '10px',
    resize: 'none',
    border: '1px solid rgb(220, 226, 230)',
    borderRadius: '5px',
    width: '100%',
  },
}));

export default function CustomTextArea({ name, label, resize, ...rest }) {
  const classes = useStyles();
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <div className={classes.main}>
      {label && (
        <label
          style={{
            fontSize: '12px',
            color: '#a8a8a8',
          }}
        >
          {label}
        </label>
      )}
      <textarea
        className={classes.input}
        ref={inputRef}
        defaultValue={defaultValue}
        maxLength='255'
        style={{ resize: resize ? 'vertical' : 'none' }}
        {...rest}
      />

      {error && <span className={classes.error}>{error}</span>}
    </div>
  );
}
