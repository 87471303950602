import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  height: 90vh;
  background-color: #fff;
  padding: 30px;
  border-radius: 20px;
  overflow-y: auto;
  .closeModal {
    align-self: flex-end;
    position: absolute;
    cursor: pointer;
  }

  h1 {
    font-size: 28px;
    width: 100%;
    text-align: center;
  }

  h2 {
    margin-top: 20px;
    font-size: 20px;
    align-self: flex-start;
  }

  form {
    & > button {
      background-color: #1ab394;
      border: none;
      border-radius: 5px;
      padding: 10px 20px;
      color: #fff;
      font-size: 16px;
      margin-top: 10px;
    }
  }
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 0px;

  form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
`;

export const ContainerInput = styled.div`
  width: 100%;
  margin-top: 15px;
`;
