import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import React from 'react';
import history from '../history';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {},
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
      borderLeft: '4px solid #19aa8d',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    background: '#2f4050',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
      background: '#293846',
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    background: '#293846',
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles(theme => ({
  icon: {
    padding: '0px 10px',
  },
  title: {
    fontFamily: 'open sans, Helvetica Neue, Helvetica, Arial, sans-serif',
    fontSize: '15px',
    fontWeight: 600,
    color: '#FFF',
    textDecoration: 'none',
  },
  item: {
    fontFamily: 'open sans, Helvetica Neue, Helvetica, Arial, sans-serif',
    fontSize: '13px',
    fontWeight: 600,
    color: '#FFF',
    textAlign: 'center',
    cursor: 'pointer',
    textDecoration: 'none',
  },
}));

export default function DrawerAccordion(props) {
  const { open, currentExpaded } = props;
  const [expanded, setExpanded] = React.useState(currentExpaded);
  const classes = useStyles();
  const roles = useSelector(state => state.auth.user)?.roles || [];

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion square onChange={() => history.push('/')}>
        <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
          <span className={classes.icon} style={{ color: '#FFF' }}>
            <i className='fas fa-chart-line fa-lg' />
          </span>
          {open && <h2 className={classes.title}>Dashboard</h2>}
        </AccordionSummary>
      </Accordion>

      {(roles.includes('CREATE_DEMAND') || roles.includes('GET_DEMANDS')) && (
        <Accordion
          square
          expanded={expanded === 'demands'}
          onChange={handleChange('demands')}
        >
          <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
            <span className={classes.icon} style={{ color: '#FFF' }}>
              <i className='fas fa-tasks fa-lg' />
            </span>
            {open && <h2 className={classes.title}>Demandas</h2>}
          </AccordionSummary>
          {roles.includes('CREATE_DEMAND') && (
            <a href={`${process.env.REACT_APP_NEW_BACKOFFICE_URL}#/demands/new`} className={classes.item}>
              <AccordionDetails>Criar</AccordionDetails>
            </a>
          )}
          {
            roles.includes('CREATE_DEMAND') && (
              <a href={`${process.env.REACT_APP_NEW_BACKOFFICE_URL}#/demands/new/massive`} className={classes.item}>
                <AccordionDetails>Enviar em Massa</AccordionDetails>
              </a>
            )
          }
          {roles.includes('CREATE_DEMAND') && (
            <a href={`${process.env.REACT_APP_NEW_BACKOFFICE_URL}#/demands/new/massive/message`} className={classes.item}>
              <AccordionDetails>Enviar Mensagem</AccordionDetails>
            </a>
          )}
          {roles.includes('GET_DEMANDS') && (
            <a href={`${process.env.REACT_APP_NEW_BACKOFFICE_URL}#/demands`} className={classes.item}>
              <AccordionDetails>Listar</AccordionDetails>
            </a>
          )}
          {roles.includes('CREATE_DEMAND') && (
            <a href={`${process.env.REACT_APP_NEW_BACKOFFICE_URL}#/demands/board`} className={classes.item}>
              <AccordionDetails>Painel</AccordionDetails>
            </a>
          )}
        </Accordion>
      )}

      <Accordion
        square
        expanded={expanded === 'journeys'}
        onChange={handleChange('journeys')}
      >
        <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
          <span className={classes.icon} style={{ color: '#FFF' }}>
            <i className='fas fa-road fa-lg' />
          </span>
          {open && <h2 className={classes.title}>Jornadas</h2>}
        </AccordionSummary>
        <a href={`${process.env.REACT_APP_NEW_BACKOFFICE_URL}#/journeys/types/create`} className={classes.item}>
          <AccordionDetails>Criar tipo de Jornada</AccordionDetails>
        </a>

        <a href={`${process.env.REACT_APP_NEW_BACKOFFICE_URL}#/journeys/types`} className={classes.item}>
          <AccordionDetails>Listar tipos de Jornadas</AccordionDetails>
        </a>

        <a href={`${process.env.REACT_APP_NEW_BACKOFFICE_URL}#/journeys/customer-journey/create`} className={classes.item}>
          <AccordionDetails>Criar jornadas para clientes</AccordionDetails>
        </a>

        <a href={`${process.env.REACT_APP_NEW_BACKOFFICE_URL}#/journeys/customer-journey`} className={classes.item}>
          <AccordionDetails>Listar jornadas de clientes</AccordionDetails>
        </a>
      </Accordion>

      <a href={`${process.env.REACT_APP_NEW_BACKOFFICE_URL}#/users/backoffice/profile`} className={classes.item}>
        <Accordion square>
          <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
            <span className={classes.icon} style={{ color: '#FFF' }}>
              <i className='far fa-user-circle fa-lg' />
            </span>
            {open && <h2 className={classes.title}>Perfil</h2>}
          </AccordionSummary>
        </Accordion>
      </a>




      <Accordion
        square
        expanded={expanded === 'cs'}
        onChange={handleChange('cs')}
      >
        <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
          <span className={classes.icon} style={{ color: '#FFF' }}>
            <i className='fas fa-chart-bar fa-lg' />
          </span>

          {open && <h2 className={classes.title}>Sucesso do Cliente</h2>}
        </AccordionSummary>

        <a href={`${process.env.REACT_APP_NEW_BACKOFFICE_URL}#/cs/demands-panel`} className={classes.item}>
          <AccordionDetails>Painel de demandas CS</AccordionDetails>
        </a>

        <a href={`${process.env.REACT_APP_NEW_BACKOFFICE_URL}#/cs/activeContact`} className={classes.item}>
          <AccordionDetails>Contato Ativo</AccordionDetails>
        </a>

        <a href={`${process.env.REACT_APP_NEW_BACKOFFICE_URL}#/cs/contracts`} className={classes.item}>
          <AccordionDetails>Renovações</AccordionDetails>
        </a>

        <a href={`${process.env.REACT_APP_NEW_BACKOFFICE_URL}#/cs/feedbacks/demand`} className={classes.item}>
          <AccordionDetails>Feedbacks</AccordionDetails>
        </a>

        <a href={`${process.env.REACT_APP_NEW_BACKOFFICE_URL}#/cs/feedbacks/onboarding`} className={classes.item}>
          <AccordionDetails>Feedbacks Onboarding</AccordionDetails>
        </a>

        <a href={`${process.env.REACT_APP_NEW_BACKOFFICE_URL}#/cs/feedbacks/activeContact`} className={classes.item}>
          <AccordionDetails>Feedbacks Contato Ativo</AccordionDetails>
        </a>

        <a href={process.env.REACT_APP_NEW_BACKOFFICE_URL} className={classes.item}>
          <AccordionDetails>Feedbacks Plataforma</AccordionDetails>
        </a>

        <a href={`${process.env.REACT_APP_NEW_BACKOFFICE_URL}#/cs/onboarding`} className={classes.item}>
          <AccordionDetails>Onboarding</AccordionDetails>
        </a>
      </Accordion>

      <Accordion
        square
        expanded={expanded === 'sales'}
        onChange={handleChange('sales')}
      >
        <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
          <span className={classes.icon} style={{ color: '#FFF' }}>
            <i className='fas fa-dollar-sign fa-lg' />
          </span>
          {open && <h2 className={classes.title}>Vendas</h2>}
        </AccordionSummary>

        <a href={process.env.REACT_APP_NEW_BACKOFFICE_URL} className={classes.item}>
          <AccordionDetails>Painel</AccordionDetails>
        </a>
      </Accordion>

      {(roles.includes('CREATE_USER') || roles.includes('GET_USERS')) && (
        <Accordion
          square
          expanded={expanded === 'users'}
          onChange={handleChange('users')}
        >
          <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
            <span className={classes.icon} style={{ color: '#FFF' }}>
              <i className='fas fa-users fa-lg' />
            </span>
            {open && <h2 className={classes.title}>Usúarios</h2>}
          </AccordionSummary>
          {roles.includes('CREATE_USER') && (
            <a href={`${process.env.REACT_APP_NEW_BACKOFFICE_URL}#/users/backoffice/new`} className={classes.item}>
              <AccordionDetails>Criar</AccordionDetails>
            </a>
          )}
          {roles.includes('GET_USERS') && (
            <a href={`${process.env.REACT_APP_NEW_BACKOFFICE_URL}#/users/backoffice`} className={classes.item}>
              <AccordionDetails>Listar</AccordionDetails>
            </a>
          )}
          {roles.includes('GET_USERS') && (
            <a href={`${process.env.REACT_APP_NEW_BACKOFFICE_URL}#/users/vacations`} className={classes.item}>
              <AccordionDetails>Férias</AccordionDetails>
            </a>
          )}
        </Accordion>
      )}

      {(roles.includes('CREATE_CUSTOMER') ||
        roles.includes('GET_CUSTOMERS')) && (
          <Accordion
            square
            expanded={expanded === 'customers'}
            onChange={handleChange('customers')}
          >
            <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
              <span className={classes.icon} style={{ color: '#FFF' }}>
                <i className='fas fa-user-tag fa-lg' />
              </span>
              {open && <h2 className={classes.title}>Clientes</h2>}
            </AccordionSummary>
            {roles.includes('CREATE_CUSTOMER') && (
              <a href={`${process.env.REACT_APP_NEW_BACKOFFICE_URL}#/customers/new`} className={classes.item}>
                <AccordionDetails>Criar</AccordionDetails>
              </a>
            )}
            {roles.includes('GET_CUSTOMERS') && (
              <a href={`${process.env.REACT_APP_NEW_BACKOFFICE_URL}#/customers`} className={classes.item}>
                <AccordionDetails>Listar</AccordionDetails>
              </a>
            )}
          </Accordion>
        )}
      {(roles.includes('CREATE_TEMPLATE') ||
        roles.includes('GET_TEMPLATES')) && (
          <Accordion
            square
            expanded={expanded === 'templates'}
            onChange={handleChange('templates')}
          >
            <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
              <span className={classes.icon} style={{ color: '#FFF' }}>
                <i className='fas fa-file-alt fa-lg' />
              </span>
              {open && <h2 className={classes.title}>Templates</h2>}
            </AccordionSummary>
            {roles.includes('CREATE_TEMPLATE') && (
              <a href={process.env.REACT_APP_NEW_BACKOFFICE_URL} className={classes.item}>
                <AccordionDetails>Criar</AccordionDetails>
              </a>
            )}
            {roles.includes('GET_TEMPLATES') && (
              <a href={process.env.REACT_APP_NEW_BACKOFFICE_URL} className={classes.item}>
                <AccordionDetails>Listar</AccordionDetails>
              </a>
            )}
            <a href={process.env.REACT_APP_NEW_BACKOFFICE_URL} className={classes.item}>
              <AccordionDetails>Métricas</AccordionDetails>
            </a>
          </Accordion>
        )}

      <Accordion
        square
        expanded={expanded === 'riskAnalysis'}
        onChange={handleChange('riskAnalysis')}
      >
        <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
          <span className={classes.icon} style={{ color: '#FFF' }}>
            <i class="fa fa-area-chart fa-lg" />
          </span>
          <h2 className={classes.title}>Análise de Risco</h2>
        </AccordionSummary>
        <a href={`${process.env.REACT_APP_NEW_BACKOFFICE_URL}#/air`} className={classes.item}>
          <AccordionDetails>Questões</AccordionDetails>
        </a>
        <a href={`${process.env.REACT_APP_NEW_BACKOFFICE_URL}#/air/menu`} className={classes.item}>
          <AccordionDetails>Menu</AccordionDetails>
        </a>
      </Accordion>

      <Accordion
        square
        expanded={expanded === 'tools' || expanded === 'templates'}
        onChange={handleChange('tools')}
      >
        <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
          <span className={classes.icon} style={{ color: '#FFF' }}>
            <i className='fas fa-cog fa-lg' />
          </span>

          {open && <h2 className={classes.title}>Ferramentas</h2>}
        </AccordionSummary>
        <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
          {open && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <a href={`${process.env.REACT_APP_NEW_BACKOFFICE_URL}#/tools/ropa`}  className={classes.item} style={{ marginBottom: '30px' }}>
                <span className={classes.icon} style={{ color: '#FFF' }}>
                  <i className='fas fa-map fa-lg' />
                </span>
                ROPA
              </a>
              <a href={`${process.env.REACT_APP_NEW_BACKOFFICE_URL}#/tools/marcas`} className={classes.item} style={{ marginBottom: '30px' }}>
                <span className={classes.icon} style={{ color: '#FFF' }}>
                  <i class="fas fa-regular fa-registered"></i>
                </span>
                Marcas
              </a>
              <a href={`${process.env.REACT_APP_NEW_BACKOFFICE_URL}#/tools/fileToUrl`} className={classes.item} style={{ marginBottom: '30px' }}>
                <span className={classes.icon} style={{ color: '#FFF' }}>
                  <i class="fas fa-regular fa-file"></i>
                </span>
                Gerador de URL
              </a>
              <a href={`${process.env.REACT_APP_NEW_BACKOFFICE_URL}`} className={classes.item}>
                <span className={classes.icon} style={{ color: '#FFF' }}>
                  <i class="fas fa-regular fa-window-maximize"></i>
                </span>
                Gerador de Pop-Up
              </a>
            </div>
          )}
        </AccordionSummary>
      </Accordion>

      {(roles.includes('CREATE_DOCUMENT') ||
        roles.includes('GET_DOCUMENTS')) && (
          <Accordion
            square
            expanded={expanded === 'documents'}
            onChange={handleChange('documents')}
          >
            <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
              <span className={classes.icon} style={{ color: '#FFF' }}>
                <i className='fas fa-file-signature fa-lg' />
              </span>
              {open && <h2 className={classes.title}>Documentos</h2>}
            </AccordionSummary>
            {roles.includes('CREATE_DOCUMENT') && (
              <a href={`${process.env.REACT_APP_NEW_BACKOFFICE_URL}`} className={classes.item}>
                <AccordionDetails>Criar</AccordionDetails>
              </a>
            )}
            {roles.includes('GET_DOCUMENTS') && (
              <a href={`${process.env.REACT_APP_NEW_BACKOFFICE_URL}`} className={classes.item}>
                <AccordionDetails>Listar</AccordionDetails>
              </a>
            )}
          </Accordion>
        )}

      <Accordion
        square
        expanded={expanded === 'tags'}
        onChange={handleChange('tags')}
      >
        <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
          <span className={classes.icon} style={{ color: '#FFF' }}>
            <i className='fas fa-tags  fa-lg' />
          </span>
          {open && <h2 className={classes.title}>Tags</h2>}
        </AccordionSummary>

        <a href={`${process.env.REACT_APP_NEW_BACKOFFICE_URL}#/tag/new`} className={classes.item}>
          <AccordionDetails>Criar</AccordionDetails>
        </a>

        <a href={`${process.env.REACT_APP_NEW_BACKOFFICE_URL}#/tag`} className={classes.item}>
          <AccordionDetails>Listar</AccordionDetails>
        </a>
      </Accordion>

      {(roles.includes('CREATE_DEMAND_TYPE') ||
        roles.includes('GET_DEMAND_TYPE')) && (
          <Accordion
            square
            expanded={expanded === 'demandTypes'}
            onChange={handleChange('demandTypes')}
          >
            <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
              <span className={classes.icon} style={{ color: '#FFF' }}>
                <i className='fas fa-list-alt fa-lg' />
              </span>
              {open && <h2 className={classes.title}>Tipos de Demanda</h2>}
            </AccordionSummary>
            {roles.includes('CREATE_DEMAND_TYPE') && (
              <a href={`${process.env.REACT_APP_NEW_BACKOFFICE_URL}#/demand-types/new`} className={classes.item}>
                <AccordionDetails>Criar</AccordionDetails>
              </a>
            )}
            {roles.includes('GET_DEMAND_TYPES') && (
              <a href={`${process.env.REACT_APP_NEW_BACKOFFICE_URL}#/demand-types`} className={classes.item}>
                <AccordionDetails>Listar</AccordionDetails>
              </a>
            )}
          </Accordion>
        )}
    </div>
  );
}
