import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import MiniDrawer from '../components/MiniDrawer';
import apiOld from '../services/Api';

const useStyles = makeStyles(() => ({
  main: {},
  notificationsContainer: {
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'none',
  },
  notificationItem: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    borderBottom: 'solid 0.7px #707070',
    cursor: 'pointer',
    marginBottom: '15px',
    textDecoration: 'none',
  },
  notificationTitle: {
    color: 'rgba(0, 0, 0, 0.87)',
    textDecoration: 'none',
  },
  notificationDescription: {
    marginTop: '3px',
    marginBottom: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    textDecoration: 'none',
  },
}));

export default function Notifications() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const getUserNotifications = useCallback(async () => {
    try {
      setLoading(true);
      const params = {
        limit: 1000,
        page: 0,
      };

      const response = await apiOld.get('/users/notifications', { params });

      apiOld.put('/users/notifications');

      setNotifications(response.data.userNotifications);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    getUserNotifications();
  }, [getUserNotifications]);

  return (
    <React.Fragment>
      <MiniDrawer
        main={
          <Grid container className={classes.main}>
            <Backdrop className={classes.backdrop} open={loading}>
              <CircularProgress />
            </Backdrop>

            <Grid container className={classes.notificationsContainer}>
              {notifications.map(item => (
                <Grid key={item.id} item>
                  <Link
                    className={classes.notificationItem}
                    to={{
                      pathname: '',
                      search: `?demandId=${item.link}`,
                    }}
                  >
                    <h4 className={classes.notificationTitle}>{item.title}</h4>

                    <p className={classes.notificationDescription}>
                      {item.description}
                    </p>
                  </Link>
                </Grid>
              ))}

              {notifications.length === 0 && (
                <h2 style={{ textAlign: 'center', marginTop: '20%' }}>
                  Nenhuma notificação por aqui!
                </h2>
              )}
            </Grid>
          </Grid>
        }
      />
    </React.Fragment>
  );
}
