import React, { useState } from 'react';
import MiniDrawer from '../../../../components/MiniDrawer';
import Table from '../../../../components/Table';
import moment from 'moment';
import {
  Container,
  Title,
  Section,
  SectionContent,
} from '../../Dashboard.styles';
import InfoModal from '../../../../components/infoModal/InfoModal';
import { formatDate } from '../../../../utils';
const feedbackTableHeadCells = [
  { id: 'date', numeric: false, label: 'Data/Hora' },
  { id: 'email', numeric: false, label: 'Email' },
  { id: 'name', numeric: false, label: 'Nome do Cliente' },
  { id: 'feedbackMeaning', numeric: false, label: 'Ocasião' },
  { id: 'feedbackDate', numeric: false, label: 'Data do Feedback' },
  { id: 'feedbackType', numeric: false, label: 'Feedback' },
];

const handleFeedbackTableRows = data => {
  return {
    rows: data.rows.map(
      ([
        date,
        email,
        name,
        feedbackMeaning,
        feedbackDate,
        feedbackType,
        feedback,
      ]) => ({
        date: formatDate(moment(date, 'DD/MM/YYYY HH:mm:ss')),
        email,
        name,
        feedbackMeaning,
        feedbackDate,
        feedbackType,
        feedback,
      })
    ),
    total: (+data.page + 1) * data.limit + +data.limit,
  };
};

export default function FeedbackDashboard() {
  const [modal, setModal] = useState({
    open: false,
    info: '',
    title: '',
    handleClose: () => {},
  });

  const handleClose = () =>
    setModal({
      open: false,
      info: '',
      title: '',
      handleClose: () => {},
    });

  const handleOpenInfoModal = info =>
    setModal(prev => ({
      ...prev,
      open: true,
      title: 'Feedbacks',
      info,
      handleClose,
    }));

  return (
    <React.Fragment>
      <MiniDrawer
        currentExpaded='cs'
        main={
          <Container>
            <Title>Feedbacks da Plataforma</Title>

            <Section>
              <SectionContent>
                <Table
                  title='Respostas Google Forms'
                  headCells={feedbackTableHeadCells}
                  handleRows={handleFeedbackTableRows}
                  handleClickItem={({ feedback }) =>
                    handleOpenInfoModal(feedback)
                  }
                  route='feedbacks/platform'
                  rolePrefix=''
                />
              </SectionContent>
            </Section>
            <InfoModal {...modal} />
          </Container>
        }
      />
    </React.Fragment>
  );
}
