import React, { useRef, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import CustomTextArea from '../../input/CustomTextArea';
import {
  ButtonsContainer,
  Container,
  ContainerInput,
  ExitButton,
  Modal,
  SubmitButton,
} from './SendPrivateMessageModal.styles';
import demandProcessItemsApi from '../../../services/apis/demandProccessItemsApi';

export default function SendPrivateMessageModal(props) {
  const { open, handleClose, modalData } = props;
  const formRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  async function handleSubmit(data) {
    try {
      setSubmitLoading(true);

      const request = {
        content: data.message,
      };

      await demandProcessItemsApi.createPrivateMessage(modalData.id, request);

      handleClose(true);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};

        error.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
    setSubmitLoading(false);
  }

  return (
    <>
      {open && (
        <Container>
          <Modal>
            <h1>Enviar mensagem privada</h1>

            <Form ref={formRef} onSubmit={handleSubmit}>
              <ContainerInput>
                <CustomTextArea
                  maxLength='70000'
                  name='message'
                  placeholder='Escrever mensagem'
                  rows={4}
                />
              </ContainerInput>

              <ButtonsContainer>
                <SubmitButton type='submit'>
                  {!submitLoading && 'Enviar'}
                  {submitLoading && <CircularProgress color='inherit' />}
                </SubmitButton>

                <ExitButton onClick={() => handleClose(false)}>
                  Fechar
                </ExitButton>
              </ButtonsContainer>
            </Form>
          </Modal>
        </Container>
      )}
    </>
  );
}
