import React, { useRef, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@unform/web';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { PATCH_DEMAND } from '../../../redux/actionsTypes/demandActionTypes';
import { newApi } from '../../../services/Api';
import CustomSelectWithSearch from '../../input/customSelectorWithSearch/CustomSelectWithSearch';
import {
  ButtonsContainer,
  Container,
  ContainerInput,
  ExitButton,
  Modal,
  SubmitButton,
  SlaExterno,
} from './ScreeningModal.styles';
import CustomerSlasCalendar from '../../customerSlasCalendar/CustomerSlasCalendar';
import demandsApi from '../../../services/apis/demandsApi';


export default function ModalTriagem({ open, handleClose, modalData, SLAExterno }) {
  const dispatch = useDispatch();
  const triagemFormRef = useRef(null);
  const demandTypes = useSelector(state => state.demandTypes.all);
  const backofficeUsers = useSelector(state => state.backofficeUsers.all);
  const [submitLoading, setSubmitLoading] = useState(null);
  const [demandTypeId, setDemandTypeId] = useState(null);

  async function handleScreeningDemand(data) {
    try {
      setSubmitLoading(true);
      triagemFormRef.current.setErrors({});

      const schema = Yup.object().shape({
        demandTypeId: Yup.string().label('Tipo de demanda').required(),
        backofficeUserId: Yup.string().label('Advogado').required(),
        deleveryForecast: Yup.date()
          .label('SLA')
          .nullable(true)
          .when('$ignoreSla', (ignoreSla, dfS) =>
            ignoreSla ? dfS.notRequired() : dfS.required()
          ),
      });

      await schema.validate(data, {
        abortEarly: false,
        context: {
          ignoreSla: !!modalData.demandTypeId || !!modalData.priority,
        },
      });

      const newValueDeleveryForecast = data.deleveryForecast || SLAExterno;
      const request = {
        demandTypeId: data.demandTypeId,
        backofficeUserId: data.backofficeUserId,
        deleveryForecast:
          !modalData.demandTypeId || !modalData.priority
            ? newValueDeleveryForecast
            : null,
      };

      let res =
        await newApi.put(`/demands/${modalData.demandId}/screening`, request).catch(err => err.response)
        ;

      if (res.status !== 200) {
        return alert(res.data.error)
      }

      if (modalData.priority === true && demandTypes.find(({id}) => id === data.demandTypeId).name !== 'Reunião') {
        res = await demandsApi.prioritizeDemand(modalData.demandId);
      }

      if (res.status !== 200) {
        alert(res.data)
      }

      dispatch({
        type: PATCH_DEMAND,
        demand: res.data,
      });

      handleClose(modalData.demandId);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};

        error.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });

        return triagemFormRef.current.setErrors(validationErrors);
      }

      console.error(error);
    }
    finally {
      setSubmitLoading(false);
    }
  }

  const getDefaultTypeDemand = (demand) => {
    const listDefaultTypeDemand = [ 'Análise Interna de Risco' ];
    const isDefaultDemand = demand?.name.includes(listDefaultTypeDemand);
    const demandType = demandTypes?.find(demandType => listDefaultTypeDemand.includes(demandType.name));

    return isDefaultDemand ? demandType?.id : null;
  }

  return (
    <>
      {(open && demandTypes && backofficeUsers && (
        <Container>
          <Modal>
            <h1>Pré-Triagem</h1>

            <Form ref={triagemFormRef} onSubmit={handleScreeningDemand}>
              {backofficeUsers && (
                <ContainerInput>
                  <CustomSelectWithSearch
                    placeholder='Advogado'
                    name='backofficeUserId'
                    label='Advogado'
                    defaultValue={modalData?.backofficeUserId}
                    options={backofficeUsers?.map(users => ({
                      name: users.name,
                      value: users.id,
                    }))}
                  />
                </ContainerInput>
              )}

              <ContainerInput>
                {demandTypes && (
                  <CustomSelectWithSearch
                    placeholder='Tipo de demandas'
                    name='demandTypeId'
                    label='Tipo de demandas'
                    defaultValue={
                      getDefaultTypeDemand(modalData) ?
                        () => {
                          setDemandTypeId(getDefaultTypeDemand(modalData));
                          return getDefaultTypeDemand(modalData);
                        } :
                        modalData?.demandTypeId &&
                        demandTypes?.find(
                          demand => demand.id === modalData?.demandTypeId
                        ).id
                    }
                    options={demandTypes?.map(demandType => ({
                      name: demandType.name,
                      value: demandType.id,
                    })).sort((a, b) => a.name.localeCompare(b.name))}
                    onChange={demandType => setDemandTypeId(demandType)}
                  />
                )}
              </ContainerInput>
              {!modalData?.priority &&
                !modalData?.demandTypeId &&
                demandTypeId && (
                  <ContainerInput>
                    <CustomerSlasCalendar
                      name='deleveryForecast'
                      modalData={{
                        ...modalData,
                        demandTypeId,
                      }}
                    />
                  </ContainerInput>
                )}
              {
                modalData?.priority === true && (
                  <SlaExterno>
                    <span id="title">SLA Externo</span>
                    <span id='sla'>{SLAExterno}</span>
                  </SlaExterno>
                )
              }

              <ButtonsContainer>
                <SubmitButton type='submit'>
                  {!submitLoading && 'Concluir'}
                  {submitLoading && <CircularProgress color='inherit' />}
                </SubmitButton>
                <ExitButton onClick={() => handleClose(false)}>
                  Fechar
                </ExitButton>
              </ButtonsContainer>
            </Form>
          </Modal>
        </Container>
      )) ||
        null}
    </>
  );
}
