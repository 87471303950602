import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FeedbackModalStyles } from './FeedbackModal.styles';
import Modal from '../../modal/Modal';
import csApi from '../../../services/apis/csApi';
import { formatDate } from '../../../utils';
import { Constants } from '../../../utils/Constants';
import { openDemandModal, useQuery } from '../../../routes';
import { Link } from 'react-router-dom';

function FeedbackModal({ handleClose, ...rest }) {
  const demandFeedbackId = useQuery().get(
    Constants.QUERY_PARAMS_STATES.FEEDBACK.DEMAND_FEEDBACK_MODAL
  );
  const [feedbackData, setFeedbackData] = useState(null);

  const fetchFeedbackData = useCallback(async () => {
    try {
      const feedbackDataResponse = (
        await csApi.getFeedbackById(demandFeedbackId)
      )?.data;

      setFeedbackData(feedbackDataResponse);
    } catch (error) {
      handleClose();
    }
  }, [handleClose, demandFeedbackId]);

  useEffect(() => {
    if (demandFeedbackId) fetchFeedbackData();
  }, [demandFeedbackId, fetchFeedbackData]);

  return (
    <Modal visible={!!demandFeedbackId} {...rest}>
      {feedbackData && (
        <FeedbackModalStyles.Container>
          <FeedbackModalStyles.ButtonClose
            className='fa fa-lg fa-times'
            onClick={handleClose}
          ></FeedbackModalStyles.ButtonClose>

          <FeedbackModalStyles.Title>Feedback</FeedbackModalStyles.Title>

          <FeedbackModalStyles.InfoContainer>
            <FeedbackModalStyles.Label>Nota</FeedbackModalStyles.Label>

            <FeedbackModalStyles.InfoText>
              {feedbackData.rating}/{Constants.FEEDBACK_MAX_RATING}
            </FeedbackModalStyles.InfoText>
          </FeedbackModalStyles.InfoContainer>

          <FeedbackModalStyles.InfoContainer>
            <FeedbackModalStyles.Label>
              Detalhes do feedback
            </FeedbackModalStyles.Label>

            <FeedbackModalStyles.InfoText>
              {feedbackData.description}
            </FeedbackModalStyles.InfoText>
          </FeedbackModalStyles.InfoContainer>

          <FeedbackModalStyles.InfoContainer>
            <FeedbackModalStyles.Label>Demanda</FeedbackModalStyles.Label>

            <FeedbackModalStyles.InfoText>
              <button
                onClick={() => {
                  openDemandModal(feedbackData.demandId);
                  handleClose();
                }}
              >
                {feedbackData.Demand?.name || feedbackData.demandId}
              </button>
            </FeedbackModalStyles.InfoText>
          </FeedbackModalStyles.InfoContainer>

          <FeedbackModalStyles.InfoContainer>
            <FeedbackModalStyles.Label>Cliente</FeedbackModalStyles.Label>

            <FeedbackModalStyles.InfoText>
              <Link to={`/customers/${feedbackData.customerId}`}>
                {feedbackData.Customer?.name || feedbackData.customerId}
              </Link>
            </FeedbackModalStyles.InfoText>
          </FeedbackModalStyles.InfoContainer>

          <FeedbackModalStyles.InfoContainer>
            <FeedbackModalStyles.Label>Usuário</FeedbackModalStyles.Label>

            <FeedbackModalStyles.InfoText>
              <Link to={`/users/${feedbackData.userId}`}>
                {feedbackData.User?.name || feedbackData.userId}
              </Link>
            </FeedbackModalStyles.InfoText>
          </FeedbackModalStyles.InfoContainer>

          <FeedbackModalStyles.InfoContainer>
            <FeedbackModalStyles.Label>Criado em</FeedbackModalStyles.Label>

            <FeedbackModalStyles.InfoText>
              {formatDate(feedbackData.createdAt)}
            </FeedbackModalStyles.InfoText>
          </FeedbackModalStyles.InfoContainer>
        </FeedbackModalStyles.Container>
      )}
    </Modal>
  );
}

FeedbackModal.propTypes = {
  handleClose: PropTypes.func,
};

export default FeedbackModal;
