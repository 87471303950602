import React, { useEffect, useRef, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from '@unform/web';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import CustomInput from '../../../components/input/CustomInput';
import CustomSelect from '../../../components/input/CustomSelect';
import FileInput from '../../../components/input/FileInput';
import MiniDrawer from '../../../components/MiniDrawer';
import history from '../../../history';
import TemplateService from '../../../services/templates/templateService';
import { formatFileToBase64 } from '../../../utils';
import { Constants } from '../../../utils/Constants';
import editTemplateFile from '../../../domains/usecases/templates/editTemplateFile';

const templateService = new TemplateService();

const useStyles = makeStyles(() => ({
  main: {
    background: '#FFF',
  },
  mainTitle: {
    background: '#FFF',
    borderTop: 'solid 2px #e7eaec',
    borderBottom: 'solid 1px #e7eaec',
    padding: '15px',
  },
  line: {
    backgorund: 'reds',
  },
  title: {
    fontSize: '15px',
  },
  mainForm: {
    padding: '0px 10px',
  },
  form: {
    width: '100%',
  },
  item: {
    padding: '10px 10px 0px',
  },
  input: {
    padding: '10px 10px 0px',
  },
  buttonContainer: {
    justifyContent: 'flex-end',
    padding: '10px',
  },
  submit: {
    padding: '10px 15px',
    fontSize: '17px',
    background: '#1ab394',
    border: 'none',
    color: '#fff',
    borderRadius: '5px',
  },
}));

const FieldInputElement = ({ name, namePrefix }) => {
  const classes = useStyles();
  const [fieldType, setFieldType] = useState();

  const _handleFieldTypeInputChange = async e => {
    setFieldType(e.target.value);
  };

  return (
    <Grid
      container
      justify='space-between'
      style={{
        borderBottom: '1px solid gray',
        marginBottom: '10px',
      }}
    >
      <span
        style={{
          fontSize: '13px',
          fontWeight: 'bold',
        }}
      >
        {name}
      </span>

      <Grid container key={namePrefix}>
        <Grid item className={classes.input} xs={4}>
          <CustomInput
            name={`${namePrefix}.name`}
            label='Nome'
            value={name}
            disabled
          />
        </Grid>

        <Grid item className={classes.input} xs={4}>
          <CustomInput name={`${namePrefix}.description`} label='Descrição' />
        </Grid>

        <Grid item className={classes.input} xs={4}>
          <CustomInput name={`${namePrefix}.label`} label='Label' />
        </Grid>

        <Grid item className={classes.input} xs={4}>
          <CustomInput name={`${namePrefix}.placeholder`} label='Placeholder' />
        </Grid>

        <Grid item className={classes.input} xs={4}>
          <CustomSelect
            name={`${namePrefix}.type`}
            label='Tipo de campo'
            options={Constants.TEMPLATE_FIELDS_TYPES}
            onChange={_handleFieldTypeInputChange}
          />
        </Grid>

        {fieldType === 'options' && (
          <Grid item className={classes.input} xs={4}>
            <CustomInput
              name={`${namePrefix}.options`}
              label='Opções (separadas por vírgula)'
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default function EditTemplateFile() {
  const { id: templateId } = useParams();
  const classes = useStyles();
  const formRef = useRef(null);
  const [template, setTemplate] = useState({
    name: '',
    fields: [],
  });
  const [newTemplate, setNewTemplate] = useState({
    loaded: false,
    fields: [],
  });

  const _fetchTemplateData = async templateId => {
    try {
      const template = await templateService.getTemplateById(templateId);

      setTemplate(template);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (templateId) {
      _fetchTemplateData(templateId);
    }
  }, [templateId]);

  const _getFieldsFromBase64TemplateFile = async base64TemplateFile => {
    const fields = await templateService.getFieldsFromBase64TemplateFile(
      base64TemplateFile
    );

    setNewTemplate({
      loaded: true,
      fields: Object.keys(fields),
    });
  };

  const _handleTemplateFileInputChange = async e => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    const base64File = await formatFileToBase64(file);

    _getFieldsFromBase64TemplateFile(base64File);
  };

  async function handleEditTemplateFileFormSubmit(formData) {
    try {
      formRef.current.setErrors({});

      const templateUpdated = await editTemplateFile(templateId, {
        ...formData,
        removedFieldsIds: _getRemovedFieldsIdsFromOldTemplate(
          template.fields,
          newTemplate.fields
        ),
      });

      toast.success('Template editado com sucesso!');

      history.push(`/templates/${templateUpdated.id}/edit`);
    } catch (error) {
      console.error(error);

      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};

        error.inner.forEach(error => {
          validationErrors[`${error.path}`] = error.message;
          toast.error(error.message, {
            toastId: 'validation-error',
          });
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  }

  const _getNewFieldsFromNewTemplate = (
    newTemplateFields = [],
    oldTemplateFields = []
  ) => {
    return newTemplateFields.filter(
      newFieldName =>
        !oldTemplateFields.find(oldFiel => oldFiel.name === newFieldName)
    );
  };

  const _getRemovedFieldsIdsFromOldTemplate = (
    oldTemplateFields = [],
    newTemplateFields = []
  ) => {
    return oldTemplateFields
      .filter(
        oldField =>
          !newTemplateFields.find(newField => newField === oldField.name)
      )
      .map(field => field.id);
  };

  return (
    <React.Fragment>
      <MiniDrawer currentExpaded='templates'>
        <Grid container className={classes.main}>
          <Grid container className={classes.mainTitle}>
            <h2 className={classes.title}>Editar Arquivo de Template</h2>
          </Grid>

          <Grid container className={classes.mainForm}>
            <Grid item className={classes.item} xs={6}>
              <h3>Template: {template.name}</h3>
            </Grid>

            <Form
              ref={formRef}
              onSubmit={handleEditTemplateFileFormSubmit}
              className={classes.form}
            >
              <Grid item className={classes.input} xs={12}>
                <FileInput
                  name='templateFile'
                  label='Novo Arquivo de Template'
                  onChange={_handleTemplateFileInputChange}
                />
              </Grid>

              {newTemplate.loaded &&
                (() => {
                  const newFields = _getNewFieldsFromNewTemplate(
                    newTemplate.fields,
                    template.fields
                  );
                  return (
                    <>
                      <Grid container className={classes.input}>
                        <Grid item xs={12}>
                          <h3
                            style={{
                              marginBottom: '10px',
                            }}
                          >
                            {newFields.length
                              ? 'Novos campos detectados:'
                              : 'Nenhum novo campo detectado!'}
                          </h3>
                        </Grid>

                        {newFields.map((fieldName, idx) => (
                          <FieldInputElement
                            key={fieldName}
                            namePrefix={`newFields[${idx}]`}
                            name={fieldName}
                          />
                        ))}
                      </Grid>

                      <Grid container className={classes.buttonContainer}>
                        <button className={classes.submit} type='submit'>
                          Atualizar arquivo
                        </button>
                      </Grid>
                    </>
                  );
                })()}
            </Form>
          </Grid>
        </Grid>
      </MiniDrawer>
    </React.Fragment>
  );
}
