import React from 'react';
import PropTypes from 'prop-types';
import { ModalContainer } from './Modal.styles';

function Modal({ visible, children, ...rest }) {
  return visible ? (
    <ModalContainer visible={visible} {...rest}>
      {children}
    </ModalContainer>
  ) : null;
}

Modal.propTypes = {
  visible: PropTypes.bool,
};

export default Modal;
