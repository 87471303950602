import { Form } from '@unform/web';
import {
    ButtonsContainer,
    Container,
    ContainerInput,
    ExitButton,
    Modal,
    SubmitButton,
    SlaExterno,
  } from './screeningModal/ScreeningModal.styles';
import { useEffect, useRef, useState } from 'react';
import customerApi from '../../services/apis/customerApi';
import CustomSelectWithSearch from '../input/customSelectorWithSearch/CustomSelectWithSearch';
import businessApi from '../../services/apis/businessApi';
import demandsApi from '../../services/apis/demandsApi';

export default function AlterBusinessModal({open,setOpen,demand}){
    const alterBusinessFormRef = useRef(null);
    const [business,setBusiness] = useState([]);

    useEffect(() => {
        businessApi.getByCustomerId(demand.customerId).then(response => {
            setBusiness(response.data)
        })
    },[demand.customerId])

    if(!open){
        return null
    }


    const handleSubmit = async (data) => {
        await demandsApi.alterBusiness(demand.id,data.businessId)
        setOpen(false)
    }


    return(
        <Container>
            <Modal>
                <h1>Alterar negócio</h1>

                <Form ref={alterBusinessFormRef} onSubmit={handleSubmit} >
                    {business && (
                        <ContainerInput>
                            <CustomSelectWithSearch
                                placeholder='Negócio'
                                name='businessId'
                                defaultValue={demand.businessId}
                                options={business.map(business => ({
                                    value: business.id,
                                    name: business.name
                                }))}
                            />
                        </ContainerInput>
                    )}


                    <ButtonsContainer>
                        <SubmitButton type='submit'>
                            Concluir
                        </SubmitButton>
                        <ExitButton onClick={() => setOpen(false)}>
                            Fechar
                        </ExitButton>
                    </ButtonsContainer>
                </Form>
            </Modal>

        </Container>
    )
}