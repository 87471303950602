import Api from '../Api';
import { toast } from 'react-toastify';
export const getMarcasById = async (id) => {
    const response = await Api.get(`/marcas/${id}`);
    return response.data;
};   

export const updateMarcas = async (id, data) => {
    await Api.patch(`/marcas/${id}`, data);
    toast.success('Marca atualizada com sucesso!');
}