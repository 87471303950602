import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import Loading from '../../../components/Loading';
import ChurnMetrics from '../../../components/metrics/churn';
import MiniDrawer from '../../../components/MiniDrawer';
import csApi from '../../../services/apis/csApi';
import { formatDate } from '../../../utils';
import {
  Container,
  Title,
  Section,
  SectionTitle,
  SectionContent,
  CustomerListsContainer,
  CustomerListContent,
  SimpleTable,
} from '../Dashboard.styles';

export default function ContractDashboard() {
  const [content, setContent] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);

      const dashboardData = (await csApi.getContractDashboard())?.data;
      setContent(dashboardData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <React.Fragment>
      <MiniDrawer
        currentExpaded='cs'
        main={
          <Container>
            <Loading open={loading} />

            <Section>
              <SectionTitle>
                Métricas de Renovação (Primeira Parcela) e Churn (Tag Churn)
              </SectionTitle>
              <SectionContent>
                <ChurnMetrics metrics={{ content: content?.metrics }} />
              </SectionContent>
            </Section>

            <Title>Contratos dos clientes</Title>

            <Section>
              <SectionTitle></SectionTitle>

              <SectionContent>
                <CustomerListsContainer>
                  <CustomerListContent>
                    <h3>
                      Em 45 dias{' '}
                      <span> (Última parcela a vencer em 45 dias)</span>
                    </h3>

                    <h2>Total: {content?.expires?.expiresIn45days?.length}</h2>

                    <SimpleTable.Container>
                      <SimpleTable.Table>
                        <SimpleTable.TableHead>
                          <SimpleTable.TableRow>
                            <SimpleTable.TableHeadCell
                              style={{
                                width: '60%',
                              }}
                            >
                              Clientes
                            </SimpleTable.TableHeadCell>
                            <SimpleTable.TableHeadCell
                              style={{
                                width: '25%',
                              }}
                            >
                              Vencimento da ultima parcela
                            </SimpleTable.TableHeadCell>
                            <SimpleTable.TableHeadCell
                              style={{
                                width: '15%',
                              }}
                            >
                              Email enviado?
                            </SimpleTable.TableHeadCell>
                          </SimpleTable.TableRow>
                        </SimpleTable.TableHead>

                        <SimpleTable.TableBody>
                          {content &&
                          content.expires?.expiresIn45days?.length > 0 ? (
                            content.expires.expiresIn45days.map(customer => (
                              <SimpleTable.TableRow key={customer.id}>
                                <SimpleTable.TableData>
                                  <Link to={`/customers/${customer.id}`}>
                                    {customer.name}
                                  </Link>
                                </SimpleTable.TableData>
                                <SimpleTable.TableData
                                  style={{
                                    ...(moment().isAfter(
                                      moment(customer.contractExpiration).add(
                                        1,
                                        'month'
                                      ),
                                      'days'
                                    )
                                      ? {
                                          color: 'red',
                                          fontWeight: 'bolder',
                                        }
                                      : {}),
                                  }}
                                >
                                  <Link to={`/customers/${customer.id}`}>
                                    {formatDate(customer.contractExpiration)}
                                  </Link>
                                </SimpleTable.TableData>
                                <SimpleTable.TableData>
                                  <Link to={`/customers/${customer.id}`}>
                                    {customer.contractRenewalEmailSent ? (
                                      <span style={{ color: '#1A9E1A' }}>
                                        Sim
                                      </span>
                                    ) : (
                                      <strong>Não</strong>
                                    )}
                                  </Link>
                                </SimpleTable.TableData>
                              </SimpleTable.TableRow>
                            ))
                          ) : (
                            <SimpleTable.TableRow>
                              <SimpleTable.TableData>
                                <span>Nenhum cliente 😃</span>
                              </SimpleTable.TableData>
                            </SimpleTable.TableRow>
                          )}
                        </SimpleTable.TableBody>
                      </SimpleTable.Table>
                    </SimpleTable.Container>
                  </CustomerListContent>

                  <CustomerListContent>
                    <h3>
                      Entre 46 e 90 dias
                      <span> (Última parcela a vencer entre 46 e 90 dias)</span>
                    </h3>

                    <h2>Total: {content?.expires?.expiresIn90days?.length}</h2>
                    <SimpleTable.Container>
                      <SimpleTable.Table>
                        <SimpleTable.TableHead>
                          <SimpleTable.TableRow>
                            <SimpleTable.TableHeadCell
                              style={{
                                width: '70%',
                              }}
                            >
                              Clientes
                            </SimpleTable.TableHeadCell>
                            <SimpleTable.TableHeadCell
                              style={{
                                width: '30%',
                              }}
                            >
                              Vencimento da ultima parcela
                            </SimpleTable.TableHeadCell>
                          </SimpleTable.TableRow>
                        </SimpleTable.TableHead>

                        <SimpleTable.TableBody>
                          {content &&
                          content.expires?.expiresIn90days?.length > 0 ? (
                            content.expires.expiresIn90days.map(customer => (
                              <SimpleTable.TableRow key={customer.id}>
                                <SimpleTable.TableData>
                                  <Link to={`/customers/${customer.id}`}>
                                    {customer.name}
                                  </Link>
                                </SimpleTable.TableData>
                                <SimpleTable.TableData>
                                  <Link to={`/customers/${customer.id}`}>
                                    {formatDate(customer.contractExpiration)}
                                  </Link>
                                </SimpleTable.TableData>
                              </SimpleTable.TableRow>
                            ))
                          ) : (
                            <SimpleTable.TableRow>
                              <SimpleTable.TableData>
                                <span>Nenhum cliente 😃</span>
                              </SimpleTable.TableData>
                            </SimpleTable.TableRow>
                          )}
                        </SimpleTable.TableBody>
                      </SimpleTable.Table>
                    </SimpleTable.Container>
                  </CustomerListContent>

                  <CustomerListContent>
                    <h3>Contrato suspenso</h3>

                    <h2>Total: {content?.suspended?.length}</h2>

                    <SimpleTable.Container>
                      <SimpleTable.Table>
                        <SimpleTable.TableHead>
                          <SimpleTable.TableRow>
                            <SimpleTable.TableHeadCell
                              style={{
                                width: '60%',
                              }}
                            >
                              Clientes
                            </SimpleTable.TableHeadCell>
                            <SimpleTable.TableHeadCell
                              style={{
                                width: '20%',
                              }}
                            >
                              Suspenso em
                            </SimpleTable.TableHeadCell>
                            <SimpleTable.TableHeadCell
                              style={{
                                width: '20%',
                              }}
                            >
                              Suspenso até
                            </SimpleTable.TableHeadCell>
                          </SimpleTable.TableRow>
                        </SimpleTable.TableHead>

                        <SimpleTable.TableBody>
                          {content && content.suspended?.length > 0 ? (
                            content.suspended?.map(customer => (
                              <SimpleTable.TableRow key={customer.id}>
                                <SimpleTable.TableData>
                                  <Link to={`/customers/${customer.id}`}>
                                    {customer.name}
                                  </Link>
                                </SimpleTable.TableData>
                                <SimpleTable.TableData>
                                  <Link to={`/customers/${customer.id}`}>
                                    {formatDate(
                                      customer.SuspendedContract?.suspendedAt
                                    )}
                                  </Link>
                                </SimpleTable.TableData>
                                <SimpleTable.TableData>
                                  <Link to={`/customers/${customer.id}`}>
                                    {formatDate(
                                      customer.SuspendedContract?.suspendedUntil
                                    )}
                                  </Link>
                                </SimpleTable.TableData>
                              </SimpleTable.TableRow>
                            ))
                          ) : (
                            <SimpleTable.TableRow>
                              <SimpleTable.TableData>
                                <span>Nenhum cliente 😃</span>
                              </SimpleTable.TableData>
                            </SimpleTable.TableRow>
                          )}
                        </SimpleTable.TableBody>
                      </SimpleTable.Table>
                    </SimpleTable.Container>
                  </CustomerListContent>

                  <CustomerListContent>
                    <h3>
                      Data indefinida
                      <span> (Sem data de vencimento da última parcela)</span>
                    </h3>

                    <h2>Total: {content?.expires?.expiresUndefined?.length}</h2>

                    <SimpleTable.Container>
                      <SimpleTable.Table>
                        <SimpleTable.TableHead>
                          <SimpleTable.TableRow>
                            <SimpleTable.TableHeadCell
                              style={{
                                width: '100%',
                              }}
                            >
                              Clientes
                            </SimpleTable.TableHeadCell>
                          </SimpleTable.TableRow>
                        </SimpleTable.TableHead>

                        <SimpleTable.TableBody>
                          {content &&
                          content.expires?.expiresUndefined?.length > 0 ? (
                            content.expires.expiresUndefined.map(customer => (
                              <SimpleTable.TableRow key={customer.id}>
                                <SimpleTable.TableData>
                                  <Link to={`/customers/${customer.id}`}>
                                    {customer.name}
                                  </Link>
                                </SimpleTable.TableData>
                              </SimpleTable.TableRow>
                            ))
                          ) : (
                            <SimpleTable.TableRow>
                              <SimpleTable.TableData>
                                <span>Nenhum cliente 😃</span>
                              </SimpleTable.TableData>
                            </SimpleTable.TableRow>
                          )}
                        </SimpleTable.TableBody>
                      </SimpleTable.Table>
                    </SimpleTable.Container>
                  </CustomerListContent>
                </CustomerListsContainer>
              </SectionContent>
            </Section>
          </Container>
        }
      />
    </React.Fragment>
  );
}
