import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from '@unform/web';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import CustomInput from '../components/input/CustomInput';
import authApi from '../services/apis/authApi';
import history from '../history';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(4),
  },
  submit: {
    fontSize: '20px',
    color: '#FFF',
    background: '#1ab394',
    borderRadius: '31px',
    border: 'none',
    padding: '10px 26px',
    marginLeft: '10px',
    boxShadow: '0 0 6px rgba(0, 0, 0, 0.09)',
  },
  createAccount: {
    backgroundColor: '#D4D4D4',
    borderRadius: '31px',
    border: 'none',
    padding: '10px 26px',
    color: '#858585',
    fontSize: '20px',
  },
  title: {
    fontSize: '36px',
  },
  main: {
    background: '#F5F6FA',
    height: '95vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '10px',
    },
  },
  buttonsContainer: {
    justifyContent: 'flex-end',
    margin: theme.spacing(4, 0, 2),
  },
  forgotPassword: {
    textDecoration: 'none',
    color: '#000',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  forgotPasswordContainer: {
    justifyContent: 'center',
    marginTop: '25px',
    fontSize: '16px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '5vh',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
    background: '#F5F6FA',
  },
  footerText: {
    textDecoration: 'none',
    color: '#000',
    '&:hover': {
      textDecoration: 'underline',
    },
    padding: '20px',
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const [submitLoading, setSubmitLoading] = React.useState(false);

  async function handleSubmit(data) {
    try {
      setSubmitLoading(true);

      await authApi.login(data);

      history.push('/');
    } catch (error) {
      setSubmitLoading(false);
    }
  }

  return (
    <React.Fragment>
      <div className={classes.main}>
        <Container component='main' maxWidth='xs'>
          <CssBaseline />
          <div className={classes.paper}>
            <h1 className={classes.title}>Login</h1>

            <Form className={classes.form} onSubmit={handleSubmit}>
              <Grid container className={classes.formContainer}>
                <Grid item xs={12}>
                  <CustomInput
                    name='email'
                    label='E-mail'
                    autoComplete='email'
                    autoFocus
                  />
                </Grid>

                <Grid item xs={12}>
                  <CustomInput
                    type='password'
                    name='password'
                    label='Senha'
                    autoComplete='current-password'
                    autoFocus
                  />
                </Grid>

                <Grid container className={classes.buttonsContainer}>
                  <Grid item>
                    <button type='submit' className={classes.submit}>
                      {!submitLoading && 'Login'}
                      {submitLoading && <CircularProgress color='inherit' />}
                    </button>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  className={classes.forgotPasswordContainer}
                  xs={12}
                >
                  <Link to='/resetPassword' className={classes.forgotPassword}>
                    Esqueceu a senha? Clique aqui
                  </Link>
                </Grid>
              </Grid>
            </Form>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}
