import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 30px 50px;
  border-radius: 15px;
  width: 500px;
  position: relative;

  .closeModal {
    align-self: flex-end;
    position: absolute;
    cursor: pointer;
    right: 15px;
    top: 15px;
  }

  h1 {
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    .inputContainer {
      margin-top: 10px;

      textarea {
        height: 200px;
        max-height: 300px;
      }
    }

    .submitButton {
      font-size: 16px;
      color: #fff;
      border-radius: 5px;
      border: none;
      padding: 5px 20px;
      background-color: #1ab394;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.09);
      width: fit-content;
      align-self: flex-end;
      margin-top: 10px;
    }
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  :visited,
  :hover,
  :active {
    color: inherit;
  }
`;
