import React, { useState } from 'react';
import MiniDrawer from '../../components/MiniDrawer';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import history from '../../history';
import Table from '../../components/Table';
import { formatDate } from '../../utils';
import tagsApi from '../../services/apis/tagsApi';
import { useEffect } from 'react';
import { Constants } from '../../utils/Constants';

const useStyles = makeStyles(theme => ({
  content: {
    height: '100vh',
    background: '#F5F6FA',
  },
  title: {
    fontSize: '35px',
  },
  table: {
    borderCollapse: 'collapse',
    margin: '25px 0',
    fontSize: '17px',
    width: '100%',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
  },
  tableHead: {
    backgroundColor: '#009879',
    color: '#ffffff',
    textAlign: 'left',
  },
  tableHeadTitleFirst: {
    borderRadius: '10px 0px 0px 0px',
    padding: '12px 15px',
  },
  tableHeadTitleLast: {
    borderRadius: '0px 10px 0px 0px',
    padding: '12px 15px',
  },
  tableHeadTitle: {
    padding: '12px 15px',
  },
  tableItem: {
    borderBottom: '1px solid #dddddd',
    '&:hover': {
      background: '#e0e0e0',
    },
  },
  tableItemColumn: {
    padding: '12px 15px',
    fontSize: '15px',
  },
  tableItemColumnStatus: {
    padding: '12px 15px',
    fontSize: '15px',
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'none',
  },
  action: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: '#000',
  },
  exportButton: {
    padding: '10px 15px',
    fontSize: '17px',
    background: '#1ab394',
    border: 'none',
    color: '#fff',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  selectSiz4Items: {},
}));

const headCells = [
  { id: 'name', numeric: false, label: 'Nome|Razão social' },
  { id: 'healthScore', numeric: true, label: 'Health Score' },
  {
    id: 'legalRepresentativeName',
    numeric: false,
    label: 'Representante Legal',
  },
  { id: 'typeCompany', numeric: false, label: 'Tipo de Sociedade' },
  { id: 'customerType', numeric: false, label: 'Tipo de Cliente' },
  { id: 'businessName', numeric: false, label: 'Negócio' },
  { id: 'createdAt', numeric: false, label: 'Criado em' },
];

export const optionsAccess = [
  {
    name: 'Gerador de documentos',
    value: 'Gerador de documentos',
  },
  {
    name: 'Mapeamento de dados',
    value: 'Mapeamento de dados',
  },
  {
    name: 'Registrar marcas',
    value: 'Registrar marcas',
  }
]

export default function ListCustomer() {
  const classes = useStyles();
  const [searchOptions, setSearchOptions] = useState([
    {
      value: 'name',
      name: 'Nome',
    },
    {
      value: 'cnpj',
      name: 'CNPJ',
    },
    {
      value: 'cpf',
      name: 'CPF',
    },
    {
      value: 'customerType',
      name: 'Tipo do Cliente',
    },
    {
      value: 'socialReason',
      name: 'Razão Social',
    },
    {
      value: 'addressCity',
      name: 'Cidade',
    },
    {
      value: 'addressState',
      name: 'Estado (mg, go, etc...)',
    },
    {
      value: 'businessName',
      name: 'Negócio',
    },
  ]);

  function handleClickItem(id) {
    history.push(`/customers/${id}/`);
  }

  const _handleLoadTagsSearchOption = async () => {
    try {
      const response = await tagsApi.getAll();
      const tags = response.data.tags;

      setSearchOptions(searchOptions => [
        ...searchOptions,
        {
          value: 'tagId',
          name: 'Tags',
          customInput: tags.map(tag => ({
            name: tag.name,
            value: tag.id,
          })),
        },
      ]);
    } catch (error) {
      console.error(error);
    }
  };

  async function handleRows(data) {
    return {
      rows: data.customers.map(customer => ({
        cpf: customer.personType === 'PF' ? customer.cpf : customer.cnpj,
        name:
          customer.personType === 'PF' ? customer.name : customer.socialReason,
        ...customer,
        businessName: customer.businessName,
        createdAt: formatDate(customer.createdAt),
      })),
      total: data.total,
    };
  }

  useEffect(() => {
    _handleLoadTagsSearchOption();
  }, []);

  return (
    <React.Fragment>
      <MiniDrawer
        currentExpaded='customers'
        main={
          <Grid container className={classes.main}>
            <Grid item className={classes.content} xs={12}>
              <Grid item xs={12}>
                <Table
                  title='Clientes'
                  headCells={headCells}
                  handleRows={handleRows}
                  handleClickItem={handleClickItem}
                  route='customers-with-business'
                  rolePrefix='CUSTOMER'
                  downloadRoute='all'
                  downloadColumns={Constants.CUSTOMER_COLUMNS}
                  searchOptions={searchOptions}
                />
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </React.Fragment>
  );
}
