import React, { useRef, useState } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { Styles } from './RegisterNewCustomerSaleModal.styles';
import * as Yup from 'yup';
import Calendar from '../../../../../../components/input/calendar/Calendar';
import CustomSelectWithSearch from '../../../../../../components/input/customSelectorWithSearch/CustomSelectWithSearch';
import Modal from '../../../../../../components/modal/Modal';
import { Constants } from '../../../../../../utils/Constants';
import CustomTextArea from '../../../../../../components/input/CustomTextArea';
import CustomerSaleService from '../../../../../../services/customer/customerSale/CustomerSaleService';
import tagsApi from '../../../../../../services/apis/tagsApi';

export default function RegisterNewCustomerSaleModal({
  open,
  customer,
  handleClose,
}) {
  const deleteUserFormRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const _handleRegisterSaleFormSubmit = async formData => {
    setLoading(true);

    try {
      const saleData = {
        ...formData,
        customerId: customer.id,
      };

      const tagsToIgnore = ['Churn NDM', 'Churn Cliente', 'Demanda Específica'];
      const tagsAll = (await tagsApi.getAll())?.data?.tags;
      const isChurn = customer.tags
        .some(tag => tagsToIgnore.includes(tag.name));
    
      const tagSpecific = tagsAll
        ?.find(tag => tag.name === 'Demanda Específica');
      const tagsChurnNDM = tagsAll
        ?.find(tag => tag.name === 'Churn NDM');
      const tagsChurnCustomer = tagsAll
        ?.find(tag => tag.name === 'Churn Cliente');
      
        const verifyTagsChurnNDMCustomer = customer.tags
        .some(tag => tag.id === tagsChurnNDM.id);
      const verifyTagsChurnCustomer = customer.tags
        .some(tag => tag.id === tagsChurnCustomer.id);

      if (isChurn && saleData.contractType === 'SPECIFIC_DEMAND' ) {
        await tagsApi.addTag(customer.id, tagSpecific?.id);
        if (verifyTagsChurnNDMCustomer) {
          await tagsApi.delete(customer.id, tagsChurnNDM.id);
        }
        if (verifyTagsChurnCustomer) {
          await tagsApi.delete(customer.id, tagsChurnCustomer.id);
        }
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
            
      await CustomerSaleService.validateSaleData(saleData);
      await CustomerSaleService.registerCustomerSale(saleData);

      toast.success('Venda registrada com sucesso!');
      handleClose(true);
      } catch (error) {
      console.error(error);

      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};

        error.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });

        deleteUserFormRef.current.setErrors(validationErrors);
      }
    }

    setLoading(false);
  };

  return (
    <Modal visible={open}>
      <Styles.Container>
        <Form onSubmit={_handleRegisterSaleFormSubmit} ref={deleteUserFormRef}>
          <h1>Registrar nova venda</h1>

          <p>
            Cliente: <strong>{customer.name}</strong>
          </p>

          <CustomSelectWithSearch
            label='Tipo de contrato'
            name='contractType'
            options={Constants.CUSTOMER_CONTRACT_TYPES.options}
          />

          <div>
            <Calendar
              label='Data da venda'
              name='saleDate'
              placeholder='Selecione a data da venda'
            />
          </div>

          <CustomTextArea name='saleDescription' label='Descrição da venda' />

          <div className='button-container'>
            <button onClick={() => handleClose(false)}>Cancelar</button>

            <button type='submit' disabled={loading}>
              Registrar
            </button>
          </div>
        </Form>
      </Styles.Container>
    </Modal>
  );
}
