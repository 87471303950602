import React, { useEffect, useRef, useState } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from '@unform/web';
import InputMask from 'react-input-mask';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import CustomInput from '../../components/input/CustomInput';
import CustomRadio from '../../components/input/CustomRadio';
import CustomSelect from '../../components/input/CustomSelect';
import MiniDrawer from '../../components/MiniDrawer';
import history from '../../history';
import apiOld from '../../services/Api';
import CEPService from '../../services/CEPService';
import { formatDate, parseDateFromYupWithMask } from '../../utils';
import { Constants } from '../../utils/Constants';
import CheckboxInput from '../../components/input/CheckboxInput';
import { useSelector } from 'react-redux';
import { validateBr } from 'js-brasil';
import CustomSelectWithSearch from '../../components/input/customSelectorWithSearch/CustomSelectWithSearch';
import { optionsAccess } from './ListCustomer';
import CustomTextArea from '../../components/input/CustomTextArea';

const useStyles = makeStyles(() => ({
  main: {
    background: '#FFF',
  },
  mainTitle: {
    background: '#FFF',
    borderTop: 'solid 2px #e7eaec',
    borderBottom: 'solid 1px #e7eaec',
    padding: '15px',
  },
  line: {
    backgorund: 'reds',
  },
  title: {
    fontSize: '15px',
  },
  mainForm: {
    padding: '10px',
  },
  form: {
    width: '100%',
  },
  input: {
    padding: '10px',
    alignItems: 'center',
  },
  buttonContainer: {
    justifyContent: 'flex-end',
    padding: '10px',
  },
  submit: {
    padding: '10px 15px',
    fontSize: '17px',
    background: '#1ab394',
    border: 'none',
    color: '#fff',
    borderRadius: '5px',
  },
  subTitle: {
    background: '#FFF',
    borderBottom: 'solid 1px #e7eaec',
  },
}));

export default function EditCustomer() {
  const { id } = useParams();
  const classes = useStyles();
  const [submitLoading, setSubmitLoading] = useState(false);
  const formRef = useRef(null);
  const backofficeUsers = useSelector(state => state.backofficeUsers.all);
  const [personType, setPersonType] = useState('PF');
  const [content, setContent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isForeign, setIsForeign] = useState(false);
  const [customerAccess, setCustomerAccess] = useState(content.customerAccess || '');


  useEffect(() => {
    setLoading(true);

    apiOld
      .get(`/customers/${id}`, {
        params: {
          detailed: true,
        },
      })
      .then(response => {
        setContent(response.data);
        setPersonType(response.data.personType);
        if (response.data.isInternacional) setIsForeign(true);
      });

    setLoading(false);
  }, [id]);

  async function handleSubmit(data) {
    setSubmitLoading(true);
    try {
      formRef.current.setErrors({});

      const communSchema = {
        contractExpiration: Yup.date()
          .label('Última parcela do contrato')
          .required()
          .nullable(true)
          .transform(parseDateFromYupWithMask)
          .typeError('Data inválida'),
        nextActiveContactDate: Yup.date()
          .label('Data do próximo contato ativo')
          .nullable(true)
          .transform(parseDateFromYupWithMask)
          .typeError('Data inválida'),
        backofficeUserId: Yup.string()
          .label('Resposável pelo contato ativo')
          .nullable(true)
          .typeError('Responsável pelo contato ativo inválido'),
        saleDate: Yup.date()
          .label('Data da venda')
          .required()
          .nullable(true)
          .transform(parseDateFromYupWithMask)
          .typeError('Data inválida'),
        contractRenewalEmailSent: Yup.boolean().label(
          'Email de renovação de contrato enviado?'
        ),
        customerType: Yup.string()
          .oneOf(Constants.CUSTOMER_TYPES.LIST)
          .required('Tipo de Cliente é obrigatório'),
        site: Yup.string(),
        customerAccess: Yup.string().nullable(true),
      };
      data.customerAccess = customerAccess;

      const schemaPF = Yup.object().shape({
        nome: Yup.string().required(),
        personType: Yup.string().required(),
        customerType: Yup.string()
          .oneOf(Constants.CUSTOMER_TYPES.LIST)
          .required('Tipo de Cliente é obrigatório'),
        rg: Yup.string(),
        cpf: Yup.string().required(),
        sexo: Yup.string().required(),
        nacionalidade: Yup.string(), //.required(),
        'estado civil': Yup.string(),
        profissão: Yup.string(), //.required(),
        'data de nascimento': Yup.string(), //.required(),
        cep: Yup.string()
          .required()
          .when('$isForeign', (isForeign, s) => {
            isForeign &&
              s.test('cep', 'CEP inválido', value => validateBr.cep(value));
          }),
        logradouro: Yup.string().required(),
        número: Yup.string().required(),
        bairro: Yup.string().required(),
        cidade: Yup.string().required(),
        estado: Yup.string().required(),
        email: Yup.string().required(),
        telefone: Yup.string(),
        ...communSchema,
      });

      const schemaPJ = Yup.object().shape({
        nome: Yup.string().required(),
        personType: Yup.string().required(),
        'razão social': Yup.string().required(),
        'tipo de sociedade': Yup.string().required(),
        cnpj: Yup.string().required(),
        cep: Yup.string().required(),
        logradouro: Yup.string().required(),
        número: Yup.string().required(),
        bairro: Yup.string().required(),
        cidade: Yup.string().required(),
        estado: Yup.string().required(),
        'nome do representante legal': Yup.string().required(),
        'cpf do representante legal': Yup.string().required(),
        email: Yup.string().required(),
        telefone: Yup.string(),
        ...communSchema,
      });

      let validated;
      if (personType === 'PJ') {
        validated = await schemaPJ.validate(data, {
          abortEarly: false,
          context: {
            isForeign,
          },
        });
      } else {
        validated = await schemaPF.validate(data, {
          abortEarly: false,
          context: {
            isForeign,
          },
        });
      }

      const request = {
        personType: data.personType,
        customerType: data.customerType,
        email: data.email,
        phone: data.telefone,
        name: data.nome,
        contractRenewalEmailSent: data.contractRenewalEmailSent,
        backofficeUserId: data.backofficeUserId,
        saleDate: validated.saleDate,
        contractExpiration: validated.contractExpiration,
        rg: data.rg,
        cnh: data.cnh,
        cpf: data.cpf,
        sex: data.sex,
        nationality: data.nacionalidade,
        customerAccess: data.customerAccess,
        civilState: data['estado civil'],
        profession: data['profissão'],
        birthDate: data['data de nascimento'],
        socialReason: data['razão social'],
        cnpj: data.cnpj,
        typeCompany: data['tipo de sociedade'],
        cep: data.cep,
        isInternacional: data.estrangeiro,
        address: data.logradouro,
        addressComplement: data.complemento,
        addressNumber: data['número'],
        addressDistrict: data.bairro,
        addressCity: data.cidade,
        addressState: data.estado,
        legalRepresentativeName: data['nome do representante legal'],
        legalRepresentativeCpf: data['cpf do representante legal'],
        site: data.site,
        observations: data.observations,
      };

      if (data.nextActiveContactDate) {
        request.nextActiveContactDate = validated.nextActiveContactDate;
      }

      await apiOld.put(`/customers/${id}`, request);

      toast.success('Dados alterados com sucesso!');

      history.push(`/customers/${id}`);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
          toast.error(error.message);
        });
        formRef.current.setErrors(validationErrors);
      }
    }
    setSubmitLoading(false);
  }

  const handlePersonTypeChange = () => {
    const personType = formRef.current.getFieldValue('personType');
    setPersonType(personType);
  };

  const handleCEPChange = () => {
    const cep = formRef.current.getFieldValue('cep').replace(/\s/g, '');
    if (cep.length === 9) {
      findCEP(cep);
    }
  };

  const findCEP = async cep => {
    const response = await CEPService.get(`/${cep}/json`);
    if (!response.data.logradouro) {
      return toast.error('Cep inválido!', {
        toastId: 'error1',
      });
    }
    formRef.current.setFieldValue('logradouro', response.data.logradouro);
    formRef.current.setFieldValue('bairro', response.data.bairro);
    formRef.current.setFieldValue('complemento', response.data.complemento);
    formRef.current.setFieldValue('cidade', response.data.localidade);
    formRef.current.setFieldValue('estado', response.data.uf);
  };

  const handleForeign = ({ target }) => setIsForeign(target.checked);

  const handleCheckboxChange = (text, event) => {
    if (event.target.checked) {
      setCustomerAccess((prevCustomerAccess) => prevCustomerAccess + text);
    } else {
      setCustomerAccess((prevCustomerAccess) => prevCustomerAccess.replace(text, ''));
    }
  };

  const handleDoc = (event) => handleCheckboxChange('Gerador de documentos,', event);
  const handleMapping = (event) => handleCheckboxChange('Mapeamento de dados,', event);
  const handleMarcas = (event) => handleCheckboxChange('Registrar marcas,', event);


  return (
    <React.Fragment>
      <MiniDrawer
        currentExpaded='customers'
        main={
          <Grid container className={classes.main}>
            <Backdrop className={classes.backdrop} open={loading}>
              <CircularProgress />
            </Backdrop>
            {content && backofficeUsers && (
              <>
                <Grid container className={classes.mainTitle}>
                  <h2 className={classes.title}>Editar Cliente</h2>
                </Grid>
                <Grid container className={classes.mainForm}>
                  <Form
                    ref={formRef}
                    onSubmit={handleSubmit}
                    className={classes.form}
                  >
                    <Grid container>
                      <Grid item className={classes.input} xs={12}>
                        <h4>Tipo</h4>

                        <CustomRadio
                          name='personType'
                          defaultValue={content.personType}
                          onChange={handlePersonTypeChange}
                          options={Constants.PERSON_TYPES}
                        />
                      </Grid>
                      <Grid item className={classes.input} xs={12}>
                        <h2 className={classes.subTitle}>
                          Informações Básicas
                        </h2>
                      </Grid>
                      <Grid item className={classes.input} xs={12}>
                        <Grid
                          container
                          className={classes.input}
                          alignItems='center'
                        >
                          <CheckboxInput
                            name='contractRenewalEmailSent'
                            defaultValue={content.contractRenewalEmailSent}
                            label='Email de renovação de contrato enviado?'
                          />
                        </Grid>
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <CustomInput
                          name='nome'
                          label='Nome*'
                          defaultValue={content.name}
                        />
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <InputMask
                          mask='99/99/9999'
                          maskChar=' '
                          defaultValue={formatDate(content.contractExpiration)}
                        >
                          {() => (
                            <CustomInput
                              name='contractExpiration'
                              label='Data de vencimento da última parcela*'
                              defaultValue={formatDate(
                                content.contractExpiration
                              )}
                            />
                          )}
                        </InputMask>
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <InputMask
                          mask='99/99/9999'
                          maskChar=' '
                          defaultValue={
                            content.ActiveContact?.nextActiveContactDate
                              ? formatDate(
                                content.ActiveContact?.nextActiveContactDate
                              )
                              : ''
                          }
                        >
                          {() => (
                            <CustomInput
                              name='nextActiveContactDate'
                              label='Data do próximo contato'
                              defaultValue={
                                content.ActiveContact?.nextActiveContactDate
                                  ? formatDate(
                                    content.ActiveContact
                                      ?.nextActiveContactDate
                                  )
                                  : ''
                              }
                            />
                          )}
                        </InputMask>
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <CustomSelectWithSearch
                          placeholder='Nome do advogado'
                          name='backofficeUserId'
                          label='Responsável pelo contato'
                          defaultValue={content.ActiveContact?.backofficeUserId}
                          options={backofficeUsers?.map(users => ({
                            name: users.name,
                            value: users.id,
                          }))}
                        />
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <InputMask
                          mask='99/99/9999'
                          maskChar=' '
                          defaultValue={formatDate(content.saleDate)}
                        >
                          {() => (
                            <CustomInput
                              name='saleDate'
                              label='Data da venda*'
                              defaultValue={formatDate(content.saleDate)}
                            />
                          )}
                        </InputMask>
                      </Grid>

                      <Grid item className={classes.input} xs={6}>
                        <CustomSelect
                          name='customerType'
                          label='Tipo de Cliente*'
                          defaultValue={content.customerType}
                          options={Constants.CUSTOMER_TYPES.OPTIONS}
                        />
                      </Grid>

                      <Grid item className={classes.input} xs={6}>
                        <p className={classes.input}>Acesso a Ferramentas</p>
                        <CheckboxInput
                          name='customerAccess'
                          label='Gerador de documento'
                          onChange={handleDoc}
                          defaultValue={content?.customerAccess?.includes('Gerador de documentos')}
                        />
                        <CheckboxInput
                          name='customerAccess'
                          label='Mapeamento de dados'
                          onChange={handleMapping}
                          defaultValue={content?.customerAccess?.includes('Mapeamento de dados')}
                        />
                        <CheckboxInput
                          name='customerAccess'
                          label='Registrar marcas'
                          onChange={handleMarcas}
                          defaultValue={content?.customerAccess?.includes('Registrar marcas')}
                        />
                      </Grid>

                      {personType === 'PF' && (
                        <>
                          <Grid item className={classes.input} xs={6}>
                            <CustomInput
                              name='rg'
                              label='RG'
                              defaultValue={content.rg}
                            />
                          </Grid>
                          <Grid item className={classes.input} xs={6}>
                            <CustomInput
                              name='cnh'
                              label='CNH'
                              defaultValue={content.cnh}
                            />
                          </Grid>
                          <Grid item className={classes.input} xs={6}>
                            <InputMask
                              mask='999.999.999-99'
                              maskChar=' '
                              disabled={false}
                              defaultValue={content.cpf}
                            >
                              {() => (
                                <CustomInput
                                  name='cpf'
                                  label='CPF*'
                                  autoComplete='cpf'
                                />
                              )}
                            </InputMask>
                          </Grid>
                          <Grid item className={classes.input} xs={6}>
                            <CustomSelect
                              name='sexo'
                              label='Sexo*'
                              autoComplete='sex'
                              defaultValue={content.sex}
                              options={Constants.SEX_TYPES}
                            />
                          </Grid>
                          <Grid item className={classes.input} xs={6}>
                            <CustomInput
                              name='nacionalidade'
                              label='Nacionalidade*'
                              defaultValue={content.nationality}
                            />
                          </Grid>
                          <Grid item className={classes.input} xs={6}>
                            <CustomSelect
                              name='estado civil'
                              label='Estado Civil'
                              options={Constants.CIVIL_STATUS}
                            />
                          </Grid>
                          <Grid item className={classes.input} xs={6}>
                            <CustomInput
                              name='profissão'
                              label='Profissão*'
                              defaultValue={content.profession}
                            />
                          </Grid>
                          <Grid item className={classes.input} xs={6}>
                            <InputMask
                              mask='99/99/9999'
                              maskChar=' '
                              defaultValue={content.birthDate}
                            >
                              {() => (
                                <CustomInput
                                  name='data de nascimento'
                                  label='Nascimento*'
                                  autoComplete='bday'
                                />
                              )}
                            </InputMask>
                          </Grid>
                        </>
                      )}
                      {personType === 'PJ' && (
                        <>
                          <Grid item className={classes.input} xs={12}>
                            <CustomInput
                              name='razão social'
                              label='Razão Social*'
                              defaultValue={content.socialReason}
                            />
                          </Grid>
                          <Grid item className={classes.input} xs={6}>
                            <InputMask
                              mask='99.999.999/9999-99'
                              maskChar=' '
                              disabled={false}
                              defaultValue={content.cnpj}
                            >
                              {() => <CustomInput name='cnpj' label='CNPJ*' />}
                            </InputMask>
                          </Grid>
                          <Grid item className={classes.input} xs={6}>
                            <CustomSelect
                              name='tipo de sociedade'
                              label='Tipo de Sociedade*'
                              options={Constants.SOCIETY_TYPES}
                              defaultValue={content.typeCompany}
                            />
                          </Grid>
                        </>
                      )}
                      <Grid item className={classes.input} xs={12}>
                        <h2 className={classes.subTitle}>Endereço</h2>
                      </Grid>

                      <Grid item className={classes.input} xs={12}>
                        <CheckboxInput
                          name='estrangeiro'
                          label='Estrangeiro'
                          onChange={handleForeign}
                          checked={isForeign}
                        />
                      </Grid>

                      <Grid item className={classes.input} xs={3}>
                        {isForeign ? (
                          <InputMask>
                            {() => (
                              <CustomInput name='cep' label={'ZIPCODE*'} />
                            )}
                          </InputMask>
                        ) : (
                          <InputMask
                            mask='99999-999'
                            maskChar=' '
                            onChange={handleCEPChange}
                            disabled={false}
                            defaultValue={content.cep}
                          >
                            {() => <CustomInput name='cep' label={'CEP*'} />}
                          </InputMask>
                        )}
                      </Grid>

                      <Grid item className={classes.input} xs={9}>
                        <CustomInput
                          name='logradouro'
                          label='Logradouro*'
                          defaultValue={content.address}
                        />
                      </Grid>
                      <Grid item className={classes.input} xs={9}>
                        <CustomInput
                          name='complemento'
                          label='Complemento'
                          defaultValue={content.addressComplement}
                        />
                      </Grid>
                      <Grid item className={classes.input} xs={3}>
                        <CustomInput
                          name='número'
                          label='Número*'
                          defaultValue={content.addressNumber}
                        />
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <CustomInput
                          name='bairro'
                          label='Bairro*'
                          defaultValue={content.addressDistrict}
                        />
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <CustomInput
                          name='cidade'
                          label='Cidade*'
                          defaultValue={content.addressCity}
                        />
                      </Grid>
                      <Grid item className={classes.input} xs={12}>
                        <CustomInput
                          name='estado'
                          label='Estado*'
                          defaultValue={content.addressState}
                        />
                      </Grid>
                      {personType === 'PJ' && (
                        <>
                          <Grid item className={classes.input} xs={12}>
                            <h2 className={classes.subTitle}>
                              Representante Legal
                            </h2>
                          </Grid>
                          <Grid item className={classes.input} xs={6}>
                            <CustomInput
                              name='nome do representante legal'
                              label='Nome do Representante Legal*'
                              defaultValue={content.legalRepresentativeName}
                            />
                          </Grid>
                          <Grid item className={classes.input} xs={6}>
                            <InputMask
                              mask='999.999.999-99'
                              maskChar=' '
                              disabled={false}
                              defaultValue={content.legalRepresentativeCpf}
                            >
                              {() => (
                                <CustomInput
                                  name='cpf do representante legal'
                                  label=' CPF do Representante Legal*'
                                  defaultValue={content.legalRepresentativeCpf}
                                />
                              )}
                            </InputMask>
                          </Grid>
                        </>
                      )}
                      <Grid item className={classes.input} xs={12}>
                        <h2 className={classes.subTitle}>
                          Informações de Contato
                        </h2>
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <CustomInput
                          name='email'
                          label='E-mail*'
                          defaultValue={content.email}
                        />
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <InputMask
                          mask='(99)99999-9999'
                          maskChar=' '
                          disabled={false}
                          defaultValue={content.phone}
                        >
                          {() => (
                            <CustomInput name='telefone' label='Telefone' />
                          )}
                        </InputMask>
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <CustomInput
                          name='site'
                          label='Site'
                          defaultValue={content.site}
                        />
                      </Grid>
                      <Grid item className={classes.input} xs={12}>
                        <h2 className={classes.subTitle}>
                          Informações adicionais
                        </h2>
                      </Grid>
                      <Grid item className={classes.input} xs={12}>
                        <CustomTextArea
                          maxLength={70000}
                          label='Observações'
                          name='observations'
                          defaultValue={content.observations}
                        />
                      </Grid>
                      <Grid
                        container
                        className={classes.buttonContainer}
                        xs={12}
                      >
                        <button className={classes.submit} type='submit'>
                          {!submitLoading && 'Salvar'}
                          {submitLoading && (
                            <CircularProgress color='inherit' />
                          )}
                        </button>
                      </Grid>
                    </Grid>
                  </Form>
                </Grid>
              </>
            )}
          </Grid>
        }
      />
    </React.Fragment>
  );
}
