import * as actionTypes from "../actionsTypes/demandActionTypes";

export const recieveDemands = (demands) => {
  return {
    type: actionTypes.RECIEVE_DEMANDS,
    demands,
  };
};

export const patchDemand = (demand) => {
  return {
    type: actionTypes.PATCH_DEMAND,
    demand,
  };
};

export const createDemand = (demand) => {
  return {
    type: actionTypes.CREATE_DEMAND,
    demand,
  };
};

export const deleteDemand = (demandId) => {
  return {
    type: actionTypes.DELETE_DEMAND,
    demandId: demandId,
  };
};
