import React from "react";
import MiniDrawer from "../MiniDrawer";
import { ClassStyle } from "../../pages/riskAnalysis/index";
import { GamePagButton, TextTag } from "./index.styles";
import history from "../../history";
import Loading from "../Loading";
import { propsGamePadButton } from "./index.styles";
import moment from "moment";

export default function SectionAIR({ dataTitle, data, params, ...rest }) {
  const { setValues, isList, loading, remove, setIdSelected } = rest;
  const classes = ClassStyle();

  const addQueryParamToUrl = (key, value) => {
    const queryParams = new URLSearchParams(window.location.search);
    const existingValue = queryParams.get(key.params);
    if (existingValue) {
      queryParams.set(key.params, `${existingValue},${value}`);
    } else {
      queryParams.set(key.params, value);
    }
    const newUrl = `${key.route}?${queryParams.toString()}`;
    history.push(newUrl);
  };

  const renderItems = () => {
    return data?.sort((a,b) => {
      const acc = moment(a.createdAt);
      const curr = moment(b.createdAt);
      return acc.isBefore(curr) ? -1 : 1;
    })?.map((item) => (
      <div style={{ margin: '0 3rem' }} key={item.id || item}>
        <GamePagButton
          {...propsGamePadButton}
          onClick={() => dataTitle.route ? addQueryParamToUrl(dataTitle, item.name) : null}
        >
          <div className={classes.buttonContent}>
            <TextTag>{dataTitle.title}</TextTag>
            <p className={classes.buttonText}>{item.name || item}</p>
          </div>
          {!isList && (
            <TextTag>
              <i
                onClick={(e) => {
                  e.stopPropagation();
                  setValues?.edit(true);
                  setIdSelected(item?.id);
                }}
                style={{ color: '#B1B11B' }}
                className="fa fa-pencil fa-lg"
              />
              <i
                onClick={(e) => {
                  e.stopPropagation();
                  const confirmed = window.confirm('Tem certeza que deseja remover esse Evento?');
                  if (confirmed) {
                    remove(item?.id);
                  }
                }}
                style={{ color: '#A93030', marginLeft: '10px' }}
                className="fa fa-trash fa-lg"
              />
            </TextTag>
          )}
        </GamePagButton>
      </div>
    ));
  };

  return (
    <React.Fragment>
      <MiniDrawer
        currentExpaded={dataTitle.params}
        main={
          <>
            <Loading open={loading} />
            <span>
              {`${params ? 'Você está em:' : ''} ${params?.sector ? '>' + params?.sector : ''} ${params?.subcategory ? '>' + params?.subcategory : ''}`}
            </span>
            <section className={classes.content}>
              <div>
                <h3 className={classes.title}>{dataTitle.subTitle}</h3>
                {!isList && (
                  <div className={classes.cardCenter}>
                    <GamePagButton onClick={() => setValues?.add(true)}>
                      <span className={classes.iconSum}>+</span>
                      <p className={classes.buttonText}>{dataTitle.buttonText}</p>
                    </GamePagButton>
                  </div>
                )}
              </div>
              <main className={classes.main}>
                <h5 className={classes.subTitle}>{dataTitle.title}({data.length})</h5>
                {renderItems()}
              </main>
            </section>
          </>
        }
      />
    </React.Fragment>
  );
}
