import styled from "styled-components";

export const DemandFeedbackAverageContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* width: 100%;
  height: 100%; */
  margin-top: 10px;

  span {
    margin-top: 5px;
    font-size: 2rem;
    margin-left: 50px;
  }
`;

export const DemandFeedbackLowAndHighContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const DemandFeedbackLowAndHighItem = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-right: 10px;
  `,
  Title: styled.h3`
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: bold;
  `,
  Table: styled.table`
    border-collapse: collapse;
    width: 100%;
  `,
  TableRow: styled.tr`
    background-color: #fff;

    :nth-child(even) {
      background-color: #dddddd;
    }
  `,
  TableHead: styled.thead``,
  TableBody: styled.tbody`
    tr {
      :hover {
        transform: scale(1.01);
      }
    }
  `,
  TableHeadCell: styled.th`
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  `,
  TableData: styled.td`
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    cursor: pointer;
  `,
};
