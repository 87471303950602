import { makeStyles } from "@material-ui/core";
import { useField } from "@unform/core";
import React, { useEffect, useRef } from "react";

const useStyles = makeStyles((theme) => ({
  error: {
    color: "red",
    opacity: "70%",
    marginLeft: "16px",
    textAlign: "justify",
    display: "block",
  },
  label: {
    paddingLeft: "3px",
  },

  main: {},
  container: {
    display: "flex",
  },
  item: {
    padding: "10px 20px 10px 20px",
  },
}));

const CustomCheckbox = ({
  name,
  options,
  customOnChange = null,
  checkAll = false,
  ...rest
}) => {
  const inputRefs = useRef([]);
  const checkAllRef = useRef();
  const { fieldName, registerField, defaultValue = [] } = useField(name);
  const classes = useStyles();

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs) => {
        return refs.filter((ref) => ref.checked).map((ref) => ref.value);
      },
      clearValue: (refs) => {
        refs.forEach((ref) => {
          ref.checked = false;
        });
      },
      setValue: (refs, values) => {
        refs.forEach((ref) => {
          if (values.includes(ref.id)) {
            ref.checked = true;
          }
        });
      },
    });
  }, [defaultValue, fieldName, registerField]);

  return (
    <div className={classes.container}>
      {options.map((option, index) => (
        <div className={classes.item} key={option.id}>
          <label className={classes.label} htmlFor={option.id} key={option.id}>
            <input
              className={classes.input}
              defaultChecked={option.defaultChecked}
              ref={(ref) => {
                inputRefs.current[index] = ref;
              }}
              value={option.value}
              type="checkbox"
              id={option.id}
              onChange={(e) => {
                if (checkAll)
                  setTimeout(
                    () =>
                      (checkAllRef.current.checked =
                        inputRefs.current.filter((ref) => ref.checked === true)
                          .length === inputRefs.current.length),
                    10
                  );
                if (customOnChange) customOnChange();
              }}
              {...rest}
            />
            <span className={classes.label}>{option.label}</span>
          </label>
        </div>
      ))}

      {checkAll && (
        <div className={classes.item}>
          <label className={classes.label}>
            <input
              className={classes.input}
              defaultChecked={false}
              type="checkbox"
              ref={checkAllRef}
              onChange={(e) => {
                inputRefs.current.forEach(
                  (ref) => (ref.checked = e.target.checked)
                );
              }}
              {...rest}
            />
            <span className={classes.label}>Selecionar todos</span>
          </label>
        </div>
      )}
    </div>
  );
};

export default CustomCheckbox;
