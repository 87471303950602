import styled from 'styled-components';
import { Calendar as CalendarComponent } from 'react-calendar';

export const Container = styled.div`
  padding: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 10;
  overflow: hidden;
  width: 100%;
`;

export const Label = styled.label`
  color: #a8a8a8;
  font-size: 12px;
`;

export const InputContainer = styled.div`
  background-color: #ffffff;
  height: 36px;
  padding: 0px 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  position: relative;
  border-radius: 3px;

  border: 1px solid #e5e6e7;

  &::after {
    ${props => (props.hasValue ? '' : "content: '';")}
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 19px;
    width: 11px;
    height: 11px;
    transform: rotate(45deg);
    border-right: 1px solid #000;
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: rgb(0, 0, 0);
    border-bottom: 1px solid #000;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgb(0, 0, 0);
  }
`;

export const Preview = styled.span`
  display: block;
  width: 100%;
  height: 36px;
  font-size: 14px;
  font-family: 'Noto Sans', sans-serif;
  border: none;
  outline: none;
  text-align: left;
  line-height: 36px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: rgb(118, 118, 118);
  cursor: pointer;
`;

export const CloseIcon = styled.i`
  cursor: pointer;
  transform: scale(0.8);
`;

export const ArrowDown = styled.div``;

export const Error = styled.span`
  color: red;
  opacity: 0.7;
`;

export const CalendarInput = styled(CalendarComponent)`
  width: 100%;
  margin: 0;
  background-color: #ffffff;
  border: 1px solid #e5e6e7;
  line-height: 1.125em;
  display: ${props => (props.visible ? props.displayType ? props.displayType : 'block' : 'none')};
  position: absolute;
  z-index: 10;

  button {
    margin: 0;
    border: 0;
    outline: none;

    :enabled {
      :hover {
        cursor: pointer;
      }
    }
  }

  .react-calendar {
    display: contents;
  }

  .react-calendar__navigation {
    display: flex;
    height: 25px;
    margin-bottom: 0.5em;

    .react-calendar__navigation__label__labelText {
      font-size: 0.9rem;
    }

    button {
      min-width: 40px;
      background: none;

      span {
        color: #000000 !important;
        font-size: 20px;
      }

      :enabled {
        &:hover {
          background-color: rgb(230, 230, 230);
        }
      }

      &&[disabled] {
        background-color: rgb(240, 240, 240);
      }
    }
  }

  .react-calendar__tile {
    color: #222222;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    background-color: #fff;
    height: 30px;
    border-radius: 5px;
    outline-offset: -1px;

    :hover {
      background-color: rgb(230, 230, 230, 0.8);
    }

    .demandsCount {
      font-size: 9px;
    }
  }

  .hasOneDemand {
    /* dia com um SLA do cliente a vencer  */
    background-color: rgba(242, 185, 15, 0.4);

    :hover {
      background-color: rgba(242, 185, 15, 0.6);
    }
  }

  .hasMoreThanOneDemand {
    /* dia com mais de um SLA do cliente a vencer  */
    background-color: rgb(234, 95, 89, 0.8);
    color: #fff;

    :hover {
      background-color: rgb(234, 95, 89, 1);
    }
  }

  .isHoliday {
    /* dia em que é feriado  */
    background-color: rgba(25, 148, 255, 0.4);

    :hover {
      background-color: rgba(25, 148, 255, 0.6);
    }
  }

  .react-calendar__tile--now {
    /* dia atual  */
    outline: 1px solid #ccc;
  }

  .react-calendar__tile--active {
    /* dia selecionado  */
    /* background-color: rgb(230, 230, 230); */
    outline: 1px solid #000;
  }

  .react-calendar__month-view__weekdays {
    font-size: 14px;
    color: #00000099;
    font-weight: 300;
    padding: 10px 0px;

    abbr {
      text-decoration: none !important;
      border: none !important;
      width: 100%;
      text-align: center;
    }
  }

  .react-calendar__month-view__weekdays__weekday {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .react-calendar__month-view__days__day--weekend {
    abbr {
      color: #ff4a55;
    }
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    abbr {
      opacity: 0.5;
    }
  }

  @media (max-width: 700px) {
    max-width: 80vw;
    padding: 20px;

    .react-calendar__navigation {
      height: 44px;
      margin-bottom: 0em;

      button {
        span {
          font-size: 16px;
        }
      }
    }

    .react-calendar__month-view__weekdays {
      font-size: 12px;
    }

    .react-calendar__tile {
      font-size: 14px;
      height: 30px;
      padding: 5px;
      border-width: 1px;
      border-radius: 5px;
    }
  }
`;
