import api from '../Api';
import { toast } from 'react-toastify';

const handleRequest = async (requestFunc) => {
  try {
    const response = await requestFunc();
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.error?.message || 'Ocorreu um erro na solicitação.';
    toast.error(errorMessage);
    throw error;
  }
};

export const popupApi = {
  getAll: function (additionalParams = {}) {
    return handleRequest(async () => {
      return api.get(`/popup`, {
        params: {
          ...additionalParams,
        },
      });
    });
  },

  getById: function (popupId, additionalParams = {}) {
    return handleRequest(async () => {
      return api.get(`/popup/${popupId}`, {
        params: {
          ...additionalParams,
        },
      });
    });
  },

  create: function (data = {}) {
    return handleRequest(async () => {
      return api.post(`/popup`, data);
    });
  },
  
  update: function (popupId, data = {}) {
    return handleRequest(async () => {
      return api.patch(`/popup/${popupId}`, data);
    });
  },

  delete: function (popupId) {
    return handleRequest(async () => {
      return api.delete(`/popup/${popupId}`);
    });
  },

  getPopupByDate: function () {
    return handleRequest(async () => {
      return api.get(`/popup/today`);
    });
  }
};
