import TemplateService from '../../../services/templates/templateService';
import EditTemplateFileData from '../../entities/template/EditTemplateFileData';
import Template from '../../entities/template/Template';

export default async function editTemplateFile(
  templateId,
  newTemplateFileData
) {
  const editTemplateFileData = new EditTemplateFileData(newTemplateFileData);

  await editTemplateFileData.validateData();

  const newTemplateFileAwsKey =
    await TemplateService.uploadTemplateFileAndReturnAwsKey(
      newTemplateFileData.templateFile
    );

  const requestData = editTemplateFileData.getDataWith(newTemplateFileAwsKey);

  const updatedTemplate = await TemplateService.updateTemplate(
    templateId,
    requestData
  );

  return new Template(updatedTemplate);
}
