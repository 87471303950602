import React, { useEffect, useState } from "react";
import MiniDrawer from "../../../components/MiniDrawer";
import DataTable from 'react-data-table-component';
import { columns, styleDataTable } from "./dataColumns";
import { ClassStyle } from "../index";
import Loading from "../../../components/Loading";
import history from "../../../history";
import demandsApi from "../../../services/apis/demandsApi";
import usersApi from "../../../services/apis/usersApi";
import moment from "moment";
import businessApi from "../../../services/apis/businessApi";

export default function MenuAIR() {
  const classes = ClassStyle();

  const [loading, setLoading] = useState(false);
  const [demandsAIR, setDemandsAIR] = useState([]);

  const data = () => {
    return demandsAIR.map((item) => {
      const constantsDemands = {
        OPEN: 'Aberto',
        PENDING: 'Pendente',
        IN_PROGRESS: 'Em Andamento',
        FINISHED: 'Finalizado',
      }

      return {
        id: `${item.id}`,
        name: item?.user?.name,
        customer: item?.customer?.name,
        business: item?.business?.name,
        status: constantsDemands[item?.statusKey],
        updatedAt: moment(item?.updatedAt).format('DD/MM/YYYY'),
        action: (
          <div style={{ display: "flex", gap: '0.5rem' }}>
            <p
              style={{ color: '#1AB394', cursor: 'pointer' }}
              onClick={() => 
                history.push(`/riskAnalysis/menu/${item?.user?.id}/business/${item.business.id}`
              )}
            >
              Detalhes
            </p>

            <p
              style={{ color: '#1AB394', cursor: 'pointer' }}
              onClick={() => history.push(`/demands?demandId=${item.id}`)}
            >
              Demanda
            </p>
          </div>
        ),
      };
    });
  };

  async function fetchData() {
    setLoading(true);

    const params = {
      demandTypeName: 'Análise Interna de Risco',
      hasCustomer: true,
    }
    const allDemands = (await demandsApi.getAllDemands(params))?.data?.demands;
    const allDemandsWithUser = await Promise.all(allDemands.map(async (demand) => {
      const user = (await usersApi.getUserById(demand.userId))?.data;
      const business = (await businessApi.getByBusinessId(demand.businessId))?.data;
      return { ...demand, user, business };
    }));

    setDemandsAIR(allDemandsWithUser)
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <MiniDrawer
        currentExpaded="Menu"
        main={
          <>
            <Loading open={loading} />
            <section className={classes.content}>
              <h3 className={classes.title} style={{ margin: ' 0 0 1.25rem 0' }}>Menu Analise Interna de Risco</h3>
              <div style={{ margin: '2rem 0' }}>
                <DataTable columns={columns} data={data()} customStyles={styleDataTable} />
              </div>
            </section>
          </>
        } />
    </React.Fragment>
  );
}