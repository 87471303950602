import { makeStyles } from '@material-ui/core';
import { useField } from '@unform/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';

const useStyles = makeStyles(theme => ({
  error: {
    color: 'red',
    opacity: '70%',
  },
  label: {
    cursor: 'pointer',
  },
  input: {
    display: 'none',
  },
  mainLabel: {
    padding: '15px',
    display: 'flex',
    border: 'dashed',
    borderColor: '#A8A8A8',
    borderRadius: '5px',
    borderWidth: '2px',
    alignItems: 'center',
  },
  choseFiles: {
    border: 'solid',
    borderRadius: '5px',
    borderWidth: '2px',
    padding: '5px',
    borderColor: '#A8A8A8',
    fontSize: '14px',
    color: '#A8A8A8',
  },
  fileName: {
    marginLeft: '20px',
    fontSize: '14px',
    color: '#3052DE',
    textAlign: 'justify',
  },
  main: {
    marginTop: '5px',
  },
  labelTitle: {
    color: '#A8A8A8',
    fontSize: '14px',
    textAlign: 'left',
  },
  labelTitleContainer: {
    marginLeft: '5px',
    marginBottom: '10px',
  },
}));

export const FileInputComponent = ({
  inputRef,
  name,
  error,
  label,
  defaultValue,
  ...rest
}) => {
  const [preview, setPreview] = useState(defaultValue);
  const classes = useStyles();

  const _handleFilePreview = useCallback(e => {
    const files = e.target.files;

    if (!files || files.length === 0) {
      setPreview(null);
    }

    if (files.length === 1) {
      setPreview(files[0].name);
    } else {
      setPreview(files.length + ' arquivos');
    }
  }, []);

  return (
    <div className={classes.main}>
      <div className={classes.labelTitleContainer}>
        <label className={classes.labelTitle} htmlFor={name}>
          {label}
        </label>
      </div>

      <label className={classes.label} htmlFor={name}>
        <div className={classes.mainLabel}>
          <h2 className={classes.choseFiles}>Escolher arquivo</h2>

          <span className={classes.fileName}>{preview}</span>
        </div>
      </label>

      <input
        ref={inputRef}
        id={name}
        name='input'
        className={classes.input}
        type='file'
        {...rest}
        onChange={e => {
          _handleFilePreview(e);

          if (typeof rest.onChange === 'function') {
            rest.onChange(e);
          }
        }}
      />

      {error && <span className={classes.error}>{error}</span>}
    </div>
  );
};

const FileInput = ({ name, label, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue(ref) {
        ref.value = '';
      },
    });
  }, [fieldName, registerField]);

  return (
    <FileInputComponent
      inputRef={inputRef}
      name={fieldName}
      label={label}
      defaultValue={defaultValue}
      error={error}
      {...rest}
    />
  );
};

export default FileInput;
