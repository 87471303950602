import React, { useState, useRef } from "react";
import * as Yup from "yup";
import CustomInput from "../../components/input/CustomInput";
import MiniDrawer from "../../components/MiniDrawer";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Form } from "@unform/web";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomRadio from "../../components/input/CustomRadio";
import CheckboxInput from "../../components/input/CheckboxInput";
import apiOld from "../../services/Api";
import history from "../../history";
import { Constants } from "../../utils/Constants";
import { toast } from "react-toastify";

const useStyles = makeStyles(() => ({
  main: {
    background: "#FFF",
  },
  mainTitle: {
    background: "#FFF",
    borderTop: "solid 2px #e7eaec",
    borderBottom: "solid 1px #e7eaec",
    padding: "15px",
  },
  line: {
    backgorund: "reds",
  },
  title: {
    fontSize: "15px",
  },
  mainForm: {
    padding: "10px",
  },
  form: {
    width: "100%",
  },
  input: {
    padding: "10px",
  },
  buttonContainer: {
    justifyContent: "flex-end",
    padding: "10px",
  },
  submit: {
    padding: "10px 15px",
    fontSize: "17px",
    background: "#1ab394",
    border: "none",
    color: "#fff",
    borderRadius: "5px",
  },
  subTitle: {
    background: "#FFF",
    borderBottom: "solid 1px #e7eaec",
  },
}));

export default function CreateForm() {
  const classes = useStyles();
  const formRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [fields, setFields] = useState([0]);
  const [valids, setValids] = useState([0]);
  const [count, setCount] = useState(1);

  async function handleSubmit(data, { reset }) {
    setSubmitLoading(true);

    try {
      formRef.current.setErrors({});
      const fieldSchema = {};

      valids.forEach((key) => {
        fieldSchema[`name-${key}`] = Yup.string().label("Nome").required();
        fieldSchema[`label-${key}`] = Yup.string().label("Título").required();
        fieldSchema[`description-${key}`] = Yup.string().label("Descrição");
        fieldSchema[`type-${key}`] = Yup.string().label("Tipo").required();
        fieldSchema[`required-${key}`] = Yup.boolean().label("Necessário");
      });

      const formSchema = Yup.object().shape({
        name: Yup.string().label("Nome do formulário").required(),
        description: Yup.string().label("Descrição").required(),
        ...fieldSchema,
      });

      await formSchema.validate(data, {
        abortEarly: false,
      });

      const request = {
        name: data.name,
        description: data.description,
        fields: valids.map((key) => {
          return {
            key: data[`name-${key}`],
            label: data[`label-${key}`],
            description: data[`description-${key}`],
            type: data[`type-${key}`],
            required: data[`required-${key}`],
          };
        }),
      };

      if (request.fields.length === 0) {
        toast.warning("Formulário deve ter pelo menos um campo!");

        return;
      }

      const response = await apiOld.post("/forms", request);

      toast.success("Formulário criado com sucesso!");

      history.push(`/forms/${response.data.id}`);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};

        error.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);

        return;
      }
    } finally {
      setSubmitLoading(false);
    }
  }

  const handleAddField = () => {
    setFields([...fields, count]);
    setValids([...valids, count]);
    setCount(count + 1);
  };

  const handleRemoveField = (key) => {
    let newArray = [...valids];
    newArray.splice(newArray.indexOf(key), 1);
    setValids(newArray);
  };

  return (
    <React.Fragment>
      <MiniDrawer
        main={
          <Grid container className={classes.main}>
            <Grid container className={classes.mainTitle}>
              <h2 className={classes.title}>Criar Formulário</h2>
            </Grid>
            <Grid container className={classes.mainForm}>
              <Form
                ref={formRef}
                onSubmit={handleSubmit}
                className={classes.form}
              >
                <Grid container>
                  <Grid item className={classes.input} xs={12}>
                    <CustomInput name="name" label="Nome do formulário*" />
                  </Grid>

                  <Grid item className={classes.input} xs={12}>
                    <CustomInput name="description" label="Descrição*" />
                  </Grid>

                  <Grid
                    container
                    className={classes.title}
                    spacing={1}
                    direction="row"
                  >
                    <Grid item>
                      <h2>Campos do formulário</h2>
                    </Grid>

                    <Grid item>
                      <button
                        className={classes.submit}
                        style={{
                          backgroundColor: "#1ab394",
                          padding: "0px 10px",
                          fontSize: "30px",
                        }}
                        type="button"
                        onClick={handleAddField}
                      >
                        +
                      </button>
                    </Grid>
                  </Grid>

                  {fields.map((key, idx) => {
                    if (!valids.includes(key)) return null;

                    return (
                      <div id={"field-" + key} key={idx}>
                        <h3 style={{ padding: "5px 0px 0px 10px" }}>
                          Entrada {valids.indexOf(key) + 1}
                        </h3>

                        <Grid
                          container
                          style={{ borderBottom: "solid 1px #e7eaec" }}
                        >
                          <Grid item className={classes.input} xs={12}>
                            <h4>Tipo</h4>

                            <CustomRadio
                              name={"type-" + key}
                              defaultValue="TEXT"
                              options={Constants.FORM_TYPES}
                            />
                          </Grid>

                          <Grid item className={classes.input} xs={2}>
                            <CustomInput
                              name={"name-" + key}
                              label="Chave (sem {, }, e _ )*"
                            />
                          </Grid>

                          <Grid item className={classes.input} xs={4}>
                            <CustomInput
                              name={"label-" + key}
                              label="Título*"
                            />
                          </Grid>

                          <Grid item className={classes.input} xs={6}>
                            <CustomInput
                              name={"description-" + key}
                              label="Descrição"
                            />
                          </Grid>

                          <Grid item xs={10} style={{ paddingLeft: "10px" }}>
                            <label htmlFor={"required-" + key}>
                              Campo necessário
                            </label>

                            <CheckboxInput name={"required-" + key} />
                          </Grid>

                          <Grid item className={classes.input} xs={2}>
                            <button
                              className={classes.submit}
                              style={{
                                backgroundColor: "#ff3333",
                              }}
                              type="button"
                              onClick={(e) => handleRemoveField(key)}
                            >
                              Remover
                            </button>
                          </Grid>
                        </Grid>
                      </div>
                    );
                  })}
                </Grid>

                <Grid container className={classes.buttonContainer}>
                  <button className={classes.submit} type="submit">
                    {submitLoading ? (
                      <CircularProgress color="inherit" />
                    ) : (
                      "Criar"
                    )}
                  </button>
                </Grid>
              </Form>
            </Grid>
          </Grid>
        }
      />
    </React.Fragment>
  );
}
