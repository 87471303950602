import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Api from '../Api';

const handleRequest = async (requestFunc) => {
  try {
    const response = await requestFunc();
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.error?.message || 'Ocorreu um erro na solicitação.';
    toast.error(errorMessage);
    throw error;
  }
};

const createCrudMethods = (entity) => {
  const baseUrl = `/analysisRisk/${entity}`;

  return {
    getAll: async (data) => handleRequest(() => Api.get(baseUrl, data)),
    getById: async (id) => handleRequest(() => Api.get(`${baseUrl}/${id}`)),
    create: async (data) => handleRequest(() => Api.post(baseUrl, data)),
    update: async (id, data) => handleRequest(() => Api.patch(`${baseUrl}/${id}`, data)),
    delete: async (id) => handleRequest(() => Api.delete(`${baseUrl}/${id}`)),
  };
};

export const AnalysisRiskApi = {
  sector: createCrudMethods('sector'),
  question: createCrudMethods('question'),
  answer: createCrudMethods('answer'),
  answerCustomer: createCrudMethods('answerCustomer'),
  questionsAnsweredByUser: async (userId, businessId) => handleRequest(() => Api.get(`/analysisRisk/question/questionsUser/${userId}/${businessId}`)),
  getQuestionBySector: async (data) => handleRequest(() => Api.post('/analysisRisk/question/questionBySector', data)),
  calculateRisk: async (data) => handleRequest(() => Api.post('/analysisRisk/answerCustomer/calculateRisk', data)),
};
