import apiOld from '../Api';

const journeyTypeDefaultDemand = {
  createJourneyTypeDefaultDemand: async function (data = {}) {
    return apiOld.post('/journeyTypeDefaultDemands', data);
  },

  updateJourneyTypeDefaultDemand: async function (defaultDemandId, data = {}) {
    return apiOld.put(`/journeyTypeDefaultDemands/${defaultDemandId}`, data);
  },

  getJourneyTypeDefaultDemandById: async function (defaultDemandId) {
    return apiOld.get(`/journeyTypeDefaultDemands/${defaultDemandId}`);
  },

  deleteJourneyTypeDefaultDemandById: async function (defaultDemandId) {
    return apiOld.delete(`/journeyTypeDefaultDemands/${defaultDemandId}`);
  },

  listAllJourneyTypeDefaultDemands: async function (params = {}) {
    return apiOld.get('/journeyTypeDefaultDemands', {
      params,
    });
  },
};

export default journeyTypeDefaultDemand;
