import moment from 'moment';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import DefaultTags from '../../constants/DefaultTags';
import { formatDate, objectToURLSearchParamsString } from '../../utils';
import { Constants } from '../../utils/Constants';
import { BackofficeResponsibleModal } from '../csModals/BackofficeResponsibleModal';
import ConfirmationModal from '../confirmationModal/ConfirmationModal';

import {
  PayOverdueIcon,
  SimpleTable,
  CheckIcon,
  CheckedIcon,
  CustomerLink,
} from './Tables.styles';
import customerApi from '../../services/apis/customerApi';

export const ActiveContactCustomerTable = ({ customers = [], fetchData }) => {
  const demandTypes = useSelector(state => state.demandTypes.all);

  const [modals, setModals] = useState({
    contactAtiveResponsibleModal: {
      open: false,
    },
    buttonsModal: {
      open: false,
      title: '',
      description: '',
      isActiveContact: true,
      data: {},
      fetchData: () => {},
      handleCloseButtonsModal: () => {},
    },
    confirmationModal: {
      open: false,
      title: '',
      description: '',
      resolveCallback: () => {},
      rejectCallback: () => {},
    },
  });

  const activeContactDemandParams = useCallback(
    ({ name, id, ActiveContact }) =>
      objectToURLSearchParamsString(
        !!ActiveContact
          ? {
              name: `Reunião Contato Ativo - ${name}`,
              descriptionKey: !!ActiveContact
                ? 'SUCCESIVE_CONTACTS'
                : 'FIRST_CONTACT',
              customerId: id,
              demandTypeId: demandTypes?.find(
                demand =>
                  demand.name ===
                  Constants.DEMAND_TYPES.ACTIVE_CONTACT_MEETING.key
              )?.id,
              backofficeUserId: ActiveContact.backofficeUserId,
            }
          : {
              name: `Reunião Contato Ativo - ${name}`,
              descriptionKey: !!ActiveContact
                ? 'SUCCESIVE_CONTACTS'
                : 'FIRST_CONTACT',
              customerId: id,
              demandTypeId: demandTypes?.find(
                demand =>
                  demand.name ===
                  Constants.DEMAND_TYPES.ACTIVE_CONTACT_MEETING.key
              )?.id,
            }
      ),

    [demandTypes]
  );

  /* const handleCloseButtonsModal = () =>
    setModals(prev => ({
      ...prev,
      buttonsModal: {
        ...prev.buttonsModal,
        open: false,
        title: '',
        description: '',
        data: {},
        fetchData: () => {},
        handleCloseButtonsModal: () => {},
      },
    })); */

  /* const handleOpenButtonsModal = useCallback(
    customer => () =>
      setModals(prev => ({
        ...prev,
        buttonsModal: {
          ...prev.buttonsModal,
          open: true,
          title: 'Qual ação deseja realizar?',
          description:
            'Você pode sugerir uma reunião (caso o cliente não tenha) ou registrar uma atividade.',
          data: customer,
          fetchData,
          handleCloseButtonsModal,
        },
      })),
    [fetchData]
  ); */

  const handleUpdateCurrentAction =
    ({ customerId }) =>
    async () => {
      try {
        await customerApi.registerCustomerActiveContactNextAction(customerId);
        await fetchData();
      } catch (error) {
        console.error(error);
      } finally {
        setModals(prev => ({
          ...prev,
          confirmationModal: {
            open: false,
            title: '',
            description: '',
            resolveCallback: () => {},
            rejectCallback: () => {},
          },
        }));
      }
    };

  const _handleOpenConfirmationModal =
    ({ customerId, currentAction, nextActionDate }) =>
    () =>
      setModals(prev => ({
        ...prev,
        confirmationModal: {
          ...prev.confirmationModal,
          open: true,
          title: `Deseja marcar a realização da atividade '${Constants.CS_ACTIONS[currentAction].title}'?`,
          description:
            currentAction !== Constants.CS_ACTIONS.length - 1
              ? `Isso fará a próxima atividade '${
                  Constants.CS_ACTIONS[currentAction + 1].title
                }' seja marcada para ${
                  Constants.CS_ACTIONS[currentAction].daysToAdd
                } dias à frente da data de ação ${formatDate(nextActionDate)}`
              : 'Isso irá encerrar as ações desse cliente',
          resolveCallback: handleUpdateCurrentAction({
            customerId,
          }),
          rejectCallback: () =>
            setModals(prev => ({
              ...prev,
              confirmationModal: {
                open: false,
                title: '',
                description: '',
                resolveCallback: () => {},
                rejectCallback: () => {},
              },
            })),
        },
      }));

  const _handleOpenActiveContactResponsibleModal = customer => {
    setModals(prev => ({
      ...prev,
      contactAtiveResponsibleModal: {
        open: true,
        customer,
        handleClose: () => {
          fetchData();
          setModals(prev => ({
            ...prev,
            contactAtiveResponsibleModal: {
              open: false,
            },
          }));
        },
      },
    }));
  };

  const annotationsParams = useCallback(
    () =>
      objectToURLSearchParamsString({
        ANNOTATION_TYPE: Constants.ANNOTATION_TYPES.cs.key,
      }),
    []
  );

  return customers.length > 0 ? (
    <SimpleTable.Container>
      <SimpleTable.Table>
        <SimpleTable.TableHead>
          <SimpleTable.TableRow>
            <SimpleTable.TableHeadCell
              style={{
                width: '15%',
              }}
            >
              Clientes
            </SimpleTable.TableHeadCell>

            <SimpleTable.TableHeadCell
              style={{
                width: '10%',
              }}
            >
              Responsável
            </SimpleTable.TableHeadCell>

            <SimpleTable.TableHeadCell
              style={{
                width: '10%',
              }}
            >
              Data da próxima reunião
            </SimpleTable.TableHeadCell>

            <SimpleTable.TableHeadCell
              style={{
                width: '15%',
              }}
            >
              Data de reunião sugerida
            </SimpleTable.TableHeadCell>

            <SimpleTable.TableHeadCell
              style={{
                width: '15%',
              }}
            >
              Próxima ação
            </SimpleTable.TableHeadCell>

            <SimpleTable.TableHeadCell
              style={{
                width: '15%',
              }}
            >
              Data da próxima ação
            </SimpleTable.TableHeadCell>

            <SimpleTable.TableHeadCell
              style={{
                width: '10%',
              }}
            >
              Data da última ação
            </SimpleTable.TableHeadCell>
            <SimpleTable.TableHeadCell
              style={{
                width: '10%',
              }}
            >
              Engajamento baixo em dias
            </SimpleTable.TableHeadCell>
          </SimpleTable.TableRow>
        </SimpleTable.TableHead>

        <SimpleTable.TableBody>
          {customers.map(customer => (
            <SimpleTable.TableRow key={customer.id}>
              <SimpleTable.TableData>
                <CustomerLink
                  title={`Health Score: ${customer.healthScore}`}
                  health={customer.healthScore}
                  to={`/customers/${customer.id}`}
                >
                  {customer.name}
                  {customer.tags?.find(
                    tag => tag.name === DefaultTags.PAYMENT_OVERDUE
                  ) && (
                    <PayOverdueIcon className='fas fa-exclamation-circle'></PayOverdueIcon>
                  )}
                </CustomerLink>
              </SimpleTable.TableData>

              <SimpleTable.TableData>
                <span
                  onClick={() =>
                    _handleOpenActiveContactResponsibleModal(customer)
                  }
                >
                  {customer.ActiveContact?.User?.name || 'Indefinido'}
                </span>
              </SimpleTable.TableData>

              <SimpleTable.TableData
                style={{
                  ...(moment().isAfter(
                    customer.ActiveContact?.nextActiveContactDate,
                    'days'
                  )
                    ? {
                        color: 'red',
                        fontWeight: 'bolder',
                      }
                    : {}),
                }}
              >
                <Link to={`/customers/${customer.id}`}>
                  {formatDate(customer.ActiveContact?.nextActiveContactDate)}
                </Link>
              </SimpleTable.TableData>

              <SimpleTable.TableData>
                {customer.ActiveContact?.suggestedMeetingAt ? (
                  formatDate(customer.ActiveContact?.suggestedMeetingAt)
                ) : (
                  <SimpleTable.TableDataButton
                    style={{
                      backgroundColor: '#1994FF',
                      fontWeight: 'bold',
                      color: 'white',
                      marginLeft: 0,
                    }}
                  >
                    <Link
                      to={{
                        pathname: `/new/demands`,
                        search: activeContactDemandParams(customer),
                      }}
                    >
                      Sugerir Reunião
                    </Link>
                  </SimpleTable.TableDataButton>
                )}
              </SimpleTable.TableData>
              <SimpleTable.TableData
                title={
                  Constants.CS_ACTIONS[
                    Constants.CS_ACTIONS.length >
                    customer?.ActiveContact?.currentAction
                      ? customer?.ActiveContact?.currentAction
                      : Constants.CS_ACTIONS.length - 1
                  ].title
                }
              >
                {
                  Constants.CS_ACTIONS[
                    Constants.CS_ACTIONS.length >
                    customer?.ActiveContact?.currentAction
                      ? customer?.ActiveContact?.currentAction
                      : Constants.CS_ACTIONS.length - 1
                  ].title
                }
              </SimpleTable.TableData>
              <SimpleTable.TableData
                style={{
                  ...(moment().isAfter(
                    customer.ActiveContact?.nextActionDate,
                    'days'
                  )
                    ? {
                        color: 'red',
                        fontWeight: 'bolder',
                      }
                    : {}),
                }}
              >
                {customer.ActiveContact?.nextActionDate ? (
                  <span>
                    {formatDate(customer.ActiveContact?.nextActionDate)}
                    {customer.ActiveContact?.currentAction <
                    Constants.CS_ACTIONS.length ? (
                      <CheckIcon
                        className='far fa-square'
                        onClick={_handleOpenConfirmationModal(
                          customer.ActiveContact
                        )}
                      />
                    ) : (
                      <CheckedIcon className='far fa-check-square' />
                    )}
                  </span>
                ) : (
                  <span title='Aguardando...'>Aguardando...</span>
                )}
              </SimpleTable.TableData>
              <SimpleTable.TableData>
                <Link
                  title={
                    customer.annotations?.reduce(
                      (prev, curr) =>
                        curr?.type === Constants.ANNOTATION_TYPES.cs.key
                          ? curr.content
                          : prev,
                      ''
                    ) || ''
                  }
                  to={{
                    pathname: `/customers/${customer.id}`,
                    search: annotationsParams(),
                  }}
                >
                  {customer.engagement?.lastActivity
                    ? formatDate(customer.engagement.lastActivity)
                    : 'Nunca'}
                </Link>
              </SimpleTable.TableData>
              <SimpleTable.TableData>
                {customer.lowEngagementSince
                  ? moment().diff(customer.lowEngagementSince, 'days')
                  : 'N/A'}
              </SimpleTable.TableData>
            </SimpleTable.TableRow>
          ))}
        </SimpleTable.TableBody>
      </SimpleTable.Table>
      {/* <ButtonOptionsModal {...modals.buttonsModal} /> */}
      <ConfirmationModal {...modals.confirmationModal} />
      <BackofficeResponsibleModal {...modals.contactAtiveResponsibleModal} />
    </SimpleTable.Container>
  ) : (
    <SimpleTable.TableRow>
      <SimpleTable.TableData>
        <span>Nenhum cliente 😃</span>
      </SimpleTable.TableData>
    </SimpleTable.TableRow>
  );
};
