import React from 'react';
import MiniDrawer from '../../components/MiniDrawer';
import { makeStyles } from '@material-ui/core/styles';
import history from '../../history';
import Table from '../../components/Table';
import { formatDate } from '../../utils';
import { Constants } from '../../utils/Constants';
import Calendar from '../../components/input/calendar/Calendar';
import { SpecificDemandCustomers } from '../../components/Tables/SpecificDemandCustomers';
import { SalesGrid, SalesItemGrid } from './SalesDashboard.styles';

const useStyles = makeStyles(theme => ({
  content: {
    height: '100vh',
    background: '#F5F6FA',
  },
  title: {
    fontSize: '35px',
  },
  table: {
    borderCollapse: 'collapse',
    margin: '25px 0',
    fontSize: '17px',
    width: '100%',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
  },
  tableHead: {
    backgroundColor: '#009879',
    color: '#ffffff',
    textAlign: 'left',
  },
  tableHeadTitleFirst: {
    borderRadius: '10px 0px 0px 0px',
    padding: '12px 15px',
  },
  tableHeadTitleLast: {
    borderRadius: '0px 10px 0px 0px',
    padding: '12px 15px',
  },
  tableHeadTitle: {
    padding: '12px 15px',
  },
  tableItem: {
    borderBottom: '1px solid #dddddd',
    '&:hover': {
      background: '#e0e0e0',
    },
  },
  tableItemColumn: {
    padding: '12px 15px',
    fontSize: '15px',
  },
  tableItemColumnStatus: {
    padding: '12px 15px',
    fontSize: '15px',
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'none',
  },
  action: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: '#000',
  },
  exportButton: {
    padding: '10px 15px',
    fontSize: '17px',
    background: '#1ab394',
    border: 'none',
    color: '#fff',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  selectSiz4Items: {},
}));

const headCells = [
  { id: 'saleDate', numeric: false, label: 'Data da venda' },
  { id: 'name', numeric: false, label: 'Nome' },
  { id: 'cpf', numeric: false, label: 'CPF|CNPJ' },
  { id: 'contractType', numeric: false, label: 'Tipo de Contrato' },
  { id: 'customerType', numeric: false, label: 'Tipo de Cliente' },
];

const getContractTypeDisplayName = contractType => {
  return (
    Constants.CUSTOMER_CONTRACT_TYPES.options.find(
      contract => contract.value === contractType
    )?.name || ''
  );
};

export default function SalesDashboard() {
  const classes = useStyles();
  const searchOptions = [
    {
      value: 'contractType',
      name: 'Tipo de Contrato',
      customInput: Constants.CUSTOMER_CONTRACT_TYPES.options,
    },
    {
      value: 'saleDateStartDate',
      name: 'Data da venda',
      customElement: () => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              position: 'relative',
              width: '100%',
            }}
          >
            <div
              style={{
                width: '50%',
                position: 'relative',
                marginRight: '10px',
              }}
            >
              <Calendar name='saleDateStartDate' label={'Data Inicial'} />
            </div>
            <div
              style={{
                width: '50%',
                position: 'relative',
              }}
            >
              <Calendar name='saleDateEndDate' label={'Data Final'} />
            </div>
          </div>
        );
      },
    },
  ];

  function handleClickItem(row) {
    history.push(`/customers/${row.customerId}/`);
  }

  async function handleRows(data) {
    return {
      rows: data.sales.map(sales => ({
        customerId: sales.customerId,
        cpf: sales.customer.cpf || sales.customer.cnpj,
        name: sales.customer.name,
        customerType: sales.customer.customerType,
        contractType: getContractTypeDisplayName(sales.contractType),
        saleDate: formatDate(sales.saleDate),
      })),
      total: data.total,
    };
  }

  return (
    <React.Fragment>
      <MiniDrawer
        currentExpaded='sales'
        main={
          <SalesGrid
            direction='column'
            spacing={30}
            justifyContent='space-evenly'
            container
            className={classes.main}
          >
            <SalesItemGrid item xs={12}>
              <Table
                title='Clientes'
                headCells={headCells}
                handleRows={handleRows}
                handleClickItem={handleClickItem}
                route='customerSales/table'
                rolePrefix='CUSTOMER'
                downloadRoute=''
                downloadColumns={Constants.CUSTOMER_COLUMNS}
                searchOptions={searchOptions}
              />
            </SalesItemGrid>
            <SalesItemGrid
              direction='column'
              alignItems='center'
              justifyContent='space-evenly'
              container
              xs={12}
            >
              <h3>Demanda Específica ℹ️</h3>
              <SpecificDemandCustomers />
            </SalesItemGrid>
          </SalesGrid>
        }
      />
    </React.Fragment>
  );
}
