import apiOld from '../Api';
import redux from '../../redux/store';
import * as userActions from '../../redux/actions/usersActions';

const { store } = redux;

const usersApi = {
  getAllBackofficeUsers: async function (additionalParams = {}) {
    return new Promise((resolve, reject) => {
      apiOld
        .get('/users', {
          params: {
            type: 'BACKOFFICE',
            ...additionalParams,
          },
        })
        .then(response => {
          store.dispatch(
            userActions.recieveBackofficeUsers(response.data.users)
          );
          resolve(response);
        })
        .catch(error => reject(error));
    });
  },

  getUserById: (id,additionalParams = {}) => {
    return apiOld.get(`/users/${id}`,{params: additionalParams})
  },

  generateNewPassword: async (userId, additionalParams = {}) => {
    return await apiOld.post(
      `/users/${userId}/generateNewPassword`,
      additionalParams
    );
  },

  deleteUser: (userId, options) => {
    return apiOld.delete(`/users/${userId}`, options);
  },
};

export default usersApi;
