import * as Yup from 'yup';
import TemplateField from '../TemplateField';

export default class Template {
  id;
  awsFileKey;
  backofficeOnly;
  description;
  fields = [];
  html;
  lastRevisionDate;
  name;
  createdAt;
  updatedAt;

  static createTemplateYupSchema = Yup.object({
    name: Yup.string().label('Nome').required(),
    description: Yup.string().label('Descrição').required(),
    html: Yup.string().label('Html').required(),
    backofficeOnly: Yup.boolean().label('Apenas para backoffice'),
    fields: Yup.array()
      .of(TemplateField.createFieldYupSchema)
      .label('Novos campos'),
  });
  static editTemplateYupSchema = Yup.object({
    name: Yup.string().label('Nome').required(),
    description: Yup.string().label('Descrição').required(),
    html: Yup.string().label('Html').required(),
    backofficeOnly: Yup.boolean().label('Apenas para backoffice'),
  });

  constructor(templateData = {}) {
    this.id = templateData.id;
    this.awsFileKey = templateData.awsFileKey;
    this.backofficeOnly = templateData.backofficeOnly;
    this.description = templateData.description;
    this.lastRevisionDate = templateData.lastRevisionDate;
    this.html = templateData.html;
    this.createdAt = templateData.createdAt;
    this.updatedAt = templateData.updatedAt;
  }
}
