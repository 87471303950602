import filesApi from './apis/filesApi';
import { preventWindowCloseOnUpload } from '../utils';

export default class FileService {
  async getSignedPutFileUrl({ fileName, fileType, type,publicRead = false }) {
    return (
      await filesApi.getSignedPutFileUrl({
        fileName,
        fileType,
        type,
        publicRead
      })
    ).data;
  }

  async getSignedDownloadFileUrlByAwsKey(key) {
    return (
      await filesApi.getSignedDownloadFileUrl({
        key,
      })
    ).data;
  }

  async uploadFileToSignedPutFileUrl({ signedUrl, file }) {
    return (
      await filesApi.uploadFileToSignedPutFileUrl({
        signedUrl,
        file,
      })
    ).data;
  }

  async uploadFileAndReturnAwsKey(file, { type, publicRead = false } = {}) {
    window.addEventListener('beforeunload', preventWindowCloseOnUpload);

    const signedPutRequest = await this.getSignedPutFileUrl({
      fileName: file.name,
      fileType: file.type,
      type,
      publicRead
    });

    await this.uploadFileToSignedPutFileUrl({
      signedUrl: signedPutRequest.signedUrl,
      file,
    });

    window.removeEventListener('beforeunload', preventWindowCloseOnUpload);

    return signedPutRequest.key;
  }

  async uploadFileAndFormatToDocumentStructure({ file, documentType, publicRead = false }) {
    const awsFileKey = await this.uploadFileAndReturnAwsKey(file, {
      type: documentType,
      publicRead,
    });

    return {
      fileName: file.name,
      fileType: file.type,
      fileSize: file.size,
      awsFileKey,
    };
  }

  async uploadFilesAndFormatToDocumentStructure({ files = [], documentType, publicRead = false }) {
    const formated = await Promise.all(
      Array.from(files).map(
        async file =>
          await this.uploadFileAndFormatToDocumentStructure({
            file,
            documentType,
            publicRead,
          })
      )
    );

    return formated;
  }

  saveFileToUserDisk(fileBinary, fileType, fileName) {
    const fileUrl = window.URL.createObjectURL(
      new Blob([fileBinary], {
        type: fileType,
      })
    );
    const link = document.createElement('a');

    link.href = fileUrl;
    link.target = '_blank';
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  async downloadFileByAwsKey(awsFileKey) {
    const { signedUrl, fileName } = await this.getSignedDownloadFileUrlByAwsKey(
      awsFileKey
    );
    const fileData = await filesApi.getFileBlobByUrl(signedUrl);

    this.saveFileToUserDisk(
      fileData.data,
      fileData.headers['content-type'],
      fileName
    );
  }
}
