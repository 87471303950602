import customerJourneyDemandApi from '../apis/customerJourneyDemandApi';

export default class CustomerJourneyDemandService {
  async updateCustomerJourneyDemand(demandId, data) {
    return await customerJourneyDemandApi.updateCustomerJourneyDemand(
      demandId,
      data
    );
  }

  async removeDemandFromCustomerJourney(demandId) {
    if (demandId)
      return await customerJourneyDemandApi.removeDemandFromCustomerJourney(
        demandId
      );
  }
}
