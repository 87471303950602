import React, { useEffect, useState } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import MiniDrawer from '../../components/MiniDrawer';
import apiOld from '../../services/Api';
import FileService from '../../services/fileService';
import { formatDateFullDetails } from '../../utils';

const fileService = new FileService();
const useStyles = makeStyles(() => ({
  main: {
    background: '#FFF',
  },
  mainTitle: {
    background: '#FFF',
    borderTop: 'solid 2px #e7eaec',
    borderBottom: 'solid 1px #e7eaec',
    padding: '15px',
  },
  line: {
    backgorund: 'reds',
  },
  title: {
    fontSize: '15px',
  },
  mainForm: {
    padding: '10px',
  },
  form: {
    width: '100%',
  },
  item: {
    padding: '10px',
  },
  buttonContainer: {
    justifyContent: 'flex-end',
    padding: '10px',
  },
  submit: {
    padding: '10px 15px',
    fontSize: '17px',
    background: '#1ab394',
    border: 'none',
    color: '#fff',
    borderRadius: '5px',
  },
  fields: {
    listStyleType: 'none',
    marginTop: '10px',
  },
  field: {
    padding: '10px 15px',
    border: '1px solid #e7eaec',
  },
  download: {
    cursor: 'pointer',
    backgroundColor: '#1ab394',
    color: '#fff',
    border: 'none',
    padding: '5px 10px',
    borderRadius: '5px',
  },
}));

export default function TemplateProfile() {
  const classes = useStyles();
  const [content, setContent] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    setLoading(true);
    apiOld
      .get(`/templates/${id}`)
      .then(response => {
        setContent(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  return (
    <React.Fragment>
      <MiniDrawer
        currentExpaded='templates'
        main={
          <Grid container className={classes.main}>
            <Backdrop className={classes.backdrop} open={loading}>
              <CircularProgress />
            </Backdrop>

            {content && (
              <>
                <Grid container className={classes.mainTitle}>
                  <h2 className={classes.title}>Template</h2>
                </Grid>
                <Grid container className={classes.mainForm}>
                  <Grid container>
                    <Grid item className={classes.item} xs={6}>
                      <h3>Nome:</h3>
                      <h4>{content.name}</h4>
                    </Grid>

                    <Grid item className={classes.item} xs={6}>
                      <h3>Apenas para o backoffice?</h3>
                      <h4>{content.backofficeOnly ? 'Sim' : 'Não'}</h4>
                    </Grid>

                    <Grid item className={classes.item} xs={12}>
                      <h3>Descrição:</h3>
                      <h4>{content.description}</h4>
                    </Grid>

                    <Grid item className={classes.item} xs={6}>
                      <h3>Revisado pela última vez em:</h3>
                      <h4>{formatDateFullDetails(content.lastRevisionDate)}</h4>
                    </Grid>

                    <Grid item className={classes.item} xs={6}>
                      <h3>Criado em:</h3>
                      <h4>{formatDateFullDetails(content.createdAt)}</h4>
                    </Grid>

                    <Grid item className={classes.item} xs={6}>
                      <h3>Template:</h3>
                      <button
                        className={classes.download}
                        onClick={() =>
                          fileService.downloadFileByAwsKey(content.awsFileKey)
                        }
                      >
                        Baixar Arquivo
                      </button>
                    </Grid>

                    <Grid item className={classes.item} xs={12}>
                      <h3>Campos do Template:</h3>
                      <ul className={classes.fields}>
                        {content.fields &&
                          content.fields.map(field => (
                            <li className={classes.field}>{field.label}</li>
                          ))}
                      </ul>
                    </Grid>

                    <Grid item className={classes.item} xs={12}>
                      <h3>html:</h3>
                      <h4>{content.html}</h4>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        }
      />
    </React.Fragment>
  );
}
