import apiOld from '../Api';

const journeyTypeApi = {
  createJourneyType: async function (data = {}) {
    return apiOld.post('/journeyTypes', data);
  },

  updateJourneyType: async function (journeyTypeId, data = {}) {
    return apiOld.put(`/journeyTypes/${journeyTypeId}`, data);
  },

  getJourneyTypeById: async function (journeyTypeId) {
    return apiOld.get(`/journeyTypes/${journeyTypeId}`);
  },

  listAllJourneyTypes: async function (params = {}) {
    return apiOld.get('/journeyTypes', {
      params,
    });
  },
};

export default journeyTypeApi;
