import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  border-radius: 15px;
  padding: 30px 30px;
  width: 400px;
`;

export const Title = styled.h1`
  font-size: 20px;
  font-weight: bold;
  color: #232323;
  text-align: center;
`;

export const Description = styled.p`
  font-size: 14px;
  font-weight: normal;
  color: #707070;
  text-align: center;
  margin-top: 30px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  align-self: flex-end;
`;

const Button = styled.button`
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 15px;
  color: #fff;
`;

export const ButtonConfirm = styled(Button)`
  background-color: #1ab394;
`;

export const ButtonCancel = styled(Button)`
  background-color: #ea5f59;
  margin-right: 10px;
`;
