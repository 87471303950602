import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import MiniDrawer from '../../components/MiniDrawer';
import metricsApi from '../../services/apis/metricsApi';
import { Constants } from '../../utils/Constants';
import {
  enumerateDaysBetweenDates,
  enumerateMonthsBetweenDates,
} from '../../utils';
import ChurnMetrics from '../../components/metrics/churn';
import CAMetrics from '../../components/contatoAtivo'
import {
  Frame,
  Container,
  Title,
  Section,
  SectionContent,
  SectionTitle,
  CustomerListsContainer,
  CustomerListContent,
  SimpleTable,
  SectionItem,
  LogoChurn,
} from './Dashboard.styles';
import 'chart.js/auto';
import { Bar, Line } from 'react-chartjs-2';
import DemandFinishedGraph from '../../components/metrics/demands/demandsFinished';
import AveragePerCustomerOfDemandsFinished from '../../components/metrics/demands/averagePerCustomerOfDemandsFinished';
import AllDemandFinishedGraph from '../../components/metrics/demands/allDemandsFinished';

const useStyles = makeStyles(() => ({
  main: {
    background: '#f3f3f4',
  },
  counterHead: {
    justifyContent: 'space-between',
    padding: '15px',
    borderTop: '2px solid #e7eaec',
    backgroundColor: '#fff',
  },
  counterContainer: {
    padding: '15px',
  },
  counterTypeMonthly: {
    backgroundColor: '#1c84c6',
    padding: '3px 8px',
    borderRadius: '.25em',
    color: '#FFF',
    fontWeight: '600',
    fontSize: '10px',
    verticalAlign: 'center',
  },
  counterBody: {
    padding: '15px 20px 20px 20px',
    borderTop: '1px solid #e7eaec',
    backgroundColor: '#fff',
  },
  counterText: {
    fontSize: '30px',
    fontWeight: '100',
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const [info, setInfo] = useState({
    content: undefined,
    loading: false,
  });

  const [asaasMetrics, setAsaasMetrics] = useState({
    content: undefined,
    loading: false,
  });

  const [contactTimeMetrics, setContactTimeMetrics] = useState({
    content: undefined,
    loading: false,
  });

  const [renewalChurn, setRenewalChurnMetrics] = useState({
    content: undefined,
    loading: false,
  });

  const [gaMetrics, setGaMetrics] = useState({
    content: undefined,
    loading: false,
  });

  const [ytMetrics, setYtMetrics] = useState({
    content: undefined,
    loading: false,
  });

  const fetchInfoPanel = useCallback(async () => {
    try {
      setInfo(prev => ({ ...prev, loading: true }));

      const { data } = await metricsApi.getInfos();

      setInfo(prev => ({
        ...prev,
        content: data,
      }));
    } catch (error) {
      console.error(error);
    } finally {
      setInfo(prev => ({ ...prev, loading: false }));
    }
  }, []);

  const formatSheetMetrics = ({ values, previews, goals }) => {
    return {
      labels: enumerateMonthsBetweenDates(
        moment().startOf('year'),
        moment().endOf('year'),
        'MM'
      ),
      percentages: values.map((value, i) =>
        ((value / goals[i]) * 100).toFixed(2)
      ),
      datasets: [
        {
          id: 1,
          label: `Faturamento`,
          borderColor: 'rgba(26,158,26, 0.2)',
          backgroundColor: '#1A9E1A',
          data: values,
        },
        {
          id: 2,
          label: `Previsão`,
          borderColor: 'rgba(242,185,15, 0.2)',
          backgroundColor: '#F2B90F',
          data: previews,
        },
        {
          id: 3,
          label: `Meta`,
          borderColor: 'rgba(25,148,255, 0.2)',
          backgroundColor: '#1994FF',
          data: goals,
        },
      ],
    };
  };

  const fetchAsaasPanel = useCallback(async () => {
    try {
      setAsaasMetrics(prev => ({ ...prev, loading: true }));

      const { data: overdue } = await metricsApi.getOverdueDashMetrics();
      const { data: revenue } = await metricsApi.getRevenueDashMetrics();
      const { data: sheet } = await metricsApi.getRevenueSheetMetrics();
      setAsaasMetrics(prev => ({
        ...prev,
        content: {
          overdue,
          revenue,
          sheet: sheet.authorizeUrl
            ? {
              url: sheet.authorizeUrl,
            }
            : { chart: formatSheetMetrics(sheet) },
        },
      }));
    } catch (error) {
      console.error(error);
    } finally {
      setAsaasMetrics(prev => ({ ...prev, loading: false }));
    }
  }, []);

  const fetchContactTimePanel = useCallback(async () => {
    try {
      setContactTimeMetrics(prev => ({ ...prev, loading: true }));

      const { data } = await metricsApi.getContactTimeDashMetrics();

      setContactTimeMetrics(prev => ({
        ...prev,
        content: data,
      }));
    } catch (error) {
      console.error(error);
    } finally {
      setContactTimeMetrics(prev => ({ ...prev, loading: false }));
    }
  }, []);

  const fetchRenewalAndChurnPanel = useCallback(async () => {
    try {
      setRenewalChurnMetrics(prev => ({ ...prev, loading: true }));

      const { data: renewal } = await metricsApi.getRenewalDashMetrics();
      const { data: churn } = await metricsApi.getChurnDashMetrics();
      const { data: suspended } = await metricsApi.getSuspendedDashMetrics();

      setRenewalChurnMetrics(prev => ({
        ...prev,
        content: { renewal, churn, suspended },
      }));
    } catch (error) {
      console.error(error);
    } finally {
      setRenewalChurnMetrics(prev => ({ ...prev, loading: false }));
    }
  }, []);

  const formatGaMetrics = ({ users, sessions }) => {
    return {
      labels: enumerateDaysBetweenDates(
        moment().startOf('month'),
        moment().endOf('month'),
        'DD'
      ),
      datasets: [
        {
          id: 1,
          label: `Sessões | Total deste mês: ${sessions.totalsForAllResults || 'Indefinido'
            }`,
          borderColor: '#1994FF',
          tension: 0,
          fill: 'origin',
          backgroundColor: 'rgba(25,148,255, 0.2)',
          data: sessions.rows.map(([, value]) => +value),
        },
        {
          id: 2,
          label: `Usuários | Total deste mês: ${users.totalsForAllResults || 'Indefinido'
            }`,
          borderColor: '#F2B90F',
          tension: 0,
          fill: 'origin',
          backgroundColor: 'rgba(242,185,15, 0.2)',
          data: users.rows.map(([, value]) => +value),
        },
      ],
    };
  };

  const fetchGaPanel = useCallback(async () => {
    try {
      setGaMetrics(prev => ({ ...prev, loading: true }));

      const { data: ga } = await metricsApi.getGaMetrics();
      setGaMetrics(prev => ({
        ...prev,
        content: {
          chart: formatGaMetrics(ga),
          topPages: ga.topPages,
        },
      }));
    } catch (error) {
      console.error(error);
    } finally {
      setGaMetrics(prev => ({ ...prev, loading: false }));
    }
  }, []);

  const formatYtMetrics = ({ views, subscribersGained }) => {
    views.totalsForAllResults = views.rows.reduce(
      (oldValue, [, value]) => oldValue + value,
      0
    );
    subscribersGained.totalsForAllResults = subscribersGained.rows.reduce(
      (oldValue, [, value]) => oldValue + value,
      0
    );
    return {
      labels: enumerateDaysBetweenDates(
        moment().startOf('month'),
        moment().endOf('month'),
        'DD'
      ),
      datasets: [
        {
          id: 1,
          label: `Visualizações | Total deste mês: ${views.totalsForAllResults || 'Indefinido'
            }`,
          borderColor: '#1994FF',
          tension: 0,
          fill: 'origin',
          backgroundColor: 'rgba(25,148,255, 0.2)',
          data: views.rows.map(([, value]) => +value),
        },
        {
          id: 2,
          label: `Inscritos Ganhos | Total deste mês: ${subscribersGained.totalsForAllResults || 'Indefinido'
            }`,
          borderColor: '#F2B90F',
          tension: 0,
          fill: 'origin',
          backgroundColor: 'rgba(242,185,15, 0.2)',
          data: subscribersGained.rows.map(([, value]) => +value),
        },
      ],
    };
  };

  const fetchYtPanel = useCallback(async () => {
    try {
      setYtMetrics(prev => ({ ...prev, loading: true }));

      const { data: yt } = await metricsApi.getYtMetrics();
      setYtMetrics(prev => ({
        ...prev,
        content: yt.authorizeUrl
          ? {
            url: yt.authorizeUrl,
          }
          : {
            chart: formatYtMetrics(yt),
            topVideos: yt.topVideos,
          },
      }));
    } catch (error) {
      toast.error('Oops, algo inesperado aconteceu!');

      console.error(error);
    } finally {
      setYtMetrics(prev => ({ ...prev, loading: false }));
    }
  }, []);

  const getProgressPercentage = useCallback(
    tooltipItems => {
      const index = +tooltipItems[0].label - 1;
      const isValid =
        index >= 0 &&
        index < asaasMetrics.content.sheet.chart.percentages.length;
      return `Faturamento / Meta: ${isValid
        ? asaasMetrics.content.sheet.chart.percentages[index] + '%'
        : 'Indefinido'
        } `;
    },
    [asaasMetrics]
  );
  useEffect(() => {
    fetchInfoPanel()
      .then(fetchAsaasPanel)
      // .then(fetchEngagementPanel)
      .then(fetchContactTimePanel)
      .then(fetchRenewalAndChurnPanel)
      // .then(fetchGaPanel)
      .then(fetchYtPanel);
  }, [
    fetchContactTimePanel,
    fetchAsaasPanel,
    // fetchEngagementPanel,
    fetchInfoPanel,
    fetchRenewalAndChurnPanel,
    // fetchGaPanel,
    fetchYtPanel,
  ]);

  return (
    <React.Fragment>
      <MiniDrawer
        main={
          <Grid container className={classes.main}>
            {info.loading && <CircularProgress />}
            {info?.content && (
              <>
                <Grid item className={classes.counterContainer} xs={3}>
                  <Grid container item className={classes.counterHead}>
                    <Grid item>
                      <h2>Clientes</h2>
                    </Grid>
                    <Grid item>
                      <span className={classes.counterTypeMonthly}>Total</span>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.counterBody}>
                    <Grid item>
                      <h3 className={classes.counterText}>
                        {info.content.customers}
                      </h3>
                      <span>Clientes na Plataforma</span>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item className={classes.counterContainer} xs={3}>
                  <Grid container item className={classes.counterHead}>
                    <Grid item>
                      <h2>Clientes Ativos</h2>
                    </Grid>
                    <Grid item>
                      <span className={classes.counterTypeMonthly}>Total</span>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.counterBody}>
                    <Grid item>
                      <h3 className={classes.counterText}>
                        {info.content.activeCustomers}
                      </h3>
                      <span>Clientes ativos na Plataforma</span>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item className={classes.counterContainer} xs={3}>
                  <Grid container item className={classes.counterHead}>
                    <Grid item>
                      <h2>Usuários</h2>
                    </Grid>
                    <Grid item>
                      <span className={classes.counterTypeMonthly}>Total</span>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.counterBody}>
                    <Grid item>
                      <h3 className={classes.counterText}>
                        {info.content.users}
                      </h3>
                      <span>Usuários criados</span>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item className={classes.counterContainer} xs={3}>
                  <Grid container item className={classes.counterHead}>
                    <Grid item>
                      <h2>Templates</h2>
                    </Grid>
                    <Grid item>
                      <span className={classes.counterTypeMonthly}>Total</span>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.counterBody}>
                    <Grid item>
                      <h3 className={classes.counterText}>
                        {info.content.templates}
                      </h3>
                      <span>Templates criados</span>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            <Container>
              <Title>Métricas</Title>

              <Section>
                <SectionTitle>
                  Inadimplência e Faturamento{' '}
                  {asaasMetrics.content?.sheet?.url && (
                    <SimpleTable.TableDataButton
                      onClick={() =>
                      (window.location.href =
                        asaasMetrics.content?.sheet?.url)
                      }
                      style={{ cursor: 'pointer' }}
                    >
                      Logar no Sheets
                    </SimpleTable.TableDataButton>
                  )}
                </SectionTitle>
                <SectionContent>
                  <CustomerListsContainer>
                    {asaasMetrics.loading && <CircularProgress />}

                    <CustomerListContent>
                      {asaasMetrics?.content && (
                        <SimpleTable.Container>
                          <SimpleTable.Table>
                            <SimpleTable.TableHead>
                              <SimpleTable.TableRow>
                                <SimpleTable.TableHeadCell
                                  style={{
                                    width: '20%',
                                  }}
                                >
                                  Períodos
                                </SimpleTable.TableHeadCell>
                                {asaasMetrics?.content?.overdue && (
                                  <SimpleTable.TableHeadCell
                                    style={{
                                      textAlign: 'center',
                                      width: '80%',
                                    }}
                                  >
                                    Inadimplentes
                                  </SimpleTable.TableHeadCell>
                                )}
                                {asaasMetrics?.content?.revenue && (
                                  <SimpleTable.TableHeadCell
                                    style={{
                                      textAlign: 'center',
                                      width: '40%',
                                    }}
                                  >
                                    Faturamento
                                  </SimpleTable.TableHeadCell>
                                )}
                              </SimpleTable.TableRow>
                            </SimpleTable.TableHead>

                            <SimpleTable.TableBody>
                              {[
                                {
                                  period: 'Este mês',
                                  overdue:
                                    asaasMetrics?.content?.overdue
                                      ?.overdueCustomersCurrentMonth,
                                  revenue:
                                    asaasMetrics?.content?.revenue
                                      ?.revenueCurrentMonth,
                                },
                                {
                                  period: 'Este ano',
                                  overdue:
                                    asaasMetrics?.content?.overdue
                                      ?.overdueCustomersCurrentYear,
                                  revenue:
                                    asaasMetrics?.content?.revenue
                                      ?.revenueCurrentYear,
                                },
                              ].map(statistics => (
                                <SimpleTable.TableRow key={statistics.period}>
                                  <SimpleTable.TableData>
                                    {statistics.period}
                                  </SimpleTable.TableData>

                                  <SimpleTable.TableData
                                    style={{
                                      textAlign: 'center',
                                      color: '#1ab394',
                                    }}
                                  >
                                    {statistics.overdue}
                                  </SimpleTable.TableData>
                                  <SimpleTable.TableData
                                    style={{
                                      textAlign: 'center',
                                      color: '#1ab394',
                                    }}
                                  >
                                    {parseFloat(
                                      statistics.revenue
                                    ).toLocaleString('pt-BR', {
                                      minimuFractionDigits: 2,
                                      style: 'currency',
                                      currency: 'BRL',
                                    })}
                                  </SimpleTable.TableData>
                                </SimpleTable.TableRow>
                              ))}
                            </SimpleTable.TableBody>
                          </SimpleTable.Table>
                        </SimpleTable.Container>
                      )}
                    </CustomerListContent>
                  </CustomerListsContainer>
                  <CustomerListsContainer>
                    {asaasMetrics.loading && <CircularProgress />}

                    <CustomerListContent style={{ height: '50vh' }}>
                      {asaasMetrics?.content?.sheet && (
                        <Bar
                          datasetIdKey='Revenue Sheet'
                          options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                              tooltip: {
                                callbacks: {
                                  footer: getProgressPercentage,
                                },
                              },
                            },
                          }}
                          data={
                            asaasMetrics?.content?.sheet?.chart || {
                              labels: [],
                              datasets: [],
                            }
                          }
                        />
                      )}
                    </CustomerListContent>
                  </CustomerListsContainer>
                </SectionContent>
              </Section>

              <Section style={{
                  height: '60vh',
                  overflowY: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '3rem',
                }}>
                <AllDemandFinishedGraph />
                <DemandFinishedGraph />
                <AveragePerCustomerOfDemandsFinished />
              </Section>

              <Section>
                <SectionTitle>Contato Ativo (hora de contactar)</SectionTitle>
                <SectionContent>
                  <CAMetrics metrics={contactTimeMetrics} />
                </SectionContent>
              </Section>

              <Section>
                <SectionTitle>
                  Renovação (Primeira Parcela) e Churn (Tag Churn)
                </SectionTitle>
                <SectionContent>
                  {info.content && (
                    <LogoChurn>
                      Logo Churn (último mês):{' '}
                      {(
                        (100 * info.content.churnedCustomersLastMonth) /
                        info.content.activeCustomers
                      ).toFixed(2)}
                      %
                    </LogoChurn>
                  )}
                  {info.content && (
                    <LogoChurn>
                      Logo Churn (mês atual):{' '}
                      {(
                        (100 * info.content.churnedCustomersThisMonth) /
                        info.content.activeCustomers
                      ).toFixed(2)}
                      %
                    </LogoChurn>
                  )}
                  <ChurnMetrics metrics={renewalChurn} />
                </SectionContent>
              </Section>

              <Section>
                <SectionTitle>Vendas</SectionTitle>
                <SectionContent>
                  <Frame
                    title='Sells Metrics'
                    src={Constants.PUBLIC_LINKS.PIPEDRIVE}
                  ></Frame>
                </SectionContent>
              </Section>

              <Section>
                <SectionTitle>Google Analytics</SectionTitle>
                <SectionContent isRow={true}>
                  {gaMetrics.loading && <CircularProgress />}

                  <SectionItem isResizable={true}>
                    {gaMetrics?.content?.chart && (
                      <Line
                        datasetIdKey='Google Analytics'
                        options={{
                          responsive: true,
                          maintainAspectRatio: false,
                        }}
                        data={
                          gaMetrics.content?.chart || {
                            labels: [],
                            datasets: [],
                          }
                        }
                      />
                    )}
                  </SectionItem>
                  <SectionItem>
                    {gaMetrics?.content && (
                      <SimpleTable.Container height='100%'>
                        <SimpleTable.Table>
                          <SimpleTable.TableHead>
                            <SimpleTable.TableRow>
                              {gaMetrics?.content?.topPages && (
                                <SimpleTable.TableHeadCell
                                  style={{
                                    width: '70%',
                                  }}
                                >
                                  Páginas Mais Vistas No Mês
                                </SimpleTable.TableHeadCell>
                              )}
                              {gaMetrics?.content?.topPages?.rows && (
                                <SimpleTable.TableHeadCell
                                  style={{
                                    textAlign: 'center',
                                    width: '30%',
                                  }}
                                >
                                  Visualizações
                                </SimpleTable.TableHeadCell>
                              )}
                            </SimpleTable.TableRow>
                          </SimpleTable.TableHead>

                          <SimpleTable.TableBody>
                            {gaMetrics?.content?.topPages?.rows.map(
                              ([page, views], index) => (
                                <SimpleTable.TableRow key={index}>
                                  <SimpleTable.TableData title={page}>
                                    {page}
                                  </SimpleTable.TableData>

                                  <SimpleTable.TableData
                                    style={{
                                      textAlign: 'center',
                                    }}
                                  >
                                    {views}
                                  </SimpleTable.TableData>
                                </SimpleTable.TableRow>
                              )
                            )}
                          </SimpleTable.TableBody>
                        </SimpleTable.Table>
                      </SimpleTable.Container>
                    )}
                  </SectionItem>
                </SectionContent>
              </Section>

              <Section>
                <SectionTitle>
                  Youtube Analytics{' '}
                  {ytMetrics.content?.url && (
                    <SimpleTable.TableDataButton
                      onClick={() =>
                        (window.location.href = ytMetrics.content?.url)
                      }
                      style={{ cursor: 'pointer' }}
                    >
                      Logar no Youtube
                    </SimpleTable.TableDataButton>
                  )}
                </SectionTitle>
                <SectionContent isRow={true}>
                  {ytMetrics.loading && <CircularProgress />}
                  {!ytMetrics.content?.url && (
                    <SectionItem isResizable={true}>
                      <Line
                        datasetIdKey='Youtube Analytics'
                        options={{
                          responsive: true,
                          maintainAspectRatio: false,
                        }}
                        data={
                          ytMetrics.content?.chart || {
                            labels: [],
                            datasets: [],
                          }
                        }
                      />
                    </SectionItem>
                  )}
                  {!ytMetrics.content?.url && (
                    <SectionItem>
                      {ytMetrics?.content?.topVideos && (
                        <SimpleTable.Container height='100%'>
                          <SimpleTable.Table>
                            <SimpleTable.TableHead>
                              <SimpleTable.TableRow>
                                {ytMetrics?.content?.topVideos && (
                                  <SimpleTable.TableHeadCell
                                    style={{
                                      width: '70%',
                                    }}
                                  >
                                    Vídeos Mais Vistos no Mês
                                  </SimpleTable.TableHeadCell>
                                )}
                                {ytMetrics?.content?.topVideos?.rows && (
                                  <SimpleTable.TableHeadCell
                                    style={{
                                      textAlign: 'center',
                                      width: '30%',
                                    }}
                                  >
                                    Visualizações
                                  </SimpleTable.TableHeadCell>
                                )}
                              </SimpleTable.TableRow>
                            </SimpleTable.TableHead>

                            <SimpleTable.TableBody>
                              {ytMetrics?.content?.topVideos?.rows?.length >
                                0 ? (
                                ytMetrics?.content?.topVideos?.rows.map(
                                  ([page, views], index) => (
                                    <SimpleTable.TableRow key={index}>
                                      <SimpleTable.TableData title={page}>
                                        {
                                          ytMetrics.content.topVideos
                                            .videoInfos[index].snippet.title
                                        }
                                      </SimpleTable.TableData>

                                      <SimpleTable.TableData
                                        style={{
                                          textAlign: 'center',
                                        }}
                                      >
                                        {views}
                                      </SimpleTable.TableData>
                                    </SimpleTable.TableRow>
                                  )
                                )
                              ) : (
                                <SimpleTable.TableRow>
                                  <SimpleTable.TableData>
                                    <span>Nenhum dado ainda... 😕</span>
                                  </SimpleTable.TableData>
                                  <SimpleTable.TableData></SimpleTable.TableData>
                                </SimpleTable.TableRow>
                              )}
                            </SimpleTable.TableBody>
                          </SimpleTable.Table>
                        </SimpleTable.Container>
                      )}
                    </SectionItem>
                  )}
                </SectionContent>
              </Section>
            </Container>
          </Grid>
        }
      />
    </React.Fragment>
  );
}
