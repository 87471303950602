import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from '@unform/web';
import React, { useRef, useState, useEffect, useCallback } from 'react';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import CustomInput from '../../components/input/CustomInput';
import CustomRadio from '../../components/input/CustomRadio';
import CustomSelect from '../../components/input/CustomSelect';
import CheckboxInput from '../../components/input/CheckboxInput';
import ImageInput from '../../components/input/ImageInputWithPreview';
import MiniDrawer from '../../components/MiniDrawer';
import history from '../../history';
import apiOld from '../../services/Api';
import CEPService from '../../services/CEPService';
import { formatFileToBase64, parseDateFromYupWithMask } from '../../utils';
import { Constants } from '../../utils/Constants';
import { validateBr } from 'js-brasil';
import moment from 'moment';
import tagsApi from '../../services/apis/tagsApi';
import customerApi from '../../services/apis/customerApi';

import TagInput from '../../components/input/TagInput';

import { TagsContainer } from '../../components/tagsModal/TagsModal.styles';
import CustomSelectWithSearch from '../../components/input/customSelectorWithSearch/CustomSelectWithSearch';
import { optionsAccess } from './ListCustomer';


const useStyles = makeStyles(() => ({
  main: {
    background: '#FFF',
  },
  mainTitle: {
    background: '#FFF',
    borderTop: 'solid 2px #e7eaec',
    borderBottom: 'solid 1px #e7eaec',
    padding: '15px',
  },
  line: {
    backgorund: 'reds',
  },
  title: {
    fontSize: '15px',
  },
  mainForm: {
    padding: '10px',
  },
  form: {
    width: '100%',
  },
  input: {
    padding: '10px',
  },
  buttonContainer: {
    justifyContent: 'flex-end',
    padding: '10px',
  },
  submit: {
    padding: '10px 15px',
    fontSize: '17px',
    background: '#1ab394',
    border: 'none',
    color: '#fff',
    borderRadius: '5px',
  },
  tag: {
    padding: '10px 15px',
    fontSize: '17px',
    background: 'purple',
    border: 'none',
    color: '#fff',
    borderRadius: '5px',
    margin: '10px',
  },
  subTitle: {
    background: '#FFF',
    borderBottom: 'solid 1px #e7eaec',
  },
}));

export default function CreateCustomer() {
  const classes = useStyles();
  const formRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [personType, setPersonType] = useState('PF');
  const [additionalUsers, setAdditionalUsers] = useState([]);
  const [valids, setValids] = useState([]);
  const [count, setCount] = useState(0);
  const [isForeign, setIsForeign] = useState(false);
  const [currentTags, setCurrentTags] = useState([]);
  const [tags, setActualTags] = useState([]);
  const [customerAccess, setCustomerAccess] = useState('');

  const loadTags = async () => {
    const response = await tagsApi.getAll()
    for (let i = 0; i < response.data.tags.length; i++) {
      if (response.data.tags[i].name !== 'Churn Cliente' && response.data.tags[i].name !== 'Contrato Suspenso' && response.data.tags[i].name !== 'Churn NDM')
        setCurrentTags(state => [...state, response.data.tags[i]])
    }
  }

  useEffect(() => {
    loadTags()
  }, [])


  async function handleSubmit(data) {
    setSubmitLoading(true);
    try {
      formRef.current.setErrors({});
      const additionalUsersSchema = {};
      let validatedSchema;
      valids.forEach(key => {
        additionalUsersSchema[`nome-${key}`] = Yup.string()
          .label('Nome')
          .required();
        additionalUsersSchema[`email-${key}`] = Yup.string()
          .label('Email')
          .required()
          .email();
        additionalUsersSchema[`cargo-${key}`] = Yup.string().label('Cargo');
        additionalUsersSchema[`telefone-${key}`] = Yup.string()
          .label('Telefone')
          .test(
            'celular',
            'Número celular inválido, você inseriu o 9 antes?',
            value => {
              if (value === '') return true;
              return validateBr.celular(value);
            }
          );
        additionalUsersSchema[`customerAdmin-${key}`] =
          Yup.bool().label('Administrador');
      });

      const communSchema = {
        saleDate: Yup.date()
          .label('Data da venda')
          .required()
          .nullable(true)
          .transform(parseDateFromYupWithMask)
          .typeError('Data inválida'),
        customerType: Yup.string()
          .oneOf(Constants.CUSTOMER_TYPES.LIST)
          .required('Tipo de Cliente é obrigatório'),
        contractType: Yup.string()
          .oneOf(Constants.CUSTOMER_CONTRACT_TYPES.list)
          .required('Tipo de Cliente é obrigatório'),
        cep: Yup.string()
          .required()
          .when('$isInternacional', (isInternacional, s) => {
            !isInternacional &&
              s.test('cep', 'CEP inválido', value => validateBr.cep(value));
          }),
        customerAccess: Yup.string().nullable(true),
      };
      data.customerAccess = customerAccess;

      const schemaPF = Yup.object().shape({
        nome: Yup.string().required(),
        contractExpiration: Yup.string()
          .label('Última parcela do contrato')
          .required(),
        personType: Yup.string().required(),
        rg: Yup.string(),
        cpf: Yup.string()
          .required()
          .test('cpf', 'CPF inválido', value => validateBr.cpf(value)),
        sexo: Yup.string().required(),
        nacionalidade: Yup.string(), //.required(),
        'estado civil': Yup.string(),
        profissão: Yup.string(),
        'data de nascimento': Yup.string(), //.required(),
        logradouro: Yup.string().required(),
        número: Yup.string().required(),
        bairro: Yup.string().required(),
        cnh: Yup.string(),
        cidade: Yup.string().required(),
        estado: Yup.string().required(),
        email: Yup.string().required().email(),
        telefone: Yup.string()
          // .required()
          .test(
            'celular',
            'Número celular inválido, você inseriu o 9 antes?',
            value => (value === '' ? true : validateBr.celular(value))
          ),
        site: Yup.string(),
        ...additionalUsersSchema,
        ...communSchema,
      });

      const schemaPJ = Yup.object().shape({
        nome: Yup.string().required(),
        contractExpiration: Yup.string()
          .label('Última parcela do contrato')
          .required(),
        personType: Yup.string().required(),
        'razão social': Yup.string().required(),
        'tipo de sociedade': Yup.string().required(),
        cnpj: Yup.string()
          .required()
          .test('cnpj', 'CNPJ inválido', value => validateBr.cnpj(value)),
        logradouro: Yup.string().required(),
        número: Yup.string().required(),
        bairro: Yup.string().required(),
        cidade: Yup.string().required(),
        estado: Yup.string().required(),
        'nome do representante legal': Yup.string().required(),
        'cpf do representante legal': Yup.string()
          .required()
          .test('cpf', 'CPF inválido', value => validateBr.cpf(value)),
        email: Yup.string().email().required(),
        telefone: Yup.string()
          // .required()
          .test(
            'celular',
            'Número celular inválido, você inseriu o 9 antes?',
            value => (value === '' ? true : validateBr.celular(value))
          ),
        logo: Yup.mixed()
          .label('Logo')
          .test('Image type', 'Tipo de imagem não aceita!', img => {
            if (!img) return true;

            return ['image/png', 'image/jpeg', 'image/jpg'].includes(img.type);
          })
          .test(
            'Image size',
            'Imagem muito grande! Tamanho máximo permitido: 2mb',
            img => {
              if (!img) return true;

              return img.size <= 1024 * 1024 * 2;
            }
          )
          .test('Image valid', 'Imagem inválida!', async img => {
            if (!img) return true;

            return await formatFileToBase64(img);
          }),
        ...additionalUsersSchema,
        ...communSchema,
      });

      if (personType === 'PJ') {
        validatedSchema = await schemaPJ.validate(data, {
          abortEarly: false,
          context: {
            isInternacional: data.isInternacional,
          },
        });
      } else {
        validatedSchema = await schemaPF.validate(data, {
          abortEarly: false,
          context: {
            isInternacional: data.isInternacional,
          },
        });
      }

      const parsedContractExpiration = data.contractExpiration
        ? moment(data.contractExpiration, 'DD-MM-YYYY').toDate()
        : null;

      const request = {
        saleDate: validatedSchema.saleDate,
        personType: data.personType,
        customerType: data.customerType,
        contractType: data.contractType,
        name: data.nome,
        contractExpiration: parsedContractExpiration,
        email: data.email,
        phone: data.telefone,
        site: data.site,
        rg: data.rg,
        cnh: data.cnh,
        cpf: data.cpf,
        sex: data.sexo,
        nationality: data.nacionalidade,
        customerAccess: data.customerAccess,
        civilState: data['estado civil'],
        profession: data['profissão'],
        birthDate: data['data de nascimento'],
        socialReason: data['razão social'],
        cnpj: data.cnpj,
        typeCompany: data['tipo de sociedade'],
        cep: data.cep,
        isInternacional: data.estrangeiro,
        address: data.logradouro,
        addressComplement: data.complemento,
        addressNumber: data['número'],
        addressDistrict: data.bairro,
        addressCity: data.cidade,
        addressState: data.estado,
        legalRepresentativeName: data['nome do representante legal'],
        legalRepresentativeCpf: data['cpf do representante legal'],
        logo:
          personType === 'PJ' && data.logo
            ? await formatFileToBase64(data.logo)
            : null,
        users: valids.map(key => {
          return {
            name: data[`nome-${key}`],
            email: data[`email-${key}`],
            occupation: data[`cargo-${key}`],
            phone: data[`telefone-${key}`],
            customerAdmin: data[`customerAdmin-${key}`],
          };
        })
      };

      const response = await apiOld.post('/customers', request);
      const customerId = response.data.id


      try {
        const sortedTags = tags.sort()
        for (let i = 0; i < sortedTags.length; i++) {
          await customerApi.addTag(customerId, sortedTags[i])
        }
      } catch (error) {
        toast.error('Erro ao criar cliente!');
      } finally {
        toast.success('Cliente cadastrado com sucesso!');
        history.push(`/customers/${response.data.id}`);
      }
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        console.log(err);
        err.inner.forEach((error, idx) => {
          validationErrors[error.path] = error.message;
          if (idx < 2) toast.error(error.message);
        });
        formRef.current.setErrors(validationErrors);
      }
    }
    setSubmitLoading(false);
  }

  const handlePersonTypeChange = () => {
    const personType = formRef.current.getFieldValue('personType');
    formRef.current?.setErrors({});
    setPersonType(personType);
  };

  const handleCEPChange = useCallback(() => {
    const cep = formRef.current.getFieldValue('cep').replace(/\s/g, '');
    if (!isForeign && cep.length === 9) {
      findCEP(cep);
    }
  }, [isForeign]);

  const handleAddAdditionalUser = () => {
    setAdditionalUsers([...additionalUsers, count]);
    setValids([...valids, count]);
    setCount(count + 1);
  };

  const handleRemoveAdditionalUser = key => {
    let newArray = [...valids];
    newArray.splice(newArray.indexOf(key), 1);
    setValids(newArray);
  };

  const findCEP = async cep => {
    const response = await CEPService.get(`/${cep}/json`);
    formRef.current.setFieldValue('logradouro', response.data.logradouro);
    formRef.current.setFieldValue('bairro', response.data.bairro);
    formRef.current.setFieldValue('complemento', response.data.complemento);
    formRef.current.setFieldValue('cidade', response.data.localidade);
    formRef.current.setFieldValue('estado', response.data.uf);
  };

  const handleForeign = ({ target }) => setIsForeign(target.checked);

  const handleCheckboxChange = (text, event) => {
    if (event.target.checked) {
      setCustomerAccess((prevCustomerAccess) => prevCustomerAccess + text);
    } else {
      setCustomerAccess((prevCustomerAccess) => prevCustomerAccess.replace(text, ''));
    }
  };

  const handleDoc = (event) => handleCheckboxChange('Gerador de documentos,', event);
  const handleMapping = (event) => handleCheckboxChange('Mapeamento de dados,', event);
  const handleMarcas = (event) => handleCheckboxChange('Registrar marcas,', event);

  return (
    <React.Fragment>
      <MiniDrawer
        currentExpaded='customers'
        main={
          <Grid container className={classes.main}>
            <Grid container className={classes.mainTitle}>
              <h2 className={classes.title}>Criar Cliente</h2>
            </Grid>
            <Grid container className={classes.mainForm}>
              <Form
                ref={formRef}
                onSubmit={handleSubmit}
                className={classes.form}
              >
                <Grid container>
                  <Grid item className={classes.input} xs={12}>
                    <h4>Tipo</h4>
                    <CustomRadio
                      name='personType'
                      defaultValue='PF'
                      onChange={handlePersonTypeChange}
                      options={Constants.PERSON_TYPES}
                    />
                  </Grid>
                  <Grid item className={classes.input} xs={12}>
                    <h2 className={classes.subTitle}>Informações Básicas</h2>
                  </Grid>
                  <Grid item className={classes.input} xs={6}>
                    <CustomInput
                      autoComplete='name'
                      name='nome'
                      label='Nome*'
                    />
                  </Grid>
                  <Grid item className={classes.input} xs={6}>
                    <InputMask mask='99/99/9999' maskChar=' '>
                      {() => (
                        <CustomInput
                          name='contractExpiration'
                          label='Data de vencimento da última parcela*'
                        />
                      )}
                    </InputMask>
                  </Grid>

                  <Grid item className={classes.input} xs={6}>
                    <InputMask mask='99/99/9999' maskChar=' '>
                      {() => (
                        <CustomInput name='saleDate' label='Data da venda*' />
                      )}
                    </InputMask>
                  </Grid>

                  <Grid item className={classes.input} xs={6}>
                    <CustomSelect
                      name='customerType'
                      label='Tipo de Cliente*'
                      options={Constants.CUSTOMER_TYPES.OPTIONS}
                    />
                  </Grid>

                  <Grid item className={classes.input} xs={6}>
                    <CustomSelect
                      label='Tipo de contrato'
                      name='contractType'
                      options={Constants.CUSTOMER_CONTRACT_TYPES.options}
                    />
                  </Grid>

                  {personType === 'PF' && (
                    <>
                      <Grid item className={classes.input} xs={6}>
                        <CustomInput name='rg' label='RG' />
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <CustomInput name='cnh' label='CNH' />
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <InputMask
                          mask='999.999.999-99'
                          maskChar=' '
                          disabled={false}
                        >
                          {() => <CustomInput name='cpf' label='CPF*' />}
                        </InputMask>
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <CustomSelect
                          name='sexo'
                          label='Sexo*'
                          options={Constants.SEX_TYPES}
                        />
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <CustomInput
                          name='nacionalidade'
                          label='Nacionalidade'
                        />
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <CustomSelect
                          name='estado civil'
                          label='Estado Civil'
                          options={Constants.CIVIL_STATUS}
                        />
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <CustomInput name='profissão' label='Profissão' />
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <InputMask mask='99/99/9999' maskChar=' '>
                          {() => (
                            <CustomInput
                              name='data de nascimento'
                              label='Data de nascimento'
                            />
                          )}
                        </InputMask>
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <p className={classes.input}>Acesso a Ferramentas</p>
                        <CheckboxInput
                          name='customerAccess'
                          label='Gerador de documento'
                          onChange={handleDoc}
                          defaultValue={false}
                        />
                        <CheckboxInput
                          name='customerAccess'
                          label='Mapeamento de dados'
                          onChange={handleMapping}
                          defaultValue={false}
                        />
                        <CheckboxInput
                          name='customerAccess'
                          label='Registrar marcas'
                          onChange={handleMarcas}
                          defaultValue={false}
                        />
                      </Grid>
                    </>
                  )}
                  {personType === 'PJ' && (
                    <>
                      <Grid item className={classes.input} xs={12}>
                        <CustomInput
                          name='razão social'
                          label='Razão Social*'
                        />
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <InputMask
                          mask='99.999.999/9999-99'
                          maskChar=' '
                          disabled={false}
                        >
                          {() => <CustomInput name='cnpj' label='CNPJ*' />}
                        </InputMask>
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <CustomSelect
                          name='tipo de sociedade'
                          label='Tipo de Sociedade*'
                          options={Constants.SOCIETY_TYPES}
                        />
                      </Grid>

                      <Grid item className={classes.input} xs={6}>
                        <ImageInput name='logo' label='Logo' />
                      </Grid>
                    </>
                  )}
                  <Grid item className={classes.input} xs={12}>
                    <h2 className={classes.subTitle}>Endereço</h2>
                  </Grid>

                  <Grid item className={classes.input} xs={12}>
                    <CheckboxInput
                      name='estrangeiro'
                      label='Estrangeiro'
                      onChange={handleForeign}
                    />
                  </Grid>

                  <Grid item className={classes.input} xs={3}>
                    {isForeign ? (
                      <InputMask>
                        {() => <CustomInput name='cep' label={'ZIPCODE*'} />}
                      </InputMask>
                    ) : (
                      <InputMask
                        mask='99999-999'
                        maskChar=' '
                        onChange={handleCEPChange}
                        disabled={false}
                      >
                        {() => <CustomInput name='cep' label={'CEP*'} />}
                      </InputMask>
                    )}
                  </Grid>

                  <Grid item className={classes.input} xs={9}>
                    <CustomInput name='logradouro' label='Logradouro*' />
                  </Grid>
                  <Grid item className={classes.input} xs={9}>
                    <CustomInput name='complemento' label='Complemento' />
                  </Grid>
                  <Grid item className={classes.input} xs={3}>
                    <CustomInput name='número' label='Número*' />
                  </Grid>
                  <Grid item className={classes.input} xs={6}>
                    <CustomInput name='bairro' label='Bairro*' />
                  </Grid>
                  <Grid item className={classes.input} xs={6}>
                    <CustomInput name='cidade' label='Cidade*' />
                  </Grid>
                  <Grid item className={classes.input} xs={12}>
                    <CustomInput name='estado' label='Estado*' />
                  </Grid>
                  {personType === 'PJ' && (
                    <>
                      <Grid item className={classes.input} xs={12}>
                        <h2 className={classes.subTitle}>Representante Legal</h2>
                      </Grid>
                      <Grid item className={classes.input} xs={12}>
                        <CustomInput
                          name='nome do representante legal'
                          label='Nome do Representante Legal*'
                        />
                      </Grid>
                      <Grid item className={classes.input} xs={6}>
                        <InputMask
                          mask='999.999.999-99'
                          maskChar=' '
                          disabled={false}
                        >
                          {() => (
                            <CustomInput
                              name='cpf do representante legal'
                              label=' CPF do Representante Legal*'
                            />
                          )}
                        </InputMask>
                      </Grid>
                    </>
                  )}
                  <Grid item className={classes.input} xs={12}>
                    <h2 className={classes.subTitle}>Informações de Contato</h2>
                  </Grid>
                  <Grid item className={classes.input} xs={6}>
                    <CustomInput name='email' label='E-mail*' />
                  </Grid>
                  <Grid item className={classes.input} xs={6}>
                    <InputMask
                      mask='(99)99999-9999'
                      maskChar=' '
                      disabled={false}
                    >
                      {() => <CustomInput name='telefone' label='Telefone' />}
                    </InputMask>
                  </Grid>
                  <Grid item className={classes.input} xs={6}>
                    <CustomInput name='site' label='Site' />
                  </Grid>
                  <Grid
                    container
                    className={classes.input}
                    spacing={1}
                    direction='row'
                  >
                    <Grid item>
                      <h2>Usuários adicionais</h2>
                    </Grid>

                    <Grid item>
                      <button
                        className={classes.submit}
                        style={{
                          backgroundColor: '#1ab394',
                          padding: '0px 10px',
                          fontSize: '30px',
                        }}
                        type='button'
                        onClick={handleAddAdditionalUser}
                      >
                        +
                      </button>
                    </Grid>
                  </Grid>

                  {additionalUsers.map((key, idx) => {
                    if (!valids.includes(key)) return null;
                    return (
                      <Grid container id={'user-' + key} key={idx}>
                        <h3 style={{ padding: '5px 0px 0px 10px' }}>
                          Usuário {valids.indexOf(key) + 1}
                        </h3>

                        <Grid
                          container
                          style={{ borderBottom: 'solid 1px #e7eaec' }}
                        >
                          <Grid item className={classes.input} xs={6}>
                            <CustomInput name={'nome-' + key} label='Nome*' />
                          </Grid>
                          <Grid item className={classes.input} xs={6}>
                            <CustomInput
                              name={'email-' + key}
                              label='E-mail*'
                            />
                          </Grid>

                          <Grid item className={classes.input} xs={6}>
                            <CustomInput
                              name={'cargo-' + key}
                              label='Cargo/Função'
                            />
                          </Grid>
                          <Grid item className={classes.input} xs={6}>
                            <InputMask
                              mask='(99)99999-9999'
                              maskChar=' '
                              disabled={false}
                            >
                              {() => (
                                <CustomInput
                                  name={'telefone-' + key}
                                  label='Telefone'
                                />
                              )}
                            </InputMask>
                          </Grid>
                          <Grid item className={classes.input} xs={6}>
                            <CheckboxInput
                              name={'customerAdmin-' + key}
                              label='Administrador'
                              defaultValue={false}
                            />
                          </Grid>

                          <Grid
                            container
                            direction='row'
                            alignItems='center'
                            className={classes.input}
                            justify='space-between'
                          >
                            <Grid item xs={2}></Grid>

                            <button
                              className={classes.submit}
                              style={{
                                backgroundColor: '#ff3333',
                              }}
                              type='button'
                              onClick={e => handleRemoveAdditionalUser(key)}
                            >
                              Remover
                            </button>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>

                <Grid item className={classes.input} xs={6}>
                  <Grid item className={classes.subTitle}>
                    <h2>Adicionar Tags</h2>
                  </Grid>
                  <TagsContainer>
                    {
                      currentTags.map((dt =>
                        <TagInput
                          key={dt.id}
                          name={dt.name}
                          description={dt.description}
                          onClick={() => {
                            if (tags.includes(dt.id))
                              setActualTags(tags.filter(item => item !== dt.id))
                            else setActualTags(state => [...state, dt.id])
                          }}
                          style={tags.includes(dt.id) ? { textDecoration: 'line-through', opacity: 0.5 } : { textDecoration: 'none' }}
                        />
                      ))
                    }
                  </TagsContainer>
                </Grid>
                <Grid
                  container
                  className={classes.buttonContainer}
                  justify='center'
                >
                  <Grid item xs={7}>

                    <button className={classes.submit} type='submit'>
                      {!submitLoading && 'Cadastrar'}
                      {submitLoading && <CircularProgress color='inherit' />}
                    </button>
                  </Grid>
                </Grid>
              </Form>
            </Grid>
          </Grid>
        }
      />
    </React.Fragment>
  );
}
