import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import Loading from '../../../components/Loading';
import MiniDrawer from '../../../components/MiniDrawer';
import { EngagementCustomerTable } from '../../../components/Tables/EngagementCustomerTable';
import { GenericCustomerTable } from '../../../components/Tables/GenericCustomerTable';
import csApi from '../../../services/apis/csApi';
import { formatDate } from '../../../utils';
import {
  Container,
  Title,
  Section,
  SectionTitle,
  SectionContent,
  CustomerListsContainer,
  CustomerListContent,
  SimpleTable,
} from '../Dashboard.styles';

export default function Dashboard() {
  const [content, setContent] = useState({
    allLowEngagementCustomers: [],
    customersWithoutDemands: [],
    lowEngagementLast3Months: { in: 0, out: 0, customers: [] },
    lowEngagementLastMonth: { in: 0, out: 0, customers: [] },
    lowEngagementLastWeek: { in: 0, out: 0, customers: [] },
    buttonsModal: {
      open: false,
      title: '',
      description: '',
      data: {},
      fetchData: () => {},
      handleCloseButtonsModal: () => {},
    },
    loading: true,
  });

  const fetchData = useCallback(async () => {
    try {
      setContent(prev => ({ ...prev, loading: true }));

      const dashboardData = (await csApi.getEngagementDashboard())?.data;
      setContent(prev => ({ ...prev, ...dashboardData }));
    } catch (error) {
      console.error(error);
    } finally {
      setContent(prev => ({ ...prev, loading: false }));
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <React.Fragment>
      <MiniDrawer
        currentExpaded='cs'
        main={
          <Container>
            <Loading open={content.loading} />

            <Title>Engajamento do Cliente</Title>

            <Section>
              <SectionTitle>Métricas de Engajamento</SectionTitle>

              <SectionContent>
                <CustomerListsContainer>
                  <CustomerListContent>
                    {!content.loading && (
                      <SimpleTable.Container>
                        <SimpleTable.Table>
                          <SimpleTable.TableHead>
                            <SimpleTable.TableRow>
                              <SimpleTable.TableHeadCell
                                style={{
                                  width: '25%',
                                }}
                              >
                                Engajamento nos
                              </SimpleTable.TableHeadCell>

                              <SimpleTable.TableHeadCell
                                style={{
                                  width: '25%',
                                  textAlign: 'center',
                                }}
                              >
                                Entraram em engajamento baixo
                              </SimpleTable.TableHeadCell>

                              <SimpleTable.TableHeadCell
                                style={{
                                  width: '25%',
                                  textAlign: 'center',
                                }}
                              >
                                Saíram do engajamento baixo
                              </SimpleTable.TableHeadCell>

                              <SimpleTable.TableHeadCell
                                style={{
                                  width: '25%',
                                  textAlign: 'center',
                                }}
                              >
                                Reversão
                              </SimpleTable.TableHeadCell>
                            </SimpleTable.TableRow>
                          </SimpleTable.TableHead>

                          <SimpleTable.TableBody>
                            {[
                              {
                                period: 'Últimos 7 dias',
                                in: content.lowEngagementLastWeek.in,
                                out: content.lowEngagementLastWeek.out,
                              },
                              {
                                period: 'Últimos 30 dias',
                                in: content.lowEngagementLastMonth.in,
                                out: content.lowEngagementLastMonth.out,
                              },
                              {
                                period: 'Últimos 90 dias',
                                in: content.lowEngagementLast3Months.in,
                                out: content.lowEngagementLast3Months.out,
                              },
                            ].map(statistics => (
                              <SimpleTable.TableRow key={statistics.period}>
                                <SimpleTable.TableData>
                                  {statistics.period}
                                </SimpleTable.TableData>

                                <SimpleTable.TableData
                                  style={{
                                    textAlign: 'center',
                                    color: '#ea5f59',
                                  }}
                                >
                                  {statistics.in}
                                </SimpleTable.TableData>

                                <SimpleTable.TableData
                                  style={{
                                    textAlign: 'center',
                                    color: '#1ab394',
                                  }}
                                >
                                  {statistics.out}
                                </SimpleTable.TableData>

                                <SimpleTable.TableData
                                  style={{
                                    textAlign: 'center',
                                    color: '#1994FF',
                                  }}
                                >
                                  {statistics?.in > 0
                                    ? `${Number(
                                        (statistics?.out * 100) / statistics?.in
                                      ).toFixed(2)} %`
                                    : 'Indefinido'}
                                </SimpleTable.TableData>
                              </SimpleTable.TableRow>
                            ))}
                          </SimpleTable.TableBody>
                        </SimpleTable.Table>
                      </SimpleTable.Container>
                    )}
                  </CustomerListContent>
                </CustomerListsContainer>
              </SectionContent>
            </Section>

            <Section>
              <SectionTitle>Listas</SectionTitle>

              <SectionContent>
                <CustomerListsContainer>
                  <CustomerListContent>
                    <h3>
                      Engajamento baixo (Entrou em engajamento baixo há mais de
                      7 dias)
                      <span>
                        {' '}
                        Última atividade entre{' '}
                        {formatDate(
                          moment().subtract(1, 'month').subtract(1, 'w')
                        )}{' '}
                        e {formatDate(moment().subtract(1, 'month'))}
                      </span>
                    </h3>

                    <h2>
                      Total: {content?.lowEngagementLastWeek?.customers?.length}
                    </h2>

                    {!content.loading && (
                      <GenericCustomerTable
                        customers={content.lowEngagementLastWeek.customers}
                        fetchData={fetchData}
                      />
                    )}
                  </CustomerListContent>

                  <CustomerListContent>
                    <h3>
                      Engajamento Muito Baixo (Entrou em engajamento muito baixo
                      há mais de 30 dias)
                      <span>
                        {' '}
                        Última atividade entre{' '}
                        {formatDate(moment().subtract(3, 'month'))} e{' '}
                        {formatDate(
                          moment().subtract(1, 'month').subtract(1, 'w')
                        )}
                      </span>
                    </h3>

                    <h2>
                      Total:{' '}
                      {content?.lowEngagementLastMonth?.customers.length || ''}
                    </h2>

                    {!content.loading && (
                      <GenericCustomerTable
                        customers={content.lowEngagementLastMonth.customers}
                        fetchData={fetchData}
                      />
                    )}
                  </CustomerListContent>

                  <CustomerListContent>
                    <h3>
                      Engajamento Baixíssimo (Entrou em engajamento baixíssimo
                      há mais de 90 dias)
                      <span>
                        {' '}
                        Última atividade antes de{' '}
                        {formatDate(moment().subtract(3, 'month'))}
                      </span>
                    </h3>

                    <h2>
                      Total:{' '}
                      {content?.lowEngagementLast3Months?.customers.length ||
                        ''}
                    </h2>

                    {!content.loading && (
                      <GenericCustomerTable
                        customers={content.lowEngagementLast3Months.customers}
                        fetchData={fetchData}
                      />
                    )}
                  </CustomerListContent>

                  <CustomerListContent>
                    <h3>
                      Clientes Sem Demandas <span> (sem nenhuma demanda)</span>
                    </h3>

                    <h2>Total: {content?.customersWithoutDemands?.length}</h2>

                    {!content.loading && (
                      <GenericCustomerTable
                        customers={content.customersWithoutDemands}
                        fetchData={fetchData}
                      />
                    )}
                  </CustomerListContent>
                  <CustomerListContent>
                    <h3>
                      Cliente com reunião de engajamento sugerida{' '}
                      <span>
                        (Há uma demanda de engajamento não finalizada)
                      </span>
                    </h3>

                    <h2>
                      Total: {content?.customersInEngagementMeeting?.length}
                    </h2>

                    {!content.loading && (
                      <EngagementCustomerTable
                        customers={content.customersInEngagementMeeting}
                        fetchData={fetchData}
                      />
                    )}
                  </CustomerListContent>
                </CustomerListsContainer>
              </SectionContent>
            </Section>
          </Container>
        }
      />
    </React.Fragment>
  );
}
